import { Model } from '@/models/Model';
import { Report } from '@/models/Report';
import { User } from '@/models/User';
import { Organization } from '@/models/Organization';

export class Reservation extends Model {
  protected $name = 'Reservation';

  protected $endpoint = '/reservations';

  protected $fillable: string[] = [];

  public id?: string;

  public user?: User | null;

  public mediator?: User | null;

  public report?: Report

  public starts_at?: string;

  public ends_at?: string;

  public mediator_starts_at?: string;

  public mediator_ends_at?: string;

  public released_at?: string;

  public created_at?: string;

  public updated_at?: string;

  public reserved_by_user?: User;

  public organization?: Organization;

  public date?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export interface ReservedReport {
  id: string;
  case_number: string;
  status: string;
}
