import { Component, Vue, Model } from 'vue-property-decorator';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb.vue';
import Divider from '@/components/Breadcrumbs/Divider.vue';

@Component<Breadcrumbs>({
  components: {
    Breadcrumb,
    Divider,
  },
})
export default class Breadcrumbs extends Vue {
  @Model('crumbs') crumbs!: string[];
}
