import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { Skill } from '@/models/Skill';

@Component<ReportSkillFilter>({})
export default class ReportSkillFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: false })
  protected hasNoSkills!: boolean;

  protected skills: Skill[] = [];

  protected selectedSkills = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedSkills);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedSkills = [];
    } else {
      this.selectedSkills = cloneDeep(this.value);
    }
  }

  public async mounted() {
    await this.getSkills();
  }

  protected async getSkills(): Promise<void> {
    this.skills = await new Skill().all().catch((error: AxiosError) => {
      ErrorHandler.network(error);
      return [];
    });

    if (this.hasNoSkills) {
      this.skills.push({
        name: 'Geen Skills',
        id: 'no_skills',
      } as any);
    }
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(
        this,
        'selectedSkills',
        this.skills.map((skill: Skill) => skill.id || ''),
      );
    } else {
      this.$set(this, 'selectedSkills', []);
    }

    this.handleChange();
  }
}
