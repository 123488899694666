import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component<MiDialog>({})
export default class MiDialog extends Vue {
  @Prop({ default: false })
  protected options!: Options;

  protected settings: Options | null = null;

  protected okButton = false;

  @Watch('options')
  public optionsChanged(options: Options | null) {
    if (options === null) {
      return;
    }

    this.settings = this.options;
  }

  public mounted() {
    if (this.options === null) {
      return;
    }

    this.settings = this.options;
  }

  public cancel() {
    if (this.hasCancelButton && this.settings) {
      const button: ButtonOptions = this.settings.buttons.cancel as ButtonOptions;
      if (button.action) {
        button.action();
      }
    }

    this.close();
  }

  public confirm() {
    if (this.hasConfirmButton && this.settings) {
      const button: ButtonOptions = this.settings.buttons.confirm as ButtonOptions;
      if (button.action) {
        button.action();
      }
    }

    this.close();
  }

  public close() {
    this.$emit('input', true);
    this.$emit('close', true);
  }

  get hasCancelButton(): boolean {
    if (! this.settings || ! this.settings.buttons || ! this.settings.buttons.cancel) {
      return false;
    }

    if (this.options.buttons.cancel && this.options.buttons.cancel.color === undefined) {
      this.options.buttons.cancel.color = 'warning';
    }

    return this.settings.buttons.cancel.text !== '';
  }

  get hasConfirmButton(): boolean {
    if (! this.settings || ! this.settings.buttons || ! this.settings.buttons.confirm) {
      return false;
    }

    if (this.options.buttons.confirm && this.options.buttons.confirm.color === undefined) {
      this.options.buttons.confirm.color = 'success';
    }

    if (
      this.settings.buttons.confirm.text.toLowerCase()
        === this.$t('dialogOptions.button.ok')
          .toString()
          .toLowerCase()
      || this.settings.buttons.confirm.text.toLowerCase() === 'ok'
    ) {
      this.okButton = true;
    } else {
      this.okButton = false;
    }

    return this.settings.buttons.confirm.text !== '';
  }
}

export interface Options {
  title: string;
  text: string;
  type: DialogType;
  buttons: Buttons;
}

interface Buttons {
  confirm?: ButtonOptions;
  cancel?: ButtonOptions;
}

interface ButtonOptions {
  text: string;
  action?: Function;
  color?: string;
}

export type DialogType = 'error' | 'warning' | 'success';
