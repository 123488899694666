import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { Tag } from '@/models/Tag';

@Component<ReportTagFilter>({})
export default class ReportTagFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected hasNoTags!: boolean;

  protected tags: Tag[] = [];

  protected selectedTags = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedTags);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedTags = [];
    } else {
      this.selectedTags = cloneDeep(this.value);
    }
  }

  public async mounted() {
    await this.getTags();
  }

  protected async getTags(): Promise<void> {
    this.tags = await new Tag()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    if (this.hasNoTags) {
      this.tags.push({
        name: 'Geen Tags',
        id: 'no_tags',
      } as any);
    }
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedTags', this.tags.map((tag: Tag) => tag.id || ''));
    } else {
      this.$set(this, 'selectedTags', []);
    }

    this.handleChange();
  }
}
