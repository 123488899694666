var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app",
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.isLoading
              ? _c("div", { staticClass: "loading-screen" }, [
                  _c("img", {
                    staticClass: "mb-4",
                    attrs: {
                      width: "400px",
                      src: require("@/assets/images/test-trillendhuis.gif"),
                      alt: "IMG huisje"
                    }
                  })
                ])
              : _vm._e()
          ]),
          _c(
            "v-container",
            { attrs: { "pa-0": "", fluid: "" } },
            [
              _vm.isDisplayingNavigation
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _c("Navigation", {
                            attrs: { breadcrumbs: _vm.breadcrumbs }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { row: "", "fill-vh": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pageContainer", attrs: { sm12: "" } },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500px", persistent: "" },
              model: {
                value: _vm.isDisplayingDialog,
                callback: function($$v) {
                  _vm.isDisplayingDialog = $$v
                },
                expression: "isDisplayingDialog"
              }
            },
            [
              _c("MiDialog", {
                attrs: { options: _vm.dialogOptions },
                model: {
                  value: _vm.isDisplayingDialog,
                  callback: function($$v) {
                    _vm.isDisplayingDialog = $$v
                  },
                  expression: "isDisplayingDialog"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.updateIsReady
        ? _c("p", { staticClass: "update-is-ready-text" }, [
            _vm._v(
              ' Er is een nieuwe versie beschikbaar. Sla uw werk op en druk op "ctrl+F5" (windows) of "cmd + Shift + R" (apple). '
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }