import { Anomaly, AnomalyType } from '@/models/Anomaly';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<AnomalyCustomProperties>({})
export default class AnomalyCustomProperties extends Vue {
  @Prop()
  protected anomaly!: Anomaly;

  protected get AnomalyType(): typeof AnomalyType {
    return AnomalyType;
  }

  protected get textClass(): string {
    return this.anomaly?.custom_properties?.anomaly?.styling as AnomalyStyling === 'success'
      ? 'tw-text-success-600' : 'tw-text-error-600';
  }
}

type AnomalyStyling = 'success' | 'error';
