import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Repair } from '@/models/Repair';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report } from '@/models/Report';
import { Damage, DamageAnswers } from '@/models/Damage';
import { cloneDeep } from 'lodash';
import { RepairType } from '@/models/RepairType';

@Component<RepairDialog>({
  filters: {
    formatText: (value: string) => {
      const typeLowercased = value.toString().toLowerCase();
      return typeLowercased.charAt(0).toUpperCase() + typeLowercased.slice(1);
    },
  },
})
export default class RepairDialog extends Vue {
  @Prop()
  protected report!: Report;

  @Prop()
  protected damage!: Damage;

  @Prop({ default: null })
  protected repair!: Repair;

  protected newlyCreatedRepairs: Repair[] = [];

  protected repairFormType = 'calculation';

  protected types: RepairType[] = [];

  protected unitTypeOptions: string[] = ['dag', 'dgdl', 'm1', 'm2', 'pst', 'st'];

  protected vatOptions: number[] = [21, 9, 6, 0];

  protected customRepairUnit = 'pst';

  protected filteredSubSections: any[] = [];

  protected filteredElementClusters: any[] = [];

  protected filteredRepairItems: any[] = [];

  protected elementClusters: any[] = [];

  protected selectedSubSection = '';

  protected selectedElementCluster = '';

  protected selectedRepair: any = null;

  protected isNotV1 = false;

  protected selectedUnitPrice = 0;

  protected repairQuantity = 0;

  protected selectedRepairTotal = '';

  protected repairDescription = '';

  protected customRepairUnitPrice = 0;

  protected customRepairQuantity = 0;

  protected customTotal = 0;

  protected chosenVat = 0;

  protected applySurtax = true;

  protected isEditing = false;

  protected isLoading = true;

  protected isSubmitted = false;

  protected informationDialogOpen = false;

  protected currentInformation = '';

  protected informationContent: any = {
    amount: {
      title: 'Een reparatie voor meerdere schades',
      content: [{
        text: 'Heeft u bij een schade een reparatie toegevoegd die ook voor een andere schade geldt? Denk bijvoorbeeld aan een steiger. Dan kiest u bij die volgende schade voor ‘diverse buiten/binnen’ – reeds gerekend.',
      }, {
        text: 'De kosten worden zo maar één keer berekend. ',
      }],
    },
    pricePerUnit: {
      title: 'Informatie: Prijs per eenheid',
      content: [{
        text: 'De prijzen zijn eenheidsprijzen opgesteld door meerdere deskundigen bureaus en gevalideerd door een extern bouwkostenadviesbureau. Alle deskundigen en aannemers die in Groningen actief zijn voor wat betreft het opnemen van schades als gevolg van mijnbouwactiviteiten dienen gebruik te maken van deze eenheidsprijzen.',
      }, {
        text: 'Alle benodigde werkzaamheden zijn in deze prijs opgenomen.',
      }, {
        text: 'Inbegrepen zijn:',
      }, {
        bulletPoints: [
          'Sloopwerk',
          'Herstelwerk',
          'opruimen en afvoeren van puin/restanten;',
          'huur, afschrijving/slijtage gereedschap en materieel;',
          'autokosten;',
          'klimvoorzieningen tot 2m hoogte, kamersteiger, en een ladder tot 6m;',
          'aftappen en vullen van radiatoren indien deze verwijderd/hermonteerd moeten worden;',
          'beschermende voorzieningen bij werkzaamheden aan de gevel kunnen gevonden worden bij diversen;',
          'indien bij herstel van een vloer een deel wandafwerking zal sneuvelen, kunnen deze kosten worden begroot bij het herstel',
        ],
      }],
    },
  };

  public mounted() {
    if (this.repair.uuid) {
      this.isEditing = true;
    }
    if (this.report.calculation_model) {
      this.isNotV1 = this.report.calculation_model.name !== 'v1';
    }
    this.getTypes();
  }

  fillCustomRepair() {
    this.repairFormType = 'custom';
    this.isEditing = true;
    (this.customRepairQuantity as any) = this.repair.quantity;
    (this.repairDescription as any) = this.repair.custom_name;
    (this.customRepairUnit as any) = this.repair.custom_unit;
    (this.customRepairUnitPrice as any) = this.repair.custom_unitprice;
    (this.chosenVat as any) = this.repair.vat;
    (this.applySurtax as any) = this.repair.custom_apply_surtax;
    this.calculateCustomTotal((this.repair as any).quantity);
  }

  fillRepair() {
    if (! this.repair || ! this.repair.type) {
      return;
    }

    this.isEditing = true;
    // this.repairUuid = this.repair.type.uuid;
    this.selectedRepair = this.repair.type;
    (this.selectedSubSection as any) = this.repair.type.subsection;

    if (this.isNotV1) {
      // set elementcluster
      (this.selectedElementCluster as any) = this.repair.type.elementcluster;
    }

    // set repair
    (this.repairQuantity as any) = this.repair.quantity;
    this.updateTotal();

    // set unit price
    this.selectedUnitPrice = this.getUnitPrice(this.repairQuantity);
    this.selectedRepairTotal = (this.repairQuantity * this.selectedUnitPrice).toString();
  }

  protected updateTotal() {
    this.selectedRepairTotal = (this.repairQuantity * this.selectedRepair.unitprice).toString();
  }

  protected getTypes() {
    this.isLoading = true;

    const filter = {
      section: '',
      calculation_model: this.report.calculation_model ? this.report.calculation_model.uuid : '',
    };

    if (this.damage.answers) {
      if ((this.damage.answers as any).LocatieSchade === 'Buitenkant gebouw') {
        filter.section = 'exterior';
      } else if ((this.damage.answers as any).LocatieSchade === 'Binnenkant gebouw') {
        filter.section = 'interior';
      }
    }

    new Repair()
      .limit(500)
      .filter(filter)
      .types()
      .all()
      .then((repair: RepairType[]) => {
        this.types = repair;
        this.isLoading = false;

        if (this.repair && this.repair.form === 'custom') {
          this.fillCustomRepair();
        }

        if (this.repair && this.repair.form !== 'custom') {
          this.fillRepair();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected setRepairTotal(value: number) {
    this.selectedUnitPrice = this.getUnitPrice(value);
    this.selectedRepairTotal = (value * this.selectedUnitPrice).toString();
  }

  protected getUnitPrice(value: any) {
    if (! this.selectedRepair) {
      return;
    }

    value = parseFloat(value);

    for (const priceScale of this.selectedRepair.price_scales) {
      const from = parseFloat(priceScale.from);
      const till = parseFloat(priceScale.till);
      if (value >= from && value <= till) {
        return priceScale.unitprice;
      }
    }

    return this.selectedRepair.unitprice;
  }

  protected calculateCustomTotal(quantity: number) {
    if (quantity) {
      this.customRepairQuantity = quantity;
    }
    this.customTotal = (this.customRepairQuantity * this.customRepairUnitPrice);
  }

  protected toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  protected closeCreatingRepair() {
    this.$emit('closeDialog');
  }

  protected storeRepair() {
    const payload = {
      quantity: this.repairQuantity,
      type: this.selectedRepair.uuid,
      damage: this.damage.uuid,
      form: this.repairFormType,
    };

    if (this.repair && this.repair.uuid) {
      this.updateRepair(payload);
    } else {
      this.createRepair(payload);
    }
  }

  storeCustomRepair(event: any, form:any) {
    event.preventDefault();
    const payload = {
      quantity: this.customRepairQuantity,
      damage: this.damage.uuid,
      form: 'custom',
      custom_name: this.repairDescription,
      custom_unit: this.customRepairUnit,
      custom_vat: this.chosenVat,
      custom_unitprice: this.customRepairUnitPrice,
      custom_apply_surtax: this.applySurtax,
    };

    if (this.repair && this.repair.uuid) {
      this.updateRepair(payload);
    } else {
      this.createRepair(payload);
    }
  }

  protected updateRepair(payload: any) {
    if (! this.repair.uuid) {
      return;
    }
    new Repair()
      .repair(this.repair.uuid)
      .update(payload)
      .then(() => {
        this.isSubmitted = true;
        setTimeout(() => {
          this.isSubmitted = false;
          this.$emit('initRepairs');
        }, 1000);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async createRepair(payload: any) {
    const repair = await new Repair()
      .create(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (this.isNotV1) {
      this.selectedRepair = null;
    }

    this.isSubmitted = true;
    setTimeout(() => {
      this.isSubmitted = false;
      this.$emit('initRepairs');
    }, 1000);

    if (repair) {
      this.newlyCreatedRepairs.push(repair);
    }
  }

  protected formatText(value: string) {
    const typeLowercased = value.toString().toLowerCase();
    return typeLowercased.charAt(0).toUpperCase() + typeLowercased.slice(1);
  }

  protected openInformationDialog(informationType: string) {
    this.currentInformation = informationType;
    this.informationDialogOpen = true;
  }

  protected get section(): string {
    if (this.damage && this.damage.answers) {
      if ((this.damage.answers as DamageAnswers).LocatieSchade === 'Buitenkant gebouw') {
        return 'exterior';
      } if ((this.damage.answers as DamageAnswers).LocatieSchade === 'Binnenkant gebouw') {
        return 'interior';
      }
    }

    return '';
  }

  protected get repairTypes(): RepairType[] {
    const types = cloneDeep(this.types).filter((repairType: RepairType) => (this.section ? (repairType.section === this.section) : true));

    return types;
  }

  protected get repairCategories(): string[] {
    const categories: string[] = [];

    this.repairTypes.forEach((type: RepairType) => {
      if (type.subsection && ! categories.includes(type.subsection)) {
        categories.push(type.subsection);
      }
    });

    return categories;
  }

  protected get repairElementClusters(): string[] {
    const categories: string[] = [];

    let types = cloneDeep(this.repairTypes);
    types = types.filter((repairType: RepairType) => this.selectedSubSection === repairType.subsection);

    types.forEach((type: RepairType) => {
      if (type.elementcluster && ! categories.includes(type.elementcluster)) {
        categories.push(type.elementcluster);
      }
    });

    return categories;
  }

  protected get repairs(): RepairType[] {
    return this.repairTypes.filter((repairType: RepairType) => (this.selectedSubSection === repairType.subsection) && (this.selectedElementCluster === repairType.elementcluster));
  }

  @Watch('repairQuantity')
  public repairQuantityChanged(to: any, from: any) {
    this.setRepairTotal(to);
  }

  @Watch('chosenVat')
  public chosenVatChanged(to:any, from: any) {
    this.calculateCustomTotal(this.customRepairQuantity);
  }

  @Watch('selectedRepair')
  public selectedRepairChange(to: any, from: any) {
    this.setRepairTotal(this.customRepairQuantity);
  }

  @Watch('repairQuantity')
  public repairQuantityChange(to: any, from: any) {
    if (this.repairQuantity < 0) {
      this.repairQuantity = 0;
    }
  }

  @Watch('customRepairQuantity')
  public customRepairQuantityChange(to: any, from: any) {
    if (this.customRepairQuantity < 0) {
      this.customRepairQuantity = 0;
    }
  }
}
