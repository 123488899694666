export enum QuestionaireDialogConclusionTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INCOMPLETE = 'incomplete'
}

export interface QuestionaireDialogConclusionTypeItemInterface {
  value: QuestionaireDialogConclusionTypeEnum;
  text: string;
}

export default class QuestionaireDialogConclusionType {
  public static get items(): QuestionaireDialogConclusionTypeItemInterface[] {
    return Object
      .keys(QuestionaireDialogConclusionType.icons)
      .map((value: string): QuestionaireDialogConclusionTypeItemInterface => ({
        value: value as QuestionaireDialogConclusionTypeEnum,
        text: QuestionaireDialogConclusionType.icons[value as QuestionaireDialogConclusionTypeEnum],
      }));
  }

  public static get icons(): Record<QuestionaireDialogConclusionTypeEnum, string> {
    return {
      [QuestionaireDialogConclusionTypeEnum.SUCCESS]: 'check_circle',
      [QuestionaireDialogConclusionTypeEnum.WARNING]: 'warning',
      [QuestionaireDialogConclusionTypeEnum.ERROR]: 'remove_circle',
      [QuestionaireDialogConclusionTypeEnum.INCOMPLETE]: 'help',
    };
  }

  public static get buttonColors(): Record<QuestionaireDialogConclusionTypeEnum, string> {
    return {
      [QuestionaireDialogConclusionTypeEnum.SUCCESS]: 'success-button',
      [QuestionaireDialogConclusionTypeEnum.WARNING]: 'orange-button',
      [QuestionaireDialogConclusionTypeEnum.ERROR]: 'warning-button',
      [QuestionaireDialogConclusionTypeEnum.INCOMPLETE]: '',
    };
  }
}
