var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.areas,
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      label: "Gebied",
      "search-input": _vm.searchInput,
      clearable: "",
      "return-object": ""
    },
    on: {
      "update:searchInput": function($event) {
        _vm.searchInput = $event
      },
      "update:search-input": function($event) {
        _vm.searchInput = $event
      },
      keyup: this.searchDebounce,
      change: _vm.handleChange
    },
    model: {
      value: _vm.selectedMapArea,
      callback: function($$v) {
        _vm.selectedMapArea = $$v
      },
      expression: "selectedMapArea"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }