import { User } from '@/models/User';
import { Model } from '@/models/Model';

export class AppointmentLimit extends Model {
  protected $name = 'AppointmentLimit';

  protected $endpoint = '/appointment-limits';

  protected $fillable: string[] = [];

  public id?: string;

  public date?: string;

  public created_at?: string;

  public updated_at?: string;

  public appointment_limit!: number;

  public type_limits?: {[key: string]: string};

  public user?: User;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
