import { Model } from '@/models/Model';

export class Invoice extends Model {
  protected $name = 'Invoice';

  protected $endpoint = '/invoices';

  protected $fillable: string[] = ['name'];

  public id?: string;

  public date?: string;

  public status?: string;

  public number?: string;

  public total_ex?: number;

  public total_vat?: number;

  public total_in?: number;

  public purchase_order_number?: string;

  public info?: string;

  public sent_at?: string;

  public links?: InvoiceLinks;
}

export interface InvoiceLinks {
  download: string;
  preview: string;
}
