import { findIndex } from 'lodash';
import environments, { Environment } from '@/support/Environments';

export class Application {
  protected static environmentIndex: number|null;

  protected static environments: Environment[] = environments;

  public static setEnvironment() {
    Application.environmentIndex = findIndex(Application.environments, (environment: Environment) => environment.hostnames.includes(window.location.hostname));
  }

  public static config(key: string): boolean|string|number|undefined {
    if (! Application.environmentIndex) {
      Application.setEnvironment();
    }

    return Application.environment?.config[key];
  }

  public static get environment(): Environment|undefined {
    return Application.environments[Application.environmentIndex as number] || undefined;
  }
}
