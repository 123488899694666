import { Model } from '@/models/Model';
import client from '@/support/Client';
import { Media, DamageAnswers, Report } from '@/models/Report';
import { Repair } from '@/models/Repair';
import { OverruleReason } from '@/models/OverruleReason';

export class Damage extends Model {
  protected $name = 'Damage';

  protected $endpoint = '/damages';

  protected $fillable: string[] = [
    'from',
    'till',
    'overrule_payout',
    'overrule_reasons',
  ];

  public uuid?: string;

  public name?: string;

  public status?: string;

  public sort_order?: number;

  public apply_vat?: string;

  public reject_comment?: string;

  public exception_reason?: string;

  public exception_comment?: string;

  public calculation_percentage?: number;

  public overrule_payout?: string;

  public overrule_custom?: string;

  public created_at?: string;

  public updated_at?: string;

  public answers?: DamageAnswers;

  public instructions?: any | null;

  public is_room_without_damage?: boolean;

  // includes
  public links?: any;

  public media?: Media[];

  public repairs?: Repair[];

  public report?: Report;

  public reject_reason?: any;

  public reject_reasons?: any;

  public overrule_reasons?: OverruleReason[] | string[];

  public validations?: any;

  public damage_finance?: DamageFinance;

  public casuality_has_deviated?: boolean;

  public casuality_anomaly_reason?: string;

  public casuality_anomaly_explanation?: string;

  public damage(damage: Model | string) {
    this.$endpoint = `/damages/${damage}`;
    return this;
  }

  public mediaEndpoint(damage: Damage) {
    this.$endpoint = `/damages/${damage.uuid}/media`;
    return this;
  }

  public duplicateDamage(damage: Damage) {
    this.$endpoint = `/damages/${damage.uuid}/duplicate`;
    return this;
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public sortDamages(attributes: string[]) {
    const url = this.$endpoint;

    return client('post',
      url, attributes, true);
  }
}

interface DamageFinance {
  total?: string;
  total_incl_vat?: string;
}
