var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DamageDialogContainer" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                {
                  staticClass: "elementPanel__header",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "header__title", attrs: { sm11: "" } },
                    [
                      _c("h2", [
                        _vm._v(" Schade aanpassen | "),
                        _c("span", { staticClass: "subheader" }, [
                          _vm._v(_vm._s(_vm.dialogSubHeader))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "header__close", attrs: { sm1: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "tab__filled",
                      class: { active: _vm.activeTab === "tab-report" },
                      attrs: { href: "#tab-report" }
                    },
                    [_vm._v(" Vragenlijst ")]
                  ),
                  !_vm.isVES
                    ? _c(
                        "v-tab",
                        {
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === "tab-repairs" },
                          attrs: { href: "#tab-repairs" }
                        },
                        [_vm._v(" Reparaties ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-tab",
                    {
                      staticClass: "tab__filled",
                      class: { active: _vm.activeTab === "tab-images" },
                      attrs: { href: "#tab-images" }
                    },
                    [_vm._v("Foto's")]
                  )
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { lazy: true, value: "tab-report" } },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel__sub" },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _vm.damageModel.validations
                                ? _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "item__list item__list--reports pl-0",
                                      attrs: { wrap: "" }
                                    },
                                    [
                                      _vm._l(
                                        _vm.damageModel.validations,
                                        function(validation, key) {
                                          return [
                                            validation !== "ok"
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    key: key,
                                                    staticClass: "list__item",
                                                    attrs: { xs12: "" }
                                                  },
                                                  [
                                                    _c("ValidationWarning", {
                                                      attrs: {
                                                        validation: validation,
                                                        validationKey: key
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "damageContainer__content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "damageContainer__content__inner"
                                },
                                [
                                  _vm.reportId && _vm.damageModel
                                    ? _c("DynamicForm", {
                                        attrs: {
                                          reportId: _vm.reportId,
                                          damage: _vm.damageModel,
                                          sections: [51, 121]
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  !_vm.isVES
                    ? _c(
                        "v-tab-item",
                        { attrs: { lazy: true, value: "tab-repairs" } },
                        [
                          _c(
                            "div",
                            { staticClass: "elementPanel elementPanel__sub" },
                            [
                              _c(
                                "div",
                                { staticClass: "damageContainer__content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "damageContainer__content__inner"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "text-xs-right": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    icon: "add",
                                                    small: "true",
                                                    color: "secondary"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      _vm.isCreatingRepair = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Reparatie toevoegen "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.damageModel &&
                                      _vm.damageModel.repairs &&
                                      _vm.damageModel.repairs.length > 0
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass: "item__list",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "label",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs5: "" } },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Reparaties")
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs2: "" } },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Eenheid")
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                          attrs: { xs2: "" }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Prijs")
                                                          ])
                                                        ]
                                                      ),
                                                      _c("v-flex", {
                                                        attrs: { xs3: "" }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "draggable",
                                                _vm._b(
                                                  {
                                                    staticClass: "list-group",
                                                    attrs: {
                                                      options: { delay: 500 }
                                                    },
                                                    on: {
                                                      start: function($event) {
                                                        _vm.drag = true
                                                      },
                                                      end: function($event) {
                                                        ;(_vm.drag = false),
                                                          _vm.updateDamageRepairOrder()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentDamageRepairs,
                                                      callback: function($$v) {
                                                        _vm.currentDamageRepairs = $$v
                                                      },
                                                      expression:
                                                        "currentDamageRepairs"
                                                    }
                                                  },
                                                  "draggable",
                                                  _vm.dragOptions,
                                                  false
                                                ),
                                                [
                                                  _c(
                                                    "transition-group",
                                                    _vm._l(
                                                      _vm.currentDamageRepairs,
                                                      function(repair) {
                                                        return _c(
                                                          "v-flex",
                                                          {
                                                            key: repair.uuid,
                                                            staticClass:
                                                              "list-group-item list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs1: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "sortIcon"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "drag_indicator"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs4: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          repair.form !==
                                                                            "custom"
                                                                            ? repair
                                                                                .type
                                                                                .name
                                                                            : repair.custom_name
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs2: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          repair.form !==
                                                                            "custom"
                                                                            ? repair.quantity
                                                                            : repair.custom_unit
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "text-xs-right",
                                                                    attrs: {
                                                                      xs2: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            repair.total_incl_vat
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "text-xs-center customRepairIndicator",
                                                                    attrs: {
                                                                      xs1: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  repair.form ===
                                                                                  "custom"
                                                                                    ? _c(
                                                                                        "v-icon",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs: {
                                                                                              color:
                                                                                                "primary"
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            "info"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Aangepaste calculatie"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "text-xs-right",
                                                                    attrs: {
                                                                      xs2: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item__icon item__icon--action"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.editRepair(
                                                                                  repair
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "edit"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item__icon item__icon--action delete"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteRepair(
                                                                                  repair
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "list__item",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        wrap: "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-flex", {
                                                        attrs: { xs1: "" }
                                                      }),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "strongText",
                                                          attrs: { xs6: "" }
                                                        },
                                                        [_vm._v("Totaal")]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "strongText text-xs-right",
                                                          attrs: { xs2: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                _vm.damageModel
                                                                  .totalCost
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c("v-flex", {
                                                        attrs: { xs3: "" }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.damageModel &&
                                      _vm.damageModel.repairs &&
                                      _vm.damageModel.repairs.length === 0
                                        ? [
                                            _c("span", [
                                              _vm._v("Geen reparaties")
                                            ])
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-tab-item",
                    { attrs: { lazy: true, value: "tab-images" } },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel elementPanel__sub" },
                        [
                          _c(
                            "div",
                            { staticClass: "damageContainer__content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "damageContainer__content__inner"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "text-xs-right": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                con: "add",
                                                small: "true",
                                                color: "secondary"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.isUploadingFile = true
                                                }
                                              }
                                            },
                                            [_vm._v(" Foto toevoegen ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isLoading
                                    ? _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center",
                                          minHeight: "300px"
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isLoading
                                    ? [
                                        _vm.damageModel && _vm.damageModel.media
                                          ? [
                                              _c(
                                                "draggable",
                                                _vm._b(
                                                  {
                                                    staticClass: "list-group",
                                                    on: {
                                                      start: function($event) {
                                                        _vm.drag = true
                                                      },
                                                      end: function($event) {
                                                        ;(_vm.drag = false),
                                                          _vm.updateDamageMediaOrder()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentDamageMedia,
                                                      callback: function($$v) {
                                                        _vm.currentDamageMedia = $$v
                                                      },
                                                      expression:
                                                        "currentDamageMedia"
                                                    }
                                                  },
                                                  "draggable",
                                                  _vm.dragOptions,
                                                  false
                                                ),
                                                _vm._l(
                                                  _vm.currentDamageMedia,
                                                  function(media, imageIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: imageIndex,
                                                        staticClass:
                                                          "mediaContainer"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mediaContainer__actions"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "action"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "sortIcon"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "drag_indicator"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    media.original,
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "action"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "remove_red_eye"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "action",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.editMedia(
                                                                      media
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v("edit")
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "action",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteMedia(
                                                                      media
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "delete"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "delete"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            src: media.thumb
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm.damageModel &&
                                  _vm.damageModel.media &&
                                  _vm.damageModel.media.length === 0
                                    ? [_c("span", [_vm._v("Geen foto's")])]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                { attrs: { "justify-space-between": "", row: "", "pt-4": "" } },
                [
                  _c(
                    "MiButton",
                    {
                      attrs: { color: "warning", outline: "true" },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.close($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$tc("general.cancel")) + " ")]
                  ),
                  _c(
                    "MiButton",
                    {
                      staticClass: "float--right",
                      attrs: { disabled: !_vm.isValidDate, color: "success" },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleConfirm($event)
                        }
                      }
                    },
                    [_vm._v(" Aanpassen ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isCreatingRepair
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.isCreatingRepair,
                callback: function($$v) {
                  _vm.isCreatingRepair = $$v
                },
                expression: "isCreatingRepair"
              }
            },
            [
              _c("RepairDialog", {
                attrs: {
                  report: _vm.reportModel,
                  damage: _vm.damageModel,
                  repair: _vm.repair
                },
                on: {
                  closeDialog: _vm.closeCreatingRepair,
                  initRepairs: function($event) {
                    return _vm.getDamage(_vm.damageModel)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isUploadingFile
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: true,
                fullscreen: "",
                "content-class": "no-box-shadow"
              },
              model: {
                value: _vm.isUploadingFile,
                callback: function($$v) {
                  _vm.isUploadingFile = $$v
                },
                expression: "isUploadingFile"
              }
            },
            [
              _c("v-card", [
                _c(
                  "div",
                  { staticClass: "fileUpload" },
                  [
                    _c(
                      "v-card-title",
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "elementPanel__header",
                            attrs: { row: "", wrap: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__title",
                                attrs: { sm11: "" }
                              },
                              [_c("h2", [_vm._v("Media toevoegen")])]
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "header__close",
                                attrs: { sm1: "" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.closeUploadingFile($event)
                                      }
                                    }
                                  },
                                  [_vm._v("cancel")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { staticClass: "createDialog" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("p", [
                                    _vm._v(" " + _vm._s(_vm.uploadText) + " ")
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "fileUpload__container",
                                        attrs: {
                                          "fill-height": "",
                                          "pa-1": "",
                                          wrap: ""
                                        }
                                      },
                                      [
                                        _c("MiFileUpload", {
                                          ref: "fileUpload",
                                          attrs: {
                                            filePath: _vm.uploadedFile,
                                            clearAfterUpload: false,
                                            acceptedFileTypes:
                                              _vm.acceptedFileTypes
                                          },
                                          on: {
                                            itemDropped: function($event) {
                                              return _vm.handleItemDropped(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.isEditingImage
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.isEditingImage,
                callback: function($$v) {
                  _vm.isEditingImage = $$v
                },
                expression: "isEditingImage"
              }
            },
            [
              _c("EditImageDialog", {
                attrs: { selectedMedia: _vm.selectedMedia },
                on: {
                  closeDialog: _vm.closeEditingImage,
                  postFile: function($event) {
                    return _vm.postFile($event, true)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }