var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isShowingImageRecognitionDialog
    ? _c(
        "div",
        [
          _vm.damage
            ? _c(
                "QuestionConclusion",
                {
                  attrs: {
                    result: _vm.classificationResult,
                    damage: _vm.damage,
                    question: _vm.question,
                    buttonDisabled: _vm.disabled,
                    isDeviation: _vm.isDeviation
                  },
                  on: {
                    answerQuestion: _vm.handleAnswerQuestion,
                    removeAnswer: _vm.handleRemoveAnswer,
                    actionButtonClicked: function($event) {
                      return _vm.onOpenImageRecognitionDialog()
                    }
                  }
                },
                [
                  _vm.isDeviation
                    ? [
                        _c("template", { slot: "content_deviation-reason" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.overwriteConclusionReasonLabels[
                                  _vm.damage.casuality_anomaly_reason
                                ]
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "template",
                          { slot: "content_deviation-explanation" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.damage.casuality_anomaly_explanation
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c("template", { slot: "content_title" }, [
                    _vm._v(" " + _vm._s(_vm.question.label) + " ")
                  ]),
                  _c("template", { slot: "content_description" }, [
                    _vm._v(" " + _vm._s(_vm.classificationDescription) + " ")
                  ]),
                  _c("template", { slot: "button_text" }, [
                    _vm._v("Beeldherkenning")
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm.isDisplayingImageRecognitionDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { fullscreen: true },
                  model: {
                    value: _vm.isDisplayingImageRecognitionDialog,
                    callback: function($$v) {
                      _vm.isDisplayingImageRecognitionDialog = $$v
                    },
                    expression: "isDisplayingImageRecognitionDialog"
                  }
                },
                [
                  _vm.damageQuestions && _vm.currentDamage && !_vm.isLoading
                    ? _c(
                        "ImageRecognitionDialog",
                        {
                          attrs: {
                            currentDamageMedia: _vm.currentDamageMedia,
                            damageQuestions: _vm.damageQuestions,
                            answers: _vm.currentDamage.answers,
                            report: _vm.report,
                            damage: _vm.currentDamage
                          },
                          on: {
                            close: function($event) {
                              return _vm.closeImageRecognitionDialog()
                            }
                          },
                          model: {
                            value: _vm.isDisplayingImageRecognitionDialog,
                            callback: function($$v) {
                              _vm.isDisplayingImageRecognitionDialog = $$v
                            },
                            expression: "isDisplayingImageRecognitionDialog"
                          }
                        },
                        [
                          _c("template", { slot: "error" }, [
                            _c("h2", [
                              _vm._v(
                                "De gekozen schadesoort is nooit mijnbouw causaal."
                              )
                            ])
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }