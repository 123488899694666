import { Model } from '@/models/Model';

export class RejectReason extends Model {
  protected $name = 'RejectReason';

  protected $endpoint = '/reject-reasons';

  protected $fillable: string[] = [];

  public name?: string;

  public id?: string;

  public category?: string;

  public uuid?: string;

  public type?: string;

  public group?: string;

  public created_at?: string;

  public links?: any;

  public target_status?: string;

  public updated_at?: string;

  public ftr_class?: string;

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }
}
