import { TableHeader } from '@/components/data-table/DataTable';
import { UserPreference, UserPreferenceData, UserPreferenceEnum } from '@/models/UserPreference';
import { AxiosError, AxiosResponse } from 'axios';
import { sortBy } from 'lodash';
import ErrorHandler from '@/support/ErrorHandler';

// #region API Requests + Get Preference

export const fetchUserPreferences = async (): Promise<null | UserPreference[]> => {
  const userPreferences: null | UserPreference[] = await new UserPreference()
    .all()
    .catch((error: AxiosError) => {
      ErrorHandler.network(error);
      return null;
    });

  return userPreferences;
};

export const getUserPreference = (userPreferences: UserPreference[], key: UserPreferenceEnum): null | UserPreferenceData[] => {
  const userPreference = userPreferences.find((userPreference): boolean => userPreference.key === key);

  if (! userPreference?.data) { return null; }

  return userPreference.data;
};

export const updateUserPreference = async <TPreference extends BasicPreference>(userPreferences: TPreference[], key: UserPreferenceEnum): Promise<null | UserPreferenceData[]> => {
  if (! userPreferences) { return null; }

  const payload = {
    key,
    data: JSON.stringify(userPreferences),
  };

  const userPreference: null | AxiosResponse = await new UserPreference()
    .create(payload, false)
    .catch((error: AxiosError) => {
      ErrorHandler.network(error);
      return null;
    });

  if (! userPreference?.data?.data) { return null; }

  return userPreference.data.data;
};

// #endregion

// #region Basic Preference

export type BasicDefaultsKeyed = UserPreferenceData;

export interface BasicPreference {
  key?: string;
  order?: number;
}

export interface BasicPreferenceForEditing extends BasicPreference {
  displayNameForEditing?: string;
}

export class BasicPreferenceHelper {
  private static instance: null | BasicPreferenceHelper = null;

  public static getInstance(): BasicPreferenceHelper {
    if (BasicPreferenceHelper.instance === null) {
      BasicPreferenceHelper.instance = new BasicPreferenceHelper();
    }

    return BasicPreferenceHelper.instance;
  }

  public getCleanPreferenceKeys(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): string[] {
    preferences = this.getCleanPreferences(preferences, defaults);

    const preferenceKeys: string[] = preferences.map((preference): string => preference?.key || '');

    return preferenceKeys;
  }

  public getCleanPreferencesForEditing(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): BasicPreferenceForEditing[] {
    preferences = this.getCleanPreferences(preferences, defaults);

    const preferencesForEditing: BasicPreferenceForEditing[] = this.addDefaultDisplayName(preferences, defaults);

    return preferencesForEditing;
  }

  public getPreferenceForEditingToPreference(preferences: BasicPreferenceForEditing[]): BasicPreference[] {
    const preferencesForEditing: BasicPreferenceForEditing[] = this.removeDefaultDisplayName(preferences);

    return preferencesForEditing;
  }

  public getCleanPreferences(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): BasicPreference[] {
    preferences = this.removeWhereNotInDefaults(preferences, defaults);
    preferences = this.sortByOrder(preferences);
    preferences = this.normalizeOrder(preferences);
    preferences = this.addMissingFromDefaults(preferences, defaults);
    preferences = this.normalizeOrder(preferences);

    return preferences;
  }

  protected removeWhereNotInDefaults(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): BasicPreference[] {
    return preferences.filter((preference: BasicPreference): boolean => !! defaults[preference?.key || '']);
  }

  protected sortByOrder(preferences: BasicPreference[]): BasicPreference[] {
    return sortBy(preferences, 'order');
  }

  public normalizeOrder(preferences: BasicPreference[]): BasicPreference[] {
    return preferences.map((preference: BasicPreference, index: number): BasicPreference => ({
      ...preference,
      order: index,
    }));
  }

  protected addMissingFromDefaults(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): BasicPreference[] {
    const defaultsKeys = Object.keys(defaults);
    const preferencesKeys = preferences.map((preference): string => preference?.key || '');

    const missingDefaultsKeys = defaultsKeys.filter((defaultsKey): boolean => ! preferencesKeys.includes(defaultsKey));

    const missingDefaults = missingDefaultsKeys.map((defaultsKey: string): BasicPreference => ({
      key: defaultsKey,
      order: 0,
    }));

    return [...preferences, ...missingDefaults];
  }

  protected addDefaultDisplayName(preferences: BasicPreference[], defaults: BasicDefaultsKeyed): BasicPreferenceForEditing[] {
    return preferences.map((preference): BasicPreferenceForEditing => ({
      ...preference,
      displayNameForEditing: (defaults[preference?.key || ''] as string) || '',
    }));
  }

  protected removeDefaultDisplayName(preferences: BasicPreferenceForEditing[]): BasicPreference[] {
    return preferences.map(({ displayNameForEditing, ...rest }) => rest);
  }
}

/*
** KEEP: FOR QUICK EXAMPLE/TESTING PURPOSE
*/

// const exampleBasicDefaultsKeyed: BasicDefaultsKeyed = {
//   ZaaknummerHeader: 'Zaaknummer',
//   PrioHeader: 'Prio',
//   GeplandOpHeader: 'Gepland op',
//   SomethingHeader: 'Iets',
// }

// const exampleBasicPreferences: BasicPreference[] = [
//   {
//     key: 'PrioHeader',
//     order: 2,
//   },
//   {
//     key: 'ZaaknummerHeader',
//     order: 1,
//   },
//   {
//     key: 'Blieh',
//     order: 4,
//   },
//   {
//     key: 'GeplandOpHeader',
//     order: 3,
//   }
// ];

// console.log('exampleBasicDefaultsKeyed', exampleBasicDefaultsKeyed);
// console.log('exampleBasicPreferences', exampleBasicPreferences);
// console.log('exampleBasicCleanPreferences', BasicPreferenceHelper.getInstance().getCleanPreferences(exampleBasicPreferences, exampleBasicDefaultsKeyed));
// console.log('exampleBasicCleanPreferenceKeys', BasicPreferenceHelper.getInstance().getCleanPreferenceKeys(exampleBasicPreferences, exampleBasicDefaultsKeyed));
// console.log('exampleBasicCleanPreferencesForEditing', BasicPreferenceHelper.getInstance().getCleanPreferencesForEditing(exampleBasicPreferences, exampleBasicDefaultsKeyed));

// #endregion

// #region TableHeader Preference

export type TableHeaderDefaultsKeyed = Record<string, TableHeader>;

export type TableHeaderPreference = BasicPreference

export type TableHeaderPreferenceForReading = TableHeader;

export interface TableHeaderPreferenceForEditing extends TableHeaderPreference {
  displayNameForEditing?: string;
}

export class TableHeaderPreferenceHelper extends BasicPreferenceHelper {
  private static tableHeaderInstance: null | TableHeaderPreferenceHelper = null;

  public static getInstance(): TableHeaderPreferenceHelper {
    if (TableHeaderPreferenceHelper.tableHeaderInstance === null) {
      TableHeaderPreferenceHelper.tableHeaderInstance = new TableHeaderPreferenceHelper();
    }

    return TableHeaderPreferenceHelper.tableHeaderInstance;
  }

  public getCleanPreferenceKeys(preferences: TableHeaderPreference[], defaults: TableHeaderDefaultsKeyed): string[] {
    return super.getCleanPreferenceKeys(preferences, defaults);
  }

  public getCleanPreferencesForEditing(preferences: TableHeaderPreference[], defaults: TableHeaderDefaultsKeyed): TableHeaderPreferenceForEditing[] {
    return super.getCleanPreferencesForEditing(preferences, defaults);
  }

  public getCleanPreferencesForReading(preferences: TableHeaderPreference[], defaults: TableHeaderDefaultsKeyed): TableHeaderPreferenceForReading[] {
    preferences = this.getCleanPreferences(preferences, defaults);

    const preferencesForReading: TableHeaderPreferenceForReading[] = preferences.map((preference): TableHeaderPreferenceForReading => defaults[preference?.key || '']);

    return preferencesForReading;
  }

  public getCleanPreferences(preferences: TableHeaderPreference[], defaults: TableHeaderDefaultsKeyed): TableHeaderPreference[] {
    return super.getCleanPreferences(preferences, defaults);
  }

  public getPreferenceForEditingToPreference(preferences: TableHeaderPreferenceForEditing[]): TableHeaderPreference[] {
    return super.getPreferenceForEditingToPreference(preferences);
  }

  public normalizeOrder(preferences: TableHeaderPreference[]): TableHeaderPreference[] {
    return super.normalizeOrder(preferences);
  }

  protected addDefaultDisplayName(preferences: TableHeaderPreference[], defaults: TableHeaderDefaultsKeyed): TableHeaderPreferenceForEditing[] {
    return preferences.map((preference): TableHeaderPreferenceForEditing => ({
      ...preference,
      displayNameForEditing: defaults[preference?.key || '']?.text || defaults[preference?.key || '']?.value || '',
    }));
  }
}

/*
** KEEP: FOR QUICK EXAMPLE/TESTING PURPOSE
*/

// const exampleTableHeaderDefaultsKeyed: TableHeaderDefaultsKeyed = {
//   ZaaknummerHeader: {
//     text: 'Header: Zaaknummer',
//     value: ''
//   },
//   PrioHeader: {
//     text: 'Header: Prio',
//     value: ''
//   },
//   GeplandOpHeader: {
//     text: 'Header: Gepland op',
//     value: ''
//   },
// };

// const exampleTableHeaderPreferences: TableHeaderPreference[] = [
//   {
//     key: 'PrioHeader',
//     order: 2,
//   },
//   {
//     key: 'ZaaknummerHeader',
//     order: 1,
//   },
//   {
//     key: 'Blieh',
//     order: 4,
//   },
//   {
//     key: 'GeplandOpHeader',
//     order: 3,
//   }
// ];

// console.log('exampleTableHeaderDefaultsKeyed', exampleTableHeaderDefaultsKeyed);
// console.log('exampleTableHeaderPreferences', exampleTableHeaderPreferences);
// console.log('exampleTableHeaderCleanPreferences', TableHeaderPreferenceHelper.getInstance().getCleanPreferences(exampleTableHeaderPreferences, exampleTableHeaderDefaultsKeyed));
// console.log('exampleTableHeaderCleanPreferenceKeys', TableHeaderPreferenceHelper.getInstance().getCleanPreferenceKeys(exampleTableHeaderPreferences, exampleTableHeaderDefaultsKeyed));
// console.log('exampleTableHeaderCleanPreferencesForEditing', TableHeaderPreferenceHelper.getInstance().getCleanPreferencesForEditing(exampleTableHeaderPreferences, exampleTableHeaderDefaultsKeyed));
// console.log('exampleTableHeaderCleanPreferencesForReading', TableHeaderPreferenceHelper.getInstance().getCleanPreferencesForReading(exampleTableHeaderPreferences, exampleTableHeaderDefaultsKeyed));

// #endregion
