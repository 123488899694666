export default {
  auth: {
    login: 'Inloggen',
    logout: 'Uitloggen',
    no_match: 'De wachtwoorden komen niet overeen',
    forgot: {
      title: 'Wachtwoord vergeten',
      instruction: 'Voer je e-mailadres in en ontvang een mail om een nieuw wachtwoord aan te maken.',
      confirmation: 'We hebben je een nieuw wachtwoord gemaild.',
      submit: 'Opvragen',
    },
    reset: {
      title: 'Wachtwoord resetten',
      instruction: 'Maak een nieuw wachtwoord aan',
      submit: 'reset',
    },
    submitted: {
      success: 'Gelukt!',
      successIntruction: 'Wachtwoord is succesvol aangepast!',
      sent: 'We hebben een mail gestuurd aan {email}.',
      again: 'Log opnieuw in',
    },
    required: {
      email: 'Ongeldige email',
      password: 'Wachtwoord is verplicht',
      default: 'Verplicht',
      name: 'Ongeldige naam',
      url: 'Ongeldige url',
      number: 'Alleen cijfers toegestaan',
      passwordRules: {
        lowercase: 'Eén kleine letter vereist',
        uppercase: 'Eén hoofdletter vereist',
        length: 'Minimaal 8 karakters',
        number: 'Eén nummer vereist',
        special: 'Eén speciale character vereist',
        match: 'Wachtwoorden moeten overeen komen',
      },
    },
  },
  actions: {
    back: 'Terug',
    next: 'Volgende',
    add: 'Toevoegen',
    edit: 'Aanpassen',
    save: 'Opslaan',
    create: 'Aanmaken',
    close: 'Sluiten',
    esc: 'esc',
    cancel: 'Annuleren',
  },
  labels: {
    hourType: 'Uursoort',
    project: 'Project',
    description: 'Toelichting',
    employee: 'Medewerker',
    client: 'Cliënt',
  },
  event: {
    title: 'Uren registratie',
    dialog: {
      create: 'Schrijf uren',
      edit: 'Pas aan',
      remove: 'Verwijder',
    },
  },
  hourType: {
    title: 'Uursoort | Uursoorten',
    dialog: {
      create: 'Uursoort aanmaken',
      edit: 'Uursoort aanpassen',
      remove: 'Uursoort verwijder',
    },
  },
  employeeType: {
    title: 'Medewerker type | Medewerker types',
    dialog: {
      create: 'Medewerker type aanmaken',
      edit: 'Medewerker type aanpassen',
      remove: 'Medewerker type verwijder',
    },
  },
  settings: {
    subscriptionTypes: 'Abonnement soorten',
    locations: 'Vestiging | Vestigingen',
    hourTypes: 'Uursoort | Uursoorten',
    costTypes: 'Kostensoort | Kostensoorten',
    projectTypes: 'Project soort | Project soorten',
    ledgers: 'Grootboekrekening | Grootboekrekeningen',
    vatCodes: 'BTW Code | BTW Codes',
    officeCosts: 'Kantoor kosten | Kantoor kosten',
    stamgegevens: 'Stamgegeven | Stamgegevens',
    employeeTypes: 'Medewerker Type | Medewerker Types',
    ratesManagement: 'Tarieven Beheer | Tarieven Beheer',
    calendarSettings: {
      title: 'Kalender instellingen',
      weekends: 'Weekeinden',
      first_day: 'Eerste dag van de week',
      calendar_default_view: 'Standaard weergave urenschrijven',
      calendar: 'Kalender',
      list: 'Lijst',
      slot_duration: 'Schrijf tijd',
      end_with_today: 'Eindig de week met vandaag (laat de 6 afgelopen dagen zien)',
    },
  },
  user: {
    add: '{item} toevoegen',
  },
  table: {
    found: '{total} {name} gevonden',
    perPage: '{name} per pagina:',
    noData: 'Geen gegevens beschikbaar',
    filter: 'Filter op {name}:',
  },
  tableHeaders: {
    name: {
      title: 'Naam',
      first: 'Voornaam',
      middle: 'Tussenvoegsel',
      last: 'Achternaam',
    },
    age: {
      title: 'Leeftijd',
    },
    email: {
      title: 'Email',
    },
    phone: {
      title: 'Telefoonnummer',
    },
    created_at: {
      title: 'Aangemaakt op',
    },
    price: {
      title: 'Prijs',
    },
    code: {
      title: 'Code',
    },
  },
  navigation: {
    reports: 'Rapporten',
    hourRegistration: 'Urenschrijven',
    crm: 'CRM',
    clients: 'Cliënten',
    clientGroups: 'Cliëntgroepen',
    projects: 'Projecten',
    subscriptions: 'Abonnementen',
    employees: 'Medewerkers',
    billing: 'Facturatie',
    billingCost: 'Facturatie OHW & Kosten',
    manualBilling: 'Handmatige factuur',
    accountancy: 'Boekhouding',
    invoiceHistory: 'Factuur geschiedenis',
    mailingList: 'Verzendlijst',
    debtors: 'Debiteuren',
    unreadMailPerUser: 'Openstaande posten per medewerker',
    outstandingAdvances: 'Openstaande voorschotten',
    reporting: 'Rapportage',
    latourMatrix: 'Latour matrix',
    clientFeeMatrix: 'Cliënt loon matrix',
    revenuePrognosis: 'Omzet prognose',
    revenueBreakdown: 'Omzet breakdown',
    timeSheet: 'Urenstaten',
    acquisitionMatrix: 'Acquisitie matrix',
    tenants: 'Tenants',
  },
  tenant: {
    title: 'Tenant | Tenants',
    dialog: {
      status: 'Tenant status',
      epona: 'Epona DMSFL Token',
      header: {
        general: 'Algemeen',
        naw: 'NAW',
        billing: 'Facturatie',
      },
    },
    status: {
      free: 'Gratis',
      active: 'Actief',
      inactive: 'Non-actief',
    },
  },
  client: {
    title: 'Cliënt | Cliënten',
  },
  clientGroup: {
    title: 'Cliëntgroep | Cliëntgroepen',
  },
  project: {
    title: 'Project | Projecten',
  },
  employee: {
    title: 'Medewerker | Medewerkers',
  },
  subscription: {
    title: 'Abonnement | Abonnementen',
  },
  profile: {
    title: 'Profiel | Profielen',
    setting: 'Instelling | Instellingen',
    mine: 'Mijn profiel',
    standard: 'Standard Informatie',
    contact: {
      information: 'Contact Informatie',
      person: 'Contactpersoon',
      number: 'Contact nummer',
    },
    dob: 'Geboortedatum',
    name: 'Naam',
    firstName: 'Voornaam',
    middleName: 'Middelnaam',
    lastName: 'Achternaam',
    email: 'Email',
    phone: 'Telefoonnummer',
    website: 'Website',
    reportType: 'Rapport type',
    reportTag: 'Rapport tag',
    caseNumber: 'Zaaknummer',
    password: {
      title: 'Nieuw wacthwoord',
      forgot: 'Wachtwoord vergeten?',
      current: 'Huidig wachtwoord',
      confirm: 'Wacthwoord bevestigen',
      change: 'Wachtwoord wijzigen',
      save: 'Wachtwoord opslaan',
      saved: 'Wachtwoord opgeslagen',
    },
    group: 'Groep',
    login: 'Inloggen als',
    address: 'Adres',
    street: 'Straatnaam',
    housenumber: 'Huisnummer',
    addition: 'Toevoeging',
    postcode: 'Postcode',
    city: 'Stad',
    budgetHours: 'Budget uren',
    budgetMoney: 'Budget geld',
    code: 'Code',
    color: 'Kleur',
    invoiceName: 'Factuur naam',
    calculationModel: 'Calculatie model',
    responsibleEmployee: 'Medewerker',
  },
  avatar: {
    title: 'Profiel foto',
    change: 'Wijzig foto',
  },
  general: {
    back: 'Terug',
    search: 'Zoek | Zoeken',
    cancel: 'annuleren',
    close: 'sluiten',
    yes: 'Ja',
    no: 'Nee',
  },
  time: {
    year: 'jaar | jaren',
    month: 'maand | maanden',
    week: 'week | weken',
    day: 'dag | dagen',
    hour: 'uur | uren',
    minute: 'minuut | minuten',
  },
  inbox: {
    title: '',
    menu: {
      inbox: 'Inbox',
      starred: 'Uitgelicht',
      sent: 'Verzonden',
      draft: 'Concept',
      create: 'Nieuwe e-mail',
      folders: 'Mappen',
    },
    actions: {
      delete: 'Verwijderen',
      reply: 'Beantwoorden',
      archive: 'Archiveren',
      sent: 'Versturen',
    },
    contact: 'contact | contacten',
  },
  createClient: {
    vatNumber: 'Vat nummer',
    iban: 'Iban',
    chamberOfCommerce: 'Kamer van Koophandel',
    contactType: 'Contact type',
    debtor: 'Deze client is ook een debiteur',
  },
  createTenant: {
    logo: 'Logo',
    url: 'URL',
  },
  createProject: {
    calculationModel: 'Calculation model',
    budgetHours: 'Budget uren',
    budgetMoney: 'Budget geld',
    status: 'Status',
    code: 'Code',
    debtor: 'Debiteur',
  },
  form: {
    formFields: {
      avatar: 'Avatar',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      middleName: 'Tussenvoegsel',
      name: 'Naam',
      invoiceName: 'Factuur naam',
      website: 'Website',
      phone: 'Telefoon',
      username: 'Gebruikersnaam',
      email: 'Email',
      password: 'Wachtwoord',
      confirmPassword: 'Bevestig wachtwoord',
      code: 'Code',
      color: 'Project kleur',
      address: 'Adres',
      street: 'Straatnaam',
      housenumber: 'Huisnummer',
      addition: 'Toevoeging',
      postcode: 'Postcode',
      city: 'Stad',
      status: 'Status',
      createdAt: 'Aangemaakt op',
      planned: 'Gepland',
      created: 'Aangemaakt',
      send: 'Opgestuurd',
      preChecked: 'Voorgecontroleerd',
      accepted: 'Geaccepteerd',
      updated: 'Geupdate',
      deadline: 'Deadline',
      slowed: 'Rapport vertraagd',
      dateConfirm: 'Datum bevestiging',
      expert: 'Naam Expert',
      calculationModel: 'Calculatie model',
      calculationVat: 'BTW percentage',
      description: 'Omschrijving',
      time: 'Tijd',
      submissionDate: 'Datum aanvraag',
      token: 'MFA token',
      country: {
        title: 'Land',
        nl: 'Nederland',
        en: 'Engeland',
        de: 'Duitsland',
      },
    },
    optional: 'Optioneel',
    fieldsRequiredText: 'Alle velden zijn verplicht',
  },
  languages: {
    title: 'Taal',
    nl: 'Nederlands',
    en: 'Engels',
    de: 'Duits',
  },
  createDialog: {
    header: {
      create: 'Aanmaken',
      overview: 'Overzicht',
      finished: 'Voltooid',
    },
    body: {
      title: {
        create: 'Maak een {role} aan',
        finished: '{role} is aangemaakt',
        information: '{role} informatie',
        billingAddress: 'Factuuradres',
        postAddress: 'Postadres',
        officeAddress: 'Bezoekadres',
        address: 'Adres',
        upload: 'Bijlage toevoegen',
      },
      description: {
        confirm: 'Bevestig of alle informatie juist is voor de nieuwe {role}',
        finished: 'Bekijk hieronder de aangemaakte {role}',
      },
      upload: 'Uploaden',
    },
    actions: {
      reset: 'Maak nog een {role} aan',
    },
  },
  dialogOptions: {
    item: {
      client: 'Cliënt',
      subscriptionType: 'Abonnementsoort',
    },
    confirmation: 'Bevestiging',
    delete: 'Weet je zeker dat je dit {item} wilt verwijderen? | Weet je zeker dat je deze {item} wilt verwijderen?',
    deleteMany: 'Weet je zeker dat je deze {item} wilt verwijderen?',
    lesson: 'Weet je zeker dat je deze {item} wilt kiezen?',
    save: '{item} is opgeslagen',
    update: 'Weet je zeker dat je dit {item} wilt update? | Weet je zeker dat je deze {item} wilt updaten?',
    button: {
      delete: 'Verwijder',
      cancel: 'Nee dat wil ik niet',
      ok: 'Oke',
    },
  },
  bugReport: {
    title: 'Bug',
    header: 'Meld een bug',
    field: {
      title: 'Titel',
      description: 'Omschrijving',
      screenshot: 'Afbeelding',
    },
    create: {
      title: 'Voeg een titel toe',
      description: 'Voeg een omschrijving toe',
      descriptionExplanation: 'Hoe is deze bug onstaan? Welke stappen heb je doorlopen? Welke knoppen heb je geklikt?',
      screenshot: 'Schermafbeelding',
      submitBug: 'Bug melden',
      created: 'Bug melding is aangemaakt.',
      downloadScreenshot: 'Download de afbeelding',
      uploadScreenshot: 'Upload de gedownloade afbeelding in de link',
    },
    alert: {
      noIdSet: 'Monday Board Id of Group Id is niet ingesteld, kan geen puls genereren',
      warning: 'Waarschuwing',
    },
    reportContent: {
      description: 'Omschrijving',
      localStorage: 'Lokale opslag',
      stacktrace: 'Stacktrace',
      message: 'Bericht',
      file: 'Bestand',
      line: 'Regel',
      column: 'Kolom',
      error: 'Error',
    },
  },
  calculationModels: {
    fixed: 'Vaste prijs',
    internal: 'Intern',
    costbased: 'Nacalculatie',
  },
  errors: {
    loading: {
      description: 'Er is een probleem opgetreden bij het laden van de vereiste informatie.',
      suggestion: 'Probeer het later opnieuw of maak via sCoolHelp een ticket aan.',
    },
    notFound: {
      description: 'De door jouw opgevraagde pagina is niet gevonden.',
      suggestion: '404',
      url: 'Controleer de URL voor eventuele typefouten',
      error: '404 ERROR.',
      back: 'ga naar home',
      oops: 'Oeps :(',
      or: 'of',
      contact: 'Neem contact met ons op via',
    },
  },
  exact_online: {
    title: 'Exact Online',
    guid: 'Exact ID',
    log: 'Log',
  },
};
