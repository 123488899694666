import { QuestionBase } from '@/models/question-models/question-base';

export class TextareaQuestion extends QuestionBase<string> {
  controlType = 'textarea';

  type: string;

  constructor(options: any = {}) {
    super(options);
    this.type = options.type || '';
  }
}
