import Vue from 'vue';

// @ts-ignore
import FullCalendar from '@fullcalendar/vue';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import '@fullcalendar/list/main.min.css';
import '@fullcalendar/timeline/main.min.css';
import '@fullcalendar/resource-timeline/main.min.css';

import AllowedToView from '@/components/allowed-to-view/AllowedToView.vue';
import ApprovalBar from '@/components/approval-bar/ApprovalBar.vue';
import ApprovalDialog from '@/components/dialog/approval-dialog/ApprovalDialog.vue';
import Attachments from '@/components/attachments/Attachments.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import CreateDialogHeader from '@/components/dialog/header/CreateDialogHeader.vue';
import DamagePanel from '@/components/damage-panel/DamagePanel.vue';
import DataTable from '@/components/data-table/DataTable.vue';
import DateFilter from '@/components/filters/date-filter/DateFilter.vue';
import DateFilterDialog from '@/components/filters/period-filter/DateFilterDialog.vue';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import FullscreenDialogLayout from '@/components/dialog/FullscreenDialogLayout/FullscreenDialogLayout.vue';
import DraggableContent from '@/components/draggable-content/DraggableContent.vue';
import DynamicForm from '@/components/dynamic-form/DynamicForm.vue';
import EditImageDialog from '@/components/dialog/edit-image-dialog/EditImageDialog.vue';
import ExpertAvailability from '@/components/Availability/ExpertAvailability.vue';
import FileUpload from '@/components/file-upload/FileUpload.vue';
import LanguageSwitch from '@/components/language-switch/LanguageSwitch.vue';
import List from '@/components/List/List.vue';
import LoaderCard from '@/components/loader-card/LoaderCard.vue';
import MediaViewer from '@/components/media-viewer/MediaViewer.vue';
import ManagementClassFilter from '@/components/filters/management-class-filter/ManagementClassFilter.vue';
import MapAreaFilter from '@/components/filters/map-area-filter/MapAreaFilter.vue';
import MiButton from '@/components/mi/MiButton.vue';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload.vue';
import MiPassword from '@/components/mi-password/MiPassword.vue';
import MoreInfoDialog from '@/components/dialog/more-info-dialog/MoreInfoDialog.vue';
import NoDataCard from '@/components/NoDataCard.vue';
import OpnameVariantFilter from '@/components/filters/opname-variant-filter/OpnameVariantFilter.vue';
import RejectReason from '@/components/reject-reason/RejectReason.vue';
import RepairDialog from '@/components/dialog/repair-dialog/RepairDialog.vue';
import ReportDatatable from '@/components/ReportDatatable/ReportDatatable.vue';
import ReportDepartmentFilter from '@/components/filters/report-department-filter/ReportDepartmentFilter.vue';
import ReportExpertFilter from '@/components/filters/report-expert-filter/ReportExpertFilter.vue';
import ReportHandlerFilter from '@/components/filters/report-handler-filter/ReportHandlerFilter.vue';
import ReportOrganizationFilter from '@/components/filters/report-organization-filter/ReportOrganizationFilter.vue';
import ReportSkillFilter from '@/components/filters/report-skill-filter/ReportSkillFilter.vue';
import ReportStatusFilter from '@/components/filters/report-status-filter/ReportStatusFilter.vue';
import ReportMediatorFilter from '@/components/filters/report-mediator-filter/ReportMediatorFilter.vue';
import ReportTagFilter from '@/components/filters/report-tag-filter/ReportTagFilter.vue';
import ReportTags from '@/components/report-tags/ReportTags.vue';
import ReportTagsSkills from '@/components/report-tags-skills/ReportTagsSkills.vue';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';
import Sidemenu from '@/components/sidemenu/Sidemenu.vue';
import Skills from '@/components/skills/Skills.vue';
import StatisticsNumberWidget from '@/components/dashboard/StatisticsNumberWidget/StatisticsNumberWidget.vue';
import StatisticsProgressWidget from '@/components/dashboard/StatisticsProgressWidget/StatisticsProgressWidget.vue';
import TreeView from '@/components/tree-view/TreeView.vue';
import UserFilter from '@/components/user-filter/UserFilter.vue';
import ValidationWarning from '@/components/validation-warning/ValidationWarning.vue';
import Questionnaire from '@/components/Questionnaire/Questionnaire.vue';
import WeekStatisticsView from '@/components/week-statistics-view/WeekStatisticsView.vue';
import WorkFlowFilter from '@/components/filters/work-flow-filter/WorkFlowFilter.vue';
import QuestionConclusionDialog from '@/components/QuestionConclusionDialog/QuestionConclusionDialog.vue';

// #region VUE UI IMPORTS
import AAutocompleteInput from '@/components/AAutocompleteInput/AAutocompleteInput.vue';
import AButton from '@/components/AButton/AButton.vue';
import AButtonContent from '@/components/AButtonContent/AButtonContent.vue';
import ACard from '@/components/ACard/ACard.vue';
import ADialogLayout from '@/components/ADialogLayout/ADialogLayout.vue';
import ALoader from '@/components/ALoader/ALoader.vue';
import AMessageDialog from '@/components/AMessageDialog/AMessageDialog.vue';
import AMessageDialogIcon from '@/components/AMessageDialogIcon/AMessageDialogIcon.vue';
import ASelectInput from '@/components/ASelectInput/ASelectInput.vue';
import AStatefulForm from '@/components/AStatefulForm/AStatefulForm.vue';
import AStatefulFormForFilters from '@/components/AStatefulFormForFilters/AStatefulFormForFilters.vue';
import ATextInput from '@/components/ATextInput/ATextInput.vue';
import ATitle from '@/components/ATitle/ATitle.vue';
import ATableLink from '@/components/ATableLink/ATableLink.vue';
import LabelValue from '@/components/LabelValue/LabelValue.vue';

import AModal, { AModalOptions } from '@/services/AModal';
// #endregion

Vue.component('FullCalendar', FullCalendar);

Vue.component('AllowedToView', AllowedToView);
Vue.component('ApprovalBar', ApprovalBar);
Vue.component('ApprovalDialog', ApprovalDialog);
Vue.component('Attachments', Attachments);
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.component('CreateDialogHeader', CreateDialogHeader);
Vue.component('DamagePanel', DamagePanel);
Vue.component('DataTable', DataTable);
Vue.component('DateFilter', DateFilter);
Vue.component('DateFilterDialog', DateFilterDialog);
Vue.component('DefaultDialog', DefaultDialog);
Vue.component('DraggableContent', DraggableContent);
Vue.component('DynamicForm', DynamicForm);
Vue.component('EditImageDialog', EditImageDialog);
Vue.component('ExpertAvailability', ExpertAvailability);
Vue.component('FileUpload', FileUpload);
Vue.component('LanguageSwitch', LanguageSwitch);
Vue.component('List', List);
Vue.component('LoaderCard', LoaderCard);
Vue.component('MediaViewer', MediaViewer);
Vue.component('ManagementClassFilter', ManagementClassFilter);
Vue.component('MapAreaFilter', MapAreaFilter);
Vue.component('MiButton', MiButton);
Vue.component('MiFileUpload', MiFileUpload);
Vue.component('MiPassword', MiPassword);
Vue.component('MoreInfoDialog', MoreInfoDialog);
Vue.component('NoDataCard', NoDataCard);
Vue.component('OpnameVariantFilter', OpnameVariantFilter);
Vue.component('RejectReason', RejectReason);
Vue.component('RepairDialog', RepairDialog);
Vue.component('ReportDatatable', ReportDatatable);
Vue.component('ReportDepartmentFilter', ReportDepartmentFilter);
Vue.component('ReportExpertFilter', ReportExpertFilter);
Vue.component('ReportHandlerFilter', ReportHandlerFilter);
Vue.component('ReportOrganizationFilter', ReportOrganizationFilter);
Vue.component('ReportSkillFilter', ReportSkillFilter);
Vue.component('ReportMediatorFilter', ReportMediatorFilter);
Vue.component('ReportStatusFilter', ReportStatusFilter);
Vue.component('ReportTagFilter', ReportTagFilter);
Vue.component('ReportTags', ReportTags);
Vue.component('ReportTagsSkills', ReportTagsSkills);
Vue.component('ReportTypeFilter', ReportTypeFilter);
Vue.component('Sidemenu', Sidemenu);
Vue.component('Skills', Skills);
Vue.component('StatisticsNumberWidget', StatisticsNumberWidget);
Vue.component('StatisticsProgressWidget', StatisticsProgressWidget);
Vue.component('TreeView', TreeView);
Vue.component('UserFilter', UserFilter);
Vue.component('ValidationWarning', ValidationWarning);
Vue.component('Questionnaire', Questionnaire);
Vue.component('FullscreenDialogLayout', FullscreenDialogLayout);
Vue.component('WeekStatisticsView', WeekStatisticsView);
Vue.component('WorkFlowFilter', WorkFlowFilter);
Vue.component('QuestionConclusionDialog', QuestionConclusionDialog);

// #region VUE UI EXPORTS
Vue.component('AAutocompleteInput', AAutocompleteInput);
Vue.component('AButton', AButton);
Vue.component('AButtonContent', AButtonContent);
Vue.component('ACard', ACard);
Vue.component('ADialogLayout', ADialogLayout);
Vue.component('ALoader', ALoader);
Vue.component('AMessageDialog', AMessageDialog);
Vue.component('AMessageDialogIcon', AMessageDialogIcon);
Vue.component('AModal', AModal);
Vue.component('ASelectInput', ASelectInput);
Vue.component('AStatefulForm', AStatefulForm);
Vue.component('AStatefulFormForFilters', AStatefulFormForFilters);
Vue.component('ATextInput', ATextInput);
Vue.component('ATitle', ATitle);
Vue.component('ATableLink', ATableLink);
Vue.component('LabelValue', LabelValue);
// #endregion

// #region VUE UI PLUGINS
declare module 'vue/types/vue' {
  interface Vue {
    $showModal: <T>(component: VueConstructor<Vue>, options?: AModalOptions | undefined) => Promise<T>;
    $closeModal: (data: any) => void;
  }
}
// #endregion
