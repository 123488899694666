import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import AModalService, { AModalListeners, AModalOptions, AModalProps } from '@/services/AModal';

@Component<AModal>({})
export default class AModal extends Vue {
  @Prop({ default: () => ({}) })
  component!: VueConstructor<Vue>;

  @Prop({ default: () => ({}) })
  options!: AModalOptions;

  protected props: AModalProps = {};

  protected listeners: AModalListeners = {};

  protected isMounting = true;

  public mounted(): void {
    window.addEventListener('keydown', this.onKeyDownEscape);
    this.props = this.options?.props || {};
    this.listeners = this.options?.listeners || {};

    this.isMounting = false;
  }

  public unmounted(): void {
    window.removeEventListener('keydown', this.onKeyDownEscape);
  }

  public onKeyDownEscape(event: KeyboardEvent): void {
    event = event || window.event;
    const pressedEscapse = event.keyCode === 27;
    if (pressedEscapse) {
      if (this.props?.persistent) { return; }
      AModalService.close();
    }
  }

  public onOverlayClick(): void {
    if (this.props?.persistent) { return; }
    AModalService.close();
  }

  public close(): void {
    if (this.props?.persistent) { return; }
    AModalService.close();
  }

  @Watch('$route')
  public onRouteChanged(): void {
    this.close();
  }
}
