import { debounce, cloneDeep, isArray, isObject } from 'lodash';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import AAbstractInput from '@/components/AAbstractInput/AAbstractInput';
import { Model } from '@/models/Model';

@Component<ASelectInput>({})
export default class ASelectInput extends AAbstractInput {
  // #region @VModel
  // #endregion

  // #region @PropSyncs
  // #endregion

  // #region @Props

  /**
   * Usefull to enable cache-items when you only have a few results which you only
   *  need to retreive once
   */
  @Prop()
  protected cacheItems!: boolean | string;

  @Prop()
  protected chips!: boolean | string;

  @Prop()
  protected deletableChips!: boolean | string;

  @Prop()
  protected disabled!: boolean | string;

  /**
   * when you don't want to use model and just have a simple list of options
   *   , you can use the items -> item-text and item-search should still work!
   */
  @Prop()
  protected items!: any[];

  /** Name of API filter parameter: e.g. filters[search]={query}  */
  @Prop({ default: 'search' })
  protected itemSearch!: string;

  @Prop()
  protected itemText!: number | string | Function;

  @Prop()
  protected itemValue!: boolean | number | string;

  /**
   * when you have a default value(s), you want to preload the values.
   * For this we can do a special initial call to server
   * Defaults to id which results in /users?filters[id]={id},{id}
   */
  @Prop({ default: 'id' })
  protected internalItemValueFilter!: string;

  @Prop()
  protected multiple!: boolean | string;

  @Prop()
  protected model!: Model;

  @Prop()
  protected noResultText!: string;

  @Prop({ default: 15 })
  protected searchLimit!: number;

  // #endregion

  // #region @Refs

  @Ref()
  protected vSelectInput!: HTMLFormElement;

  // #endregion

  // #region Class properties

  protected internalItems: AInputableSelect[] = [];

  protected doSearch = debounce(this.handleDoSearch, 300);

  protected isLoading = false;

  /** used with internalCacheItems */
  protected loadedCachedItems = false;

  protected searchString = '';

  // #endregion

  // #region Lifecycle Hooks

  protected created(): void {
    this.onCreated();
  }

  // #endregion

  // #region Lifecycle Hooks: Handlers

  protected async onCreated(): Promise<void> {
    if (this.internalMultiple && this.internalValue !== null && ! Array.isArray(this.internalValue)) {
      throw new Error('You cannot set a non-array v-model for <ASelectInput> when you have the `multiple` property set');
    }

    if (this.hasModel && ! this.noInitialValues()) {
      this.loadCurrentSelection();
    } else {
      this.internalItems = this.items || [];
    }
  }

  // #endregion

  // #region Class methods

  /**
   * Situation
   * a) User searches for something and selects it
   * b) Now it shows it as selected in the SelectInput
   * c) Now the user searches for something else
   * d) While typing the currently selected value disappears
   * e) The user is confused why the currently selected item suddenly disappears
   * f) The user changes the search
   * g) Suddenly the currently selected item reappears
   * h) This is unexpected behaviour and causes bad UX
   *
   * Solution
   * a) Convert selected to models where possible (might be only keyvalues)
   * b) Remove from items the selected models (to prevent duplicates in the search-results)
   * c) Prepend selected to items
   */
  protected addSelectedItemsToItems(items: AInputableSelect[]): AInputableSelect[] {
    const selectedModels = this.replaceSelectedWithModels(this.internalValue, this.internalItems);

    const itemsExclSelectedModels = this.excludeSelectedModelsFromItems(items, selectedModels);

    return [...selectedModels, ...itemsExclSelectedModels];
  }

  protected excludeSelectedModelsFromItems(items: AInputableSelect[], selected: AInputableSelect[]): AInputableSelect[] {
    const itemValueKey = typeof this.internalItemValue !== 'boolean'
      ? this.internalItemValue
      : (this.internalItemValue ? 1 : 0);

    // Selected is a list with models/objects, simplify it to a list with keyValues
    const selectedKeyValues = selected.map((selectedItem) => (selectedItem as Record<string, unknown>)[itemValueKey]);

    // Remove items that already are selected
    return items.filter((item) => ! selectedKeyValues.includes((item as Record<string, unknown>)[itemValueKey]));
  }

  protected handleBlurEvent(): void {
    if (this.internalMultiple) {
      this.loadCurrentSelection();
    }
  }

  protected handleDoSearch(query: string|null): void {
    query = query || '';

    this.searchModels(query);
  }

  protected handleChangeEvent(): void {
    if (this.internalMultiple) { return; }

    this.vSelectInput.blur();
  }

  /**
   * Initial value can be number/string/objects or array of previous values
   * When we search via API we want to preload ONLY those values
   */
  protected async loadCurrentSelection(): Promise<void> {
    if (! this.internalValue) { return; }

    if (! Array.isArray(this.internalValue)) {
      this.preloadSingleValue();

      return;
    }

    this.preloadMultipleValues();
  }

  protected loadOnFocus(): void {
    if (! this.hasModel) { return; }

    // when value already set we still want to load all items initially
    if (this.internalCacheItems || ! this.loadedCachedItems) {
      this.loadedCachedItems = true;
      this.searchModels(''); // don't use searchString since that has the default initial value
    } else if (! this.searchString) {
      // when we focus and have not yet searched, we want results immediately
      this.searchModels(this.searchString);
    }
  }

  protected noInitialValues(): boolean {
    return ! this.internalValue
      || (
        Array.isArray(this.internalValue)
        && this.internalValue.length === 0
      );
  }

  protected onBottomOfItemListVisibile(entries: boolean): void {
    // Early return if
    if (! entries) { return; } // bottom not reached

    this.searchModelsForNextPage();
  }

  protected queryModel(): Model {
    return cloneDeep(this.model);
  }

  protected replaceSelectedWithModels(selected: AInputableSelect | AInputableSelect[], items: AInputableSelect[]): AInputableSelect[] {
    // If selected isn't an array, convert it into one, this reduces the need for if statements after this step
    const selectedArray = isArray(selected) ? selected : [selected];

    const itemValueKey = typeof this.internalItemValue !== 'boolean'
      ? this.internalItemValue
      : (this.internalItemValue ? 1 : 0);

    // For each selected item
    const selectedModelArrayRaw = selectedArray.map((selectedItem) => {
      // Check if the item isn't a null-object and if it's already an object/model
      if (selectedItem && isObject(selectedItem)) {
        // If so, it's already as it should be
        return selectedItem;
      }

      // If it's not an object/model, try to look it up in the list of available items
      return items.find((item) => (item as Record<string, unknown>)[itemValueKey] === selectedItem);
    });

    // Filter away items that aren't an object/model
    return selectedModelArrayRaw.filter((selectedItem) => !! selectedItem) as AInputableSelect[];
  }

  protected selectedModelAlreadyLoaded(): boolean {
    if (this.noInitialValues()) {
      return false;
    }

    let modelAlreadyLoaded = false;
    // do any here because gives nasty warning... AInputableSelect
    this.internalItems.forEach((item: any) => {
      if (
        typeof item === 'object'
        && item[`${this.internalItemValue}`] === this.internalValue[`${this.internalItemValue}`]
      ) {
        modelAlreadyLoaded = true;
      }
    });

    return modelAlreadyLoaded;
  }

  protected valueDifferent(query: string): boolean {
    if (! this.internalValue) {
      return true;
    }

    if (typeof this.internalValue === 'string' && query === this.internalValue) {
      return false;
    }

    if (
      typeof this.internalValue === 'object'
      && this.internalValue[`${this.internalItemValue}`] === query
    ) {
      return false;
    }

    return true;
  }

  // #endregion

  // #region Async methods

  /**
   * Only attempt to load data of models from basic values
   * When you alreayd have a model or object you can just set these values
   */
  protected async preloadMultipleValues(): Promise<void> {
    if (this.internalItems.length) {
      return;
    }

    const initialValues: AInputableSelect[] = [];
    const preloadValueIds: Array<number | string | boolean> = [];

    this.internalValue.forEach((item: AInputableSelect) => {
      typeof item === 'object'
        ? initialValues.push(item)
        : preloadValueIds.push(item);
    });

    let preloadedValues: Model[] = [];

    if (preloadValueIds.length > 0 && this.hasModel) {
      const response = await this.queryModel().filter({
        [this.internalItemValueFilter]: preloadValueIds,
      }).all();

      if (response) {
        preloadedValues = response;
      }
    }

    this.internalItems = [...initialValues, ...preloadedValues];
  }

  protected async preloadSingleValue(): Promise<void> {
    if (this.internalValue && typeof this.internalValue === 'object') {
      if (! this.selectedModelAlreadyLoaded()) {
        this.internalItems.push(this.internalValue);
      }

      return;
    }

    if (! this.hasModel) {
      return;
    }

    const response = await this.queryModel().filter({
      [this.internalItemValueFilter]: this.internalValue,
    }).first();

    if (response) {
      this.internalItems.push(response);
    }
  }

  protected async searchModels(query: string): Promise<void> {
    if (this.hasModel) {
      this.isLoading = true;

      const items = await this.queryModel()
        .filter({ [this.itemSearch]: query })
        .limit(this.searchLimit)
        .all();

      this.internalItems = this.addSelectedItemsToItems(items);

      this.isLoading = false;
    }
  }

  protected async searchModelsForNextPage(): Promise<void> {
    // Early return if
    if (
      this.isLoading // still loading, or
      || ! this.lastInternalItemHasNextPage // there's no next page, or
      || ! this.hasModel // there's no model
    ) { return; }

    this.isLoading = true;

    this.internalItems = [
      ...this.internalItems,
      ...await cloneDeep(this.internalItems[this.internalItems.length - 1] as Model)
        .page((this.internalItems[this.internalItems.length - 1] as any).meta.current_page + 1)
        .all(),
    ];

    this.isLoading = false;
  }

  // #endregion

  // #region Getters & Setters

  protected get hasDeletableChips(): boolean {
    return ! this.internalDisabled
      && (
        this.internalDeletableChips
        || this.internalChips
      );
  }

  protected get hasModel(): boolean {
    return this.model !== undefined;
  }

  protected get internalDeletableChips(): boolean {
    return this.deletableChips !== undefined
      && this.deletableChips !== null
      && this.deletableChips !== false;
  }

  protected get internalCacheItems(): boolean {
    return this.cacheItems !== undefined
      && this.cacheItems !== null
      && this.cacheItems !== false;
  }

  protected get internalChips(): boolean {
    return this.chips !== undefined
      && this.chips !== null
      && this.chips !== false;
  }

  protected get internalDisabled(): boolean {
    return this.disabled !== undefined
      && this.disabled !== null
      && this.disabled !== false;
  }

  protected get internalItemText(): boolean | number | string | Function {
    return this.itemText !== undefined
      && this.itemText !== null
      ? this.itemText
      : 'label';
  }

  protected get internalItemValue(): boolean | number | string {
    return this.itemValue !== undefined
      && this.itemValue !== null
      ? this.itemValue
      : 'id';
  }

  protected get internalMultiple(): boolean {
    return this.multiple !== undefined
      && this.multiple !== null
      && this.multiple !== false;
  }

  protected get filterLocally(): boolean {
    return this.hasModel
      && ! this.internalCacheItems;
  }

  protected get lastInternalItemHasNextPage(): boolean {
    // Early return if
    if (
      ! this.hasModel // not bound to a model, or
      || ! this.internalItems.length // there's no results
    ) {
      return false;
    }

    const lastInternalItem = this.internalItems[this.internalItems.length - 1] as {
      meta?: {
        last_page?: number;
        current_page?: number;
      }
    };

    // Sidenote: This could be shortened to
    // lastInternalItem.meta?.current_page === lastInternalItem.meta?.last_page
    // if the linter supports it
    //
    // Early return if
    if (
      ! lastInternalItem.meta
      || ! lastInternalItem.meta.last_page
      || ! lastInternalItem.meta.current_page
      || lastInternalItem.meta.current_page === lastInternalItem.meta.last_page
    ) {
      return false;
    }

    return true;
  }

  protected get returnsObject(): boolean {
    return ! this.itemValue;
  }

  // #endregion

  // #region @Watchers

  @Watch('$attrs.value')
  protected onChangedValue(newValue: unknown, oldValue: unknown): void {
    // eslint-disable-next-line eqeqeq
    if (oldValue == newValue) { return; }

    this.internalValue = newValue;
  }

  @Watch('internalValue')
  protected onChangedInternalValue(newValue: unknown, oldValue: unknown): void {
    // eslint-disable-next-line eqeqeq
    if (oldValue == newValue) { return; }

    this.$emit('input', newValue);

    this.loadCurrentSelection();
  }

  @Watch('searchString')
  protected searchChanged(query: string|null): void {
    if (
      query === null
      || query === undefined
      || this.internalCacheItems
      || ! this.valueDifferent(query)
    ) { return; }

    this.doSearch(query);
  }

  // #endregion
}

// #region @Enums
// #endregion

// #region @Types

export type AInputableSelect = Model | Record<string, unknown> | string | number | boolean;

// #endregion

// #region @Interfaces
// #endregion
