import { Model } from '@/models/Model';

export class ReportLog extends Model {
  protected $name = 'ReportLog';

  protected $endpoint = '/report-logs';

  protected $fillable: string[] = [];

  public id?: string;

  public user?: string;

  public type?: string;

  public changed_from?: string;

  public changed_to?: string;

  public text?: string;

  public created_at?: string;

  public updated_at?: string;

  public links?: any;

  public delivery_status?: string;

  public counterpart_name?: string;

  public balance?: any;

  public is_processed?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  private isType(type: string): boolean {
    if (! this.type) {
      return false;
    }

    return this.type === type;
  }

  public get isMessage(): boolean {
    return this.isType('message');
  }

  public get isStatusChange(): boolean {
    return this.isType('status_change');
  }

  public get isStatusChangePlanning(): boolean {
    return this.isType('status_planning_change');
  }

  public get isChange(): boolean {
    return this.isType('change');
  }

  public get isRejectReason(): boolean {
    return this.isType('reject_reason');
  }

  public get isSync(): boolean {
    return this.isType('sync');
  }
}
