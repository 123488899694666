var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      _vm._b(
        { staticClass: "atabix-card", class: _vm.classes },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasTitle,
              expression: "hasTitle"
            }
          ],
          staticClass: "atabix-card__title-container"
        },
        [
          _c(
            "div",
            { staticClass: "atabix-card__title" },
            [
              _vm._t("title", [
                _c("ATitle", [_vm._v(" " + _vm._s(_vm.title) + " ")])
              ])
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$slots.actions,
                  expression: "$slots.actions"
                }
              ],
              staticClass: "atabix-card__actions"
            },
            [_vm._t("actions")],
            2
          )
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasTitle,
            expression: "hasTitle"
          }
        ],
        staticClass: "atabix-card__seperator"
      }),
      _c("div", { staticClass: "atabix-card__content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }