import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/components/page-header/PageHeader.vue';
import { loadLanguageAsync } from '@/i18n';

@Component<LanguageSwitch>({
  components: {
    PageHeader,
  },
})
export default class LanguageSwitch extends Vue {
  public changeLanguage(language: string) {
    loadLanguageAsync(language).then((value: string) => {
      this.$emit('locale-changed', language);
    });
  }
}
