var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex xs12" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm.allowEditOrder
            ? _c(
                "draggable",
                {
                  staticClass: "flex xs12 list-group",
                  attrs: { options: _vm.draggableOptions },
                  on: {
                    start: function($event) {
                      _vm.drag = true
                    },
                    end: function($event) {
                      ;(_vm.drag = false), _vm.handleDragEndEvent()
                    }
                  },
                  model: {
                    value: _vm.draggablesObjects,
                    callback: function($$v) {
                      _vm.draggablesObjects = $$v
                    },
                    expression: "draggablesObjects"
                  }
                },
                [_c("transition-group", [_vm._t("content")], 2)],
                1
              )
            : _c(
                "div",
                { staticClass: "flex xs12 list-group" },
                [_vm._t("content")],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }