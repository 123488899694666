import { Model } from '@/models/Model';
import { Media } from '@/models/Report';
import client from '@/support/Client';

export class HistoricalReport extends Model {
  protected $name = 'HistoricalReport';

  protected $endpoint = '/historical-reports';

  protected $fillable: string[] = [
  ];

  public uuid?: string;

  public created_at?: string;

  public updated_at?: string;

  public answers?: Answer;

  public links?: any;

  public name?: string;

  public media?: Media[];

  public damage(damage: Model | string) {
    this.$endpoint = `/historical-reports/${damage}`;
    return this;
  }

  public mediaEndpoint(id: string) {
    this.$endpoint = `${this.$endpoint}/${id}/media`;

    return this;
  }

  public deleteMediaEndpoint(id: string): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/media/${id}`;

    return this.request('delete', url);
  }

  public sortDamages(attributes: string[]) {
    const url = this.$endpoint;

    return client('post', url, attributes, true);
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }
}

interface Answer {
  historicalReport_ABCSchadesAantallen?: string;
  historicalReport_BIJZONDERHEDEN?: string;
  historicalReport_BedragUitbetaling?: string;
  historicalReport_Calculatieexpert?: string;
  historicalReport_CaseNumber?: string;
  historicalReport_DatumAkkoord?: string;
  historicalReport_FinaleKwijting?: string;
  historicalReport_KeuzeKUSKEAKHC?: string;
  historicalReport_Naamexpert?: string;
  historicalReport_OmschrijvingSchadelocatie?: string;
  historicalReport_Opnamedatum?: string;
  historicalReport_Status?: string;
  historicalReport_Uitbetaald?: string;
  historicalReport_Verslagdatum?: string;
  historicalReport_ZelfdeEigenaar?: string;
}
