import Vue from 'vue';
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import AModalComponent from '@/components/AModal/AModal.vue';

export default class AModal {
  private static promise: Promise<any> | null = null;

  private static promiseResolve: any = null;

  public static vueOptions: ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never> | undefined;

  public static setup(options?: ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never>): void {
    AModal.vueOptions = options;
  }

  public static show<T extends VueConstructor<Vue>>(component: T, options?: AModalOptions): Promise<any> {
    AModal.close();

    const containerDiv = document.createElement('div') as HTMLDivElement;
    containerDiv.setAttribute('id', 'atabix-vue-modal');

    // Find the Vuetify root element 'data-app' and append modal to that element
    // If the 'data-app' element is not found append to body instead
    const bodyDiv = document.querySelectorAll('[data-app]')[0] ? document.querySelectorAll('[data-app]')[0] : document.body;
    bodyDiv.append(containerDiv);
    document.documentElement.classList.add('overflow-hidden');

    const ComponentClass = Vue.extend(AModalComponent);
    const instance = new ComponentClass({
      ...AModal.vueOptions,
      ...{
        propsData: {
          component,
          options,
        },
      },
    });
    instance.$mount('#atabix-vue-modal');

    AModal.promise = new Promise<any>((resolve) => {
      this.promiseResolve = resolve;
    });

    return AModal.promise;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static close(data?: any): void {
    document.documentElement.classList.remove('overflow-hidden');
    const containerDiv = document.getElementById('atabix-vue-modal') as HTMLDivElement | null;

    if (containerDiv) {
      // Find the Vuetify root element 'data-app' and append modal to that element
      // If the 'data-app' element is not found append to body instead
      const bodyDiv = document.querySelectorAll('[data-app]')[0] ? document.querySelectorAll('[data-app]')[0] : document.body;
      bodyDiv.removeChild(containerDiv);
      if (AModal.promiseResolve) {
        AModal.promiseResolve(data);
      }

      AModal.promiseResolve = null;
    }
  }
}

export type AModalOptions = {
  props?: AModalProps;
  listeners?: AModalListeners;
};

export interface AModalProps extends Record<string, unknown> {
  persistent?: boolean;
  wide?: boolean;
  fullscreen?: boolean;
}

export type AModalListeners = Record<string, ((...args: any[]) => void | Promise<void>) | Array<(...args: any[]) => void | Promise<void>>>;
