var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "span",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.handleClick($event)
            }
          }
        },
        [
          !_vm.isFullUrl
            ? _c(
                "router-link",
                { staticClass: "atabix-table-link", attrs: { to: _vm.to } },
                [_vm._v(" " + _vm._s(_vm.value) + " ")]
              )
            : _c(
                "a",
                {
                  staticClass: "atabix-table-link",
                  attrs: {
                    href: _vm.to,
                    rel: "noopener noreferrer",
                    target: "_blank"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.value) + " ")]
              )
        ],
        1
      )
    : _c(
        "span",
        { staticClass: "atabix-table-link atabix-table-link--normal" },
        [_vm._v(" " + _vm._s(_vm.value) + " ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }