import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { UserFilter as UserFilterModel, ReportFilters } from '@/models/UserFilter';
import { Options } from '@/components/mi-dialog/MiDialog';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';

@Component<UserFilter>({})
export default class UserFilter extends Vue {
  @Prop()
  protected filters!: any;

  @Prop()
  protected filterType!: string;

  protected userFilters: UserFilterModel[] = [];

  protected isLoading = false;

  protected isFiltersMenuOpen = false;

  protected isCreatingFilter = false;

  protected filterDefault = false;

  protected filterName = '';

  protected filtersClone: any = {};

  protected selectedFilterName = 'Mijn filters';

  protected defaultFilterName = 'Mijn filters';

  protected selectedUserFilter: UserFilterModel | null = null;

  protected mounted() {
    this.getUserFilters();
  }

  protected setLastUsedReportFilter() {
    this.handleApplyFilter(this.$store.state.lastUsedReportFilter, this.defaultFilterName);
    this.$store.dispatch('changeLastUsedReportFilter', null);
  }

  protected resetFilters() {
    this.$emit('resetFilters');
    this.resetFilterName();
  }

  public resetFilterName() {
    this.selectedFilterName = this.defaultFilterName;
  }

  protected closeCreatingFilter() {
    this.isCreatingFilter = false;
    this.filterName = '';
    this.filterDefault = false;
  }

  protected isSelectedFilter(selectedFilterName: string) {
    return this.selectedFilterName === selectedFilterName;
  }

  protected createFilterPayload() {
    const payload: {[key: string]: any} = {
      key: this.filterType,
      default: this.filterDefault,
      name: this.filterName,
      filters: this.filters,
    };

    payload.filters = JSON.stringify(payload.filters);

    return payload;
  }

  protected getStoreFilters() {
    const storeFilters = cloneDeep(this.$store.state.userFilters);
    if (! storeFilters) {
      return;
    }

    return storeFilters.filter((userFilter: UserFilterModel) => userFilter.key === this.filterType);
  }

  protected setDefaultUserFilter() {
    if (! this.userFilters) {
      return;
    }

    const defaultFilter = this.userFilters.find((userFilter: UserFilterModel) => userFilter.default);

    if (defaultFilter && defaultFilter.user_filters && defaultFilter.name) {
      this.handleApplyFilter(defaultFilter.user_filters, defaultFilter.name);
    }
  }

  protected getUserFilters() {
    this.userFilters = this.getStoreFilters();
    if (
      this.$store.state.lastUsedReportFilter !== null
      && this.$store.state.lastUsedReportFilter.user_filter_type === this.filterType
    ) {
      this.setLastUsedReportFilter();
    } else {
      this.setDefaultUserFilter();
    }
  }

  protected saveFilter() {
    new UserFilterModel()
      .create(this.createFilterPayload())
      .then((userFilter: UserFilterModel) => {
        this.$store.dispatch('updateUserFilters', userFilter);

        if (userFilter && userFilter.user_filters && userFilter.name) {
          this.handleApplyFilter(userFilter.user_filters, userFilter.name);
        }

        this.userFilters = this.getStoreFilters();

        this.closeCreatingFilter();
        this.$store.dispatch('openDialog', this.dialogOptionsCreatedFilter);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getAllUserFilters() {
    new UserFilterModel()
      .all()
      .then((userFilters: UserFilter[]) => {
        this.$store.dispatch('changeUserFilters', userFilters);
        this.getUserFilters();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleApplyFilter(selectedUserFilters: ReportFilters, selectedFilterName: string) {
    this.$emit('selectedUserFilters', selectedUserFilters);
    this.selectedFilterName = selectedFilterName;
  }

  protected handleRemoveFilter(selectedUserFilter: UserFilterModel) {
    this.selectedUserFilter = selectedUserFilter;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteFilter);
  }

  protected get dialogOptionsCreatedFilter(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Filter is aangemaakt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Oke',
        },
      },
    };
  }

  protected get dialogOptionsDeleteFilter(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze filter wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.selectedUserFilter) {
              return;
            }

            this.selectedUserFilter
              .delete()
              .then((userFilter: UserFilterModel) => {
                this.getAllUserFilters();
                this.resetFilters();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedUserFilter = null;
          },
        },
      },
    };
  }

  protected get isDisabled() {
    return ! this.selectedFilterName;
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.resetFilters();
    this.getUserFilters();
  }
}
