import { Media } from '@/models/Media';

export class Polygon {
  constructor(attributes: object = {}) {
    Object.assign(this, attributes);
  }

  public id!: string;

  public mask_image?: string;

  public media!: Media;

  public points!: Point[];

  public type!: Type;

  public isSelected = false;

  protected colors: {[key: string]: string} = {
    blue: '#0096FF',
    red: '#ff0000',
    green: '#66ff00',
  };

  public draw(context: CanvasRenderingContext2D | null, color?: string) {
    if (! context) {
      return;
    }

    context.beginPath();
    this.points.forEach((path, index) => {
      const x0 = this.points[index].x;
      const x1 = this.points[(index + 1) % this.points.length].x;
      const y0 = this.points[index].y;
      const y1 = this.points[(index + 1) % this.points.length].y;
      context?.moveTo(x0, y0);
      context?.lineTo(x1, y1);
    });

    context.strokeStyle = color ? this.colors[color] : this.color;

    context.lineWidth = 7;
    context.stroke();
  }

  public drawFromMaskImage(context: CanvasRenderingContext2D | null, type: 'result' | 'mask') {
    if (! context || ! this.mask_image) {
      return;
    }

    const image = new Image();

    image.onload = () => {
      if (type === 'mask') {
        context.globalCompositeOperation = 'source-in';
      }

      if (type === 'result') {
        context.globalCompositeOperation = 'source-over';
      }

      context.drawImage(image, 0, 0);

      // Transform white pixels to transparent on a mask canvas
      if (type === 'mask') {
        context.globalCompositeOperation = 'destination-out';

        const pixels = context.getImageData(0, 0, 1000, 1000);
        for (let i = 0, len = pixels.data.length; i < len; i += 4) {
          const r = pixels.data[i];
          const g = pixels.data[i + 1];
          const b = pixels.data[i + 2];

          // if the pixel is white, set alpha to 0 (transparent)
          if (r >= 100 && g >= 100 && b >= 100) {
            pixels.data[i + 3] = 0;
          }
        }

        context.putImageData(pixels, 0, 0);
      }
    };

    image.src = this.mask_image;
  }

  public fill(context: CanvasRenderingContext2D | null, color?: string) {
    if (! context) {
      return;
    }

    context.beginPath();
    this.points.forEach((point, index) => {
      if (index === 0) {
        context.moveTo(point.x, point.y);
      }

      if (index === this.points.length - 1) {
        context.lineTo(this.points[0].x, this.points[0].y);
      } else {
        context.lineTo(this.points[index + 1].x, this.points[index + 1].y);
      }
    });

    context.fillStyle = color || 'white';
    context.closePath();
    context.fill();
    context.stroke();
  }

  public IsPointInPolygon(mousePoint: Point): boolean {
    let inside = false;

    for (let i = 0; i < (this.points.length - 1); i ++) {
      const x0 = this.points[i].x;
      const x1 = this.points[i + 1].x;
      const y0 = this.points[i].y;
      const y1 = this.points[i + 1].y;

      if ((y0 < mousePoint.y && y1 >= mousePoint.y) || (y1 < mousePoint.y && y0 >= mousePoint.y)) { // this edge is crossing the horizontal ray of testpoint
        if ((x0 + (mousePoint.y - y0) / (y1 - y0) * (x1 - x0)) < mousePoint.x) { // checking special cases (holes, self-crossings, self-overlapping, horizontal edges, etc.)
          inside = ! inside;
        }
      }
    }

    return inside;
  }

  public get color() {
    if (this.hasMedia) {
      return this.colors.red;
    }

    if (this.isSelected) {
      return this.colors.green;
    }

    return this.colors.blue;
  }

  public get hasMedia() {
    return !! this.media;
  }
}

export interface Point {
  x: number;
  y: number;
}

type Type = 'api' | 'manual_drawing';
