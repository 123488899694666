import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<StatisticsNumberWidget>({})
export default class StatisticsNumberWidget extends Vue {
  @Prop()
  protected item!: StatisticsNumberItem;
}

interface StatisticsNumberItem {
  class?: string[];
  icon?: string;
  amount: string;
  description?: string;
}

export interface StatisticsNumberWidgetProps {
  item?: StatisticsNumberItem;
}
