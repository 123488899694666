var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "breadcrumbList__item" },
    [
      _c("v-icon", { staticClass: "breadcrumbList__icon" }, [
        _vm._v("chevron_right")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }