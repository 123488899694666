var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FullscreenDialogLayout",
        {
          staticClass: "question-conclusion-dialog",
          attrs: { id: "question-conclusion-dialog" },
          on: {
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c("template", { slot: "title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.title))])
          ]),
          _c(
            "template",
            { slot: "container" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "100%"
                    }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "div",
                      { staticClass: "question-conclusion-dialog__content" },
                      [
                        !_vm.isMissingAnswers && !_vm.isShowingQuestionaire
                          ? _c(
                              "div",
                              { staticClass: "content__error" },
                              [
                                _vm._t("error", [
                                  _c("h2", [_vm._v("Er is iets misgegaan....")])
                                ])
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isMissingAnswers || _vm.isShowingQuestionaire
                          ? _c(
                              "div",
                              { staticClass: "content__main" },
                              [
                                _vm.isMissingAnswers &&
                                !_vm.isShowingQuestionaire
                                  ? [
                                      _c("h2", [
                                        _vm._v(
                                          "Beantwoord eerst de volgende vragen:"
                                        )
                                      ]),
                                      _vm.conclusion &&
                                      _vm.conclusion.required_questions
                                        ? _c("Questionnaire", {
                                            attrs: {
                                              answers: _vm.reportAnswers,
                                              questions:
                                                _vm.conclusion
                                                  .required_questions,
                                              report: _vm.report,
                                              enableReadMode:
                                                _vm.$store.state.Auth
                                                  .has_quick_questions
                                            },
                                            on: {
                                              reportAnswerSaved:
                                                _vm.onReportAnswerSaved
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _vm.isShowingQuestionaire
                                  ? [
                                      _vm.isLoadingQuestions
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center",
                                              minHeight: "100%"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoadingQuestions
                                        ? [
                                            _c("Questionnaire", {
                                              attrs: {
                                                answers: _vm.questionairAnswers,
                                                damage: _vm.damage,
                                                questions:
                                                  _vm.primarySectionQuestions,
                                                report: _vm.report,
                                                enableReadMode:
                                                  _vm.$store.state.Auth
                                                    .has_quick_questions,
                                                hiddenQuestionKeys:
                                                  _vm.conclusion
                                                    .hide_question_keys
                                              },
                                              on: {
                                                reportAnswerSaved:
                                                  _vm.onReportAnswerSaved,
                                                damageAnswerSaved:
                                                  _vm.onDamageAnswerSaved,
                                                onClickInfo: _vm.onClickInfo,
                                                onClickMetaPgvLimit:
                                                  _vm.onClickMetaPgvLimit
                                              }
                                            }),
                                            _vm.isShowingSecondQuestionaire
                                              ? _c("Questionnaire", {
                                                  attrs: {
                                                    answers:
                                                      _vm.questionairAnswers,
                                                    damage: _vm.damage,
                                                    questions:
                                                      _vm.secondSectionQuestions,
                                                    report: _vm.report,
                                                    enableReadMode:
                                                      _vm.$store.state.Auth
                                                        .has_quick_questions,
                                                    hiddenQuestionKeys:
                                                      _vm.conclusion
                                                        .hide_question_keys
                                                  },
                                                  on: {
                                                    reportAnswerSaved:
                                                      _vm.onReportAnswerSaved,
                                                    damageAnswerSaved:
                                                      _vm.onDamageAnswerSaved,
                                                    onClickInfo:
                                                      _vm.onClickInfo,
                                                    onClickMetaPgvLimit:
                                                      _vm.onClickMetaPgvLimit
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.sidePanelType
                          ? _c(
                              "div",
                              { staticClass: "content__side-panel" },
                              [
                                _vm.sidePanelType === "pgv"
                                  ? _c("PgvInfoBlock", {
                                      attrs: {
                                        question: _vm.currentQuestion,
                                        report: _vm.report
                                      },
                                      on: {
                                        answerQuestion: _vm.answerPGVQuestion
                                      }
                                    })
                                  : _vm._e(),
                                _vm.sidePanelType === "question"
                                  ? _c("QuestionBlock", {
                                      attrs: {
                                        question: _vm.currentQuestion,
                                        foundQuestion: _vm.foundQuestion
                                      },
                                      on: {
                                        answerQuestion:
                                          _vm.answerCurrentQuestion
                                      }
                                    })
                                  : _vm._e(),
                                _vm.sidePanelType === "article"
                                  ? _c("LibraryArticle", {
                                      attrs: { article: _vm.article }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          !_vm.isLoading
            ? _c(
                "template",
                { slot: "footer" },
                [
                  _vm.conclusion
                    ? _c(
                        "QuestionConclusion",
                        {
                          attrs: {
                            result: _vm.conclusion.status,
                            backgroundColor: _vm.footerClasses
                          }
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c("div", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.conclusion.title))
                              ]),
                              _vm._v(" - "),
                              _c("em", [
                                _vm._v(_vm._s(_vm.conclusion.description))
                              ])
                            ]),
                            _vm.conclusion.motivation
                              ? _c("div", [
                                  _c("br"),
                                  _c("strong", [
                                    _vm._v(
                                      "Motivatie " +
                                        _vm._s(_vm.conclusion.motivation_code) +
                                        ":"
                                    )
                                  ]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.conclusion.motivation) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "template",
                            { slot: "actions" },
                            [
                              _c("span"),
                              _vm.hasConclusionConfirm
                                ? _c(
                                    "MiButton",
                                    {
                                      staticClass: "tw-mr-8",
                                      attrs: {
                                        color: "gray-dark",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onOpenQuestionConclusionOverwriteDialog(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Afwijken van conclusie ")]
                                  )
                                : _vm._e(),
                              _vm.hasConclusionConfirm
                                ? _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color:
                                          _vm.QuestionaireDialogConclusionType
                                            .buttonColors[
                                            _vm.conclusion.status
                                          ] || "",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onUseConclusion($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Conclusie overnemen ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm.isDisplayingQuestionConclusionOverwriteDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingQuestionConclusionOverwriteDialog,
                callback: function($$v) {
                  _vm.isDisplayingQuestionConclusionOverwriteDialog = $$v
                },
                expression: "isDisplayingQuestionConclusionOverwriteDialog"
              }
            },
            [
              _c("QuestionConclusionOverwriteDialog", {
                attrs: {
                  conclusion: _vm.conclusion,
                  title: _vm.overwriteConclusionDialogTitle,
                  reasons: _vm.overwriteConclusionDialogReasons,
                  currentPayload: _vm.currentDeviationPayload
                },
                on: { answerQuestion: _vm.deviateFromConclusion },
                model: {
                  value: _vm.isDisplayingQuestionConclusionOverwriteDialog,
                  callback: function($$v) {
                    _vm.isDisplayingQuestionConclusionOverwriteDialog = $$v
                  },
                  expression: "isDisplayingQuestionConclusionOverwriteDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }