import { Schedule } from '@/models/Schedule';
import { BusinessHoursInput } from '@/models/User';
import { sanitize } from 'dompurify';
import { DateTime } from 'luxon';

export const studlyCase = (str: string) => {
  str = str.replace('_', ' ').replace('-', ' ');
  const r = new RegExp(/(?:^\w|[A-Z]|\b\w|\s+)/g);
  const n = str.replace(r, (x, y) => {
    if (+ x === 0) {
      return '';
    }

    const z = y === 0 ? x.toUpperCase() : x.toUpperCase();

    return z;
  });

  return n;
};

/**
 * Uppercase the first character of each word in a string.
 *
 * @example the quick fox => The Quick Fox
 *
 * @param str
 */
export const ucwords = (str: string) => (`${str}`).replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());

/**
 * Generate a custom avatar using a string (user's name)
 *
 *
 * @param name
 * @param color
 */
export const generateAvatar = (name?: string, color?: string) => `https://ui-avatars.com/api/?name=${name ? name.replace(' ', '+') : ''}&background=${color || '526880'}&color=fff&font-size=0.4&size=256`;

/**
 * Uppercase the first character of a string.
 *
 * @example the quick fox => The quick fox
 *
 * @param str
 */
export const ucstring = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve((reader as any).result);
  reader.onerror = (error) => reject(error);
});

export const hex2rgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

export const hex2rgba = (hex: string, opacity: number) => {
  let h: any = hex.replace('#', '');
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'));

  for (let i = 0; i < h.length; i ++) h[i] = parseInt(h[i].length == 1 ? h[i] + h[i] : h[i], 16);

  if (typeof opacity !== 'undefined') h.push(opacity);

  return `rgba(${h.join(',')})`;
};

export const calculateIlluminance = (hex: string) => {
  const rgbColor: RGBColor | null = hex2rgb(hex);
  if (! rgbColor) {
    return;
  }

  const red = rgbColor.r;
  const green = rgbColor.g;
  const blue = rgbColor.b;

  const a = [red, green, blue].map((color: number) => {
    color /= 255;
    return (color <= 0.03928)
      ? color / 12.92 : Math.pow(((color + 0.055) / 1.055), 2.4);
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrastToWhite = (hex: string) => {
  const illuminance = calculateIlluminance(hex);
  if (! illuminance) {
    return;
  }

  const whiteIlluminance = 1;
  return whiteIlluminance / illuminance;
};

export const isContrastToWhiteOk = (hex: string) => {
  const contrast = contrastToWhite(hex);

  if (! contrast) {
    return true;
  }
  return contrast > 4.5;
};

export const avatarHtml = (imageUrl: string) => `<img class="avatar avatar-small" src="${imageUrl}">`;

export const activeHtml = (value: string) => `<span class="activeItem"><i class="material-icons activeItem">beenhere</i>${value}</span>`;

export const iconToHtml = (iconString: string, iconClass?: string, size = 16) => `<i aria-hidden="true" class="icon material-icons ${iconClass}" style="font-size: ${size}px;">${iconString}</i>`;

export const formatDate = (date: string, luxonFormat = 'dd-LL-yyyy') => DateTime.fromSQL(date).toFormat(luxonFormat);

export const formatDateIso = (date: string, luxonFormat = 'dd-LL-yyyy') => DateTime.fromISO(date).toFormat(luxonFormat);

export const formatDateSeconds = (date: string) => {
  if (! date) {
    return;
  }
  return date.slice(0, - 3);
};

export const concatenate = (value: string, amount = 20) => {
  if (value.length >= amount) {
    return `${value.slice(0, amount)}...`;
  }
  return value;
};

export const trim = (char: string, str: string) => {
  // Make str regex safe.
  char = char.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

  return str.replace(`/^[${char}]+|[${char}]+$/g`, '');
};

export const clearWhiteSpace = (str: string) => {
  if (/\s/.test(str)) {
    return str.replace(/\s/g, '');
  }

  return str;
};

export const sortObjectsArrayByProperty = (objects: any[], property: string) => objects.sort((object1: any, object2: any) => ((object1[property] > object2[property]) ? 1 : ((object2[property] > object1[property]) ? - 1 : 0)));

export const sortObjectsArrayByStringDesc = (objects: any[], key: string) => objects.sort((object1: any, object2: any) => object2[key].length - object1[key].length);

export const currency = (value: number | string, currency?: string) => {
  if (value && ! Number(value) && (value as string).length) {
    value = (value as string).replace(/[.\s]/g, '');
    value = value.replace(/[,\s]/g, '.');
  }

  const currencyOptions = currency ? { style: 'currency', currency } : {};
  return new Intl.NumberFormat('nl-NL', currencyOptions).format(Number((value as string)));
};

export const hours = (value: number | string) => new Intl.NumberFormat('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(Number(value));

export const sanitizeString = (value: string, allowHtml = false) => (value ? sanitize(value, { USE_PROFILES: { html: allowHtml } }) : '');

/**
 * Ruturns a error message for a date.
 *
 * @example 12-50-1587 => 'Opgegeven datum is ongeldig'
 *
 * @param boolean
 */
export const isValidDate = (date: string): boolean => {
  if (! date || date.length === 0) {
    return true;
  }

  const datePattern = /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/;
  return datePattern.test(date) && date.length > 0 && date.length <= 10 && DateTime.fromFormat(date, 'dd-LL-yyyy').isValid;
};

/**
 * Ruturns a boolean for a valid time.
 *
 * @example 15:31 => 'Opgegeven tijd is ongeldig'
 *
 * @param boolean
 */
export const isValidTime = (time: string): boolean => {
  if (! time || time.length === 0) {
    return true;
  }

  time = formatDate(time, 'HH:mm');

  const timePattern = /([0-1]?[0-9]|2[0-3]):[0-5][0-9]/;
  return timePattern.test(time) && time.length > 0 && time.length <= 10 && DateTime.fromFormat(time, 'HH:mm').isValid;
};

// TODO: WEGHALEN
export const dateErrorMessage = 'Gelieve een geldige datum op te geven (bijv. 23-12-2019)';

/**
 * Ruturns a formatted date & checks if the date is valid to be formatted.
 *
 * @example 2019-12-10 => 10-12-2019
 *
 * @param str
 */
export const setFormattedDatePickerValue = (date: string, oldFormat = 'dd-LL-yyyy', newFormat = 'yyyy-LL-dd'): string => {
  if (date && date.length === 10 && DateTime.fromFormat(date, oldFormat).isValid) {
    return DateTime.fromFormat(date, oldFormat).toFormat(newFormat);
  }
  return '';
};

export const firstDayOfWeek = (date: DateTime): DateTime => {
  const weekday = date.weekday;
  return date.minus({ days: weekday - 1 });
};

export const lastDayOfWeek = (date: DateTime): DateTime => {
  const weekday = date.weekday;
  return date.plus({ days: 7 - weekday });
};

export const parseScheduleToBusinessHours = (schedules: Schedule[]): BusinessHoursInput[] => {
  const businessHours: BusinessHoursInput[] = [];

  schedules.forEach((schedule: Schedule) => {
    if (! schedule.day) {
      return;
    }

    const businessHour = {
      daysOfWeek: [reverseFullcalendarDayMap[schedule.day]],
      startTime: schedule.starts_at ? DateTime.fromSQL(schedule.starts_at).toFormat('HH:mm') : '09:00',
      endTime: schedule.ends_at ? DateTime.fromSQL(schedule.ends_at).toFormat('HH:mm') : '17:00',
    };

    businessHours.push(businessHour);
  });

  // If No business hours
  if (businessHours.length == 0) {
    const businessHour = {
      daysOfWeek: [],
      startTime: '09:00',
      endTime: '17:00',
    };

    businessHours.push(businessHour);
  }

  return businessHours;
};

export const fullcalendarDayMap: {[key: string]: string} = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export const reverseFullcalendarDayMap: {[key: string]: number} = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
};

export interface RGBColor {
  r: number;
  g: number;
  b: number
}
