import axios from 'axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Settings as LuxonSettings } from 'luxon';

Vue.use(VueI18n);

const defaultLocale = 'nl';

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    nl: require('@/locales/nl').default,
  },
});

const loadedLanguages = [defaultLocale];

const setI18nLanguage = (lang: string) => {
  i18n.locale = lang;
  LuxonSettings.defaultLocale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;

  const html = document.querySelector('html');
  if (html) {
    html.setAttribute('lang', lang);
  }

  return lang;
};

export function loadLanguageAsync(lang: string) {
  lang = lang !== undefined ? lang : defaultLocale;

  if (i18n.locale !== lang) {
    if (! loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}`).then((msgs) => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);

        return setI18nLanguage(lang);
      });
    }

    return Promise.resolve(setI18nLanguage(lang));
  }

  return Promise.resolve(lang);
}

if ((module as any).hot) {
  (module as any).hot.accept(['./locales/en', './locales/nl'], () => {
    i18n.setLocaleMessage('en', require('./locales/en').default);
    i18n.setLocaleMessage('nl', require('./locales/nl').default);
  });
}

export default i18n;
