var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mi-file-upload--container",
      class: [
        { "mi-file-upload--container__dragging": _vm.dragging },
        { invalid: _vm.invalidFileType },
        {
          "mi-file-upload--container__uploading":
            _vm.isUploaded || _vm.isUploading
        }
      ],
      on: {
        click: _vm.handleClickUploaderAction,
        dragstart: _vm.handleDragStart,
        dragend: _vm.handleDragEnd,
        dragenter: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDragEnter($event)
        },
        dragover: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDragOver($event)
        },
        dragleave: _vm.handleDragLeave,
        drop: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDrop($event)
        },
        mouseenter: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleMouseEnter($event)
        },
        mouseleave: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleMouseLeave($event)
        }
      }
    },
    [
      _c("span", {
        staticClass: "mi-file-upload--ripple",
        style: _vm.rippleStyle
      }),
      !_vm.filePathClone && !_vm.isUploading && !_vm.isUploaded
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              [
                _c("v-icon", { staticClass: "mi-file-upload--icon" }, [
                  _vm._v(
                    _vm._s(_vm.invalidFileType ? "remove_circle" : _vm.fileIcon)
                  )
                ]),
                _c("span", { staticClass: "mi-file-upload--message" }, [
                  _vm._v(_vm._s(_vm.uploadMessage))
                ])
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isUploaded && !_vm.multiple && !_vm.manualSubmit
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass:
                      "mi-file-upload--icon mi-file-upload--icon__green"
                  },
                  [_vm._v("check")]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "mi-file-upload--message mi-file-upload--message__green"
                  },
                  [_vm._v("Bestand met succes geüpload!")]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isUploaded &&
      _vm.multiple &&
      !_vm.clearAfterUpload &&
      _vm.manualSubmit
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass:
                      "mi-file-upload--icon mi-file-upload--icon__green"
                  },
                  [_vm._v("check")]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "mi-file-upload--message mi-file-upload--message__green"
                  },
                  [
                    _vm._v(
                      " (" +
                        _vm._s(_vm.filesUploaded.length) +
                        ") Bestanden zijn met succes geüpload! "
                    )
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isUploading &&
      !_vm.clearAfterUpload &&
      _vm.multiple &&
      !_vm.manualSubmit
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              _vm._l(_vm.files, function(file, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "mi-file-upload--multiFileContainer"
                  },
                  [
                    _vm.filesUploaded &&
                    !_vm.filesUploaded[index].isUploaded &&
                    _vm.filesUploaded[index].isLoading
                      ? _c("LoaderCard", {
                          attrs: { type: "spinner--small", flat: "" }
                        })
                      : _vm._e(),
                    _vm.filesUploaded &&
                    _vm.filesUploaded[index].isUploaded &&
                    !_vm.filesUploaded[index].isLoading
                      ? _c(
                          "v-progress-circular",
                          {
                            staticClass: "successIcon",
                            attrs: {
                              rotate: 360,
                              size: 25,
                              width: 3,
                              value: 100,
                              color: "success"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass:
                                  "mi-file-upload--icon mi-file-upload--icon--small mi-file-upload--icon__green"
                              },
                              [_vm._v("check")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.filesUploaded &&
                    !_vm.filesUploaded[index].isUploaded &&
                    !_vm.filesUploaded[index].isLoading
                      ? _c(
                          "v-progress-circular",
                          {
                            staticClass: "successIcon",
                            attrs: {
                              rotate: 360,
                              size: 25,
                              width: 3,
                              value: 100,
                              color: "warning"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass:
                                  "mi-file-upload--icon mi-file-upload--icon--small mi-file-upload--icon__red"
                              },
                              [_vm._v("close")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span", { staticClass: "mi-file-upload--name" }, [
                      _vm._v(_vm._s(file.name))
                    ])
                  ],
                  1
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm.filePathClone && _vm.filePathClone.length && !_vm.isUploading
        ? [
            _vm.isImage
              ? _c("span", {
                  staticClass: "mi-file-upload--imageBackground",
                  style: {
                    "background-image": "url(" + _vm.filePathClone + ")"
                  }
                })
              : _vm._e(),
            !_vm.isImage
              ? _c("span", { staticClass: "mi-file-upload--iconContainer" }, [
                  _c("span", {
                    staticClass: "mi-file-upload--icon mdi",
                    class: [_vm.iconClass]
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "mi-file-upload--message mi-file-upload--message__file",
                      class: [_vm.iconClass + "__text"]
                    },
                    [_vm._v(" " + _vm._s(_vm.fileNameFromFilePath) + " ")]
                  )
                ])
              : _vm._e(),
            _c(
              "v-icon",
              {
                staticClass: "mi-file-upload--removeFile",
                class: { "mi-file-upload--removeFile__dark": !_vm.isImage },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.removeFile($event)
                  }
                }
              },
              [_vm._v(" cancel ")]
            )
          ]
        : _vm._e(),
      _vm.isUploading && !_vm.multiple
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              [
                _c("LoaderCard", {
                  attrs: { type: "spinner--medium", flat: "" }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      !_vm.isUploadedMultiple
        ? _c("form", { ref: "uploadFileForm" }, [
            _c("input", {
              ref: "hiddenFileInput",
              staticClass: "hiddenFileInput",
              attrs: { type: "file", multiple: _vm.multiple },
              on: { change: _vm.onFileInputChange }
            })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }