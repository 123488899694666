import Vue from 'vue';
import Vuetify from 'vuetify';
import { Theme } from '@/config/theme';
import Page from '@/plugins/Page';
import { debounce } from 'lodash';
import Breadcrumb from '@/plugins/Breadcrumb';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

Vue.use(Page);
Vue.use(Breadcrumb);

Vue.use(Vuetify, {
  theme: Theme,
});

Vue.prototype.$debounce = debounce(function (this: any, methodName: string, ...parameters: any[]) {
  (this as any)[methodName].call(this as any, ...parameters);
}, 300);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAk2uRjYIuRK1CeSfWHbCQ_czxbGfZi3-U',
    libraries: 'places,visualization', // necessary for places input
  },
});

Vue.component('GmapCluster', GmapCluster);
