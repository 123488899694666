var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.damage
        ? _c(
            "QuestionConclusion",
            {
              attrs: {
                result: _vm.classificationResult,
                damage: _vm.damage,
                question: _vm.question,
                buttonDisabled: _vm.disabled,
                isDeviation: _vm.isDeviation
              },
              on: {
                answerQuestion: _vm.handleAnswerQuestion,
                removeAnswer: _vm.handleRemoveAnswer,
                actionButtonClicked: function($event) {
                  return _vm.onOpenQuestionConclusionDialog()
                }
              }
            },
            [
              _vm.isDeviation
                ? [
                    _c("template", { slot: "content_deviation-reason" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.overwriteConclusionReasonLabels[
                              _vm.damage.casuality_anomaly_reason
                            ]
                          ) +
                          " "
                      )
                    ]),
                    _c("template", { slot: "content_deviation-explanation" }, [
                      _vm._v(_vm._s(_vm.damage.casuality_anomaly_explanation))
                    ])
                  ]
                : _vm._e(),
              _c("template", { slot: "content_title" }, [
                _vm._v(_vm._s(_vm.question.label))
              ]),
              _c("template", { slot: "content_description" }, [
                _vm._v(_vm._s(_vm.classificationDescription))
              ]),
              _c("template", { slot: "button_text" }, [
                _vm._v("Causaliteit beoordeling")
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.isDisplayingQuestionConclusionDialog
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.isDisplayingQuestionConclusionDialog,
                callback: function($$v) {
                  _vm.isDisplayingQuestionConclusionDialog = $$v
                },
                expression: "isDisplayingQuestionConclusionDialog"
              }
            },
            [
              !_vm.isLoading
                ? _c(
                    "QuestionConclusionDialog",
                    {
                      ref: "questionConclusionDialog",
                      attrs: {
                        title: "Causaliteit beoordeling",
                        damage: _vm.damage,
                        damageAnswers: _vm.damageAnswers,
                        conclusion: _vm.conclusion,
                        report: _vm.report,
                        question: _vm.question,
                        primarySection: 70,
                        secondarySection: 71,
                        isLoadingQuestions: _vm.isLoadingQuestions,
                        overwriteConclusionDialogTitle:
                          _vm.overwriteConclusionDialogTitle,
                        overwriteConclusionDialogReasons:
                          _vm.overwriteConclusionDialogReasons
                      },
                      on: {
                        "update:damageAnswers": function($event) {
                          _vm.damageAnswers = $event
                        },
                        "update:damage-answers": function($event) {
                          _vm.damageAnswers = $event
                        },
                        answerQuestion: _vm.handleAnswerQuestion,
                        useConclusion: _vm.onUseConclusion,
                        deviateFromConclusion: _vm.onDeviateFromConclusion,
                        reloadFreshData: _vm.reloadFreshData
                      },
                      model: {
                        value: _vm.isDisplayingQuestionConclusionDialog,
                        callback: function($$v) {
                          _vm.isDisplayingQuestionConclusionDialog = $$v
                        },
                        expression: "isDisplayingQuestionConclusionDialog"
                      }
                    },
                    [
                      _c("template", { slot: "error" }, [
                        _c("h2", [
                          _vm._v(
                            "De gekozen schadesoort is nooit mijnbouw causaal."
                          )
                        ])
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }