var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "miDialog" },
    [
      _c(
        "v-card",
        { staticClass: "miDialog--card" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                {
                  staticClass: "elementPanel__header",
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "header__title", attrs: { "sm-11": "" } },
                    [_c("h2", [_vm._v("Notitie toevoegen")])]
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "header__close", attrs: { "sm-1": "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "miDialog--card__text" },
            [
              !_vm.isSaving
                ? [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          [
                            !_vm.isExpert
                              ? _c("v-textarea", {
                                  attrs: { name: "note", label: "Notitie" },
                                  model: {
                                    value: _vm.note,
                                    callback: function($$v) {
                                      _vm.note = $$v
                                    },
                                    expression: "note"
                                  }
                                })
                              : _vm._e(),
                            _vm.isExpert
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.note.length
                                          ? _vm.note
                                          : "Geen notitie toegevoegd"
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.isSaving
                ? _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c("LoaderCard", {
                        staticClass: "main-loader",
                        attrs: {
                          extraClass: "availability",
                          type: "spinner--medium",
                          flat: true
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { staticClass: "justify-space-between", attrs: { wrap: "" } },
                [
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: { color: "warning", outline: "true" },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.close($event)
                          }
                        }
                      },
                      [_vm._v("Sluiten")]
                    )
                  ],
                  !_vm.isExpert
                    ? [
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: !_vm.note.length || _vm.isSaving,
                              color: "success"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.updateEvent($event)
                              }
                            }
                          },
                          [_vm._v(" Opslaan ")]
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }