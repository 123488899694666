import { Model } from '@/models/Model';

export class ImageMatching extends Model {
  protected $name = 'ImageMatching';

  protected $endpoint = '/ml/damage-recognition-image-matching';

  protected $fillable: string[] = [];

  public id?: string;

  public matches?: Match[];
}

export interface Match {
  historical_report_id: string;
  historical_report_image: string;
  historical_report_pdf: string;
  historical_report_name: string;
  page_number: number;
}

export interface UpdateImageMatchInformation {
  key: 'page_nr' | 'historical_report' | 'historical_image' | 'damage_image';
  value: number | string;
}

export interface UpdateImageMatchInformationPayload {
  data: UpdateImageMatchInformation[];
}
