import { QuestionBase } from '@/models/question-models/question-base';

export class DateQuestion extends QuestionBase<string> {
  controlType = 'date';

  type: string;

  startView: string;

  constructor(options: any = {}) {
    super(options);
    this.type = options.type || '';
    this.startView = 'month';
  }
}
