var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mi-button__Container",
      class: { disabled: _vm.disabled },
      style: _vm.fullWidth ? "width: 100%;" : "width: fit-content;"
    },
    [
      _vm.href
        ? _c(
            "a",
            {
              attrs: { href: _vm.href, target: _vm.blank ? "_blank" : "_self" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "button--normal icon",
                  class: [
                    "button--normal-" + _vm.color,
                    { "button--normal-round": _vm.round },
                    { "button--normal-fab": _vm.fab },
                    { "button--normal-small": _vm.small },
                    { "button--normal-compact": _vm.compact },
                    { "button--background": _vm.background },
                    _vm.outlineClass
                  ],
                  style: {
                    width: _vm.fullWidth ? "100%" : "",
                    fontSize: _vm.fontSize + "px"
                  }
                },
                [
                  _vm.icon.length
                    ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
                    : _vm._e(),
                  !_vm.fab ? _vm._t("default") : _vm._e()
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.to
        ? _c(
            "router-link",
            { attrs: { to: _vm.to, target: _vm.blank ? "_blank" : "_self" } },
            [
              _c(
                "div",
                {
                  staticClass: "button--normal icon",
                  class: [
                    "button--normal-" + _vm.color,
                    { "button--normal-round": _vm.round },
                    { "button--normal-fab": _vm.fab },
                    { "button--normal-small": _vm.small },
                    { "button--normal-compact": _vm.compact },
                    { "button--background": _vm.background },
                    _vm.outlineClass
                  ],
                  style: {
                    width: _vm.fullWidth ? "100%" : "",
                    fontSize: _vm.fontSize + "px"
                  }
                },
                [
                  _vm.icon.length
                    ? _c("v-icon", [_vm._v(_vm._s(_vm.icon))])
                    : _vm._e(),
                  !_vm.fab ? _vm._t("default") : _vm._e()
                ],
                2
              )
            ]
          )
        : _vm._e(),
      !_vm.to && !_vm.href
        ? _c(
            "button",
            {
              staticClass: "button--normal icon",
              class: [
                "button--normal-" + _vm.color,
                { "button--normal-round": _vm.round },
                { "button--normal-fab": _vm.fab },
                { "button--normal-small": _vm.small },
                { "button--normal-compact": _vm.compact },
                { "button--background": _vm.background },
                _vm.outlineClass
              ],
              style: {
                width: _vm.fullWidth ? "100%" : "",
                fontSize: _vm.fontSize + "px"
              },
              attrs: { disabled: _vm.disabled },
              on: {
                click: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              !_vm.isBusy
                ? [
                    _vm.icon.length && !_vm.iconRight
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "icon--left",
                            attrs: { color: _vm.iconColor }
                          },
                          [_vm._v(_vm._s(_vm.icon))]
                        )
                      : _vm._e(),
                    !_vm.fab ? _vm._t("default") : _vm._e(),
                    _vm.icon.length && _vm.iconRight
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "icon--right",
                            attrs: { color: _vm.iconColor }
                          },
                          [_vm._v(_vm._s(_vm.icon))]
                        )
                      : _vm._e()
                  ]
                : [
                    _c("v-progress-circular", {
                      attrs: {
                        size: 16,
                        width: 2,
                        indeterminate: "",
                        color: "white"
                      }
                    })
                  ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }