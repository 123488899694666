import { VueConstructor } from 'vue/types/umd';
import { merge } from 'lodash';
import AModal, { AModalOptions } from '@/services/AModal';
import AMessageDialog from '@/components/AMessageDialog/AMessageDialog.vue';
import { ATranslator } from '@/support/ATranslator';

export class ADialog {
  static async close(): Promise<void> {
    AModal.close();
  }

  static async open(modal: VueConstructor<Vue>, options: AModalOptions = {}): Promise<any> {
    return AModal.show(modal, options);
  }

  /*
    e.g.
    ADialog.success({
      title: 'Successfully created',
      text: 'Item has been successfully created.',
    });
  */

  static async success(options: Partial<ADialogOptions> = {}): Promise<any> {
    const defaultOptions: ADialogOptions = {
      title: ATranslator.tc('dialog_success_title') || 'Success',
      text: ATranslator.tc('dialog_success_text') || 'Action completed successfully.',
      type: AMessageDialogType.SUCCESS,
      closeLabel: ATranslator.tc('dialog_success_close_label') || 'Close',
    };

    return AModal.show(AMessageDialog, { props: { options: merge(defaultOptions, options) } });
  }

  /*
    e.g.
    ADialog.warning({
      title: 'Delete item',
      text: 'Are you sure you wish to delete this item?',
    });
  */

  static async warning(options: Partial<ADialogOptions> = {}): Promise<any> {
    const defaultOptions: ADialogOptions = {
      title: ATranslator.tc('dialog_warning_title') || 'Warning',
      text: ATranslator.tc('dialog_warning_text') || 'Are you sure?',
      type: AMessageDialogType.WARNING,
      closeLabel: ATranslator.tc('dialog_warning_close_label') || 'Close',
    };

    return AModal.show(AMessageDialog, { props: { options: merge(defaultOptions, options) } });
  }

  /*
    e.g.
    ADialog.error({
      title: 'Fatal error',
      text: 'An unknown error occured, please contact support',
    });
  */

  static async error(options: Partial<ADialogOptions> = {}): Promise<any> {
    const defaultOptions: ADialogOptions = {
      title: ATranslator.tc('dialog_error_title') || 'Error',
      text: ATranslator.tc('dialog_error_text') || 'An unknown error occured',
      type: AMessageDialogType.ERROR,
      closeLabel: ATranslator.tc('dialog_error_close_label') || 'Close',
    };

    return AModal.show(AMessageDialog, { props: { options: merge(defaultOptions, options) } });
  }

  /*
    e.g.
    ADialog.confirmError(() => deleteItem()), 'Some item');
  */

  static async confirmDelete(
    deleteFunction: (...args: any[]) => void | Promise<void>,
    deleteItem = '',
    options: Partial<ADialogOptions> = {},
  ): Promise<any> {
    const defaultOptions: ADialogOptions = {
      title: deleteItem ? (ATranslator.tc('dialog_confirm_delete_title_with_item', 1, { item: deleteItem }) || `Verwijderen: ${deleteItem}`) : (ATranslator.tc('dialog_confirm_delete_title') || 'Verwijderen bevestigen'),
      text: ATranslator.tc('dialog_confirm_delete_text') || 'Weet je zeker dat je dit item wilt verwijderen?',
      type: AMessageDialogType.WARNING,
      closeLabel: ATranslator.tc('dialog_confirm_delete_close_label') || 'Sluiten',
      buttons: {
        confirm: {
          text: ATranslator.tc('dialog_confirm_delete_buttons_confirm_text') || 'Verwijderen',
          icon: 'mdi-check-circle',
          color: 'bg-warning-500 hover:bg-warning-600',
          action: () => deleteFunction(),
        },
        cancel: {
          text: ATranslator.tc('dialog_confirm_delete_buttons_cancel_text') || 'Annuleren',
          icon: 'mdi-close-circle',
        },
      },
    };

    return AModal.show(AMessageDialog, { props: { options: merge(defaultOptions, options) } });
  }
}

export interface ADialogOptions {
  title?: string;
  text: string;
  type: AMessageDialogType;
  buttons?: ADialogButtons;
  closeLabel?: string;
}

export interface ADialogButtons {
  confirm?: ADialogButtonOptions;
  cancel?: ADialogButtonOptions;
}

export interface ADialogButtonOptions {
  text?: string;
  action?: (...args: any[]) => void | Promise<void>;
  color?: string;
  autoClose?: boolean;
  icon?: string;
}

export enum AMessageDialogType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}
