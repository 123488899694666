import { Model } from '@/models/Model';
import { Report, Media } from '@/models/Report';
import { SubmissionDamage } from '@/models/SubmissionDamage';

export class Submission extends Model {
  protected $name = 'Submission';

  protected $endpoint = '/applicant-submissions';

  protected $fillable: string[] = [];

  public id?: any;

  public applicant?: any;

  public report?: Report;

  public status?: string;

  public type?: string;

  public media?: Media[];

  public is_syncing?: boolean;

  public additional_request?: string;

  public submission_damages?: SubmissionDamage[];

  public get isSubmitted() {
    return this.status === 'submitted';
  }

  public get isAdditionalRequest() {
    return this.additional_request?.length;
  }
}

export const statusColorMap: SubmissionStatus = {
  in_progress: 'blue-magenta',
  submitted: 'green',
  feedback: 'red',
  processed: 'green',
  cancelled: 'yellow',
};

export const statusLabelMap: SubmissionStatus = {
  in_progress: 'Bezig met opname',
  submitted: 'Opgestuurd aan IMG',
  feedback: 'Feedback gevraagt',
  processed: 'Verwerkt',
  cancelled: 'Geannuleerd',
};

export interface SubmissionStatus {
  in_progress: string;
  submitted: string;
  feedback: string;
  processed: string;
  cancelled: string;
}
