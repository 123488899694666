import { render, staticRenderFns } from "./QuestionConclusion.vue?vue&type=template&id=5f15d222&"
import script from "./QuestionConclusion.ts?vue&type=script&lang=ts&"
export * from "./QuestionConclusion.ts?vue&type=script&lang=ts&"
import style0 from "./QuestionConclusion.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f15d222')) {
      api.createRecord('5f15d222', component.options)
    } else {
      api.reload('5f15d222', component.options)
    }
    module.hot.accept("./QuestionConclusion.vue?vue&type=template&id=5f15d222&", function () {
      api.rerender('5f15d222', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QuestionConclusion/QuestionConclusion.vue"
export default component.exports