import { Application } from '@/support/Application';

export default class Storage {
  public static prefix(): string {
    return Application.config('APP_LOCAL_STORAGE_TOKEN') as string || 'com.atabix_';
  }

  public static title(key: string): string {
    return Storage.prefix() + key;
  }

  public static get(key: string, defaultValue: string|null = null): string | null {
    return localStorage.getItem(Storage.title(key)) || defaultValue;
  }

  public static set(key: string, value: any) {
    localStorage.setItem(Storage.title(key), value);
  }

  public static dump() {
    const keys = Object.keys(this.prefix);
  }

  public static delete(key: string) {
    localStorage.removeItem(Storage.title(key));
  }
}
