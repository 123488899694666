import { Aanvrager } from '@/models/Aanvrager';
import { formatDate, currency } from '@/support/String';
import { Report, LastStatusChangeLog, statusProblemsLabel } from '@/models/Report';
import { Address } from '@/models/Address';
import { DateTime } from 'luxon';
import { User, userRoles } from '@/models/User';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';
import { Tag } from '@/models/Tag';
import { Skill } from '@/models/Skill';
import { ReportType } from '@/models/ReportType';
import { Organization } from '@/models/Organization';
import { TableHeader, TableSort } from '@/components/data-table/DataTable';
import store from '@/store';
import WorkFlowFilter from '@/components/filters/work-flow-filter/WorkFlowFilter';
import OpnameVariantFilter from '@/components/filters/opname-variant-filter/OpnameVariantFilter';
import ManagementClassFilter from '@/components/filters/management-class-filter/ManagementClassFilter';
import { getFirstTimeRightName } from '@/support/FirstTimeRight';
import { UserPreference, UserPreferenceEnum } from '@/models/UserPreference';
import { getUserPreference, TableHeaderDefaultsKeyed, TableHeaderPreferenceHelper } from '@/support/UserPreferencesHelper';
import { OpnameVariantLabels } from '@/items/OpnameVariant';

const ZaaknummerHeader = {
  text: 'Zaaknummer',
  value: 'case_number',
  action: '/reports/{id}',
  sortable: {
    key: 'case_number',
    order: 'ASC',
  },
};

const ZaaknummerPlanningHeader = {
  text: 'Zaaknummer',
  value: 'case_number',
  action: '/planning-tool?report={id}',
  sortable: {
    key: 'case_number',
    order: 'ASC',
  },
};

const PrioHeader = {
  text: 'Prio',
  value: 'is_prio',
  width: '5%',
  sortable: {
    key: 'is_prio',
    order: 'ASC',
  },
  transform: (is_prio: boolean) => {
    if (is_prio) {
      return '<i aria-hidden="true" class="v-icon material-icons theme--light isPrio">warning</i>';
    }

    return '';
  },
};

const SpecialHeader = {
  text: 'Special',
  value: 'is_special',
  width: '5%',
  sortable: {
    key: 'is_special',
    order: 'ASC',
  },
  transform: (is_prio: boolean) => {
    if (is_prio) {
      return '<i aria-hidden="true" class="v-icon special-icon material-icons theme--light primary--text">star</i>';
    }

    return '';
  },
};

const LastStatusChangeHeader = {
  text: 'Langdurig in status',
  value: 'last_status_change_log',
  width: '5%',
  transform: (last_status_change_log: LastStatusChangeLog) => {
    if (! last_status_change_log || ! last_status_change_log.created_at) {
      return '';
    }

    return `<span class="status-dot ${getAvailabilityColor(last_status_change_log.created_at)}"></span>`;
  },
};

const getAvailabilityColor = (date: string) => {
  const selectedDate = DateTime.fromFormat(date, 'yyyy-LL-dd HH:mm:ss');
  const difference = DateTime.local()
    .diff(selectedDate, 'months')
    .toObject().months;
  const deadlineNumber = Math.floor(difference as any);

  if (deadlineNumber >= 3) {
    return 'red';
  }

  if (deadlineNumber >= 2) {
    return 'orange';
  }
};

const GeplandOpHeader = {
  text: 'Opnamedatum',
  value: 'planned_at',
  width: '5%',
  sortable: {
    key: 'planned_at',
    order: 'DESC',
  },
  transform: (planned_at: string) => {
    if (planned_at === '0000-00-00 00:00:00') {
      return '';
    }
    return formatDate(planned_at, 'dd-LL-yyyy HH:mm');
  },
};

const VerwachteOpleveringHeader = {
  text: 'Verwachte Opleverdatum',
  value: 'expected_delivery_at',
  width: '5%',
  sortable: {
    key: 'expected_delivery_at',
    order: 'DESC',
  },
  transform: (expected_delivery_at: string) => {
    if (expected_delivery_at === '0000-00-00 00:00:00') {
      return '';
    }
    return formatDate(expected_delivery_at, 'dd-LL-yyyy');
  },
};

const StatsBudgetHeader = {
  text: 'Resterende dagen Contract KPI',
  value: 'stats_realtime_budget',
  width: '10%',
  class: 'text-right',
  sortable: {
    key: 'stats_realtime_budget',
    order: 'DESC',
  },
  transform: (stats_realtime_budget: number, report: Report) => {
    if (! stats_realtime_budget) {
      return '';
    }

    let deadlineNumberClass = '';
    let deadlineNumberType = '';

    if (stats_realtime_budget < 0) {
      deadlineNumberClass = 'negativeNumber';
    } else if (stats_realtime_budget >= 0 && stats_realtime_budget < 5) {
      deadlineNumberClass = 'warningNumber';
      deadlineNumberType = '+';
    } else {
      deadlineNumberClass = 'positiveNumber';
      deadlineNumberType = '+';
    }

    // red = Afgekeurd Veldwerk
    // blue-magenta = Voorcontrole
    // orange = Afgekeurd
    // yellow = Voorcontrole
    // gray = default
    let statusHistoryColor = 'gray';

    if (report.has_been_submitted) {
      statusHistoryColor = 'blue-magenta';
    }

    if (report.has_been_tc_rejected) {
      statusHistoryColor = 'orange';
    }

    if (report.has_been_img_rejected) {
      statusHistoryColor = 'red';
    }

    return `
    <span class="statusHistory">
    <span class="mr-3 deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${stats_realtime_budget}</span>
    <span class="ml-3 pl-3 status-dot d-inline-block ${statusHistoryColor}"></span>
    </span>`;
  },
  visible: () => store.state.Auth && store.state.Auth.hasRole(userRoles.PreControllerRoles),
};

const DeadlineHeader = {
  text: 'Deadline',
  value: 'deadline_at',
  width: '10%',
  class: 'text-right',
  sortable: {
    key: 'deadline_at',
    order: 'DESC',
  },
  transform: (deadline_at: string, report: Report) => {
    if (deadline_at === '0000-00-00 00:00:00') {
      return '';
    }

    const deadlineNumber = report.stats_deadline_in_days as number;

    let deadlineNumberClass = '';
    let deadlineNumberType = '';

    if (deadlineNumber < 0) {
      deadlineNumberClass = 'negativeNumber';
    } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
      deadlineNumberClass = 'warningNumber';
      deadlineNumberType = '+';
    } else {
      deadlineNumberClass = 'positiveNumber';
      deadlineNumberType = '+';
    }

    return `<span class="deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${deadlineNumber}</span>`;
  },
};

const AdresHeader = {
  text: 'Adres',
  value: 'address',
  transform: (address: Address) => {
    if (! address) {
      return;
    }
    return `
      ${address.street}
      ${address.number}
      ${address.number_add}
      <br>
      ${address.postcode}
      ${address.city}
    `;
  },
};

const AaanvragerNameHeader = {
  text: 'Aanvrager',
  value: 'applicant',
  transform: (applicant: Aanvrager) => `${applicant?.name || '-'}`,
  visible: () => store.state.isServiceOrganization,
};

const PostcodeHeader = {
  text: 'Postcode',
  value: 'address',
  sortable: {
    key: 'postcode',
    order: 'ASC',
  },
  transform: (address: Address) => {
    if (! address) {
      return;
    }
    return `${address.postcode}`;
  },
};

const SchadesHeader = {
  text: 'Schades',
  value: 'damage_count',
  sortable: {
    key: 'damage_count',
    order: 'ASC',
  },
  transform: (damage_count: string) => `<span class="damage-count">${damage_count}</span>`,
};

const SchadebedragHeader = {
  text: 'Schadebedrag',
  value: 'payout_total',
  sortable: {
    key: 'payout_total',
    order: 'ASC',
  },
  transform: (payout_total: number) => `<span class="damage-count">${currency(payout_total, 'EUR')}</span>`,
};

const StatusHeader = {
  text: 'Status',
  value: 'status',
  sortable: {
    key: 'status',
    order: 'ASC',
  },
  transform: (status: string) => {
    if (! status) {
      return '';
    }
    return `<span class="statusPill statusBackgroundColor--${getStatusColor(status)}">${getStatusLabel(status)}</span>`;
  },
};

const PlanningStatusHeader = {
  text: 'Planning status',
  value: 'planning_status',
  sortable: {
    key: 'planning_status',
    order: 'ASC',
  },
  transform: (planning_status: string) => {
    if (! planning_status) {
      return '';
    }
    return `<span class="statusPill statusBackgroundColor--${getStatusColor(planning_status)}">${getStatusLabel(planning_status)}</span>`;
  },
};

const TagsHeader = {
  text: 'Tags',
  value: 'tags',
  transform: (tags: Tag[]) => parseNameList(tags),
};

const CompetentiesHeader = {
  text: 'Competenties',
  value: 'skills',
  transform: (skills: Skill[]) => parseNameList(skills),
};

const ProblemReasonHeader = {
  text: 'Terugzet reden',
  value: 'problem_key',
  sortable: {
    key: 'problem_key',
    order: 'ASC',
  },
  width: '30%',
  transform: (problem_key: string) => statusProblemsLabel[problem_key],
};

const TypeRapportHeader = {
  text: 'Type rapport',
  value: 'type',
  sortable: {
    key: 'type',
    order: 'ASC',
  },
  transform: (report_type: ReportType, report: Report) => {
    if (report.applicant_submissions) {
      return `<span class="d-flex--center"><span>${report_type.name}</span> <span class="status-dot d-flex--center green ml-1">${report.applicant_submissions}</span></span>`;
    }

    return report_type.name;
  },
};

const BureauHeader = {
  text: 'Expertisebureau',
  value: 'organization',
  width: '8%',
  sortable: {
    key: 'organization',
    order: 'ASC',
  },
  transform: (organization: Organization) => (organization ? organization.name : ''),
};

const DossierbeheerderHeader = {
  text: 'Dossierbeheerder',
  value: 'report_handler',
  width: '8%',
  sortable: {
    key: 'report_handler',
    order: 'ASC',
  },
  transform: (expert: User, report: Report) => (report && report.report_handler ? report.report_handler.name : ''),
};

const OpnemerHeader = {
  text: 'Opnemer',
  value: 'expert',
  sortable: {
    key: 'expert',
    order: 'ASC',
  },
  transform: (expert: User) => {
    if (! expert) {
      return '';
    }
    return expert.name;
  },
};

const UserCompanyName = {
  text: 'Bedrijfsnaam',
  value: 'expert',
  sortable: {
    key: 'expert',
    order: 'ASC',
  },
  transform: (expert: User) => {
    if (! expert) {
      return '';
    }
    return expert.company_name;
  },
};

const SecondExpertHeader = {
  text: 'Deskundige (indien anders)',
  value: 'second_expert',
  sortable: {
    key: 'second_expert',
    order: 'ASC',
  },
  transform: (second_expert: User) => {
    if (! second_expert) {
      return '';
    }
    return second_expert.name;
  },
};

const DeskundigeTCHeader = {
  text: 'Deskundige / TC',
  value: 'organization_expert',
  width: '8%',
  transform: (expert: User, report: Report) => {
    if (! expert) {
      return '';
    }

    const tcName = report && report.organization_tc ? `/ ${report.organization_tc.name}` : '';

    return `${report.organization_expert ? report.organization_expert.name : ''} ${tcName}`;
  },
};

const IsOnlineHeader = {
  text: 'Is online',
  value: 'is_offline',
  transform: (is_offline: boolean) => `<span class="status-dot ${is_offline}"></span>`,
  visible: () => ! store.state.isServiceOrganization,
};

const OpnameVariant = {
  text: 'Opname variant',
  value: 'opname_variant',
  transform: (opname_variant: string) => {
    switch (opname_variant) {
      case 'opname':
        return 'Opname';
      case 'zienswijze':
        return 'Zienswijze';
      case 'heropname':
        return 'Heropname';
      default:
        break;
    }
  },
};

const VerstuurdAanAanvrager = {
  text: 'Verstuurd aan Aanvrager',
  value: 'downloaded_at',
  sortable: {
    key: 'downloaded_at',
    order: 'DESC',
  },
  transform: (downloaded_at: string) => {
    if (! downloaded_at || downloaded_at === '0000-00-00 00:00:00') {
      return '';
    }
    return formatDate(downloaded_at);
  },
};

const Indieningsdatum = {
  text: 'Indieningsdatum',
  value: 'applicant_submitted_at',
  width: '10%',
  sortable: {
    key: 'applicant_submitted_at',
    order: 'DESC',
  },
  transform: (applicant_submitted_at: string) => {
    if (applicant_submitted_at === '0000-00-00') {
      return '';
    }
    return formatDate(applicant_submitted_at, 'dd-LL-yyyy');
  },
};

const SixMonthDeadlineHeader = {
  text: '26 weken',
  value: 'planned_at',
  sortable: {
    key: 'planned_at',
    order: 'ASC',
  },
  transform: (planned_at: string) => {
    if (! planned_at || planned_at === '0000-00-00 00:00:00') {
      return '';
    }

    const plannedAt = DateTime.fromFormat(planned_at, 'yyyy-LL-dd HH:mm:ss');
    const sixMonths = plannedAt.plus({ weeks: 26 }).toFormat('yyyy-LL-dd HH:mm:ss');

    return formatDate(sixMonths);
  },
};

const FtrConfirmation = {
  text: 'FTR Bevestigd',
  value: 'stats_first_time_right_confirmed',
  class: 'text-xs-left',
  transform: (datatable: any, report: Report) => {
    if (! report) {
      return;
    }

    let color = 'orange';
    if (report.stats_first_time_right_confirmed == 'confirmed') {
      color = 'green';
    }
    if (report.stats_first_time_right_confirmed == 'rejected') {
      color = 'red';
    }

    return `<span class="d-inline-block status-dot ${color}"></span>`;
  },
};

const FtrRejectReasonBureau = {
  text: 'Afkeurredenen',
  value: 'stats_first_time_right_bureau_reasons',
  transform: (stats_first_time_right_bureau_reasons: string[], report: Report) => {
    let names = '';
    stats_first_time_right_bureau_reasons.forEach((key: string) => {
      names += `${getFirstTimeRightName(key, false)}, <br>`;
    });

    return names;
  },
};

const FtrComment = {
  text: 'Toelichting',
  value: 'stats_first_time_right_bureau_comment',
};

const ControleurHeader = {
  text: 'Voorcontrole',
  value: 'pre_controller',
  transform: (precontroller: User, report: Report) => precontroller?.name,
  visible: () => ! store.state.isServiceOrganization,
};

const TCHeader = {
  text: "TC'er",
  value: 'technisch_coordinator',
  transform: (technisch_coordinator: User, report: Report) => technisch_coordinator?.name,
};

const TCHeaderHiddenForImg = {
  ...TCHeader,
  visible: () => ! store.state.isServiceOrganization,
};

const WorkflowHeader = {
  text: 'Workflow',
  value: 'workflow',
  sortable: {
    key: 'workflow',
    order: 'DESC',
  },
  transform: (workflow: string, report: Report) => new WorkFlowFilter().getItemName(workflow),
};

const ManagementClassHeader = {
  text: 'Management info',
  value: 'management_info_class',
  sortable: {
    key: 'management_info_class',
    order: 'DESC',
  },
  transform: (management_info_class: string, report: Report) => new ManagementClassFilter().getItemName(management_info_class),
};

const OpnameVariantHeader = {
  text: 'Opname variant',
  value: 'opname_variant',
  sortable: {
    key: 'opname_variant',
    order: 'DESC',
  },
  transform: (opname_variant: string, report: Report) => OpnameVariantLabels[opname_variant],
};

const OpnameDatumHeader = {
  text: 'Opnamedatum',
  value: 'planned_at',
  transform: (planned_at: string) => {
    if (planned_at === '0000-00-00 00:00:00') {
      return '';
    }
    return formatDate(planned_at, 'dd-LL-yyyy HH:mm');
  },
  sortable: {
    key: 'planned_at',
    order: 'DESC',
  },
  width: '5%',
};

export const userPreferencesReportHeadersDefaultDefaults: TableHeaderDefaultsKeyed = {
  ZaaknummerHeader,
  PrioHeader,
  GeplandOpHeader,
  DeadlineHeader,
  StatsBudgetHeader,
  TypeRapportHeader,
  WorkflowHeader,
  ManagementClassHeader,
  OpnameVariantHeader,
  OpnemerHeader,
  AaanvragerNameHeader,
  AdresHeader,
  SchadesHeader,
  SchadebedragHeader,
  StatusHeader,
  TagsHeader,
  CompetentiesHeader,
  SpecialHeader,
  IsOnlineHeader,
};

const headers: { [key: string]: TableHeader[] } = {
  default: TableHeaderPreferenceHelper.getInstance().getCleanPreferencesForReading([], userPreferencesReportHeadersDefaultDefaults),
  tcmg: [
    ZaaknummerHeader,
    PrioHeader,
    Indieningsdatum,
    GeplandOpHeader,
    DeadlineHeader,
    StatsBudgetHeader,
    {
      text: 'Opgeleverd op',
      value: 'approved_at',
      width: '7%',
      sortable: {
        key: 'approved_at',
        order: 'DESC',
      },
      transform: (approved_at: string) => {
        if (approved_at === '0000-00-00 00:00:00') {
          return '';
        }
        return formatDate(approved_at, 'dd-LL-yyyy');
      },
    },
    BureauHeader,
    DeskundigeTCHeader,
    DossierbeheerderHeader,
    AdresHeader,
    SchadesHeader,
    SchadebedragHeader,
    StatusHeader,
    TagsHeader,
    CompetentiesHeader,
    TypeRapportHeader,
    OpnameVariantHeader,
    IsOnlineHeader,
  ],
  veldwerk: [
    ZaaknummerHeader,
    PrioHeader,
    DeadlineHeader,
    DossierbeheerderHeader,
    TagsHeader,
    TypeRapportHeader,
    OpnameVariantHeader,
    Indieningsdatum,
    SchadesHeader,
    BureauHeader,
    AdresHeader,
    SchadebedragHeader,
    DeskundigeTCHeader,
    {
      text: 'Opnamedatum',
      value: 'planned_at',
      transform: (planned_at: string) => {
        if (planned_at === '0000-00-00 00:00:00') {
          return '';
        }
        return formatDate(planned_at, 'dd-LL-yyyy HH:mm');
      },
      sortable: {
        key: 'planned_at',
        order: 'DESC',
      },
      width: '5%',
    },
    CompetentiesHeader,
  ],
  bezwaar: [
    ZaaknummerHeader,
    AaanvragerNameHeader,
    AdresHeader,
    PrioHeader,
    DeadlineHeader,
    DossierbeheerderHeader,
    TagsHeader,
    TypeRapportHeader,
    Indieningsdatum,
    SchadesHeader,
    BureauHeader,
    SchadebedragHeader,
    DeskundigeTCHeader,
    OpnameVariantHeader,
    OpnameDatumHeader,
    CompetentiesHeader,
  ],
  bezwaar_planning: [
    ZaaknummerHeader,
    AaanvragerNameHeader,
    AdresHeader,
    PrioHeader,
    DeadlineHeader,
    PlanningStatusHeader,
    DossierbeheerderHeader,
    TagsHeader,
    TypeRapportHeader,
    Indieningsdatum,
    SchadesHeader,
    BureauHeader,
    SchadebedragHeader,
    DeskundigeTCHeader,
    OpnameVariantHeader,
    OpnameDatumHeader,
    CompetentiesHeader,
  ],
  veldwerk_herzien: [ZaaknummerHeader, PrioHeader, DeadlineHeader, DossierbeheerderHeader, TagsHeader, TypeRapportHeader, OpnameVariantHeader, Indieningsdatum, SchadesHeader, SchadebedragHeader, BureauHeader],
  problem_tcmg: [ZaaknummerHeader, BureauHeader, ProblemReasonHeader, PrioHeader, DeadlineHeader, TypeRapportHeader, OpnameVariantHeader, AdresHeader, TagsHeader, CompetentiesHeader],
  simple: [
    {
      text: 'Zaaknummer',
      value: 'case_number',
      action: '/schade-regelen/{id}',
      sortable: {
        key: 'case_number',
        order: 'ASC',
      },
    },
    AdresHeader,
    SchadebedragHeader,
    StatusHeader,
    IsOnlineHeader,
  ],
  werkvoorbereiding: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, AdresHeader, TagsHeader, CompetentiesHeader, StatusHeader],
  backToWerkvoorbereiding: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, AdresHeader, TagsHeader, CompetentiesHeader, SchadesHeader, StatusHeader],
  planning: [ZaaknummerHeader, LastStatusChangeHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, AdresHeader, TagsHeader, CompetentiesHeader, StatusHeader],
  planningBureauOrganization: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, StatsBudgetHeader, OpnemerHeader, AdresHeader, TagsHeader, CompetentiesHeader, StatusHeader, SpecialHeader],
  project: [ZaaknummerHeader, Indieningsdatum, StatusHeader, TypeRapportHeader, OpnameVariantHeader, OpnameVariant, AdresHeader, OpnemerHeader, PrioHeader, SpecialHeader, CompetentiesHeader, TagsHeader],
  project_planning: [ZaaknummerPlanningHeader, PostcodeHeader, Indieningsdatum, TypeRapportHeader, OpnameVariantHeader, PrioHeader, SpecialHeader, PlanningStatusHeader],
  planningBureau: [
    {
      text: 'Aanvraag onderzoek',
      value: 'planning_specials',
      transform: (planning_specials: { [key: string]: string }) => {
        if (planning_specials && planning_specials.research_request_date) {
          return formatDate(planning_specials.research_request_date);
        }
      },
    },
    OpnameVariant,
    ZaaknummerHeader,
    {
      text: 'Straat',
      value: 'address',
      transform: (address: Address) => {
        if (! address) {
          return;
        }
        return address.street;
      },
    },
    {
      text: 'Huisnummer + toev',
      value: 'address',
      transform: (address: Address) => {
        if (! address) {
          return;
        }
        return `
          ${address.number}
          ${address.number_add}
        `;
      },
    },
    {
      text: 'Postcode',
      value: 'address',
      transform: (address: Address) => {
        if (! address) {
          return;
        }
        return address.postcode;
      },
    },
    {
      text: 'Plaats',
      value: 'address',
      transform: (address: Address) => {
        if (! address) {
          return;
        }
        return address.city;
      },
    },
    {
      text: 'Type onderzoek',
      value: 'planning_status',
      transform: (planning_status: string) => {
        if (! planning_status) {
          return '';
        }
        return `<span class="statusPill statusBackgroundColor--${getStatusColor(planning_status)}">${getStatusLabel(planning_status)}</span>`;
      },
    },
    {
      text: 'Type onderzoek aangevraagd door',
      value: 'planning_specials',
      transform: (planning_specials: { [key: string]: string }) => {
        if (planning_specials && planning_specials.research_requested_by) {
          return planning_specials.research_requested_by;
        }
      },
    },
    StatusHeader,
    {
      text: 'Datum afspraak onderzoek',
      value: 'planning_specials',
      transform: (planning_specials: { [key: string]: string }) => {
        if (planning_specials && planning_specials.inspection_date) {
          return formatDate(planning_specials.inspection_date);
        }
      },
    },
    {
      text: 'Tijdsduur',
      value: 'planning_specials',
      transform: (planning_specials: { [key: string]: string }) => {
        if (planning_specials && planning_specials.request_duration) {
          return planning_specials.request_duration;
        }
      },
    },
    {
      text: 'Externe partij',
      value: 'planning_specials',
      transform: (planning_specials: { [key: string]: string }) => {
        if (planning_specials && planning_specials.external_party) {
          return planning_specials.external_party;
        }
      },
    },
    {
      text: 'Inplannen',
      value: 'planning_specials.planning_custom',
      editable: true,
      editableKey: 'planning_custom',
      transform: (planning_specials: { [key: string]: string }, report: Report) => {
        if (planning_specials && planning_specials.planning_custom) {
          return planning_specials.planning_custom;
        }

        return '';
      },
    },
  ],
  bureau: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, StatsBudgetHeader, OpnemerHeader, SecondExpertHeader, GeplandOpHeader, ControleurHeader, TCHeaderHiddenForImg, AdresHeader, StatusHeader, SchadesHeader, SchadebedragHeader, TagsHeader, CompetentiesHeader, SpecialHeader, IsOnlineHeader],
  submitted: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, StatsBudgetHeader, OpnemerHeader, ControleurHeader, TCHeaderHiddenForImg, SecondExpertHeader, GeplandOpHeader, AdresHeader, StatusHeader, SchadesHeader, SchadebedragHeader, TagsHeader, CompetentiesHeader, SpecialHeader, IsOnlineHeader],
  expert: [ZaaknummerHeader, PrioHeader, TypeRapportHeader, OpnameVariantHeader, DeadlineHeader, StatsBudgetHeader, OpnemerHeader, UserCompanyName, SecondExpertHeader, GeplandOpHeader, AdresHeader, StatusHeader, SchadesHeader, SchadebedragHeader, TagsHeader, CompetentiesHeader, SpecialHeader, IsOnlineHeader],
  ftr_logboek_bureau_as_bureau: [FtrConfirmation, VerstuurdAanAanvrager, ZaaknummerHeader, TypeRapportHeader, OpnameVariantHeader, OpnemerHeader, FtrRejectReasonBureau, FtrComment,
    {
      text: 'Bedrijfsnaam',
      value: 'stats_first_time_right_tcmg_comment',
      transform: (stats_first_time_right_tcmg_reasons: string[], report: Report) => report.applicant?.company || report.intermediary?.company || '',
    },
    {
      text: 'Deskundige',
      value: 'expert',
      transform: (expert: User, report: Report) => expert?.name,
    },
    ControleurHeader,
    TCHeader,
  ],
  ftr_logboek_bureau_as_img: [VerstuurdAanAanvrager, ZaaknummerHeader, TypeRapportHeader, OpnameVariantHeader, FtrRejectReasonBureau, FtrComment,
    {
      text: 'Bedrijfsnaam',
      value: 'stats_first_time_right_tcmg_comment',
      transform: (stats_first_time_right_tcmg_reasons: string[], report: Report) => report.applicant?.company || report.intermediary?.company || '',
    },
    {
      text: 'Deskundige',
      value: 'expert',
      transform: (expert: User, report: Report) => expert?.name,
    },
    ControleurHeader,
    TCHeader,
  ],
  kpi_overdue: [ZaaknummerHeader, TypeRapportHeader, OpnameVariantHeader, StatusHeader, SpecialHeader, StatsBudgetHeader, VerwachteOpleveringHeader, SixMonthDeadlineHeader, TagsHeader],
};

const sortOptions: { [key: string]: { [key: string]: TableSort } } = {
  default: {
    default: {
      key: 'deadline_at',
      order: 'DESC',
    },
  },
  bureau: {
    default: {
      key: 'stats_realtime_budget',
      order: 'ASC',
    },
    admin: {
      key: 'stats_realtime_budget',
      order: 'ASC',
    },
  },
  submitted: {
    default: {
      key: 'stats_realtime_budget',
      order: 'ASC',
    },
  },
  planning: {
    default: {
      key: 'created_at',
      order: 'ASC',
    },
  },
  veldwerk: {
    default: {
      key: 'deadline_at',
      order: 'ASC',
    },
  },
};

const parseNameList = (array: any) => {
  let names = '';

  if (! array) {
    return names;
  }

  array.forEach((object: any, index: number) => {
    index == 0 ? (names += `${object.name}`) : (names += `, ${object.name}`);
  });

  return names;
};

const statusToHeaderKey: { [key: string]: string } = {
  approved: 'veldwerk',
  veldwerk_corrected: 'veldwerk',
  veldwerk_onhold: 'veldwerk',
  submit_no_test: 'veldwerk',
  problem_tcmg: 'problem_tcmg',
  specials_reviewed: 'veldwerk',

  planning: 'planning',
  back_to_planning: 'planning',
  planning_organization: 'planningBureauOrganization',
  created: 'werkvoorbereiding',
  werkvoorbereiding_rejected: 'backToWerkvoorbereiding',
  opname_werkvoorbereiding_done: 'backToWerkvoorbereiding',

  opname_in_progress: 'expert',
  opname_rejected: 'expert',
  in_progress: 'expert',
  rejected: 'expert',
  opname_done: 'expert',

  submitted: 'submitted',
  checking: 'bureau',
  veldwerk_rejected: 'submitted',

  objection_created: 'bezwaar',
  objection_approved: 'bezwaar',
  objection_corrected: 'bezwaar',
  objection_pending_close: 'bezwaar',
  objection_closed: 'bezwaar',
  objection_werkvoorbereiding_rejected: 'bezwaar_planning',
  objection_pending_assignment: 'bezwaar',

  zienswijze_werkvoorbereiding_rejected: 'veldwerk_herzien',
  zienswijze_approved: 'veldwerk_herzien',
  zienswijze_corrected: 'veldwerk_herzien',
  zienswijze_closed: 'veldwerk_herzien',
  zienswijze_onhold: 'veldwerk_herzien',

  nader_onderzoek: 'planningBureau',
  vervolgopname: 'planningBureau',
  aanvullend_onderzoek: 'planningBureau',
  heropname: 'planningBureau',

  project: 'project',
  project_planning: 'project_planning',
};

export const reportHeadersFromKey = (key: string) => headers[key];

export const statusToReportHeaders = (status: string, isServiceOrganization: boolean, user: User, userPreferences: null | UserPreference[] = null, headersName = '') => {
  switch (headersName) { // 'headersName' allow to use headers of a status without filtering on that status
    case 'rapportage-production':
      status = 'approved';
      break;
    default:
      break;
  }

  let key = 'default';
  if (isServiceOrganization) {
    key = 'tcmg';
  }

  key = statusToHeaderKey[status] ? statusToHeaderKey[status] : 'default';

  if (user && user.hasRole(['simple', 'simple_plus'])) {
    key = 'simple';
  }

  switch (key) {
    case 'default':
      // eslint-disable-next-line no-case-declarations
      const userPreferencesReportHeadersDefault = getUserPreference(userPreferences || [], UserPreferenceEnum.REPORT_HEADERS__DEFAULT);
      return TableHeaderPreferenceHelper.getInstance().getCleanPreferencesForReading(userPreferencesReportHeadersDefault || [], userPreferencesReportHeadersDefaultDefaults);
    default:
      return headers[key];
  }
};

export const statusToReportSort = (status: string, user: User, headersName = '') => {
  switch (headersName) { // 'headersName' allow to use headers of a status without filtering on that status
    case 'rapportage-production':
      status = 'approved';
      break;
    default:
      break;
  }

  let key = 'default';
  const role = user && user.type ? user.type : 'default';

  key = statusToHeaderKey[status] ? statusToHeaderKey[status] : 'default';

  if (! sortOptions[key]) {
    return sortOptions.default.default;
  }

  return sortOptions[key][role] ? sortOptions[key][role] : sortOptions[key].default;
};
