import { Model } from '@/models/Model';

export class Lock extends Model {
  protected $name = 'Lock';

  protected $endpoint = '/locks';

  protected $fillable: string[] = [];

  public id?: string;

  public is_locked?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public lock(payload: {[key: string]: string}) {
    const url = '/locks/lock';

    return this.request('post', url, payload);
  }

  public unlock(payload: {[key: string]: string}) {
    const url = '/locks/unlock';

    return this.request('post', url, payload);
  }

  public checkLock(payload: {[key: string]: string}) {
    const url = '/locks/check-lock';

    return this.request('post', url, payload);
  }
}
