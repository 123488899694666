import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report, Tag } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { userLevelLabels } from '@/models/User';

@Component<ReportTagsSkills>({})
export default class ReportTagsSkills extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  @Prop()
  protected report!: Report;

  protected tags: Tag[] | undefined = [];

  protected selectedTags: any = [];

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.Auth.hasRole('expert') && this.report.archive_info && this.report.archive_info.is_archived_for_expert) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected async mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.tags = cloneDeep(this.report.tags);
    this.isLoading = false;
  }

  protected cancelEditTags() {
    this.selectedTags = [];
    this.isEditing = false;
    this.initialize();
  }

  protected saveReportTags(tags: Tag[]) {
    this.selectedTags = tags;
  }

  protected updateReport() {
    this.isUpdating = true;

    new Report()
      .convertTags(this.$route.params.id, {
        tags: this.selectedTags,
      })
      .then(() => {
        this.$emit('reloadReport');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isUpdating = false;
        this.isEditing = false;
      });
  }

  protected get userLevelLabels() {
    return userLevelLabels;
  }
}
