/**
 * Naming Vragen aan Paul
 */

export enum ReportTypes {
  OLD_FORMAT_AANNEMERS_VARIANT = '8bb4800a-32a9-4f15-b175-df273fc801ad',
  AANNEMERS_VARIANT = 'feeda287-d0e8-4aee-9fb0-e372ad14b6aa',
  ADVIESRAPPORT_BEZWAAR = '8dad08cb-f9e8-440d-965a-d8c86979dfa6',
  ADVIESRAPPORT_BEZWAAR_ADDENDUM = 'f6c7f2ca-dff0-4460-a1f2-c63863a0d1f4',
  ADVIESRAPPORT_BEZWAAR_HOORZITTING = '50119db9-5cec-4ba8-9654-0f6275de0d53',
  ADVIESRAPPORT_BEZWAAR_AMBTELIJK = 'dfdcaf2d-24ba-4318-b424-5236cf5dd1d2',
  // wat is CW CAT 1 etc
  CW_CAT_1 = '8b888db1-8f94-419a-84d0-7de98eab86ec',
  CW_CAT_2 = '8ba62ed8-7d78-4c35-9666-97062391a57b',
  CW_CAT_3 = '8ba62f08-5206-42b0-a292-7224650e5dfc',
  CW_CAT_4 = '8ba62f41-a593-4318-91ac-c76c15cefa57',
  EERDER_BESTAANDE_SCHADE = '8bc60fad-ca3d-43a3-8ddf-d20d9e12b596',
  ENKELVOUDIG = '8b79d970-6e90-4ed6-8d39-ddd7e8e5de50',
  REGULIER = '8e11630c-5d5e-45f6-ad26-e8b2df78aa10',
  STUWMEERREGELING = '8df83997-29b0-4136-9817-a01274e52811',
  OPNAMEOPAFSTAND = '2d01c12b-22e5-486f-8dc9-3791cefea794',
  // wat is WONING_COOPERATIE 1 etc
  WONING_COOPERATIE_1 = '8db2e68b-cf14-4a27-b60e-80dc74fa516f',
  WONING_COOPERATIE_1_NEW = '8ebab73d-e6f7-4b39-8153-edb768802e45',
  WONING_COOPERATIE_2 = '8d97043b-3ee3-4448-9881-8e9bcdd76efd',
  WONING_COOPERATIE_2_NEW = '8ebab749-16c7-4af6-b022-4d975ba89008',
  OPNEMER = '8ed775f6-f373-4879-b2dd-45feebaa62d1',
  EIGEN_AANNEMER = '8e6ae76d-a534-4057-b45f-b7153d3424f0',
  ZELF_OPNAME = '0066669d-21b5-4b5c-ba58-74f4e75f95b9',
  REGULIER_2021 = 'a5cb7861-22e1-11eb-b142-0679c6d9ea22',
  IMMATERIELESCHADE = 'f00655f1-291c-11eb-b142-0679c6d9ea22',
  // ZELF_OPNAME = '32a124ea-74d8-4625-af41-9425c46d8d0d',
  BEZWAAR_2021 = '1ee14a00-3e20-4cb9-b202-1b1fd23f5b70',
  VES_NULMETING = '5a930faa-1736-400f-b583-aff7f0cdd36b',
  BEZWAAR_AMBTELIJK_2 = '0618a028-8576-446a-be32-118096431677',
  BEZWAAR_AMBTELIJK_2_CALCULATIE = '273cbc67-6dc1-4bd2-a000-4eadbb5da8b4',
  BEZWAAR_BAC_2 = 'fa6eebc2-c639-4468-907d-afaa1089a4f4',
  BEZWAAR_BAC_2_CALCULATIE = 'a1a79dde-2c6b-4542-81e4-2b816beeb718',
}

// export const reportTypeNames: {[key: string] : string} =  {
//   'feeda287-d0e8-4aee-9fb0-e372ad14b6aa': 'Aannemersvariant',
//   '8dad08cb-f9e8-440d-965a-d8c86979dfa6': 'Adviesrapport Bezwaar',
//   '8b888db1-8f94-419a-84d0-7de98eab86ec': 'CVW cat 1',
//   '8ba62ed8-7d78-4c35-9666-97062391a57b': 'CVW cat 2',
//   '8ba62f08-5206-42b0-a292-7224650e5dfc': 'CVW cat 3',
//   '8ba62f41-a593-4318-91ac-c76c15cefa57': 'CVW cat 4',
//   '8e6ae76d-a534-4057-b45f-b7153d3424f0': 'Eigen Aannemer',
//   '8ed775f6-f373-4879-b2dd-45feebaa62d1': 'Opnemersvariant',
//   '8bb4800a-32a9-4f15-b175-df273fc801ad': 'Oud Format - Aannemersvariant',
//   '8bc60fad-ca3d-43a3-8ddf-d20d9e12b596': 'Oud Format - EBS',
//   '8b79d970-6e90-4ed6-8d39-ddd7e8e5de50': 'Oud Format - Enkelvoudig',
//   '8db2e68b-cf14-4a27-b60e-80dc74fa516f': 'Oud Format - WoCo v1',
//   '8d97043b-3ee3-4448-9881-8e9bcdd76efd': 'OuAB91C5d Format - WoCo v2',
//   '8e11630c-5d5e-45f6-ad26-e8b2df78aa10': 'Regulier',
//   '8df83997-29b0-4136-9817-a01274e52811': 'Stuwmeerregeling',
//   '8ebab73d-e6f7-4b39-8153-edb768802e45': 'WoCo v1',
//   '8ebab749-16c7-4af6-b022-4d975ba89008': 'WoCo v2',
// };

export const reportTypeColors: {[key: string] : string} = {
  'feeda287-d0e8-4aee-9fb0-e372ad14b6aa': '#F0CB69',
  '8dad08cb-f9e8-440d-965a-d8c86979dfa6': '#F0CB69',
  '8b888db1-8f94-419a-84d0-7de98eab86ec': '#F0CB69',
  '8ba62ed8-7d78-4c35-9666-97062391a57b': '#F0CB69',
  '8ba62f08-5206-42b0-a292-7224650e5dfc': '#F0CB69',
  '8ba62f41-a593-4318-91ac-c76c15cefa57': '#F0CB69',
  '8e6ae76d-a534-4057-b45f-b7153d3424f0': '#F0CB69',
  '8ed775f6-f373-4879-b2dd-45feebaa62d1': '#F0CB69',
  '8bb4800a-32a9-4f15-b175-df273fc801ad': '#F0CB69',
  '8bc60fad-ca3d-43a3-8ddf-d20d9e12b596': '#6D53DC',
  '8b79d970-6e90-4ed6-8d39-ddd7e8e5de50': '#B8DBEB',
  '8db2e68b-cf14-4a27-b60e-80dc74fa516f': '#AB91C5',
  '8d97043b-3ee3-4448-9881-8e9bcdd76efd': '#AB91C5',
  '8e11630c-5d5e-45f6-ad26-e8b2df78aa10': '#8EC2A7',
  '8df83997-29b0-4136-9817-a01274e52811': '#DC5355',
  '8ebab73d-e6f7-4b39-8153-edb768802e45': '#AB91C5',
  '8ebab749-16c7-4af6-b022-4d975ba89008': '#AB91C5',
  '32a124ea-74d8-4625-af41-9425c46d8d0d': '#F0CB69',
};
