import { Event } from '@/models/Event';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';

@Component<EventNoteDialog>({})
export default class EventNoteDialog extends Vue {
  @Prop()
  protected event!: Event

  protected note = '';

  protected isSaving = false;

  protected mounted() {
    if (this.event) {
      this.fetchEvent();
    }
  }

  protected fetchEvent() {
    new Event()
      .include('note')
      .find(this.event.id)
      .then((event: Event) => {
        this.note = event.note || '';
      });
  }

  protected updateEvent() {
    if (! this.event || this.isExpert) {
      return;
    }

    this.isSaving = true;

    this.event
      .update({ note: this.note })
      .then(() => {
        this.$emit('eventUpdated', this.event.id);
        setTimeout(() => {
          this.isSaving = false;
        }, 300);
      });
  }

  protected get isExpert() {
    return this.$store.state.Auth && (this.$store.state.Auth as User).hasRole('expert');
  }

  protected close() {
    this.$emit('input', false);
    this.isSaving = false;
    this.note = '';
  }

  protected confirm() {
    this.$emit('eventUpdated');
  }
}
