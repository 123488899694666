import { Model } from '@/models/Model';

export class Holiday extends Model {
  protected $name = 'Holiday';

  protected $endpoint = '/holidays';

  protected $primaryKey = 'id';

  protected $fillable: string[] = [
    'user',
    'starts_at',
    'ends_at',
    'status',
  ];

  public id?: string;

  public user?: string;

  public starts_at?: string;

  public ends_at?: string;

  public status?: Status;

  public comment?: string;
}

export type Status = 'pending' | 'rejected' | 'approved';

export const holidayStatusLabels: {[key: string] :string} = {
  pending: 'In behandeling',
  rejected: 'Afgewezen',
  approved: 'Goedgekeurd',
};

export const holidayStatusColors: {[key: string] :string} = {
  pending: 'yellow',
  rejected: 'red',
  approved: 'green',
};

export interface CreateHolidayPayload {
  user: string,
  starts_at: string,
  ends_at: string,
  comment: string,
  status?: Status,
}
