import { User } from '@/models/User';
import { Model } from '@/models/Model';
import { CostSpecification } from '@/models/CostSpecification';

export class CostSpecificationLine extends Model {
  protected $name = 'CostSpecificationLine';

  protected $endpoint = '/cost-specification-lines';

  protected $fillable: string[] = [];

  public id!: string;

  public specification?: CostSpecification;

  public user?: User;

  public duration?: Duration;

  public user_name!: string;

  public type!: string;

  public report_name!: string;

  public report_lite!: ReportLite;

  public unitprice!: number;

  public subtotal!: number;

  public date!: string;

  public created_at!: string;

  public updated_at!: string;

  public resolvePrimaryKey(): string {
    return this.id;
  }
}

export interface Duration {
  seconds: number;
  minutes: number;
  hours: number;
}

interface ReportLite {
  case_number: string;
  id: string;
  status: string;
}
