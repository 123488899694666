import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AHasNestedBindingsMixin extends Vue {
  protected getChildListeners(listeners: Record<string, Function | Function[]>): Record<string, Function | Function[]> {
    return { ...listeners, ...this.$listeners };
  }

  protected getChildListenersExcept(listeners: Record<string, Function | Function[]>, blacklist: string[] = []): Record<string, Function | Function[]> {
    const mergedListeners = this.getChildListeners(listeners);

    Object.keys(mergedListeners).forEach((key) => {
      if (blacklist.includes(key)) {
        delete mergedListeners[key];
      }
    });
    return mergedListeners;
  }

  protected getChildAttributes(attrs: Record<string, string>): Record<string, string> {
    return { ...attrs, ...this.$attrs };
  }

  protected getChildAttributesExcept(attrs: Record<string, string>, blacklist: string[] = []): Record<string, string> {
    const attributes = this.getChildAttributes(attrs);

    Object.keys(attributes).forEach((key) => {
      if (blacklist.includes(key)) {
        delete attributes[key];
      }
    });

    return attributes;
  }
}
