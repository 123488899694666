var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "pageHeader" },
    [
      _c(
        "v-layout",
        {
          staticClass: "search--row",
          attrs: { row: "", "justify-start": "", "align-baseline": "" }
        },
        [
          _vm.avatar.length
            ? _c("v-flex", { staticClass: "avatarContainer" }, [
                _c("img", { staticClass: "avatar", attrs: { src: _vm.avatar } })
              ])
            : _vm._e(),
          _c(
            "v-flex",
            [
              _c("Breadcrumbs", { attrs: { crumbs: _vm.breadcrumbs } }),
              _c("h1", [_vm._v(_vm._s(_vm.pageTitle))])
            ],
            1
          ),
          _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-end": "", "align-baseline": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "flex-starving" },
                    [_vm._t("default")],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }