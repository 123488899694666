var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AStatefulForm",
    _vm._g(
      _vm._b(
        {
          ref: "aStatefulForm",
          staticClass: "atabix-stateful-form-for-filters collapse-inputs",
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(attrs) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "atabix-stateful-form-for-filters__inner"
                      },
                      [_vm._t("default", null, null, attrs)],
                      2
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        "AStatefulForm",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }