import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<MoreInfoDialog>({})
export default class MoreInfoDialog extends Vue {
  @Prop({ default: 'Meer info' })
  protected title!: string;

  @Prop({ default: '' })
  protected description!: string;

  protected isLoading = false;

  protected mounted() {

  }

  protected close() {
    this.$emit('input', false);
  }
}

export interface MoreInfo {
  title?: string;
  description?: string;
}
