import { Model } from '@/models/Model';

export class PostalCode extends Model {
  protected $name = 'PostalCode';

  protected $endpoint = '/postal-codes';

  protected $fillable: string[] = ['id', 'is_excluded'];

  public name?: string;

  public id?: string;

  public code?: string;

  public is_excluded?: boolean;
}
