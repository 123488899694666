var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "miDialog" },
    [
      !_vm.options
        ? _c(
            "v-card",
            { class: "error-dialog" },
            [_c("v-card-text", [_vm._v("Loading...")])],
            1
          )
        : _vm._e(),
      _vm.options
        ? _c(
            "div",
            [
              _vm.settings
                ? _c(
                    "v-card",
                    {
                      staticClass: "miDialog--card",
                      class: _vm.settings.type + "-dialog"
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "miDialog--card__text" },
                        [
                          _vm.settings.type === "success"
                            ? _c(
                                "div",
                                { staticClass: "icon" },
                                [_c("v-icon", [_vm._v("check_circle")])],
                                1
                              )
                            : _vm._e(),
                          _vm.settings.type === "warning"
                            ? _c(
                                "div",
                                { staticClass: "icon" },
                                [_c("v-icon", [_vm._v("warning")])],
                                1
                              )
                            : _vm._e(),
                          _vm.settings.type === "error"
                            ? _c(
                                "div",
                                { staticClass: "icon" },
                                [_c("v-icon", [_vm._v("error")])],
                                1
                              )
                            : _vm._e(),
                          _c("h2", {
                            domProps: { innerHTML: _vm._s(_vm.settings.title) }
                          }),
                          _c("p", {
                            domProps: { innerHTML: _vm._s(_vm.settings.text) }
                          }),
                          _c(
                            "v-layout",
                            {
                              class: [
                                _vm.hasCancelButton
                                  ? "justify-space-between"
                                  : "justify-center"
                              ],
                              attrs: { wrap: "" }
                            },
                            [
                              _vm.hasCancelButton
                                ? [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color:
                                            _vm.settings.buttons.cancel.color,
                                          outline: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.cancel($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.settings.buttons.cancel.text
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e(),
                              _vm.hasConfirmButton
                                ? [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color:
                                            _vm.settings.buttons.confirm.color
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.confirm($event)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.settings.buttons.confirm.text
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }