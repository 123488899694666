import { Model } from '@/models/Model';

export class CancelReason extends Model {
  protected $name = 'CancelReason';

  protected $endpoint = '/cancel-reasons';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;
}
