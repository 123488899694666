var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "breadcrumbList", attrs: { "align-center": "" } },
    [
      _vm._l(_vm.crumbs, function(crumb, index) {
        return [
          _c("Breadcrumb", {
            key: index,
            staticClass: "breadcrumb--item",
            attrs: { crumb: crumb }
          }),
          index < _vm.crumbs.length - 1
            ? _c("Divider", {
                key: "d" + index,
                staticClass: "breadcrumb--item"
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }