import { Model } from '@/models/Model';
import mondayClient from '@/support/MondayClient';
import { AxiosError } from 'axios';
import { isObject } from 'lodash';

export class Asset extends Model {
  protected $name = 'Asset';

  protected $endpoint = '/assets';

  protected $fillable: string[] = [];

  // authenticity_token: 5778532d7cdf2ec0f8cf875f593c22e55f30c4244a09689dfdbb771e8324a
  // asset[resource]: (binary)

  public id?: string;

  public asset?: MondayAsset[];

  public filelink?: string;

  public filename?: string;

  public icon?: string;

  public icon_url?: string;

  public is_gif?: boolean;

  public is_image?: boolean;

  public url?: string;

  public url_big?: string;

  public url_large?: string;

  public url_thumb?: string;

  public url_thumb_big?: string;

  public url_thumb_big_scaled?: string;

  public url_thumb_small?: string;

  public find(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}`;

    return this.request('get', url);
  }

  public request(method: string, url: string, payload: any = {}): Promise<any> {
    if (this.$includes.length) {
      payload.with = this.$includes;
    }

    const keys = Object.keys(this.filters);
    if (keys.length) {
      keys.forEach((key) => {
        const model: Model = (this.filters as any)[key];
        if (isObject(model) && typeof model.resolvePrimaryKey === 'function') {
          (this.filters as any)[key] = model.resolvePrimaryKey();
        }
      });

      payload.filters = this.filters;
    }

    if (this.$page !== null) {
      payload.page = this.$page;
    }

    if (this.$limit !== null) {
      payload.limit = this.$limit;
    }

    if (this.$sort.key) {
      payload.sort = this.$sort.order === 'DESC' ? `!${this.$sort.key}` : this.$sort.key;
    }

    return mondayClient(method, url, payload).then(
      (response: object) => Promise.resolve(this.responseToModel(response)),
      (error: AxiosError) => {
        if (error.response !== undefined && error.response.status === 401) {
          console.log('monday error');
        }

        return Promise.reject(error);
      },
    );
  }
}

export interface MondayAsset {
  account_id?: number;
  big_geometry?: string;
  id?: number;
  large_geometry?:string;
  original_geometry?:string;
  resource_content_type?:string;
  resource_file_name?:string;
  resource_file_size?: number;
  thumb_big_geometry?:string;
  thumb_geometry?:string;
  uploaded_by_id?: number;
}
