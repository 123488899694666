import { QuestionBase } from '@/models/question-models/question-base';

export class CheckboxQuestion extends QuestionBase<string> {
  controlType = 'checkbox';

  options: {key: string, value: any, checked: boolean, label: string, optionalQuestions: any[]}[] = [];

  checked: boolean;

  constructor(options: any = {}) {
    super(options);
    this.options = options.options || [];
    this.checked = false;
  }
}
