var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "quill-question",
      class: { "quill-question--fullscreen": _vm.isFullscreen }
    },
    [
      _vm.canFullscreen
        ? _c(
            "v-icon",
            {
              staticClass: "fullscreen-icon",
              on: {
                click: function($event) {
                  _vm.isFullscreen = !_vm.isFullscreen
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.isFullscreen ? "fullscreen_exit" : "fullscreen") +
                  " "
              )
            ]
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c("quill-editor", {
            ref: "quillEditor",
            staticClass: "quill-editor",
            attrs: { options: _vm.defaultOptions },
            on: {
              blur: function($event) {
                return _vm.blur(_vm.inputValue)
              },
              change: function($event) {
                return _vm.change(_vm.inputValue)
              }
            },
            model: {
              value: _vm.inputValue,
              callback: function($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }