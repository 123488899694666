import { Model } from '@/models/Model';
import { Polygon } from '@/support/Polygon';
import { DamageRecognition } from '@/models/DamageRecognition';

export class Media extends Model {
  protected $name = 'Media';

  protected $endpoint = '/media';

  protected $fillable: string[] = [];

  public uuid!: string;

  public id!: string;

  public type?: string;

  public created_at?: string;

  public description?: string;

  public file_name?: string;

  public mime_type?: string;

  public original?: string;

  public show_in_report?: string;

  public sort_order?: number;

  public thumb?: string;

  public uploaded_by?: string;

  public uploaded_on?: string;

  public meta_meeting?: MetaMeeting;

  public damage_recognition?: DamageRecognition;

  public toggleVisibilityMedia(mediaId: string, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${mediaId}/show-in-report`;
    return this.request('put', url, payload);
  }
}

export interface MetaMeeting {
  meeting: string;
  report: string;
  target: string;
  damage: string;
  is_assigned: boolean;
  token: string;
}
