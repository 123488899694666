import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<CreateDialogHeader>({
  //
})
export default class CreateDialogHeader extends Vue {
  @Prop({})
  protected role!: string;

  @Prop({ default: 'create' })
  protected type!: string;

  @Prop({ default: 'person_add' })
  protected icon!: string;

  mounted() {
    //
  }

  created() {
    document.addEventListener('keyup', this.escapeKeyListener);
  }

  destroyed() {
    document.removeEventListener('keyup', this.escapeKeyListener);
  }

  protected escapeKeyListener(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      this.$emit('closedDialog');
    }
  }

  protected close() {
    this.$emit('closedDialog');
  }
}
