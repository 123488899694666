import { Model } from '@/models/Model';

export enum AanvragerModelKeyEnum {
  ID = 'id',
  BSN = 'bsn',
  CODE = 'code',
  COMPANY = 'company',
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  INITIALS = 'initials',
  KVK = 'kvk',
  LAST_NAME = 'last_name',
  MIDDLE_NAME = 'middle_name',
  MOBILE = 'mobile',
  NAME = 'name',
  PHONE = 'phone',
  CREATED_AT = 'created_at',
}

export class Aanvrager extends Model {
  protected $name = 'Aanvrager';

  protected $endpoint = '/applicants';

  protected $primaryKey = AanvragerModelKeyEnum.ID;

  protected $fillable: string[] = [
    AanvragerModelKeyEnum.BSN,
    AanvragerModelKeyEnum.COMPANY,
    AanvragerModelKeyEnum.EMAIL,
    AanvragerModelKeyEnum.FIRST_NAME,
    AanvragerModelKeyEnum.INITIALS,
    AanvragerModelKeyEnum.KVK,
    AanvragerModelKeyEnum.LAST_NAME,
    AanvragerModelKeyEnum.MIDDLE_NAME,
    AanvragerModelKeyEnum.MOBILE,
    AanvragerModelKeyEnum.PHONE,
  ];

  public [AanvragerModelKeyEnum.ID]?: string;

  public [AanvragerModelKeyEnum.BSN]?: string;

  public [AanvragerModelKeyEnum.CODE]?: string;

  public [AanvragerModelKeyEnum.COMPANY]?: string;

  public [AanvragerModelKeyEnum.EMAIL]?: string;

  public [AanvragerModelKeyEnum.FIRST_NAME]?: string;

  public [AanvragerModelKeyEnum.INITIALS]?: string;

  public [AanvragerModelKeyEnum.KVK]?: string;

  public [AanvragerModelKeyEnum.LAST_NAME]?: string;

  public [AanvragerModelKeyEnum.MIDDLE_NAME]?: string;

  public [AanvragerModelKeyEnum.MOBILE]?: string;

  public [AanvragerModelKeyEnum.NAME]?: string;

  public [AanvragerModelKeyEnum.PHONE]?: string;

  public [AanvragerModelKeyEnum.CREATED_AT]?: string;
}
