import { Component, Vue, Prop } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';

@Component<FileUpload>({
  filters: {
    calculateKB: (value: number) => (value * 0.001).toFixed(1),
    concatenateName: (value: string) => {
      if (value.length >= 25) {
        return `${value.slice(0, 15)}...${value.slice(value.length - 10, value.length)}`;
      }
      return value;
    },
  },
})
export default class FileUpload extends Vue {
  @Prop()
  protected acceptedFileTypes?: string[];

  protected accepted: string [] = [];

  @Prop({ default: 300 })
  protected height?: number;

  @Prop()
  protected filePath?: string;

  @Prop({ default: false })
  protected clearAfterUpload?: boolean;

  @Prop({ default: false })
  protected multiple?: boolean;

  @Prop({ default: 'add_photo_alternate' })
  protected fileIcon?: string;

  //
  // STATES
  //
  protected dragging = false;

  protected hovering = false;

  protected isUploaded = false;

  protected hasError = false;

  protected invalidFileType = false;

  protected isUploading = false;

  protected showRipple = false;

  protected style: RippleStyle = {};

  // Files
  protected files: File[] = [];

  //
  // TO TRACK IF A FILE IS UPLOADED OR NOT: MULTIPLE ONLY
  //
  protected filesUploaded: FilesUpload[] = [];

  //
  // PARSE CORRECT ICON FOR FILE TYPE
  //
  protected imageExtesions = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'bmp'];

  protected fileIcons: {[key:string]:string} = {
    pdf: 'mdi-file-pdf-box',
    xls: 'mdi-file-excel-box',
    xlsx: 'mdi-file-excel-box',
    docx: 'mdi-file-word-box',
    doc: 'mdi-file-word-box',
    ppt: 'mdi-file-powerpoint-box',
    pptx: 'mdi-file-powerpoint-box',
    other: 'mdi-file-document-box',
    jpg: 'mdi-image',
    jpeg: 'mdi-image',
    png: 'mdi-image',
  };

  protected mounted() {
    if (! this.acceptedFileTypes) {
      this.accepted = [
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'msg',
        'oft',
      ];
    } else {
      this.accepted = this.acceptedFileTypes;
    }
  }

  //
  // GET FILE KEY
  //
  protected async getFileKey(files: File[]) {
    const keys: string[] = [];

    // FILL FILEUPLOADED OBJECT
    for (let index = 0; index < files.length; index += 1) {
      const file = files[index];
      //
      // VALIDATE FILE
      //
      this.invalidFileType = ! this.accepted.includes(file.type.toString());
      if (this.invalidFileType) {
        this.dragging = true;
        return;
      }

      const parsedPath = file.name.split('.').pop();

      this.filesUploaded.push({
        isLoading: true,
        icon: parsedPath ? parsedPath.split('?')[0] : 'other',
      });
    }

    //
    // START RECIEVING THE S3 KEYS
    //
    if (this.invalidFileType) { return; }
    this.isUploading = true;
    for (let index = 0; index < files.length; index += 1) {
      const file = files[index];

      const key = await this.getUploadForm(file, index);

      if (key) {
        keys.push(key);
      }
    }

    return keys;
  }

  //
  // UPLOAD EVENT
  //
  protected async getUploadForm(file: File, index: number) {
    const payload = {
      signature: 'stateless-uploads:form:create',
      body: {
        config: {
          mime: 'any',
        },
      },
    };

    const response = await new Rpc()
      .rpcPost(payload)
      .then((response) => response).catch((error) => error.response);

    if (response.status && response.status !== 200) {
      this.handleError(index);
      return '';
    }

    const key = await this.uploadImageToS3(response.formdata, file, index);

    if (! key) {
      return;
    }

    return key;
  }

  protected async uploadImageToS3(formData: any, file: File, index: number) {
    if (! formData) {
      return;
    }

    const payload = new FormData();
    payload.append('Policy', formData.inputs.Policy);
    payload.append('acl', formData.inputs.acl);
    payload.append('key', formData.inputs.key);
    payload.append('X-Amz-Algorithm', formData.inputs['X-Amz-Algorithm']);
    payload.append('X-Amz-Credential', formData.inputs['X-Amz-Credential']);
    payload.append('X-Amz-Date', formData.inputs['X-Amz-Date']);
    payload.append('X-Amz-Security-Token', formData.inputs['X-Amz-Security-Token']);
    payload.append('X-Amz-Signature', formData.inputs['X-Amz-Signature']);

    payload.append('success_action_status', '201');
    payload.append('Content-Type', file.type);
    payload.append('file', file);

    let key = '';
    const response = await fetch(formData.attributes.action, {
      method: formData.attributes.method,
      body: payload,
    }).then((response: any) => {
      if (response.status && response.status !== 201) {
        return response;
      }

      return response.text();
    }).then((response: any) => response);

    if (response.status && response.status !== 201) {
      this.handleError(index);
      return;
    }

    const match = response.match(/<Key>(.+?)<\/Key>/);
    if (! match[1]) {
      return null;
    }

    key = match[1];
    return key;
  }

  //
  // EVENTS
  //
  protected async onFileInputChange() {
    if (this.isLoadingFiles) {
      return;
    }

    if (this.input.files) {
      this.files = Array.from(this.input.files);
      const keys = await this.getFileKey(this.files);
      this.$emit('submitKeys', keys);
    }
  }

  protected handleMouseEnter() {
    this.hovering = true;
    this.style = {};
  }

  protected handleMouseLeave() {
    this.hovering = false;
  }

  protected handleClickUploaderAction(event: any) {
    if (this.isLoadingFiles) {
      return;
    }

    const input = this.$refs.hiddenFileInput as HTMLInputElement;
    if (input) {
      this.reset();
      input.value = '';
      const event = new MouseEvent('click');
      input.dispatchEvent(event);
    }
  }

  protected handleDragEnd(event: DragEvent) {
    this.dragging = false;
  }

  protected handleDragEnter(event: DragEvent) {
    if (! event.dataTransfer) {
      return;
    }

    this.dragging = true;
    const element: HTMLElement = event.target as HTMLElement;
    this.setRippleStyle(element, event.pageX, event.pageY);

    if (event.dataTransfer.items && event.dataTransfer.items.length && this.accepted.length) {
      const file = Array.from(event.dataTransfer.items)[0];
      this.invalidFileType = ! this.accepted.includes(file.type.toString());
    }
  }

  protected handleDragOver(event: DragEvent) {
    this.dragging = true;
  }

  protected handleDragLeave(event: DragEvent) {
    this.reset();
  }

  protected async handleDrop(event: DragEvent) {
    if (this.invalidFileType) {
      this.reset();
      return;
    }

    this.dragging = false;
    if (! event.dataTransfer) {
      return;
    }

    const files = Array.from(event.dataTransfer.files);
    const keys = await this.getFileKey(files);
    this.$emit('submitKeys', keys);
  }

  //
  // PARSE FILE TYPE TO ICON & NAME
  //
  private get fileNameFromFilePath() {
    if (! this.filePath) {
      return '';
    }

    const parsedPath = this.filePath.split('/').pop();
    if (! parsedPath) {
      return '';
    }
    return parsedPath.split('?')[0];
  }

  private get fileExtension() {
    return this.fileNameFromFilePath ? this.fileNameFromFilePath.split('.').pop() : null;
  }

  protected get iconClass() {
    if (! this.fileExtension || ! this.fileIcons[this.fileExtension]) {
      return this.fileIcons.other;
    }

    return this.fileIcons[this.fileExtension];
  }

  protected get isImage() {
    if (! this.filePath) {
      return false;
    }

    if (! this.fileExtension) {
      return false;
    }

    return this.imageExtesions.includes(this.fileExtension) || this.filePath.includes('data:image');
  }

  protected get uploadMessage() {
    if (! this.dragging) {
      if (this.hovering) {
        return `Klik om één ${this.multiple ? 'of meer bestand(en)' : '​​bestand'} te selecteren`;
      }
      return `Versleep één ${this.multiple ? 'of meer bestand(en)' : '​​bestand'} om te uploaden`;
    }
    if (this.invalidFileType) {
      return `${this.multiple ? 'Één of meer bestand(en) worden' : 'Dit ​​bestand wordt'} niet ondersteund`;
    }
    return `Drop ${this.multiple ? 'één of meer bestand(en)' : 'dit bestand'} om te uploaden`;
  }

  protected get rippleStyle() {
    return this.style;
  }

  private setRippleStyle(element: HTMLElement, x: number, y: number) {
    const boundingRectangle: ClientRect = element.getBoundingClientRect();
    const size = Math.max(boundingRectangle.width, boundingRectangle.height);
    this.style.height = `${size}px`;
    this.style.width = `${size}px`;
    this.style.top = `${y - boundingRectangle.top - size / 2}px`;
    this.style.left = `${x - boundingRectangle.left - size / 2}px`;
  }

  //
  // REMOVE FILE
  //
  protected removeFile() {
    this.$emit('fileRemoved');
    this.reset();
  }

  //
  // RESET UPLOAD
  //
  public reset() {
    this.dragging = false;
    this.hovering = false;
    this.isUploading = false;
    this.invalidFileType = false;
    this.showRipple = false;
    this.isUploaded = false;
    this.filesUploaded = [];
  }

  //
  // DONE UPLOADING
  //
  public handleUploaded(index: number) {
    this.filesUploaded[index].isUploaded = true;
    this.filesUploaded[index].isLoading = false;
  }

  //
  // HANDLE ERROR
  //
  public handleError(index: number) {
    this.filesUploaded[index].isUploaded = false;
    this.filesUploaded[index].isLoading = false;
  }

  //
  // GETTERS
  //
  protected get input() {
    return this.$refs.hiddenFileInput as HTMLInputElement;
  }

  protected get isLoadingFiles() {
    return this.filesUploaded.length && this.filesUploaded[this.filesUploaded.length - 1].isLoading;
  }

  public uploadedMultiple(index: number) {
    this.filesUploaded[index].isUploaded = true;
    this.filesUploaded[index].isLoading = false;
  }

  public uploadedMultipleFailed(index: number) {
    this.filesUploaded[index].isUploaded = false;
    this.filesUploaded[index].isLoading = false;
  }
}

interface RippleStyle {
  top?: string;
  left?: string;
  height?: string;
  width?: string;
}

interface FilesUpload {
  isUploaded?: boolean;
  isLoading?: boolean;
  icon?: string;
}
