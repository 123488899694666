import { Component, Vue, Prop } from 'vue-property-decorator';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report } from '@/models/Report';
import { User } from '@/models/User';
import { disableEditor } from '@/support/ReportEditor';

@Component<AllowedToView>({})
export default class AllowedToView extends Vue {
  @Prop()
  protected report!: Report;

  @Prop({ default: () => [] })
  protected types!: any;

  @Prop({ default: '' })
  protected editor!: string;

  public mounted() {
    if (! this.isAllowedToView) {
      this.$store.dispatch('openDialog', this.dialogOptions);
    }

    this.handler();
  }

  get user(): User {
    return this.$store.state.Auth;
  }

  protected handler() {
    this.$emit('handleAlert', this.isAllowedToView);
  }

  protected get isAllowedToView() {
    return disableEditor(this.editor, this.report, this.user);
    // if (this.report.is_offline) return false
    // if (!this.user.type) return
    // if (!this.report.status) return
    // return allowEditorBasedOnStatus(this.editor, this.report.status, this.user.type, false, this.$store.state.isServiceOrganization);
  }

  protected get dialogOptions(): Options {
    return {
      title: 'Niet beschikbaar',
      text: 'Deze pagina is op dit moment niet beschikbaar',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Terug naar dossier',
          action: () => {
            this.$router.push(`/reports/${this.report.uuid}`);
          },
        },
      },
    };
  }
}

interface EditorStatuses {
  [key: string]: string[];
}
