import { NormalizedScopedSlot } from 'vue/types/vnode';

/*
** Use $scopedSlots instead of $slots
**
** The var $slots can cause unexpected behaviour with rerendering
** The var $scopedSlots does some normalisation and prevents this
** https://github.com/vuejs/vue/issues/11084
*/

/*
** Do not conditionally render a slot, but conditionally render it's contents
**
** The var $scopedSlots isn't reactive, it's nodes are
** https://stackoverflow.com/questions/38148297/in-vue-js-can-a-component-detect-when-the-slot-content-changes
**
** Therefore, instead of using:
// <template v-if="visible" v-slot:something></template>
** Use:
// <template v-slot:something><template v-if="visible"> Something</template></template>
**/

/*
** RETURNS
*/
// {
//   default: true,
//   title: false,
//   'append-header': true,
// }

/*
** TS
*/
// export default class Something extends mixins(Vue, ZIsSetSlotMixin) {

/*
** VUE
*/
// <ACard :title="title">
//   <template v-slot:title>
//     <template v-if="zIsSetSlot.title">
//       <slot name="title" />
//     </template>
//   </template>
// </ACard>

/*
** We purposely do a basic check here, so we:
** - don't filter away items that don't containt text, e.g.; <img />
** - don't filter away components without text, e.g. <LoaderCard>
** - don't filter away components that might render unexpectedly, e.g.: <HighCharts>
** - we keep most control in the parent whether to show something or not, but still have a basic check
*/

export class AIsSetSlot {
  public static scopedSlots(slots: { [key: string]: NormalizedScopedSlot | undefined; }): AIsSetSlotType {
    const accumulatorInitial: AIsSetSlotType = {};

    if (!slots) { return accumulatorInitial; }

    return Object.keys(slots)
      .reduce(
        (accumulator, key) => {
          const slot = slots[key];

          if (slot === undefined) {
            return {
              ...accumulator,
              [key]: false,
            };
          }

          const children = slot({});

          return {
            ...accumulator,
            [key]: children !== undefined && children.length > 0,
          };
        },
        accumulatorInitial
      );
  }
}

export type AIsSetSlotType = Record<string, boolean>;
