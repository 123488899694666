import { Model } from '@/models/Model';
import { User } from '@/models/User';
import { Report } from '@/models/Report';
import { Damage } from './Damage';
import { RejectReason } from './RejectReason';

export class Anomaly extends Model {
  protected $name = 'Anomaly';

  protected $endpoint = '/anomalies';

  protected $primaryKey = 'id';

  protected $fillable: string[] = [];

  public id?: string;

  public user?: User;

  public report?: Report;

  public damage?: Damage;

  public type?: AnomalyType;

  public custom_properties?: AnomalyCustomProperties;

  public created_at?: string;

  public approved_at?: string | null;

  public reject_reasons!: RejectReason[];

  public reject_comment!: string;

  public sumamry!: AnomalySummary;

  public status!: AnomalyStatus;

  public service?: {
    status: AnomalyStatus;
    rejected_reason: string;
    rejected_comment: string;
  };

  public get isApproved(): boolean {
    return this.status === AnomalyStatusEnum.APPROVED;
  }

  public set isApproved(value: boolean) {
    // Only created to work around a v-checkbox that does not accept a set value, so we had to v-model it using getter and setter.
  }

  protected get name(): string {
    return this.damage?.name || '';
  }
}

export enum AnomalyIncludes {
  USER = 'user',
  REPORT = 'report',
  DAMAGE = 'damage',
  REJECT_REASONS = 'reject_reasons',
  SUMMARY = 'summary',
}

export enum AnomalyType {
  ASSESSMENT_SCHEME = 'assessment_scheme',
  DAMAGE_AMOUNT = 'damage_amount',
  DAMAGE_CLASSIFICATION = 'damage_classification',
  OTHER = 'other',
}

export const AnomalyTypeLabels: {[key: string] :string} = {
  [AnomalyType.ASSESSMENT_SCHEME]: 'Beoordelingschema',
  [AnomalyType.DAMAGE_AMOUNT]: 'Schadebedrag',
  [AnomalyType.DAMAGE_CLASSIFICATION]: 'Schadeclassificatie',
  [AnomalyType.OTHER]: 'Anders',
};

export interface AnomalyCustomProperties {
  anomaly?: {
    styling?: string,
    value?: string,
  }
  expected?: string,
}

export interface AnomalySummary {
  damage: {
    id: string,
    name: string,
  },
  expert: {
    id: string,
    name: string,
  },
  report: {
    id: string,
    case_number: string,
  },
}

export enum AnomalyStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export type AnomalyStatus = AnomalyStatusEnum.PENDING | AnomalyStatusEnum.APPROVED | AnomalyStatusEnum.REJECTED;
