import { debounce } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { QuestionBase } from '@/models/question-models/question-base';
import DefaultAnswerDialog from '@/components/dialog/default-answer-dialog/DefaultAnswerDialog.vue';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';

@Component<PrefillableTextArea>({
  components: {
    DefaultAnswerDialog,
    QuillEditor,
  },
})
export default class PrefillableTextArea extends Vue {
  @Prop()
  protected question!: QuestionBase<any>;

  @Prop({ default: '' })
  protected answer!: string;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: '' })
  protected instruction!: string;

  @Prop({ default: false })
  protected assumedValue!: boolean;

  protected stringInputText = '';

  protected stringInputInstruction = '';

  protected defaulftAnswers: defaultAnswer[] = [];

  protected isLoadingDefaulftAnswers = false;

  protected isShowingDefaultAnwsersDialog = false;

  protected isReadingMoreInfo = false;

  protected isLoading = false;

  protected answerDebounce: Function = debounce(this.answerDebounceQuestion, 500);

  protected answerDebounceFromQuill: Function = debounce(this.answerDebounceQuestionFromQuill, 500);

  protected toggleAssumable(event: boolean) {
    this.$emit('toggleAssumable', event);
  }

  protected answerDebounceQuestion() {
    this.$emit('textareaDebounce', this.stringInputText);
    this.$emit('questionAnswered', this.stringInputText);
  }

  protected answerDebounceQuestionFromQuill(value: string) {
    this.$emit('textareaDebounce', value);
    this.$emit('questionAnswered', value);
  }

  protected answerQuestion() {
    this.$emit('textareaChanged', this.stringInputText);
    this.$emit('questionAnswered', this.stringInputText);
  }

  protected answerQuestionFromQuill(value: string) {
    this.$emit('textareaChanged', value);
    this.$emit('questionAnswered', value);
  }

  protected defaultAnswerSelected(answer: {[key: string]: string}) {
    this.stringInputText = `${this.stringInputText}${this.stringInputText.length ? '\n\n\n' : ''}${answer.text}`;
    this.stringInputInstruction = answer.instruction;

    this.$emit('textareaChanged', this.stringInputText);
    this.$emit('questionAnswered', this.stringInputText);
    this.$emit('questionInstruction', this.stringInputInstruction);
    this.isShowingDefaultAnwsersDialog = false;
  }

  protected toggleEditMode() {
    this.$emit('toggleEditMode');
  }

  protected onClickInfo() {
    this.$emit('onClickInfo', this.question);
  }

  protected onClickSaveCustomText() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  @Watch('answer')
  protected answerChanged() {
    this.stringInputText = this.answer;
  }

  @Watch('instruction')
  protected instructionChanged() {
    this.stringInputInstruction = this.instruction;
  }

  protected openReadMoreDialog(key: string) {
    // this.description = this.moreInfo[key];
    this.isReadingMoreInfo = true;
  }
}

interface defaultAnswer {
  text: string;
  title: string;
}
