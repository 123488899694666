import { cloneDeep } from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { User, userRoles } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Skill } from '@/models/Skill';
import { UserLevelItem, UserLevels } from '@/models/User';
import { Report } from '@/models/Report';

@Component<Skills>({})
export default class Skills extends Vue {
  @Prop()
  protected model!: User | Report;

  @Prop()
  protected userId!: string;

  @Prop()
  protected type!: 'report' | 'user';

  // @Prop({default: true})
  // protected canEdit!: boolean;

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected forceEditable!: boolean;

  @Prop()
  protected reportId!: string;

  @Prop({ default: false })
  protected disableAutosave!: boolean;

  protected userRoles = userRoles;

  protected isLoading = false;

  protected skills: Skill[] = [];

  protected skillList: { [key: string]: boolean } = {};

  protected fixedSkillList: { [key: string]: boolean } = {};

  protected modelSkills: Skill[] = [];

  protected modelFixedSkills: Skill[] = [];

  protected modelClone: User | Report | null = null;

  protected userLevels: UserLevelItem[] = UserLevels;

  protected mounted() {
    this.initialize();
  }

  protected initialize() {
    if (! this.model) {
      return;
    }

    this.modelClone = cloneDeep(this.model);
    if (this.organization) {
      this.modelClone = this.modelClone.dmz(this.organization);
    }

    this.fetchSkills();
  }

  public updateLevel() {
    if (! this.modelClone) {
      return;
    }

    this.modelClone
      .update({ level: (this.model as User | Report).level })
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public addSkills() {
    if (! this.modelClone) {
      return;
    }

    const selectedSkills = Object.keys(this.skillList).filter((key: string) => this.skillList[key]);

    this.modelClone
      .AddSkills(this.model.uuid, { skills: selectedSkills })
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.fetchSkills();
      });
  }

  public async addFixedSkills() {
    if (! this.modelClone) {
      return;
    }

    const selectedSkills = Object.keys(this.fixedSkillList).filter((key: string) => this.fixedSkillList[key]);

    await (this.modelClone as User)
      .AddFixedSkills(this.model.uuid, { skills: selectedSkills })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.fetchSkills();
  }

  protected async fetchSkills() {
    this.resetSkills();
    this.isLoading = true;

    this.skills = await this.getSkills();
    this.skills.sort(this.sortBySkillName);

    if (this.type === 'user') {
      this.modelSkills = await this.getUserSkills();
      this.modelFixedSkills = await this.getFixedUserSkills();
    } else {
      this.modelSkills = await this.getReportSkills();
    }

    this.createSkillsList();
    this.createFixedSkillsList();
    this.isLoading = false;
  }

  protected resetSkills() {
    this.modelSkills = [];
    this.skills = [];
    this.$set(this, 'skillList', {});
  }

  protected async getSkills(): Promise<Skill[]> {
    return await new Skill()
      .limit(1000)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getUserSkills(): Promise<Skill[]> {
    const user = this.organization ? new User().dmz(this.organization) : new User();

    return await user
      .dmz(this.organization)
      .limit(1000)
      .getSkills(this.userId ? this.userId : this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getFixedUserSkills(): Promise<Skill[]> {
    const user = this.organization ? new User().dmz(this.organization) : new User();

    return await user
      .dmz(this.organization)
      .limit(1000)
      .getFixedSkills(this.userId ? this.userId : this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportSkills(): Promise<Skill[]> {
    return await new Report()
      .limit(1000)
      .getSkills(this.reportId ? this.reportId : this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected createSkillsList() {
    if (! this.skills) {
      return;
    }

    this.skills.forEach((skill: Skill) => {
      if (skill.id) {
        if (! this.skillList[skill.id]) {
          const skillValue = this.modelSkills.some((modelSkill: Skill) => modelSkill.id === skill.id);

          this.skillList[skill.id] = skillValue;
        }
      }
    });
  }

  protected createFixedSkillsList() {
    if (! this.skills) {
      return;
    }

    this.skills.forEach((skill: Skill) => {
      if (skill.id) {
        if (! this.fixedSkillList[skill.id]) {
          const skillValue = this.modelFixedSkills.some((modelSkill: Skill) => modelSkill.id === skill.id);

          this.fixedSkillList[skill.id] = skillValue;
        }
      }
    });
  }

  private sortBySkillName(skillA: Skill, skillB: Skill) {
    if (skillA.name === undefined || skillB.name === undefined || skillA.name === skillB.name) {
      return 0;
    }

    if (skillA.name < skillB.name) {
      return - 1;
    }

    return 1;
  }

  // level
  protected get canEditLevel() {
    if (! this.isServiceOrganization) { return false; }
    if (this.organization) {
      return this.$store.state.Auth.has_expert_register;
    }

    return this.isReportType ? true : this.isPlanner && this.isManager;
  }

  // planbare skill
  protected canEditSkill(skill: Skill) {
    if (this.isReportType) {
      return true;
    }

    if (! this.isServiceOrganization) { return false; }

    if (this.organization) {
      if (! this.isPlanner || ! this.isManager) { return false; }

      const skillValue = this.modelFixedSkills.some((modelSkill: Skill) => modelSkill.id === skill.id);

      return !! skillValue;
    }

    if (this.isPlanner && this.isManager) { return true; }

    return false;
  }

  // active skill
  protected get canEditFixedSkills() {
    if (this.organization) {
      return this.$store.state.Auth.has_expert_register;
    }

    return false;
  }

  protected get isPlanner() {
    return this.$store.state.Auth.is_planner;
  }

  protected get isServiceOrganization() {
    return this.$store.state.isServiceOrganization;
  }

  protected get isAdmin() {
    return this.$store.state.Auth.hasRole(this.userRoles.AdminRoles);
  }

  protected get isManager() {
    return this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles);
  }

  protected get isUserType() {
    return this.type === 'user';
  }

  protected get isReportType() {
    return this.type === 'report';
  }

  @Watch('model')
  protected modelChanged() {
    this.modelClone = cloneDeep(this.model);
    this.modelClone.dmz(this.organization);
  }
}
