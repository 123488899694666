import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

@Component<WorkFlowFilter>({})
export default class WorkFlowFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected hasNoTags!: boolean;

  public items: Item[] = [
    {
      name: WorkflowLabels[WorkflowEnum.REGULIER],
      value: WorkflowEnum.REGULIER,
    },
    {
      name: WorkflowLabels[WorkflowEnum.NADER_ADVIES],
      value: WorkflowEnum.NADER_ADVIES,
    },
    {
      name: WorkflowLabels[WorkflowEnum.AMBTELIJK_BEZWAAR],
      value: WorkflowEnum.AMBTELIJK_BEZWAAR,
    },
    {
      name: WorkflowLabels[WorkflowEnum.BEZWAAR],
      value: WorkflowEnum.BEZWAAR,
    },
    {
      name: WorkflowLabels[WorkflowEnum.SMR],
      value: WorkflowEnum.SMR,
    },
    {
      name: WorkflowLabels[WorkflowEnum.VES],
      value: WorkflowEnum.VES,
    },
  ];

  protected selectedItems = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedItems);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedItems = [];
    } else {
      this.selectedItems = cloneDeep(this.value);
    }
  }

  public async mounted() {

  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedItems', this.items.map((item: Item) => item.value || ''));
    } else {
      this.$set(this, 'selectedItems', []);
    }

    this.handleChange();
  }

  public getItemName(value: string) {
    const foundItem = this.items.find((item) => item.value === value);

    return foundItem ? foundItem.name : '';
  }
}

interface Item {
  name: string;
  value: string;
}

export enum WorkflowEnum {
  REGULIER = 'regulier',
  NADER_ADVIES = 'nader_advies',
  AMBTELIJK_BEZWAAR = 'ambtelijk_bezwaar',
  BEZWAAR = 'bezwaar',
  SMR = 'smr',
  VES = 'ves',
}

export const WorkflowLabels: {[key: string] :string} = {
  [WorkflowEnum.REGULIER]: 'Regulier',
  [WorkflowEnum.NADER_ADVIES]: 'Nader advies',
  [WorkflowEnum.AMBTELIJK_BEZWAAR]: 'Ambtelijk bezwaar',
  [WorkflowEnum.BEZWAAR]: 'Bezwaaradviescommissie',
  [WorkflowEnum.SMR]: 'SMR',
  [WorkflowEnum.VES]: 'VES nulmeting',
};
