import { Component, Prop, Vue, Model } from 'vue-property-decorator';

@Component<MiButton>({})
export default class MiButton extends Vue {
  @Model('isBusy', { default: false })
  isBusy!: boolean;

  @Prop({ default: '' })
  protected icon!: string;

  @Prop({ default: 'primary' })
  protected color!: string;

  @Prop({ default: false })
  protected round!: boolean;

  @Prop({ default: false })
  protected small!: boolean;

  @Prop({ default: false })
  protected compact!: boolean;

  @Prop({ default: false })
  protected background!: boolean;

  @Prop({ default: false })
  protected outline!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: '' })
  protected href!: string;

  @Prop({ default: '' })
  protected to!: string;

  @Prop({ default: '' })
  protected iconColor!: string;

  @Prop({ default: false })
  protected fab!: boolean;

  @Prop({ default: false })
  protected iconRight!: boolean;

  @Prop({ default: false })
  protected fullWidth!: boolean;

  @Prop({})
  protected fontSize!: string;

  @Prop({})
  protected blank!: boolean;

  public mounted() { }

  protected get outlineClass() {
    return this.outline ? `button--normal-outline-${this.color}` : '';
  }
}
