import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AHasAttributesMixin extends Vue {
  protected executeClick(event: MouseEvent): void {
    if (! this.isDisabled) {
      this.$emit('click', event);
    }
  }

  protected get clickEventName(): 'click' | null {
    return this.isDisabled ? null : 'click';
  }

  protected get hasRouterLink(): boolean {
    return this.$attrs.to !== undefined && ! this.isDisabled;
  }

  protected get hasHref(): boolean {
    return this.$attrs.href !== undefined && ! this.isDisabled;
  }

  protected get isDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }
    if (typeof this.$attrs.disabled === 'boolean') {
      return this.$attrs.disabled;
    }
    return this.$attrs.disabled !== undefined;
  }

  protected get isLoading(): boolean {
    if (typeof this.$attrs.loading === 'boolean') {
      return this.$attrs.loading;
    }
    return this.$attrs.loading !== undefined;
  }

  protected get isClickable(): boolean {
    return this.$listeners.click !== undefined;
  }

  protected get isAlternative(): boolean {
    if (typeof this.$attrs.alternative === 'boolean') {
      return this.$attrs.alternative;
    }
    return this.$attrs.alternative !== undefined;
  }

  protected get isFloatingActionButton(): boolean {
    if (typeof this.$attrs.fab === 'boolean') {
      return this.$attrs.fab;
    }
    return this.$attrs.fab !== undefined;
  }
}
