import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<StatisticsProgressWidget>({})
export default class StatisticsProgressWidget extends Vue {
  // Lables:
  @Prop({ default: '' })
  protected label!: string;

  // Boundries:
  @Prop({ default: 100 })
  protected warningBoundry!: number;

  @Prop({ default: 100 })
  protected okBoundry!: number;

  // Default Colors:
  @Prop({ default: '#F74949' })
  protected alertColor!: string;

  @Prop({ default: '#f98d35' })
  protected warningColor!: string;

  @Prop({ default: '#62DC4F' })
  protected okColor!: string;

  // Data:
  @Prop()
  protected item!: StatisticsProgressItem;

  // Width:
  @Prop({ default: 160 })
  protected size!: number;

  protected get widgetLabel() {
    return (this.label === '') ? this.item.label : this.label;
  }

  protected get widgetColor() {
    if (this.item.percentage >= this.okBoundry) {
      return this.okColor;
    }

    if (this.item.percentage >= this.warningBoundry) {
      return this.warningColor;
    }

    return this.alertColor;
  }
}

interface StatisticsProgressItem {
  percentage: number;
  color?: string;
  value: number;
  target: number;
  label?: string;
}
