import { Organization } from '@/models/Organization';
import { Model } from '@/models/Model';
import { User } from '@/models/User';

export class CostSpecification extends Model {
  protected $name = 'CostSpecification';

  protected $endpoint = '/cost-specifications';

  protected $fillable: string[] = [
    'file',
  ];

  public id!: string;

  public specification_number!: string;

  public hours_count!: number;

  public hours_sum!: number;

  public cost_total?: number;

  public period_starts_at!: string;

  public period_ends_at!: string;

  public created_at!: string;

  public updated_at!: string;

  public status?: Status;

  public status_updated_at!: string;

  public status_updated_by?: User;

  public uploaded_by_user?: User;

  public organization?: Organization;

  public status_update_comment?: string;

  public resolvePrimaryKey(): string {
    return this.id;
  }

  public get isUploaded() {
    return this.status === 'uploaded';
  }

  public get isPending() {
    return this.status === 'pending';
  }

  public get isRejected() {
    return this.status === 'rejected';
  }

  public get isAccepted() {
    return this.status === 'accepted';
  }

  public get statusName() {
    return this.status ? statusNames[this.status] : '';
  }

  public get statusColor() {
    return this.status ? statusColors[this.status] : '';
  }
}

const statusNames: { [key: string]: string } = {
  uploaded: 'Geupload',
  pending: 'Ingediend',
  rejected: 'Afgekeurd',
  accepted: 'Goedgekeurd',
};

const statusColors: { [key: string]: string } = {
  uploaded: 'magenta',
  pending: 'orange',
  rejected: 'red',
  accepted: 'green',
};

export type Status = 'uploaded' | 'deleted' | 'pending' | 'rejected' | 'accepted';
