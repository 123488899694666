import { Model } from '@/models/Model';

export class Department extends Model {
  protected $name = 'Department';

  protected $endpoint = '/departments';

  protected $fillable: string[] = [];

  public name?: string;

  public id?: string;

  public invoice_contact_name?: string;

  public invoice_email?: string;
}
