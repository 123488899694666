import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import draggable from 'vuedraggable';

@Component<DraggableContent>({
  components: {
    draggable,
  },
})
export default class DraggableContent extends Vue {
  @Prop()
  protected options!: DraggableOptions;

  @Prop({ default: true })
  protected allowEditOrder!: boolean;

  protected draggableOptions: DraggableOptions = {
    animation: 200,
    disabled: false,
    ghostClass: 'ghost',
  };

  @Prop()
  protected draggables!: {[key: string]: string}[];

  protected draggablesObjects: {[key: string]: string}[] = [];

  protected mounted() {
    this.draggableOptions = { ...{}, ...this.draggableOptions, ...this.options };
    this.draggablesObjects = cloneDeep(this.draggables);
  }

  protected handleDragEndEvent() {
    this.$emit('handleDragEndEvent', this.draggablesObjects);
  }

  @Watch('draggables')
  protected draggablesChanged() {
    this.draggablesObjects = cloneDeep(this.draggables);
  }
}

interface DraggableOptions {
  delay?: number;
  animation?: number,
  disabled?: boolean,
  ghostClass?: string;
}
