import { Model } from '@/models/Model';

export class RepairSet extends Model {
  protected $name = 'RepairSet';

  protected $endpoint = '/repairs/sets';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;

  public description?: string;

  public created_at?: string;

  public updated_at?: string;
}
