import { ABool, AString } from "@/interfaces/AGeneral";

export const isTrueBoolAttr = (prop: undefined | ABool, fallbackValue = false): boolean => {
  return fallbackValue === false
    ? prop !== undefined && prop !== null && prop !== false && prop !== 0
    : prop === undefined || prop === null || (prop !== false && prop !== 0);
}

export const isSetStringAttr = (prop: undefined | AString): boolean => {
  return prop !== undefined && prop !== null && prop !== '';
}
