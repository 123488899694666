import { User, UserRole } from '@/models/User';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppointmentGroup, AppointmentTypeMap, getGroupAppointmentTypes } from '@/models/Event';
import { WorkDay } from '@/components/Availability/WorkHours/WorkHours';
import { DateTime } from 'luxon';
import { AppointmentLimit } from '@/models/AppointmentLimit';
import { Setting } from '@/models/Setting';

@Component<AppointmentTypeDialog>({})
export default class AppointmentTypeDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
  */

  @Prop()
  protected date!: DateTime;

  @Prop()
  protected user!: User;

  @Prop()
  protected day!: WorkDay;

  @Prop()
  protected max!: number;

  @Prop()
  protected appointmentLimit!: AppointmentLimit;

  @Prop()
  protected settings!: Setting[];

  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected appointmentTypeAmount: any= null;

  protected isValidAmountAppointments = true;

  protected isLoading = false;

  protected appointmentTypes: ModifiedAppointmentType[] | null = null;

  protected weekTypeMap = {
    uneven: 'Oneven',
    even: 'Even',
  };

  protected total = 0;
  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted() {
    this.$set(this, 'appointmentTypes', getGroupAppointmentTypes(this.user.type === UserRole.APPEALS_COMMITTEE ? AppointmentGroup.JZ : AppointmentGroup.ZB));

    if (this.day) {
      this.appointmentTypes?.forEach((appointmentType) => {
        appointmentType.value = this.day.max_appointment_type_count?.[appointmentType.key] || '';
      });
    }

    if (this.date) {
      this.appointmentTypes?.forEach((appointmentType) => {
        appointmentType.value = this.appointmentLimit?.type_limits?.[appointmentType.key] || '';
      });
    }

    this.calculateTotal();
  }

  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('input', false);
  }

  protected submit() {
    if (this.date) {
      this.$emit('updateMaxAppointmentTypeCount', this.appointmentTypes, this.user.uuid, this.date);
    }

    if (this.day) {
      this.$emit('updateMaxAppointmentTypeCount', this.appointmentTypes, this.day);
    }

    this.close();
  }

  protected calculateTotal() {
    const filteredAppointmentTypes = this.appointmentTypes?.filter((appointmentType) => appointmentType.value !== undefined);
    this.total = filteredAppointmentTypes?.map((filteredAppointmentType) => Number(filteredAppointmentType.value)).reduce((a, b) => a + b, 0) || 0;
  }

  protected validateAppointmentValue(appointmentType: AppointmentTypeMap) {
    this.isValidAmountAppointments = Number(appointmentType.value) <= Number(this.findSettingValue(appointmentType.key) || 0);
    this.calculateTotal();
  }

  protected findSettingValue(key: string) {
    const setting = this.settings.find((currentSetting: Setting) => (currentSetting.key ? currentSetting.key.includes(`max_visit_type_${key}`) : false));

    return setting?.value || null;
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */

  // #endregion

  // #region Getters & Setters

  protected get isDisabled() {
    return this.total > this.max;
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

export interface ModifiedAppointmentType extends AppointmentTypeMap {
  value?: string;
}
