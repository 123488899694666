var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      attrs: { src: require("../../assets/images/countries/nl-nl.svg") },
      on: {
        click: function($event) {
          return _vm.changeLanguage("nl")
        }
      }
    }),
    _c("img", {
      attrs: { src: require("../../assets/images/countries/en.svg") },
      on: {
        click: function($event) {
          return _vm.changeLanguage("en")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }