import { Model } from '@/models/Model';

export class PlanningTargetLog extends Model {
  protected $name = 'PlanningTargetLog';

  protected $endpoint = '/planning-target-logs';

  protected $fillable: string[] = [];

  public id?: string;

  public type?: PlanningTargetLogType;

  public changed_column?: string;

  public changed_from?: string | number;

  public changed_to?: string | number;

  public text?: string;

  public target_date?: string;

  public created_at?: string;

  public updated_at?: string;
}

export enum PlanningTargetLogType {
  TARGET_CHANGE = 'target_change',
}

export enum PlanningTargetLogInclude {
  ORGANIZATION = 'organization',
  USER = 'user',
}
