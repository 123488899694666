import { Model } from '@/models/Model';
import { User } from '@/models/User';

export class Message extends Model {
  protected $name = 'Message';

  protected $endpoint = 'chat/messages';

  protected $fillable: string[] = [];

  public id?: string;

  public text?: string;

  public type?: string;

  public room_key?: string;

  public created_at?: string;

  public user?: User;

  public getMessages(roomId: string) {
    const url = `${this.$endpoint}`;
    return this.include('user').filter({ room: roomId }).request('get', url);
  }
}
