import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DynamicFormQuestion from '@/components/dynamic-form/dynamic-form-question/DynamicFormQuestion.vue';
import { AnswerPayload } from '@/components/dynamic-form/dynamic-form-question/DynamicFormQuestion';
import { Question } from '@/models/Question';
import ErrorHandler from '@/support/ErrorHandler';
import { Model as DataModel } from '@/models/Model';
import { Report as ReportModel } from '@/models/Report';
import { HistoricalReport } from '@/models/HistoricalReport';
import { Panel } from '@/views/Reports/Report';
import { setFormattedDatePickerValue, dateErrorMessage, isValidDate } from '@/support/String';
import { User } from '@/models/User';
import { Damage } from '@/models/Damage';
import { AxiosError, AxiosResponse } from 'axios';

@Component<DynamicForm>({
  components: {
    DynamicFormQuestion,
  },
})
export default class DynamicForm extends Vue {
  @Prop({ default: false })
  protected showApplicantSubmittedAtField!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  private questions: Question[] = [];

  protected currentReport: ReportModel = new ReportModel();

  protected historicalReports: HistoricalReport[] = [];

  protected panelHistoricalReport: Panel[] | null = [];

  protected isLoading = false;

  protected damageAnswers: any = {};

  protected damageAnswersInstructions: any = {};

  protected HistoricalDamageAnswers: any = {};

  protected answerPayload: AnswerPayload[] = [];

  protected applicant_submitted_at: string | null = '';

  protected isEditingApplicantSubmittedAt = false;

  @Prop({ default: '' })
  protected currentEditor!: string;

  @Prop({ default: '' })
  protected reportId!: string;

  @Prop({ default: '' })
  protected reportType!: string;

  @Prop({ default: false })
  protected planningQuestions!: boolean;

  @Prop({ default: false })
  protected showTags!: boolean;

  @Prop({ default: true })
  protected hasAutosave!: boolean;

  @Prop()
  protected damage?: Damage;

  @Prop()
  protected historicalDamage?: HistoricalReport;

  @Prop()
  protected sections?: string[];

  @Prop({ default: () => [] })
  protected appendKeys?: string[];

  @Prop({ default: () => ['manager', 'tcmg', 'expert', 'pre_controller'] })
  protected formTypes?: string[];

  get user(): User {
    return this.$store.state.Auth;
  }

  protected questionFilters: QuestionFilter = {
    form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
    sections: [],
    append_keys: [],
    report_type: '',
    report: this.reportId,
  };

  public created() {
    this.questionFilters = {
      form_types: this.formTypes,
      sections: this.sections,
      append_keys: this.appendKeys,
      report_type: '',
      report: this.reportId,
    };
  }

  public mounted() {
    if (this.planningQuestions) {
      this.isLoading = true;
      this.getQuestionsPlanning();
    } else {
      this.getReport();
    }
    if (this.damage && this.damage.answers) {
      if (Array.isArray(this.damage.answers)) {
        this.damageAnswers = {};
      } else {
        this.damageAnswers = this.damage.answers;
      }
    }

    if (this.historicalDamage && this.historicalDamage.answers) {
      if (Array.isArray(this.historicalDamage.answers)) {
        this.HistoricalDamageAnswers = {};
      } else {
        this.HistoricalDamageAnswers = this.historicalDamage.answers;
      }
    }
  }

  protected get dynamicFormQuestionReportId() {
    return this.$route.params.id ? this.$route.params.id : this.reportId;
  }

  protected questionAnswered() {
    this.$emit('questionAnswered');
  }

  private getQuestionsPlanning() {
    new Question()
      .include('answer')
      .filter({
        sections: this.sections,
        append_keys: this.appendKeys,
        report: this.reportId,
        report_type: this.reportType,
      })
      .limit(300)
      .all()
      .then((response: any) => {
        this.$nextTick(() => {
          this.questions = response;
          this.setQuestionsVisible();
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private getQuestions() {
    new Question()
      .include(['answer'])
      .filter(this.questionFilters)
      .limit(300)
      .all()
      .then((response: any) => {
        this.$nextTick(() => {
          this.questions = response;
          this.setQuestionsVisible();
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getReport() {
    this.isLoading = true;

    new ReportModel()
      .include(['answers', 'damages'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.currentReport = new DataModel().dataToModel(reportModel, 'Report');
        if (this.currentReport.type) {
          this.questionFilters.report_type = this.currentReport.type.uuid;
        }

        if (this.currentReport && this.currentReport.applicant_submitted_at && this.currentReport.applicant_submitted_at !== '0000-00-00') {
          this.applicant_submitted_at = this.currentReport.applicant_submitted_at;
          this.applicantSubmittedAtDateFormatted = setFormattedDatePickerValue(this.applicant_submitted_at, 'yyyy-LL-dd', 'dd-LL-yyyy');
        }

        this.getQuestions();
        this.getHistoricalReports();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getHistoricalReports() {
    new HistoricalReport()
      .include(['files'])
      .filter({ report: this.reportId })
      .all()
      .then((historicalReports: HistoricalReport[]) => {
        this.historicalReports = historicalReports;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected appendReport() {
    if (! this.currentReport || this.currentReport.status !== 'opname_werkvoorbereiding_done') {
      return;
    }

    new ReportModel()
      .append(this.currentReport.uuid)
      .then((response: AxiosResponse) => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageInstruction(keyValue: string[]) {
    const key: string = keyValue[0];
    const value: any = keyValue[1];

    (this.damageAnswersInstructions as any)[key] = value;
    const payload: DamageAnswerPayload = {
      instructions: this.damageAnswersInstructions,
      report: this.reportId,
    };

    this.updateDamagePromise(payload);
  }

  protected updateDamage(keyValue: string[]) {
    const key: string = keyValue[0];
    const value: any = keyValue[1];

    if (typeof value !== 'string') {
      (this.damageAnswers as any)[key] = value.value;
    } else {
      (this.damageAnswers as any)[key] = value;
    }

    const payload: DamageAnswerPayload = {
      answers: this.damageAnswers,
      report: this.reportId,
    };

    this.updateDamagePromise(payload);
  }

  protected updateDamagePromise(payload: DamageAnswerPayload) {
    if (! this.damage || ! this.damage.uuid) {
      return;
    }

    new Damage()
      .damage(this.damage.uuid)
      .update(payload)
      .then((response: any) => {
        this.$emit('getDamages');
      })
      .catch((error: AxiosError) => {
        console.log('error', error);
      });
  }

  protected updateHistoricalDamage(keyValue: string[]) {
    const key: string = keyValue[0];
    const value: any = keyValue[1];

    if (typeof value !== 'string') {
      (this.HistoricalDamageAnswers as any)[key] = value.target.value;
    } else {
      (this.HistoricalDamageAnswers as any)[key] = value;
    }

    const payload: DamageAnswerPayload = {
      answers: this.HistoricalDamageAnswers,
      report: this.reportId,
    };

    if (! this.historicalDamage || ! this.historicalDamage.uuid) {
      return;
    }

    if (! this.hasAutosave) {
      this.$emit('historicalDamagePayload', payload, this.historicalDamage.uuid);
      return;
    }
    // ...
    new HistoricalReport()
      .damage(this.historicalDamage.uuid)
      .update(payload)
      .catch((error: AxiosError) => {
        console.log('error', error);
      });
  }

  protected createAnswerPayload(id: string, value: any, nestedQuestion: any = null) {
    const question = nestedQuestion || this.getQuestion(id);
    if (! question) {
      return;
    }

    // is in payload
    const inAnswerPayload = this.answerPayload.find((answer: AnswerPayload) => answer.question === question.uuid);

    // create answer object
    const answer = this.answerPayloadObject(question, value);
    const newValue = value.value ? value.value : value;

    // is in payload with same value
    if (inAnswerPayload) {
      if (question.answer.value === newValue) { // value is the same as original => remove from array
        this.answerPayload = this.answerPayload.filter((answer: AnswerPayload) => answer.question !== question.uuid);
        this.$store.dispatch('saveAnswerPayload', this.answerPayload);
        return;
      } if (inAnswerPayload.value === newValue) { // value is the same as in payload => return;
        return;
      } // value is not in payload => add object;
      inAnswerPayload.value = newValue;
      this.$store.dispatch('saveAnswerPayload', this.answerPayload);
      return;
    }

    // is not in answer payload but has same value => return
    if (question.answer.value === newValue) {
      return;
    }

    this.answerPayload.push(answer);
    this.$store.dispatch('saveAnswerPayload', this.answerPayload);
  }

  protected getQuestion(id: string) {
    return this.questions.find((question: Question) => question.uuid === id);
  }

  protected answerPayloadObject(question: Question, value: any) {
    return {
      value: (typeof value === 'object' && ! Array.isArray(value)) ? value.value : value,
      report: this.reportId,
      question: question.uuid,
    };
  }

  protected openAllHistoricReports() {
    this.panelHistoricalReport = [...Array((this.historicalReports as any).length).keys()].map((_) => [true]);
  }

  protected closeAllHistoricReports() {
    this.panelHistoricalReport = [];
  }

  protected setQuestionsVisible() {
    this.questions.forEach((question) => {
      question.visible = true;
      if (question.form_type !== this.user.type && this.user.type !== 'manager') {
        // eslint-disable-next-line no-constant-condition
        if (this.user.type === 'pre_controller' || 'tcmg') {
          return;
        }
        question.disabled = true;
      }
    });
    this.isLoading = false;
  }

  protected previewPdf() {
    window.open(this.currentReport.links.preview);
  }

  // Applicant Submitted At Date
  protected applicantSubmittedAtDateFormatted: string | null = null;

  protected applicantSubmittedAtDateErrorMessage = '';

  protected formatPlannedAtDateFromDatePicker() {
    if (this.applicant_submitted_at) {
      this.applicantSubmittedAtDateFormatted = setFormattedDatePickerValue(this.applicant_submitted_at, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.$root.$emit('dateFieldValid', isValidDate(this.applicantSubmittedAtDateFormatted));
      this.applicantSubmittedAtDateErrorMessage = ! isValidDate(this.applicantSubmittedAtDateFormatted) ? dateErrorMessage : '';
      this.updateReport();
    }
  }

  protected formatApplicantSubmittedAtDateFromTextField(value: string) {
    this.$root.$emit('dateFieldValid', isValidDate(value));
    this.applicantSubmittedAtDateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.applicant_submitted_at = setFormattedDatePickerValue(value);
    this.updateReport();
  }

  protected handleClearApplicantSubmittedAtDate() {
    this.applicantSubmittedAtDateErrorMessage = '';
    this.applicantSubmittedAtDateFormatted = '';
    this.applicant_submitted_at = null;
    this.$root.$emit('dateFieldValid', isValidDate(''));
    this.updateReport();
  }

  protected updateReport() {
    if (! this.hasAutosave) {
      this.$emit('sendApplicantSubmittedAtDate', this.applicant_submitted_at);
      return;
    }
    if (! this.currentReport) {
      return;
    }

    this.currentReport
      .update({
        applicant_submitted_at: this.applicant_submitted_at,
      })
      .then(() => {

      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  @Watch('damageAnswers')
  public damageAnswersChanged(to: any, from: any) {
    //
  }

  @Watch('damage')
  public damageChanged(to: any, from: any) {
    //
  }

  @Watch('sections')
  public sectionsChanged(to: any, from: any) {
    this.questionFilters.sections = to;
    if (this.currentReport && this.currentReport.type) {
      this.getQuestions();
    }

    if (to === 3) {
      this.openAllHistoricReports();
    }
  }
}

interface QuestionFilter {
  form_types?: string[];
  sections?: string[];
  append_keys?: string[];
  report_type?: string;
  report?: string;
}

interface DamageAnswers {
  CalculationMethod?: string;
  DakNummer?: string;
  DakOfGevel?: string;
  EtageSchade?: string;
  GevelNummerSchade?: string;
  LocatieSchade?: string;
  MijnbouwCausaalVerband?: string;
  MijnbouwCausaalVerbandToelichting?: string;
  MijnbouwOorzaakGevolg?: string;
  OmschrijvingOverigeSchade?: string;
  OmschrijvingSchadeGebouwWerk?: string;
  OorzakenToename?: string;
  OorzakenToenameAnders?: string;
  OorzakenToenameOnbekend?: string;
  OpmerkingExpert?: string;
  RuimteAnders?: string;
  RuimteSchade?: string;
  SchadeHerstelMethodiek?: string;
  SchadeKlasse?: string;
  SlaapkamerNummer?: string;
  WandNummer?: string;
  WandNummerAnders?: string;
  WandPlafondVloer?: string;
  damageDate?: string;
  damageVatRuling?: string;
  typeOfDamage?: string;
}

interface HistoricalDamageAnswers {
  historicalReport_ABCSchadesAantallen?: string;
  historicalReport_BIJZONDERHEDEN?: string;
  historicalReport_BedragUitbetaling?: string;
  historicalReport_Calculatieexpert?: string;
  historicalReport_CaseNumber?: string;
  historicalReport_DatumAkkoord?: string;
  historicalReport_FinaleKwijting?: string;
  historicalReport_KeuzeKUSKEAKHC?: string;
  historicalReport_Naamexpert?: string;
  historicalReport_OmschrijvingSchadelocatie?: string;
  historicalReport_Opnamedatum?: string;
  historicalReport_Status?: string;
  historicalReport_Uitbetaald?: string;
  historicalReport_Verslagdatum?: string;
  historicalReport_ZelfdeEigenaar?: string;
}

interface DamageAnswerPayload {
  instructions?: any;
  answers?: any;
  report?: string;
}
