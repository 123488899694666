import { Model } from '@/models/Model';

export class Participant extends Model {
  protected $name = 'Participant';

  protected $endpoint = '/participants';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;

  public property?: string;

  public type?: string;

  public device?: string;

  public links?: any;

  public created_at?: string;

  public updated_at?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
