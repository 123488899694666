var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading ? [_c("ALoader", { staticClass: "tw-flex" })] : _vm._e(),
      !_vm.isLoading
        ? [
            _c("div", { staticClass: "info-block" }, [
              _c("p", { staticClass: "tw-text-2xl" }, [
                _vm._v(" Het bouwjaar van de woning is "),
                _c("span", { staticClass: "tw-font-semibold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.constructionYear
                        ? _vm.constructionYear
                        : "(niet opgegeven)"
                    )
                  )
                ]),
                _vm._v(" en deze schademelding is van "),
                _c("span", { staticClass: "tw-font-semibold" }, [
                  _vm._v(_vm._s(_vm._f("dateFormat")(_vm.report.planned_at)))
                ]),
                _vm._v(
                  " . De hoogst berekende trillingssterkte uit deze periode heeft een PGV van "
                ),
                _c("span", { staticClass: "tw-font-semibold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.realtimeTrilingstoolData &&
                          _vm.realtimeTrilingstoolData.pgv_1pct
                          ? "" +
                              _vm.filterQuantity(
                                _vm.realtimeTrilingstoolData.pgv_1pct
                              )
                          : "(niet gevonden)"
                      ) +
                      " mm/s (1%) "
                  )
                ]),
                _vm._v(" . ")
              ]),
              _c(
                "p",
                { staticClass: "tw-text-2xl tw-font-semibold tw-mt-16" },
                [_vm._v("Conclusie:")]
              ),
              _c("p", { staticClass: "tw-text-2xl" }, [
                _vm._v(" Er is "),
                _c("span", { staticClass: "tw-font-semibold" }, [
                  _vm._v(
                    _vm._s(_vm.hasHigherPgvLimit ? "wel een" : "geen") +
                      " trilling"
                  )
                ]),
                _vm._v(" van "),
                _c("span", { staticClass: "tw-font-semibold" }, [
                  _vm._v(_vm._s(_vm.pgvLimit) + " mm/s (1%)")
                ]),
                _vm._v(" of hoger voorgekomen. ")
              ]),
              _c(
                "div",
                { staticClass: "tw-flex tw-flex-row tw-mt-16" },
                [
                  _c(
                    "MiButton",
                    {
                      attrs: { color: _vm.metaButtonColor },
                      nativeOn: {
                        click: function($event) {
                          return _vm.setAnswer($event)
                        }
                      }
                    },
                    [_vm._v("conclusie overnemen")]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "tw-flex tw-flex-row tw-space-x-16 tw-pt-32" },
              [
                _c("DateFilter", {
                  staticClass: "tw-w-1/2",
                  attrs: { label: "Van" },
                  on: { handleChange: _vm.getRealtimeTrillingtool },
                  model: {
                    value: _vm.filters.from,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "from", $$v)
                    },
                    expression: "filters.from"
                  }
                }),
                _c("DateFilter", {
                  staticClass: "tw-w-1/2",
                  attrs: { label: "Tot" },
                  on: { handleChange: _vm.getRealtimeTrillingtool },
                  model: {
                    value: _vm.filters.until,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "until", $$v)
                    },
                    expression: "filters.until"
                  }
                }),
                _c("v-checkbox", {
                  attrs: {
                    "hide-details": "",
                    label: "Alleen aardbevingen uit Groninger gasveld"
                  },
                  model: {
                    value: _vm.filters.gasfield_id,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "gasfield_id", $$v)
                    },
                    expression: "filters.gasfield_id"
                  }
                })
              ],
              1
            ),
            _c("DataTable", {
              ref: "trillingstoolDatatableCompact",
              attrs: {
                options: _vm.tableOptions,
                visibility: _vm.visibility,
                limit: 5
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }