var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.damage
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "align-center": "" } },
            [
              _vm.visible.checkboxes
                ? _c(
                    "v-flex",
                    { attrs: { xs1: "", shrink: "" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-2",
                        attrs: {
                          value: _vm.damage.uuid,
                          color: "secondary",
                          disabled: _vm.disabled,
                          "hide-details": ""
                        },
                        on: { change: _vm.emitSelectedDamages },
                        model: {
                          value: _vm.selectedDamage,
                          callback: function($$v) {
                            _vm.selectedDamage = $$v
                          },
                          expression: "selectedDamage"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { class: _vm.visible.checkboxes ? "xs10" : "xs12" },
                [
                  _c(
                    "v-expansion-panel",
                    {
                      staticClass: "damagePanel",
                      class: [
                        _vm.showStatus(_vm.damage),
                        _vm.showIdentical(_vm.damage)
                      ],
                      attrs: { expand: "" },
                      model: {
                        value: _vm.panelClone,
                        callback: function($$v) {
                          _vm.panelClone = $$v
                        },
                        expression: "panelClone"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "align-center": "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      class: [
                                        _vm.visible.reject ||
                                        _vm.visible.approve ||
                                        _vm.visible.ambtshalve ||
                                        _vm.visible.anomalies
                                          ? "xs4"
                                          : "xs9",
                                        _vm.damage.is_room_without_damage
                                          ? "tw-text-right"
                                          : ""
                                      ]
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "tw-italic tw-text-gray-400":
                                              _vm.damage.is_room_without_damage
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.createDamageName(
                                                  _vm.damage,
                                                  _vm.index
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.visible.reject ||
                                  _vm.visible.approve ||
                                  _vm.visible.ambtshalve
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "text-xs-right",
                                          attrs: { xs5: "" }
                                        },
                                        [
                                          _vm.visible.editAmbtshalve &&
                                          !_vm.disabled
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                {
                                                                  class:
                                                                    "headerButton headerButton--ambtshalve " +
                                                                    (_vm.hasAmbtshalve(
                                                                      _vm.damage
                                                                    )
                                                                      ? "ambtshalve"
                                                                      : "") +
                                                                    " mr-2",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.openAmbtshalveDialog(
                                                                        _vm.damage
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "custom"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "edit"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3544284922
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Ambtshalve toekennen"
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.visible.reject && !_vm.disabled
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                {
                                                                  class:
                                                                    "headerButton headerButton--reject " +
                                                                    (_vm.visible
                                                                      .internal
                                                                      ? _vm
                                                                          .damage
                                                                          .internal_status
                                                                      : _vm
                                                                          .damage
                                                                          .status),
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.rejectDamage(
                                                                        _vm.damage
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "custom"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "cancel"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1132419576
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Schade afkeuren")
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.visible.approve && !_vm.disabled
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                {
                                                                  class:
                                                                    "ml-2 headerButton headerButton--accept " +
                                                                    (_vm.visible
                                                                      .internal
                                                                      ? _vm
                                                                          .damage
                                                                          .internal_status
                                                                      : _vm
                                                                          .damage
                                                                          .status),
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      $event.preventDefault()
                                                                      return _vm.updateCurrentDamage(
                                                                        _vm.damage,
                                                                        "accepted",
                                                                        _vm.index
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "custom"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "check_circle"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1223593004
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Schade goedkeuren")
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.visible.validations &&
                                  _vm.damage.validations
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "text-xs-right",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _vm.showHeaderWarning(
                                            _vm.damage.validations
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "customRepairIndicator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "custom" },
                                                    [_vm._v("warning")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.showHeaderError(
                                            _vm.damage.validations
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "customRepairIndicator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "custom custom-error"
                                                    },
                                                    [_vm._v("warning")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.visible.anomalies &&
                                  _vm.damage.anomalies &&
                                  _vm.damage.anomalies.length > 0
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "text-xs-right test",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "tw-text-warning-500"
                                                },
                                                [_vm._v("warning")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "panel" },
                            [
                              _vm.panelClone
                                ? _c(
                                    "v-card-text",
                                    [
                                      _vm.visible.anomalies &&
                                      _vm.damage.anomalies &&
                                      _vm.damage.anomalies.length > 0
                                        ? [
                                            _c(
                                              "ATitle",
                                              {
                                                staticClass:
                                                  "tw-px-16 tw-text-black-900"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "tw-text-warning-500 tw-mx-4"
                                                  },
                                                  [_vm._v("warning")]
                                                ),
                                                _vm._v(" Afwijkingen ")
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "item__list item__list--reports tw-mb-24",
                                                attrs: { wrap: "" }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass: "label",
                                                    attrs: { xs12: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { wrap: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs2: "" }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Datum / tijdstip"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs2: "" }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Criteria")
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs2: "" }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Informatie"
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs2: "" }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Reden")
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs4: "" }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Toelichting"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._l(
                                                  _vm.damage.anomalies,
                                                  function(anomaly, index) {
                                                    return _c(
                                                      "v-flex",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      anomaly.created_at
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .AnomalyTypeLabels[
                                                                        anomaly
                                                                          .type
                                                                      ]
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  [
                                                                    _c(
                                                                      "AnomalyCustomProperties",
                                                                      {
                                                                        attrs: {
                                                                          anomaly: anomaly
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs2: ""
                                                                }
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        anomaly.type ===
                                                                          _vm
                                                                            .AnomalyType
                                                                            .ASSESSMENT_SCHEME &&
                                                                          _vm
                                                                            .damage
                                                                            .casuality_has_deviated
                                                                          ? _vm
                                                                              .damage
                                                                              .casuality_anomaly_reason
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs4: ""
                                                                }
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        anomaly.type ===
                                                                          _vm
                                                                            .AnomalyType
                                                                            .ASSESSMENT_SCHEME &&
                                                                          _vm
                                                                            .damage
                                                                            .casuality_has_deviated
                                                                          ? _vm
                                                                              .damage
                                                                              .casuality_anomaly_explanation
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.visible.rejectReasons
                                        ? [
                                            _c("RejectReason", {
                                              attrs: {
                                                rejectReasons:
                                                  _vm.rejectDamageReasons,
                                                model: _vm.damage
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm.visible.rejectImage
                                        ? [
                                            _c("RejectReason", {
                                              attrs: {
                                                rejectReasons:
                                                  _vm.rejectDamageReasons,
                                                showReason: false,
                                                model: _vm.damage
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm.damage.answers
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "item__list item__list--reports",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "label",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs7: "" } },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Schade info"
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs5: "" } },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Antwoord")
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              !_vm.visible.manager &&
                                              !_vm.visible.veldwerk &&
                                              _vm.damage.answers
                                                ? [
                                                    _vm.damage.answers
                                                      .MijnbouwCausaalVerband
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Is de schade veroorzaakt en/of verergerd door mijnbouw?"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .MijnbouwCausaalVerband
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .MijnbouwCausaalVerbandToelichting
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Toelichting"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .MijnbouwCausaalVerbandToelichting
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .ErgerDoorMijnbouw &&
                                                    !_vm.damage.answers
                                                      .MijnbouwCausaalVerband
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Is de nieuwe schade verergerd door mijnbouw?"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .ErgerDoorMijnbouw
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    (!_vm.damage.answers
                                                      .MijnbouwCausaalVerband &&
                                                      _vm.damage.answers
                                                        .ErgerDoorMijnbouwToelichting) ||
                                                    _vm.damage.answers
                                                      .ToelichtingNietDoorMijnbouw
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Toelichting"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .damage
                                                                            .answers
                                                                            .ErgerDoorMijnbouwToelichting
                                                                        ) +
                                                                        _vm._s(
                                                                          _vm
                                                                            .damage
                                                                            .answers
                                                                            .ToelichtingNietDoorMijnbouw
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .damageDate
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Datum ontstaan schade"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm.damage
                                                                  .answers
                                                                  ? _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs5:
                                                                            "",
                                                                          xs4:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .damage
                                                                              .answers
                                                                              .damageDate
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers &&
                                                    !_vm.isVES
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Percentage Calculatie"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .calculation_percentage
                                                                      ) + "%"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .MijnbouwCausaalVerbandToelichting
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Toelichting"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .MijnbouwCausaalVerbandToelichting
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .damageSituationNotRestored
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Eerder behandeld en identiek"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .damage
                                                                            .answers
                                                                            .damageSituationNotRestored ===
                                                                            "Identiek, er is geen verschil waarneembaar"
                                                                            ? "Ja"
                                                                            : "Nee"
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .OmschrijvingSchadeGebouwWerk
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Omschrijving van de eerder behandelde schade"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .OmschrijvingSchadeGebouwWerk
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.damage.answers
                                                      .OmschrijvingSchadeGebouwWerkNieuw
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Omschrijving van de (nieuwe) schade"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .OmschrijvingSchadeGebouwWerkNieuw
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                : _vm._e(),
                                              _vm.visible.veldwerk &&
                                              _vm.damage.answers
                                                ? [
                                                    _vm.damage.answers
                                                      .MijnbouwCausaalVerband
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Is de schade veroorzaakt en/of verergerd door mijnbouw?"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .MijnbouwCausaalVerband
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Percentage Calculatie"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.damage
                                                                      .calculation_percentage
                                                                  ) + "%"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.damage.answers &&
                                              _vm.visible.manager
                                                ? [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Soort schade"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.damage
                                                                        .answers
                                                                        .typeOfDamage
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .SoortSchade
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Datum ontstaan schade"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.damage
                                                                        .answers
                                                                        .damageDate
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .SchadeDatum
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Locatie"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.damage
                                                                        .answers
                                                                        .LocatieSchade
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .Locatie
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Etage"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.damage
                                                                        .answers
                                                                        .EtageSchade
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .BouwlaagBinnen
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "list__item",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs7: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Vertrek"
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs5: ""
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.damage
                                                                        .answers
                                                                        .RuimteSchade
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm
                                                                          .damage
                                                                          .answers
                                                                          .RuimteNummer
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.isVES
                                                      ? [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Is de schade veroorzaakt en/of verergerd door mijnbouw"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .damage
                                                                                .answers
                                                                                .MijnbouwCausaalVerband
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Toelichting"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .damage
                                                                                .answers
                                                                                .MijnbouwCausaalVerbandToelichting
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Percentage Calculatie"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .damage
                                                                                .calculation_percentage
                                                                            ) +
                                                                              "%"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm.visible.media
                                        ? [
                                            _vm.damage.media
                                              ? _c(
                                                  "v-layout",
                                                  { attrs: { "pa-2": "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      _vm._l(
                                                        _vm.damage.media,
                                                        function(media, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "media-" +
                                                                index,
                                                              staticClass:
                                                                "mediaContainerWithDescription"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mediaContainer"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mediaContainer__actions"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs: {
                                                                            href:
                                                                              media.original,
                                                                            target:
                                                                              "_blank"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "remove_red_eye"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        media.thumb
                                                                    }
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mediaContainer__name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      media.description
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      _vm.visible.editMedia
                                        ? [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        "Afbeeldingen schade keuring"
                                                      )
                                                    ]),
                                                    _c(
                                                      "MiButton",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          color: "warning",
                                                          small: "true",
                                                          icon: "cancel",
                                                          outline:
                                                            _vm.damage
                                                              .status ===
                                                            "rejected"
                                                              ? false
                                                              : true
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.updateCurrentDamage(
                                                              _vm.damage,
                                                              "rejected"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Afkeuren ")]
                                                    ),
                                                    _c(
                                                      "MiButton",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px"
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                          color: "success",
                                                          small: "true",
                                                          icon: "check_circle",
                                                          outline:
                                                            _vm.damage
                                                              .status ===
                                                            "accepted"
                                                              ? false
                                                              : true
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.updateCurrentDamage(
                                                              _vm.damage,
                                                              "accepted"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Goedkeuren ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.visible.repairs && _vm.damage
                                        ? [
                                            _c("RepairTable", {
                                              attrs: { damage: _vm.damage }
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm.damage.exception_reason &&
                                      _vm.damage.exception_reason.length
                                        ? _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "pa-3": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      "Schade Uitzondering"
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.damageExceptions,
                                                      "item-value": "key",
                                                      "item-text": "name",
                                                      label: "Uitzondering",
                                                      disabled: true
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.damage
                                                          .exception_reason,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.damage,
                                                          "exception_reason",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "damage.exception_reason"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.damage.exception_reason ===
                                              "overig"
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "fadedText"
                                                        },
                                                        [_vm._v("Toelichting")]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.damage
                                                              .exception_comment
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.visible.edit
                                        ? _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "mt-3": "",
                                                "px-4": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("h4", [
                                                    _vm._v("Schade Aanpassen")
                                                  ]),
                                                  _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        disabled: _vm.disabled,
                                                        color: "primary",
                                                        small: "true",
                                                        icon: "edit",
                                                        outline: true
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleEditDialog(
                                                            _vm.damage,
                                                            _vm.index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Aanpassen ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            row: "",
                                            "mt-3": "",
                                            "px-4": ""
                                          }
                                        },
                                        [
                                          _vm.visible.reject &&
                                          _vm.rejectDamageReasons &&
                                          !_vm.visible.internal
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.rejectDamageReasons,
                                                      "item-value": "id",
                                                      "item-text": "name",
                                                      label: "Afwijsreden",
                                                      multiple: "",
                                                      disabled: _vm.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.updateDamageRejectReasonDebounce(
                                                          _vm.damage,
                                                          $event,
                                                          "reject_reasons"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.damage
                                                          .reject_reasons,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.damage,
                                                          "reject_reasons",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "damage.reject_reasons"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          (_vm.visible.reject ||
                                            _vm.visible.rejectImage) &&
                                          !_vm.visible.internal
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      name: "comment",
                                                      label:
                                                        "Afwijsopmerkingen",
                                                      value:
                                                        _vm.damage
                                                          .reject_comment,
                                                      disabled: _vm.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.updateDamageRejectReason(
                                                          _vm.damage,
                                                          $event,
                                                          "reject_comment"
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.visible.internal
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      name: "comment",
                                                      label:
                                                        "Interne afwijsopmerkingen",
                                                      value:
                                                        _vm.damage
                                                          .internal_reject_comment,
                                                      disabled: _vm.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.updateDamageRejectReason(
                                                          _vm.damage,
                                                          $event,
                                                          "internal_reject_comment"
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm.visible.ambtshalve &&
                                      _vm.hasAmbtshalve(_vm.damage)
                                        ? _c(
                                            "v-layout",
                                            { attrs: { wrap: "", row: "" } },
                                            [
                                              _vm.damage.overrule_reasons
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list item__list--reports",
                                                          attrs: {
                                                            "mt-4": "",
                                                            wrap: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs8: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Ambtshalve redenen"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                        "text-xs-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Ambtshalve bedrag"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs8: ""
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      _vm.damage
                                                                        .overrule_reasons,
                                                                      function(
                                                                        reason,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "span",
                                                                          {
                                                                            key: index,
                                                                            staticClass:
                                                                              "block"
                                                                          },
                                                                          [
                                                                            reason.name ===
                                                                            "Overig, nl."
                                                                              ? [
                                                                                  _vm._v(
                                                                                    "- " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .damage
                                                                                          .overrule_custom
                                                                                      )
                                                                                  )
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    "- " +
                                                                                      _vm._s(
                                                                                        reason.name
                                                                                      )
                                                                                  )
                                                                                ]
                                                                          ],
                                                                          2
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                        "text-xs-right":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "currency"
                                                                              )(
                                                                                _vm
                                                                                  .damage
                                                                                  .overrule_payout
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.damage.validations &&
                                      _vm.visible.validations
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "item__list item__list--reports",
                                              attrs: { "mt-4": "", wrap: "" }
                                            },
                                            [
                                              _vm._l(
                                                _vm.damage.validations,
                                                function(validation, key) {
                                                  return [
                                                    validation !== "ok"
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            key: key,
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "ValidationWarning",
                                                              {
                                                                attrs: {
                                                                  validation: validation,
                                                                  validationKey: key
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }