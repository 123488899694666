import { userRoles } from '@/models/User';
import { sortObjectsArrayByStringDesc } from '@/support/String';
import { orderBy } from 'lodash';

export enum ReportStatus {
  CREATED = 'created',
  PLANNING = 'planning',
  BACK_TO_PLANNING = 'back_to_planning',
  BACK_TO_PLANNING_IMS = 'back_to_planning_ims',
  PLANNING_ON_HOLD = 'planning_on_hold',
  EXPERT_REQUESTED = 'expert_requested',
  WERKVOORBEREIDING_REJECTED = 'werkvoorbereiding_rejected',
  OPNAME_WERKVOORBEREIDING_DONE = 'opname_werkvoorbereiding_done',
  OPNAME_IN_PROGRESS = 'opname_in_progress',
  OPNAME_REJECTED = 'opname_rejected',
  OPNAME_DONE = 'opname_done',
  PLANNING_ORGANIZATION = 'planning_organization',
  IN_PROGRESS = 'in_progress',
  SUPERVISOR = 'supervisor',
  REJECTED = 'rejected',
  SUBMITTED = 'submitted',
  CHECKING = 'checking',
  APPROVED = 'approved',
  VELDWERK_REJECTED = 'veldwerk_rejected',
  VELDWERK_CORRECTED = 'veldwerk_corrected',
  SUBMIT_NO_TEST = 'submit_no_test',
  SPECIALS_IN_REVIEW = 'specials_in_review',
  SPECIALS_REVIEWED = 'specials_reviewed',
  SPECIALS_PLANNED = 'specials_planned',
  ZAAKBEGELEIDER_INCOMING = 'zaakbegeleider_incoming',
  ZAAKBEGELEIDER = 'zaakbegeleider',
  PENDING_SB_APPROVAL = 'pending_sb_approval',
  PROBLEM_TCMG = 'problem_tcmg',
  DOWNLOADED = 'downloaded',
  VELDWERK_ONHOLD = 'veldwerk_onhold',
  ZIENSWIJZE_ONHOLD = 'zienswijze_onhold',
  ABORTED = 'aborted',
  ZIENSWIJZE_WERKVOORBEREIDING_REJECTED = 'zienswijze_werkvoorbereiding_rejected',
  ZIENSWIJZE_IN_PROGRESS = 'zienswijze_in_progress',
  ZIENSWIJZE_CHECKING = 'zienswijze_checking',
  ZIENSWIJZE_REJECTED = 'zienswijze_rejected',
  ZIENSWIJZE_APPROVED = 'zienswijze_approved',
  ZIENSWIJZE_VELDWERK_REJECTED = 'zienswijze_veldwerk_rejected',
  ZIENSWIJZE_CORRECTED = 'zienswijze_corrected',
  ZIENSWIJZE_CLOSED = 'zienswijze_closed',
  OBJECTION_CREATED = 'objection_created',
  OBJECTION_WERKVOORBEREIDING_REJECTED = 'objection_werkvoorbereiding_rejected',
  OBJECTION_PENDING_ASSIGNMENT = 'objection_pending_assignment',
  OBJECTION_IN_PROGRESS = 'objection_in_progress',
  OBJECTION_CHECKING = 'objection_checking',
  OBJECTION_REJECTED = 'objection_rejected',
  OBJECTION_APPROVED = 'objection_approved',
  OBJECTION_VELDWERK_REJECTED = 'objection_veldwerk_rejected',
  OBJECTION_CORRECTED = 'objection_corrected',
  OBJECTION_PENDING_CLOSE = 'objection_pending_close',
  OBJECTION_CLOSED = 'objection_closed',
  SMR_CREATED = 'smr_created',
  SMR_WERKVOORBEREIDING_REJECTED = 'smr_werkvoorbereiding_rejected',
  SMR_IN_PROGRESS = 'smr_in_progress',
  SMR_REJECTED = 'smr_rejected',
  SMR_CHECKING = 'smr_checking',
  SMR_APPROVED = 'smr_approved',
  SMR_VELDWERK_REJECTED = 'smr_veldwerk_rejected',
  SMR_CORRECTED = 'smr_corrected',
  SMR_NO_VISIT = 'smr_no_visit',
  SMR_CLOSED = 'smr_closed',
  MSR_APPROVED = 'msr_approved',
  UNPLANNED = 'unplanned',
  NO_ANSWER = 'no_answer',
  PLANNED = 'planned',
  NOT_NEEDED_ANYMORE = 'not_needed_anymore',
  NO_ACTION_REQUIRED = 'no_action_required',
  VERVOLGOPNAME = 'vervolgopname',
  NADER_ONDERZOEK = 'nader_onderzoek',
  AANVULLEND_ONDERZOEK = 'aanvullend_onderzoek',
  HEROPNAME = 'heropname',
  STRUCTURAL_ENGINEER_REJECTED = 'structural_engineer_rejected',
  STRUCTURAL_ENGINEER_CHECKING = 'structural_engineer_checking',

  // Old & removed, keep for labels in communication logs
  EXCEPTION = 'exception',
  BUITENGEBIED = 'buitengebied',
  RESEARCH_NEEDED = 'research_needed',
}

export const reportStatusMap: ReportStatusMap[] = [
  // Regulier
  { key: ReportStatus.CREATED, label: 'Werkvoorbereiding', color: 'brown', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.PLANNING, label: 'Planning', color: 'blue-magenta', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.BACK_TO_PLANNING, label: 'Teruggezet Planning', color: 'blue-magenta', roles: userRoles.PlanningRoles },
  { key: ReportStatus.BACK_TO_PLANNING_IMS, label: 'Teruggezet Planning IMS', color: 'blue-magenta', roles: userRoles.PlanningRoles },
  { key: ReportStatus.PLANNING_ON_HOLD, label: 'Planning On-hold', color: 'pink', roles: userRoles.PlanningRoles },
  { key: ReportStatus.EXPERT_REQUESTED, label: 'Uitvraag', color: 'green-cyan', roles: userRoles.PreControllerRoles },
  { key: ReportStatus.WERKVOORBEREIDING_REJECTED, label: 'Teruggezet naar Werkvoorbereiding', color: 'pink', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.OPNAME_WERKVOORBEREIDING_DONE, label: 'Opname afgerond voor IMG', color: 'blue-magenta', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.OPNAME_IN_PROGRESS, label: 'Opnemer', color: 'orange-dark', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.OPNAME_REJECTED, label: 'Opnemer teruggezet', color: 'red-dark', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.OPNAME_DONE, label: 'Opnemer afgerond', color: 'green', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.PLANNING_ORGANIZATION, label: 'Planning Expertisebureau', color: 'magenta', roles: userRoles.DeskundigeRoles },
  { key: ReportStatus.IN_PROGRESS, label: 'Deskundige', color: 'orange', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SUPERVISOR, label: 'Supervisor', color: 'yellow', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.REJECTED, label: 'Teruggezet', color: 'red', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SUBMITTED, label: 'Voorcontrole', color: 'blue-magenta', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.CHECKING, label: 'Te controleren', color: 'blue-magenta', roles: userRoles.ManagerRoles },
  { key: ReportStatus.APPROVED, label: 'Nieuw opgeleverd', color: 'green', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.VELDWERK_REJECTED, label: 'Teruggezet Schadebepaling', color: 'red', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.VELDWERK_CORRECTED, label: 'Gecorrigeerd', color: 'orange', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SUBMIT_NO_TEST, label: 'Versturen zonder toetsen', color: 'blue-magenta', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SPECIALS_IN_REVIEW, label: 'Controle JZ', color: 'yellow', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SPECIALS_REVIEWED, label: 'JZ beoordeeld', color: 'secondary-dark', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SPECIALS_PLANNED, label: 'JZ gepland', color: 'pink-magenta', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.ZAAKBEGELEIDER_INCOMING, label: 'Zaakbegeleider Inkomend', color: 'gray-dark', roles: userRoles.CaseMediatorRoles },
  { key: ReportStatus.ZAAKBEGELEIDER, label: 'Bij Zaakbegeleider', color: 'green-cyan', roles: userRoles.CaseMediatorRoles },
  { key: ReportStatus.PENDING_SB_APPROVAL, label: 'Ter akkoord SB', color: 'yellow', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.PROBLEM_TCMG, label: 'Probleem IMG', color: 'primary-dark', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.DOWNLOADED, label: 'Verstuurd aan aanvrager', color: 'blue', roles: userRoles.VeldwerkRoles },

  // Exception
  { key: ReportStatus.VELDWERK_ONHOLD, label: 'Schadebepaling On-hold', color: 'pink', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.ZIENSWIJZE_ONHOLD, label: 'Herzien rapport On-hold', color: 'pink', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.ABORTED, label: 'Afgebroken opdrachten', color: 'red', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },

  // Zienswijze:
  { key: ReportStatus.ZIENSWIJZE_WERKVOORBEREIDING_REJECTED, label: 'Herzien rapport Teruggezet SBA', color: 'pink', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles, ...userRoles.VeldwerkRoles] },
  { key: ReportStatus.ZIENSWIJZE_IN_PROGRESS, label: 'Herzien rapport Lopend', color: 'orange', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.ZIENSWIJZE_CHECKING, label: 'Herzien rapport Te controleren', color: 'blue-magenta', roles: userRoles.PreControllerRoles },
  { key: ReportStatus.ZIENSWIJZE_REJECTED, label: 'Herzien rapport Afgekeurd', color: 'red', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.ZIENSWIJZE_APPROVED, label: 'Herzien rapport Nieuw Opgeleverd', color: 'green', roles: [...userRoles.VeldwerkRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED, label: 'Herzien rapport Afgekeurd Schadebepaling', color: 'red', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.ZIENSWIJZE_CORRECTED, label: 'Herzien rapport Gecorrigeerd', color: 'orange', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.ZIENSWIJZE_CLOSED, label: 'Herzien rapport Afgewikkeld', color: 'blue', roles: userRoles.VeldwerkRoles },

  // Bezwaar
  { key: ReportStatus.OBJECTION_CREATED, label: 'Bezwaar Werkvoorbereiding', color: 'brown', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.OBJECTION_WERKVOORBEREIDING_REJECTED, label: 'Bezwaar Teruggezet BC', color: 'pink', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.OBJECTION_PENDING_ASSIGNMENT, label: 'Bezwaar Afwachting Deskundige', color: 'magenta', roles: userRoles.DeskundigeRoles },
  { key: ReportStatus.OBJECTION_IN_PROGRESS, label: 'Bezwaar Lopend', color: 'orange', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.OBJECTION_CHECKING, label: 'Bezwaar Te controleren', color: 'blue-magenta', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.OBJECTION_REJECTED, label: 'Bezwaar Afgekeurd', color: 'red', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.OBJECTION_APPROVED, label: 'Bezwaar Opgeleverd', color: 'green', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.OBJECTION_VELDWERK_REJECTED, label: 'Bezwaar Afgekeurd Schadebepaling', color: 'red', roles: userRoles.ManagerRoles },
  { key: ReportStatus.OBJECTION_CORRECTED, label: 'Bezwaar Gecorrigeerd', color: 'orange', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.OBJECTION_PENDING_CLOSE, label: 'Bezwaar Goedgekeurd', color: 'green', roles: userRoles.ObjectionRoles },
  { key: ReportStatus.OBJECTION_CLOSED, label: 'Bezwaar Afgewikkeld', color: 'blue', roles: userRoles.ObjectionRoles },

  // SMR
  { key: ReportStatus.SMR_CREATED, label: 'SMR Planning', color: 'brown', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.SMR_WERKVOORBEREIDING_REJECTED, label: 'SMR Teruggezet WV', color: 'pink', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles] },
  { key: ReportStatus.SMR_IN_PROGRESS, label: 'SMR Lopend', color: 'orange', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SMR_REJECTED, label: 'SMR Afgekeurd', color: 'red', roles: [...userRoles.DeskundigeRoles, ...userRoles.SimpleRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SMR_CHECKING, label: 'SMR Te controleren', color: 'blue-magenta', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SMR_APPROVED, label: 'SMR Nieuw Opgeleverd', color: 'green', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SMR_VELDWERK_REJECTED, label: 'SMR Afgekeurd Schadebepaling', color: 'red', roles: [...userRoles.PreControllerRoles, ...userRoles.HelpdeskTCMG] },
  { key: ReportStatus.SMR_CORRECTED, label: 'SMR Gecorrigeerd', color: 'orange', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.SMR_NO_VISIT, label: 'SMR Zonder opname ', color: 'pink', roles: userRoles.ManagerRoles },
  { key: ReportStatus.SMR_CLOSED, label: 'SMR Afgewikkeld', color: 'blue', roles: userRoles.ManagerRoles },

  // MSR
  { key: ReportStatus.MSR_APPROVED, label: 'MSR Opgeleverd', color: 'green', roles: userRoles.ManagerRoles },

  { key: ReportStatus.STRUCTURAL_ENGINEER_CHECKING, label: 'Bouwkundige controle', color: 'yellow', roles: userRoles.VeldwerkRoles },
  { key: ReportStatus.STRUCTURAL_ENGINEER_REJECTED, label: 'Bouwkundige vraag', color: 'secondary-dark', roles: userRoles.ManagerRoles },

  // Old: (keep for logging)
  // {key: ReportStatus, label: 'Zienswijze Lopend', color: 'orange', roles: userRoles.ManagerRoles},
  // {key: ReportStatus, label: 'Zienswijze Opgeleverd', color: 'green', roles: userRoles.ManagerRoles},
  // {key: ReportStatus, label: 'Zienswijze Afgewikkeld', color: 'blue-magenta', roles: userRoles.ManagerRoles},

  // Planning status
  { key: ReportStatus.UNPLANNED, label: 'Nog te plannen', color: 'orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.NO_ANSWER, label: 'Geen gehoor / TBV', color: 'red', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.PLANNED, label: 'Ingepland', color: 'blue-magenta', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.NOT_NEEDED_ANYMORE, label: 'Niet meer nodig', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.NO_ACTION_REQUIRED, label: 'Geen actie', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },

  { key: ReportStatus.VERVOLGOPNAME, label: 'Vervolgopname', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.NADER_ONDERZOEK, label: 'Nader onderzoek', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.AANVULLEND_ONDERZOEK, label: 'Aanvullend onderzoek', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },
  { key: ReportStatus.HEROPNAME, label: 'Heropname', color: 'red-orange', roles: [...userRoles.WVRoles, ...userRoles.PlanningRoles], type: 'planning-status' },

  // Old & removed, keep for labels in communication logs
  { key: ReportStatus.EXCEPTION, label: 'Uitgezonderd', color: 'pink', roles: userRoles.ManagerRoles },
  { key: ReportStatus.BUITENGEBIED, label: 'Buitengebied', color: 'red-orange', roles: userRoles.ManagerRoles },
  { key: ReportStatus.RESEARCH_NEEDED, label: 'Nader onderzoek', color: 'yellow', roles: [...userRoles.DeskundigeRoles, ...userRoles.HelpdeskTCMG] },
];

export const planningStatusLabelMap: { [key: string]: string } = {
  not_needed_anymore: 'Niet meer nodig',
  discarded: 'Niet meer nodig',
  callback: 'Terugbel afspraak',
  callback_tbv: 'Terugbel afspraak TBV',
  callback_corona: 'Terugbel afspraak corona',
  callback_risk: 'Terugbel afspraak risicogroep',
  no_answer: 'Geen gehoor',
  no_answer_ggh1: 'Geen gehoor 1',
  no_answer_ggh2: 'Geen gehoor 2',
  no_answer_ggh3: 'Geen gehoor 3',
  no_answer_ggh4: 'Geen gehoor 4',
};

export const statusHexColor: { [key: string]: string } = {
  red: '#F74949',
  'red-orange': '#ce5d25',
  green: '#62DC4F',
  'green-cyan': '#2edba7',
  brown: '#852222',
  orange: '#f98d35',
  yellow: '#F9BD35',
  pink: '#ff0077',
  magenta: '#a12891',
  'pink-magenta': '#ff66cc',
  'blue-magenta': '#551A8B',
  blue: '#B8DBEB',
  'secondary-dark': '#837f16',
  'primary-dark': '#354454',
  'red-dark': '#c53a3a',
  'orange-dark': '#c7702a',
};

/**
 * This returns the color based on the key from reportStatusMap
 * @param color
 */
export const getStatusColor = (key: string): string => getStatusAttribute(key, 'color') as string;

/**
 * This returns the label based on the key from reportStatusMap
 * @param label
 */
export const getStatusLabel = (key: string): string => getStatusAttribute(key, 'label') as string;

/**
 * This returns the roles based on the key from reportStatusMap
 * @param roles
 */
export const getStatusRoles = (key: string): string[] => getStatusAttribute(key, 'roles') as string[];

/**
 * This returns a sorted array from reportStatusMap
 * @param sorted
 */
export const getSortedStatus = (key: string): ReportStatusMap[] => sortObjectsArrayByStringDesc(reportStatusMap, key);

/**
 * This returns a array of status keys from reportStatusMap
 * @param keys
 */
export const getStatusKeys = (): string[] => reportStatusMap.map((reportStatus: ReportStatusMap) => reportStatus.key);

/**
 *  This replaces status key with a styled/colored label
 * @param message
 */
export const getStyledStatusLog = (message: string, type = '') => {
  let parsed = message;
  let count = 0;

  if (type === 'status_change') {
    getSortedStatus('key').forEach((status: ReportStatusMap) => {
      if (message.includes(status.key)) {
        if (count > 0) {
          return;
        }

        count = 1;
        parsed = message.replace(status.key, `<span class="font-weight-bold statusColor--${getStatusColor(status.key)}">${status.label}</span>`);
      }
    });
  }

  return parsed;
};

/**
 *  Gets specific status object from a string or string array values as a status array
 * @param message
 */
export const getStatusObjects = (key: string | string[]) => {
  const foundReportStatuses: ReportStatusMap[] = [];

  if (Array.isArray(key)) {
    (key as string[]).forEach((currentKey: string) => {
      foundReportStatuses.push(searchStatus(currentKey));
    });
  } else {
    foundReportStatuses.push(searchStatus(key));
  }

  return foundReportStatuses;
};

/**
 * This gets the specific object from reportStatusMap based on which key
 * @param attribute
 */
const getStatusAttribute = (key: string, attribute: string) => {
  const statusObject = searchStatus(key);

  return (statusObject as ReportStatusMap)[attribute];
};

/**
 * This seeks the specific object from reportStatusMap based on which key
 * @param search
 */
const searchStatus = (key: string) => {
  const defaultStatus: ReportStatusMap = { key: '', label: 'Onbekend', color: 'primary', roles: userRoles.ManagerRoles };
  const status = reportStatusMap.find((reportStatus: ReportStatusMap) => reportStatus.key === key);

  return status || defaultStatus;
};

export const excludePlanningStatuses = () => reportStatusMap.filter((reportType: ReportStatusMap) => reportType.type !== 'planning-status');

export const planningStatuses = () => reportStatusMap.filter((reportType: ReportStatusMap) => reportType.type === 'planning-status');

export const excludeReportStatuses = (key: string | string[]): ReportStatusMap[] => {
  let foundReportStatuses: ReportStatusMap[] = [];

  if (Array.isArray(key)) {
    foundReportStatuses = [...reportStatusMap, ...[]];
    (key as string[]).forEach((currentKey: string) => {
      foundReportStatuses = foundReportStatuses.filter((reportType: ReportStatusMap) => reportType.key !== currentKey);
    });
  } else {
    foundReportStatuses = reportStatusMap.filter((reportType: ReportStatusMap) => reportType.key !== key);
  }

  return foundReportStatuses;
};

export interface ReportStatusMap {
  [key: string]: string | string[];
  key: ReportStatus | string;
  label: string;
  color: string;
  roles: string[];
}

export const sortedExcludePlanningStatuses = () => orderBy(reportStatusMap.filter((reportType: ReportStatusMap) => reportType.type !== 'planning-status'), 'label', 'asc');
