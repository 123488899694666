import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Tag } from '@/models/Tag';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<ReportTags>({})
export default class ReportTags extends Vue {
  @Prop()
  protected reportId?: string;

  @Prop({ default: true })
  protected isEditing?: boolean;

  @Prop({ default: false })
  protected disabled?: boolean;

  @Prop({ default: true })
  protected autoSave?: boolean;

  @Prop()
  protected reportTags?: Tag[];

  protected tags: Tag[] = [];

  protected selectedTags: Tag[] = [];

  protected mounting = true;

  protected get allowEdit() {
    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  public mounted() {
    this.initialize();
    this.setTags();
  }

  protected setTags() {
    this.selectedTags = [];

    if (this.reportTags) {
      this.reportTags.forEach((tag: Tag) => {
        this.selectedTags.push((tag as any).id);
      });
      this.$nextTick(() => {
        this.mounting = false;
        this.$emit('tagsCreated', this.selectedTags);
      });
    }
  }

  protected initialize() {
    this.getTags();
  }

  protected getTags() {
    new Tag()
      .all()
      .then((tags: Tag[]) => {
        this.tags = tags;
      })
      .catch(() => {});
  }

  protected createTags() {
    const payload = {
      tags: this.selectedTags,
    };

    if (this.mounting) return;
    new Report()
      .convertTags(this.reportId, payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected removeTag(index: number) {
    this.selectedTags.splice(index, 1);
    this.selectedTagsChanged();
  }

  protected selectedTagsChanged() {
    if (this.autoSave) {
      this.createTags();
    } else {
      this.$emit('tagsCreated', this.selectedTags);
    }
  }

  protected getName(id: string) {
    const tag = this.tags.find((tag: Tag) => tag.id === id);

    return tag ? tag.name : '';
  }

  // Watchers
  @Watch('isEditing')
  protected isEditingChanged() {
    if (! this.isEditing) {
      this.setTags();
    }
  }
}
