import { Model } from '@/models/Model';

export class Statistic extends Model {
  protected $name = 'Statistic';

  protected $endpoint = '/statistics';

  protected $fillable: string[] = [];

  public deadlines?: Deadline;

  public resolvePrimaryKey(): string {
    return '';
  }
}

interface Deadline {
  organizations?: Organization[];
}

export interface Organization {
  id?: string;
  name?: string;
  total?: number;
  y?: number;
  types?: Type[];
  status?: Status[];
  experts?: Expert[];
  color?: string;
}

export interface Type {
  name?: string;
  id?: string;
  color?: string;
  total?: number;
  y?: number;
  data?: number[];
  [key: string]: any;
}

export interface Expert {
  id?: string;
  name?: string;
  total?: number
  types?: Type[];
  y?: number;
  color?: string;
  [key: string]: any;
}

export interface Status {
  name?: string;
  key?: string;
  total: number;
  y: number;
  color?: string;
  [key: string]: any;
}
