


















































import QuestionaireDialogConclusionType from '@/items/QuestionaireDialogConclusionType';
import { Question } from '@/models/Question';
import { Report } from '@/models/Report';
import { formatDate } from '@/support/String';
import { DateTime } from 'luxon';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TableVisibility } from '../data-table/DataTable';

@Component<PgvInfoBlock>({})
export default class PgvInfoBlock extends Vue {
  @Prop({ default: null })
  protected question!: Question;

  @Prop({ default: null })
  protected report!: Report;

  protected filters: trilingstoolFilters = {
    from: this.constructionYear?.length ? `${this.constructionYear}-01-01` : '2012-01-01',
    until: this.report.planned_at || DateTime.local().toFormat('yyyy-LL-dd'),
    gasfield_id: 1,
  };

  protected realtimeTrilingstoolData: Report | null = null;

  protected isLoading = true;

  protected mounted() {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.getRealtimeTrillingtool();
  }

  protected filterQuantity(value: number): string {
    return new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value);
  }

  protected setAnswer() {
    if (!this.question) {
      return;
    }

    const value = this.hasHigherPgvLimit ? 'Ja' : 'Nee';
    this.$emit('answerQuestion', this.question, value);
  }

  protected async getRealtimeTrillingtool() {
    if (!this.report?.uuid) {
      return;
    }

    this.isLoading = true;

    const realtimeTrilingstoolData = await new Report()
      .setTrilingstoolRealtimeEndpoint(this.report.uuid)
      .limit(1)
      .filter(this.tableFilters)
      .all()
      .catch(error => null);

    if (realtimeTrilingstoolData) {
      this.realtimeTrilingstoolData = realtimeTrilingstoolData[0];
      this.isLoading = false;
    }
  }

  protected get pgvLimit(): number {
    return (this.question?.meta?.pgv_limit as number) || 0;
  }

  protected get hasHigherPgvLimit(): boolean {
    return this.pgvLimit <= (this.realtimeTrilingstoolData?.pgv_1pct || 0);
  }

  protected get metaButtonColor(): string {
    return QuestionaireDialogConclusionType.buttonColors[this.hasHigherPgvLimit ? 'success' : 'error'] || '';
  }

  protected get constructionYear(): string {
    return this.report ? this.report.printAnswer('BouwJaar') : '';
  }

  protected get tableOptions(): Object {
    return {
      model: new Report()
        .setTrilingstoolRealtimeEndpoint(this.report.uuid ? this.report.uuid : '')
        .filter(this.tableFilters),
      headers: [
        {
          text: 'datum beving',
          value: 'occurred_at',
          transform: (occurred_at: string) => {
            if (!occurred_at) {
              return;
            }

            return formatDate(occurred_at, 'dd-LL-yyyy');
          },
        },
        {
          text: 'Magnitude & Locatie',
          value: 'magnitude',
          transform: (magnitude: number, report: Report) =>
            `M${new Intl.NumberFormat('nl-NL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(magnitude)} ${report.city}`,
        },
        {
          text: 'PGV 1% (mm/s)***',
          value: 'pgv_1pct',
          class: 'text-right',
          transform: (pgv_1pct: number) =>
            new Intl.NumberFormat('nl-NL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(pgv_1pct),
        },
      ],
      actions: [],
    };
  }

  protected get tableFilters(): trilingstoolFilters {
    return this.filters;
  }

  protected get visibility(): TableVisibility {
    return {
      checkboxes: false,
      title: false,
      search: false,
      limit: false,
      hidePaginationTop: true,
    };
  }
}

interface trilingstoolFilters {
  from: string;
  until: string;
  gasfield_id: string | number;
}
