var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.question.type === "header"
        ? _c("h2", { staticClass: "sectionTitle" }, [
            _vm._v(" " + _vm._s(_vm.question.label) + " ")
          ])
        : _vm._e(),
      _vm.question.type === "textbox"
        ? _c("v-text-field", {
            attrs: {
              label: _vm.question.label,
              name: _vm.question.key,
              disabled: _vm.question.disabled || _vm.disabled,
              required: _vm.question.required,
              color: "secondary"
            },
            on: {
              blur: function($event) {
                return _vm.answerQuestion(_vm.stringInput)
              }
            },
            model: {
              value: _vm.stringInput,
              callback: function($$v) {
                _vm.stringInput = $$v
              },
              expression: "stringInput"
            }
          })
        : _vm._e(),
      _vm.question.type === "number"
        ? _c("v-text-field", {
            attrs: {
              label: _vm.question.label,
              name: _vm.question.key,
              disabled: _vm.question.disabled || _vm.disabled,
              required: _vm.question.required,
              color: "secondary",
              type: "number"
            },
            on: {
              blur: function($event) {
                return _vm.answerQuestion(_vm.stringInput)
              }
            },
            model: {
              value: _vm.stringInput,
              callback: function($$v) {
                _vm.stringInput = $$v
              },
              expression: "stringInput"
            }
          })
        : _vm._e(),
      _vm.question.type === "checkbox"
        ? _c(
            "div",
            { staticClass: "formGroup" },
            [
              _c("label", [_vm._v(_vm._s(_vm.question.label))]),
              _vm._l(_vm.question.options, function(option, index) {
                return _c("v-checkbox", {
                  key: index,
                  attrs: {
                    value: option.uuid,
                    label: option.label,
                    name: _vm.question.key,
                    id: option.key,
                    primary: "",
                    "hide-details": "",
                    disabled: _vm.disabled,
                    color: "secondary"
                  },
                  on: {
                    change: function($event) {
                      return _vm.answerQuestion(_vm.checkboxInput)
                    }
                  },
                  model: {
                    value: _vm.checkboxInput,
                    callback: function($$v) {
                      _vm.checkboxInput = $$v
                    },
                    expression: "checkboxInput"
                  }
                })
              })
            ],
            2
          )
        : _vm._e(),
      _vm.question.type === "radio"
        ? _c(
            "div",
            { staticClass: "formGroup" },
            [
              _c("label", [_vm._v(_vm._s(_vm.question.label))]),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: false, disabled: _vm.disabled },
                  on: {
                    change: function($event) {
                      return _vm.answerQuestion(_vm.radioGroup)
                    }
                  },
                  model: {
                    value: _vm.radioGroup,
                    callback: function($$v) {
                      _vm.radioGroup = $$v
                    },
                    expression: "radioGroup"
                  }
                },
                [
                  _vm._l(_vm.question.options, function(option, index) {
                    return [
                      _c(
                        "span",
                        {
                          key: index,
                          staticClass: "relative radioSelection",
                          class:
                            index !== _vm.question.options.length ? "mb-2" : ""
                        },
                        [
                          _c("v-radio", {
                            staticClass: "mb-1",
                            attrs: {
                              label: option.label,
                              value: option,
                              color: "secondary",
                              disabled: _vm.disabled
                            }
                          }),
                          _vm.showDelete(option)
                            ? _c(
                                "span",
                                {
                                  staticClass: "deleteSelection",
                                  on: {
                                    click: function($event) {
                                      _vm.answerQuestion("")
                                      _vm.radioGroup = null
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("delete")])],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.question.type === "textarea"
        ? _c(
            "div",
            [
              _c("prefillable-text-area", {
                attrs: {
                  question: _vm.question,
                  instruction: _vm.instruction,
                  answer: _vm.stringInput,
                  disabled: _vm.disabled
                },
                on: {
                  questionAnswered: _vm.answerQuestion,
                  questionInstruction: _vm.answerQuestionInstruction
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.question.type === "alert"
        ? _c("p", { staticClass: "alertMessage" }, [
            _vm._v(_vm._s(_vm.question.label))
          ])
        : _vm._e(),
      _vm.question.type === "date"
        ? _c(
            "v-menu",
            {
              ref: _vm.question.ref,
              attrs: {
                "close-on-content-click": false,
                lazy: "",
                "nudge-right": "130",
                "full-width": "",
                "max-width": "290px",
                "min-width": "290px",
                disabled: _vm.disabled
              }
            },
            [
              _c("v-text-field", {
                staticClass: "input input-date",
                attrs: {
                  slot: "activator",
                  "prepend-icon": "event",
                  label: _vm.question.label,
                  clearable: "",
                  "error-messages": _vm.dateErrorMessage,
                  disabled: _vm.disabled
                },
                on: {
                  "click:clear": function($event) {
                    return _vm.handleClear($event)
                  },
                  change: function($event) {
                    return _vm.dateFromTextField($event)
                  }
                },
                slot: "activator",
                model: {
                  value: _vm.formattedDate,
                  callback: function($$v) {
                    _vm.formattedDate = $$v
                  },
                  expression: "formattedDate"
                }
              }),
              _c("v-date-picker", {
                ref: "picker",
                attrs: {
                  locale: "nl-nl",
                  min: "1910-01-01",
                  color: "#837f16",
                  "first-day-of-week": "1"
                },
                on: { input: _vm.answerDateQuestion },
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.question.type === "year"
        ? _c("v-text-field", {
            attrs: {
              label: _vm.question.label,
              name: _vm.question.key,
              disabled: _vm.question.disabled || _vm.disabled,
              required: _vm.question.required,
              type: "number"
            },
            on: {
              blur: function($event) {
                return _vm.answerQuestion(_vm.stringInput)
              }
            },
            model: {
              value: _vm.stringInput,
              callback: function($$v) {
                _vm.stringInput = $$v
              },
              expression: "stringInput"
            }
          })
        : _vm._e(),
      _vm.radioGroup && _vm.radioGroup.questions
        ? _c(
            "div",
            _vm._l(_vm.radioGroup.questions, function(nestedQuestion, index) {
              return _c(
                "div",
                { key: index, attrs: { xs12: "" } },
                [
                  _c("DynamicFormQuestion", {
                    key: nestedQuestion.key,
                    attrs: {
                      question: nestedQuestion,
                      damage: _vm.damage,
                      reportId: _vm.reportId,
                      disabled: _vm.disabled,
                      hasAutosave: _vm.hasAutosave
                    },
                    on: {
                      answeredDamageQuestionInstruction: function($event) {
                        return _vm.updateDamageInstruction($event)
                      },
                      answeredDamageQuestion: function($event) {
                        return _vm.updateDamage($event)
                      },
                      createAnswerPayload: _vm.createAnswerPayload,
                      questionAnswered: _vm.questionAnswered
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.checkboxInputChosenOptions
        ? _c(
            "div",
            _vm._l(_vm.checkboxInputChosenOptions, function(
              checkboxInputChosenOption,
              index
            ) {
              return _c(
                "div",
                { key: index },
                _vm._l(checkboxInputChosenOption.questions, function(
                  nestedQuestion,
                  index
                ) {
                  return _c(
                    "div",
                    { key: index, attrs: { xs12: "" } },
                    [
                      _c("DynamicFormQuestion", {
                        key: nestedQuestion.key,
                        attrs: {
                          question: nestedQuestion,
                          damage: _vm.damage,
                          reportId: _vm.reportId,
                          disabled: _vm.disabled,
                          hasAutosave: _vm.hasAutosave
                        },
                        on: {
                          answeredDamageQuestionInstruction: function($event) {
                            return _vm.updateDamageInstruction($event)
                          },
                          answeredDamageQuestion: function($event) {
                            return _vm.updateDamage($event)
                          },
                          createAnswerPayload: _vm.createAnswerPayload,
                          questionAnswered: _vm.questionAnswered
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }