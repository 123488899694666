import { Component } from 'vue-property-decorator';
import AAbstractInput from '@/components/AAbstractInput/AAbstractInput';

@Component<ATextInput>({})
export default class ATextInput extends AAbstractInput {
  protected get isSimple(): boolean {
    if (typeof this.$attrs.simple === 'boolean') {
      return this.$attrs.simple;
    }
    return this.$attrs.simple !== undefined;
  }
}
