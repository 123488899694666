var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _c(
              "v-autocomplete",
              _vm._g(
                _vm._b(
                  {
                    ref: "vAutocompleteInput",
                    staticClass: "atabix-autocomplete-input with-border",
                    attrs: {
                      "append-icon": _vm.appendInnerIcon,
                      autocomplete: _vm.autocomplete ? "on" : "off",
                      "cache-items": _vm.internalCacheItems,
                      chips: _vm.internalChips,
                      "data-vv-name": _vm.name,
                      "deletable-chips": _vm.hasDeletableChips,
                      disabled: _vm.internalDisabled,
                      "error-messages": errors,
                      "hide-details": false,
                      "item-text": _vm.internalItemText,
                      "item-value": _vm.internalItemValue,
                      items: _vm.internalItems,
                      label: _vm.internalLabel,
                      loading: _vm.isLoading,
                      multiple: _vm.internalMultiple,
                      "no-filter": _vm.filterLocally,
                      "prepend-icon": _vm.prependOuterIcon,
                      "return-object": _vm.returnsObject,
                      "search-input": _vm.searchString
                    },
                    on: {
                      "update:searchInput": function($event) {
                        _vm.searchString = $event
                      },
                      "update:search-input": function($event) {
                        _vm.searchString = $event
                      },
                      blur: function($event) {
                        return _vm.handleBlurEvent()
                      },
                      change: function($event) {
                        return _vm.handleChangeEvent()
                      },
                      focus: function($event) {
                        return _vm.loadOnFocus()
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function(index, name) {
                          return {
                            key: name,
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._t(name, null, null, item)]
                            }
                          }
                        }),
                        {
                          key: "append-item",
                          fn: function() {
                            return [
                              _c("div", [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "intersect",
                                      rawName: "v-intersect",
                                      value: {
                                        onChange: _vm.onBottomOfItemListVisibile
                                      },
                                      expression:
                                        "{ onChange: onBottomOfItemListVisibile }"
                                    }
                                  ]
                                }),
                                _vm.lastInternalItemHasNextPage && _vm.isLoading
                                  ? _c(
                                      "div",
                                      [
                                        _c("LoaderCard", {
                                          staticClass: "tw-pt-8",
                                          attrs: { type: "spinner--small" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          },
                          proxy: true
                        },
                        _vm.noResultText
                          ? {
                              key: "no-data",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "atabix-autocomplete-input__no-data",
                                      on: {
                                        click: _vm.$listeners["clickNoResult"]
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.noResultText))
                                      ])
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.internalValue,
                      callback: function($$v) {
                        _vm.internalValue = $$v
                      },
                      expression: "internalValue"
                    }
                  },
                  "v-autocomplete",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }