export class QuestionBase<T> {
  value: T;

  type: string;

  key: string;

  uuid: string;

  label: string;

  form_type: string;

  is_required: boolean;

  order: number;

  controlType: string;

  options: any[];

  visible: boolean;

  is_formattable: boolean;

  section: any;

  disabled: any;

  answer: any;

  constructor(options: {
    value?: any,
    type?: string;
    key?: string,
    uuid?: string;
    label?: string,
    form_type?: string,
    is_required?: boolean,
    order?: number,
    controlType?: string,
    options?: any[];
    visible?: boolean;
    is_formattable?: boolean;
    section?: string;
    disabled?: boolean;
  } = {}) {
    this.value = options.value;
    this.type = options.type || '';
    this.key = options.key || '';
    this.uuid = options.uuid || '';
    this.label = options.label || '';
    this.form_type = options.form_type || '';
    this.is_required = !! options.is_required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.options = options.options || [];
    this.visible = !! this.value;
    this.is_formattable = options.is_formattable || false;
    if (options.section) {
      this.section = options.section;
    }

    if (options.disabled) {
      this.disabled = options.disabled;
    }
  }

  getQuestions() {
    const questions = [];
    questions.push(this);
    this.options.forEach((option) => {
      if (option.questions !== undefined) {
        option.questions.forEach((subQuestion: any) => {
          subQuestion.getQuestions().forEach((q: any) => {
            questions.push(q);
          });
        });
      }
    });
    return questions;
  }

  setSubQuestionKeys(postfix: string) {
    this.options.forEach((option) => {
      if (option.questions !== undefined) {
        option.questions.forEach((subQuestion: any) => {
          subQuestion.key = `${subQuestion.key}-${postfix}`;
          subQuestion.toggleSubQuestionsVisible();
        });
      }
    });
  }

  toggleSubQuestionsVisible(visible = true) {
    this.options.forEach((option) => {
      if (option.questions !== undefined) {
        option.questions.forEach((subQuestion: any) => {
          subQuestion.visible = visible;

          if ((subQuestion.type === 'radio' || subQuestion.type === 'checkbox') && ! visible) {
            subQuestion.checked = visible;
          }
          subQuestion.toggleSubQuestionsVisible(visible);
        });
      }
    });
  }
}
