import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { validationRules, Rules } from '@/support/ValidationRules';

@Component<MiPassword>({})
export default class MiPassword extends Vue {
  @Prop({ default: { password: 'lock_open', confirmation: 'lock_open' } })
  protected icons!: MiPasswordIcons;

  @Prop({ default: () => {} })
  protected visibility!: MiPasswordVisibility;

  protected visible: MiPasswordVisibility = {
    ruleList: false,
    showPassword: false,
  };

  protected password = '';

  protected passwordConfirmation = '';

  protected hasError = true;

  protected validRules = false;

  protected rules: Rules = validationRules(this.getTranslation);

  protected options: MiPasswordOptions = {
    password: {
      icon: 'visibility',
      type: 'password',
      visble: false,
    },
    passwordConfirmation: {
      icon: 'visibility',
      type: 'password',
      visble: false,
    },
  };

  protected passwordRulesList: PasswordRulesList[] = [
    {
      name: 'lowercase',
      rule: this.rules.password.lowercase,
    },
    {
      name: 'uppercase',
      rule: this.rules.password.uppercase,
    },
    {
      name: 'length',
      rule: this.rules.password.length,
    },
    {
      name: 'number',
      rule: this.rules.password.number,
    },
    {
      name: 'special',
      rule: this.rules.password.special,
    },
  ];

  protected get showPasswordConfirmationError() {
    if (this.password.length && this.passwordConfirmation.length) {
      return ! this.isPasswordEqual;
    }

    return false;
  }

  protected get emptyPasswords() {
    if (this.password.length && this.passwordConfirmation.length) {
      return false;
    }

    return true;
  }

  protected get isPasswordEqual() {
    return this.password === this.passwordConfirmation;
  }

  protected get passwordRules() {
    return [
      this.rules.password.lowercase,
      this.rules.password.uppercase,
      this.rules.password.length,
      this.rules.password.number,
      this.rules.password.special,
    ];
  }

  public mounted() {
    this.visible = { ...this.visible, ...this.visibility };
  }

  public getPassword() {
    return {
      password: this.password,
      confirmation: this.passwordConfirmation,
    };
  }

  protected validRule(valid: boolean) {
    this.validRules = valid;
    return valid;
  }

  public resetRules() {
    this.validRules = false;
  }

  public reset() {
    this.password = '';
    this.passwordConfirmation = '';
  }

  protected getTranslation(key: string) {
    return this.$t(key);
  }

  protected handleShowMiPassword(password: MiPasswordModel) {
    if (password.visble) {
      password.type = 'password';
      password.icon = 'visibility';
      password.visble = false;
    } else {
      password.type = 'text';
      password.icon = 'visibility_off';
      password.visble = true;
    }
  }

  protected handlePasswordError(hasError: boolean) {
    this.hasError = hasError;
  }

  protected get isPasswordValid() {
    return ! this.showPasswordConfirmationError;
  }

  @Watch('isPasswordValid')
  protected validChange() {
    this.$emit('input', this.isPasswordValid);
  }
}

interface MiPasswordOptions {
  [key: string]: MiPasswordModel;
}

interface MiPasswordModel {
  icon: string;
  type: string;
  visble: boolean;
}

interface PasswordRulesList {
  name: string;
  rule: Function | boolean;
}

export interface MiPasswordVisibility {
  ruleList?: boolean;
  showPassword?: boolean;
}

export interface MiPasswordIcons {
  [key: string]: string;
}
