var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "icon", attrs: { sm1: "" } },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
            1
          ),
          _c("v-flex", { attrs: { sm11: "" } }, [
            _vm.type === "create"
              ? _c("h3", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("createDialog.body.title.create", {
                        role: _vm.$tc(_vm.role + ".title", 1)
                      })
                    )
                  )
                ])
              : _vm._e(),
            _vm.type === "report"
              ? _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("bugReport.header")))
                ])
              : _vm._e(),
            _vm.type === "event"
              ? _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("event.title")))
                ])
              : _vm._e(),
            _vm.type === "file"
              ? _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("createDialog.body.title.upload")))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "close" }, [
            _c("button", {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            }),
            _c("div", { staticClass: "esc" }, [
              _vm._v(_vm._s(_vm.$t("actions.esc")))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }