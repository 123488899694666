import { RejectReason } from '@/models/RejectReason';
import { Model } from '@/models/Model';
import client from '@/support/Client';
import { Media } from '@/models/Report';
import { Repair } from '@/models/Repair';
import { OverruleReason } from '@/models/OverruleReason';
import { concatenate } from '@/support/String';
import { Anomaly } from './Anomaly';

export class Damage extends Model {
  protected $name = 'Damage';

  protected $endpoint = '/damages';

  protected $fillable: string[] = ['from', 'till', 'overrule_payout', 'overrule_reasons'];

  public id?: string;

  public answers?: DamageAnswers | any;

  public apply_vat?: string;

  public calculation_percentage?: string;

  public created_at?: string;

  public updated_at?: string;

  public links?: any;

  public media?: Media[];

  public name?: string;

  public repairs?: Repair[];

  public sort_order?: number;

  public uuid?: string;

  public totalCost?: number;

  public reject_comment?: string;

  public reject_reason?: any;

  public reject_reasons?: RejectReason[];

  public status?: DamageStatus;

  public overrule_payout?: string;

  public overrule_reasons?: OverruleReason[] | string[];

  public exception_reason?: string;

  public exception_comment?: string;

  public validations?: any;

  public instructions?: any;

  public finance?: DamageFinance;

  public assumed_answers!: Record<string, boolean>;

  public expert_remark?: DamageExpertRemark;

  public expert_remarks?: string;

  public internal_status?: string;

  public internal_reject_comment?: string;

  public is_room_without_damage?: boolean;

  public casuality_has_deviated?: boolean;

  public casuality_anomaly_reason?: string;

  public casuality_anomaly_explanation?: string;

  public classification?: Classification;

  public anomalies?: Anomaly[];

  public damage(damage: Model | string) {
    this.$endpoint = `/damages/${damage}`;
    return this;
  }

  public mediaEndpoint(damage: Damage) {
    this.$endpoint = `/damages/${damage.uuid}/media`;
    return this;
  }

  public duplicateDamage(damage: Damage) {
    this.$endpoint = `/damages/${damage.uuid}/duplicate`;
    return this;
  }

  public transferDamage() {
    this.$endpoint = `/damages/${this.uuid}/transfer`;
    return this;
  }

  public updateAnswer() {
    this.$endpoint = `/damages/${this.uuid}/update-answer`;
    return this;
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public sortDamages(attributes: string[]) {
    const url = this.$endpoint;

    return client('post', url, attributes, true);
  }

  public updateImageMatchInformation(uuid: string): this {
    this.$endpoint = `${this.$endpoint}/${uuid}/update-image-matching-info`;
    return this;
  }

  protected static calculateIndex = (index: number, damages: Damage[]) => {
    let count = 0;
    let notFoundCount = 0;
    damages.forEach((damage) => {
      if (count === index) { return; }
      if (damage.is_room_without_damage) {
        notFoundCount += 1;
      }
      count += 1;
    });

    return count - notFoundCount;
  };

  public static createDamageName = (damage: Damage, index: number, damages: Damage[]) => {
    const newIndex = Damage.calculateIndex(index, damages);

    if (damage.is_room_without_damage) {
      return damage?.name || '';
    }

    const defaultName = concatenate(`${newIndex + 1}. Nieuwe schade ${newIndex + 1}`, 30);
    if (! damage?.name) {
      return defaultName;
    }

    const name = damage.name.replace(/\s/g, '').length ? damage.name : defaultName;
    return `${newIndex + 1}. ${name}`;
  };
}

export interface DamageFinance {
  subtotal_ex: number;
  subtotal_in: number;
}

export interface DamageAnswers {
  CalculationMethod?: string;
  DakNummer?: string;
  DakOfGevel?: string;
  EtageSchade?: string;
  GevelNummerSchade?: string;
  Locatie?: string;
  LocatieSchade?: string;
  MijnbouwCausaalVerband?: string;
  MijnbouwCausaalVerbandToelichting?: string;
  MijnbouwOorzaakGevolg?: string;
  OmschrijvingOverigeSchade?: string;
  OmschrijvingSchadeGebouwWerk?: string;
  OorzakenToename?: string;
  OorzakenToenameAnders?: string;
  OorzakenToenameOnbekend?: string;
  OpmerkingExpert?: string;
  RuimteAnders?: string;
  RuimteSchade?: string;
  SchadeHerstelMethodiek?: string;
  SchadeKlasse?: string;
  SlaapkamerNummer?: string;
  WandNummer?: string;
  WandNummerAnders?: string;
  WandPlafondVloer?: string;
  damageDate?: string;
  damageVatRuling?: string;
  typeOfDamage?: string;
}

export interface DamageExpertRemark {
  outside_surface_facade_length?: number,
  outside_surface_facade_width?: number,
  outside_surface_facade_square_meter?: number,
  outside_facing_brick_layers?: number,
  outside_facing_brick_stretches?: number,
  outside_previously_visibly_restored?: boolean,
  inside_floor_area_length?: number,
  inside_floor_area_width?: number,
  inside_floor_area_square_meter?: number,
  inside_wall_size_length?: number,
  inside_wall_size_width?: number,
  inside_wall_size_square_meter?: number,
  inside_other_walls_length?: number,
  inside_opening_size_length?: number,
  inside_opening_size_width?: number,
  inside_opening_size_square_meter?: number,
  inside_type_of_walls?: string,
  inside_particularities?: string,
  inside_crack_hollowcore_slab?: string,
}

export enum Classification {
  ONBEKEND = 'onbekend',
  CAUSAAL = 'causaal',
  NIET_CAUSAAL = 'niet_causaal',
  IDENTIEK_NAM = 'identiek_nam',
  IDENTIEK_IMG = 'identiek_img',
  ROOM_WITHOUT_DAMAGE = 'room_without_damage',
}

export enum DamageStatusEnum {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  CHANGED = 'changed',
}

export type DamageStatus = DamageStatusEnum.PENDING | DamageStatusEnum.ACCEPTED | DamageStatusEnum.REJECTED | DamageStatusEnum.CHANGED;
