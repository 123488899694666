import { sanitizeString } from '@/support/String';
import { Article } from '@/models/Article';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LibraryItem } from '@/components/LibraryTree/LibraryTree';

@Component<LibraryArticle>({})
export default class LibraryArticle extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop()
  protected article!: LibraryItem;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {

  }
  // #endregion

  // #region Class methods

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  protected async fetchArticle(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    const selectedArticle = await new Article()
      .include(['media', 'default_answers', 'repair_types'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  protected async fetchArticleMedia(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    const selectedArticle = await new Article()
      .include(['media'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (selectedArticle) {
      // (this.selectedArticle as Article).media = selectedArticle.media;
      // this.fetchFolders();
    }
  }
  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  // #endregion
}
