import { Model } from '@/models/Model';

export class RepairType extends Model {
  protected $name = 'RepairType';

  protected $endpoint = '/repairs/types';

  protected $fillable: string[] = [];

  public created_at?: string;

  public custom_apply_surtax?: boolean;

  public custom_name?: string;

  public custom_unit?: string;

  public custom_unitprice?: string;

  public custom_customvat?: string;

  public form?: string;

  public links?: object;

  public quantity?: string;

  public total?: number;

  public type?: any;

  public unitprice?: string;

  public updated_at?: string;

  public uuid?: string;

  public vat?: number;

  public elementcluster?: any;

  public subsection?: string;

  public section?: string;

  public default_quantity?: string;
}
