var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reportContainer" },
    [
      _vm.isEditing
        ? _c("v-select", {
            attrs: {
              items: _vm.tags,
              "item-text": "name",
              "item-value": "id",
              "hide-details": "",
              placeholder: "Tags",
              clearable: "",
              multiple: "",
              disabled: _vm.disabled
            },
            on: { change: _vm.selectedTagsChanged },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      index === 0
                        ? _c("span", [_vm._v(_vm._s(item.name) + " ")])
                        : _vm._e(),
                      index === 1
                        ? _c("span", { staticClass: "grey--text caption" }, [
                            _vm._v(
                              "(+" +
                                _vm._s(_vm.selectedTags.length - 1) +
                                " andere)"
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              4023920875
            ),
            model: {
              value: _vm.selectedTags,
              callback: function($$v) {
                _vm.selectedTags = $$v
              },
              expression: "selectedTags"
            }
          })
        : _vm._e(),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "align-content-start": "",
            "align-start": "",
            "justify-start": ""
          }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "chips",
              attrs: {
                s12: "",
                "align-content-start": "",
                "align-start": "",
                "justify-start": ""
              }
            },
            [
              !_vm.isEditing
                ? _vm._l(_vm.selectedTags, function(tag, index) {
                    return _c(
                      "v-chip",
                      { key: index, attrs: { disabled: _vm.disabled } },
                      [_c("span", [_vm._v(_vm._s(_vm.getName(tag)))])]
                    )
                  })
                : _vm._e(),
              _vm.isEditing
                ? _vm._l(_vm.selectedTags, function(tag, index) {
                    return _c(
                      "v-chip",
                      {
                        key: index,
                        attrs: { close: "", disabled: _vm.disabled },
                        on: {
                          input: function($event) {
                            return _vm.removeTag(index)
                          }
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.getName(tag)))])]
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }