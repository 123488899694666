import { extend, ValidationProvider } from 'vee-validate';
import { email, max, max_value, min, min_value, numeric, required } from 'vee-validate/dist/rules';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { accepted, date,
  date_after,
  date_after_or_equal,
  date_before,
  date_before_or_equal,
  date_between,
  iban,
  bic } from '@/support/AValidationRules';
import StatefulForm from '@/components/AStatefulForm/AStatefulForm';
import IconNamePropertyMixin from '@/mixins/AIconNamePropertyMixin';
import HasNestedBindingsMixin from '@/mixins/AHasNestedBindingsMixin';
import { ATranslator } from '@/support/ATranslator';

@Component<AbstractInput>({
  components: {
    ValidationProvider,
  },
})
export default class AbstractInput extends mixins(Vue, IconNamePropertyMixin, HasNestedBindingsMixin) {
  // #region @VModel
  // #endregion

  // #region @PropSyncs
  // #endregion

  // #region @Props

  /**
   * The name for an input element.
   */
  @Prop({ default: '' })
  protected name!: string;

  /**
   * A list of veevalidate rules split with the `|` operator
   * @example required|min:5
   * @see {@link https://logaretm.github.io/vee-validate/guide/rules.html}
   */
  @Prop({ default: '' })
  protected rules!: string;

  /**
   * autocomplete lets web developers specify what if any permission the user agent has to provide automated assistance in filling out form field values
   */
  @Prop({ default: true })
  protected autocomplete!: boolean;

  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties

  protected internalValue: any = (this.$attrs.value !== undefined) && (this.$attrs.value !== null) ? this.$attrs.value : null;

  // #endregion

  // #region Lifecycle Hooks

  protected beforeCreate(): void {
    (this as any).$statefulFormCompatible = true;
  }

  protected created(): void {
    this.onAbstractInputCreated();
  }

  protected mounted(): void {
    this.onAbstractInputMounted();
  }

  protected beforeDestroy(): void {
    this.onAbstractInputBeforeDestroy();
  }

  // #endregion

  // #region Lifecycle Hooks: Handlers

  protected onAbstractInputCreated(): void {
    this.guardInvalidIconPropUse();

    this.$on('input', (data: any) => {
      this.form?.emitChange(this.name, data, this);
    });
  }

  protected onAbstractInputMounted(): void {
    // eslint-disable-next-line no-unused-expressions
    this.form?.registerField(this.name, this);
  }

  protected onAbstractInputBeforeDestroy(): void {
    // eslint-disable-next-line no-unused-expressions
    this.form?.unregisterField(this.name);
  }

  // #endregion

  // #region Class methods: Handlers
  // #endregion

  // #region Class methods: Helpers

  public getName(): string {
    return this.name;
  }

  public reset(): void {
    const validator = this.validator;
    if (! validator) return;

    const initialValue = validator.initialValue;
    this.internalValue = initialValue;
    /**
     * Used for resetting to it's base value or communicating when the internal value changed
     */
    this.$emit('input', initialValue);
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters

  public get form(): StatefulForm | null {
    const findForm = (parent: Vue): StatefulForm | null => {
      if ((parent as any).$isStatefulForm) {
        return parent as StatefulForm;
      }

      if (parent.$parent) {
        return findForm(parent.$parent);
      }

      return null;
    };

    return findForm(this.$parent);
  }

  protected get internalLabel(): string | undefined {
    if (typeof this.$attrs.label === 'boolean' && ! this.$attrs.label) {
      return undefined;
    }
    console.log();
    return this.$attrs.label || this.$attrs.placeholder || '';
  }

  public get validator(): AValidatedVueComponent | null {
    const findValidator = (children: Vue[]): AValidatedVueComponent | null => {
      for (let index = 0; index < children.length; index += 1) {
        const child = children[index];
        if ((child as any).$_veeObserver) {
          return child as AValidatedVueComponent;
        }

        return findValidator(child.$children);
      }

      return null;
    };

    return findValidator(this.$children);
  }

  // #endregion

  // #region @Watchers

  @Watch('internalValue')
  protected onChangedInternalValue(newValue: any, oldValue: any): void {
    if (oldValue === newValue) return;
    this.$emit('input', newValue);
  }

  @Watch('$attrs.value')
  protected onChangedValue(newValue: any, oldValue: any): void {
    if (oldValue === newValue) return;
    this.internalValue = newValue;
  }

// #endregion
}

// #region Enums
// #endregion

// #region Types
// #endregion

// #region Interfaces

export interface AValidatedVueComponent extends Vue {
  initialValue: any;
}

// #endregion

extend('required', required);
extend('max', max);
extend('min', min);
extend('max_value', max_value);
extend('min_value', min_value);
extend('email', email);
extend('numeric', numeric);
extend('accepted', accepted);
extend('date', date);
extend('date_after', date_after);
extend('date_before', date_before);
extend('date_after_or_equal', date_after_or_equal);
extend('date_before_or_equal', date_before_or_equal);
extend('date_between', date_between);
extend('iban', iban);
extend('bic', bic);
extend('url', {
  validate: (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
          + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
          + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
          + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
          + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
          + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !! pattern.test(str);
  },
  message: ATranslator.tc('validation_rules_url') || 'This is not a valid URL',
});
extend('phone', {
  validate: (str) => {
    const pattern = /^\d{10}$/; // 10 digits with no comma, no spaces, no punctuation
    return !! pattern.test(str);
  },
  message: ATranslator.tc('validation_rules_phone') || 'This is not a valid phone number',
});
