var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "repairDialog" },
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.closeCreatingRepair } },
        [
          _c("template", { slot: "header" }, [
            _c("h2", [
              _vm._v(
                "Reparatie " + _vm._s(_vm.isEditing ? "aanpassen" : "toevoegen")
              )
            ])
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-space-between": "",
                        "align-baseline": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-5": "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "", "hide-details": "" },
                              model: {
                                value: _vm.repairFormType,
                                callback: function($$v) {
                                  _vm.repairFormType = $$v
                                },
                                expression: "repairFormType"
                              }
                            },
                            [
                              _c(
                                "v-radio",
                                {
                                  attrs: {
                                    value: "calculation",
                                    color: "#a09b1b"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        slot: "label",
                                        value: "calculation"
                                      },
                                      slot: "label"
                                    },
                                    [_vm._v("Calculatie")]
                                  )
                                ]
                              ),
                              _c(
                                "v-radio",
                                {
                                  attrs: { value: "custom", color: "#a09b1b" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "label", value: "custom" },
                                      slot: "label"
                                    },
                                    [_vm._v("Aangepast")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.repairFormType === "calculation"
                        ? [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs11: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.repairCategories,
                                        "item-value": "uuid",
                                        "item-text": "name",
                                        label: "Categorie"
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.selectedRepair = null
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedSubSection,
                                        callback: function($$v) {
                                          _vm.selectedSubSection = $$v
                                        },
                                        expression: "selectedSubSection"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-flex", { attrs: { xs1: "" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "informationTooltip" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            dark: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2703458109
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " Kies de herstelactiviteiten die primair nodig zijn, hierna kunnen in het elementcluster de maatregelen die aanvullend noodzakelijk zijn geselecteerd worden. "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs11: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.repairElementClusters,
                                        "item-value": "elementcluster",
                                        "item-text": "elementcluster",
                                        label: "Element cluster",
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.selectedRepair = null
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedElementCluster,
                                        callback: function($$v) {
                                          _vm.selectedElementCluster = $$v
                                        },
                                        expression: "selectedElementCluster"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-flex", { attrs: { xs1: "" } }, [
                                  _vm.selectedRepair || _vm.repairs.length
                                    ? _c(
                                        "div",
                                        { staticClass: "informationTooltip" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2703458109
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " Onder dit punt vult u de maatregel in die nodig is voor herstel. U voegt één maatregel per keer op. Zijn er meerdere maatregelen nodig, dan maakt u een nieuwe reparatie aan onder dezelfde schade. "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs11: "" } },
                                  [
                                    _vm.selectedRepair || _vm.repairs.length
                                      ? _c("v-select", {
                                          attrs: {
                                            items: _vm.repairs,
                                            "return-object": "",
                                            "item-text": "name",
                                            label: "Reparatie",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.selectedRepair,
                                            callback: function($$v) {
                                              _vm.selectedRepair = $$v
                                            },
                                            expression: "selectedRepair"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.selectedRepair && _vm.repairs.length
                              ? _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs3: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Hoeveelheid",
                                            type: "number",
                                            min: "0"
                                          },
                                          model: {
                                            value: _vm.repairQuantity,
                                            callback: function($$v) {
                                              _vm.repairQuantity = $$v
                                            },
                                            expression: "repairQuantity"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-flex", { attrs: { xs1: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "informationTooltip",
                                          on: {
                                            click: function($event) {
                                              return _vm.openInformationDialog(
                                                "amount"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2703458109
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Klik voor meer informatie over de hoeveelheid"
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("v-flex", { attrs: { xs1: "" } }, [
                                      _c("p", { staticClass: "repairUnit" }, [
                                        _vm._v(_vm._s(_vm.selectedRepair.unit))
                                      ])
                                    ]),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "", "pa-2": "" }
                                      },
                                      [
                                        _vm._v(" Prijs per eenheid: "),
                                        _c("span", { staticClass: "amount" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.selectedUnitPrice
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _c("v-flex", { attrs: { xs1: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "informationTooltip",
                                          on: {
                                            click: function($event) {
                                              return _vm.openInformationDialog(
                                                "pricePerUnit"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                dark: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2703458109
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Klik voor meer informatie over de prijs per eenheid"
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs2: "", "pa-2": "" }
                                      },
                                      [
                                        _vm._v(" Totaal: "),
                                        _c("span", { staticClass: "amount" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.selectedRepairTotal
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.repairFormType === "custom"
                        ? [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Beschrijving van de reparatie",
                                        type: "text"
                                      },
                                      model: {
                                        value: _vm.repairDescription,
                                        callback: function($$v) {
                                          _vm.repairDescription = $$v
                                        },
                                        expression: "repairDescription"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs8: "", sm4: "", "pr-1": "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Hoeveelheid",
                                        type: "number",
                                        min: "0"
                                      },
                                      model: {
                                        value: _vm.customRepairQuantity,
                                        callback: function($$v) {
                                          _vm.customRepairQuantity = $$v
                                        },
                                        expression: "customRepairQuantity"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs2: "", "px-1": "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.unitTypeOptions,
                                        label: "Eenheid"
                                      },
                                      model: {
                                        value: _vm.customRepairUnit,
                                        callback: function($$v) {
                                          _vm.customRepairUnit = $$v
                                        },
                                        expression: "customRepairUnit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs2: "", "px-1": "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Prijs per eenheid",
                                        type: "number"
                                      },
                                      on: {
                                        keyup: function($event) {
                                          return _vm.calculateCustomTotal(
                                            _vm.customRepairQuantity
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.customRepairUnitPrice,
                                        callback: function($$v) {
                                          _vm.customRepairUnitPrice = $$v
                                        },
                                        expression: "customRepairUnitPrice"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-xs-center",
                                    attrs: { xs6: "", sm2: "", "pa-2": "" }
                                  },
                                  [
                                    _vm._v(" Totaal: "),
                                    _c("span", { staticClass: "amount" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(_vm.customTotal)
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "", sm2: "", "px-1": "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.vatOptions,
                                        label: "BTW"
                                      },
                                      model: {
                                        value: _vm.chosenVat,
                                        callback: function($$v) {
                                          _vm.chosenVat = $$v
                                        },
                                        expression: "chosenVat"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Toeslag percentages toepassen",
                                        "hide-details": "",
                                        name: "useAllowance",
                                        color: "#a09b1b"
                                      },
                                      model: {
                                        value: _vm.applySurtax,
                                        callback: function($$v) {
                                          _vm.applySurtax = $$v
                                        },
                                        expression: "applySurtax"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _vm.isSubmitted
                            ? _c("span", { staticClass: "submittedText" }, [
                                _vm._v("Reparatie is opgeslagen")
                              ])
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "fadedText link mr-3",
                              on: { click: _vm.closeCreatingRepair }
                            },
                            [_vm._v("Annuleren")]
                          ),
                          _vm.repairFormType === "calculation"
                            ? _c(
                                "MiButton",
                                {
                                  attrs: { color: "primary", small: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.storeRepair($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isEditing
                                          ? "Reparatie wijzigen"
                                          : "Reparatie Toevoegen"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.repairFormType === "custom"
                            ? _c(
                                "MiButton",
                                {
                                  attrs: { color: "primary", small: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.storeCustomRepair($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isEditing
                                          ? "Reparatie wijzigen"
                                          : "Reparatie Toevoegen"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.newlyCreatedRepairs && _vm.newlyCreatedRepairs.length
                ? [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Laatst toegevoegde reparaties")
                    ]),
                    _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "label", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs6: "" } }, [
                                  _vm._v("Reparaties")
                                ]),
                                _c("v-flex", { attrs: { xs2: "" } }, [
                                  _c("span", [_vm._v("Eenheid")])
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-xs-right",
                                    attrs: { xs2: "" }
                                  },
                                  [_c("span", [_vm._v("Prijs")])]
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-xs-right",
                                    attrs: { xs2: "" }
                                  },
                                  [_c("span", [_vm._v("BTW %")])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.newlyCreatedRepairs, function(
                          repair,
                          index
                        ) {
                          return _c(
                            "v-flex",
                            { key: repair.uuid, staticClass: "list__item" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs6: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        repair.form !== "custom"
                                          ? repair.type.name
                                          : repair.custom_name
                                      )
                                    )
                                  ]),
                                  _c("v-flex", { attrs: { xs2: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          repair.form !== "custom"
                                            ? repair.type.unit
                                            : repair.custom_unit
                                        )
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right",
                                      attrs: { xs2: "" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              repair.total_incl_vat
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right",
                                      attrs: { xs2: "" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(repair.vat) + "%")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        2
      ),
      _vm.currentInformation
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "700px" },
              model: {
                value: _vm.informationDialogOpen,
                callback: function($$v) {
                  _vm.informationDialogOpen = $$v
                },
                expression: "informationDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { type: "spinner--center", minHeight: "400px" }
                      })
                    : _vm._e(),
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel__header",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__title",
                              attrs: { sm11: "" }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _c("v-icon", { staticClass: "infoIcon" }, [
                                    _vm._v("info")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.informationContent[
                                          _vm.currentInformation
                                        ].title
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__close",
                              attrs: { sm1: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.informationDialogOpen = false
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.informationContent[_vm.currentInformation].content,
                    function(information) {
                      return _c("div", { key: information.title }, [
                        information.header
                          ? _c("h2", { staticClass: "informationTitle" }, [
                              _vm._v(_vm._s(information.header))
                            ])
                          : _vm._e(),
                        information.text
                          ? _c("p", [_vm._v(_vm._s(information.text))])
                          : _vm._e(),
                        information.bulletPoints
                          ? _c(
                              "ul",
                              { staticClass: "bulletPointList" },
                              _vm._l(information.bulletPoints, function(
                                bulletPoint
                              ) {
                                return _c("li", { key: bulletPoint }, [
                                  _vm._v(_vm._s(bulletPoint))
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    }
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }