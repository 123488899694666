import { Model } from '@/models/Model';

export class Setting extends Model {
  protected $name = 'Setting';

  protected $endpoint = '/settings';

  protected $fillable: string[] = [];

  public id?: string;

  public key?: SettingKey;

  public value?: string | boolean | number;

  public created_at?: string;

  public updated_at?: string;

  public cast?: string;

  public updateSetting(payload: any): Promise<any> {
    return this.request('put', this.$endpoint, payload);
  }

  public resolvePrimaryKey(): string {
    return '';
  }
}

export enum SettingKey {
  PLANNING_EMAILBOX_ADMINS = 'planning_emailbox_admins',
  PLANNING_EXPERTS_NOTIFIABLE = 'planning_experts_notifiable',
  PLANNING_EXPERTS_CAN_ACCESS_AVAILABILITY = 'planning_experts_can_access_availability',
  PLANNING_ACTIVE_WEEK = 'planning_active_week',
  PLANNING_SCHEDULE_LOCK_DATE_USERS = 'planning_schedule_lock_date_users',
  PLANNING_SCHEDULE_LOCK_DATE_ADMINS = 'planning_schedule_lock_date_admins',
  PLANNING_VISIBLE_END_DATE_FOR_TCMG = 'planning_visible_end_date_for_tcmg',
  PLANNING_REQUESTED_START_DATE = 'planning_requested_start_date',
  PLANNING_REQUESTED_END_DATE = 'planning_requested_end_date',
  PLANNING_PLAN_WINDOW_START = 'planning_plan_window_start',
  PLANNING_PLAN_WINDOW_END = 'planning_plan_window_end',
  REPORT_STEEKPROEF_INTERVAL = 'report_steekproef_interval',
  REPORT_STEEKPROEF_COUNTER = 'report_steekproef_counter',
  ACCEPTANCE_IS_DEPLOYING = 'acceptance_is_deploying',
  PRODUCTION_TARGET_WEEK = 'production_target_week',
  PRODUCTION_TARGET_MONTH = 'production_target_month',
  PLANNING_TARGET_WEEK = 'planning_target_week',
  PLANNING_TARGET_MONTH = 'planning_target_month',
  HOURS_DEFAULT_TARIFF_PROCES_EXPERT = 'hours_default_tariff_proces_expert',
  HOURS_DEFAULT_TARIFF_MEDIOR_DESKUNDIGE = 'hours_default_tariff_medior_deskundige',
  HOURS_DEFAULT_TARIFF_SENIOR_DESKUNDIGE = 'hours_default_tariff_senior_deskundige',
  HOURS_DEFAULT_TARIFF_PROJECT_COORDINATOR = 'hours_default_tariff_project_coordinator',
  HOURS_DEFAULT_INTERNAL_TARIFF_PROCES_EXPERT = 'hours_default_internal_tariff_proces_expert',
  HOURS_DEFAULT_INTERNAL_TARIFF_MEDIOR_DESKUNDIGE = 'hours_default_internal_tariff_medior_deskundige',
  HOURS_DEFAULT_INTERNAL_TARIFF_SENIOR_DESKUNDIGE = 'hours_default_internal_tariff_senior_deskundige',
  HOURS_DEFAULT_INTERNAL_TARIFF_PROJECT_COORDINATOR = 'hours_default_internal_tariff_project_coordinator',
  INVOICE_SENDER_NAME = 'invoice_sender_name',
  INVOICE_SENDER_EMAIL = 'invoice_sender_email',
  HOURS_CHANGE_LOCK_UNTIL_USERS = 'hours_change_lock_until_users',
  HOURS_CHANGE_LOCK_UNTIL_ADMINS = 'hours_change_lock_until_admins',
  EXPERT_ARCHIVE_VISIBILITY = 'expert_archive_visibility',
  MAX_VISIT_DESKUNDIGE = 'max_visit_deskundige',
  MAX_VISIT_OPNEMER = 'max_visit_opnemer',
  MAX_VISIT_TYPE_DEFAULT = 'max_visit_type_default',
  MAX_VISIT_TYPE_VERVOLGOPNAME = 'max_visit_type_vervolgopname',
  MAX_VISIT_TYPE_NADER_ONDERZOEK = 'max_visit_type_nader_onderzoek',
  MAX_VISIT_TYPE_AANVULLEND_ONDERZOEK = 'max_visit_type_aanvullend_onderzoek',
  MAX_VISIT_TYPE_HEROPNAME = 'max_visit_type_heropname',
  MAX_VISIT_TYPE_HOORZITTING = 'max_visit_type_hoorzitting',
  MAX_VISIT_TYPE_SECOND_OPINION = 'max_visit_type_second_opinion',
  MAX_VISIT_TYPE_OPNAME_OP_AFSTAND = 'max_visit_type_opname_op_afstand',
  MAX_VISIT_TYPE_PHYSICAL = 'max_visit_type_physical',
  MAX_VISIT_TYPE_WEBEX = 'max_visit_type_webex',
  MAX_VISIT_TYPE_OFFICE = 'max_visit_type_office',
  MAX_VISIT_TYPE_CALL = 'max_visit_type_call',
  CAN_EDIT_BOOKED_HOURS = 'can_edit_booked_hours',
}

export interface Language {
  language: string;
  locale: string;
}
