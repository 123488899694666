import { Model } from '@/models/Model';

export class UserFilter extends Model {
  protected $name = 'UserFilter';

  protected $endpoint = '/user-filters';

  protected $fillable: string[] = [
    'name',
    'key',
    'slug',
    'default',
    'user_filters',
  ];

  public id?: string;

  public name?: string;

  public key?: string;

  public slug?: string;

  public default?: boolean;

  public user_filters?: ReportFilters;

  get uuid(): string {
    return this.id ? this.id : '';
  }
}

export interface ReportFilters {
  [key: string]: any;
  search?: string;
  first_person?: string;
  section?: string;
  status?: string;
  statuses?: string[];
  expert?: string;
  pre_controller?: string;
  technical_coordinator?: string;
  secondExperts?: string;
  report_handler?: string;
  report_handlers?: string[];
  organization?: string;
  organizations?: string[];
  type?: string;
  project?: string;
  types?: string[];
  tags?: string[];
  overdue?: boolean;
  future?: boolean;
  specials?: boolean;
  planning_status?: string;
  plannable_action?: string;
  from?: string;
  till?: string;
  has_ebs?: boolean;
  requires_nulmeting?: boolean;
  objection_type?: string | string[];
}
