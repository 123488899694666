/* eslint-disable no-return-assign */
import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import { QuestionBase } from '@/models/question-models/question-base';
import { DateTime } from 'luxon';
import { Answer } from '@/models/Answer';
import { AxiosError } from 'axios';
import { Damage } from '@/models/Damage';
import { HistoricalReport } from '@/models/HistoricalReport';
import { isValidDate, setFormattedDatePickerValue, dateErrorMessage } from '@/support/String';
import PrefillableTextArea from '@/components/prefillable-text-area/PrefillableTextArea.vue';

@Component<DynamicFormQuestion>(
  {
    name: 'DynamicFormQuestion',
    components: {
      PrefillableTextArea,
    },
  },
)
export default class DynamicFormQuestion extends Vue {
  @Model('isBusy', { default: false })
  isBusy!: boolean;

  @Prop({ default: '' })
  protected reportId!: string;

  @Prop({ default: false })
  protected disabled!: string;

  @Prop()
  protected damage?: Damage;

  @Prop()
  protected historicalDamage?: HistoricalReport;

  @Prop()
  protected question!: QuestionBase<any>;

  @Prop({ default: true })
  protected hasAutosave!: boolean;

  protected radioGroup: any = null;

  protected instruction = '';

  protected stringInput = '';

  protected checkboxInput: string[] = [];

  protected checkboxInputChosenOptions: any[] = [];

  protected date: string | null = null;

  protected currentQuestion: QuestionBase<any> | null = null;

  protected currentAnswer: Answer | null = null;

  protected damageAnswers: any = {};

  protected damageAnswersInstructions: any = {};

  protected nestedQuestions: any = [];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    if (this.damage && this.damage.answers) {
      this.damageAnswers = this.damage.answers;
      this.damageAnswersInstructions = this.damage.instructions;
      this.checkDamageAnswers(this.question);
      return;
    }

    if (this.historicalDamage && this.historicalDamage.answers) {
      this.damageAnswers = this.historicalDamage.answers;

      this.checkDamageAnswers(this.question);
      return;
    }
    this.$nextTick(() => {
      this.checkForAnswer();
    });
  }

  protected getPayloadQuestion() {
    return this.$store.state.answerPayload.find((question: any) => this.question.uuid === question.question);
  }

  protected fillStringInput() {
    if (this.$store.state.answerPayload) {
      const payloadQuestion = this.getPayloadQuestion();

      this.stringInput = payloadQuestion ? payloadQuestion.value : this.question.answer.value;
    } else {
      this.stringInput = this.question.answer.value ? this.question.answer.value : '';
    }
  }

  protected fillCheckboxInput() {
    if (this.$store.state.answerPayload) {
      const payloadQuestion = this.getPayloadQuestion();
      this.checkboxInput = payloadQuestion ? [...payloadQuestion.value, ...[]] : this.question.answer.value;
    } else {
      this.checkboxInput = this.question.answer.value ? [...this.question.answer.value, ...[]] : [];
    }
  }

  protected fillDateInput() {
    if (this.question.answer.value !== 'Invalid DateTime') {
      const answer = this.question.answer.value;
      let format = 'yyyy-MM-dd';

      if (answer && answer.length > 12) {
        format = 'yyyy-MM-dd hh:mm:ss';
      }

      if (answer && answer[2] === '-') {
        format = 'dd-MM-yyyy';
      }

      if (answer && DateTime.fromFormat(answer, 'yyyy-MM-dd hh:mm:ss').isValid) {
        this.date = DateTime.fromString(answer, format).toFormat('yyyy-MM-dd');
      }
    }
  }

  protected fillRadioInput() {
    this.question.options.forEach((option) => {
      if (this.$store.state.answerPayload) {
        const payloadQuestion = this.$store.state.answerPayload.find((question: any) => this.question.uuid === question.question);

        if (payloadQuestion) {
          if (option.value === payloadQuestion.value) {
            return this.radioGroup = option;
          }
        } else if (option.value === this.question.answer.value) {
          return this.radioGroup = option;
        }
      } else if (option.value === this.question.answer.value) {
        return this.radioGroup = option;
      }
    });
  }

  protected checkForAnswer() {
    // TODO: CHECK DIT VOOR BESTAANDE QUESTIONS
    if (! this.question.answer) {
      return;
    }

    switch (this.question.type) {
      case 'number':
        this.fillStringInput();
        break;
      case 'textbox':
        this.fillStringInput();
        break;
      case 'textarea':
        this.fillStringInput();
        break;
      case 'year':
        this.fillStringInput();
        break;
      case 'date':
        this.fillDateInput();
        break;
      case 'radio':
        this.fillRadioInput();
        break;
      case 'checkbox':
        this.fillCheckboxInput();
        break;
      default:
    }
  }

  protected checkDamageAnswers(question: any) {
    Object.keys(this.damageAnswers).forEach((key) => {
      if (question.options) {
        question.options.forEach((option: any) => {
          option.questions.forEach((question: any) => {
            if (question.key === key) {
              this.fillDamageAnswers(question, this.damageAnswers[key], this.damageAnswersInstructions && this.damageAnswersInstructions[key] ? this.damageAnswersInstructions[key] : '');
            }
          });
        });
      }
    });

    Object.keys(this.damageAnswers).forEach((key) => {
      if (this.question.key === key) {
        this.fillDamageAnswers(question, this.damageAnswers[key], this.damageAnswersInstructions && this.damageAnswersInstructions[key] ? this.damageAnswersInstructions[key] : '');
      }
    });
  }

  protected fillDamageAnswers(question: any, answer: any, instruction = '') {
    const type = question.type;
    switch (type) {
      case 'number': return this.stringInput = answer;
      case 'textbox': return this.stringInput = answer;
      case 'textarea':
        this.stringInput = answer;
        if (instruction) {
          this.instruction = instruction;
        }
        return;
      case 'year': return this.stringInput = answer;
      case 'date':
        if (answer) {
          if (answer.length < 12) {
            return this.date = DateTime.fromString(answer, 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
          } if (answer.length === 0) {
            return this.date = null;
          }
          return this.date = DateTime.fromString(answer, 'yyyy-MM-dd hh:mm:ss').toFormat('yyyy-MM-dd');
        } return;
      case 'radio':
        question.options.forEach((option: any) => {
          if (option.value === answer) {
            return this.radioGroup = option;
          }
        });
        break;
      case 'checkbox':
        this.checkboxInput = this.question.answer.value;
        break;
      default:
    }
  }

  protected answerDateQuestion(value: any) {
    if (value === 'Invalid DateTime') {
      this.date = null;
      return;
    }
    const formattedValue = value.length > 0 ? `${value} 00:00:00` : '';
    this.answerQuestion(formattedValue);
  }

  protected createAnswerPayload(id: string, value: any) {
    const nestedQuestion = this.getNestedQuestionById(id);
    if (! nestedQuestion) {
      return;
    }

    this.$emit('createAnswerPayload', id, value, nestedQuestion);
  }

  protected getNestedQuestionById(id: string) {
    let nestedQuestion = null;
    this.question.options.forEach((option: any) => {
      if (! option && ! option.questions) {
        return;
      }

      const currentNestedQuestion = option.questions.find((currentQuestion: any) => currentQuestion.uuid === id);

      if (! currentNestedQuestion) {
        return;
      }

      nestedQuestion = currentNestedQuestion;
    });

    return nestedQuestion || null;
  }

  protected questionAnswered() {
    this.$emit('questionAnswered');
  }

  protected answerQuestionInstruction(value: string) {
    if (this.damage) {
      this.$emit('answeredDamageQuestionInstruction', [this.question.key, value]);
    }
  }

  protected answerQuestion(value: any) {
    if (this.historicalDamage) {
      this.$emit('answeredHistoricalDamageQuestion', [this.question.key, value]);
      return;
    }

    if (! this.hasAutosave) {
      this.$emit('createAnswerPayload', this.question.uuid, value);
      return;
    }

    if (this.damage) {
      this.$emit('answeredDamageQuestion', [this.question.key, value]);
      return;
    }

    this.$emit('questionAnswered');

    const payload: AnswerPayload = {
      value: (typeof value === 'object' && ! Array.isArray(value)) ? value.value : value,
      report: this.reportId,
      question: this.question.uuid,
    };

    new Answer()
      .create(payload)
      .then((response: any) => {
        this.$emit('appendReport');
      })
      .catch((error: AxiosError) => {
        console.log('error', error);
      });
  }

  protected updateDamageInstruction(keyAnswer: string[]) {
    if (! this.damage || ! this.damage.uuid) {
      return;
    }

    this.$emit('answeredDamageQuestionInstruction', keyAnswer);
  }

  protected updateDamage(keyAnswer: string[]) {
    if (! this.damage || ! this.damage.uuid) {
      return;
    }

    this.$emit('answeredDamageQuestion', keyAnswer);
  }

  protected updateHistoricalDamage(keyAnswer: string[]) {
    if (! this.historicalDamage || ! this.historicalDamage.uuid) {
      return;
    }

    this.$emit('answeredHistoricalDamageQuestion', keyAnswer);
  }

  // DATE
  protected formattedDate: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.date) {
      this.formattedDate = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.$root.$emit('dateFieldValid', isValidDate(this.formattedDate));
      this.dateErrorMessage = ! isValidDate(this.formattedDate) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.$root.$emit('dateFieldValid', isValidDate(value));
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
    this.answerDateQuestion(this.date);
  }

  protected handleClear() {
    this.dateErrorMessage = '';
    this.formattedDate = '';
    this.date = null;
    this.$root.$emit('dateFieldValid', isValidDate(''));
    this.answerDateQuestion('');
  }

  @Watch('date')
  protected dateChanged(date: string) {
    if (date && date.length) {
      this.dateFromDatePicker();
    }
  }

  @Watch('checkboxInput')
  public checkboxInputChanged(to: any, from: any) {
    this.checkboxInputChosenOptions = [];
    this.question.options.forEach((option) => {
      if (this.checkboxInput && this.checkboxInput.indexOf(option.uuid) > - 1) {
        if (this.checkboxInputChosenOptions.some((chosenOption: any) => chosenOption.uuid === option.id)) {
          return;
        }
        this.checkboxInputChosenOptions.push(option);
      }
    });
  }

  @Watch('question')
  public questionChanged(to: any, from: any) {
    this.radioGroup = null;
    this.initialize();
  }

  @Watch('damage')
  public damageChanged(to: any, from: any) {
    this.initialize();
  }

  @Watch('radioGroup')
  public radioGroupChanged(to: any, from: any) {
    //
  }

  protected showDelete(option: any) {
    if (! this.radioGroup || ! option) {
      return false;
    }

    return option.value === this.radioGroup.value;
  }
}

export interface AnswerPayload {
  value?: string;
  report?: string;
  question?: string;
}
