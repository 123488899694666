import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { showHeaderbasedOnStatus, Validations } from '@/support/Warning';
import ErrorHandler from '@/support/ErrorHandler';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { Repair } from '@/models/Repair';
import { Damage } from '@/models/Damage';
import { Options } from '@/components/mi-dialog/MiDialog';
import { OverruleReason } from '@/models/OverruleReason';
import { currency } from '@/support/String';
import DamagePanelRow from '@/components/damage-panel/DamagePanelRow.vue';
import DamageDialog from './DamageDialog/DamageDialog.vue';

@Component<DamagePanel>({
  components: {
    DamageDialog,
    DamagePanelRow,
  },
})
export default class DamagePanel extends Vue {
  @Prop()
  protected damages!: Damage[];

  @Prop()
  protected includes!: string[];

  @Prop()
  protected disabled!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  @Prop()
  protected visibility!: DamageVisibility;

  @Prop()
  protected panelsFromStorage!: Panel[];

  protected visible: DamageVisibility = {
    identicals: false,
    status: false,
    reject: false,
    approve: false,
    rejectReasons: false,
    validations: false,
    repairs: false,
    edit: false,
    media: false,
    editMedia: false,
    rejectImage: false,
    ambtshalve: false,
    editAmbtshalve: false,
    checkboxes: false,
    openAllPanels: true,
    anomalies: false,
  };

  protected panels: Panel[] | null = [];

  protected panelClone: Panel[] | null = [];

  protected hasPanelsOpen = false;

  protected isLoadingDamage = true;

  protected isLoading = false;

  protected editDamageDialog = false;

  protected approvalDialogOpen = false;

  protected isCreatingAmbtshalve = false;

  protected status = '';

  protected title = '';

  protected fileUrl = '';

  protected currentDamage: Damage | null = null;

  protected selectedDamage: Damage | null = null;

  protected currentDamageIndex: number | null = null;

  protected currentStatus = '';

  // ambtshalve
  protected ambtshalveAmount = '';

  protected isEditingAmbtshalve = false;

  protected ambtshalveReasons: OverruleReason[] = [];

  protected selectedAmbtshalveReasons: OverruleReason[] | string[] = [];

  protected selectedReason = '';

  protected selectedDamageRejectReasons: string[] = [];

  protected rejectExplanation = '';

  protected reasons = [];

  // exceptions
  protected damageExceptions: DamageExceptions[] = [
    {
      key: 'verkleuring',
      name: 'Verkleuring / vlekvorming',
    },
    {
      key: 'verfwerk',
      name: 'Bladderen van verfwerk',
    },
    {
      key: 'loslaten',
      name: 'Loslaten / onthechten verf- en kitwerk',
    },
    {
      key: 'veroudering',
      name: 'Veroudering / verwering',
    },
    {
      key: 'krimpscheur',
      name: 'Krimpscheur in materialen',
    },
    {
      key: 'overig',
      name: 'Overig',
    },
  ];

  public async mounted() {
    this.visible = { ...this.visible, ...this.visibility };
    if (this.damages) {
      this.isLoadingDamage = true;
      await this.calculateDamageTotals();
      if (this.visible.openAllPanels) {
        await this.setAllPanels();
      }
      await this.getRejectReasons();

      if (this.visible.ambtshalve) {
        await this.getAmbtshalveReasons();
      }

      this.isLoadingDamage = false;
    }
  }

  protected showStatus(damage: Damage) {
    return this.visible.status ? this.getStatusClass(damage) : '';
  }

  protected showIdentical(damage: Damage) {
    return this.visible.identicals ? this.getIdenticalClass(damage) : '';
  }

  protected getStatusClass(damage: Damage) {
    switch (damage.status) {
      case 'rejected':
        return 'damagePanel--declined';
      case 'accepted':
        return 'damagePanel--accepted';
      case 'changed':
        return 'damagePanel--changed';
      default:
        return '';
    }
  }

  protected getIdenticalClass(damage: Damage) {
    if (! damage.repairs || ! damage.answers.damageSituationNotRestored || ! damage.answers) {
      return '';
    }

    return (damage.answers.damageSituationNotRestored === 'Identiek, er is geen verschil waarneembaar') && damage.repairs.length > 0 ? 'identicalDamageWarning' : '';
  }

  protected async calculateDamageTotals() {
    let total = 0;
    this.damages.forEach((damage: Damage) => {
      if (! damage || ! damage.repairs) {
        return;
      }

      damage.repairs.forEach((repair: Repair) => {
        if (! repair || ! repair.total_incl_vat) {
          return;
        }

        total += repair.total_incl_vat;
      });

      damage.totalCost = total;
      total = 0;
    });
  }

  protected showHeaderError(validations: Validations) {
    return showHeaderbasedOnStatus(validations, 'error');
  }

  protected showHeaderWarning(validations: Validations) {
    return showHeaderbasedOnStatus(validations, 'warning');
  }

  protected openAll() {
    this.panels = [...Array(this.damages.length).keys()].map((_) => [true]);
    this.hasPanelsOpen = true;
  }

  // Reset the panel
  protected closeAll() {
    this.panels = [];
    this.hasPanelsOpen = false;
  }

  protected async setAllPanels() {
    if (! this.damages) {
      return;
    }

    if (this.panelsFromStorage) {
      this.panels = this.panelsFromStorage;
      return;
    }

    const panels: Panel[] = [];
    this.damages.forEach((damage: Damage) => {
      panels.push((damage.status === 'rejected' || damage.status === 'changed') || damage.internal_status === 'rejected' || damage.internal_status === 'changed' ? [true] : [false]);
    });

    this.panels = panels;
    this.panelClone = cloneDeep(panels);
  }

  protected handleChangedPanel(value: boolean, index: number) {
    if (this.panelClone) {
      this.$set(this.panelClone, index, value);
    }

    this.$emit('panelChanged', this.panelClone);
  }

  protected prefillInputs() {
    if (! this.currentDamage) {
      return;
    }

    if (this.currentDamage.reject_reason) {
      this.selectedReason = this.currentDamage.reject_reason.id;
    }

    if (this.currentDamage.reject_reasons) {
      this.selectedDamageRejectReasons = this.currentDamage.reject_reasons.map((reason) => reason.id || '');
    }
  }

  protected async getRejectReasons() {
    this.reasons = await new RejectReasonModel()
      .filter({ types: ['damage', 'report'] })
      .limit(100)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getAmbtshalveReasons() {
    await new OverruleReason()
      .sort('name')
      .all()
      .then((overruleReason: OverruleReason[]) => {
        this.ambtshalveReasons = overruleReason;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageRejectReason(damage: Damage, value: string, key: string) {
    if (! damage) {
      return;
    }

    const payload = {
      [key]: key === 'reject_reasons' ? damage.reject_reasons?.map((rejectReason: RejectReasonModel) => (rejectReason.id ? rejectReason.id : rejectReason)) : value,
    };

    new Damage(damage)
      .update(payload)
      .then(() => {
        if (key === 'reject_reasons') {
          this.rejectDamage(damage);
        } else {
          this.$emit('reloadDamages');
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected findDamageFromStore(damage: Damage) {
    return this.$store.state.damages.find((storeDamage: Damage) => storeDamage.uuid === damage.uuid);
  }

  protected closeAmbtshalveDialog() {
    this.isCreatingAmbtshalve = false;
    this.selectedDamage = null;
  }

  protected openAmbtshalveDialog(damage: Damage, isEditing = false) {
    this.isCreatingAmbtshalve = true;
    this.selectedDamage = damage;

    if (this.hasAmbtshalve(damage)) {
      this.selectedAmbtshalveReasons = (this.selectedDamage.overrule_reasons as OverruleReason[]).map((reason: OverruleReason) => reason.id) as string[];
      this.selectedDamage.overrule_payout = currency(this.selectedDamage.overrule_payout as string);
      this.isEditingAmbtshalve = isEditing;
    }
  }

  protected assignAmbtshalve() {
    const textField = this.$refs.ambtshalveAmount;
    const textArea = this.$refs.ambtshalveReasonComment;
    if (! this.selectedDamage || ! textField) {
      return;
    }

    this.isLoading = true;

    const parsedAmount = this.changeCommaToDot(((textField as any).$refs.input.value as string));
    const parsedComment = textArea ? ((textArea as any).$refs.input.value as string) : '';
    const payload: {[key: string]: any} = {
      overrule_reasons: this.selectedAmbtshalveReasons,
      overrule_payout: parsedAmount,
    };

    if (textArea) {
      payload.overrule_custom = parsedComment;
    }

    new Damage(this.selectedDamage)
      .update(payload)
      .then(() => {
        this.$emit('reloadDamages');
        this.closeAmbtshalveDialog();
        this.$store.dispatch('openDialog', this.dialogOptionsCreatedAmbtshalve);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected hasAmbtshalve(currentDamage: Damage) {
    return currentDamage.overrule_payout && currentDamage.overrule_payout.length || currentDamage.overrule_reasons && currentDamage.overrule_reasons.length;
  }

  protected hasOtherReasonSelected() {
    // id => Overige, nl.
    const otherId = '3198eb63-43a5-4c4a-a955-af91a65f9a8c';
    return (this.selectedAmbtshalveReasons as string[]).includes(otherId);
  }

  protected changeCommaToDot(value: string): string {
    value = (value as string).replace(/[.\s]/g, '');
    value = value.replace(/[,\s]/g, '.');

    return value;
  }

  protected get dialogOptionsCreatedAmbtshalve(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Ambtshalve is aangemaakt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Oke',
        },
      },
    };
  }

  protected rejectDamage(damage: Damage) {
    if (! damage || ! damage.uuid) {
      return;
    }

    this.currentStatus = 'rejected';
    this.selectedDamage = damage;
    this.updateDamage();
  }

  protected updateCurrentDamage(damage: Damage, status: string, index: number | null = null) {
    if (! damage || ! damage.uuid) {
      return;
    }

    this.currentStatus = status;
    this.selectedDamage = damage;

    if (index !== null && index >= 0) {
      this.currentDamageIndex = index;
    }

    this.updateDamage();
  }

  protected updateDamage() {
    if (! this.selectedDamage) {
      return;
    }

    let payload;

    if (this.currentStatus === 'accepted') {
      payload = {
        status: this.currentStatus,
      };
    } else {
      payload = {
        status: this.currentStatus,
        reject_comment: this.selectedDamage.reject_comment,
        reject_reasons: this.selectedDamage.reject_reasons?.map((rejectReason: RejectReasonModel) => (rejectReason.id ? rejectReason.id : rejectReason)),
      };
    }

    new Damage(this.selectedDamage)
      .update(payload)
      .then(() => {
        if (this.currentStatus === 'accepted') {
          if (this.panels && this.currentDamageIndex !== null && this.currentDamageIndex >= 0) {
            this.panels[this.currentDamageIndex] = [false];
          }
        }
        this.$emit('reloadDamages');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get dialogOptionsRejectDamage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze schade wilt afkeuren?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Afkeuren',
          color: 'warning',
          action: () => {
            this.updateDamage();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected get dialogOptionsAcceptDamage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze schade wilt goedkeuren?',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Goedkeuren',
          color: 'success',
          action: () => {
            this.updateDamage();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            //
          },
        },
      },
    };
  }

  protected closeDialog() {
    const textField = this.$refs.commentField;
    (textField as any).$refs.input.value = '';
    this.selectedReason = '';
    this.selectedDamageRejectReasons = [];
    this.approvalDialogOpen = false;
  }

  protected handleEditDialog(damage: Damage, index: number) {
    this.currentDamageIndex = index + 1;
    this.currentDamage = damage;
    this.editDamageDialog = true;
  }

  protected reloadDamages() {
    this.$emit('reloadDamages');
  }

  protected selectedDamages: string[] = [];

  protected emitSelectedDamages(item: {[key: string]: string}) {
    if (! item.selectedDamage) {
      this.selectedDamages = this.selectedDamages.filter((currentSelectedDamage: string) => currentSelectedDamage !== item.damage);
    } else {
      this.selectedDamages.push(item.damage);
    }

    this.$emit('emitSelectedDamages', this.selectedDamages);
  }

  @Watch('panels')
  protected panelsChanged() {
    if (! this.panels) {
      return;
    }

    if (this.damages.length === this.panels.length) {
      const panels = this.panels.filter((item) => item[0] === true);

      if (panels.length === this.damages.length) {
        this.hasPanelsOpen = true;
      } else {
        this.hasPanelsOpen = false;
      }
    }
  }
}

export interface DamageVisibility {
  identicals?: boolean;
  status?: boolean;
  rejectReasons?: boolean;
  reject?: boolean;
  approve?: boolean;
  edit?: boolean;
  validations?: boolean;
  repairs?: boolean;
  media?: boolean;
  editMedia?: boolean;
  manager?: boolean;
  ambtshalve?: boolean;
  editAmbtshalve?: boolean;
  rejectImage?: boolean;
  veldwerk?: boolean;
  internal?: boolean;
  checkboxes?: boolean;
  openAllPanels?: boolean;
  anomalies?: boolean;
}

interface Validation {
  [key: string]: boolean;
}

export interface Panel {
  [key: number]: boolean;
}

interface DamageExceptions {
 name: string;
 key: string;
}
