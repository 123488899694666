import { Answer } from '@/models/Answer';
import { Report } from '@/models/Report';
import { Question as QuestionModel } from '@/models/Question';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Question from '@/components/Questionnaire/Question.vue';
import { Damage } from '@/models/Damage';
import { ModefiedQuestion } from './Question';

@Component<Questionnaire>({
  name: 'questionnaire',
  components: {
    Question,
  },
})
export default class Questionnaire extends Vue {
  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: false })
  protected enableReadMode!: boolean;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop({ default: false })
  protected compact!: boolean;

  @Prop()
  protected questions!: ModefiedQuestion[];

  @Prop()
  protected answers!: Answer[] | {[key: string]: string};

  @Prop()
  protected report!: Report;

  @Prop()
  protected damage!: Damage;

  @Prop()
  protected hiddenQuestionKeys!: string[];

  protected toggleAllEditMode = true;

  protected isQuestionDisabled(question: QuestionModel): boolean {
    return (this.report.isVesNulmetingReport && question.key === 'ToelichtingKadastraalObject') || this.disabled;
  }

  protected reportAnswerSaved(answer: Answer) {
    this.$emit('reportAnswerSaved', answer);
  }

  protected reloadAnswers() {
    this.$emit('reloadAnswers');
  }

  protected reloadReportAnswers() {
    this.$emit('reloadReportAnswers');
  }

  protected reloadDamageAnswers() {
    this.$emit('reloadDamageAnswers');
  }

  protected damageAnswerSaved(answer: Answer, question: Question) {
    this.$emit('damageAnswerSaved', answer, question);
  }

  protected onClickInfo(question: QuestionModel): void {
    this.$emit('onClickInfo', question);
  }

  protected onClickMetaPgvLimit(question: QuestionModel): void {
    this.$emit('onClickMetaPgvLimit', question);
  }

  protected toggleEditMode() {
    this.toggleAllEditMode = ! this.toggleAllEditMode;
  }
}
