import { Model } from '@/models/Model';
import { Report } from '@/models/Report';
import { Organization } from '@/models/Organization';
import { LateReason } from '@/models/LateReason';

export class LateRequest extends Model {
  protected $name = 'LateRequest';

  protected $endpoint = '/late-requests';

  protected $fillable: string[] = [
    'status',
    'comment',
    'requested_deadline',
    'approved_deadline',
  ];

  public status?: string;

  public comment?: string;

  public original_deadline?: string;

  public requested_deadline?: string;

  public approved_deadline?: string;

  public created_at?: string;

  public updated_at?: string;

  public report?: Report;

  public organization?: Organization;

  public id?: string;

  public late_reason?: LateReason;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
