import { Media } from '@/models/Media';
import { Event } from '@/models/Event';
import { Model } from '@/models/Model';
import { Participant } from '@/models/Participant';
import { User } from '@/models/User';
import { Answer } from '@/models/Answer';

export class Meeting extends Model {
  protected $name = 'Meeting';

  protected $endpoint = '/meetings';

  protected $fillable: string[] = [];

  public id?: string;

  public user?: User;

  public updated_at?: string;

  public links?: any;

  public chime?: Chime;

  public event?: Event;

  public participants?: Participant[];

  public media?: Media[];

  public status?: MeetingStatus;

  public applicant?: Applicant;

  public submission?: Submission;

  public app?: MeetingAppInfo;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export enum MeetingIncludes {
  EVENT = 'event',
  PARTICIPANTS = 'participants',
  REPORT = 'report',
  RISK_PROFILE = 'risk_profile',
  MEDIA = 'media',
  META_MEETING = 'meta_meeting',
  APPLICANT = 'applicant',
  CHECKLIST = 'checklist',
  SUBMISSION = 'submission',
  SUBMISSION_DAMAGES = 'submission_damages',
  ANSWERS = 'answers',
  DATA = 'data',
}

export enum MeetingStatus {
  OPEN = 'open',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  SYNCED = 'synced',
  CANCELLED = 'cancelled',
}

export const meetingStatusLabels: { [key: string]: string } = {
  [MeetingStatus.OPEN]: 'Lopend',
  [MeetingStatus.PROCESSING]: "Foto's koppelen",
  [MeetingStatus.PROCESSED]: "Foto's verwerken",
  [MeetingStatus.SYNCED]: 'Opname afgerond',
  [MeetingStatus.CANCELLED]: 'Geannuleerd',
};

export const meetingStatusColor: { [key: string]: string } = {
  [MeetingStatus.OPEN]: 'green',
  [MeetingStatus.PROCESSING]: 'orange',
  [MeetingStatus.PROCESSED]: 'magenta',
  [MeetingStatus.SYNCED]: 'blue',
  [MeetingStatus.CANCELLED]: 'red',
};

export type Status = 'open' | 'processing' | 'processed' | 'synced' | 'canceled';

export interface Chime {
  id?: string;
  media_audio_host_url?: string;
  media_audio_fallback_url?: string;
  media_screen_data_url?: string;
  media_screen_sharing_url?: string;
  media_screen_viewing_url?: string;
  media_signaling_url?: string;
  media_turn_control_url?: string;
  media_region?: string;
  created_at?: string;
  updated_at?: string;
}

export interface Applicant {
  checklist: {
    items: ChecklistItem[],
    updated_at: string;
  }
}

export type ChecklistStatus = 'authorized' | 'denied' | 'restricted' | 'undetermined';
export type ChecklistKey = 'permission' | 'microphone' | 'camera' | 'notifications' | 'storage';

export interface ChecklistItem {
  description: string;
  key: ChecklistKey;
  label: string;
  status: ChecklistStatus;
}

export interface Submission {
  additional_request: string;
  code: string;
  created_at: string;
  id: string;
  instructions: null;
  is_syncing: null;
  media: Media[];
  status: string;
  submission_damages: SubmissionDamage[];
  answers: Answer[];
  submitted_at: string;
  type: string;
  updated_at: string;
}

export interface SubmissionDamage {
  additional_request: string;
  answers: Answer[];
  created_at: string;
  id: string;
  location: null;
  media: Media[];
  updated_at: string;
}

export interface MeetingAppInfo {
  login_code?: string | null;
  post_code?: string | null;
}
