export const styles: Styles = {
  primary: '#526880',
  'primary-light': '#8a9aac',
  'primary-dark': '#354454',
  secondary: '#a09b1b',
  'secondary-dark': '#837f16',
  white: '#FFFFFF',
  black: '#222222',
  'black-medium': '#3E3E3E',
  'black-light': 'rgba(62,62,62,0.6)',
  'black-ultra-light': 'rgba(62,62,62,0.2)',
  success: '#62DC4F',
  warning: '#F74949',
  alert: '#F9BD35',
  lines: '#f5f5f5',
  gray: '#E8EBEF',
  'gray-light': '#F6F9FC',
  link: '#777777',
  'text-dark': '#222222',
  'text-medium': 'rgba(62,62,62,0.8)',
  'text-light': 'rgba(74,87,105,0.6)',
  'text-ultra-light': 'rgba(74,87,105,0.4)',
  '$body-font-family': 'nudista-web',
};

interface Styles {
  [key: string]: string;
}
