import { Article } from '@/models/Article';
import { Model } from '@/models/Model';

export class Folder extends Model {
  protected $name = 'Folder';

  protected $endpoint = '/library/folders';

  protected $fillable: string[] = [];

  public id?: string;

  public title?: string;

  public folders?: any[];

  public articles?: any[];

  public children?: Article[] | Folder[];

  protected folder_icon?: string;

  protected folder_internal_type?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public get icon() {
    return icons[this.internal_type];
  }

  public set icon(icon) {
    this.folder_icon = icon;
  }

  public get internal_type() {
    return this.folder_internal_type || 'folders';
  }

  public set internal_type(type) {
    this.folder_internal_type = type;
  }

  public createChildren(item: any) {
    item.children = [];

    if (! item.folders || ! item.articles) {
      return;
    }

    const types = ['folders', 'articles'];
    types.forEach((type: string) => {
      if (! item[`${type}`]) {
        return;
      }

      item[`${type}`].forEach((child: any) => {
        let customChild;
        if (type === 'folders') {
          customChild = new Folder(child);
        }
        if (type === 'articles') {
          customChild = new Article(child);
        }

        if (customChild) {
          customChild.internal_type = type;
          customChild.icon = icons[type];
          item.children.push(customChild);
        }
      });
    });

    item.children.forEach((child: any) => {
      this.createChildren(child);
    });
  }
}

export const icons: {[key: string]: string} = {
  articles: 'article',
  folders: 'folder',
};
