var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-shrink tw-w-64 tw-flex-row tw-flex-no-wrap tw-items-end tw-justify-end tw-justify-items-end tw-justify-self-end tw-text-right"
    },
    [
      _vm.question.library_article_id
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "z-index": "99999" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "link",
                              on: { click: _vm.onClickInfo }
                            },
                            on
                          ),
                          [_vm._v("info")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2154150568
              )
            },
            [_c("span", [_vm._v("Open artikel")])]
          )
        : _vm._e(),
      _vm.question.meta && _vm.question.meta.pgv_limit
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", "z-index": "99999" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "link",
                              on: { click: _vm.onClickMetaPgvLimit }
                            },
                            on
                          ),
                          [_vm._v("insert_chart")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2856050181
              )
            },
            [_c("span", [_vm._v("Open conclusie assistent")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }