import { Component, Prop, Vue } from 'vue-property-decorator';
import { Damage } from '@/models/Damage';
import { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { Conclusion } from '../QuestionConclusionDialog';

@Component<QuestionConclusionOverwriteDialog>({
  components: {
    //
  },
})
export default class QuestionConclusionOverwriteDialog extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  @Prop()
  protected conclusion!: Conclusion;

  @Prop()
  protected reasons!: { label?: string, value?: string}[];

  @Prop()
  protected title!: string;

  @Prop()
  protected currentPayload!: QuestionConclusionOverwritePayload;

  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected payload: QuestionConclusionOverwritePayload = {
    reason: '',
    comment: '',
  };

  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    if (this.currentPayload) {
      this.payload = this.currentPayload;
    }
  }
  // #endregion

  // #region Class methods
  protected close() {
    this.$emit('input', false);
  }

  protected submit() {
    this.$emit('answerQuestion', this.payload);
    this.close();
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters

  protected get isSuccessConclusion(): boolean {
    return this.conclusion?.status === QuestionaireDialogConclusionTypeEnum.SUCCESS;
  }

  protected get isValidForm(): boolean {
    return this.payload?.comment?.length > 0 && this.payload?.reason.length > 0;
  }

  // #endregion

  // #region @Watchers
  // #endregion
}

export interface QuestionConclusionOverwritePayload {
  comment: string;
  reason: string;
}
