var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.media.mime_type === "video/mp4"
    ? _c("div", [
        _c(
          "video",
          {
            attrs: { width: "600", controls: "true", poster: _vm.media.thumb }
          },
          [
            _c("source", {
              attrs: { src: _vm.media.original, type: "video/mp4" }
            }),
            _vm._v(" Your browser does not support the video tag. ")
          ]
        )
      ])
    : _c("div", { key: "images-" + _vm.index, staticClass: "mediaContainer" }, [
        _c("div", { staticClass: "mediaContainer__actions" }, [
          _c("a", { attrs: { href: _vm.media.original, target: "_blank" } }, [
            _c(
              "span",
              { staticClass: "action" },
              [_c("v-icon", [_vm._v("remove_red_eye")])],
              1
            )
          ])
        ]),
        _c("img", { attrs: { src: _vm.media.thumb } })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }