import { Model } from '@/models/Model';

export class Location extends Model {
  protected $name = 'Locations';

  protected $endpoint = '/locations';

  protected $fillable: string[] = ['name'];

  public id?: string;

  public name?: string;

  public created_at?: string;

  public updated_at?: string;
}
