import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Damage } from '@/models/Damage';
import { Model as DataModel } from '@/models/Model';
import { Report as ReportModel, Media } from '@/models/Report';
import { RejectReason } from '@/models/RejectReason';
import { Repair } from '@/models/Repair';
import { Options } from '@/components/mi-dialog/MiDialog';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import draggable from 'vuedraggable';
import { File as FileModel } from '@/models/File';
import { parseWarning } from '@/support/Warning';

@Component<DamageDialog>({
  components: {
    draggable,
  },
})
export default class DamageDialog extends Vue {
  public $pageTitle = 'Schade aanpassen';

  protected mask: Mask = {
    time: '##:##',
  }

  @Prop({ default: '' })
  protected approval!: string;

  @Prop({ default: '' })
  protected type!: string;

  @Prop({ default: '' })
  protected itemTitle!: string;

  @Prop()
  protected damage?: Damage;

  @Prop()
  protected damageIndex?: number;

  @Prop({ default: false })
  protected isVES!: boolean;

  protected reportModel: ReportModel = new ReportModel();

  protected damageModel: Damage | null = new Damage();

  protected dialogTitle = 'Titel';

  protected selectedReason = '';

  protected activeTab = 'tab-report';

  protected userFilter: UserFilter = {};

  protected expertSearch = '';

  protected comment = '';

  protected rejectReasons = [];

  protected reportId = '';

  protected isLoading = false;

  protected isCreatingRepair = false;

  protected selectedRepair: Repair | null = null;

  protected repair: Repair = new Repair();

  protected isEditingImage = false;

  protected uploadQueue: File[] = [];

  protected postTimeOut: any | null = null;

  protected fileCounter = 0;

  protected currentDamageRepairs: Repair[] | null = null;

  protected currentDamageMedia: FileModel[] | null = null;

  protected isValidDate = true;

  protected selectedMedia: Media | null = null;

  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected uploaded = false;

  protected acceptedFileTypes: string[] = [
    'image/png',
    'image/jpeg',
    'image/svg+xml',
  ];

  protected parseTitle: object = {
    report: 'Rapport',
    damage: 'Schade',
    approved: 'goedkeuren',
    rejected: 'afkeuren',
  };

  protected vatTypes: NumberArray[] = [
    {
      name: 6,
      value: 6,
    },
    {
      name: 9,
      value: 9,
    },
  ];

  public mounted() {
    this.$root.$on('dateFieldValid', this.setValidDate);
    this.reportId = this.$route.params.id;
    this.getReport();
    this.setDialogContent();
    this.getRejectReasons();
    this.damageModel = this.damage || null;
    this.damageModel = new DataModel().dataToModel(this.damage, 'Damage');
    this.currentDamageMedia = (this.damage?.media as FileModel[]).sort(this.compare);
    this.currentDamageRepairs = (this.damage?.repairs as Repair[]).sort(this.compare);
    this.calculateDamageTotals();
  }

  private setValidDate(isValid: boolean) {
    this.isValidDate = isValid;
  }

  protected get dialogSubHeader() {
    return `${this.damageIndex ? `${this.damageIndex}.` : ''} ${this.damageModel && this.damageModel.name ? this.damageModel.name : ''}`;
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected prefillInputs() {
    if (this.type === 'report') {
      return;
    }

    if (this.damage?.reject_comment) {
      this.comment = this.damage.reject_comment;
    }

    if (this.damage?.reject_reason) {
      this.selectedReason = this.damage.reject_reason.id;
    }
  }

  private setDialogContent() {
    this.dialogTitle = `${(this.parseTitle as any)[this.type]} ${(this.parseTitle as any)[this.approval]}`;
  }

  protected close() {
    this.$emit('closeDialog', this.damage);
    this.$emit('input', false);
  }

  protected handleConfirm() {
    this.updateDamage();
  }

  protected closeEditingImage() {
    this.isEditingImage = false;
  }

  protected editMedia(media: Media) {
    this.selectedMedia = media;
    this.isEditingImage = true;
  }

  protected getRejectReasons() {
    new RejectReason()
      .filter({ type: this.type })
      .all()
      .then((response: any) => {
        let previousGroup = '';
        response.forEach((reason: any) => {
          if (previousGroup !== reason.group) {
            (this.rejectReasons as any).push({
              header: reason.group,
            });
            (this.rejectReasons as any).push(reason);
            previousGroup = reason.group;
            return;
          }
          (this.rejectReasons as any).push(reason);
          previousGroup = reason.group;
        });
        this.prefillInputs();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamage() {
    if (! this.damageModel) {
      return;
    }

    const payload = {
      is_changed: true,
    };

    new Damage(this.damageModel)
      .update(payload)
      .then(() => {
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageMediaOrder() {
    if (! this.currentDamageMedia) {
      return;
    }

    const fileIds: string[] = this.currentDamageMedia.map((file: FileModel) => file.uuid as string);

    new FileModel()
      .sortFiles(fileIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageRepairOrder() {
    if (! this.currentDamageRepairs) {
      return;
    }

    const repairIds: string[] = this.currentDamageRepairs.map((repair: Repair) => repair.uuid as string);

    new Repair()
      .sortRepairs(repairIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getReport() {
    new ReportModel()
      .include(['applicant', 'answers', 'damages'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = new DataModel().dataToModel(reportModel, 'Report');
        this.prefillInputs();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected deleteMedia(media: Media) {
    this.selectedMedia = media;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected postFiles() {
    if (! this.damageModel || ! this.uploadQueue.length) {
      return;
    }

    new Damage()
      .mediaEndpoint(this.damageModel)
      .create({ file: this.uploadQueue[0] })
      .then((response: AxiosResponse) => {
        const queueCopy = [...[], ...this.uploadQueue];
        queueCopy.shift();
        this.uploadQueue = queueCopy;

        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultiple(this.fileCounter);

        if (this.uploadQueue.length <= 0) {
          this.fileCounter = 0;
          this.getDamage((this.damageModel as any));
        } else {
          setTimeout(() => {
            this.fileCounter += 1;
            this.postFiles();
          }, 500);
        }
      })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });
  }

  protected postFile(file: File | string | string[], editing = false) {
    if (! this.damageModel) {
      return;
    }
    new Damage()
      .mediaEndpoint(this.damageModel)
      .create({ file })
      .then((response: AxiosResponse) => {
        if (editing) {
          this.isEditingImage = false;
          this.isLoading = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultiple(this.fileCounter);
            this.fileCounter += 1;
          }
        }

        if (! this.damageModel) {
          return;
        }
        this.getDamage(this.damageModel);
      })
      .catch((error: AxiosError) => {
        if (editing) {
          this.isEditingImage = false;
        } else {
          const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
          if (fileUploadComponent) {
            fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
          }
        }
      });
  }

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.postFiles();
    }, 20);
  }

  protected get uploadText() {
    if (! this.uploaded) {
      return 'Upload een bestand in:';
    }

    return 'Upload nog een bestand in:';
  }

  protected closeUploadingFile() {
    this.uploaded = false;
    this.isUploadingFile = false;
  }

  protected calculateDamageTotals() {
    if (! this.damageModel || ! this.damageModel.repairs) {
      return;
    }
    let total = 0;
    this.damageModel.repairs.forEach((repair: any) => {
      total += repair.total_incl_vat;
    });
    this.damageModel.totalCost = total;
    total = 0;
  }

  protected editRepair(repair: Repair) {
    this.repair = repair;
    this.isCreatingRepair = true;
  }

  protected deleteRepair(repair: Repair) {
    this.selectedRepair = repair;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteRepair);
  }

  protected closeCreatingRepair() {
    if (! this.damageModel) {
      return;
    }

    this.repair = new Repair();
    this.getDamage(this.damageModel);
    this.isCreatingRepair = false;
  }

  protected getDamage(damage: Damage) {
    this.isLoading = true;

    new Damage()
      .find(damage.uuid)
      .then((damage: Damage) => {
        this.damageModel = damage as Damage;
        this.currentDamageMedia = (damage.media as FileModel[]).sort(this.compare);
        this.currentDamageRepairs = (damage.repairs as Repair[]).sort(this.compare);
        this.calculateDamageTotals();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private compare(a: any, b: any) {
    let comparison = 0;

    if (a.sort_order === undefined || b.sort_order === undefined) {
      return 0;
    }

    if (a.sort_order > b.sort_order) {
      comparison = 1;
    } else if (a.sort_order < b.sort_order) {
      comparison = - 1;
    }
    return comparison;
  }

  protected get dialogOptionsDeleteRepair(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze reparatie wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.damageModel || ! this.selectedRepair) {
              return;
            }

            new Repair()
              .removeRepair((this.selectedRepair as any).uuid)
              .then((response: AxiosResponse) => {
                if (! this.damageModel) {
                  return;
                }
                this.getDamage(this.damageModel);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedRepair = null;
          },
        },
      },
    };
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.reportModel || ! this.selectedMedia) {
              return;
            }

            this.reportModel
              .deleteMediaEndpoint(this.selectedMedia.uuid ? this.selectedMedia.uuid : '')
              .then((response: AxiosResponse) => {
                if (! this.damageModel) {
                  return;
                }
                this.getDamage(this.damageModel);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedMedia = null;
          },
        },
      },
    };
  }

  protected get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  @Watch('expertSearch')
  protected productSearchChanged(query: string) {
    if (query && query.length) {
      this.userFilter.search = query;
    }
  }
}

export interface StringArray {
  [key: string]: string;
}

export interface NumberArray {
  [key: string]: number;
}

interface UserFilter {
  search?: string;
}

interface Mask {
  [key: string]: string;
}

interface Reason {
  label: string;
  key: string;
}
