import { Model } from '@/models/Model';

export class UserLog extends Model {
  protected $name = 'UserLog';

  protected $endpoint = '/user-logs';

  protected $fillable: string[] = [];

  public id?: string;

  public user?: string;

  public type?: string;

  public text?: string;

  public changed_from?: string;

  public changed_to?: string;

  public created_at?: string;

  public updated_at?: string;

  public links?: any;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
