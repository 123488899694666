import { Model } from '@/models/Model';

export class HourSpecification extends Model {
  protected $name = 'HourSpecification';

  protected $endpoint = '/hour-specifications';

  protected $fillable: string[] = [];

  public id?: string;

  public specification_number?: string;

  public hours_count?: string;

  public hours_sum?: string;

  public cost_total?: string;

  public invoice?: string;

  public created_at?: string;

  public updated_at?: string;

  public links?: any;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
