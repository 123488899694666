import Storage from '@/support/Storage';
import { Application } from '@/support/Application';

export const isServiceOrganization = () => applicationMode() === 'tcmg';

export const is10be = () => applicationMode() === '10be';

export const isProduction = () => Application.config('APP_ENV') === 'production';

export const isStaging = () => Application.config('APP_ENV') === 'staging';

export const isLocal = () => Application.config('APP_ENV') === 'local';

export const isAcceptance = () => Application.config('APP_ENV') === 'acceptatie';

export const applicationMode = () => {
  const mode = Storage.get('application_mode', Application.config('APP_APPLICATION_MODE') as string || null);
  return mode;
};

export const hasTimeModule = () => Application.config('APP_MODULE_TIME');
