import { Component, Vue, Prop, Model } from 'vue-property-decorator';

@Component<PageHeader>({})
export default class PageHeader extends Vue {
  @Prop({ default: '' })
  protected pageTitle!: string;

  @Prop({ default: '' })
  protected avatar!: string;

  @Model('breadcrumbs') breadcrumbs!: Object[];
}
