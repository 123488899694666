import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/registerServiceWorker';
import '@/filter';
import IdleVue from 'idle-vue';
import AModalPlugin from '@/plugins/AModal';
import { ATranslator } from '@/support/ATranslator';
import VueIntersect from 'vue-intersect-directive';

Vue.config.productionTip = false;

if (window.location.href.includes('tcmg.schadeexpertise-groningen.nl')) {
  window.location.href = 'https://img.schadeexpertise-groningen.nl';
}

if (window.location.href.includes('tcmg.test.schadeexpertise-groningen.nl')) {
  window.location.href = 'https://img.test.schadeexpertise-groningen.nl';
}

if (window.location.href.includes('tcmg.acceptatie.schadeexpertise-groningen.nl')) {
  window.location.href = 'https://img.acceptatie.schadeexpertise-groningen.nl';
}

if ((module as any).hot !== undefined) {
  require('@/setup/hot-reload');
}

require('@/setup/components');
require('@/setup/plugins');

// TRANSLATOR SET LOCALE
ATranslator.setI18n(i18n);

(Vue as any).$router = router;

Vue.use(IdleVue, {
  store,
  idleTime: 600000, // 10 minutes
  startAtIdle: false,
});

Vue.use(VueIntersect);

const plugins = {
  router,
  store,
  i18n,
};

const vue = new Vue({
  ...plugins,
  render: (h) => h(App),
});

Vue.use(AModalPlugin({ parent: vue, ...plugins }));

(window as any).App = vue;

vue.$mount('#app');
