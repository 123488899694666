import { Model } from '@/models/Model';

export class TimeProject extends Model {
  protected $name = 'TimeProject';

  protected $endpoint = '/time/projects';

  protected $fillable: string[] = [
    'name',
  ];

  public id?: string;

  public name?: string;
}
