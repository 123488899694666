import { QuestionBase } from '@/models/question-models/question-base';

export class YearQuestion extends QuestionBase<string> {
  controlType = 'year';

  type: string;

  constructor(options: any = {}) {
    super(options);
    this.type = options.type || '';
  }
}
