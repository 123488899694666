var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [
        _c("h2", [_vm._v("Periode filter")])
      ]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { staticClass: "item__list", attrs: { wrap: "", row: "" } },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "label", attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs4: "" } }, [
                              _vm._v("jaar")
                            ]),
                            _c("v-flex", { attrs: { xs4: "" } }, [
                              _vm._v("Periode")
                            ]),
                            _c("v-flex", { attrs: { xs4: "" } }, [
                              _vm._v("Maand")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._l(_vm.years, function(year, index) {
                      return _c(
                        "v-flex",
                        {
                          key: index,
                          staticClass: "list__item",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs4: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "item__link",
                                    on: {
                                      click: function($event) {
                                        _vm.setPeriod(
                                          _vm.parseYearFrom(year),
                                          _vm.parseYearTo(year)
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(year))]
                                )
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _vm._l(_vm.filters.q, function(q) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          key: year + "-" + q.name,
                                          staticClass: "item__link",
                                          on: {
                                            click: function($event) {
                                              _vm.setPeriod(
                                                _vm.parseQFrom(year, q),
                                                _vm.parseQTo(year, q)
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(q.name) + " ")]
                                      ),
                                      _vm._v(" , ")
                                    ]
                                  })
                                ],
                                2
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _vm._l(_vm.filters.month, function(month) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          key: year + "-" + month.name,
                                          staticClass: "item__link",
                                          on: {
                                            click: function($event) {
                                              _vm.setPeriod(
                                                _vm.parseMonthFrom(year, month),
                                                _vm.parseMonthTo(year, month)
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(month.name) + " ")]
                                      ),
                                      _vm._v(" , ")
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }