import { Model } from '@/models/Model';

export class HourType extends Model {
  protected $name = 'HourType';

  protected $endpoint = '/hour-types';

  protected $fillable: string[] = [
    'name',
  ];

  public id?: string;

	public name?: string;

	public data?: any;
}
