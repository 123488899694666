import { Model } from '@/models/Model';
import { Media } from '@/models/Media';
import { Polygon, Point } from '@/support/Polygon';

export class DamageRecognition extends Model {
  protected $name = 'DamageRecognition';

  protected $endpoint = '/ml/damage-recognition';

  protected $fillable: string[] = ['mask_image'];

  protected $primaryKey = 'id';

  public id!: string;

  public created_at?: string;

  public error_message?: string;

  public media?: Media;

  public ml_model_version?: string;

  public prediction?: string;

  public response_time_in_ms?: number;

  public status_code?: number;

  public polygons?: Polygon[];

  public createManualPolygon(attributes: any) {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/relationship/polygon`;

    return this.request('post', url, attributes);
  }

  public createMask(attributes: any): Promise<Mask> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/relationship/mask`;

    return this.request('post', url, attributes);
  }

  public updateMask(id: string, attributes: any): Promise<Mask> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/relationship/mask/${id}`;

    return this.request('patch', url, attributes);
  }
}

export interface Mask {
  id: string;
  created_at: string;
  mask_image?: string;
  points: Point[][];
  updated_at: string;
}
