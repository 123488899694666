const environments: Environment[] = [
  { // Production TCMG
    hostnames: [
      'img.schadeexpertise-groningen.nl',
      'tcmg.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Atabix] IMG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'production',

      APP_API: 'production-tcmg.api.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://tcmg.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.tcmg.app_',
      APP_APPLICATION_MODE: 'tcmg',

      APP_PUSHER_KEY: 'c87d6364b481494ab76e',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: false,
    },
  },
  { // Production NIVRE
    hostnames: [
      'nivre.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Atabix] Nivre',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'production',

      APP_API: 'production-nivre.api.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://nivre.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.nivre.app_',
      APP_APPLICATION_MODE: 'nivre',

      APP_PUSHER_KEY: 'c87d6364b481494ab76e',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: true,
    },
  },
  { // Production 10BE
    hostnames: [
      '10be.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Atabix] 10BE',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'production',

      APP_API: 'production-10be.api.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://10be.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.10be.app_',
      APP_APPLICATION_MODE: '10be',

      APP_PUSHER_KEY: 'c87d6364b481494ab76e',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: false,
    },
  },
  { // Production CED
    hostnames: [
      'ced.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Atabix] CED',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'production',

      APP_API: 'production-ced.api.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://ced.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.ced.app_',
      APP_APPLICATION_MODE: 'ced',

      APP_PUSHER_KEY: 'c87d6364b481494ab76e',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: true,
    },
  },
  { // Production DOG
    hostnames: [
      'dog.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Atabix] DOG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'production',

      APP_API: 'production-dog.api.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://dog.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.dog.app_',
      APP_APPLICATION_MODE: 'dog',

      APP_PUSHER_KEY: 'c87d6364b481494ab76e',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: false,
    },
  },
  { // Acceptatie TCMG
    hostnames: [
      'tcmg.acceptatie.schadeexpertise-groningen.nl',
      'img.acceptatie.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Acceptatie] IMG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'acceptatie',

      APP_API: 'tcmg.api.acceptatie.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://tcmg.acceptatie.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.tcmg.app_',
      APP_APPLICATION_MODE: 'tcmg',

      APP_PUSHER_KEY: 'b5a22e602f8bf3d204a2',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: true,
    },
  },
  { // Acceptatie 10BE
    hostnames: [
      '10be.acceptatie.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Acceptatie] 10BE',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'acceptatie',

      APP_API: '10be.api.acceptatie.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://10be.acceptatie.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.10be.app_',
      APP_APPLICATION_MODE: '10be',

      APP_PUSHER_KEY: 'b5a22e602f8bf3d204a2',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: false,
    },
  },
  { // Acceptatie Nivre
    hostnames: [
      'nivre.acceptatie.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Acceptatie] Nivre',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'acceptatie',

      APP_API: 'nivre.api.acceptatie.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://nivre.acceptatie.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.nivre.app_',
      APP_APPLICATION_MODE: 'nivre',

      APP_PUSHER_KEY: 'b5a22e602f8bf3d204a2',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: true,
    },
  },
  { // Acceptatie Dog
    hostnames: [
      'dog.acceptatie.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Acceptatie] DOG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'acceptatie',

      APP_API: 'dog.api.acceptatie.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://dog.acceptatie.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.dog.app_',
      APP_APPLICATION_MODE: 'dog',

      APP_PUSHER_KEY: 'b5a22e602f8bf3d204a2',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: false,
    },
  },
  { // Acceptatie CED
    hostnames: [
      'ced.acceptatie.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[Acceptatie] CED',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'production',
      APP_ENV: 'acceptatie',

      APP_API: 'ced.api.acceptatie.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://ced.acceptatie.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.ced.app_',
      APP_APPLICATION_MODE: 'ced',

      APP_PUSHER_KEY: 'b5a22e602f8bf3d204a2',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,
      APP_BUGSNAG_API_KEY: '',

      APP_MODULE_TIME: true,
    },
  },
  { // TEST TCMG
    hostnames: [
      'tcmg.test.schadeexpertise-groningen.nl',
      'img.test.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[TEST] IMG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'local',
      APP_ENV: 'staging',

      APP_API: 'tcmg.api.test.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://tcmg.test.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.tcmg.app_',
      APP_APPLICATION_MODE: 'tcmg',

      APP_PUSHER_KEY: '0cbeb0b10c7b90f4e38c',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: false,
    },
  },
  { // TEST Expert
    hostnames: [
      'expert.test.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[TEST] Expert',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'local',
      APP_ENV: 'staging',

      APP_API: 'expert.api.test.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://expert.test.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.expert.app_',
      APP_APPLICATION_MODE: 'nivre',

      APP_PUSHER_KEY: '0cbeb0b10c7b90f4e38c',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: true,
    },
  },
  { // TEST MSR
    hostnames: [
      'msr.test.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[TEST] Mijn Schade Regelen',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'local',
      APP_ENV: 'staging',

      APP_API: 'msr.api.test.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://msr.test.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.msr.app_',
      APP_APPLICATION_MODE: 'msr',

      APP_PUSHER_KEY: '0cbeb0b10c7b90f4e38c',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: false,
    },
  },
  { // DEMO TCMG
    hostnames: [
      'tcmg.demo.schadeexpertise-groningen.nl',
      'img.demo.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[DEMO] IMG',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'local',
      APP_ENV: 'demo',

      APP_API: 'tcmg.api.demo.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://tcmg.demo.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.tcmg.app_',
      APP_APPLICATION_MODE: 'tcmg',

      APP_PUSHER_KEY: '0cbeb0b10c7b90f4e38c',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: false,
    },
  },
  { // DEMO Expert
    hostnames: [
      'expert.demo.schadeexpertise-groningen.nl',
    ],
    config: {
      APP_TITLE: '[DEMO] Expert',
      APP_VERSION: '0.0.1',
      APP_BUGSNAG_RELEASE_STAGE: 'local',
      APP_ENV: 'demo',

      APP_API: 'expert.api.demo.schadeexpertise-groningen.nl',
      APP_API_HTTPS: true,
      APP_HOSTNAME: 'https://expert.demo.schadeexpertise-groningen.nl',
      APP_API_VERSION: '/v1',
      APP_LOCAL_STORAGE_TOKEN: 'com.atabix.tcmg.expert.app_',
      APP_APPLICATION_MODE: 'nivre',

      APP_PUSHER_KEY: '0cbeb0b10c7b90f4e38c',
      APP_PUSHER_CLUSTER: 'eu',
      APP_PUSHER_ENCRYPTED: true,

      APP_MODULE_TIME: true,
    },
  },
  { // Local
    hostnames: [
      'localhost',
    ],
    config: {
      APP_TITLE: process.env.VUE_APP_TITLE || '',
      APP_VERSION: process.env.VUE_APP_VERSION || '',
      APP_BUGSNAG_RELEASE_STAGE: process.env.VUE_APP_BUGSNAG_RELEASE_STAGE || '',
      APP_ENV: process.env.VUE_APP_ENV || '',

      APP_API: process.env.VUE_APP_API || '',
      APP_API_HTTPS: process.env.VUE_APP_API_HTTPS || '',
      APP_HOSTNAME: process.env.VUE_APP_HOSTNAME || '',
      APP_API_VERSION: process.env.VUE_APP_API_VERSION || '',
      APP_LOCAL_STORAGE_TOKEN: process.env.VUE_APP_LOCAL_STORAGE_TOKEN || '',
      APP_APPLICATION_MODE: process.env.VUE_APP_APPLICATION_MODE || '',

      APP_PUSHER_KEY: process.env.VUE_APP_PUSHER_KEY || '',
      APP_PUSHER_CLUSTER: process.env.VUE_APP_PUSHER_CLUSTER || '',
      APP_PUSHER_ENCRYPTED: process.env.VUE_APP_PUSHER_ENCRYPTED || '',

      APP_MODULE_TIME: process.env.VUE_APP_MODULE_TIME || '',
    },
  },
];

export default environments;

export interface Environment {
  hostnames: string[];
  config: {[key:string]: string|boolean|number};
}
