import _Vue, { PluginObject } from 'vue';
import { VueConstructor } from 'vue/types/umd';
import { ThisTypedComponentOptionsWithArrayProps } from 'vue/types/options';
import AModal, { AModalOptions } from '@/services/AModal';

const AModalPlugin = (
  options?: ThisTypedComponentOptionsWithArrayProps<Vue, object, object, object, never>,
): PluginObject<object> => {
  AModal.setup(options);

  return {
    install: (Vue: typeof _Vue, _: object = {}) => {
      if (! Vue.prototype.$showModal) {
        Vue.prototype.$showModal = (component: VueConstructor<Vue>, options?: AModalOptions | undefined) => AModal.show(component, options);
      }

      if (! Vue.prototype.$showModal) {
        Vue.prototype.$closeModal = (data: any) => {
          AModal.close(data);
        };
      }
    },
  };
};

export default AModalPlugin;
