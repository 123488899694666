import { Model } from '@/models/Model';

export class NAMHistories extends Model {
  protected $name = 'NAMHistories';

  protected $endpoint = '/nam-histories';

  protected $fillable: string[] = [];

  public id?: string;
}
