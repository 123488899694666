import { Model } from '@/models/Model';

export class Skill extends Model {
  protected $name = 'Skill';

  protected $endpoint = '/skills';

  protected $fillable: string[] = [];

  public name?: string;

  public id?: string;

  public color?: string;
}

export enum SkillTypes {
  Opnemersvariant = 'c9fa0393-004a-4e5c-901b-e6d9b51341b3',
  Regulie = '026aba33-d4a6-4743-b068-97949c031764',
  Aannemersvariant = '4fa4299a-5342-407d-bf6c-ff043479cc45',
  Special_Monument = '8f22668b-7b11-4038-b770-7cd89d322cb8',
  Special_MKB = '0ec58abe-de97-413a-a015-7f5b1184c759',
  Special_AGRO = '0fc2062e-c1ae-47f6-92d8-1cd7ac497d2b',
  Special_AOS_follow_up = '0eaa799d-97ff-4864-acec-4f58cce61dfe',
  Special_Kerken = '0365e498-cafb-4928-a5f3-c0790c4eac85',
  Special_Molen = 'fd286555-2da6-4843-bd5b-6c12e3f847a4',
  Special_Overig = 'bcc0c09f-1187-44a6-b880-9a60131b860f',
  Special_Schoolgebouw = 'a5055514-202e-4849-b559-8e5d0d8e4ddb',
  Special_Graf = 'f1b07b6e-effa-4837-b447-b452cc2381fa',
  Nader_Onderzoek = 'f1b07b6e-effa-4837-b447-b452cc2381fb',
  Special_Mestkelder = 'f1b07b6e-effa-4837-b447-b452cc2381fc',
  Heropname = '552ea1aa-84bb-4ce7-b34f-13529467d74d',
  Vervolgafspraak = '58ee3ac1-1f6b-4531-bb0f-151e42a12465',
  Aanvullend_onderzoek = '536c3253-8f2a-43f9-8569-212c98c06efd',
  Prio = '55760b85-6c4f-4d98-93c1-cb48e00dafef',
  Second_Opinion = '419291c6-7798-4fc8-8fc8-ed48ea4c17f9',
  Hoorzitting = '419291c6-7798-4fc8-8fc8-ed48ea4c18f9',
  Vastgoed = '419291c6-7798-4fc8-8fc8-ed48ea4c18e9',
  Opname_op_Afstand = 'ea882ed2-72a8-11ea-9b15-0679c6d9ea22',
  Special_Light_Monument = 'e0d3a9b8-9608-11ea-9b15-0679c6d9ea22',
  Zelfopname = '7ab44ced-a381-11ea-9b15-0679c6d9ea22',
  Special_Light_Agro = 'c6714f00-afdf-11ea-9b15-0679c6d9ea22',
  IMS = '178da275-291d-11eb-b142-0679c6d9ea22',
}
