import { Component, Vue, Prop, Ref, ProvideReactive } from 'vue-property-decorator';
import { File } from '@/models/File';
import { Report } from '@/models/Report';
import { Question } from '@/models/Question';
import { Damage } from '@/models/Damage';
import { ImageMatching, Match, UpdateImageMatchInformation, UpdateImageMatchInformationPayload } from '@/models/ImageMatching';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Rpc } from '@/models/Rpc';

@Component<ImageRecognitionDialog>({})
export default class ImageRecognitionDialog extends Vue {
  // #region Props
  @Prop({ required: true })
  protected currentDamageMedia!: File[];

  @Prop({ required: true })
  protected report!: Report;

  @Prop({ required: true })
  protected damageQuestions!: Question[];

  @Prop({ required: true })
  protected damage!: Damage;
  // #endregion

  // #region Refs
  @Ref()
  protected pdfIframe!: HTMLIFrameElement;
  // #endregion

  // #region Provides
  @ProvideReactive() // Injected at ImageRecognitionQuestion.ts
  protected isShowingImageRecognitionDialog = true;
  // #endregion

  // #region Class properties
  // Dialog question keys
  protected questionKeys = ['EBS', 'EBS_Organisatie', 'EBS_Dossiernummer', 'EBS_Schadenummer', 'EBS_Paginanummer', 'EBS_Hersteld', 'EBS_StatusVorigeMelding_GedeeltelijkHersteld'];

  protected selectedImage: File | null = null;

  protected isLoadingQuestions = false;

  protected isLoadingImageSuggestions = false;

  protected isLoadingHistoricalExtractions = false;

  protected foundImages: Match[] = [];

  protected selectedReportImage: Match | null = null;

  protected step: ImageRecognitionSteps = ImageRecognitionSteps.IMAGE_SELECT;

  protected historicalReportExtractions: HistoricalReportExtraction[] = [];

  // #endregion
  // #region Class methods
  protected close(): void {
    this.$emit('close');
  }

  protected damageAnswerSaved(damage: Damage): void {
    this.$emit('damageAnswerSaved', damage);
  }

  protected reloadDamageAnswers(): void {
    this.$emit('reloadDamageAnswers');
  }

  protected reloadReportAnswers(): void {
    this.$emit('reloadReportAnswers');
  }

  protected onClickExtractionInformation(): void {
    this.isLoadingHistoricalExtractions = true;
    this.step = ImageRecognitionSteps.EXTRACTION_INFORMATION;
    this.fetchHistoricalReportExtractions();
    this.isLoadingHistoricalExtractions = false;
  }

  protected onClickReportNoMatches(): void {
    this.selectedReportImage = null;
    this.updateImageMatchInformation();
    this.close();
  }

  protected onClickNavigateToImageSelect(): void {
    this.step = ImageRecognitionSteps.IMAGE_SELECT;
  }

  protected compareImage(index: number): void {
    if (this.isLoadingImageSuggestions) { return; }
    this.selectedImage = this.currentDamageMedia[index];
    this.selectedReportImage = null;
    this.suggestImages();
  }

  protected cancelCompareImage(): void {
    if (this.isLoadingImageSuggestions) { return; }
    this.selectedImage = null;
    this.selectedReportImage = null;
  }

  protected chooseReportImage(index: number): void {
    if (this.isLoadingImageSuggestions) { return; }
    this.selectedReportImage = this.foundImages[index];
  }

  protected cancelReportImage(index: number): void {
    if (this.isLoadingImageSuggestions) { return; }
    this.selectedReportImage = this.foundImages[index];
  }
  // #endregion

  // #region Async
  // ML API Calls
  protected async suggestImages(): Promise<void> {
    if (this.isLoadingImageSuggestions) { return; }

    this.isLoadingImageSuggestions = true;
    this.foundImages = [];
    try {
      const response = await new ImageMatching()
        .create({
          report: this.report.uuid,
          media: this.selectedImage?.uuid,
        });

      this.foundImages = response.matches;
    } catch (error: unknown) {
      ErrorHandler.network(error as AxiosError);
    } finally {
      this.isLoadingImageSuggestions = false;
    }
  }

  protected async postImageMatchInformation(payload: UpdateImageMatchInformation[]) {
    if (! this.damage.uuid) { return; }
    try {
      await new Damage()
        .updateImageMatchInformation(this.damage.uuid)
        .create(({ data: payload } as UpdateImageMatchInformationPayload));
    } catch (error) {
      //
    }
  }

  protected async fetchHistoricalReportExtractions(): Promise<void> {
    try {
      const payload = {
        signature: 'ml:check-historical-reports-extractions',
        body: {
          report: this.report.uuid,
        },
      };

      const response = await new Rpc()
        .rpcPost(payload, false);

      this.historicalReportExtractions = response.data;
    } catch (error) {
      //
    }
  }

  protected async onClickNavigateToReport(): Promise<void> {
    await this.updateImageMatchInformation();
    this.step = ImageRecognitionSteps.QUESTIONNAIRE;
  }

  protected async updateImageMatchInformation(): Promise<void> {
    const payload: UpdateImageMatchInformation[] = [];

    Object.entries(this.modelFeedbackPayload).map(([key, value]) => {
      payload.push({
        key: (key as UpdateImageMatchInformation['key']),
        value,
      });
    });

    await this.postImageMatchInformation(payload);
  }
  // #endregion

  // #region Getters & Setters
  protected get filteredDamageQuestions(): Question[] {
    return this.damageQuestions.filter((question) => this.questionKeys.some((key) => key === question.key));
  }

  protected get reportUrl(): string {
    return `${this.selectedReportImage?.historical_report_pdf}#page=${this.selectedReportImage?.page_number}` || '';
  }

  protected get answers(): Record<string, any> {
    return this.damage.answers;
  }

  protected get tableData(): HistoricalReportExtraction[] {
    return this.historicalReportExtractions || [];
  }

  protected get isMatches(): boolean {
    return !! this.foundImages.length;
  }

  protected get modelFeedbackPayload(): ModelFeedbackPayload {
    return {
      page_nr: this.selectedReportImage?.page_number || null,
      historical_report: this.selectedReportImage?.historical_report_id || null,
      historical_image: this.selectedReportImage?.historical_report_image || null,
      damage_image: this.selectedImage?.uuid || null,
    };
  }
  // #endregion
}

enum ImageRecognitionSteps {
  IMAGE_SELECT = 1,
  QUESTIONNAIRE = 2,
  EXTRACTION_INFORMATION = 3,
}

interface HistoricalReportExtraction {
  historical_report_name: string;
  extracted_images_count: number;
  name: string;
}

interface ModelFeedbackPayload {
  page_nr: null | undefined | number,
  historical_report: null | undefined | string,
  historical_image: null | undefined | string,
  damage_image: null | undefined | string,
}
