import { Model } from '@/models/Model';
import mondayClient from '@/support/MondayClient';
import { AxiosError } from 'axios';
import { isObject } from 'lodash';

export class Board extends Model {
  protected $name = 'Board';

  protected $endpoint = '/boards';

  protected $fillable: string[] = [];

  public id?: string;

  public groups?: MondayGroup[];

  public find(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}.json`;

    return this.request('get', url);
  }

  public request(method: string, url: string, payload: any = {}): Promise<any> {
    if (this.$includes.length) {
      payload.with = this.$includes;
    }

    const keys = Object.keys(this.filters);
    if (keys.length) {
      keys.forEach((key) => {
        const model: Model = (this.filters as any)[key];
        if (isObject(model) && typeof model.resolvePrimaryKey === 'function') {
          (this.filters as any)[key] = model.resolvePrimaryKey();
        }
      });

      payload.filters = this.filters;
    }

    if (this.$page !== null) {
      payload.page = this.$page;
    }

    if (this.$limit !== null) {
      payload.limit = this.$limit;
    }

    if (this.$sort.key) {
      payload.sort = this.$sort.order === 'DESC' ? `!${this.$sort.key}` : this.$sort.key;
    }

    return mondayClient(method, url, payload).then(
      (response: object) => Promise.resolve(this.responseToModel(response)),
      (error: AxiosError) => {
        if (error.response !== undefined && error.response.status === 401) {
          console.log('monday error');
        }

        return Promise.reject(error);
      },
    );
  }
}

export interface MondayGroup {
  board_id: number;
  color: string;
  id: string;
  title: string;
}
