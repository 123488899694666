import { Model } from '@/models/Model';
import { Permission } from '@/models/Permission';

export class Role extends Model {
  protected $name = 'Role';

  protected $endpoint = '/roles';

  protected $fillable: string[] = [];

  public id!: string;

  public name!: string;

  public permissions!: Permission[];

  public value?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
