import { DateTime } from 'luxon';

export const calcYearFilters = () => {
  const startedYear = 2018;
  let latestYear = Number(DateTime.local().toFormat('yyyy'));
  const years = [];

  // At the last 3 months of the year, the next year will show for dropdowns.
  if(Number(DateTime.local().toFormat('MM')) > 9) {
    latestYear++;
  }

  for (let year = startedYear; year <= latestYear; year ++) {
    years.push(`${year}`);
  }

  return years;
};

export const YearFilters = calcYearFilters();
