/* eslint-disable */
let lastHash: any = '';

const upToDate = function upToDate() {
  return lastHash.indexOf(__webpack_hash__) >= 0;
};

const clientEmitter = require('webpack/hot/emitter');

clientEmitter.on('webpackHotUpdate', (currentHash: string) => {
  const previousHash: string = lastHash;
  lastHash = currentHash;
  if (upToDate()) return;

  console.clear();
  console.log(
    `%c Hot Reloaded: ${previousHash} > ${currentHash}`,
    'color: #FF00FF',
  );
});
