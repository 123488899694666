import VueI18n from 'vue-i18n';

export class ATranslator {
  public static i18n: VueI18n | null = null;

  public static setI18n(i18n: VueI18n): void {
    ATranslator.i18n = i18n;
  }

  public static tc(key: string, choice?: number, values?: VueI18n.Values): string | undefined {
    /*
    * if i18n cannot find a translation key it will simply return the key
    * when we notice this behaviour we will return undefined
    */
    return ATranslator.i18n?.tc(key, choice, values) !== key ? ATranslator.i18n?.tc(key, choice, values) : undefined;
  }
}
