
































import { Question } from '@/models/Question';
import { Report } from '@/models/Report';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<QuestionBlock>({})
export default class QuestionBlock extends Vue {
  @Prop({ default: null })
  protected question!: Question;

  @Prop({ default: null })
  protected foundQuestion!: Question;

  @Prop({ default: null })
  protected report!: Report;

  protected mounted() {
    this.initialize();
  }

  protected async initialize(): Promise<void> {}

  protected async answerCurrentQuestion(question: Question, value: string) {
    this.$emit('answerQuestion', question, value);
  }
}
