import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { MapArea } from '@/models/MapArea';
import { debounce } from 'lodash';

@Component<MapAreaFilter>({})
export default class MapAreaFilter extends Vue {
  @Prop()
  protected area!: MapArea;

  protected areas: MapArea[] = [];

  protected searchInput = '';

  public searchDebounce: Function = this.handleSearch();

  protected selectedMapArea: MapArea | null = null;

  protected handleChange() {
    this.$emit('selectedArea', this.selectedMapArea);
    this.$emit('handleChange');
  }

  public async mounted() {
    this.areas = [];
    this.selectedMapArea = this.area;
    this.areas.push(this.area);
    await this.getMapAreas();
  }

  public async getMapAreas(): Promise<void> {
    this.areas = await new MapArea()
      .filter({
        type: 'other',
      })
      .filter('search', this.searchInput)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  handleSearch() {
    return debounce(() => {
      this.getMapAreas();
    }, 400);
  }
}
