import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { formatDate, dateErrorMessage, setFormattedDatePickerValue, isValidDate } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel } from '@/models/Report';
import { RejectReason } from '@/models/RejectReason';
import { Message } from '@/models/Message';
import { User } from '@/models/User';
import { debounce } from 'lodash';
import { Organization } from '@/models/Organization';
import { getStatusLabel } from '@/support/ReportStatus';
import { firstTimeRightBureau, FirstTimeRight } from '@/support/FirstTimeRight';
import { Application } from '@/support/Application';

@Component<ApprovalDialog>({
  filters: {
    dateFormat: (date: string) => {
      if (! date) {
        return '';
      }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
    getStatusLabel: (status: string) => {
      if (! status) {
        return '';
      }
      return getStatusLabel(status);
    },
  },
})
export default class ApprovalDialog extends Vue {
  protected actionDisabled = false;

  protected mask: Mask = {
    time: '##:##',
  };

  @Prop({ default: false })
  protected expertReject?: boolean;

  @Prop({ default: 'approved' })
  protected approval!: string;

  @Prop({ default: '' })
  protected status!: string;

  @Prop({ default: '' })
  protected title!: string;

  @Prop({ default: false })
  protected showComment?: boolean;

  @Prop({ default: false })
  protected showExpertSelect?: boolean;

  @Prop({ default: false })
  protected planning?: boolean;

  @Prop({ default: false })
  protected chooseOrganization?: boolean;

  @Prop({ default: '' })
  protected expert?: string;

  @Prop({ default: '' })
  protected secondExpert?: string;

  @Prop({ default: '' })
  protected organization?: string;

  @Prop({ default: '' })
  protected damageDescription?: string;

  @Prop({ default: '' })
  protected information?: string;

  @Prop({ default: '' })
  protected dateTime!: string;

  @Prop({ default: '' })
  protected dateField!: string;

  @Prop({ default: '' })
  protected role!: string;

  @Prop({ default: '' })
  protected default!: string;

  @Prop({ default: false })
  protected firstTimeRight!: boolean;

  @Prop()
  protected report?: ReportModel;

  protected rejectReasons = [];

  protected planningStatus = '';

  protected selectedReason = '';

  protected reason = '';

  protected comment = '';

  protected changeApprovedDate = false;

  protected planningByOrganization = false;

  protected selectedExpert = '';

  protected experts: User[] = [];

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected showCommentFieldRoles: any = {
    veldwerk: ['rejected'],
    manager: ['opname_in_progress', 'in_progress', 'approved', 'rejected'],
    preCheck: ['opname_in_progress', 'in_progress', 'rejected'],
    workDivision: ['opname_in_progress'],
  };

  protected isLoading = false;

  protected organizations: Organization[] = [];

  protected expertOrganizations: Organization[] = [];

  protected selectedOrganization = '';

  protected expertSearch = '';

  // date field
  protected isEditingDate = false;

  protected date: string | null = '';

  protected time: string | null = '';

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  protected clearPlannedAtDateFromTextField() {
    this.dateErrorMessage = '';
    this.date = '';
  }

  @Watch('date')
  protected dateChanged() {
    this.formatDateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingDateChanged() {
    if (! this.isEditingDate) {
      this.formatDateFromDatePicker();
    }
  }

  // first time right
  protected firstTimeRightValue: 'nothing_chosen' | boolean = this.isSchadebepalingGoedkeurenFtrFlow
    ? 'nothing_chosen'
    : true;

  protected firstTimeRightReasons: FirstTimeRight[] = firstTimeRightBureau;

  protected selectedFirstTimeRightReasons: string[] = [];

  protected firstTimeRightComment = '';

  protected get showCommentField() {
    let showField = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.showCommentFieldRoles) {
      if (this.role === key) {
        if (this.showCommentFieldRoles[key].indexOf(this.status) > - 1) {
          showField = true;
        }
      }
    }

    if (this.role === 'veldwerk' && this.approval === 'rejected') {
      return true;
    }

    if (this.showComment) {
      showField = true;
    }

    if (this.expertReject) {
      showField = true;
    }

    return showField;
  }

  protected get showCheckbox() {
    if (this.role === 'manager' && this.status === 'veldwerk_corrected') {
      return true;
    }

    return false;
  }

  protected get isVeldwerkRejected() {
    return this.role === 'veldwerk' && this.approval === 'rejected';
  }

  protected get isSmr() {
    if (this.report) {
      return this.report.status === 'smr_approved' || this.report.status === 'smr_closed';
    }
    return false;
  }

  protected get isSmrCreated() {
    if (this.report) {
      return this.report.status === 'smr_created' && this.$route.name === 'work-preparation.editor';
    }
    return false;
  }

  public mounted() {
    this.initialize();

    if (this.report && this.report.second_expert && this.report.second_expert.uuid) {
      this.selectedExpert = this.report.second_expert.uuid;
    }
  }

  protected initialize() {
    this.comment = this.default;
    this.getRejectReasons();

    if (this.planning) {
      this.getOrganizations();
    }

    if (this.chooseOrganization) {
      this.getExpertOrganizations();
    }

    if (this.showExpertSelect || this.planning) {
      this.actionDisabled = true;
      this.fetchExperts();
    }

    if (this.isVeldwerkRejected && ! this.isSmr) {
      this.actionDisabled = true;
    }

    if (this.report) {
      this.actionDisabled = false;
    }

    if (this.dateField) {
      this.actionDisabled = true;
    }
  }

  protected getRejectReasons() {
    new RejectReason()
      .filter({ type: 'report' })
      .all()
      .then((response: any) => {
        if (! response) {
          return;
        }

        let previousGroup = '';
        response.forEach((reason: any) => {
          if (previousGroup !== reason.group) {
            (this.rejectReasons as any).push({
              header: reason.group,
            });
            (this.rejectReasons as any).push(reason);
            previousGroup = reason.group;
            return;
          }
          (this.rejectReasons as any).push(reason);
          previousGroup = reason.group;
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.comment = '';
    this.reason = '';
    this.changeApprovedDate = false;

    this.$emit('closeDialog', this.report);
    this.$emit('input', false);
  }

  protected handleConfirm() {
    if (this.isSmrCreated) {
      this.$router.push(`/reports/${this.report ? this.report.uuid : ''}`);
      return;
    }
    this.updateReport();
  }

  protected updateReport() {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload: any = {};
    payload.status = this.planningStatus ? this.planningStatus : this.status;

    if (this.showCommentField) {
      payload.status_comment = this.comment;
    }

    if (this.firstTimeRight) {
      payload.stats_first_time_right_bureau = this.firstTimeRightValue; // ja of nee
      payload.stats_first_time_right_bureau_reasons = this.selectedFirstTimeRightReasons; // reden
      payload.stats_first_time_right_bureau_comment = this.firstTimeRightComment; // toelichting
    }

    if (this.showExpertSelect) {
      if (this.role === 'workDivision') {
        if (! this.selectedExpert) {
          // cant update with no expert selected
          this.isLoading = false;
          return;
        }

        payload.second_expert = this.selectedExpert;
      } else {
        payload.expert = this.selectedExpert;
      }
    }

    if (this.organization) {
      payload.organization = this.organization;
    }

    if (this.damageDescription) {
      if (! payload.answers) {
        payload.answers = {};
      }
      payload.answers.schadeOmschrijvingNaAanvraag = this.damageDescription;
    }

    if (this.expert) {
      payload.organization_expert = this.expert;
    }

    if (this.secondExpert) {
      payload.organization_second_expert = this.secondExpert;
    }

    if (this.dateTime && this.status !== 'planning_organization') {
      payload.planned_at = this.dateTime;
    }

    if (this.selectedOrganization && (this.planning || this.planningByOrganization)) {
      payload.organization = this.selectedOrganization;
    }

    if (this.selectedExpert && this.planning) {
      payload.organization_expert = this.selectedExpert;
    }

    if (this.changeApprovedDate && this.showCheckbox) {
      payload.update_approved_at = true;
    }

    if (this.dateField && this.date) {
      payload[this.dateField] = formatDate(`${this.date} ${this.time}`, 'yyyy-LL-dd HH:mm:ss');
    }

    this.report
      .update(payload)
      .then(() => {
        this.$emit('reportUpdated');
        this.close();

        if (this.$store.state.Auth.external_user) {
          if (Application.config('APP_ENV') === 'staging') {
            window.location.href = 'https://img.test.schadeexpertise-groningen.nl';
          } else if (Application.config('APP_ENV') === 'acceptatie') {
            window.location.href = 'https://img.acceptatie.schadeexpertise-groningen.nl';
          } else {
            window.location.href = 'https://img.schadeexpertise-groningen.nl';
          }
        } else {
          this.$router.push(`/reports/${this.report ? this.report.uuid : ''}`);
        }

        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected fetchExperts() {
    new User()
      .filter({ type: 'expert' })
      .all()
      .then((response: any) => {
        this.experts = response;
        if (this.report && this.report.second_expert) {
          this.experts.push(new User(this.report.second_expert));
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getExpertOrganizations() {
    new Organization()
      .getAllExperts()
      .then((response: Organization[]) => {
        this.expertOrganizations = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((response: Organization[]) => {
        this.organizations = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleSearch(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new User()
      .filter('search', query.target.value)
      .limit(2000)
      .all()
      .then((response: any) => {
        this.experts = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected createComment() {
    const payload = {
      text: this.comment,
    };

    new Message()
      .create(payload)
      .then((response: any) => {
        this.close();
        this.$router.push(`/reports/${this.report ? this.report.uuid : ''}`);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get isActionDisabled(): boolean {
    if (this.isSchadebepalingGoedkeurenFtrFlow && this.firstTimeRightValue === 'nothing_chosen') {
      return true;
    }

    return this.actionDisabled;
  }

  protected get isSchadebepalingGoedkeurenFtrFlow(): boolean {
    return this.firstTimeRight && this.approval === 'approved';
  }

  @Watch('selectedExpert')
  protected selectedExpertChanged() {
    if (this.selectedExpert) {
      this.actionDisabled = false;
    } else {
      this.actionDisabled = true;
    }
  }

  @Watch('selectedReason')
  protected selectedReasonChanged() {
    if (this.selectedReason) {
      this.actionDisabled = false;
    } else {
      this.actionDisabled = true;
    }
  }

  @Watch('date')
  @Watch('time')
  protected dateAndTimeFieldChanged() {
    if (this.time && this.date && this.dateField && this.time.length >= 4) {
      this.actionDisabled = false;
    } else {
      this.actionDisabled = true;
    }
  }

  @Watch('planningByOrganization')
  protected planningByOrganizationChanged() {
    if (this.planningByOrganization) {
      this.planningStatus = 'planning_organization';
    } else {
      this.planningStatus = 'planning';
    }
  }

  @Watch('firstTimeRightValue')
  protected firstTimeRightValueChanged() {
    if (! this.firstTimeRight) {
      return;
    }

    if (! this.firstTimeRightValue) {
      this.actionDisabled = true;
    } else {
      this.actionDisabled = false;
    }
  }

  @Watch('selectedFirstTimeRightReasons')
  protected selectedFirstTimeRightReasonsChanged() {
    if (! this.firstTimeRight) {
      return;
    }

    if (this.selectedFirstTimeRightReasons && this.selectedFirstTimeRightReasons.length) {
      this.actionDisabled = false;
    } else {
      this.actionDisabled = true;
    }
  }
}

export interface StringArray {
  [key: string]: string;
}

export interface NumberArray {
  [key: string]: number;
}

interface Mask {
  [key: string]: string;
}

interface Reason {
  label: string;
  key: string;
}
