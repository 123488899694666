export enum OpnameVariantEnum {
  OPNAME = 'opname',
  ZONDER_OPNAME = 'zonder_opname',
  ZELF_OPNAME = 'zelf_opname',
  OPNAME_OP_AFSTAND = 'opname_op_afstand',
  WOCO = 'woco',
  WOCO1 = 'woco1',
  WOCO2 = 'woco2',
  AANNEMERSVARIANT = 'aannemersvariant',
  EIGEN_AANNEMER = 'eigen_aannemer',
  ZIENSWIJZE = 'zienswijze',
  HEROPNAME = 'heropname',
  NULMETING = 'nulmeting',
  HOORZITTING = 'hoorzitting',
}

export const OpnameVariantLabels: {[key: string] :string} = {
  [OpnameVariantEnum.OPNAME]: 'Reguliere opname',
  [OpnameVariantEnum.ZONDER_OPNAME]: 'Zonder opname',
  [OpnameVariantEnum.ZELF_OPNAME]: 'Zelfopname',
  [OpnameVariantEnum.OPNAME_OP_AFSTAND]: 'Opname op afstand',
  [OpnameVariantEnum.WOCO]: 'WoCo',
  [OpnameVariantEnum.WOCO1]: 'WoCo v1',
  [OpnameVariantEnum.WOCO2]: 'WoCo v2',
  [OpnameVariantEnum.AANNEMERSVARIANT]: 'Aannemersvariant',
  [OpnameVariantEnum.EIGEN_AANNEMER]: 'Eigen aannemer',
  [OpnameVariantEnum.ZIENSWIJZE]: 'Nader Advies',
  [OpnameVariantEnum.HEROPNAME]: 'Heropname',
  [OpnameVariantEnum.NULMETING]: 'Nulmeting',
  [OpnameVariantEnum.HOORZITTING]: 'Hoorzitting',
};

export const ObjectionOpnameVariants: OpnameVariantEnum[] = [OpnameVariantEnum.OPNAME, OpnameVariantEnum.ZONDER_OPNAME, OpnameVariantEnum.HOORZITTING];
