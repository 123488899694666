import { Media } from '@/models/Media';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<MediaViewer>({})
export default class MediaViewer extends Vue {
  @Prop()
  protected media!: Media;

  @Prop()
  protected index!: number;

  public mounted() {
    //
  }
}
