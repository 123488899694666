import { cloneDeep, debounce } from 'lodash';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { showHeaderbasedOnStatus, Validations } from '@/support/Warning';
import ErrorHandler from '@/support/ErrorHandler';
import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { Damage } from '@/models/Damage';
import { OverruleReason } from '@/models/OverruleReason';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { concatenate } from '@/support/String';
import { AnomalyType, AnomalyTypeLabels } from '@/models/Anomaly';
import AnomalyCustomProperties from '@/components/AnomalyCustomProperties/AnomalyCustomProperties.vue';
import RepairTable from '@/components/RepairTable/RepairTable.vue';

@Component<DamagePanelRow>({
  components: {
    AnomalyCustomProperties,
    RepairTable,
  },
})
export default class DamagePanelRow extends Vue {
  @Prop()
  protected index!: number;

  @Prop()
  protected damages!: Damage[];

  @Prop()
  protected damageModel!: Damage;

  protected damage: Damage | null = null;

  protected hasDamage = false;

  @Prop()
  protected includes!: string[];

  @Prop()
  protected disabled!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  @Prop()
  protected rejectReasons!: RejectReasonModel[];

  @Prop()
  protected panel!: boolean[];

  protected panelClone: boolean[] | null = null;

  @Prop()
  protected visibility!: DamageVisibility;

  protected visible: DamageVisibility = {
    identicals: false,
    status: false,
    reject: false,
    approve: false,
    rejectReasons: false,
    validations: false,
    repairs: false,
    edit: false,
    media: false,
    editMedia: false,
    rejectImage: false,
    ambtshalve: false,
    editAmbtshalve: false,
    checkboxes: false,
    anomalies: false,
  };

  protected selectedDamages: string[] | null = null;

  protected isLoading = false;

  protected approvalDialogOpen = false;

  protected status = '';

  protected title = '';

  protected fileUrl = '';

  protected currentDamage: Damage | null = null;

  protected selectedDamage: Damage | null = null;

  protected currentDamageIndex: number | null = null;

  protected currentStatus = '';

  // ambtshalve
  protected ambtshalveAmount = '';

  protected isEditingAmbtshalve = false;

  protected ambtshalveReasons: OverruleReason[] = [];

  protected selectedAmbtshalveReasons: OverruleReason[] | string[] = [];

  protected selectedReason = '';

  protected selectedDamageRejectReasons: string[] = [];

  protected rejectExplanation = '';

  protected rejectDamageReasons: RejectDamageReasons[] = [];

  protected updateDamageRejectReasonDebounce: Function = debounce(this.updateDamageRejectReason, 500);

  // exceptions
  protected damageExceptions: DamageExceptions[] = [
    {
      key: 'verkleuring',
      name: 'Verkleuring / vlekvorming',
    },
    {
      key: 'verfwerk',
      name: 'Bladderen van verfwerk',
    },
    {
      key: 'loslaten',
      name: 'Loslaten / onthechten verf- en kitwerk',
    },
    {
      key: 'veroudering',
      name: 'Veroudering / verwering',
    },
    {
      key: 'krimpscheur',
      name: 'Krimpscheur in materialen',
    },
    {
      key: 'overig',
      name: 'Overig',
    },
  ];

  public mounted() {
    this.visible = { ...this.visible, ...this.visibility };
    this.damage = cloneDeep(this.damageModel);
    this.panelChanged();
  }

  protected parseRejectReasons() {
    let previousGroup = '';
    this.rejectReasons.forEach((reason: RejectReasonModel) => {
      if (reason.type === 'damage') {
        if (previousGroup !== reason.group) {
          this.rejectDamageReasons.push({
            header: reason.group,
          });
          this.rejectDamageReasons.push(reason);
          previousGroup = reason.group ? reason.group : '';
          return;
        }
        this.rejectDamageReasons.push(reason);
        previousGroup = reason.group;
      }
    });
  }

  // protected calculateDamageTotals() {
  //   if (! this.damage || ! this.damage.repairs) {
  //     return;
  //   }

  //   let total = 0;
  //   this.damage.repairs.forEach((repair) => {
  //     total += repair?.total_incl_vat || 0;
  //   });
  //   this.damage.totalCost = total;
  //   total = 0;
  // }

  protected showStatus(damage: Damage) {
    if (this.visible.internal) {
      return damage.internal_status ? this.getStatusClass(damage.internal_status) : '';
    }

    return this.visible.status && damage.status ? this.getStatusClass(damage.status) : '';
  }

  protected showIdentical(damage: Damage) {
    return this.visible.identicals ? this.getIdenticalClass(damage) : '';
  }

  protected getStatusClass(status: string) {
    switch (status) {
      case 'rejected':
        return 'damagePanel--declined';
      case 'accepted':
        return 'damagePanel--accepted';
      case 'changed':
        return 'damagePanel--changed';
      default:
        return '';
    }
  }

  protected getIdenticalClass(damage: Damage) {
    if (! damage.repairs || ! damage.answers.damageSituationNotRestored || ! damage.answers) {
      return '';
    }

    return (damage.answers.damageSituationNotRestored === 'Identiek, er is geen verschil waarneembaar') && damage.repairs.length > 0 ? 'identicalDamageWarning' : '';
  }

  protected showHeaderError(validations: Validations) {
    return showHeaderbasedOnStatus(validations, 'error');
  }

  protected showHeaderWarning(validations: Validations) {
    return showHeaderbasedOnStatus(validations, 'warning');
  }

  protected getAmbtshalveReasons() {
    new OverruleReason()
      .sort('name')
      .all()
      .then((overruleReason: OverruleReason[]) => {
        this.ambtshalveReasons = overruleReason;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateDamageRejectReason(damage: Damage, value: string, key: string) {
    if (! damage) {
      return;
    }

    const payload = {
      [key]: key === 'reject_reasons' ? damage.reject_reasons?.map((rejectReason: RejectReasonModel) => (rejectReason.id ? rejectReason.id : rejectReason)) : value,
    };

    new Damage(damage)
      .update(payload)
      .then(() => {
        if (key === 'reject_reasons') {
          this.rejectDamage(damage);
        } else {
          this.fetchDamage();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected findDamageFromStore(damage: Damage) {
    return this.$store.state.damages.find((storeDamage: Damage) => storeDamage.uuid === damage.uuid);
  }

  protected openAmbtshalveDialog(damage: Damage, isEditing = false) {
    this.$emit('openAmbtshalveDialog', damage, isEditing);
  }

  protected hasAmbtshalve(currentDamage: Damage) {
    return currentDamage.overrule_payout && currentDamage.overrule_payout.length || currentDamage.overrule_reasons && currentDamage.overrule_reasons.length;
  }

  protected rejectDamage(damage: Damage) {
    if (! damage || ! damage.uuid) {
      return;
    }

    this.currentStatus = 'rejected';
    this.selectedDamage = damage;
    this.updateDamage();
  }

  protected updateCurrentDamage(damage: Damage, status: string, index: number | null = null) {
    if (! damage || ! damage.uuid) {
      return;
    }

    this.currentStatus = status;
    this.selectedDamage = damage;

    if (index !== null && index >= 0) {
      this.currentDamageIndex = index;
    }

    this.updateDamage();
  }

  protected updateDamage() {
    if (! this.selectedDamage) {
      return;
    }

    let payload: {[key: string]: string | string[]};

    if (this.visible.internal) {
      payload = {
        internal_status: this.currentStatus,
        internal_reject_comment: this.selectedDamage.internal_reject_comment as string,
      };
    } else if (this.currentStatus === 'accepted') {
      payload = {
        status: this.currentStatus,
      };
    } else {
      payload = {
        status: this.currentStatus,
        reject_comment: this.selectedDamage.reject_comment || '',
      };

      if (this.selectedDamage.reject_reasons) {
        const mappedRejectReasons = this.selectedDamage.reject_reasons.map((rejectReason) => (rejectReason.id ? rejectReason.id : rejectReason));
        payload.reject_reasons = mappedRejectReasons as string[];
      }
    }

    new Damage(this.selectedDamage)
      .update(payload)
      .then((damage: Damage) => {
        this.fetchDamage();

        if (damage?.status === 'accepted') {
          this.panelClone = [false];
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleEditDialog(damage: Damage, index: number) {
    this.$emit('handleEditDialog', damage, index);
  }

  protected fetchIndex(index: number) {
    let count = 0;
    let notFoundCount = 0;
    this.damages.forEach((damage) => {
      if (count === index) { return; }
      if (damage.is_room_without_damage) {
        notFoundCount += 1;
      }
      count += 1;
    });

    return count - notFoundCount;
  }

  protected createDamageName(damage: Damage, index: number): string {
    const revised = damage.answers && damage.answers.MarkerenAlsHerzien === 'Ja' ? '(herzien)' : '';
    return `${Damage.createDamageName(damage, index, this.damages)} ${revised}`;
  }

  protected async fetchDamage() {
    this.damage = await this.damageModel
      .refactor()
      .include(this.includes)
      .find(this.damageModel.uuid);

    // this.calculateDamageTotals();
    this.parseRejectReasons();
    this.hasDamage = true;
  }

  protected emitSelectedDamages() {
    if (! this.damage) { return; }
    this.$emit('emitSelectedDamage', { selectedDamage: this.selectedDamage, damage: this.damage.uuid });
  }

  protected get AnomalyTypeLabels(): {[key: string] :string} {
    return AnomalyTypeLabels;
  }

  protected get AnomalyType(): {[key: string] :string} {
    return AnomalyType;
  }

  @Watch('panel')
  protected panelChanged() {
    this.panelClone = cloneDeep(this.panel);
  }

  @Watch('panelClone')
  protected panelCloneChanged() {
    this.$emit('panelCloneChanged', this.panelClone);

    if (! this.panelClone || ! this.panelClone[0] || this.hasDamage) {
      return;
    }

    this.fetchDamage();
  }
}

interface Validation {
  [key: string]: boolean;
}

export interface Panel {
  [key: number]: boolean;
}

interface DamageExceptions {
  key: string;
  name: string;
}

interface RejectDamageReasons {
  header?: string;
  name?: string;
  id?: string;
  category?: string;
  uuid?: string;
  type?: string;
  group?: string;
}
