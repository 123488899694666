import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { DefaultAnswer } from '@/models/DefaultAnswer';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QuestionBase } from '@/models/question-models/question-base';

@Component<DefaultAnswerDialog>({})
export default class DefaultAnswerDialog extends Vue {
  public $pageTitle = 'Standaard motivatie teksten';

  @Prop()
  protected question!: QuestionBase<any>;

  protected selectedDefaultAnswer = ''

  protected maxTruncateWords = 20;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  }

  protected close() {
    this.selectedDefaultAnswer = '';
    this.$emit('input', false);
  }

  protected get tableOptions(): Object {
    return {
      model: new DefaultAnswer().filter('question', this.question.uuid),
      name: {
        singular: 'Standaard tekst',
        plural: 'Standaard teksten',
      },
      headers: [
        {
          text: 'Onderwerp',
          value: 'title',

          width: '30%',
        },
        {
          text: 'inhoud',
          value: 'text',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'playlist_add',
          action: (dataTable: DataTable, defaultAnswer: DefaultAnswer) => {
            this.$emit('answerSelected', {
              text: defaultAnswer.text,
              instruction: defaultAnswer.instruction,
            });
          },
          tooltip: 'Voeg deze standaard motivatie tekst toe',
        },
      ],
      filter: [],
    };
  }
}
