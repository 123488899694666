import Vue from 'vue';
import { Application } from '@/support/Application';

export default () => {
  const _prop = {
    get: () => document.title,
    set: (title: string) => {
      const mainTitle = Application.config('APP_TITLE');

      document.title = `${mainTitle} - ${title}` || 'IMG';
    },
  };

  Object.defineProperty(Vue, 'pageTitle', _prop);
  Object.defineProperty(Vue.prototype, '$pageTitle', _prop);
};
