import axios, { AxiosError, AxiosResponse } from 'axios';
import { Application } from '@/support/Application';

let API = '';
const Qs = require('qs');

export default (method: string, url: string, payload: object) => {
  const headers = {
    'Content-type': 'application/x-www-form-urlencoded',
  };

  API = `${identifyHostname()}${Application.config('APP_MONDAY_API_VERSION')}`;
  (payload as any).api_key = Application.config('APP_MONDAY_API_KEY') ? Application.config('APP_MONDAY_API_KEY') : '';

  const client = axios.create({
    baseURL: API,
    headers,
    withCredentials: false,
    paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'brackets' }),
  });

  let hasFile = false;
  const keys = Object.keys(payload);

  if (! payload) {
    payload = {};
  }

  keys.forEach((property, index) => {
    if ((payload as any)[property] !== undefined && (payload as any)[property] !== null && (payload as any)[property].name !== undefined && (payload as any)[property].lastModified !== undefined && (payload as any)[property].size !== undefined) {
      hasFile = true;
    }
  });

  if (hasFile || method !== 'get') {
    payload = objectToFormdata(payload);
  }

  if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
    payload = {
      params: payload,
    };
  }

  return (client as any)
    [method](url, payload)
    .then((response: AxiosResponse) => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const identifyHostname = (): string => {
  const hostProtocol = isHttps() ? 'https' : 'http';

  return `${hostProtocol}://api.monday.com`;
};

export const isHttps = (): boolean => (! (Application.config('APP_API_HTTPS') && Application.config('APP_API_HTTPS') === 'false'));

export const objectToFormdata = (payload: any, form: any = null, namespace: any = null): FormData => {
  const fd: FormData = form || new FormData();
  let formKey: string | null = null;

  Object.keys(payload).forEach((key: string) => {
    if (namespace) {
      formKey = `${namespace}[${key}]`;
    } else {
      formKey = key;
    }
    if (typeof payload[key] === 'object' && ! (payload[key] instanceof File)) {
      objectToFormdata(payload[key], fd, key);
    } else {
      fd.append(formKey, payload[key]);
    }
  });
  return fd;
};
