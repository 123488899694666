var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "item__list", attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "label", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _vm._l(_vm.labels, function(label) {
                return [
                  _c(
                    "v-flex",
                    {
                      key: "label-" + label.slotName,
                      class: [label.width, label.headerClass]
                    },
                    [
                      _vm._t(
                        label.slotName + "-label",
                        [_vm._v(" " + _vm._s(label.title) + " ")],
                        null,
                        label
                      )
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._l(_vm.items, function(item, index) {
        return [
          !item.hidden
            ? _c(
                "v-flex",
                {
                  key: "item-" + index,
                  staticClass: "list__item",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm._l(_vm.labels, function(label) {
                        return [
                          _c(
                            "v-flex",
                            {
                              key: "content-" + label.slotName,
                              class: [label.width, label.class]
                            },
                            [_vm._t(label.slotName, null, null, item)],
                            2
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }