import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getStatusLabel } from '@/support/ReportStatus';

@Component<TreeView>({
  name: 'tree-view',
})
export default class TreeView extends Vue {
  @Prop()
  protected items!: any;

  @Prop({ default: false })
  protected first!: boolean;

  @Prop()
  protected columns!: TreeViewColumn[];

  @Prop()
  protected options!: TreeViewOptions;

  protected defaultOptions: TreeViewOptions = {
    showStatusLabels: false,
  }

  mounted() {
    this.defaultOptions = { ...this.defaultOptions, ...this.options };
  }

  protected parseItemName(name: string) {
    if (this.defaultOptions.showStatusLabels) {
      return getStatusLabel(name) !== 'Onbekend' ? getStatusLabel(name) : name;
    }

    return name;
  }

  protected parseData(input: any, column: TreeViewColumn) {
    if (column.transform) {
      return column.transform(input);
    }
    return input;
  }

  public isFunction(object: any) {
    return object && {}.toString.call(object) == '[object Function]';
  }
}

export interface TreeViewOptions {
  showStatusLabels?: boolean;
}

export interface TreeViewColumn {
  key: string;
  name: string;
  class?: string;
  style?: string;
  postfix?: string;
  suffix?: string;
  transform?: Function;
  visible?: boolean | Function;
  action?: Function;
  icon?: string;
}
