import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep, debounce } from 'lodash';
import { User } from '@/models/User';

@Component<ReportExpertFilter>({})
export default class ReportExpertFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop()
  protected placeholder!: string;

  @Prop()
  protected filters!: any;

  protected defaultFilters: any = {};

  @Prop()
  protected label!: string;

  @Prop()
  protected dmz!: string;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected experts: User[] = [];

  protected selectedExperts: User[] = [];

  public searchDebounce: Function = this.handleExpertSearch();

  protected expertSearchInput = '';

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedExperts);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedExperts = [];
    } else {
      this.selectedExperts = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.defaultFilters = { ...this.filters, ...this.defaultFilters };
    await this.getExperts();
  }

  protected async getExperts(): Promise<void> {
    this.experts = await new User()
      .filter('search', this.expertSearchInput)
      .dmz(this.dmz)
      .filter(this.defaultFilters)
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected handleExpertSearch() {
    return debounce(() => {
      this.getExperts();
    }, 400);
  }

  @Watch('expertSearchInput')
  public expertSearchInputChanged(val: any) {
    if (! this.expertSearchInput) {
      this.getExperts();
    }
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedExperts', this.experts.map((expert: User) => expert.uuid || ''));
    } else {
      this.$set(this, 'selectedExperts', []);
    }

    this.handleChange();
  }
}
