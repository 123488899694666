import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { ManagementInfoClassEnum } from '@/models/Report';

@Component<ManagementClassFilter>({})
export default class ManagementClassFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected hasNoTags!: boolean;

  public items: Item[] = [
    {
      name: 'Standaard',
      value: ManagementInfoClassEnum.STANDAARD,
    },
    {
      name: 'Standaard groot',
      value: ManagementInfoClassEnum.STANDAARD_GROOT,
    },
    {
      name: 'Nader advies',
      value: ManagementInfoClassEnum.NADER_ADVIES,
    },
    {
      name: 'Special',
      value: ManagementInfoClassEnum.SPECIAL,
    },
    {
      name: 'Bezwaar',
      value: ManagementInfoClassEnum.BEZWAAR,
    },
    {
      name: 'Aannemersvariant',
      value: ManagementInfoClassEnum.BEZWAAR,
    },
    {
      name: 'WoCo v1',
      value: ManagementInfoClassEnum.WOCOV1,
    },
    {
      name: 'WoCo v2',
      value: ManagementInfoClassEnum.WOCOV2,
    },
    {
      name: 'VES nulmeting',
      value: ManagementInfoClassEnum.VES,
    },
  ];

  protected selectedItems = this.value ? this.value : [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedItems);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedItems = [];
    } else {
      this.selectedItems = cloneDeep(this.value);
    }
  }

  public async mounted() {

  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedItems', this.items.map((item: Item) => item.value || ''));
    } else {
      this.$set(this, 'selectedItems', []);
    }

    this.handleChange();
  }

  public getItemName(value: string) {
    const foundItem = this.items.find((item) => item.value === value);

    return foundItem ? foundItem.name : '';
  }
}

interface Item {
  name: string;
  value: string;
}
