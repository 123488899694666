import { RejectReason as RejectReasonModel } from '@/models/RejectReason';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Damage } from '@/models/Damage';
import { Report } from '@/models/Report';

@Component<RejectReason>({})
export default class RejectReason extends Vue {
  @Prop()
  protected model!: Report | Damage;

  @Prop()
  protected rejectReasons!: RejectReasonModel[];

  @Prop({ default: true })
  protected showComment!: boolean;

  @Prop({ default: false })
  protected showCategories!: boolean;

  @Prop({ default: true })
  protected showReason!: boolean;

  protected getReasonName(reason: RejectReasonModel): string {
    if (! this.rejectReasons || reason.name) {
      return reason ? reason.name as string : '';
    }

    const foundReason = this.rejectReasons.find((rejectReason) => rejectReason.id == reason.id);

    return foundReason && foundReason.name ? foundReason.name : '';
  }

  protected get hasComment(): boolean {
    return !! this.model.reject_comment?.length;
  }

  protected get hasReason(): boolean {
    return !! this.model.reject_reasons?.length;
  }

  protected get hasReasonOrComment(): boolean {
    return (!! this.model.reject_reasons?.length) || (!! this.model.reject_comment?.length);
  }

  protected parseFTR(reason: RejectReasonModel) {
    return reason.ftr_class === 'ftr' ? 'Wel FTR' : 'Niet FTR';
  }
}
