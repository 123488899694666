import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { Event } from '@/models/Event';

@Component<WorkHoursDialog>({})
export default class WorkHoursDialog extends Vue {
  @Prop()
  protected event!: Event;

  protected isLoading = false;

  protected time = '';

  protected date: string | undefined = '';

  protected selectedEvent: Event | null = null;

  protected mounted() {
    this.selectedEvent = cloneDeep(this.event);

    this.date = this.isMorning ? this.selectedEvent.starts_at : this.selectedEvent.ends_at;

    if (this.date) {
      this.time = DateTime.fromSQL(this.date).toFormat('HH:mm');
    }
  }

  protected close() {
    this.$emit('input', false);
  }

  protected update() {
    if (! this.date) {
      return;
    }

    const formatedDate = DateTime.fromSQL(this.date);

    const date = DateTime.fromObject({
      day: formatedDate.day,
      month: formatedDate.month,
      year: formatedDate.year,
      hour: Number(DateTime.fromSQL(this.time).toFormat('HH')),
      minute: Number(DateTime.fromSQL(this.time).toFormat('mm')),
    }).toFormat('yyyy-LL-dd HH:mm:ss');

    this.$emit('workHourUpdated', date, this.selectedEvent);
    this.close();
  }

  // Getters
  protected get isMorning() {
    return this.selectedEvent && this.selectedEvent.daypart === 'morning';
  }

  protected get label(): string {
    return this.isMorning ? 'Start werkdag' : 'Einde werkdag';
  }

  protected get isDisabled(): boolean {
    if (! this.time) {
      return true;
    }

    return this.isMorning ? this.time > '13:00' : this.time < '13:00' || this.time > '23:00';
  }
}
