import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { ReportStatusMap } from '@/support/ReportStatus';

@Component<ReportStatusFilter>({})
export default class ReportStatusFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: false })
  protected items!: ReportStatusMap[];

  protected selected: ReportStatusMap[] = [];

  protected selectAll = false;

  protected onChange() {
    this.$emit('input', this.selected);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selected = [];
    } else {
      this.selected = cloneDeep(this.value);
    }
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selected', this.items.map((status: ReportStatusMap) => status.key || ''));
    } else {
      this.$set(this, 'selected', []);
    }

    this.onChange();
  }
}
