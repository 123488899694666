export const Theme = {
  primary: '#67A7C4',
  primaryDark: '#00335B',
  secondary: '#a09b1b',
  secondaryDark: '#837f16',
  black: '#3E3E3E',
  success: '#62DC4F',
  warning: '#F74949',
  alert: '#F9BD35',
  lines: '#f5f5f5',
  gray: '#E8EBEF',
  grayLight: '#F6F9FC',
  white: '#FFFFFF',
  textDark: '#3E3E3E',
};
