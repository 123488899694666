/**
 * const contour = new Contour(canvas)
 * const points = contour.points();
 * */

export class Contour {
  constructor(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    this.canvas = canvas;
    this.context = context;
    this.canvasWidth = canvas.width;
    this.canvasHeight = canvas.height;
    this.context = context;
    this.imageData = context.getImageData(0, 0, this.canvas.width, this.canvas.height).data;
  }

  protected imageData!: Uint8ClampedArray;

  protected canvas!: HTMLCanvasElement;

  protected canvasWidth!: number;

  protected canvasHeight!: number;

  protected context!: CanvasRenderingContext2D;

  protected contourDx = [1, 0, 1, 1, - 1, 0, - 1, 1, 0, 0, 0, 0, - 1, 0, - 1, NaN];

  protected contourDy = [0, - 1, 0, 0, 0, - 1, 0, 0, 1, - 1, 1, 1, 0, - 1, 0, NaN];

  protected defineWhite(imageData: Uint8ClampedArray, canvasWidth: number, x: number, y: number): boolean {
    const red = imageData[(y * canvasWidth + x) * 4];
    const green = imageData[(y * canvasWidth + x) * 4 + 1];
    const blue = imageData[(y * canvasWidth + x) * 4 + 2];

    return !! (red > 220 && green > 220 && blue > 220);
  }

  public points() {
    return this.contour(this.defineWhite);
  }

  public contour(grid: defineWhite): Point[] {
    const s = this.contourStart(grid); // starting point
    const c: Point[] = []; // contour polygon
    let x = s[0]; // current x position
    let y = s[1]; // current y position
    let dx = 0; // next x direction
    let dy = 0; // next y direction
    let pdx = NaN; // previous x direction
    let pdy = NaN; // previous y direction
    let i = 0;

    do {
      // determine marching squares index
      i = 0;
      if (grid(this.imageData, this.canvasWidth, x - 1, y - 1)) i += 1;
      if (grid(this.imageData, this.canvasWidth, x, y - 1)) i += 2;
      if (grid(this.imageData, this.canvasWidth, x - 1, y)) i += 4;
      if (grid(this.imageData, this.canvasWidth, x, y)) i += 8;

      // determine next direction
      if (i === 6) {
        dx = pdy === - 1 ? - 1 : 1;
        dy = 0;
      } else if (i === 9) {
        dx = 0;
        dy = pdx === 1 ? - 1 : 1;
      } else {
        dx = this.contourDx[i];
        dy = this.contourDy[i];
      }

      // update contour polygon
      if (dx != pdx && dy != pdy) {
        c.push({ x, y });
        pdx = dx;
        pdy = dy;
      }

      x += dx;
      y += dy;
    } while (s[0] != x || s[1] != y);

    return c;
  }

  protected contourStart(grid: defineWhite): number [] {
    let x = 0;
    let y = 0;

    // search for a starting point; begin at origin
    // and proceed along outward-expanding diagonals
    while (true) {
      if (grid(this.imageData, this.canvasWidth, x, y)) {
        return [x, y];
      }
      if (x === 0) {
        x = y + 1;
        y = 0;
      } else {
        x -= 1;
        y += 1;
      }
    }
  }
}

export interface Point {
  x: number;
  y: number;
}

type defineWhite = (imageData: Uint8ClampedArray, canvasWidth: number, x: number, y: number) => boolean;
