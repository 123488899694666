export const parseWarning = (status: string) => {
  switch (status) {
    // Reports:
    case 'naam_zaakbegeleider': return 'De naam van de Zaakbegeleider is niet ingevuld';
    case 'type_woning': return 'Het type van de Woning is niet ingevuld';
    case 'verschillende_tc_en_deskundige': return 'De Deskundige en de TC zijn hetzelfde';

    // Schades:
    case 'schade_omschrijving': return 'Schadeomschrijving veld is niet ingevuld';
    case 'causaal_verband_zonder_toelichting': return 'Toelichting causaliteit is niet ingevuld';
    case 'missing_repairs': return 'Er zijn geen reparaties opgevoerd';
    case 'nam_verergering_percentage': return 'Verergeringspercentage NAM/CVW ontbreekt';
    case 'vergering_percentage_zonder_toelichting': return 'Toelichting op verergeringspercentage NAM/CVW is niet ingevuld';
    case 'ebs_compleet': return 'De EBS vraag en/of subvragen zijn niet volledig ingevuld';
    case 'ebs_percentage': return 'De aangepaste toekenningspercentage vraag is met ja beantwoord maar de onderliggende vragen zijn nog niet beantwoord';
    case 'causaliteit_vraag': return 'Is de schade veroorzaakt en/of verergerd door mijnbouw';

    case 'single_first_radiator': return 'De post "de- en hermonteren radiatoren/-leidingen - >1e radiatoren" komt meer dan 1 keer voor';
    case 'single_first_boiler': return 'De post "de- en hermonteren CV-ketel (2x 0,5 dag)" komt meer dan 1 keer voor';
    case 'single_spot_repair_wall_tile': return 'Komt de post "spotrepair wandtegels 1e tegel" komt meer dan 1 keer ';
    case 'single_scaffold_including_rent_and_transport': return 'Komt de post "roisteiger incl. huur en transport" komt meer dan 1 keer voor';
    case 'either_first_radiator_or_first_boiler': return 'De post "de- en hermonteren radiatoren/-leidingen - >1e radiatoren" en  "de- en hermonteren CV-ketel (2x 0,5 dag)" zijn beide opgevoerd';
    case 'extra_radiator_requirements': return 'De post "de- en hermonteren radiatoren/-leidingen - > extra radiatoren" is gebruikt, maar er is er ook een post "de- en hermonteren radiatoren/-leidingen - >1e radiatoren" of "de- en hermonteren CV-kete" (2x 0,5 dag)" aanwezig';

    case 'soort_schade_vraag': return 'De vraag soort schade is niet ingevuld';
    case 'locatie_schade': return 'De vraag locatie schade is niet ingevuld';
    case 'locatie_schade_ruimte': return 'De vraag locatie schade of de vraag ruimte schade is niet ingevuld';
    case 'locatie_schade_plafond_of_vloer': return 'De vraag schade aan wand, plafond of vloer is niet ingevuld';
    case 'schade_wall_nummer': return 'De vraag welke wand heeft schade is niet ingevuld';
    case 'locatie_schade_dak_of_gevel': return 'De vraag schade aan dak of gevel is niet ingevuld';
    case 'locatie_schade_gevel_nummer': return 'De vraag welk gevelnummer is niet ingevuld';
    case 'locatie_schade_dak_nummer': return 'De vraag welk daknummer is niet ingevuld';
    case 'vat_particulier_9_of_21': return 'Bij de handmatige calculatieregel is niet 9% of 21% ingevuld';
    case 'locatie_betaald_parkeren': return 'Parkeerkosten zijn niet toegevoegd in een betaalde parkeerzone';
    case 'locatie_mogelijk_betaald_parkeren': return 'Parkeerkosten zijn toegevoegd in een mogelijke betaalde parkeerzone';
    case 'geen_dubbel_calculatie': return 'Er zijn dubbele reperaties toegevoegd voor deze schade';
    case 'hashtag_check_report': return 'Voor dit rapport zit er nog een placeholder (##) teken. Hierin moet nog de gecorrespondeerde waarde toegevoegd worden.';

    case 'dak_afwerking': return 'De vraag "Afwerking van het dak?" is niet ingevuld';
    case 'kenmerken_bijzonderheden': return 'De vraag "Zijn er overige (bijzondere) gebouwkenmerken?" is niet ingevuld';
    case 'kenmerken_meer_gevelconstructies': return 'De vraag "Zijn er meerdere gevelconstructies?" is niet ingevuld';
    case 'kenmerken_vloer2': return 'De vraag "Kenmerken bouwlaag 2" is niet ingevuld';
    case 'dak_materiaal': return 'De vraag "Materiaal van het dak?" is niet ingevuld';
    case 'kenmerken_bouwkundige_aanpassingen': return 'De vraag "Zijn er latere bouwkundige aanpassing(en) doorgevoerd?" is niet ingevuld';
    case 'kenmerken_meer_vloerconstructies': return 'De vraag "Zijn er opmerkingen over de div. vloerconstructies?" is niet ingevuld';
    case 'kenmerken_vloer3': return 'De vraag "Kenmerken bouwlaag 3" is niet ingevuld';
    case 'dak_vorm': return 'De vraag "Vorm van het dak?" is niet ingevuld';
    case 'kenmerken_dakkapel': return 'De vraag "Zijn er één of meerdere dakkapelen later bijgeplaatst?" is niet ingevuld';
    case 'kenmerken_schoorstenen': return 'De vraag "Hoeveel gemetselde schoorstenen zijn er aanwezig?" is niet ingevuld';
    case 'kenmerken_vloer_half': return 'De vraag "Kenmerken bouwlaag 0.5" is niet ingevuld';
    case 'fundering': return 'De vraag "Hoe is het gebouw gefundeerd?" is niet ingevuld';
    case 'kenmerken_dakterras': return 'De vraag "Dakterras aanwezig?" is niet ingevuld';
    case 'kenmerken_staat_van_onderhoud_dakbedekking': return 'De vraag "Staat van onderhoud van het dak/goten?" is niet ingevuld';
    case 'kenmerken_zetting': return 'De vraag "Is er sprake van zettingen/scheefstand? (visueel te bepalen)" is niet ingevuld';
    case 'fundering_vastgesteld': return 'De vraag "Is de fundering definitief vastgesteld of is er een aanname gedaan?" is niet ingevuld';
    case 'kenmerken_gevelopbouw': return 'De vraag "Kenmerken Gevelopbouw?" is niet ingevuld';
    case 'kenmerken_staat_van_onderhoud_metselwerk': return 'De vraag "Staat van onderhoud van het metselwerk/voegwerk?" is niet ingevuld';
    case 'nadere_omschrijving_gebouw': return 'De vraag "Nadere omschrijving gebouw" is niet ingevuld';
    case 'gebouw_of_werk': return 'De vraag "Is het een gebouw of een werk?" is niet ingevuld';
    case 'kenmerken_historische_gegevens': return 'De vraag "Zijn er relevante historische gegevens/calamiteiten?" is niet ingevuld';
    case 'kenmerken_staat_van_onderhoud_schilderwerk': return 'De vraag "Staat van onderhoud van het schilderwerk/houtconstructies?" is niet ingevuld';
    case 'soort_werk': return 'De vraag "Soort werk" is niet ingevuld';
    case 'kenmerken_aanbouw': return 'De vraag "Is er een aanbouw (niet zijnde een erker) aanwezig?" is niet ingevuld';
    case 'kenmerken_hoofd_draagconstructie': return 'De vraag "Kenmerken Hoofd draagconstructie?" is niet ingevuld';
    case 'kenmerken_vloer0': return 'De vraag "Kenmerken bouwlaag 0" is niet ingevuld';
    case 'gebouw_gebruik': return 'De vraag "Waarvoor wordt het gebouw gebruikt?" is niet ingevuld';
    case 'kenmerken_balkon': return 'De vraag "Balkon aanwezig?" is niet ingevuld';
    case 'kenmerken_kelder': return 'De vraag "Kelder/souterrain aanwezig?" is niet ingevuld';
    case 'kenmerken_vloer1': return 'De vraag "Kenmerken bouwlaag 1" is niet ingevuld'; // @deprecated
    case 'kenmerken_vloer1_www': return 'De vraag "Kenmerken bouwlaag 1" is niet ingevuld';
    case 'hashtag_check_damage': return 'Voor deze schade zit er nog een placeholder (##) teken. Hierin moet nog de gecorrespondeerde waarde toegevoegd worden.';
    case 'casuality_question_deviates': return 'Voor deze schade wijkt de casualiteits vraag af van de geadviseerde waarde.';
    case 'location_question': return 'De vraag "Waar wil je een schade opnemen?" is niet ingevuld.';
    case 'classification_question': return 'De vraag "Soort schade?" is niet ingevuld.';
    default: return `Onbekende waarschuwing: ${status}`;
  }
};

export const showHeaderbasedOnStatus = (validations: Validations, status: string) => {
  let showHeader = false;
  Object.keys(validations).forEach((key: string) => {
    if (validations[key] === status) {
      showHeader = true;
    }
  });

  return showHeader;
};

export interface Validations {
  [key: string]: string
}
