import { Experience } from '@/models/Experience';
import { AxiosResponse } from 'axios';
import { Department } from '@/models/Department';
import { Model } from '@/models/Model';
import { Organization } from '@/models/Organization';
import { Schedule } from '@/models/Schedule';
import { Skill } from '@/models/Skill';
import { parseScheduleToBusinessHours } from '@/support/String';
import { Token } from '@/support/Token';
import { endsWith } from 'lodash';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Role } from '@/models/Role';

export class User extends Model {
  protected $name = 'User';

  protected $endpoint = '/users';

  protected $fillable: string[] = [
    'first_name',
    'last_name',
    'report_name',
    'company_name',
    'business_email',
    'phone',
    'password',
    'email',
    'contact',
    'type',
    'supervisor',
    'is_active',
    'is_plannable',
    'is_planner',
    'is_opnemer',
    'is_realtime_plannable',
    'level',
    'urenadministratie_level',
    'department',
    'sms_preference',
    'has_quick_questions',
    'roles',
  ];

  public uuid?: string;

  public first_name?: string;

  public last_name?: string;

  public name?: string;

  public report_name?: string;

  public company_name?: string;

  public email?: string;

  public business_email?: string;

  public phone?: string;

  public password?: string;

  public username?: string;

  public token?: ModifiedToken;

  public type?: string;

  public level?: Level;

  public urenadministratie_level?: UrenadministratieLevel;

  public link?: string;

  public roles?: Role[];

  public organization?: Organization;

  public qr_image?: string;

  public signature?: File;

  public force_password_change?: boolean;

  public supervisor?: User;

  public is_active?: boolean;

  public is_plannable?: boolean;

  public is_planner?: boolean;

  public is_realtime_plannable?: boolean;

  public has_quick_questions?: boolean;

  public schedules?: Schedule[];

  public is_opnemer?: boolean;

  public is_blocked_by_planning?: boolean;

  public has_management_info?: boolean;

  public is_rockefeller?: boolean;

  public mfa_token?: boolean;

  public flamingo?: boolean;

  public hideInPlanning?: boolean;

  public skills?: Skill[];

  public default_tab?: string;

  public department?: string | Department;

  public supervisor_info?: SupervisorInfo;

  public experiences?: Experience[];

  public started_working_at?: string;

  public postcode?: string;

  public number?: string;

  public number_add?: string;

  public specification_statistics?: SpecificationStatistics;

  public getId() {
    return this.id ? this.id : this.uuid ? this.uuid : '';
  }

  public current(): Promise<any> {
    return this.filter('current', true)
      .include(['roles', 'contact', 'organization', 'skills', 'supervisor_info', 'department', 'permissions'])
      .first();
  }

  public isAuthenticated() {
    if (! Token.get('access')) {
      return false;
    }

    this.token = Token.get('access') as Token;

    return Token.get('access').length > 0;
  }

  public logout() {
    this.token = undefined;
    Token.delete(['access', 'refresh']);
  }

  public hasRole(roles: string | string[], includeAdmin = true) {
    if (this.type === undefined) {
      return false;
    }

    const userRoles = this.type;

    if (! Array.isArray(roles)) {
      roles = [roles];
    }

    let hasRole = false;
    if (roles.includes(this.type)) {
      hasRole = true;
    }

    return hasRole;
  }

  public hasSkill(skills: string | string[]) {
    if (this.skills === undefined) {
      return false;
    }

    if (! Array.isArray(skills)) {
      skills = [skills];
    }

    let hasRole = false;
    this.skills.forEach((skill: Skill) => {
      if (skill.id && skills.includes(skill.id)) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public storeMedia(attributes?: object, id?: string): Promise<any> {
    const url = `${this.$endpoint}/${id}`;

    return this.request('post', url, this.getPayload(attributes));
  }

  public getQR(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/get-mfa`;

    return this.request('get', url);
  }

  public get id() {
    return this.uuid;
  }

  public set id(id: string | undefined) {
    this.uuid = id;
  }

  // #region API Request Calls
  /**
  * ie.
  * public getItems() {
  *   return this.request('get', `${this.$endpoint}/items`);
  * }
  */
  public getAreas(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/areas`;

    return this.request('get', url);
  }

  public AddAreas(id: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/areas`;

    return this.request('put', url, payload);
  }

  public getPostalCodes(userId: string): Promise<any> {
    const url = `${this.$endpoint}/${userId}/relationships/postal-codes`;
    return this.request('get', url);
  }

  public addPostalCodes(userId: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${userId}/relationships/postal-codes`;
    return this.request('put', url, payload);
  }

  public getSkills(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('get', url);
  }

  public AddSkills(id: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('put', url, payload);
  }

  public getFixedSkills(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/fixed-skills`;

    return this.request('get', url);
  }

  public AddFixedSkills(id: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/fixed-skills`;

    return this.request('put', url, payload);
  }

  public getReportTypes(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/report-types`;

    return this.request('get', url);
  }

  public AddReportTypes(id: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/report-types`;

    return this.request('put', url, payload);
  }

  public deleteMfa(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/mfa`;

    return this.request('delete', url);
  }

  public getSchedules(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/schedules`;

    return this.request('get', url);
  }

  public storeQr(attributes?: object, id?: string): Promise<any> {
    const url = `${this.$endpoint}/${id}/set-mfa`;
    attributes = this.getPayload(attributes);

    return this.request('post', url, attributes);
  }

  public loginAs(userId: string) {
    const url = `${this.$endpoint}/${userId}?with=token`;

    return this.request('get', url);
  }

  public reserve(payload: object) {
    const url = '/planning/reserve';
    return this.request('post', url, payload);
  }

  public eventChangeLog() {
    const url = '/planning/event-changelog';
    return this.request('get', url);
  }

  public exportDeclarationOfIndependence() {
    const url = `/users/${this.uuid}/declaration-of-independence`;
    return this.request('get', url);
  }

  public importUser(attributes?: object): Promise<AxiosResponse> {
    const url = `${this.$endpoint}/import`;

    attributes = this.getPayload(attributes);

    return this.request('post', url, attributes, false);
  }
  // #endregion

  // #region Permissions
  /**
  */

  public can(permission: PermissionSlug): boolean {
    let can = false;
    if (! this.roles) {
      return can;
    }

    this.roles.forEach((role) => {
      if (role.permissions.some((rolePermission) => rolePermission.slug === permission)) {
        can = true;
      }
    });

    return can;
  }

  public canAny(permissions: PermissionSlug[]): boolean {
    let can = false;
    if (! this.roles) {
      return can;
    }

    this.roles.forEach((role) => {
      permissions.forEach((permissionSlug) => {
        if (role.permissions.some((rolePermission) => rolePermission.slug === permissionSlug)) {
          can = true;
        }
      });
    });

    return can;
  }
  // #endregion

  public parseScheduleToBusinessHours(): BusinessHoursInput[] {
    return parseScheduleToBusinessHours(this.schedules || []);
  }

  public parseToCalendarUser():CalendarUser {
    return {
      id: this.uuid || '',
      name: this.name || '',
      level: this.level || '',
      events: [],
      businessHours: {},
      organization: '',
      schedules: this.schedules || [],
      skills: this.skills || [],
      isOpnemer: this.is_opnemer || false,
    };
  }

  public parseToResourceUser():ResourceUser {
    return {
      id: this.uuid || '',
      title: this.name || '',
      businessHours: [],
    };
  }

  /**
   * Checks whether the user has an atabix user.
   */
  public get isAtabix(): boolean {
    return !! this.flamingo;
  }

  public get isSimpleUser() {
    return this.hasRole(['simple', 'simple_plus']);
  }

  public get hasBearerToken() {
    return !! this.token?.bearer?.length;
  }

  public static get modifications() {
    return [
      {
        name: 'Template',
        value: 'template',
      },
      {
        name: 'Beschikbaarheid',
        value: 'availability',
      },
      {
        name: 'Afspraken limiet',
        value: 'limit',
      },
    ];
  }
}

export const userTypes: UserType[] = [
  { key: 'tcmg', name: 'IMG' },
  { key: 'serviceloket', name: 'Serviceloket' },
  { key: 'werkvoorbereiding', name: 'Werkvoorbereiding' },
  { key: 'planning', name: 'Planning' },
  { key: 'case_mediator', name: 'Zaakbegeleider' },
  { key: 'damage_controller', name: 'Schadebepaling' },
  { key: 'damage_handler', name: 'Schadeafhandeling' },
  { key: 'appeals_committee', name: 'Bezwaarcommissie' },
  { key: 'expert', name: 'Deskundige' },
  { key: 'pre_controller', name: 'Voorcontroleur' },
  { key: 'smr', name: 'Stuwmeerregeling' },
  { key: 'simple', name: 'Opnemer' },
  { key: 'simple_plus', name: 'Aannemer' },
  { key: 'manager', name: 'Manager' },
  { key: 'helpdesk_tcmg', name: 'Helpdesk' },
  { key: 'admin', name: 'Beheerder' },
];

export const userUrenadministratieLevelLabels: {[key: string] :string} = {
  schadeopnemer: 'Schadeopnemer',
  proces_expert: 'Procesexpert',
  junior_deskundige: 'Junior Deskundige',
  medior_deskundige: 'Medior Deskundige',
  senior_deskundige: 'Senior Deskundige',
  special_deskundige: 'Special Deskundige',
  project_coordinator: 'Project Coordinator',
};

export const userReportTabs: ReportTab[] = [
  {
    name: 'Communicatie',
    value: 'communicatie',
  },
  {
    name: 'Planning',
    value: 'planning',
  },
  {
    name: 'Urenadministratie',
    value: 'urenadministratie',
  },
  {
    name: 'Schades',
    value: 'schades',
  },
  {
    name: 'Historische schades',
    value: 'historische-schades',
  },
  {
    name: 'Bijlagen',
    value: 'bijlagen',
  },
  {
    name: 'Aanzichtfoto\'s',
    value: 'aanzichtfotos',
  },
  {
    name: 'Plattegronden',
    value: 'plattegronden',
  },
  {
    name: 'Management Info',
    value: 'management-info',
  },
  {
    name: 'Schadebedrag',
    value: 'schadebedrag',
  },
  {
    name: 'Synchronisatie',
    value: 'synchronisatie',
  },
  {
    name: 'Vragen overzicht',
    value: 'vragenoverzicht',
  },
];

export const excludeUserTypes = (key: string | string[]): UserType[] => {
  let foundUserTypes: UserType[] = [];

  if (Array.isArray(key)) {
    foundUserTypes = [...userTypes, ...[]];
    (key as string[]).forEach((currentKey: string) => {
      foundUserTypes = foundUserTypes.filter((userType: UserType) => userType.key !== currentKey);
    });
  } else {
    foundUserTypes = userTypes.filter((userType: UserType) => userType.key !== key);
  }

  return foundUserTypes;
};

export const includeUserTypes = (key: string | string[]): UserType[] => {
  const foundUserTypes: UserType[] = [];

  if (Array.isArray(key)) {
    (key as string[]).forEach((currentKey: string) => {
      const userType = searchUserType(currentKey);
      if (userType) {
        foundUserTypes.push(userType);
      }
    });
  } else {
    foundUserTypes.push(searchUserType(key) as UserType);
  }

  return foundUserTypes;
};

export const getUserTypeName = (key: string): string => getUserTypeAttribute(key, 'name') as string;

const getUserTypeAttribute = (key: string, attribute: string) => {
  const userTypeObject = searchUserType(key);

  return (userTypeObject as UserType)[attribute];
};

const searchUserType = (key: string) => {
  const userType = userTypes.find((userType: UserType) => userType.key === key);

  return userType;
};

const WVRoles = ['tcmg', 'werkvoorbereiding', 'manager', 'admin'];
const PlanningRoles = ['tcmg', 'planning', 'manager', 'admin'];
const SimpleRoles = ['simple', 'simple_plus', 'manager', 'admin'];
const DeskundigeRoles = ['expert', 'pre_controller', 'manager', 'admin'];
const PreControllerRoles = ['pre_controller', 'manager', 'admin'];
const VeldwerkRoles = ['damage_handler', 'damage_controller', 'smr', 'manager', 'admin'];
const DamageControllerRoles = ['damage_controller', 'smr', 'manager', 'admin'];
const DamageHandlerRoles = ['damage_handler', 'smr', 'manager', 'admin'];
const CaseMediatorRoles = ['case_mediator', 'manager', 'admin'];
const ObjectionRoles = ['appeals_committee', 'manager', 'admin'];
const ServiceLoketRoles = ['serviceloket', 'manager', 'admin'];
const StuwmeerRoles = ['smr', 'manager', 'admin'];
const ManagerRoles = ['manager', 'admin', 'helpdesk_tcmg'];
const AdminRoles = ['admin'];
const HelpdeskTCMG = ['helpdesk_tcmg'];

export const userRoles: UserRoles = {
  WVRoles,
  PlanningRoles,
  SimpleRoles,
  DeskundigeRoles,
  PreControllerRoles,
  VeldwerkRoles,
  DamageControllerRoles,
  DamageHandlerRoles,
  CaseMediatorRoles,
  ObjectionRoles,
  ServiceLoketRoles,
  StuwmeerRoles,
  ManagerRoles,
  AdminRoles,
  HelpdeskTCMG,
};

export interface UserType {
  [key: string]: string | string[];
  key: string;
  name: string;
}

export interface CalendarUser {
  id: string;
  name: string;
  level: string;
  events: any[];
  businessHours: {[key:string]: any},
  organization: string,
  schedules: Schedule[],
  skills: Skill[]
  isOpnemer: boolean;
}

export interface ResourceUser {
  id: string;
  title: string;
  businessHours: {[key:string]: any},
}

export interface BusinessHoursInput {
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
}

export interface ReportTab {
  name: string;
  value: string;
}

export interface SupervisorInfo {
  is_supervisor?: boolean;
}

export interface UserImportResponse {
  created: string[];
  updated: string[];
  failed: string[];
}

export interface UserRoles {
  WVRoles: string[];
  PlanningRoles: string[];
  SimpleRoles: string[];
  DeskundigeRoles: string[];
  PreControllerRoles: string[];
  VeldwerkRoles: string[];
  DamageControllerRoles: string[];
  DamageHandlerRoles: string[];
  CaseMediatorRoles: string[];
  ObjectionRoles: string[];
  ServiceLoketRoles: string[];
  StuwmeerRoles: string[];
  ManagerRoles: string[];
  AdminRoles: string[];
  HelpdeskTCMG: string[];
}

interface ModifiedToken extends Token {
  bearer?: string;
}

export interface SpecificationStatistics {
  hour_statistics: {
    report: number;
    others: number;
    total: number;
  }
}

export enum UserRole {
  TCMG = 'tcmg',
  SERVICELOKET = 'serviceloket',
  WERKVOORBEREIDING = 'werkvoorbereiding',
  PLANNING = 'planning',
  CASE_MEDIATOR = 'case_mediator',
  DAMAGE_CONTROLLER = 'damage_controller',
  DAMAGE_HANDLER = 'damage_handler',
  APPEALS_COMMITTEE = 'appeals_committee',
  EXPERT = 'expert',
  PRE_CONTROLLER = 'pre_controller',
  SMR = 'smr',
  SIMPLE = 'simple',
  SIMPLE_PLUS = 'simple_plus',
  MANAGER = 'manager',
  HELPDESK_TCMG = 'helpdesk_tcmg',
  ADMIN = 'admin',
}

export const userLevelLabels: {[key: string] :string} = {
  schadeopnemer: 'Schadeopnemer',
  procesexpert: 'Procesexpert',
  junior: 'Junior Deskundige',
  medior: 'Medior Deskundige',
  senior: 'Senior Deskundige',
  special: 'Special Deskundige',
  project_coordinator: 'Project Coördinator',
};

export enum Level {
  SCHADEOPNEMER = 'schadeopnemer',
  PROCESEXPERT = 'procesexpert',
  JUNIOR_DESKUNDIGE = 'junior',
  MEDIOR_DESKUNDIGE = 'medior',
  SENIOR_DESKUNDIGE = 'senior',
  SPECIAL_DESKUNDIGE = 'special',
  PROJECT_COORDINATOR = 'project_coordinator',
}

export interface UserLevelItem {
  name?: string;
  value?: string;
}

export const UserLevels: UserLevelItem[] = Object.values(Level).map((level: string) => (
  { name: userLevelLabels[`${level}`], value: level }
));

export enum UrenadministratieLevel {
  SCHADEOPNEMER = 'schadeopnemer',
  PROCES_EXPERT = 'proces_expert',
  JUNIOR_DESKUNDIGE = 'junior_deskundige',
  MEDIOR_DESKUNDIGE = 'medior_deskundige',
  SENIOR_DESKUNDIGE = 'senior_deskundige',
  SPECIAL_DESKUNDIGE = 'special_deskundige',
  PROJECT_COORDINATOR = 'project_coordinator',
}
