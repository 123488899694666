import { Vue, Component, Prop } from 'vue-property-decorator';
import { sanitizeString } from '@/support/String';
import { getStatusLabel } from '@/support/ReportStatus';

@Component<Sidemenu>({})
export default class Sidemenu extends Vue {
  @Prop()
  protected items!: SidemenuItem[];

  @Prop({ default: false })
  protected showStatusLabel!: boolean;

  public isVisible(item: SidemenuItem): boolean | Function {
    if (typeof item.visible !== 'undefined') {
      if (this.isFunction(item.visible) && typeof item.visible !== 'boolean') {
        return item.visible() as Function;
      }
      return item.visible as boolean;
    }

    return true;
  }

  protected hasIcon(item: SidemenuItem): boolean {
    return !! item.icon;
  }

  protected iconPostion(item: SidemenuItem): boolean {
    if (item && item.icon) {
      switch (item.icon.position) {
        case 'right':
          return true;
        case 'left':
        default:
          return false;
      }
    }
    return false;
  }

  public sanitizeString(value: string) {
    return this.showStatusLabel
      ? getStatusLabel(sanitizeString(value, true))
      : sanitizeString(value, true);
  }

  public isFunction(object: any) {
    return object && {}.toString.call(object) == '[object Function]';
  }
}

export interface SidemenuItem {
  title: string;
  subtitle?: string;
  link?: any;
  visible?: boolean | Function;
  disabled?: boolean | Function;
  icon?: SidemenuIcon;
  action?: Function;
}

export interface SidemenuIcon {
  key: string;
  position?: 'left' | 'right';
}
