export const hasClass = (el: Element, cls: string): boolean => {
  if (el === null || el === undefined) {
    return false;
  }

  return el.className.length > 0 && new RegExp(`(\\s|^)${cls}(\\s|$)`).test(el.className);
};

export const addClass = (el: Element, cls: string): void => {
  if (el === null || el === undefined) {
    return;
  }

  if (el.classList) {
    el.classList.add(cls);
  } else {
    el.className += ` ${cls}`;
  }
};

export const removeClass = (el: Element, cls: string): void => {
  if (el === null || el === undefined) {
    return;
  }

  if (el.classList) {
    el.classList.remove(cls);
  } else {
    el.className = el.className.replace(new RegExp(`(^|\\b)${cls.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
  }
};

export const getParents = (el: Element): Node[] => {
  const parents = [];
  let p = el.parentNode;

  while (p !== null) {
    const o = p;
    parents.push(o);
    p = o.parentNode;
  }

  return parents;
};

export const getParentWithClass = (el: Element, cls: string): Element | null => {
  let parent: Element | null = null;
  const parents = getParents(el) as Element[];

  for (let i = 0; i < parents.length; i += 1) {
    if (hasClass(parents[i], cls)) {
      parent = parents[i];
      break;
    }
  }

  return parent;
};
