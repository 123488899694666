import { Model } from '@/models/Model';

export class Tag extends Model {
  protected $name = 'Tag';

  protected $endpoint = '/tags';

  protected $fillable: string[] = [];

  public id?: string;

  public uuid?: string;

  public name?: string;

  public created_at?: string;

  public updated_at?: string;
}
