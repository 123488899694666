var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "defaultDialog fullscreen-dialog-layout" }, [
    _c(
      "div",
      [
        _c(
          "v-tooltip",
          {
            attrs: { bottom: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        {
                          staticClass: "dialog-close",
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        on
                      ),
                      [_vm._v("close")]
                    )
                  ]
                }
              }
            ])
          },
          [_c("span", [_vm._v("Sluiten")])]
        ),
        _c("div", { staticClass: "dialog-title" }, [_vm._t("title")], 2)
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fullscreen-dialog-layout__container" },
      [_vm._t("container")],
      2
    ),
    _c(
      "div",
      { staticClass: "fullscreen-dialog-layout__footer" },
      [_vm._t("footer")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }