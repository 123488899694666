var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.name, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          return [
            _vm.$attrs.type === "textarea"
              ? _c(
                  "v-textarea",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "atabix-text-input",
                        class: {
                          "atabix-text-input--simple": _vm.isSimple
                        },
                        attrs: {
                          autocomplete: _vm.autocomplete ? "on" : "off",
                          "error-messages": errors,
                          "hide-details": false,
                          label: _vm.internalLabel
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.$scopedSlots, function(index, name) {
                              return {
                                key: name,
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._t(name, null, null, item)]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.internalValue,
                          callback: function($$v) {
                            _vm.internalValue = $$v
                          },
                          expression: "internalValue"
                        }
                      },
                      "v-textarea",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                )
              : _vm._e(),
            _vm.$attrs.type !== "textarea"
              ? _c(
                  "v-text-field",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "atabix-text-input",
                        class: {
                          "atabix-text-input--simple": _vm.isSimple
                        },
                        attrs: {
                          "append-icon": _vm.appendInnerIcon,
                          autocomplete: _vm.autocomplete ? "on" : "off",
                          "data-vv-name": _vm.name,
                          "error-messages": errors,
                          "hide-details": false,
                          label: _vm.internalLabel,
                          "prepend-icon": _vm.prependOuterIcon
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.$scopedSlots, function(index, name) {
                              return {
                                key: name,
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._t(name, null, null, item)]
                                }
                              }
                            })
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.internalValue,
                          callback: function($$v) {
                            _vm.internalValue = $$v
                          },
                          expression: "internalValue"
                        }
                      },
                      "v-text-field",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }