var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atabix-modal", attrs: { id: "atabix-vue-modal" } },
    [
      _c("div", {
        staticClass: "atabix-modal-overlay",
        on: { click: _vm.onOverlayClick }
      }),
      !_vm.isMounting
        ? _c(
            _vm.component,
            _vm._g(
              _vm._b(
                {
                  tag: "component",
                  staticClass: "atabix-modal-component",
                  class: { "atabix-modal-component--wide": _vm.props.wide }
                },
                "component",
                _vm.props,
                false
              ),
              _vm.listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }