import { Component, Vue, Prop } from 'vue-property-decorator';
import { firstDayOfWeek, lastDayOfWeek } from '@/support/String';
import { DateTime } from 'luxon';
import { Rpc } from '@/models/Rpc';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import Client from '@/support/Client';
import { User } from '@/models/User';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<ApplyTemplateDialog>({})
export default class ApplyTemplateDialog extends Vue {
  @Prop()
  protected startDate!: DateTime;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected isExpert!: boolean;

  @Prop()
  protected users!: User[];

  protected selectedUsers: string[] = [];

  protected isLoading = false;

  protected overrideTemplates = false;

  protected close() {
    this.$emit('input', false);
    this.overrideTemplates = false;
  }

  protected applyWeekTemplate() {
    const payloadBody: {[key: string]: string | string[] | boolean} = {
      starts_at: firstDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
      ends_at: lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
      override: this.overrideTemplates,
    };

    if (this.isExpert) {
      payloadBody.user = this.$store.state.Auth.uuid;
    } else if (this.selectedUsers) {
      payloadBody.users = this.selectedUsers;
    }

    const payload = {
      signature: 'events:schedules-to-availability',
      body: payloadBody,
    };

    this.isLoading = true;
    const templateModel = this.readOnly ? Client('post', '/dmz/planning/apply-template', payloadBody, false, this.organization) : new Rpc().rpcPost(payload);

    templateModel
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.$emit('initialize');
    this.$store.dispatch('openDialog', this.dialogOptionsIsBusy);
    this.close();
  }

  protected get dialogOptionsIsBusy(): Options {
    return {
      title: 'Gelukt',
      text: 'LET OP: Het kan 5 a 10 minuten duren voordat alles is doorgevoerd.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
        },
      },
    };
  }
}
