import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Model as DataModel } from '@/models/Model';
import { InstanceLoader } from '@/support/InstanceLoader';

@Component<SelectFilter>({})
export default class SelectFilter extends Vue {
  @Prop() private type!: string;

  @Prop({ default: undefined })
  private multiple!: boolean;

  @Prop() private placeholder?: string;

  @Prop({ default: false })
  protected hideDetails!: boolean;

  @Prop({ default: true })
  protected returnObject!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: '' })
  protected default!: string;

  @Prop({ default: false })
  protected reset!: boolean;

  @Prop({ default: false })
  protected clear!: boolean;

  @Prop({ default: '' })
  protected label!: string;

  public Model: DataModel = new DataModel();

  public options: object[] = [];

  public isLoading = false;

  protected selected: any = null;

  public mounted() {
    if (this.type in this.$store.state.filters) {
      this.options = this.$store.state.filters[this.type];
      this.setDefault();
      return;
    }

    this.getOptions();
  }

  protected setDefault() {
    if (
      this.default !== null
      && this.default !== undefined
      && this.default.length > 0
      && this.selected === null
      && this.selected !== this.default
    ) {
      this.selected = this.default;
    }
  }

  public getOptions() {
    if (this.type in this.$store.state.filters && this.isLoading == false) {
      this.options = this.$store.state.filters[this.type];
      return;
    }

    const payload: any = {};
    payload[this.type] = [];
    // this.$store.dispatch('updateFilters', payload);

    this.Model = InstanceLoader.get(this.type);

    this.Model.all()
      .then((items: DataModel[]) => {
        const filters: any[] = [];

        items.forEach((item) => {
          filters.push({
            value: (item as any).id ? (item as any).id : (item as any).uuid,
            text: item.getFilterLabel(),
          });
        });

        const payload: any = {};
        payload[this.type] = filters;

        // this.$store.dispatch('updateFilters', payload);
        this.options = filters;
        this.setDefault();
        this.isLoading = false;
      })
      .catch(() => {
        /**/
      });
  }

  @Watch('clear')
  protected clearSelect(clear: boolean) {
    if (clear) {
      this.$nextTick(() => {
        this.selected = '';
      });
    }
  }

  @Watch('reset')
  public resetValues(reset: any) {
    if (reset) {
      this.$nextTick(() => {
        this.selected = this.default;
        this.$emit('select-reset');
      });
    }
  }

  @Watch('selected')
  public optionSelected(value: any, oldValue: any) {
    if (value !== oldValue) {
      if (this.returnObject) {
        this.$emit('input', { value, type: this.type });
      } else {
        this.$emit('input', value);
      }
    }
  }

  @Watch('filters')
  public onStateChanged(value: string, oldValue: string) {
    if (this.type in this.$store.state.filters) {
      this.options = this.$store.state.filters[this.type];
    }
  }

  get filters(): Object {
    return this.$store.state.filters;
  }
}
