import { styles } from '@/support/Style';
import { Avatar } from '@/support/Avatar';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User, userRoles } from '@/models/User';
import { Breadcrumb } from '@/support/Breadcrumb';
import { addClass, removeClass } from '@/support/Html';
import { getStatusLabel } from '@/support/ReportStatus';
import { Setting } from '@/models/Setting';
import { Application } from '@/support/Application';
import MiGlobalSearch from '@/components/mi-global-search/MiGlobalSearch.vue';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<Navigation>({
  components: {
    MiGlobalSearch,
  },
})
export default class Navigation extends Vue {
  public breakpointTablet = 1367;

  public windowWidth: number = window.innerWidth;

  public curriculumId = '';

  protected isMenuActive = false;

  protected isCondensed = false;

  private lastScrollY = 0;

  protected breadcrumbs: Breadcrumb[] = [];

  protected isSettingsMenuOpen = false;

  protected temp: object = {};

  protected isTablet = false;

  protected isShowingSync = false;

  protected showAvailability = false;

  protected userRoles = userRoles;

  protected get settingItems(): NavigationItem[] {
    return [
      {
        title: 'Mijn profiel',
        link: '/mijn-profiel',
        icon: 'face',
        visible: this.user ? ! this.user.hasRole(['simple', 'simple_plus']) : false,
      },
      {
        title: 'Mijn exports',
        link: '/mijn-exports',
        icon: 'file_download',
        visible: true,
      },
      {
        title: 'Instructiepagina',
        link: '/instructies',
        icon: 'info',
        visible: this.user ? ! this.user.hasRole(['simple', 'simple_plus']) : false,
      },
      {
        title: 'Kennisbank',
        link: '/release-dashboard',
        icon: 'new_releases',
        visible: true,
      },
      {
        title: 'Instellingen',
        link: '/instellingen/gebruikersbeheer',
        icon: 'settings',
        visible: this.user ? this.user.hasRole(this.userRoles.ManagerRoles) : false,
      },
      {
        title: 'Uitloggen',
        action: () => {
          this.logout();
        },
        icon: 'power_settings_new',
        visible: true,
      },
    ];
  }

  protected get userAvatar() {
    if (this.$store.state.Auth && this.$store.state.Auth.contact && this.$store.state.Auth.contact.avatar && ! this.$store.state.Auth.contact.avatar.includes('gravatar')) {
      return this.$store.state.Auth.contact.avatar;
    }

    return new Avatar(this.$store.state.Auth.name, { color: styles.white, background: styles.primary }).toDataUrl();
  }

  public mounted() {
    if (this.$route && this.$route.name) {
      if (! this.$route.name.includes('report.detail') || ! this.$route.name.includes('.editor')) {
        this.$store.state.alert = {
          message: '',
          show: false,
        };
      }
    }
    this.getSettings();
    this.resetActive();
    this.setActiveItem();

    if (this.windowWidth < this.breakpointTablet) {
      this.toggleMenuState('mini', true);
    }
    this.$root.$on('breadcrumbUpdated', (crumb: Breadcrumb[]) => {
      this.breadcrumbs = (crumb as any).crumb;
    });
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleWindowScroll);

    if (window.innerWidth < 960) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }

    this.$root.$on('syncReport', this.handleReportSync);
  }

  protected destroyed() {
    this.$root.$off('syncReport');
  }

  protected getSettings() {
    new Setting()
      .all()
      .then((settings: Setting[]) => {
        const setting = settings.find((setting: Setting) => setting.key === 'planning_experts_can_access_availability');

        if (setting) {
          this.showAvailability = setting.value as boolean;
        }
      })
      .catch(() => {});
  }

  protected handleReportSync(showSync: boolean) {
    this.isShowingSync = showSync;
  }

  protected handleWindowScroll() {
    const direction = this.lastScrollY > window.scrollY ? 'up' : 'down';
    this.lastScrollY = window.scrollY;

    if ((window.scrollY > 400 && direction === 'up') || window.scrollY < 280) {
      this.isCondensed = false;
    } else {
      this.isCondensed = true;
    }
  }

  protected checkForActive(item: any) {
    if (item.items) {
      for (let i = 0; i < item.items.length; i += 1) {
        const currentItem = item.items[i];
        if (currentItem.link === this.$route.fullPath) {
          return true;
        }
      }
    }
    if (item.link === this.$route.fullPath) {
      return true;
    }
    return false;
  }

  public handleResize() {
    if (window.innerWidth < 960) {
      this.isTablet = true;
    } else {
      this.isTablet = false;
    }

    if (this.windowWidth !== window.innerWidth && window.innerWidth < this.breakpointTablet) {
      this.windowWidth = window.innerWidth;
      this.toggleMenuState('mini', true);
    }
  }

  get drawerState(): boolean {
    return this.$store.state.drawer;
  }

  get miniState(): boolean {
    return this.$store.state.miniNav;
  }

  set miniState(value) {
    //
  }

  protected logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  protected canViewItem(item: NavigationItem) {
    return this.validateRole(item) && this.validateVisible(item);
  }

  protected validateRole(item: NavigationItem) {
    return ! item.roles || this.user.hasRole(item.roles);
  }

  protected validateVisible(item: NavigationItem) {
    if (item.visible === undefined) {
      return true;
    }
    if (typeof item.visible === 'boolean') {
      return item.visible;
    }
    if (typeof item.visible === 'function') {
      return (item as any).visible(item);
    }
    return false;
  }

  protected getTranslation(key: string) {
    return this.$t(key);
  }

  @Watch('$route')
  protected routerChanged(currentRoute: any, oldRoute: any) {
    if (oldRoute.meta.category) {
      const item = this.items.find((navItem: NavigationItem) => navItem.category === oldRoute.meta.category);

      if (item) {
        item.active = false;
      }
    }

    this.resetActive();
    this.setActiveItem();

    if (this.isMenuActive) {
      this.isMenuActive = false;
    }

    if (this.$route.params.id && this.user.hasRole(['simple', 'simple_plus'])) {
      this.$store.dispatch('updateReportId', this.$route.params.id);
    }
  }

  protected resetActive() {
    this.items.forEach((navItem: NavigationItem) => {
      navItem.active = false;
    });
  }

  protected setActiveItem() {
    if (! this.$router.currentRoute.meta.category) {
      return;
    }

    // if (this.$route.fullPath.includes('?status')) {
    //   const item  = this.items.find((navItem: NavigationItem) => {
    //     return navItem.category === 'expert_office';
    //   });

    //   if (item) {
    //     item.active = true;
    //   }
    //   return;
    // }

    const item = this.items.find((navItem: NavigationItem) => navItem.category === this.$router.currentRoute.meta.category);

    if (item) {
      item.active = true;
    }
  }

  protected get showNavigation() {
    let showNav = true;
    const hideNavRoutes: string[] = ['alertconfirmation', 'set-mfa'];
    if (this.$store.state.Auth.force_password_change) return false;
    hideNavRoutes.forEach((routeName) => {
      if (routeName === this.$route.name) showNav = false;
    });
    return showNav;
  }

  protected resetFilters() {
    this.$store.state.reportFilter = {};
  }

  @Watch('isMenuActive')
  protected menuActiveChanged() {
    const pageHtml = document.querySelectorAll('html')[0];
    if (this.isMenuActive) {
      addClass(pageHtml, 'overflow-y-hidden');
    } else {
      removeClass(pageHtml, 'overflow-y-hidden');
    }
  }

  @Watch('$route')
  protected routeChange(to: any, from: any) {
    if (this.$route && this.$route.name) {
      if (! this.$route.name.includes('report.detail') || ! this.$route.name.includes('.editor')) {
        this.$store.state.alert = {
          message: '',
          show: false,
        };
      }
    }
  }

  @Watch('drawerState')
  @Watch('miniState')
  @Watch('user')
  @Watch('schoolLogo')
  public onStateChanged(value: string, oldValue: string) {}

  get user(): User {
    return this.$store.state.Auth;
  }

  public toggleMenuState(type: string, forcedState: boolean) {
    if (type === 'mini') {
      this.$store.dispatch('toggleMini', forcedState);
    } else {
      this.$store.dispatch('toggleDrawer', forcedState);
    }
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  public displayItem(item: any) {
    if (item.auth === undefined) {
      return true;
    }

    return false;
  }

  public get profileLink() {
    return `/users/${this.user.id}`;
  }

  protected get isAcceptance() {
    return Application.config('APP_ENV') === 'acceptatie';
  }

  protected get items(): NavigationItem[] {
    if (this.user && this.user.hasRole(['expert', 'simple', 'simple_plus'])) {
      return this.expertItems;
    }

    return this.mainItems;
  }

  protected get canViewMonitor(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.MONITOR);
  }

  protected get expertItems(): NavigationItem[] {
    return [
      {
        title: 'Mijn Rapporten',
        link: '/reports',
        active: false,
        category: 'reports',
        visible: (item: NavigationItem) => {
          if (this.user) {
            if (this.$store.state.isServiceOrganization) {
              return this.user.hasRole(['expert', 'simple', 'simple_plus']);
            }

            return this.user.hasRole(['expert']);
          }
        },
      },
      {
        title: 'Mijn schade',
        link: `/schade-regelen/${this.$store.state.reportId}`,
        icon: 'home',
        visible: (item: NavigationItem) => {
          if (this.user) {
            if (! this.$store.state.reportId) {
              return false;
            }

            return this.user.hasRole(['simple', 'simple_plus']) && ! this.$store.state.isServiceOrganization;
          }
        },
      },
      {
        title: 'Mijn Agenda',
        link: '/mijn-agenda',
        icon: 'home',
        visible: (item: NavigationItem) => {
          if (this.user.hasRole(['simple', 'simple_plus'])) {
            return false;
          }

          if (this.user) {
            return this.user.is_plannable;
          }
        },
      },
      {
        title: 'Beschikbaarheid',
        link: '/availability',
        icon: 'home',
        visible: (item: NavigationItem) => {
          if (this.user.hasRole(['simple', 'simple_plus'])) {
            return false;
          }

          if (this.user) {
            return this.user.is_plannable && this.showAvailability;
          }
        },
      },
      {
        title: 'Rapportage',
        link: '/rapportage',
        active: false,
        category: 'report-statistics',
        visible: (item: NavigationItem): boolean => {
          if (this.user.has_management_info) {
            return true;
          }

          return ! this.$store.state.isServiceOrganization && this.$store.state.Auth.supervisor_info && this.$store.state.Auth.supervisor_info.is_supervisor;
        },
      },
      {
        title: 'Urenadministratie',
        link: this.$store.state.isServiceOrganization ? '/urenadministratie/ingediende-specificaties' : '/urenadministratie',
        active: false,
        category: 'urenadministratie',
        visible: (item: NavigationItem) => {
          if (this.$store.state.isServiceOrganization) {
            return this.$store.state.Auth.is_rockefeller;
          }

          return this.$store.state.hasTimeModule ? this.$store.state.hasTimeModule : this.$store.state.Auth.is_rockefeller;
        },
      },
    ];
  }

  protected get mainItems(): NavigationItem[] {
    return [
      {
        title: 'Rapporten',
        link: '/reports',
        active: false,
        category: 'reports',
        visible: (item: NavigationItem) => true,
      },
      {
        title: 'Werkstromen',
        link: '/werkstromen',
        active: false,
        category: 'reportflow',
        visible: (item: NavigationItem) => true,
      },
      {
        title: 'Mijn Agenda',
        link: '/mijn-agenda',
        icon: 'home',
        visible: (item: NavigationItem) => {
          if (this.user) {
            return this.user.is_plannable;
          }
        },
      },
      {
        title: 'Werkvoorbereiding',
        link: '/werkvoorbereiding',
        icon: 'home',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.WVRoles),
      },
      {
        title: 'Planning',
        link: `/planning${! this.user.hasRole(this.userRoles.ManagerRoles) ? '/mededelingen' : ''}`,
        visible: (item: NavigationItem) => {
          if (! this.$store.state.isServiceOrganization) {
            return this.user.is_planner || this.user.hasRole(this.userRoles.ManagerRoles);
          }

          return this.user.hasRole([...this.userRoles.PlanningRoles, ...this.userRoles.ServiceLoketRoles]);
        },
      },
      {
        title: 'Zaakbegeleiding',
        link: '/zaakbegeleiding',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.CaseMediatorRoles),
      },
      {
        title: 'Schadebepaling',
        link: '/schadebepaling',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.DamageControllerRoles),
      },
      {
        title: 'Schadeafhandeling',
        link: '/schadeafhandeling',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.DamageHandlerRoles),
      },
      {
        title: 'JZ',
        link: '/juridische-zaken',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.VeldwerkRoles),
      },
      {
        title: 'Bezwaar',
        link: '/bezwaar',
        visible: (item: NavigationItem) => this.$store.state.isServiceOrganization && this.user.hasRole(this.userRoles.ObjectionRoles),
      },
      {
        title: 'Bureau',
        link: '/bureau',
        visible: (item: NavigationItem) => ! this.$store.state.isServiceOrganization && this.user.hasRole([...this.userRoles.PreControllerRoles, ...this.userRoles.ManagerRoles]),
      },
      {
        title: 'Tools',
        active: false,
        link: '/tools/trillingstool',
        category: 'tools',
        visible: (item: NavigationItem) => {
          if (this.user.hasRole(['serviceloket', 'case_mediator'])) {
            return false;
          }
          return true;
        },
      },
      {
        title: 'Rapportage',
        link: '/rapportage',
        active: false,
        category: 'report-statistics',
        visible: (item: NavigationItem) => this.user.has_management_info || (! this.$store.state.isServiceOrganization && this.user.supervisor_info && this.user.supervisor_info.is_supervisor),
      },
      {
        title: 'Urenadministratie',
        link: this.$store.state.isServiceOrganization ? '/urenadministratie/ingediende-specificaties' : '/urenadministratie',
        active: false,
        category: 'urenadministratie',
        visible: (item: NavigationItem) => {
          if (this.$store.state.isServiceOrganization) {
            return this.$store.state.Auth.is_rockefeller;
          }

          return this.$store.state.hasTimeModule ? this.$store.state.hasTimeModule : this.$store.state.Auth.is_rockefeller;
        },
      },
      {
        title: 'Schadebibliotheek',
        active: false,
        link: '/schadebibliotheek',
        category: 'library',
        visible: (item: NavigationItem) => this.$store.state.isBETA,
      },
      {
        title: 'Monitor',
        active: false,
        link: '/monitor',
        category: 'library',
        visible: (item: NavigationItem) => ! this.$store.state.isServiceOrganization && this.canViewMonitor,
      },
    ];
  }
}

export interface NavigationItem {
  title: string;
  subTitle?: string;
  link?: string;
  icon?: string;
  id?: string;
  roles?: string[] | string;
  visible?: Function | boolean;
  divider?: Function | boolean;
  items?: NavigationItem[];
  external?: boolean;
  action?: Function;
  category?: string;
  active?: boolean;
  hide?: boolean;
}
