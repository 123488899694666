var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "sidemenu__container item__list", attrs: { wrap: "" } },
    [
      _vm._l(_vm.items, function(item, index) {
        return [
          _vm.isVisible(item)
            ? _c(
                "v-flex",
                {
                  key: index,
                  staticClass: "list__item",
                  class: {
                    disabled: _vm.isFunction(item.disabled)
                      ? item.disabled()
                      : item.disabled
                  },
                  attrs: { xs12: "" }
                },
                [
                  item.action
                    ? _c(
                        "span",
                        {
                          key: index,
                          staticClass: "list__item--link",
                          on: {
                            click: function($event) {
                              return item.action()
                            }
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                wrap: "",
                                "align-center": "",
                                row: "",
                                reverse: _vm.iconPostion(item)
                              }
                            },
                            [
                              _vm.hasIcon(item)
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "text-xs-right item__icon item__icon--action",
                                      attrs: { xs2: "" }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(item.icon.key))
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { class: _vm.hasIcon(item) ? "xs10" : "xs12" },
                                [
                                  item.title
                                    ? [
                                        _c("span", {
                                          staticClass: "item__link capital",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(item.title)
                                            )
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  item.subtitle
                                    ? [
                                        _c("span", {
                                          staticClass: "item__sub",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(item.subtitle)
                                            )
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "router-link",
                        {
                          key: index,
                          staticClass: "list__item--link",
                          attrs: { to: item.link }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                wrap: "",
                                "align-center": "",
                                row: "",
                                reverse: _vm.iconPostion(item)
                              }
                            },
                            [
                              _vm.hasIcon(item)
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "text-xs-right item__icon item__icon--action",
                                      attrs: { xs2: "" }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(item.icon.key))
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { class: _vm.hasIcon(item) ? "xs10" : "xs12" },
                                [
                                  item.title
                                    ? [
                                        _c("span", {
                                          staticClass: "item__link capital",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(item.title)
                                            )
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  item.subtitle
                                    ? [
                                        _c("span", {
                                          staticClass: "item__sub",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitizeString(item.subtitle)
                                            )
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }