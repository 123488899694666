var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v("Afspraak types")]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c("p", [_vm._v(_vm._s(_vm.user.name))]),
                _vm.date
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.date.toFormat("EEEE")) +
                          " - " +
                          _vm._s(_vm.date.toFormat("dd-LL-yyyy"))
                      )
                    ])
                  : _vm._e(),
                _vm.day
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.day.label) +
                          " - " +
                          _vm._s(_vm.weekTypeMap[_vm.day.week_type])
                      )
                    ])
                  : _vm._e(),
                _c(
                  "v-layout",
                  { staticClass: "item__list", attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "label", attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs6: "" } }, [
                              _vm._v("type")
                            ]),
                            _c("v-flex", { attrs: { xs3: "" } }, [
                              _vm._v("aantal")
                            ]),
                            _c("v-flex", { attrs: { xs3: "" } }, [
                              _vm._v("totaal")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._l(_vm.appointmentTypes, function(appointmentType) {
                      return _c(
                        "v-flex",
                        {
                          key: appointmentType.key,
                          staticClass: "list__item",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-center": "" } },
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _vm._v(_vm._s(appointmentType.label))
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      type: "number",
                                      placeholder: "aantal",
                                      "hide-details": "",
                                      min: "0"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validateAppointmentValue(
                                          appointmentType
                                        )
                                      }
                                    },
                                    model: {
                                      value: appointmentType.value,
                                      callback: function($$v) {
                                        _vm.$set(appointmentType, "value", $$v)
                                      },
                                      expression: "appointmentType.value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      text__warning:
                                        Number(appointmentType.value) >
                                        Number(
                                          _vm.findSettingValue(
                                            appointmentType.key
                                          ) || 0
                                        )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(appointmentType.value || 0) +
                                        "/" +
                                        _vm._s(
                                          _vm.findSettingValue(
                                            appointmentType.key
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _c(
                      "v-flex",
                      {
                        staticClass: "list__item list__item--bold",
                        attrs: { xs12: "" }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "align-center": "" } },
                          [
                            _c("v-flex", { attrs: { xs6: "" } }, [
                              _vm._v("Totaal")
                            ]),
                            _c("v-flex", { attrs: { xs3: "" } }, [
                              _vm._v(_vm._s(_vm.total))
                            ]),
                            _c("v-flex", { attrs: { xs3: "" } }, [
                              _vm._v(_vm._s(_vm.total) + "/" + _vm._s(_vm.max))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.isDisabled
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "", "align-center": "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("span", { staticClass: "text__warning" }, [
                                    _vm._v(
                                      "Gekozen aantal is hoger dan het limiet"
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled:
                                _vm.isDisabled ||
                                !_vm.isValidAmountAppointments,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit()
                              }
                            }
                          },
                          [_vm._v(" Aanpassen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }