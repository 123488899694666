export enum overwriteCausalityConclusionReason {
  OTHER = 'other',
}

export enum overwriteBuildingFeaturesConclusionReason {
  REASON_1 = 'building_features_reden_1',
  REASON_2 = 'building_features_reden_2',
  REASON_3 = 'building_features_reden_3',
}

export const overwriteConclusionReasonLabels: {[key: string] :string} = {
  // Causality Question
  [overwriteCausalityConclusionReason.OTHER]: 'Overig',

  // Building features Question
  [overwriteBuildingFeaturesConclusionReason.REASON_1]: 'Gevoelig object Reden 1',
  [overwriteBuildingFeaturesConclusionReason.REASON_2]: 'Gevoelig object Reden 2',
  [overwriteBuildingFeaturesConclusionReason.REASON_3]: 'Gevoelig object Reden 3',
};
