import { User } from '@/models/User';
import { Skill } from '@/models/Skill';
import { Model } from '@/models/Model';
import { ExperienceSubmissionItem } from '@/models/ExperienceSubmissionItem';

export class ExperienceSubmission extends Model {
  protected $name = 'ExperienceSubmission';

  protected $endpoint = '/experience-submissions';

  protected $fillable: string[] = [
    'title',
    'description',
    'completed',
    'starts_at',
    'ends_at',
    'type',
    'user',
  ];

  public id!: string;

  public status?: ExperienceSubmissionStatus;

  public type?: string;

  public created_at?: string;

  public updated_at?: string;

  public level?: string;

  public user?: User;

  public completed?: boolean;

  public title ?: string;

  public description?: string;

  public submission_notes?: string;

  public reject_notes?: string;

  public skills?: Skill[];

  public starts_at?: string;

  public ends_at?: string;

  public verified_by?: string;

  public verified_at?: string;

  public items?: ExperienceSubmissionItem[];

  public agreed_to_declaration_of_independence?: boolean;

  public declaration_of_expertise?: any;

  public declaration_of_good_behaviour?: any;

  public available_fte?: string;

  public postcode?: string;

  public started_working_at?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export const statusLabels: {[key: string] :string} = {
  checking_expert: 'Afwachting deskundige',
  pending: 'Open',
  rejected: 'Afgewezen',
  verified: 'Goedgekeurd',
};

export const statusColors: {[key: string] :string} = {
  checking_expert: 'blue',
  pending: 'orange',
  rejected: 'red',
  verified: 'green',
};

export enum ExperienceSubmissionStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  VERIFIED = 'verified',
  CHECKING_EXPERT = 'checking_expert',
}

export interface ExperienceSubmissionPayload {
  status?: ExperienceSubmissionStatus;
  submission_notes?: string;
  reject_notes?: string;
  level?: string;
  skills?: string[];
  user?: string;
  declaration_of_expertise?: string | null;
  declaration_of_good_behaviour?: string | null;
  assessment?: string | null;
  agreed_to_declaration_of_independence?: boolean;
  available_fte?: string;
  postcode?: string;
  started_working_at?: string;
}
