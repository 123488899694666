import { Model } from '@/models/Model';

export class AlertSystem extends Model {
  protected $name = 'AlertSystem';

  protected $endpoint = '/alert-system';

  protected $fillable: string[] = [];

  public setBadExpertsEndpoint() {
    this.$endpoint = `${this.$endpoint}/bad-experts`;
    return this;
  }

  public postLateReason(id?: string, payload?: object) {
    const url = `${this.$endpoint}/${id}/late-reasons`;

    return this.request('post', url, payload);
  }
}
