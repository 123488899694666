import { ValidationObserver } from 'vee-validate';
import { Component, Ref, Vue } from 'vue-property-decorator';
import AAbstractInput from '@/components/AAbstractInput/AAbstractInput';
import { AStatefulFormInterface } from '@/components/AStatefulForm/AStatefulForm';

@Component<AStatefulFormForFilters>({
  components: {
    ValidationObserver,
  },
})
export default class AStatefulFormForFilters extends Vue implements AStatefulFormInterface {
  @Ref()
  protected aStatefulForm!: AStatefulFormInterface;

  public async submit(): Promise<void> {
    await this.aStatefulForm?.submit();
  }

  public emitChange(name: string, data: unknown, input: AAbstractInput): void {
    this.aStatefulForm?.emitChange(name, data, input);
  }

  public reset(): void {
    this.aStatefulForm?.reset();
  }

  public async validate(silent = false): Promise<boolean> {
    const valid = await this.aStatefulForm?.validate(silent);
    return valid;
  }

  public registerField(name: string, input: AAbstractInput): void {
    return this.aStatefulForm?.registerField(name, input);
  }

  public unregisterField(name: string): void {
    return this.aStatefulForm?.unregisterField(name);
  }

  public get errors(): Record<string, string[]> {
    return this.aStatefulForm?.errors;
  }

  public get original(): Record<string, any> {
    return this.aStatefulForm?.original;
  }

  public get isValid(): boolean {
    return this.aStatefulForm?.isValid;
  }

  public get isDirty(): boolean {
    return this.aStatefulForm?.isDirty;
  }

  public getData(): Record<string, string | number | string[] | number[]> {
    return this.aStatefulForm?.getData();
  }

  public parsePayload(): void {
    return this.aStatefulForm?.parsePayload();
  }
}
