var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.isLoading
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c("v-select", {
                  attrs: {
                    "prepend-icon": "poll",
                    items: _vm.userLevels,
                    "item-text": "name",
                    "item-value": "value",
                    label: "Niveau",
                    "persistent-hint": "",
                    disabled: !_vm.canEditLevel
                  },
                  on: {
                    change: function($event) {
                      !_vm.disableAutosave ? _vm.updateLevel() : null
                    }
                  },
                  model: {
                    value: _vm.model.level,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "level", $$v)
                    },
                    expression: "model.level"
                  }
                })
              ],
              1
            ),
            _vm.skills && _vm.skills.length
              ? _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { staticClass: "item__list", attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "label", attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs8: "" } }, [
                                  _c("span", [_vm._v("Naam")])
                                ]),
                                _vm.isUserType && _vm.canEditFixedSkills
                                  ? _c("v-flex", { attrs: { xs2: "" } }, [
                                      _c("span", [_vm._v("Actief")])
                                    ])
                                  : _vm._e(),
                                _c("v-flex", { attrs: { xs2: "" } }, [
                                  _c("span", [_vm._v("Planbaar")])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.skills, function(skill, index) {
                          return _c(
                            "v-flex",
                            {
                              key: index,
                              staticClass: "list__item",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("span", [_vm._v(_vm._s(skill.name))])
                                  ]),
                                  _vm.isUserType && _vm.canEditFixedSkills
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mt-0 pt-0",
                                            attrs: {
                                              color: "#837f16",
                                              "hide-details": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                !_vm.disableAutosave
                                                  ? _vm.addFixedSkills()
                                                  : null
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.fixedSkillList[skill.id],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.fixedSkillList,
                                                  skill.id,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fixedSkillList[skill.id]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "" } },
                                    [
                                      _vm.canEditSkill(skill)
                                        ? _c("v-switch", {
                                            staticClass: "mt-0 pt-0",
                                            attrs: {
                                              color: "#837f16",
                                              "hide-details": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                !_vm.disableAutosave
                                                  ? _vm.addSkills()
                                                  : null
                                              }
                                            },
                                            model: {
                                              value: _vm.skillList[skill.id],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.skillList,
                                                  skill.id,
                                                  $$v
                                                )
                                              },
                                              expression: "skillList[skill.id]"
                                            }
                                          })
                                        : _c(
                                            "span",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.skillList[
                                                      skill.id
                                                    ]
                                                      ? "success"
                                                      : "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.skillList[skill.id]
                                                          ? "check"
                                                          : "close"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }