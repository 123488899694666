var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "v-layout",
            {
              staticClass: "elementPanel elementPanel--shadowed",
              attrs: { row: "", wrap: "", "pa-0": "" }
            },
            [
              _c("LoaderCard", {
                staticClass: "main-loader",
                attrs: { extraClass: "availability", type: "dots", flat: true }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              {
                attrs: {
                  row: "",
                  wrap: "",
                  "mb-3": "",
                  "justify-end": "",
                  "align-center": ""
                }
              },
              [
                _vm.totalMissingExperts > 0 &&
                !_vm.isPastCurrentWeek &&
                _vm.isPlanner
                  ? _c(
                      "v-flex",
                      {
                        staticClass: "availability-warning",
                        attrs: {
                          "align-center": "",
                          "pl-2": "",
                          "p-2": "",
                          "mr-3": ""
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { "fill-height": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { "align-self-center": "" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "translate-icon",
                                    attrs: { "mr-3": "" }
                                  },
                                  [_vm._v("warning")]
                                ),
                                _c("span", { staticClass: "warning-text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.totalMissingExperts) +
                                      " van de " +
                                      _vm._s(_vm.experts.length) +
                                      " " +
                                      _vm._s(
                                        _vm.experts.length > 1
                                          ? _vm.userTypesText
                                          : _vm.userTypeText
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.totalMissingExperts > 1
                                          ? "hebben"
                                          : "heeft"
                                      ) +
                                      " (nog) geen beschikbaarheid doorgegeven "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isPlanner && _vm.isManager
                  ? _c(
                      "v-flex",
                      { staticClass: "flex-starving" },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: _vm.isLoadingExport,
                              compact: true,
                              color: "primary",
                              icon: "cloud_download"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.downloadExcel($event)
                              }
                            }
                          },
                          [_vm._v(" Download Excel ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isPlanner && !_vm.isImmaterial && !_vm.isZaakbegeleider
                  ? _c(
                      "v-flex",
                      {
                        staticClass: "flex-starving custom-filter",
                        attrs: { "mt-0": "", "pa-0": "" }
                      },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.userTypes,
                            "item-text": "name",
                            "item-value": "key",
                            "hide-details": "",
                            placeholder: "Gebruikerstype",
                            clearable: "",
                            multiple: ""
                          },
                          on: { change: _vm.filterUserTypes },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(data) {
                                  return [
                                    data.index === 0
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "userTypes--selected"
                                            },
                                            [_vm._v(_vm._s(data.item.name))]
                                          ),
                                          _vm.selectedUserTypes.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "userTypes--amount fadedText"
                                                },
                                                [
                                                  _vm._v(
                                                    " (+" +
                                                      _vm._s(
                                                        _vm.selectedUserTypes
                                                          .length
                                                      ) +
                                                      ") "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            726907129
                          ),
                          model: {
                            value: _vm.selectedUserTypes,
                            callback: function($$v) {
                              _vm.selectedUserTypes = $$v
                            },
                            expression: "selectedUserTypes"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isPlanner && !_vm.isServiceOrganization && _vm.canSubmitWeek
                  ? _c("v-flex", { staticClass: "flex-starving" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "toolbar__toggle toolbar__toggle--submit",
                          on: { click: _vm.handInWeek }
                        },
                        [
                          _c("v-icon", [_vm._v("assignment_turned_in")]),
                          _vm._v(" Week inleveren ")
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            !_vm.isLoading
              ? _c(
                  "v-layout",
                  {
                    staticClass: "elementPanel mb-4",
                    attrs: { row: "", wrap: "", "pa-0": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "availabilityPanel",
                        attrs: { xs12: "", "pa-0": "" }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "", "mx-0": "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "stickyTableHeader",
                                class: {
                                  translate: _vm.stickyTableHeader,
                                  condensed: _vm.isMenuCondensed
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "availability-toolbar",
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "align-center": "",
                                      "pb-2": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { staticClass: "flex-starving" },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "weekChange weekChange__prev",
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.goToPreviousWeek(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" keyboard_arrow_left ")]
                                            ),
                                            _c(
                                              "h1",
                                              {
                                                staticClass:
                                                  "elementSubTitle elementSubTitle__accent"
                                              },
                                              [
                                                _vm._v(
                                                  "Week " +
                                                    _vm._s(_vm.weekNumber)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "weekChange weekChange__next",
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.goToNextWeek(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" keyboard_arrow_right ")]
                                            ),
                                            _c(
                                              "v-menu",
                                              {
                                                ref: "dialog",
                                                attrs: {
                                                  "close-on-content-click": false,
                                                  lazy: "",
                                                  "nudge-right": "100",
                                                  "full-width": "",
                                                  "max-width": "290px",
                                                  "min-width": "290px"
                                                },
                                                model: {
                                                  value: _vm.isEditingDate,
                                                  callback: function($$v) {
                                                    _vm.isEditingDate = $$v
                                                  },
                                                  expression: "isEditingDate"
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "input input-date go-to-week-input",
                                                  attrs: {
                                                    slot: "activator",
                                                    placeholder: "DD-MM-YYYY",
                                                    label: "Spring naar datum",
                                                    "error-messages":
                                                      _vm.dateErrorMessage,
                                                    "prepend-icon": "today"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.formatDateFromTextField(
                                                        $event
                                                      )
                                                    },
                                                    "click:prepend": function(
                                                      $event
                                                    ) {
                                                      return _vm.goToDate(true)
                                                    }
                                                  },
                                                  slot: "activator",
                                                  model: {
                                                    value: _vm.dateFormatted,
                                                    callback: function($$v) {
                                                      _vm.dateFormatted = $$v
                                                    },
                                                    expression: "dateFormatted"
                                                  }
                                                }),
                                                _c("v-date-picker", {
                                                  ref: "picker",
                                                  attrs: {
                                                    "first-day-of-week": "1",
                                                    locale: "nl-nl",
                                                    min: "1910-01-01",
                                                    color: "#837f16"
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      _vm.isEditingDate = false
                                                      _vm.goToDate()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.selectedDate,
                                                    callback: function($$v) {
                                                      _vm.selectedDate = $$v
                                                    },
                                                    expression: "selectedDate"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    !_vm.isInThePast && !_vm.isServiceLoket
                                      ? _c(
                                          "v-flex",
                                          { staticClass: "flex-starving" },
                                          [
                                            _c("v-layout", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "toolbar__toggle toolbar__toggle--secondary",
                                                  class: {
                                                    selected:
                                                      _vm.selectedToggle &&
                                                      _vm.selectedToggle ===
                                                        "time"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectToggle(
                                                        "time"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("access_time")
                                                  ]),
                                                  _vm._v(" Werktijd aanpassen ")
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "toolbar__toggle toolbar__toggle--free",
                                                  class: {
                                                    selected:
                                                      _vm.selectedToggle &&
                                                      _vm.selectedToggle ===
                                                        "free"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectToggle(
                                                        "free"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("check_circle")
                                                  ]),
                                                  _vm._v(" Beschikbaar ")
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "toolbar__toggle toolbar__toggle--busy",
                                                  class: {
                                                    selected:
                                                      _vm.selectedToggle &&
                                                      _vm.selectedToggle ===
                                                        "busy"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectToggle(
                                                        "busy"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("remove_circle")
                                                  ]),
                                                  _vm._v(" Niet Beschikbaar ")
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "toolbar__toggle toolbar__toggle--internal",
                                                  class: {
                                                    selected:
                                                      _vm.selectedToggle &&
                                                      _vm.selectedToggle ===
                                                        "internal"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectToggle(
                                                        "internal"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("apartment")
                                                  ]),
                                                  _vm._v(" Intern beschikbaar ")
                                                ],
                                                1
                                              ),
                                              _vm.isPlanner
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "toolbar__toggle toolbar__toggle--dashboard",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.isShowingApplyTemplateDialog = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("dashboard")
                                                      ]),
                                                      _vm._v(
                                                        " Template toepassen "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.isPlanner
                                  ? _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          wrap: "",
                                          "justify-space-between": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { shrink: "", "px-3": "" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.departments,
                                                "item-value": "id",
                                                "item-text": "name",
                                                "hide-details": "",
                                                placeholder: "Afdelingen",
                                                clearable: "",
                                                multiple: ""
                                              },
                                              on: {
                                                change: _vm.reloadInitialize
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      var index = ref.index
                                                      return [
                                                        index === 0
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Afdeling (" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .selectedDepartments
                                                                      .length
                                                                  ) +
                                                                  ")"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2431312973
                                              ),
                                              model: {
                                                value: _vm.selectedDepartments,
                                                callback: function($$v) {
                                                  _vm.selectedDepartments = $$v
                                                },
                                                expression:
                                                  "selectedDepartments"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { shrink: "", "px-3": "" } },
                                          [
                                            _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  disabled: _vm.isLoading,
                                                  compact: true,
                                                  outline: true,
                                                  color: "primary",
                                                  icon: "refresh"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.reloadInitialize(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Herlaad beschikbaarheid "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isPlanner || _vm.isServiceLoket
                                  ? _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          "justify-space-between": "",
                                          "pr-3": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs8:
                                                _vm.isPlanner ||
                                                _vm.isServiceLoket,
                                              xs12:
                                                !_vm.isPlanner ||
                                                !_vm.isServiceLoket,
                                              "pa-0": ""
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass:
                                                "searchInput--availability",
                                              attrs: {
                                                value: _vm.query,
                                                "hide-details": true,
                                                "prepend-inner-icon": "search",
                                                placeholder:
                                                  "Zoek een gebruiker",
                                                clearable: ""
                                              },
                                              on: {
                                                input: _vm.debouncedSearch,
                                                "click:clear":
                                                  _vm.debouncedSearch
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.isPlanner
                                          ? _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  "pa-0": "",
                                                  shrink: ""
                                                }
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    right: "",
                                                    label:
                                                      "Toon alleen experts zonder beschikbaarheid",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value: _vm.onlyEmptyExperts,
                                                    callback: function($$v) {
                                                      _vm.onlyEmptyExperts = $$v
                                                    },
                                                    expression:
                                                      "onlyEmptyExperts"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.stickyTableHeader
                                  ? _c(
                                      "table",
                                      {
                                        staticClass:
                                          "item__list item__list--table availability-list availability-list--expert",
                                        attrs: { wrap: "" }
                                      },
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticClass: "label header__row",
                                            attrs: {
                                              wrap: "",
                                              row: "",
                                              "align-center": ""
                                            }
                                          },
                                          [
                                            _vm.isPlanner || _vm.isServiceLoket
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "expert__column",
                                                    attrs: { rowspan: "2" }
                                                  },
                                                  [_vm._v("Expert")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "daypart__column"
                                              },
                                              [_c("span", [_vm._v("Dagdeel")])]
                                            ),
                                            _vm._l(_vm.currentWeek, function(
                                              day
                                            ) {
                                              return _c(
                                                "td",
                                                {
                                                  key: day + "-cell",
                                                  staticClass: "day__cell",
                                                  class: {
                                                    locked: _vm.isLocked(day)
                                                  }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--day"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.toFormat("ccc")
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--date"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.toFormat(
                                                              "dd-LL-yyyy"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.isSearching
                              ? _c(
                                  "v-flex",
                                  [
                                    _c("LoaderCard", {
                                      staticClass: "main-loader",
                                      attrs: {
                                        extraClass: "availability",
                                        type: "dots",
                                        flat: true
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isSearching
                              ? _c(
                                  "v-flex",
                                  { attrs: { "pa-0": "" } },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "item__list item__list--table availability-list availability-list--expert",
                                        class: {
                                          toolbarSelected: _vm.selectedToggle
                                        },
                                        attrs: { wrap: "" },
                                        on: { click: _vm.handleTableClick }
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            {
                                              staticClass: "label header__row",
                                              attrs: {
                                                wrap: "",
                                                row: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _vm.isPlanner ||
                                              _vm.isServiceLoket
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "expert__column",
                                                      attrs: { rowspan: "2" }
                                                    },
                                                    [_vm._v("Expert")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "daypart__column"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Dagdeel")
                                                  ])
                                                ]
                                              ),
                                              _vm._l(_vm.currentWeek, function(
                                                day
                                              ) {
                                                return _c(
                                                  "td",
                                                  {
                                                    key: day + "-cell",
                                                    staticClass: "day__cell",
                                                    class: {
                                                      locked: _vm.isLocked(day)
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--day"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.toFormat("ccc")
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "date__span date__span--date"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            day.toFormat(
                                                              "dd-LL-yyyy"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]),
                                        _vm._l(_vm.experts, function(expert) {
                                          return _c(
                                            "tbody",
                                            {
                                              key: expert.id,
                                              staticClass: "expert-row"
                                            },
                                            [
                                              _c(
                                                "tr",
                                                {
                                                  staticClass: "list__item",
                                                  class: {
                                                    hide: expert.hideInPlanning
                                                  }
                                                },
                                                [
                                                  _vm.isPlanner ||
                                                  _vm.isServiceLoket
                                                    ? _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "expert__cell cursor-pointer",
                                                          attrs: {
                                                            rowspan: "3"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.navigateToUser(
                                                                expert
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          !_vm.hasEvents(expert)
                                                            ? _c("span", {
                                                                staticClass:
                                                                  "no_data"
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "expert-name"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  expert.name
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          expert.level
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "expert-level"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .userLevelLabels[
                                                                          expert
                                                                            .level
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          expert.company_name
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "expert-organization"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        expert.company_name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "daypart__cell daypart__cell--morning"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "daypart__container daypart__container--morning"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Ochtend")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.currentWeek,
                                                    function(day) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key: day + "-cell",
                                                          staticClass:
                                                            "list__input"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container",
                                                              attrs: {
                                                                "data-expert":
                                                                  expert.uuid,
                                                                "data-daypart":
                                                                  "morning",
                                                                "data-day": day.toFormat(
                                                                  "yyyy-LL-dd"
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "availability__indicator",
                                                                  attrs: {
                                                                    "data-expert":
                                                                      expert.uuid,
                                                                    "data-daypart":
                                                                      "morning",
                                                                    "data-day": day.toFormat(
                                                                      "yyyy-LL-dd"
                                                                    ),
                                                                    "data-type":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon v-icon material-icons theme--light",
                                                                      attrs: {
                                                                        "data-v-34be290e":
                                                                          "",
                                                                        "aria-hidden":
                                                                          "true"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " help "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "timestamp"
                                                                  }),
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon v-icon material-icons theme--light note_icon",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " comment "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dots dots--small availability"
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce1"
                                                                  }),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce2"
                                                                  }),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce3"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "tr",
                                                {
                                                  staticClass: "list__item",
                                                  class: {
                                                    hide: expert.hideInPlanning
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "daypart__cell daypart__cell--afternoon"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "daypart__container daypart__container--afternoon"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Middag")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.currentWeek,
                                                    function(day) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key: day + "-cell",
                                                          staticClass:
                                                            "list__input"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "availability--container",
                                                              attrs: {
                                                                "data-expert":
                                                                  expert.uuid,
                                                                "data-daypart":
                                                                  "afternoon",
                                                                "data-day": day.toFormat(
                                                                  "yyyy-LL-dd"
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "availability__indicator",
                                                                  attrs: {
                                                                    "data-expert":
                                                                      expert.uuid,
                                                                    "data-daypart":
                                                                      "afternoon",
                                                                    "data-day": day.toFormat(
                                                                      "yyyy-LL-dd"
                                                                    ),
                                                                    "data-type":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon v-icon material-icons theme--light",
                                                                      attrs: {
                                                                        "data-v-34be290e":
                                                                          "",
                                                                        "aria-hidden":
                                                                          "true"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " help "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "timestamp"
                                                                  }),
                                                                  _c(
                                                                    "i",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon v-icon material-icons theme--light note_icon",
                                                                      attrs: {
                                                                        "aria-hidden":
                                                                          "true"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " comment "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "dots dots--small availability"
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce1"
                                                                  }),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce2"
                                                                  }),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "bounce3"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "tr",
                                                { staticClass: "list__item" },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "daypart__cell"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "daypart__container daypart__container--border-top"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Max afspr.")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.isZaakbegeleider ||
                                                  _vm.isImmaterial
                                                    ? _vm._l(
                                                        _vm.currentWeek,
                                                        function(day) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key:
                                                                day +
                                                                "-appointment-limit",
                                                              staticClass:
                                                                "list__appointment"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "availability--container px-2 appointment-container",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.showAppointmentTypeDialog(
                                                                        expert,
                                                                        day
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.findValueAppointmentLimit(
                                                                          expert,
                                                                          day
                                                                        )
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        size:
                                                                          "20"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "create"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  !(
                                                    _vm.isZaakbegeleider ||
                                                    _vm.isImmaterial
                                                  )
                                                    ? _vm._l(
                                                        _vm.currentWeek,
                                                        function(day) {
                                                          return _c(
                                                            "td",
                                                            {
                                                              key:
                                                                day +
                                                                "-appointment-limit",
                                                              staticClass:
                                                                "list__appointment"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  ref:
                                                                    "appointment__limit--" +
                                                                    expert.uuid,
                                                                  refInFor: true,
                                                                  staticClass:
                                                                    "pa-0 ma-0 appointment__limit",
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Aantal",
                                                                    type:
                                                                      "number",
                                                                    clearable:
                                                                      "",
                                                                    "hide-details":
                                                                      "",
                                                                    min: 0,
                                                                    value: _vm.getAppointmentLimit(
                                                                      expert,
                                                                      day
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateAppointmentLimit(
                                                                        $event,
                                                                        expert.uuid,
                                                                        day
                                                                      )
                                                                    },
                                                                    "click:clear": function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateAppointmentLimit(
                                                                        null,
                                                                        expert.uuid,
                                                                        day
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm.isPlanner || _vm.isServiceLoket
                                      ? _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              wrap: "",
                                              "px-4": "",
                                              "py-3": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-space-between":
                                                        "",
                                                      wrap: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "flex-starving"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(_vm.from) +
                                                              " - " +
                                                              _vm._s(_vm.to) +
                                                              " van " +
                                                              _vm._s(_vm.total)
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "flex-starving"
                                                      },
                                                      [
                                                        _c("v-pagination", {
                                                          attrs: {
                                                            length:
                                                              _vm.lastPage,
                                                            "total-visible": 7
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.paginationDebounce
                                                          },
                                                          model: {
                                                            value: _vm.page,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.page = $$v
                                                            },
                                                            expression: "page"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.experts || !_vm.experts.length
                                      ? [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    "pa-3": "",
                                                    "mb-3": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "fadedText"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Geen planbare gebruikers beschikbaar."
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.isShowingApplyTemplateDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw" },
              model: {
                value: _vm.isShowingApplyTemplateDialog,
                callback: function($$v) {
                  _vm.isShowingApplyTemplateDialog = $$v
                },
                expression: "isShowingApplyTemplateDialog"
              }
            },
            [
              _c("ApplyTemplateDialog", {
                attrs: {
                  readOnly: _vm.readOnly,
                  users: _vm.experts,
                  organization: _vm.organization,
                  startDate: _vm.startDate
                },
                on: { initialize: _vm.reloadInitialize },
                model: {
                  value: _vm.isShowingApplyTemplateDialog,
                  callback: function($$v) {
                    _vm.isShowingApplyTemplateDialog = $$v
                  },
                  expression: "isShowingApplyTemplateDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isShowingEventNoteDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw" },
              model: {
                value: _vm.isShowingEventNoteDialog,
                callback: function($$v) {
                  _vm.isShowingEventNoteDialog = $$v
                },
                expression: "isShowingEventNoteDialog"
              }
            },
            [
              _c("event-note-dialog", {
                attrs: { event: _vm.selectedEvent },
                on: { eventUpdated: _vm.handleEventUpdated },
                model: {
                  value: _vm.isShowingEventNoteDialog,
                  callback: function($$v) {
                    _vm.isShowingEventNoteDialog = $$v
                  },
                  expression: "isShowingEventNoteDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isShowingEditWorkHoursDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.isShowingEditWorkHoursDialog,
                callback: function($$v) {
                  _vm.isShowingEditWorkHoursDialog = $$v
                },
                expression: "isShowingEditWorkHoursDialog"
              }
            },
            [
              _c("WorkHoursDialog", {
                attrs: { event: _vm.selectedEvent },
                on: { workHourUpdated: _vm.handleWorkHourUpdated },
                model: {
                  value: _vm.isShowingEditWorkHoursDialog,
                  callback: function($$v) {
                    _vm.isShowingEditWorkHoursDialog = $$v
                  },
                  expression: "isShowingEditWorkHoursDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplayingAppointmentTypeDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "800" },
              model: {
                value: _vm.isDisplayingAppointmentTypeDialog,
                callback: function($$v) {
                  _vm.isDisplayingAppointmentTypeDialog = $$v
                },
                expression: "isDisplayingAppointmentTypeDialog"
              }
            },
            [
              _c("AppointmentTypeDialog", {
                attrs: {
                  user: _vm.appointmentType.user,
                  date: _vm.appointmentType.date,
                  max: _vm.appointmentType.max,
                  appointmentLimit: _vm.appointmentType.appointmentLimit,
                  settings: _vm.settings
                },
                on: {
                  updateMaxAppointmentTypeCount:
                    _vm.updateMaxAppointmentTypeCount
                },
                model: {
                  value: _vm.isDisplayingAppointmentTypeDialog,
                  callback: function($$v) {
                    _vm.isDisplayingAppointmentTypeDialog = $$v
                  },
                  expression: "isDisplayingAppointmentTypeDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }