import { Model } from '@/models/Model';

export class Experience extends Model {
  protected $name = 'Experience';

  protected $endpoint = '/experiences';

  protected $fillable: string[] = [
    'title',
    'description',
    'completed',
    'starts_at',
    'ends_at',
    'type',
    'user',
    'experience',
    'is_verified',
  ];

  public id?: string;

  public type?: string;

  public created_at?: string;

  public updated_at?: string;

  public user_id?: string;

  public completed?: boolean;

  public title ?: string;

  public description?: string;

  public experience?: string;

  public starts_at?: string;

  public ends_at?: string;

  public verified_by?: string;

  public verified_at?: string;

  public is_verified?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export interface ExperiencePayload {
  user?: string;
  type?: string;
  completed?: boolean;
  title?: string;
  experience?: string;
  description?: string;
  starts_at?: string;
  ends_at?: string;
}

export enum ExperienceType {
  SCHOOL = 'school',
  WORK = 'work',
  SIDE_ACTIVITY = 'side_activity',
  SPECIAL_EXPERTISE = 'special_expertise',
}
