var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "atabix-button",
        class: {
          "atabix-button--alternative": _vm.isAlternative,
          "atabix-button--disabled": _vm.isDisabled,
          "atabix-button--fab": _vm.isFloatingActionButton
        },
        on: _vm._d({}, [_vm.clickEventName, _vm.executeClick])
      },
      _vm.getChildListenersExcept([], ["click"])
    ),
    [
      _vm.hasHref
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.$attrs.href,
                target: _vm.$attrs.target ? _vm.$attrs.target : "_self"
              }
            },
            [
              _c(
                "AButtonContent",
                _vm._b(
                  { staticClass: "atabix-button__content" },
                  "AButtonContent",
                  _vm.$attrs,
                  false
                ),
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        : _vm.hasRouterLink
        ? _c(
            "router-link",
            {
              attrs: {
                to: _vm.$attrs.to,
                target: _vm.$attrs.target ? _vm.$attrs.target : "_self"
              }
            },
            [
              _c(
                "AButtonContent",
                _vm._b(
                  { staticClass: "atabix-button__content" },
                  "AButtonContent",
                  _vm.$attrs,
                  false
                ),
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        : [
            _c(
              "AButtonContent",
              _vm._b(
                { staticClass: "atabix-button__content" },
                "AButtonContent",
                _vm.$attrs,
                false
              ),
              [_vm._t("default")],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }