import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Report } from '@/models/Report';
import { getUserTypeName, User, userRoles } from '@/models/User';
import { getStatusLabel } from '@/support/ReportStatus';
import { OpnameVariantLabels } from '@/items/OpnameVariant';
import OpnameVariantFilter from '../filters/opname-variant-filter/OpnameVariantFilter';

@Component<MiGlobalSearch>({})
export default class MiGlobalSearch extends Vue {
  @Model('isLoading', { default: false })
  isLoading!: boolean;

  @Prop({ default: '' })
  protected icon!: string;

  @Prop({ default: false })
  protected disabled!: boolean;

  protected items: any[] = [];

  protected selected: any = null;

  protected search = '';

  protected isFocused = false;

  protected searchDebounce: Function = this.handleSearch();

  protected reports: Report[] = [];

  protected users: User[] = [];

  get user(): User {
    return this.$store.state.Auth;
  }

  protected selectedItems: SearchOption[] = [];

  protected mounted() {
    //
  }

  protected getReports(searchInput: string) {
    new Report()
      .filter('search', searchInput)
      .include(['organization'])
      .limit(4)
      .all()
      .then((reports: Report[]) => {
        this.reports = reports;
        if (this.user.hasRole(userRoles.AdminRoles)) {
          this.getUsers(searchInput);
        } else {
          this.combineUsersAndReports();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getUsers(searchInput: string) {
    new User()
      .filter('search', searchInput)
      .all()
      .then((users: User[]) => {
        (this.selectedItems as any) = users;
        this.users = users;
        this.combineUsersAndReports();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected combineUsersAndReports() {
    this.selectedItems = [];
    this.reports.forEach((report: Report) => {
      const opnameVariant = OpnameVariantLabels[`${report.opname_variant}`];
      const subText1 = `${report.organization ? report.organization.name : 'IMG'} | ${getStatusLabel(report.status ? report.status : '')}`;
      const subText2 = `${report.type ? report.type.name : ''} | ${opnameVariant}`;

      this.selectedItems.push({
        uuid: report.uuid,
        name: report.case_number,
        sub1: subText1,
        sub2: subText2,
        type: 'reports',
      });
    });

    this.users.forEach((user: User) => {
      this.selectedItems.push({
        uuid: user.uuid,
        name: user.name,
        sub1: `${getUserTypeName(user.type ? user.type : '')}`,
        sub2: `${user.email}`,
        type: 'users',
      });
    });
  }

  protected getItemType(uuid: string) {
    const index = this.selectedItems.findIndex((item: any) => item.uuid === uuid);
    if (this.selectedItems[index] && this.selectedItems[index].type === 'reports') {
      return 'reports';
    }
    return 'users';
  }

  protected handleSearch() {
    return debounce((searchInput: string) => {
      this.getReports(searchInput);
    }, 400);
  }

  protected reset() {
    this.items = [];
    this.search = '';
    this.isFocused = false;
  }

  protected goToSearchPage() {
    if (this.$refs.autocomplete) {
      (this.$refs.autocomplete as any).isMenuActive = false;
    }

    this.isFocused = false;
    this.$router.push(`/search?search=${this.search}`);
  }

  @Watch('search')
  protected searchInputChanged(search: string) {
    if (search) {
      this.searchDebounce(search);
    }
  }

    @Watch('selected')
  protected selectedChanged(selected: any) {
    if (selected) {
      window.location.href = `${window.location.origin}/${this.getItemType(selected)}/${selected}`;
    }
  }
}

interface SearchOption {
  uuid?: string;
  name?: string;
  sub1?: string;
  sub2?: string;
  type?: string;
}
