import { Model } from '@/models/Model';

export class DefaultAnswer extends Model {
  protected $name = 'DefaultAnswer';

  protected $endpoint = '/default-answers';

  protected $fillable: string[] = [
    'title',
    'instruction',
    'text',
  ];

  public text?: string;

  public instruction?: string;

  public question_key?: string;

  public is_rich_text?: boolean;

  public title?: string;

  public id?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export interface UpdateDefaultAnswerPayload {
  title?: string;
  instruction?: string;
  text?: string;
  is_rich_text?: boolean;
  question?: string;
}

export interface CreateDefaultAnswerPayload {
  title?: string;
  instruction?: string;
  text?: string;
  is_rich_text?: boolean;
  question?: string;
}
