import Vue from 'vue';
import { currency, formatDate } from '@/support/String';

Vue.filter('currency', (value: any) => currency(value, 'EUR'));

Vue.filter('hours', (value: any) => new Intl.NumberFormat('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(value));

Vue.filter('quantity', (value: any) => new Intl.NumberFormat('nl-NL', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}).format(value));

Vue.filter('dateFormatTime', (date: string) => {
  if (date === '-') {
    return '-';
  }

  if (date === '0000-00-00 00:00:00') {
    return '-';
  }

  if (! date) {
    return '';
  }

  return formatDate(date, 'dd-LL-yyyy HH:mm');
});

Vue.filter('formatTime', (date: string) => {
  if (date === '-') {
    return '-';
  }

  if (date === '0000-00-00 00:00:00') {
    return '-';
  }

  if (! date) {
    return '';
  }

  return formatDate(date, 'HH:mm');
});

Vue.filter('dateFormat', (date: string) => {
  if (date === '-') {
    return '-';
  }

  if (date === '0000-00-00 00:00:00') {
    return '-';
  }

  if (! date) {
    return '';
  }

  return formatDate(date);
});

Vue.filter('concatenateString', (value: string, amount = 20) => {
  if (value.length >= amount) {
    return `${value.slice(0, amount)}...`;
  }
  return value;
});

Vue.filter('weekdayFormat', (date: string) => {
  if (date === '-') {
    return '-';
  }

  if (date === '0000-00-00 00:00:00') {
    return '-';
  }

  if (! date) {
    return '';
  }

  return formatDate(date, 'cccc');
});
