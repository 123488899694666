import { VesStatusEnum } from '@/items/VesStatus';
import { RejectReason } from '@/models/RejectReason';
import { ReportType } from '@/models/ReportType';
import { ReportStatus } from '@/support/ReportStatus';
import { Project } from '@/models/Project';
import { Organization } from '@/models/Organization';
import { Model } from '@/models/Model';
import { formatDateSeconds } from '@/support/String';
import { Repair } from '@/models/Repair';
import { ReportTypes } from '@/support/ReportTypes';
import { LateRequest } from '@/models/LateRequest';
import { ReportLog } from '@/models/ReportLog';
import { Skill } from '@/models/Skill';
import { DateTime } from 'luxon';
import { Aanvrager } from '@/models/Aanvrager';
import { OpnameVariantLabels } from '@/items/OpnameVariant';

/**
 *
 * Types aan
 */

export class Report extends Model {
  protected $name = 'Report';

  protected $endpoint = '/reports';

  public $availableIncludes = [];

  protected $fillable: string[] = [
    'address',
    'approved_at',
    'report_handler',
    'calculation_model',
    'calculation_model_vat_low',
    'planning_workflow',
    'case_number',
    'created_at',
    'damage_count',
    'deadline_at',
    'expected_delivery_at',
    'expert',
    'has_been_img_rejected',
    'has_been_submitted',
    'has_ebs',
    'is_prio',
    'is_special',
    'is_late',
    'is_offline',
    'is_prio',
    'is_special',
    'is_zetting_zakking',
    'is_test',
    'last_update_user',
    'late_reason',
    'late_response_at',
    'links',
    'offline_at',
    'offline_device',
    'offline_user',
    'online_at',
    'planned_at',
    'last_appointment_at',
    'pre_controlled_at',
    'pre_controller',
    'requires_nulmeting',
    'second_expert',
    'status',
    'submitted_at',
    'tags',
    'technisch_coordinator',
    'type',
    'late_requests',
    'applicant_submitted_at',
    'level',
    'applicant',
    'intermediary',

    'stats_doorlooptijd_werkvoorbereiding',
    'stats_doorlooptijd_planning',
    'stats_doorlooptijd_bureau',
    'stats_doorlooptijd_veldwerk',
    'stats_first_time_right_tcmg',
    'stats_first_time_right_bureau',
    'stats_costs',

    'workflow',
    'management_info_class',
  ];

  public uuid!: string;

  public id?: string;

  public address?: Address;

  public departure_address?: Address;

  public approved_at?: string;

  public applicant_submissions?: number;

  public calculation_model?: CalculationModel;

  public calculation_model_vat_low?: number;

  public planning_workflow?: string;

  public case_number?: string;

  public opname_variant?: string;

  public payout_total?: number;

  public created_at?: string;

  public damage_count?: number;

  public damages?: Damage[];

  public deadline_at?: string;

  public expected_delivery_at?: string;

  public expert?: User;

  public organization_expert?: User;

  public report_handler?: User;

  public mediator?: User;

  public has_been_img_rejected?: boolean;

  public has_been_submitted?: boolean;

  public has_been_tc_rejected?: boolean;

  public has_ebs?: boolean;

  public is_prio?: boolean;

  public is_special?: boolean;

  public is_zetting_zakking?: boolean;

  public is_late?: boolean;

  public is_test?: boolean;

  public is_syncing?: boolean;

  public is_offline?: boolean;

  public is_final?: boolean;

  public last_update_user?: User;

  public late_reason?: string;

  public late_response_at?: string;

  public links?: any;

  public media?: Media[];

  public offline_at?: string;

  public offline_device?: string;

  public offline_user?: any | null;

  public jz_user?: User;

  public online_at?: string;

  public planned_at?: string;

  public last_appointment_at?: string;

  public planning_status?: string;

  public pre_controlled_at?: string;

  public pre_controller?: User;

  public reason?: RejectReason[];

  public requires_nulmeting?: boolean;

  public reject_comment?: string;

  public problem_key?: string;

  public reject_reasons?: RejectReason[];

  public second_expert?: User;

  public status?: string;

  public status_comment?: string;

  public submitted_at?: string;

  public tags?: Tag[];

  public technisch_coordinator?: string | User | null;

  public type?: ReportType;

  public last_late_request_status?: string;

  public last_updated_by_user_at?: string;

  public total_repairs?: TotalRepairs;

  public risk_profile?: RiskProfile;

  public job_project?: Project;

  public updated_at?: string;

  public uploaded_at?: string;

  public error?: string;

  public answers?: Answer[];

  public requested_deadline_reason?: string;

  public requested_deadline_at?: string;

  public late_requests?: LateRequest[];

  public logs?: ReportLog[];

  public submit_count?: number;

  public applicant_submitted_at?: string;

  public applicant?: Aanvrager;

  public intermediary?: Aanvrager;

  public level?: string;

  public skills?: Skill[];

  public statistics?: StatisticsInfo[];

  public organization_tc?: User;

  public organization?: Organization;

  public internal_reject_comment?: string;

  public finance?: any;

  public dates?: any;

  public archive_info?: ArchiveInfo;

  public workflow?: string;

  public management_info_class?: string;

  public city?: string;

  public pgv_1pct?: number;

  public ves_status?: VesStatusEnum;

  // First time right
  public stats_first_time_right_tcmg?: boolean;

  public stats_first_time_right_bureau?: boolean;

  public stats_first_time_right_tcmg_reason?: string;

  public stats_first_time_right_bureau_reason?: string;

  public stats_first_time_right_tcmg_reasons?: string[];

  public stats_first_time_right_bureau_reasons?: string;

  public stats_first_time_right_tcmg_comment?: string;

  public stats_first_time_right_bureau_comment?: string;

  public stats_deadline_in_days?: number;

  public stats_applicant_deadline_in_days?: number;

  public stats_first_time_right_confirmed?: string;

  public pgv_report?: PgvReport;

  public has_damage_anomaly?: boolean;

  public objection?: { is_hearing?: boolean }

  // TODO: set interface
  public contacts?: any[];

  public appointments?: any[];

  public getId(): string | null {
    if (this.id) {
      return this.id;
    }

    if (this.uuid) {
      return this.uuid;
    }

    return null;
  }

  public get isClosed() {
    return this.hasStatus([ReportStatus.DOWNLOADED, ReportStatus.ZIENSWIJZE_CLOSED, ReportStatus.OBJECTION_CLOSED, ReportStatus.SMR_CLOSED]);
  }

  public get isOwnedByBureau() {
    return this.hasStatus([
      // REGULIER - (Externe) opnemer
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      // REGULIER - Bureau
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      ReportStatus.SUPERVISOR,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
      // NADER ADVIES - Bureau
      ReportStatus.ZIENSWIJZE_IN_PROGRESS,
      ReportStatus.ZIENSWIJZE_REJECTED,
      ReportStatus.ZIENSWIJZE_CHECKING,
      ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,
      // BEZWAAR - Bureau
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
      // STUWMEER - Bureau
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
      ReportStatus.SMR_CHECKING,
      ReportStatus.SMR_VELDWERK_REJECTED,
    ]);
  }

  public get isOwnedByBureauRegulier() {
    return this.hasStatus([
      // REGULIER - (Externe) opnemer
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      // REGULIER - Bureau
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      ReportStatus.SUPERVISOR,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
    ]);
  }

  public get isOwnedByBureauSMR() {
    return this.hasStatus([
      // STUWMEER - Bureau
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
      ReportStatus.SMR_CHECKING,
      ReportStatus.SMR_VELDWERK_REJECTED,
    ]);
  }

  public get isEditableByExpert() {
    return this.hasStatus([
      // REGULIER - (Externe) opnemer
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      // REGULIER - Bureau
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      // NADER ADVIES - Bureau
      ReportStatus.ZIENSWIJZE_IN_PROGRESS,
      ReportStatus.ZIENSWIJZE_REJECTED,
      // BEZWAAR - Bureau
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      // STUWMEER - Bureau
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
    ]);
  }

  public get isOwnedBySchadeBepaling() {
    return this.hasStatus([
      // REGULIER - (Externe) opnemer
      ReportStatus.STRUCTURAL_ENGINEER_CHECKING,
      ReportStatus.APPROVED,
      ReportStatus.VELDWERK_CORRECTED,
      ReportStatus.VELDWERK_ONHOLD,
      // NADER ADVIES - Bureau
      ReportStatus.ZIENSWIJZE_APPROVED,
      ReportStatus.ZIENSWIJZE_CORRECTED,
      // BEZWAAR - Bureau
      ReportStatus.OBJECTION_APPROVED,
      ReportStatus.OBJECTION_CORRECTED,
      ReportStatus.OBJECTION_PENDING_CLOSE,
      // STUWMEER - Bureau
      ReportStatus.SMR_APPROVED,
      ReportStatus.SMR_NO_VISIT,
      ReportStatus.SMR_CLOSED,
    ]);
  }

  protected get parsedOpnameVariant() {
    return this.opname_variant ? OpnameVariantLabels[this.opname_variant] : '';
  }

  public get bouwjaar() {
    const bouwjaar = this.printAnswer('BouwJaar');

    return bouwjaar.substring(0, 4);
  }

  public get isProblem() {
    return this.hasStatus([ReportStatus.PROBLEM_TCMG]);
  }

  public setReportId(report: Model | string) {
    this.$endpoint = `/reports/${report}`;

    return this;
  }

  public get formattedCreatedAt() {
    if (this.created_at) {
      return formatDateSeconds(this.created_at) || '';
    }
    return '';
  }

  public set formattedCreatedAt(date: string) {
    this.created_at = `${date}:00`;
  }

  public get formattedPlannedAt() {
    if (this.planned_at) {
      return formatDateSeconds(this.planned_at) || '';
    }
    return '';
  }

  public set formattedPlannedAt(date: string) {
    this.planned_at = `${date}:00`;
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public assessmentExportUrl(id: string): string {
    return `v1/reports/${id}/assessment-export`;
  }

  public convertType(id?: string, attributes?: object): Promise<any> {
    const url = `${this.$endpoint}/${id}/convert`;

    return this.request('post', url, attributes);
  }

  public append(id?: string) {
    const url = `${this.$endpoint}/${id}/append`;

    return this.request('post', url, {});
  }

  public deleteReport(id?: string, payload?: object) {
    const url = `${this.$endpoint}/${id}`;

    return this.request('delete', url, payload);
  }

  public convertTags(id?: string, attributes?: object) {
    const url = `${this.$endpoint}/${id}/tags`;

    return this.request('post', url, attributes);
  }

  public mediaEndpoint(id: string) {
    this.$endpoint = `reports/${id}/media`;

    return this;
  }

  public setBasedOnEndpoint(id: string) {
    this.$endpoint = `reports/${id}/based-on`;

    return this;
  }

  public setTrilingstoolRealtimeEndpoint(id: string) {
    this.$endpoint = `reports/${id}/realtime-trillingstool`;
    return this;
  }

  public setTrillingsToolForCoordinates(latitude: number | null, longitude: number | null) {
    this.$endpoint = `trillingstool/coordinates?latitude=${latitude}&longitude=${longitude}`;
    return this;
  }

  public listMedia(payload: any): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/media`;

    return this.request('get', url, payload, false);
  }

  public deleteMediaEndpoint(id: string): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/media/${id}`;

    return this.request('delete', url);
  }

  public updateMediaEndpoint(id: string, attributes?: object): Promise<any> {
    const url = `${this.$endpoint}${this.resolvePrimaryKey().length ? `/${this.resolvePrimaryKey()}` : ''}/media/${id}`;

    return this.request('patch', url, this.getPayload(attributes));
  }

  public getTrillingstoolPdf(payload: any): Promise<any> {
    const url = `${this.$endpoint}/${payload.report}/trillingstool`;

    return this.request('get', url, payload, false);
  }

  public getSkills(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('get', url);
  }

  public AddSkills(id: any, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('put', url, payload);
  }

  public pingReport(id: string): Promise<any> {
    const url = `${this.$endpoint}/${id}/ping`;

    return this.request('post', url);
  }

  public getAnswer(questionKey: string): Answer | undefined {
    if (! this.answers) {
      return;
    }

    return this.answers.find((answer: Answer) => answer.key === questionKey);
  }

  public printAnswer(questionKey: string): string {
    const reportAnswer = this.getAnswer(questionKey);

    return reportAnswer ? (reportAnswer.value as string) : '';
  }

  public googleMapUrl() {
    if (! this.address) {
      return 'https://www.google.nl/maps/';
    }

    return `https://maps.google.nl/maps?q=${this.address.street}%20${this.address.number}%20${this.address.city}`;
  }

  public get encodedReportAddress() {
    return encodeURI(`${this.address?.street} ${this.address?.number} ${this.address?.number_add ? this.address?.number_add : ''},  ${this.address?.postcode} ${this.address?.city}`);
  }

  private isType(typeEnum: ReportTypes): boolean {
    if (! this.type || ! this.type.uuid) {
      return false;
    }

    return this.type.uuid === typeEnum;
  }

  private isAnyType(typeEnums: ReportTypes[]): boolean {
    const typeId = this.type?.uuid;
    if (! typeId) {
      return false;
    }

    return typeEnums.includes(typeId as ReportTypes);
  }

  public hasStatus(status: string | string[]) {
    if (this.status === undefined) {
      return false;
    }

    if (! Array.isArray(status)) {
      status = [status];
    }

    return status.includes(this.status);
  }

  public hasPlanningStatus(status: string | string[]) {
    if (this.planning_status === undefined) {
      return false;
    }

    if (! Array.isArray(status)) {
      status = [status];
    }

    return status.includes(this.planning_status);
  }

  public hasVesStatus(status: VesStatusEnum | VesStatusEnum[]) {
    if (this.ves_status === undefined) {
      return false;
    }

    if (! Array.isArray(status)) {
      status = [status];
    }

    return status.includes(this.ves_status);
  }

  public isOpen(id: string): boolean {
    if (this.last_update_user?.uuid === id) {
      return false;
    }

    if (this.last_updated_by_user_at) {
      const then = DateTime.fromSQL(this.last_updated_by_user_at);
      const diff = DateTime.local()
        .diff(then, 'minutes')
        .toObject();

      if (! diff || ! diff.minutes) {
        return false;
      }

      if (diff.minutes > 120) {
        return false;
      }
    }

    return true;
  }

  public isOffline(): boolean {
    if (this.is_offline) {
      return true;
    }

    return false;
  }

  public get isPre2021ReportTypes(): boolean {
    return this.isWoningCooperatie1Report
    || this.isWoningCooperatie1ReportNew
    || this.isWoningCooperatie2ReportNew
    || this.isWoningCooperatie2Report
    || this.isCWCat1Report
    || this.isCWCat2Report
    || this.isCWCat3Report
    || this.isCWCat4Report
    || this.isAannemersReport
    || this.isEigenAannemerReport
    || this.isBezwaarReport
    || this.isEerderBestaandeSchadeReport
    || this.isEnkelvoudigReport
    || this.isRegulierReport
    || this.isStuwmeerRegelingReport
    || this.isOpnameVanAfstandReport
    || this.isOpnemerReport
    || this.isZelfopnameReport;
  }

  public get isPre2021CalculationModel(): boolean {
    return [
      '8c3fb664-6064-4401-8dbe-eeb47680e0a5', // v1
      '8c6e3f4f-047d-4ea4-9f34-8db31c164370', // v2
      '8c8d900a-c030-4510-be14-c8acfb702574', // v3
      '6a841a6e-bfdc-47b7-bc69-5ab104ba64ee', // v4
    ].some((id) => this.calculation_model?.uuid === id);
  }

  public get isWoningCooperatieReport(): boolean {
    return this.isWoningCooperatie1Report || this.isWoningCooperatie2Report;
  }

  public get isWoningCooperatieReportNew(): boolean {
    return this.isWoningCooperatie1ReportNew || this.isWoningCooperatie2ReportNew;
  }

  public get isWoningCooperatie1ReportNew(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_1_NEW);
  }

  public get isWoningCooperatie1Report(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_1);
  }

  public get isWoningCooperatie2ReportNew(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_2_NEW);
  }

  public get isWoningCooperatie2Report(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_2);
  }

  public get isCWCat1Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_1);
  }

  public get isCWCat2Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_2);
  }

  public get isCWCat3Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_3);
  }

  public get isCWCat4Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_4);
  }

  public get isAannemersReport(): boolean {
    return this.isType(ReportTypes.AANNEMERS_VARIANT) || this.isType(ReportTypes.OLD_FORMAT_AANNEMERS_VARIANT);
  }

  public get isEigenAannemerReport(): boolean {
    return this.isType(ReportTypes.EIGEN_AANNEMER);
  }

  public get isBezwaarReport(): boolean {
    return this.isAnyType([
      ReportTypes.ADVIESRAPPORT_BEZWAAR,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK,
      ReportTypes.BEZWAAR_2021,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ]);
  }

  public get canAccessCausalityWizard(): boolean {
    return this.isAnyType([
      ReportTypes.REGULIER_2021,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ]);
  }

  public get canAccessBuildingFeatureWizard(): boolean {
    return this.isAnyType([
      ReportTypes.REGULIER_2021,
      ReportTypes.VES_NULMETING,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ]);
  }

  public get canUseImageRecognition(): boolean {
    return this.isAnyType([
      ReportTypes.REGULIER_2021,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ]);
  }

  public get isBezwaarAdviesCommissieReport(): boolean {
    return this.isType(ReportTypes.BEZWAAR_2021);
  }

  public get isBezwaarAmbtelijkReport(): boolean {
    return this.isType(ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK);
  }

  public get isEerderBestaandeSchadeReport(): boolean {
    return this.isType(ReportTypes.EERDER_BESTAANDE_SCHADE);
  }

  public get isEnkelvoudigReport(): boolean {
    return this.isType(ReportTypes.ENKELVOUDIG);
  }

  public get isRegulierReport(): boolean {
    return this.isType(ReportTypes.REGULIER);
  }

  public get isRegulier2021Report(): boolean {
    return this.isType(ReportTypes.REGULIER_2021);
  }

  public get isUsingRegulier2021Questions(): boolean {
    return this.isAnyType([
      ReportTypes.REGULIER_2021,
      ReportTypes.VES_NULMETING,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ]);
  }

  public get isVesNulmetingReport(): boolean {
    return this.isType(ReportTypes.VES_NULMETING);
  }

  public get isMutatieWoningReport(): boolean {
    return this.case_number ? this.case_number.substring(0, 2).toUpperCase() === 'MW' : false;
  }

  public get isStuwmeerRegelingReport(): boolean {
    return this.isType(ReportTypes.STUWMEERREGELING);
  }

  public get isOpnameVanAfstandReport(): boolean {
    return this.isType(ReportTypes.OPNAMEOPAFSTAND);
  }

  public get isZienswijzeReport(): boolean {
    return this.workflow ? this.workflow === 'nader_advies' : false;
  }

  public get isOpnemerReport(): boolean {
    return this.isType(ReportTypes.OPNEMER);
  }

  public get isZelfopnameReport(): boolean {
    return this.isType(ReportTypes.ZELF_OPNAME) || this.opname_variant === 'zelf_opname';
  }

  public get isReplanning() {
    return this.hasPlanningStatus(['vervolgopname', 'nader_onderzoek', 'aanvullend_onderzoek']) || this.hasStatus(['pending_sb_approval']);
  }

  public get isVoorControle() {
    return this.hasStatus(['submitted', 'checking', 'zienswijze_checking', 'objection_checking', 'smr_checking']);
  }

  public get isPlanningOnHold() {
    return this.hasStatus(['planning_on_hold']);
  }

  public get isImmaterial() {
    return this.type?.uuid === ReportTypes.IMMATERIELESCHADE;
  }

  public get defaultAppointmentType(): string {
    return 'default';
  }

  public get defaultAppointmentTypeZb(): string {
    return 'call';
  }

  public get defaultConfirmEmailToApplicant(): boolean {
    return true;
  }

  public get opnameVariantName(): string {
    if (! this.opname_variant) return 'onbekend';
    return OpnameVariantLabels[this.opname_variant];
  }
}

export const attachmentTypes: { [key: string]: string }[] = [
  {
    label: 'Plattegrond',
    value: 'floorplan',
  },
  {
    label: 'Aanzichtfoto',
    value: 'picture',
  },
  {
    label: 'Bijlage',
    value: 'attachment',
  },
];

export const statusProblems: { [key: string]: string }[] = [
  {
    label: 'Opname kan niet doorgaan en kan niet opnieuw gepland worden',
    value: 'cant_continue',
  },
  {
    label: 'Aanvrager ondanks diverse pogingen niet kunnen bereiken',
    value: 'cannot_contact',
  },
  {
    label: 'Ontbrekende Instructie vanuit IMG',
    value: 'missing_instructions',
  },
  {
    label: 'Op verzoek Zaakbegeleider: on-hold',
    value: 'zaakbegeleider_on_hold',
  },
  {
    label: "In afwachting stukken van de aanvrager (bijv. BTW verklaring, foto's, akte van cessie, etc)",
    value: 'aanvrager_on_hold',
  },
  {
    label: 'In afwachting werkwijze bepalen toerekenbaarheid IEDB dossiers.',
    value: 'waiting_iedb_reports',
  },
];

export const statusProblemsLabel: { [key: string]: string } = {
  cant_continue: 'Opname kan niet doorgaan en kan niet opnieuw gepland worden',
  cannot_contact: 'Aanvrager ondanks diverse pogingen niet kunnen bereiken',
  heropname: 'Heropname',
  vervolgopname: 'In afwachting planning: Vervolgopname',
  nader_onderzoek: 'In afwachting planning: Nader onderzoek',
  aanvullend_onderzoek: 'In afwachting planning: Aanvullend onderzoek',
  missing_instructions: 'Ontbrekende Instructie vanuit IMG',
  zaakbegeleider_on_hold: 'Op verzoek Zaakbegeleider: on-hold',
  aanvrager_on_hold: "In afwachting stukken van de aanvrager (bijv. BTW verklaring, foto's, akte van cessie, etc)",
  waiting_iedb_reports: 'In afwachting werkwijze bepalen toerekenbaarheid IEDB dossiers.',
};

export const runtimeExemptionReasonsLabels: { [key: string]: string } = {
  mutual_attunement: 'Wederzijds afstemming',
  status_correction: 'Correctie foutieve status',
  ict: 'ICT',
  other: 'Overig',
};

export const runtimeExemptionReasons: { [key: string]: string }[] = [
  {
    label: 'Wederzijds afstemming',
    value: 'mutual_attunement',
  },
  {
    label: 'Correctie foutieve status',
    value: 'status_correction',
  },
  {
    label: 'ICT',
    value: 'ict',
  },
  {
    label: 'Overig',
    value: 'other',
  },
];

export interface Address {
  street?: string;
  number?: number;
  number_add?: string;
  postcode?: string;
  city?: string;
  created_at?: string;
  updated_at?: string;
  links?: any;
  uuid?: string;
  distance_kilometers?: number;
  geolocation?: Geolocation;
  name?: string;
}

interface CalculationModel {
  created_at?: string;
  from?: string;
  name?: string;
  till?: string;
  updated_at?: string;
  uuid?: string;
}

interface User {
  business_email?: string;
  company_name?: string;
  created_at?: string;
  email?: string;
  first_name?: string;
  force_password_change?: boolean;
  last_name?: string;
  links?: any;
  mfa_token?: boolean;
  name?: string;
  phone?: string;
  report_name?: string;
  signature?: string;
  type?: string;
  updated_at?: string;
  uuid?: string;
}

export interface Tag {
  created_at?: string;
  id?: string;
  links?: any;
  name?: string;
  updated_at?: string;
}

export interface Type {
  created_at?: string;
  id?: string;
  links?: any;
  name?: string;
  updated_at?: string;
  calculation_model?: CalculationModel;
  elementcluster?: string;
  price_scales?: PriceScales[];
  section?: string;
  subsection?: string;
  unit?: string;
  unitprice?: number;
  uuid?: string;
  vat?: number;
}

export interface Media {
  description?: string;
  file_name?: string;
  mime_type?: string;
  original?: string;
  show_in_report?: string;
  sort_order?: string | number;
  thumb?: string;
  type?: string;
  uuid?: string;
  created_at?: string;
  uploaded_by?: string;
  uploaded_on?: string;
}

interface TotalRepairs {
  gebouw_ex?: number;
  gebouw_ex_vat_high?: number;
  gebouw_ex_vat_low?: number;
  gebouw_ex_vat_none?: number;
  gebouw_in?: number;
  gebouw_vat_high?: number;
  gebouw_vat_low?: number;
  gevolg_ex?: number;
  gevolg_ex_vat_high?: number;
  gevolg_ex_vat_low?: number;
  gevolg_ex_vat_none?: number;
  gevolg_in?: number;
  gevolg_vat_high?: number;
  gevolg_vat_low?: number;
  total_in?: number;
}

interface Damage {
  answers?: DamageAnswers;
  apply_vat?: boolean;
  calculation_percentage?: number;
  created_at?: string;
  links?: any;
  media?: Media[];
  name?: string;
  reject_comment?: string;
  reject_reason?: RejectReason;
  repairs?: Repair[];
  sort_order?: number;
  status?: string;
  updated_at?: string;
  uuid?: string;
}

export interface Answer {
  is_editable?: boolean;
  key?: string;
  links?: any;
  options?: object;
  question_id?: string;
  type?: string;
  uuid?: string;
  value?: string;
  sensitive_building_has_deviated?: boolean;
  sensitive_building_anomaly_reason?: string;
  sensitive_building_anomaly_explanation?: string;
}

interface PriceScales {
  created_at?: string;
  from?: string;
  till?: string;
  unitprice?: number;
  updated_at?: string;
  uuid?: string;
}

interface StatisticsInfo {
  damage_causaal_count?: number;
  damage_not_causaal_count?: number;
  ebs_identiek_nam?: number;
  ebs_identiek_tcmg?: number;
  damage_onbekend?: number;
  damage_count?: number;
}

export interface DamageAnswers {
  CalculationMethod?: string | null;
  DakOfGevel?: string | null;
  EtageSchade?: string | null;
  GevelNummerSchade?: string | null;
  LocatieSchade?: string | null;
  MijnbouwCausaalVerband?: string | null;
  MijnbouwCausaalVerbandToelichting?: string | null;
  MijnbouwOorzaakGevolg?: string | null;
  OmschrijvingOverigeSchade?: string | null;
  OmschrijvingSchadeGebouwWerk?: string | null;
  OmschrijvingSchadeGebouwWerkNieuw?: string | null;
  OorzakenToename?: string | null;
  OorzakenToenameAnders?: string | null;
  OorzakenToenameOnbekend?: string | null;
  OpmerkingExpert?: string | null;
  RuimteAnders?: string | null;
  RuimteSchade?: string | null;
  SchadeHerstelMethodiek?: string | null;
  SchadeKlasse?: string | null;
  SlaapkamerNummer?: string | null;
  WandNummer?: string | null;
  WandPlafondVloer?: string | null;
  damageDate?: string | null;
  damageHasBeenReported?: string | null;
  damageHasRestored?: string | null;
  damageOldReportDossiernummer?: string | null;
  damageOldReportPaginanummer?: string | null;
  damageOldReportSchadenummer?: string | null;
  damageSchadebeperkingsplicht?: string | null;
  damageSchadebeperkingsplichtToelichting?: string | null;
  damageSituationNotRestored?: string | null;
  damageSituationPartiallyRestored?: string | null;
  damageSituationPartiallyRestoredPercentage?: string | null;
  damageSituationPartiallyRestoredPercentageOmschrijving?: string | null;
  damageSituationRestored?: string | null;
  damageVatRuling?: string | null;
  skipAndContinue?: string | null;
  typeOfDamage?: string | null;
}

export interface LastStatusChangeLog {
  changed_from?: string;
  changed_to?: string;
  counterpart_name?: string;
  created_at?: string;
  delivery_status?: string;
  id?: string;
  is_internal?: boolean;
  links?: any;
  organization?: Organization;
  text?: string;
  type?: string;
  updated_at?: string;
  user?: string;
}
export interface RiskProfile {
  gasveld?: string;
  gasveld_6km?: string;
  pgv_1pct?: number;
  pgv_25pct?: number;
  earthquakes?: number;
  earthquakes_accountable?: number;
  map_areas?: RiskProfileMapAreas;
}
export interface RiskProfileMapAreas {
  gasveld: boolean;
  effectgebied: boolean;
  zoutveld: boolean;
  peilgebied: boolean;
  boezem: boolean;
  boezem200m: boolean;
}
export interface PgvReport {
  period_start?: string;
  period_end?: string;
  pgv_50pct?: number;
  pgv_25pct?: number;
  pgv_1pct?: number;
  accountable_earthquakes?: number;
}

interface Geolocation {
  latitude: number;
  longitude: number;
}

interface ArchiveInfo {
  is_archived_for_expert: boolean;
}

export const WorkFlows: WorkFlow[] = [
  {
    name: 'Standaard',
    value: 'standaard',
  },
  {
    name: 'Intermediair',
    value: 'intermediair',
  },
  {
    name: 'Vastgoed',
    value: 'vastgoed',
  },
  {
    name: 'Immaterieel',
    value: 'immaterieel',
  },
];

export interface WorkFlow {
  name?: string;
  value?: string;
}

export enum ReportIncludes {
  ANSWERS = 'answers',
  ANOMALIES = 'anomalies',
  APPLICANT_SUBMISSIONS = 'applicant_submissions',
  APPLICANT = 'applicant',
  ARCHIVE_INFO = 'archive_info',
  DAMAGES = 'damages',
  DEPARTURE_ADDRESS = 'departure_address',
  HAS_DAMAGE_ANOMALY = 'has_damage_anomaly',
  INTERMEDIARY = 'intermediary',
  JOB_PROJECT = 'job_project',
  JZ_USER = 'jz_user',
  LAST_STATUS_CHANGE_LOG = 'last_status_change_log',
  LAST_STATUS_CHANGE = 'last_status_change',
  LOGS = 'logs',
  MEDIA = 'media',
  ORGANIZATION = 'organization',
  PGV_REPORT = 'pgv_report',
  PICTURES = 'pictures',
  PLANNING_SPECIALS = 'planning_specials',
  PROJECTS = 'projects',
  REJECT_REASONS = 'reject_reasons',
  RISK_PROFILE = 'risk_profile',
  STATISTICS = 'statistics',
  STATUS_TRANSITIONS = 'status_transitions',
  TOTAL_REPAIRS = 'total_repairs',
  VALIDATIONS = 'validations',
  OBJECTION = 'objection',
}

export enum ManagementInfoClassEnum {
  STANDAARD = 'standaard',
  STANDAARD_GROOT = 'standaard_groot',
  NADER_ADVIES = 'nader_advies',
  SPECIAL = 'special',
  BEZWAAR = 'bezwaar',
  WOCOV1 = 'wocov1',
  WOCOV2 = 'wocov2',
  VES = 'ves',
}

export enum ObjectionTypeEnum {
  ASSESSMENT = 'assessment',
  ADDENDUM = 'addendum',
  HEARING = 'hearing',
  CALCULATION = 'calculation',
}

export const ObjectionTypeLabels: Record<ObjectionTypeEnum, string> = {
  [ObjectionTypeEnum.ASSESSMENT]: 'Met opname',
  [ObjectionTypeEnum.ADDENDUM]: 'Zonder opname',
  [ObjectionTypeEnum.HEARING]: 'Hoorzitting',
  [ObjectionTypeEnum.CALCULATION]: 'Calculatie',
};
