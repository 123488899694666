var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            class: _vm.cssClass,
            attrs: {
              items: _vm.items,
              "item-text": "name",
              "item-value": "value",
              "hide-details": "",
              placeholder:
                _vm.$attrs.placeholder !== undefined
                  ? _vm.$attrs.placeholder
                  : "Workflow",
              clearable: "",
              multiple: ""
            },
            on: { change: _vm.handleChange },
            scopedSlots: _vm._u(
              [
                _vm.canSelectAll
                  ? {
                      key: "prepend-item",
                      fn: function() {
                        return [
                          _c(
                            "v-list-tile",
                            [
                              _c("v-checkbox", {
                                attrs: { color: "primary", "hide-details": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("span", { staticClass: "pl-4" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectAll
                                                    ? "(deselecteer alles)"
                                                    : "(selecteer alles)"
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  953812867
                                ),
                                model: {
                                  value: _vm.selectAll,
                                  callback: function($$v) {
                                    _vm.selectAll = $$v
                                  },
                                  expression: "selectAll"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      index === 0
                        ? [
                            _c("span", [_vm._v("Workflow ")]),
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" + _vm._s(_vm.selectedItems.length) + ")"
                              )
                            ])
                          ]
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedItems,
              callback: function($$v) {
                _vm.selectedItems = $$v
              },
              expression: "selectedItems"
            }
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }