import QuestionaireDialogConclusionType, { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { Damage } from '@/models/Damage';
import { Question } from '@/models/Question';
import { Report, Answer } from '@/models/Report';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<QuestionConclusion>({})
export default class QuestionConclusion extends Vue {
  // #region @Props
  @Prop({ required: true })
  protected result!: QuestionaireDialogConclusionTypeEnum;

  @Prop()
  protected question!: Question;

  @Prop({ default: false })
  protected buttonDisabled!: boolean;

  @Prop({ default: false })
  protected isDeviation!: boolean;

  @Prop({ default: '' })
  protected backgroundColor!: string;
  // #endregion

  protected get resultIcon() {
    const icon = QuestionaireDialogConclusionType.icons[this.result];

    return icon || 'help';
  }

  protected get resultBackgroundColor() {
    if (this.backgroundColor) {
      // override background
      return this.backgroundColor;
    }

    switch (this.result) {
      case QuestionaireDialogConclusionTypeEnum.SUCCESS:
        return 'tw-bg-success-700';
      case QuestionaireDialogConclusionTypeEnum.ERROR:
        return 'tw-bg-error-600';
      case QuestionaireDialogConclusionTypeEnum.INCOMPLETE:
      default:
        return 'tw-bg-gray-600';
    }
  }

  protected get classificationButtonColors() {
    const buttonColor = QuestionaireDialogConclusionType.buttonColors[this.result];

    return buttonColor || 'primary';
  }

  protected deleteAnswer() {
    this.$emit('removeAnswer', this.question, '');
  }
}
