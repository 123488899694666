var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-block" },
    [
      _vm.foundQuestion.answer && _vm.foundQuestion.answer.value
        ? [
            _c("p", { staticClass: "tw-text-2xl" }, [
              _vm._v(
                " Deze vraag is bij gebouwkenmerken als volgt beantwoord: "
              ),
              _c("br"),
              _c("br"),
              _c("i", [_vm._v(_vm._s(_vm.foundQuestion.label))]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.foundQuestion.answer.value))]),
              _c("br"),
              _c("br"),
              _vm._v(" wil je dit antwoord overnemen voor de huidge vraag? ")
            ]),
            _c(
              "div",
              { staticClass: "tw-flex tw-flex-row tw-mt-16" },
              [
                _c(
                  "MiButton",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.answerCurrentQuestion(
                          _vm.question,
                          _vm.foundQuestion.answer.value
                        )
                      }
                    }
                  },
                  [_vm._v(" antwoord overnemen ")]
                )
              ],
              1
            )
          ]
        : [
            _c("p", { staticClass: "tw-text-2xl" }, [
              _vm._v(" Beantwoord eerst deze vraag bij gebouwkenmerken: "),
              _c("br"),
              _c("i", [_vm._v(_vm._s(_vm.foundQuestion.label))])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }