import { Component, Vue, Prop } from 'vue-property-decorator';
import { AMessageDialogType } from '@/support/ADialog';

@Component<AMessageDialogIcon>({})
export default class AMessageDialogIcon extends Vue {
  @Prop({ default: 'none' })
  protected type!: AMessageDialogType | 'none';

  protected get isTypeSuccess(): boolean {
    return this.type === AMessageDialogType.SUCCESS;
  }

  protected get isTypeWarning(): boolean {
    return this.type === AMessageDialogType.WARNING;
  }

  protected get isTypeError(): boolean {
    return this.type === AMessageDialogType.ERROR;
  }
}
