var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-circular-container" },
    [
      _c(
        "v-progress-circular",
        {
          staticClass: "progress-circular--custom",
          attrs: {
            rotate: -90,
            size: _vm.size,
            width: 12,
            color: _vm.widgetColor,
            value: _vm.item.percentage
          }
        },
        [
          _c("h2", { style: "color: " + _vm.widgetColor }, [
            _vm._v(" " + _vm._s(_vm.item.value) + " "),
            _c("span", [_vm._v("/ " + _vm._s(_vm.item.target))])
          ])
        ]
      ),
      _c("h2", { staticClass: "progress-circular__title" }, [
        _vm._v(_vm._s(_vm.widgetLabel))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }