import { Component, Vue, Prop } from 'vue-property-decorator';
import { Breadcrumb as BreadcrumbInterface } from '@/support/Breadcrumb';

@Component<Breadcrumb>({})
export default class Breadcrumb extends Vue {
  @Prop() crumb!: BreadcrumbInterface;

  public mounted() {
    //
  }
}
