import { Model } from '@/models/Model';

export class ReportType extends Model {
  protected $name = 'ReportType';

  protected $endpoint = '/report-types';

  protected $fillable: string[] = [];

  public name?: string;

  public uuid?: string;

  public id?: string;

  public hours?: string;
}
