import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<LoaderCard>({})
export default class LoaderCard extends Vue {
  @Prop({ default: '' })
  protected title!: string;

  @Prop({ default: '' })
  protected minHeight!: string;

  @Prop({ default: 'dots' })
  protected type!: string;

  @Prop({ default: '' })
  protected extraClass!: string;

  @Prop({ default: false })
  protected flat!: boolean;

  public mounted() {
    //
  }
}
