var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { verticalSscrollable: !_vm.first } }, [
    _c(
      "table",
      { staticClass: "tree-container", attrs: { wrap: "" } },
      [
        _vm.first
          ? _c(
              "tr",
              _vm._l(_vm.columns, function(column, columnsIndex) {
                return _c(
                  "th",
                  {
                    key: columnsIndex,
                    class: column.class,
                    style: column.style
                  },
                  [_vm._v(" " + _vm._s(column.name) + " ")]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._l(_vm.items, function(item, index) {
          return [
            _c(
              "tr",
              { key: index, staticClass: "tree-row" },
              _vm._l(_vm.columns, function(column, columnIndex) {
                return _c(
                  "td",
                  {
                    key: columnIndex,
                    staticClass: "row-list",
                    class: column.class,
                    style: column.style
                  },
                  [
                    column.key === "first"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "row__name",
                              class: { hasChildren: item.children },
                              on: {
                                click: function($event) {
                                  item.active = !item.active
                                }
                              }
                            },
                            [
                              item.children && item.children.length
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "row__icon",
                                      class: { opened: item.active }
                                    },
                                    [_vm._v("keyboard_arrow_down")]
                                  )
                                : _vm._e(),
                              !item.link
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.parseItemName(item.name)))
                                  ])
                                : _vm._e(),
                              item.link
                                ? _c("span", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.link,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm.isFunction(column.transform)
                                          ? [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    column.transform(item)
                                                  )
                                                }
                                              })
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.parseItemName(item.name)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      : column.key === "year" && column.visible
                      ? [
                          _c("div", { staticClass: "row__value" }, [
                            _vm._v(_vm._s(item.data["year_sum"][columnIndex]))
                          ])
                        ]
                      : column.visible
                      ? [
                          _c("div", { staticClass: "row__value" }, [
                            column.suffix
                              ? _c("span", [_vm._v(_vm._s(column.suffix))])
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.parseData(item.data[column.key], column)
                                ) +
                                " "
                            ),
                            column.postfix
                              ? _c("span", [_vm._v(_vm._s(column.postfix))])
                              : _vm._e()
                          ])
                        ]
                      : _vm._e(),
                    column.key === "action"
                      ? [
                          column.visible(item)
                            ? _c(
                                "span",
                                {
                                  staticClass: "action-icon",
                                  on: {
                                    click: function($event) {
                                      return column.action(item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v(_vm._s(column.icon))])],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              }),
              0
            ),
            item.children && item.children.length && item.active
              ? _c(
                  "tr",
                  { key: index + "-child", staticClass: "tree-row--child" },
                  [
                    _c(
                      "td",
                      { attrs: { colspan: _vm.columns.length } },
                      [
                        _c("tree-view", {
                          attrs: {
                            options: _vm.options,
                            items: item.children,
                            columns: _vm.columns
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }