import i18n from '@/i18n';
import { values } from 'lodash';

export const validationRules = (translateFunction: Function) => ({
  required: (value: string) => !! value || translateFunction('auth.required.default'),
  name: (value: string) => {
    if (! value) { return true; }
    const pattern = /^[a-zA-Z0-9\-]+$/;
    return pattern.test(value) || translateFunction('auth.required.name');
  },
  url: (value: string) => {
    if (! value) { return true; }
    const pattern = /^[a-zA-Z0-9\-]+$/;
    return pattern.test(value) || translateFunction('auth.required.url');
  },
  email: (value: string) => {
    if (! value) { return true; }
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || translateFunction('auth.required.email');
  },
  number: (value: string) => {
    if (! value) { return true; }
    const pattern = /^[0-9]+$/;
    return pattern.test(value) || translateFunction('auth.required.number');
  },
  time: (value: string) => {
    if (! value) { return true; }
    const pattern = /^(0[0-9]|1[0-9]|2[0-3]|[0-9])[0-5][0-9]$/;
    return pattern.test(value) || 'Opgegeven tijd is ongeldig';
  },
  date: (value: string) => {
    if (! value) { return true; }
    const pattern = /((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/;
    return pattern.test(value) || 'Opgegeven datum is ongeldig';
  },
  mobile: (value: string) => {
    if (! value) { return true; }
    const pattern = /(^316|06)[0-9]{8}$/;
    return pattern.test(value) || 'Ongeldig telefoonnummer';
  },
  phone: (value: string) => {
    if (! value) { return true; }
    const pattern = /^[0-9]+$/;
    return pattern.test(value) || 'Ongeldig telefoonnummer';
  },
  postcode: (value: string) => {
    if (! value) { return true; }
    const pattern = /^[1-9][0-9]{3}(?!sa|sd|ss)[a-z]{2}$/i; // https://stackoverflow.com/a/17898538
    return pattern.test(value) || 'Ongeldige postcode. Gelieve in formaat 1234AB in te vullen.';
  },
  password: {
    lowercase: (value: string) => {
      const pattern = /[a-z]+/;
      return pattern.test(value) || translateFunction('auth.required.passwordRules.lowercase');
    },
    uppercase: (value: string) => {
      const pattern = /[A-Z]+/;
      return pattern.test(value) || translateFunction('auth.required.passwordRules.uppercase');
    },
    length: (value: string) => {
      const pattern = /.{8,}/;
      return pattern.test(value) || translateFunction('auth.required.passwordRules.length');
    },
    number: (value: string) => {
      const pattern = /[0-9]+/;
      return pattern.test(value) || translateFunction('auth.required.passwordRules.number');
    },
    special: (value: string) => {
      const pattern = /\W|_/g;
      return pattern.test(value) || translateFunction('auth.required.passwordRules.special');
    },
  },
  minLength6: (value: string) => {
    if (! value) { return true; }
    return value.length >= 6 || 'Minimum 6 karakters';
  },
  maxLength128: (value: string) => {
    if (! value) { return true; }
    return value.length <= 128 || 'Maximum 128 karakters';
  },
});

const getTranslation = (key: string) => i18n.t(key);

export const rules = validationRules(getTranslation);

export interface Rules {
  required: Function;
  name: Function;
  url: Function;
  email: Function;
  number: Function;
  time: Function;
  password: Password;
  date: Function;
}

interface Password {
  [key: string]: Function;
}

export type Rule = (value: string | any) => true | string;
