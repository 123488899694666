import { getStatusKeys, getStatusRoles, ReportStatus } from '@/support/ReportStatus';
import { Report } from '@/models/Report';
import { User } from '@/models/User';

// #region Status
const allStatus = getStatusKeys();

const werkvoorbereidingStatus = [
  ReportStatus.CREATED,
  ReportStatus.PLANNING,
  ReportStatus.SMR_CREATED,
  ReportStatus.WERKVOORBEREIDING_REJECTED,
  ReportStatus.OPNAME_WERKVOORBEREIDING_DONE,
  ReportStatus.OBJECTION_CREATED,
  ReportStatus.OBJECTION_WERKVOORBEREIDING_REJECTED,
  ReportStatus.SMR_WERKVOORBEREIDING_REJECTED,
  ReportStatus.ZIENSWIJZE_WERKVOORBEREIDING_REJECTED,
];

const planningStatus = [
  ReportStatus.CREATED,
  ReportStatus.PLANNING,
  ReportStatus.SMR_CREATED,
  ReportStatus.OBJECTION_CREATED,
  ReportStatus.OPNAME_WERKVOORBEREIDING_DONE,
  ReportStatus.BACK_TO_PLANNING,
  ReportStatus.BACK_TO_PLANNING_IMS,
];

const voorcontroleStatus = [
  ReportStatus.SUBMITTED,
  ReportStatus.CHECKING,
];

const veldwerkStatus = [
  ReportStatus.APPROVED,
  ReportStatus.VELDWERK_CORRECTED,
  ReportStatus.VELDWERK_ONHOLD,
  ReportStatus.ZIENSWIJZE_ONHOLD,
  ReportStatus.SMR_APPROVED,
  ReportStatus.SMR_CORRECTED,
  ReportStatus.SMR_CLOSED,
  ReportStatus.OBJECTION_APPROVED,
  ReportStatus.OBJECTION_CORRECTED,
  ReportStatus.OBJECTION_PENDING_CLOSE,
  ReportStatus.ZIENSWIJZE_APPROVED,
  ReportStatus.ZIENSWIJZE_CORRECTED,
  ReportStatus.MSR_APPROVED,
  ReportStatus.SUBMIT_NO_TEST,
  ReportStatus.PROBLEM_TCMG,
  ReportStatus.SPECIALS_REVIEWED,
  ReportStatus.STRUCTURAL_ENGINEER_CHECKING,
];

const technischCoordinatorStatus = [
  ReportStatus.OPNAME_IN_PROGRESS,
  ReportStatus.OPNAME_REJECTED,
  ReportStatus.IN_PROGRESS,
  ReportStatus.SUPERVISOR,
  ReportStatus.REJECTED,
  ReportStatus.OPNAME_DONE,
  ReportStatus.SUBMITTED,
  ReportStatus.CHECKING,
  ReportStatus.VELDWERK_REJECTED,

  ReportStatus.ZIENSWIJZE_IN_PROGRESS,
  ReportStatus.ZIENSWIJZE_REJECTED,
  ReportStatus.ZIENSWIJZE_CHECKING,
  ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,

  ReportStatus.OBJECTION_IN_PROGRESS,
  ReportStatus.OBJECTION_REJECTED,
  ReportStatus.OBJECTION_CHECKING,
  ReportStatus.OBJECTION_VELDWERK_REJECTED,

  ReportStatus.SMR_IN_PROGRESS,
  ReportStatus.SMR_REJECTED,
  ReportStatus.SMR_CHECKING,
  ReportStatus.SMR_VELDWERK_REJECTED,

  ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
];

const deskundigeStatus = [
  ReportStatus.OPNAME_IN_PROGRESS,
  ReportStatus.OPNAME_REJECTED,
  ReportStatus.IN_PROGRESS,
  ReportStatus.SUPERVISOR,
  ReportStatus.REJECTED,
  ReportStatus.OPNAME_DONE,
  ReportStatus.SUBMITTED,
  ReportStatus.CHECKING,
  ReportStatus.VELDWERK_REJECTED,

  ReportStatus.ZIENSWIJZE_IN_PROGRESS,
  ReportStatus.ZIENSWIJZE_REJECTED,
  ReportStatus.ZIENSWIJZE_CHECKING,
  ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,

  ReportStatus.OBJECTION_IN_PROGRESS,
  ReportStatus.OBJECTION_REJECTED,
  ReportStatus.OBJECTION_CHECKING,
  ReportStatus.OBJECTION_VELDWERK_REJECTED,

  ReportStatus.SMR_IN_PROGRESS,
  ReportStatus.SMR_REJECTED,
  ReportStatus.SMR_CHECKING,
  ReportStatus.SMR_VELDWERK_REJECTED,

  ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
];

const bezwaarStatus = [ReportStatus.CREATED, ReportStatus.OBJECTION_CREATED];
// #endregion Status

const reportEditorMap: { [key: string]: string[] } = {
  msr: allStatus,
  nalever: allStatus,
  werkvoorbereiding: werkvoorbereidingStatus,
  planning: planningStatus,
  planning_expert: [ReportStatus.PLANNING_ORGANIZATION, ReportStatus.OPNAME_IN_PROGRESS, ReportStatus.IN_PROGRESS, ReportStatus.SUPERVISOR, ReportStatus.OBJECTION_PENDING_ASSIGNMENT],
  expert_requested: [ReportStatus.EXPERT_REQUESTED],
  deskundige: deskundigeStatus,
  deskundige_simple: [ReportStatus.OPNAME_IN_PROGRESS, ReportStatus.OPNAME_REJECTED],
  werkverdeling: [ReportStatus.OPNAME_DONE],
  voorcontrole: voorcontroleStatus,
  voorcontrole_woco: deskundigeStatus,
  technisch_coordinator: technischCoordinatorStatus,
  veldwerk: veldwerkStatus,
  interruption_tcmg: allStatus,
  objection: bezwaarStatus,
};

export const disableEditor = (editor: string, report: Report, user: User) => {
  if (! editor || ! report || ! report.status || report.is_offline || ! user) {
    return false;
  }

  const foundStatus = reportEditorMap[editor].find((editorStatus: string) => editorStatus === report.status);

  const foundRole = getStatusRoles(report.status).find((role: string) => role === user.type);

  return !! (foundStatus && foundRole);
};
