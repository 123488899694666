import { Model } from '@/models/Model';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import { Damage } from '@/models/Damage';
import { Answer } from '@/models/Answer';
import { Log } from '@/models/Log';
import { Tag } from '@/models/Tag';
import { Skill } from '@/models/Skill';
import { LateRequest } from '@/models/LateRequest';
import { ReportTypes } from '@/support/ReportTypes';
import { DateTime } from 'luxon';

export class ReportNew extends Model {
  protected $name = 'ReportNew';

  protected $endpoint = '/reports';

  protected isRefactored = true;

  protected $fillable: string[] = [
    'level',

    'is_prio',
    'is_special',
    'is_final',

    'dates',

    'organization',
    'address',
    'address',
    'type',
    'departure_address',
    'calculation_model',

    'expert',
    'second_expert',
    'organization_expert',
    'organization_tc',
    'technisch_coordinator',
    'pre_controller',
    'offline_user',
    'report_handler',
    'mediator',
    'created_by_user',

    'damages',
    'answers',
    'media',
    'reject_reasons',

    'tags',
    'skills',

    'late_requests',
    'finance',
    'total_repairs',
    'management_info',
    'late_request_info',
    'sync_info',
    'last_updated',
  ];

  public id?: string;

  public case_number?: string;

  public opname_variant?: string;

  public status?: string;

  public planning_status?: string;

  public level?: string;

  public is_prio?: boolean;

  public is_special?: boolean;

  public is_final?: boolean;

  public dates?: Dates;

  public Links?: Links;

  // Includes
  public organization?: Organization | null;

  public address?: Address | null;

  public type?: Type | null;

  public departure_address?: Address | null;

  public calculation_model?: Calculation | null;

  public expert?: User | null;

  public second_expert?: User | null;

  public organization_expert?: User | null;

  public organization_tc?: User | null;

  public technisch_coordinator?: User | null;

  public pre_controller?: User | null;

  public offline_user?: User | null;

  public report_handler?: User | null;

  public mediator?: User | null;

  public created_by_user?: User | null;

  public damages?: Damage[];

  public answers?: Answer[];

  public logs?: Log[];

  public media?: Media[];

  public reject_reasons?: RejectReason[];

  public validations?: Validations;

  public status_transitions?: Status[];

  public last_status_change?: Status;

  public damage_statistics?: DamageStatistics;

  public tags?: Tag[];

  public skills?: Skill[];

  public late_requests?: LateRequest[];

  public finance?: Finance;

  public total_repairs?: TotalRepairs;

  public management_info?: ManagementInfo;

  public late_request_info?: LateRequestInfo;

  public sync_info?: SyncInfo;

  public last_updated?: LastUpdated;

  // Functions
  public convertType(id: string, attributes?: object) {
    const url = `${this.$endpoint}/${id}/convert`;

    return this.request('post', url, attributes);
  }

  public convertTags(id: string, attributes?: object) {
    const url = `${this.$endpoint}/${id}/tags`;

    return this.request('post', url, attributes);
  }

  public append(id: string) {
    const url = `${this.$endpoint}/${id}/append`;

    return this.request('post', url, {});
  }

  public mediaEndpoint(id: string) {
    this.$endpoint = `reports/${id}/media`;

    return this;
  }

  public setBasedOnEndpoint(id: string) {
    this.$endpoint = `reports/${id}/based-on`;

    return this;
  }

  public listMedia(payload?: any): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/media`;

    return this.request('get', url, payload, false);
  }

  public deleteMediaEndpoint(id: string): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}/media/${id}`;

    return this.request('delete', url);
  }

  public updateMediaEndpoint(id:string, attributes?: object): Promise<any> {
    const url = `${this.$endpoint}${this.resolvePrimaryKey().length ? `/${this.resolvePrimaryKey()}` : ''}/media/${id}`;

    return this.request('patch', url, this.getPayload(attributes));
  }

  public getTrillingstoolPdf(payload: any): Promise<any> {
    const url = `${this.$endpoint}/${payload.report}/trillingstool`;

    return this.request('get', url, payload, false);
  }

  public getSkills(id: string): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('get', url);
  }

  public AddSkills(id: string, payload: any): Promise<any> {
    const url = `${this.$endpoint}/${id}/relationships/skills`;

    return this.request('put', url, payload);
  }

  public pingReport(id: string): Promise<any> {
    const url = `${this.$endpoint}/${id}/ping`;

    return this.request('post', url);
  }

  public googleMapUrl() {
    if (! this.address) {
      return 'https://www.google.nl/maps/';
    }

    return `https://maps.google.nl/maps?q=${this.address.street}%20${this.address.number}%20${this.address.city}`;
  }

  private isType(typeEnum: ReportTypes): boolean {
    if (! this.type || ! this.type.uuid) {
      return false;
    }

    return this.type.uuid === typeEnum;
  }

  public hasStatus(status: string | string[]) {
    if (this.status === undefined) {
      return false;
    }

    if (! Array.isArray(status)) {
      status = [status];
    }

    let hasStatus = false;
    if (status.includes(this.status)) {
      hasStatus = true;
    }

    return hasStatus;
  }

  public isOpen(id: string): boolean {
    if (this.last_updated?.user?.uuid === id) {
      return false;
    }

    if (this.last_updated && this.last_updated.date) {
      const then = DateTime.fromSQL(this.last_updated.date);
      const diff = DateTime.local().diff(then, 'minutes').toObject();

      if (! diff || ! diff.minutes) {
        return false;
      }

      if (diff.minutes > 120) {
        return false;
      }
    }

    return true;
  }

  public isOffline(): boolean {
    if (this.sync_info?.is_offline) {
      return true;
    }

    return false;
  }

  public get isVoorControle() {
    return this.hasStatus(['submitted', 'checking']);
  }

  public get isClosed() {
    return this.hasStatus(['downloaded', 'zienswijze_closed', 'objection_closed', 'smr_closed']);
  }

  public get isWoningCooperatieReport(): boolean {
    return this.isWoningCooperatie1Report || this.isWoningCooperatie2Report;
  }

  public get isWoningCooperatieReportNew(): boolean {
    return this.isWoningCooperatie1ReportNew || this.isWoningCooperatie2ReportNew;
  }

  public get isWoningCooperatie1ReportNew(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_1_NEW);
  }

  public get isWoningCooperatie1Report(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_1);
  }

  public get isWoningCooperatie2ReportNew(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_2_NEW);
  }

  public get isWoningCooperatie2Report(): boolean {
    return this.isType(ReportTypes.WONING_COOPERATIE_2);
  }

  public get isCWCat1Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_1);
  }

  public get isCWCat2Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_2);
  }

  public get isCWCat3Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_3);
  }

  public get isCWCat4Report(): boolean {
    return this.isType(ReportTypes.CW_CAT_4);
  }

  public get isAannemersReport(): boolean {
    return this.isType(ReportTypes.AANNEMERS_VARIANT) || this.isType(ReportTypes.OLD_FORMAT_AANNEMERS_VARIANT);
  }

  public get isBezwaarReport(): boolean {
    return this.isType(ReportTypes.ADVIESRAPPORT_BEZWAAR) || this.isType(ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM);
  }

  public get isEerderBestaandeSchadeReport(): boolean {
    return this.isType(ReportTypes.EERDER_BESTAANDE_SCHADE);
  }

  public get isEnkelvoudigReport(): boolean {
    return this.isType(ReportTypes.ENKELVOUDIG);
  }

  public get isRegulierReport(): boolean {
    return this.isType(ReportTypes.REGULIER);
  }

  public get isMutatieWoningReport(): boolean {
    return this.case_number ? this.case_number.substring(0, 2).toUpperCase() === 'MW' : false;
  }

  public get isStuwmeerRegelingReport(): boolean {
    return this.isType(ReportTypes.STUWMEERREGELING);
  }

  public get isZienswijzeReport(): boolean {
    return this.opname_variant ? this.opname_variant === 'zienswijze' : false;
  }

  public get isOpnemerReport(): boolean {
    return this.isType(ReportTypes.OPNEMER);
  }
}

interface Dates {
  created_at?: string | null;
  updated_at?: string | null;
  applicant_submitted_at?: string | null;
  planning_last_action_at?: string | null;
  planning_next_action_at?: string | null;
  planned_at?: string | null;
  submitted_at?: string | null;
  pre_controlled_at?: string | null;
  deadline_at?: string | null;
  approved_at?: string | null;
  downloaded_at?: string | null;
}

interface Address {
  uuid?: string;
  street?: string;
  number?: string;
  number_add?: string;
  postcode?: string;
  city?: string;
  geolocation: {
    latitude?: number
    longitude?: number
  },
  created_at?: string;
  updated_at?: string;
  links?: Links;
}

interface Type {
  uuid?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
  links?: Links;
}

interface Calculation {
  uuid?: string;
  name?: string;
  from?: string;
  till?: string;
  created_at?: string;
  updated_at?: string;
}

interface Media {
  uuid?: string;
  original?: string;
  thumb?: string;
  sort_order?: number;
  type?: string;
  description?: string;
  show_in_report?: string;
  file_name?: string;
  mime_type?: string;
  uploaded_on?: string;
  uploaded_by?: string;
  created_at?: string;
}

interface RejectReason {
  id?: string;
  type?: string;
  group?: string;
  name?: string;
  target_status?: string;
  created_at?: string;
  updated_at?: string;
  links?: Links;
}

interface Validations {
  naam_zaakbegeleider?: string;
  type_woning?: string;
  eigendom_sinds?: string;
  bouwjaar?: string;
  klein_werk_toeslag?: string;
  historical_reports_missing?: string;
}

interface Status {
  uuid?: string;
  status_old?: string;
  status_new?: string;
  duration?: string | null;
  created_at?: string;
  updated_at?: string;
}

interface DamageStatistics {
  damage_count?: number;
  damage_causaal_count?: number;
  damage_not_causaal_count?: number;
  ebs_identiek_nam?: number;
  ebs_identiek_tcmg?: number;
}

interface Finance {
  calculation_model_vat_low?: number;
  payout_total?: number;
  klein_werk_toeslag?: number;
  index_percentage?: number;
}

interface TotalRepairs {
  gebouw_ex_vat_none?: number;
  gebouw_ex_vat_low?: number;
  gebouw_ex_vat_high?: number;
  gebouw_ex?: number;
  gebouw_vat_low?: number;
  gebouw_vat_high?: number;
  gebouw_in?: number;
  gevolg_ex_vat_none?: number;
  gevolg_ex_vat_low?: number;
  gevolg_ex_vat_high?: number;
  gevolg_ex?: number;
  gevolg_vat_low?: number;
  gevolg_vat_high?: number;
  gevolg_in?: number;
  klein_werk_toeslag?: number;
  total_in?: number;
  index_percentage?: number;
  index_amount?: number;
  total_payment?: number;
}

interface ManagementInfo {
  stats_doorlooptijd_werkvoorbereiding?: number;
  stats_doorlooptijd_planning?: number;
  stats_doorlooptijd_bureau?: number;
  stats_doorlooptijd_veldwerk?: number;
  stats_first_time_right_tcmg?: boolean;
  stats_first_time_right_bureau?: boolean;
  stats_first_time_right_tcmg_reason?: string;
  stats_first_time_right_tcmg_reasons?: string[];
  stats_first_time_right_bureau_reason?: string;
  stats_first_time_right_bureau_reasons?: string[];
  stats_first_time_right_tcmg_comment?: string;
  stats_first_time_right_bureau_comment?: string;
  stats_costs?: number;
}

interface LateRequestInfo {
  late_requests_count?: number;
  last_late_request_status?: string;
  late_reason?: string;
  reject_comment?: string;
  submit_count?: number;
  late_response_at?: string;
  is_late?: boolean;
}

interface SyncInfo {
  is_syncing?: boolean;
  is_offline?: boolean;
  offline_device?: string;
  offline_at?: string;
  online_at?: string;
}

interface LastUpdated {
  date: string | null,
  user: User | null;
}

interface Links {
  self?: string;
}
