import { Model } from '@/models/Model';

export class Address extends Model {
  protected $name = 'Address';

  protected $endpoint = '/addresses';

  protected $fillable: string[] = [
    'country',
    'street',
    'number',
    'number_add',
    'postcode',
    'city',
    'contact',
  ];

  public street?: string;

  public number?: string;

  public number_add?: string;

  public postcode?: string;

  public city?: string;

  public contact?: string;

  public id?: string;

  public uuid?: string;
}
