import { Question } from '@/models/Question';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Damage, Classification, DamageAnswers } from '@/models/Damage';
import { Report } from '@/models/Report';
import QuestionConclusion from '@/components/QuestionConclusion/QuestionConclusion.vue';
import { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { QuestionConclusionOverwritePayload } from '@/components/QuestionConclusionDialog/QuestionConclusionOverwriteDialog/QuestionConclusionOverwriteDialog';
import QuestionConclusionDialog from '@/components/QuestionConclusionDialog/QuestionConclusionDialog';
import { overwriteCausalityConclusionReason, overwriteConclusionReasonLabels } from '@/items/OverwriteConclusionDialogReasons';

@Component<CausalityQuestion>({
  components: {
    QuestionConclusion,
  },
})
export default class CausalityQuestion extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  @Prop()
  protected question!: Question;

  @Prop()
  protected damage!: Damage;

  @Prop()
  protected report!: Report;

  @Prop({ default: false })
  protected disabled!: boolean;
  // #endregion

  // #region @Refs
  @Ref()
  protected questionConclusionDialog!: QuestionConclusionDialog;
  // #endregion

  // #region Class properties
  protected isDisplayingQuestionConclusionDialog = false;

  protected conclusion: null | Conclusion = null;

  protected damageAnswers: {[key: string]: string} | null = null;

  protected isLoading = true;

  protected isLoadingQuestions = false;

  protected overwriteConclusionDialogReasons: { label?: string, value?: string}[] = Object.values(overwriteCausalityConclusionReason).map((reason: string) => ({ label: overwriteConclusionReasonLabels[`${reason}`], value: reason }));

  // #endregion

  // #region Lifecycle Hooks / Init
  protected async mounted(): Promise<void> {
    this.isLoading = true;
    this.damageAnswers = this.damage.answers;
    this.reloadFreshData();

    this.isLoading = false;
  }
  // #endregion

  // #region Class methods
  protected onOpenQuestionConclusionDialog(): void {
    this.isDisplayingQuestionConclusionDialog = true;
  }

  protected async handleAnswerQuestion(question: Question, value: string): Promise<void> {
    await this.answerQuestion({
      key: question.key,
      value,
    });
    this.reloadFreshData();
  }

  // #endregion

  // #region Async methods
  protected async answerQuestion(damagePayload: Record<string, string|number|boolean|null>): Promise<void> {
    this.isLoadingQuestions = true;
    const damage: Damage = await new Damage(this.damage)
      .updateAnswer()
      .include(['validations'])
      .create(damagePayload)
      .catch((error: AxiosError) => {});

    if (damage) {
      this.damageAnswers = damage.answers;
    }

    this.isLoadingQuestions = false;
  }

  protected async handleRemoveAnswer(damagePayload: Record<string, string|number|boolean|null>): Promise<void> {
    await this.answerQuestion(damagePayload);
    this.$emit('reloadAnswers');
  }

  protected async reloadFreshData(question?: Question): Promise<void> {
    await this.getConclusion();
    this.$nextTick(() => {
      this.questionConclusionDialog?.displayCorrectSidePanel(question);
    });
  }

  protected async getConclusion(): Promise<void> {
    if (! this.damage?.uuid) { return; }

    this.conclusion = await new Rpc()
      .execute('causaliteits-assistant:validate', {
        damage: this.damage.uuid,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  /**
   *
   * @param conclusion
   * @param overwritePayload
   */
  protected async onUseConclusion(isSuccess: boolean, conclusion: Conclusion): Promise<void> {
    await this.answerQuestion({
      key: this.question.key,
      value: isSuccess ? 'Ja' : 'Nee',
      casuality_has_deviated: false,
      casuality_anomaly_reason: null,
      casuality_anomaly_explanation: null,
    });
    await this.answerQuestion({
      key: 'MijnbouwCausaalVerbandToelichting',
      value: this.damage?.answers?.MijnbouwCausaalVerbandToelichting || conclusion.motivation,
    });
    this.isDisplayingQuestionConclusionDialog = false;

    this.$emit('reloadAnswers');
  }

  protected async onDeviateFromConclusion(isSuccess: boolean, overwritePayload: QuestionConclusionOverwritePayload): Promise<void> {
    await this.answerQuestion({
      key: this.question.key,
      value: isSuccess ? 'Ja' : 'Nee',
      casuality_has_deviated: true,
      casuality_anomaly_reason: overwritePayload.reason,
      casuality_anomaly_explanation: overwritePayload.comment,
    });
    // Clear toelichting when deviating from conclusion
    await this.answerQuestion({
      key: 'MijnbouwCausaalVerbandToelichting',
      value: '',
    });
    this.isDisplayingQuestionConclusionDialog = false;
    this.$emit('reloadAnswers');
  }

  // #endregion

  // #region Getters & Setters
  protected get classificationDescription() {
    switch (this.damage.classification) {
      case Classification.CAUSAAL:
        return 'Ja. Causaal';
      case Classification.NIET_CAUSAAL:
        return 'Nee. Niet causaal';
      case Classification.ONBEKEND:
        return '-';
      case Classification.IDENTIEK_NAM:
        return 'Identiek NAM';
      case Classification.IDENTIEK_IMG:
        return 'Identiek IMG';
      case Classification.ROOM_WITHOUT_DAMAGE:
        return 'Ruimte zonder schade';
      default:
        throw new Error(`Invalid classification ${this.damage.classification}`);
    }
  }

  protected get classificationResult(): QuestionaireDialogConclusionTypeEnum {
    switch (this.damage.classification) {
      case Classification.CAUSAAL:
        return QuestionaireDialogConclusionTypeEnum.SUCCESS;
      case Classification.NIET_CAUSAAL:
        return QuestionaireDialogConclusionTypeEnum.ERROR;
      case Classification.ONBEKEND:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.IDENTIEK_IMG:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.IDENTIEK_NAM:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.ROOM_WITHOUT_DAMAGE:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      default:
        throw new Error(`Invalid classification ${this.damage.classification}`);
    }
  }

  protected get overwriteConclusionDialogTitle(): string {
    if (this.isSuccessConclusion) {
      return 'Schade afwijzen';
    }

    return 'Schade toewijzen';
  }

  protected get isSuccessConclusion(): boolean {
    return this.conclusion?.status === QuestionaireDialogConclusionTypeEnum.SUCCESS;
  }

  protected get overwriteConclusionReasonLabels() {
    return overwriteConclusionReasonLabels;
  }

  protected get isDeviation(): boolean {
    return !! this.damage?.casuality_has_deviated;
  }

  // #endregion

  // #region @Watchers
  // #endregion
}

// #region Interfaces
export interface Conclusion {
  title: string;
  description: string;
  show_questionaire: boolean;
  required_questions: Question[];
  show_second_questionaire: boolean;
  missing_answers: boolean;
  motivation: string;
  motivation_code: string;
  status: QuestionaireDialogConclusionTypeEnum;
  hide_question_keys: string;
}

// #endregion
