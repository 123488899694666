var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", { attrs: { flat: "", type: "spinner--center" } })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "questionContainer" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _vm.showApplicantSubmittedAtField
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "130",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px",
                                  disabled: _vm.disabled
                                },
                                model: {
                                  value: _vm.isEditingApplicantSubmittedAt,
                                  callback: function($$v) {
                                    _vm.isEditingApplicantSubmittedAt = $$v
                                  },
                                  expression: "isEditingApplicantSubmittedAt"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    label: "Indieningsdatum",
                                    "prepend-icon": "event",
                                    clearable: "",
                                    "error-messages":
                                      _vm.applicantSubmittedAtDateErrorMessage,
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.formatApplicantSubmittedAtDateFromTextField(
                                        $event
                                      )
                                    },
                                    "click:clear": function($event) {
                                      return _vm.handleClearApplicantSubmittedAtDate(
                                        $event
                                      )
                                    }
                                  },
                                  slot: "activator",
                                  model: {
                                    value:
                                      _vm.applicantSubmittedAtDateFormatted,
                                    callback: function($$v) {
                                      _vm.applicantSubmittedAtDateFormatted = $$v
                                    },
                                    expression:
                                      "applicantSubmittedAtDateFormatted"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  on: {
                                    input:
                                      _vm.formatPlannedAtDateFromDatePicker,
                                    change: function($event) {
                                      _vm.isEditingApplicantSubmittedAt = false
                                    }
                                  },
                                  model: {
                                    value: _vm.applicant_submitted_at,
                                    callback: function($$v) {
                                      _vm.applicant_submitted_at = $$v
                                    },
                                    expression: "applicant_submitted_at"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.questions, function(question, index) {
                      return _c(
                        "v-flex",
                        { key: index, attrs: { xs12: "" } },
                        [
                          _c("DynamicFormQuestion", {
                            attrs: {
                              disabled: _vm.disabled,
                              question: question,
                              reportId: _vm.dynamicFormQuestionReportId,
                              damage: _vm.damage,
                              hasAutosave: _vm.hasAutosave,
                              historicalDamage: _vm.historicalDamage
                            },
                            on: {
                              answeredDamageQuestionInstruction: function(
                                $event
                              ) {
                                return _vm.updateDamageInstruction($event)
                              },
                              answeredDamageQuestion: function($event) {
                                return _vm.updateDamage($event)
                              },
                              answeredHistoricalDamageQuestion: function(
                                $event
                              ) {
                                return _vm.updateHistoricalDamage($event)
                              },
                              createAnswerPayload: _vm.createAnswerPayload,
                              appendReport: _vm.appendReport,
                              questionAnswered: _vm.questionAnswered
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _vm.sections === 3
                        ? _c(
                            "div",
                            [
                              _c("h2", { staticClass: "subSectionTitle" }, [
                                _vm._v("historische schades")
                              ]),
                              _vm.historicalReports.length === 0
                                ? _c("p", [
                                    _vm._v(
                                      "Er zijn geen eerdere aanvragen geweest."
                                    )
                                  ])
                                : _vm._e(),
                              _vm.historicalReports.length !== 0
                                ? [
                                    _vm.panelHistoricalReport.length
                                      ? _c("div", { staticClass: "panel" }, [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click:
                                                  _vm.closeAllHistoricReports
                                              }
                                            },
                                            [
                                              _vm._v(" sluit alle "),
                                              _c("v-icon", [
                                                _vm._v("keyboard_arrow_up")
                                              ])
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e(),
                                    !_vm.panelHistoricalReport.length
                                      ? _c("div", { staticClass: "panel" }, [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click:
                                                  _vm.openAllHistoricReports
                                              }
                                            },
                                            [
                                              _vm._v(" open alle "),
                                              _c("v-icon", [
                                                _vm._v("keyboard_arrow_down")
                                              ])
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _vm._l(_vm.historicalReports, function(
                                historicalReport,
                                index
                              ) {
                                return _c(
                                  "v-expansion-panel",
                                  {
                                    key: index,
                                    staticClass: "damagePanel",
                                    attrs: { expand: "" },
                                    model: {
                                      value: _vm.panelHistoricalReport[index],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.panelHistoricalReport,
                                          index,
                                          $$v
                                        )
                                      },
                                      expression: "panelHistoricalReport[index]"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-expansion-panel-content",
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "header" },
                                            slot: "header"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(index + 1) +
                                                ". " +
                                                _vm._s(historicalReport.name)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: {
                                              "border-top": "1px solid #ddd",
                                              "border-bottom": "1px solid #ddd"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                historicalReport.answers
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "item__list item__list--reports",
                                                        attrs: { wrap: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "label",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs10: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Algemeen"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Status:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_Status
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Zelfde eigenaar:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_ZelfdeEigenaar
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Opnamedatum:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "dateFormat"
                                                                          )(
                                                                            historicalReport
                                                                              .answers
                                                                              .historicalReport_Opnamedatum
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Naam expert:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_Naamexpert
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Calculatie expert:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            historicalReport
                                                                              .answers
                                                                              .historicalReport_Calculatieexpert
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Datum akkoord:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "dateFormat"
                                                                          )(
                                                                            historicalReport
                                                                              .answers
                                                                              .historicalReport_DatumAkkoord
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                historicalReport.answers
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "item__list item__list--reports",
                                                        attrs: { wrap: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "label",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs10: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Schade"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "ABC:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_ABCSchadesAantallen
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "ABC:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_ABCSchadesAantallen
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Keuze KUS / KEA / KH:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_KeuzeKUSKEAKHC
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Finale kwijting:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_FinaleKwijting
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Omschrijving:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_OmschrijvingSchadelocatie
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Bedrag uitbetaling:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            historicalReport
                                                                              .answers
                                                                              .historicalReport_BedragUitbetaling
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "list__item",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "align-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs7: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Nulmeting vereist:"
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs5: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          historicalReport
                                                                            .answers
                                                                            .historicalReport_NulMetingLight
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                historicalReport.media &&
                                                historicalReport.media.length
                                                  ? _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "item__list item__list--reports",
                                                        attrs: { wrap: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "label",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs10: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Bijlage"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._l(
                                                          historicalReport.media,
                                                          function(media, i) {
                                                            return _c(
                                                              "v-flex",
                                                              {
                                                                key: i,
                                                                staticClass:
                                                                  "list__item",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                      "align-center":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs12:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "item__name item__link"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href:
                                                                                    media.original,
                                                                                  target:
                                                                                    "_blank"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      media.file_name
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.sections === 8
                        ? _c(
                            "div",
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    icon: "picture_as_pdf"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.previewPdf($event)
                                    }
                                  }
                                },
                                [_vm._v(" Preview schade rapport ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ],
                  2
                ),
                _vm.showTags
                  ? _c("ReportTags", {
                      attrs: {
                        reportId: _vm.reportId,
                        reportTags: _vm.currentReport.tags,
                        disabled: _vm.disabled
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }