import { Vue, Component, Prop } from 'vue-property-decorator';
import { parseWarning } from '@/support/Warning';

@Component<ValidationWarning>({})

export default class ValidationWarning extends Vue {
  @Prop()
  protected validation!: string;

  @Prop()
  protected validationKey!: string;

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }
}
