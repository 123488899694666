var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$attrs["prepend-inner-icon"]
        ? _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(_vm._s(_vm.$attrs["prepend-inner-icon"]))
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm.$attrs["append-inner-icon"]
        ? _c("v-icon", { attrs: { right: "" } }, [
            _vm._v(_vm._s(_vm.$attrs["append-inner-icon"]))
          ])
        : _vm._e(),
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "tw-ml-16 tw--mt-2",
            attrs: { indeterminate: "", size: "16", width: "2" }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }