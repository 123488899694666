var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h2",
    { staticClass: "atabix-title" },
    [_vm._t("default", [_vm._v(" " + _vm._s(_vm.text) + " ")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }