import { Model } from '@/models/Model';

export class MapArea extends Model {
  protected $name = 'MapArea';

  protected $endpoint = '/map-areas';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;

  public type?: string;

  public shape_length?: number;

  public shape_area?: number;

  public contour?: Position[];

  public created_at?: string;

  public updated_at?: string;

  public updateContour(payload?: object) {
    const url = `${this.$endpoint}/${this.id}`;

    return this.request('patch', url, payload);
  }
}

export interface Position {
  lat: number;
  lng: number;
}
