import { Model } from '@/models/Model';

export class Announcement extends Model {
  protected $name = 'Announcement';

  protected $endpoint = '/announcements';

  protected $fillable: string[] = ['announcement'];

  public announcement?: string;

  public uuid?: string;

  public created_at?: string;

  public updated_at?: string;

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public setAnnouncementId(announcement: Model | string) {
    this.$endpoint = `/announcements/${announcement}`;

    return this;
  }
}
