






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<NoDataCard>({})
export default class NoDataCard extends Vue {
  @Prop({ default: 'error' })
  protected icon!: string;

  @Prop({ default: 'Geen data' })
  protected text!: string;
}
