import { object as dotObject } from 'dot-object';
import { ValidationObserver } from 'vee-validate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AAbstractInput from '@/components/AAbstractInput/AAbstractInput';

@Component<AStatefulForm>({
  components: {
    ValidationObserver,
  },
})
export default class AStatefulForm extends Vue implements AStatefulFormInterface {
  @Prop({ default: true }) protected autocomplete!: boolean;

  protected validator: AValidationObserverComponent | null = null;

  protected fields: AAbstractInput[] = [];

  protected payload: Record<string, any> = {};

  public beforeCreate(): void {
    (this as any).$isStatefulForm = true;
  }

  public mounted(): void {
    this.onMounted();
  }

  public onMounted(): void {
    this.$set(this, 'validator', this.$refs.observer);
  }

  /**
   * Emits a submit event after validating the form.
   */
  public async submit(): Promise<void> {
    const isValid = await this.validate();
    if (! isValid) return;

    this.$emit('submit', this.getData());
  }

  /**
   * SetTimout must be added to emitChange to fix the validation for the DateInput.
   * This is because the DateInput has 2 fields for this to go well we must first wait the input of the datepicker field into the text field.
   * Without nextTick, validation took place before your choice was entered in the date input (by vue) in the text field containing the validation rules
   */
  public emitChange(name: string, data: unknown, input: AAbstractInput): void {
    this.payload[name] = data;
    this.parsePayload();
    setTimeout(() => {
      this.$emit('change', name, data, input, this);
    }, 50);
  }

  /**
   * Resets any validation errors by hiding them.
   */
  public reset(): void {
    this.fields.forEach((field) => {
      field.reset();
    });

    this.validator?.reset();
  }

  /**
   * Validates whether all form rules are true or not.
   */
  public validate(silent = false): Promise<boolean> {
    return silent
      ? this.validator?.validate({ silent: true }) || Promise.resolve(false)
      : this.validator?.validate() || Promise.resolve(false);
  }

  /**
   * Push a registered input to the fields[].
   */
  public registerField(name: string, input: AAbstractInput): void {
    this.fields.push(input);
    // First set the 'dotted' value in the payload
    this.payload[name] = input?.$attrs?.value || input?.$data?.internalValue;
    // Second let dotObject Parse
    this.parsePayload();
  }

  /**
   * Remove a registered input from the fields[].
   */
  public unregisterField(name: string): void {
    // Bryse was (na correctie) verbaasd hoe nice Stefano dit had gemaakt
    const fieldIndex = this.fields
      .map((field) => field.getName())
      .findIndex((inputName) => inputName === name);

    if (fieldIndex >= 0 && this.fields[fieldIndex]) {
      this.fields.splice(fieldIndex, 1);
    }
  }

  /**
   * Returns all errors from the form per input name.
   */
  public get errors(): Record<string, string[]> {
    const validator = this?.validator;

    return validator?.$data?.errors || {};
  }

  public get original(): Record<string, any> {
    const initialValues: Record<string, any> = {};
    Object.keys(this.validator?.$data.refs).forEach((key) => {
      initialValues[key] = this.validator?.$data.refs[key].$data.initialValue;
    });

    return initialValues;
  }

  /**
   * Checks whether the form has been filled out correctly according to its rules.
   */
  public get isValid(): boolean {
    const errors = this.errors || {};

    const countOfErrorsPerField = Object.keys(errors).map((name) => {
      const field = errors[name];

      return field?.length || 0;
    });

    if (! countOfErrorsPerField.length) {
      return true;
    }

    const totalErrors = countOfErrorsPerField.reduce((a, b) => a + b);

    return totalErrors === 0;
  }

  /**
   * Checks whether the form has any pending changes.
   */
  public get isDirty(): boolean {
    return this.validator?.$data?.flags?.changed || false;
  }

  public getData(): Record<string, string | number | string[] | number[]> {
    // DotObject!
    const data: Record<string, any> = {};

    this.fields.forEach((input) => {
      data[input.$props.name] = input?.$attrs?.value || input?.$data?.internalValue;
    });
    this.payload = dotObject(data) as Record<string, any>;
    return this.payload;
  }

  public parsePayload(): void {
    this.payload = dotObject(this.payload);
  }
}

// Must define types for the observer component because the package has bad types.
interface AValidationObserverComponent extends Vue {
  reset(): void;
  validate(args?: Record<string, unknown>): Promise<boolean>;
}

export interface AStatefulFormInterface {
  submit: () => Promise<void>;
  emitChange: (name: string, data: any, input: AAbstractInput) => void;
  reset(): void;
  validate: (silent: boolean) => Promise<boolean>;
  registerField: (name: string, input: AAbstractInput) => void;
  unregisterField: (name: string) => void;
  errors: Record<string, string[]>;
  original: Record<string, any>;
  isValid: boolean;
  isDirty: boolean;
  getData: () => Record<string, string | number | string[] | number[]>;
  parsePayload: () => void;
}
