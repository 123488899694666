import { Question } from '@/models/Question';
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { Report, Answer } from '@/models/Report';
import QuestionConclusionDialog from '@/components/QuestionConclusionDialog/QuestionConclusionDialog';
import { Conclusion } from '@/components/QuestionConclusionDialog/QuestionConclusionDialog';
import QuestionConclusion from '@/components/QuestionConclusion/QuestionConclusion.vue';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { Answer as AnswerModel } from '@/models/Answer';
import { QuestionConclusionOverwritePayload } from '@/components/QuestionConclusionDialog/QuestionConclusionOverwriteDialog/QuestionConclusionOverwriteDialog';
import { overwriteBuildingFeaturesConclusionReason, overwriteConclusionReasonLabels } from '@/items/OverwriteConclusionDialogReasons';

@Component<BuildingFeaturesQuestion>({
  components: {
    QuestionConclusion,
  },
})
export default class BuildingFeaturesQuestion extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  @Prop()
  protected question!: Question;

  @Prop()
  protected report!: Report;

  @Prop({ default: false })
  protected disabled!: boolean;

  // #endregion

  // #region @Refs
  @Ref()
  protected questionConclusionDialog!: QuestionConclusionDialog;
  // #endregion

  // #region Class properties
  protected isDisplayingQuestionConclusionDialog = false;

  protected conclusion: null | Conclusion = null;

  protected isLoading = true;

  protected isLoadingQuestions = false;

  protected overwriteConclusionDialogReasons: { label?: string, value?: string}[] = Object.values(overwriteBuildingFeaturesConclusionReason).map((reason: string) => ({ label: overwriteConclusionReasonLabels[`${reason}`], value: reason }));

  // #endregion

  // #region Lifecycle Hooks / Init
  protected async mounted(): Promise<void> {
    this.isLoading = true;

    this.reloadFreshData();

    this.isLoading = false;
  }
  // #endregion

  // #region Class methods
  protected onOpenQuestionConclusionDialog(): void {
    this.isDisplayingQuestionConclusionDialog = true;
  }

  protected async handleAnswerQuestion(question: Question, value: string): Promise<void> {
    await this.answerQuestion({
      value,
      report: this.report.uuid,
      question: question.uuid || '',
    });
    this.reloadFreshData();
  }

  // #endregion

  // #region Async methods
  protected async answerQuestion(answerPayload: Record<string, string|number|boolean|null>): Promise<void> {
    this.isLoadingQuestions = true;

    await new AnswerModel(this.report).create(answerPayload);

    this.isLoadingQuestions = false;
    this.$emit('reloadAnswers');
  }

  protected async reloadFreshData(question?: Question): Promise<void> {
    await this.getConclusion();
    this.$nextTick(() => {
      this.questionConclusionDialog?.displayCorrectSidePanel(question);
    });
  }

  /**
   *
   * @param conclusion
   * @param overwritePayload
   */
  protected async onUseConclusion(isSuccess: boolean, conclusion: Conclusion): Promise<void> {
    await this.answerQuestion({
      value: isSuccess ? 'Ja' : 'Nee',
      report: this.report.uuid,
      question: this.question.uuid || '',
      sensitive_building_has_deviated: false,
      sensitive_building_anomaly_reason: null,
      sensitive_building_anomaly_explanation: null,
    });

    this.isDisplayingQuestionConclusionDialog = false;
  }

  protected async onDeviateFromConclusion(isSuccess: boolean, overwritePayload: QuestionConclusionOverwritePayload): Promise<void> {
    await this.answerQuestion({
      value: isSuccess ? 'Ja' : 'Nee',
      report: this.report.uuid,
      question: this.question.uuid || '',
      sensitive_building_has_deviated: true,
      sensitive_building_anomaly_reason: overwritePayload.reason,
      sensitive_building_anomaly_explanation: overwritePayload.comment,
    });

    this.isDisplayingQuestionConclusionDialog = false;
  }

  // #endregion

  // #region Async methods
  protected async getConclusion(): Promise<void> {
    if (! this.report?.uuid) { return; }

    this.conclusion = await new Rpc()
      .execute('bouwkundige-staat-assistant:validate', {
        report: this.report.uuid,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }
  // #endregion

  // #region Getters & Setters
  protected get classificationDescription() {
    switch (this.report.printAnswer('KenmerkenGevoeligObject')) {
      case 'Ja':
        return 'Ja';
      case 'Nee':
        return 'Nee';
      default:
        return '-';
    }
  }

  protected get classificationResult(): QuestionaireDialogConclusionTypeEnum {
    switch (this.report.printAnswer('KenmerkenGevoeligObject')) {
      case 'Ja':
        return QuestionaireDialogConclusionTypeEnum.SUCCESS;
      case 'Nee':
        return QuestionaireDialogConclusionTypeEnum.ERROR;
      default:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
    }
  }

  protected get overwriteConclusionDialogTitle(): string {
    if (this.isSuccessConclusion) {
      return 'Geen gevoelig object';
    }

    return 'Gevoelig object';
  }

  protected get isSuccessConclusion(): boolean {
    return this.conclusion?.status === QuestionaireDialogConclusionTypeEnum.SUCCESS;
  }

  protected get overwriteConclusionReasonLabels() {
    return overwriteConclusionReasonLabels;
  }

  protected get isDeviation(): boolean {
    return !! this.answer?.sensitive_building_anomaly_reason;
  }

  protected get answer(): Answer | null {
    return this.report?.answers?.find((answer: Answer) => answer.key === 'KenmerkenGevoeligObject') || null;
  }

  // #endregion

  // #region @Watchers
  // #endregion
}
