var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "defaultDialog" },
      [
        _c(
          "v-card",
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "dialog-close",
                              on: { click: _vm.closeEditingImage }
                            },
                            on
                          ),
                          [_vm._v("close")]
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Sluiten")])]
            ),
            _c(
              "v-container",
              {
                attrs: {
                  fluid: "",
                  "grid-list-lg": "",
                  "pa-0": "",
                  "fill-height": ""
                }
              },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      "align-space-between": "",
                      "justify-space-between": "",
                      column: "",
                      "fill-height": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "flex-none", attrs: { xs12: "" } },
                      [
                        _c("h2", { staticClass: "dialog-title" }, [
                          _vm._v("Afbeelding bewerken")
                        ])
                      ]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _vm.isLoading || _vm.isLoadingEditor
                          ? _c("LoaderCard", {
                              attrs: {
                                flat: "",
                                type: "spinner--center",
                                minHeight: "100%"
                              }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _vm.showErrorMessage
                                ? [
                                    _c(
                                      "v-layout",
                                      [
                                        _c("v-flex", [
                                          _c("span", [
                                            _vm._v(
                                              " Er ging iets mis bij het bewerken van de foto, upload de foto opnieuw of neem contact op met de IMG helpdesk. "
                                            )
                                          ])
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              !_vm.showErrorMessage
                                ? [
                                    _c(
                                      "v-layout",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isLoadingEditor,
                                            expression: "!isLoadingEditor"
                                          }
                                        ],
                                        attrs: {
                                          "align-space-between": "",
                                          "justify-space-between": "",
                                          column: "",
                                          "fill-height": ""
                                        }
                                      },
                                      [
                                        _vm.canAddDescription &&
                                        _vm.editorHeight.length > 0
                                          ? _vm._t("content", [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "flex-none",
                                                  attrs: {
                                                    xs12: "",
                                                    "mb-4": ""
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Beschrijving",
                                                      "hide-details": true
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fileDescription,
                                                      callback: function($$v) {
                                                        _vm.fileDescription = $$v
                                                      },
                                                      expression:
                                                        "fileDescription"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "flex-none imageEditor",
                                            class: {
                                              show: _vm.editorHeight.length > 0
                                            },
                                            style: { height: _vm.editorHeight },
                                            attrs: { "pa-0": "", xs12: "" }
                                          },
                                          [
                                            _vm.isLoadingAutoBlur
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "imageEditor__loader"
                                                  },
                                                  [
                                                    _c("LoaderCard", {
                                                      attrs: {
                                                        flat: "",
                                                        type: "spinner--center",
                                                        minHeight: "100%"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("canvas", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isActiveRecognition &&
                                                    !_vm.isLoadingAutoBlur &&
                                                    (_vm.isUsingMaskBrush ||
                                                      _vm.isErasingMaskBrush ||
                                                      _vm.isUsingLineTool),
                                                  expression:
                                                    "\n                        isActiveRecognition &&\n                        !isLoadingAutoBlur &&\n                        (isUsingMaskBrush || isErasingMaskBrush || isUsingLineTool)\n                      "
                                                }
                                              ],
                                              ref: "maskCanvas",
                                              style:
                                                "opacity: " + _vm.maskContrast,
                                              attrs: { id: "maskCanvas" },
                                              on: {
                                                mousemove: function($event) {
                                                  return _vm.onMouseMoveCanvasMask(
                                                    $event
                                                  )
                                                },
                                                mousedown: function($event) {
                                                  return _vm.onMouseDownCanvasMask(
                                                    $event
                                                  )
                                                },
                                                mouseup: function($event) {
                                                  return _vm.onMouseUpCanvasMask(
                                                    $event
                                                  )
                                                }
                                              }
                                            }),
                                            _c("canvas", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isActiveRecognition &&
                                                    !(
                                                      _vm.isUsingMaskBrush ||
                                                      _vm.isErasingMaskBrush ||
                                                      _vm.isUsingLineTool
                                                    ),
                                                  expression:
                                                    "isActiveRecognition && !(isUsingMaskBrush || isErasingMaskBrush || isUsingLineTool)"
                                                }
                                              ],
                                              ref: "canvas",
                                              attrs: { id: "canvas" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onClickCanvas(
                                                    $event
                                                  )
                                                },
                                                mousemove: function($event) {
                                                  return _vm.onMouseMoveCanvas(
                                                    $event
                                                  )
                                                }
                                              }
                                            }),
                                            _c("canvas", {
                                              ref: "resultCanvas",
                                              staticClass:
                                                "tw-hidden tw-border tw-border-error",
                                              attrs: { id: "resultCanvas" }
                                            }),
                                            _c("canvas", {
                                              ref: "combineCanvas",
                                              staticClass:
                                                "tw-hidden tw-border tw-border-error",
                                              attrs: { id: "combineCanvas" }
                                            }),
                                            _vm.isEditingImage
                                              ? _c("TuiImageEditor", {
                                                  ref: "imageEditor",
                                                  attrs: { "include-ui": false }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("div", { staticClass: "edit-image-dialog-bottom-bar" }, [
                      _c("div", [
                        !_vm.isActiveRecognition
                          ? _c("div", { staticClass: "imageEditBar" }, [
                              _c(
                                "div",
                                { staticClass: "imageEditBar__content" },
                                [
                                  !_vm.isCropping
                                    ? [
                                        _vm.undoChanged > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "inline-block" },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "span",
                                                                _vm._g({}, on),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      nativeOn: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.undo(
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "undo"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2527238244
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Ongedaan maken")
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "flex-none",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _vm.isActiveRecognition
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass:
                                            "tw-z-30 imageEditBar damageRecognition",
                                          attrs: {
                                            "justify-center": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "imageEditBar__content"
                                            },
                                            [
                                              _vm.hasSelectedPolygons
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inline-block"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.clearSelectedPolygons(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "clear"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2169389810
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Selectie verwijderen"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "inline-block" },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        class: {
                                                                          active:
                                                                            _vm.isUsingLineTool
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.useLineTool(
                                                                              $event
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " architecture "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2491875590
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Trek een streep"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "inline-block" },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        class: {
                                                                          active:
                                                                            _vm.isActiveRecognition &&
                                                                            !_vm.isUsingMaskBrush
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.useSelectTool(
                                                                              $event
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " highlight_alt "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        4223095311
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Selecteer schade"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              !_vm.hasSelectedPolygons
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inline-block"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            class: {
                                                                              active:
                                                                                _vm.isUsingMaskBrush
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.useBrush(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "brush"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            3031894905
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Markeer schade"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.hasSelectedPolygons
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inline-block"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            class: {
                                                                              active:
                                                                                _vm.isErasingMaskBrush
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.useEraser(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " auto_fix_normal "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            2832456131
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Uitgummen gemarkeerde schade"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isUsingMaskBrush ||
                                              _vm.isErasingMaskBrush ||
                                              _vm.isUsingLineTool
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-slider",
                                                                            {
                                                                              staticClass:
                                                                                "mt-0",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                label: _vm.maskToolsRadius.toString(),
                                                                                "inverse-label":
                                                                                  "",
                                                                                max: 80,
                                                                                min: 5,
                                                                                step: 5,
                                                                                "hide-details":
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.maskToolsRadius,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.maskToolsRadius = $$v
                                                                                },
                                                                                expression:
                                                                                  "maskToolsRadius"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              79893712
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Brush formaat"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block tw-ml-16"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-slider",
                                                                            {
                                                                              staticClass:
                                                                                "mt-0",
                                                                              attrs: {
                                                                                color:
                                                                                  "secondary",
                                                                                "append-icon":
                                                                                  "light_mode",
                                                                                "inverse-label":
                                                                                  "",
                                                                                max: 1,
                                                                                min: 0.2,
                                                                                step: 0.1,
                                                                                "hide-details":
                                                                                  ""
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm.maskContrast,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.maskContrast = $$v
                                                                                },
                                                                                expression:
                                                                                  "maskContrast"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              1246264081
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("Contrast")
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isActiveRecognition
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "tw-z-30 imageEditBar",
                                          attrs: {
                                            "justify-center": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "imageEditBar__content"
                                            },
                                            [
                                              !_vm.isCropping
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .circle
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.addShape(
                                                                                    "circle"
                                                                                  ),
                                                                                    _vm.setActiveEditor(
                                                                                      "circle"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " panorama_fish_eye "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              2636516140
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Plaats een cirkel op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .rect
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.addShape(
                                                                                    "rect"
                                                                                  ),
                                                                                    _vm.setActiveEditor(
                                                                                      "rect"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " crop_square "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              2967625964
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Plaats een vierkant op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .arrow
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addIcon()
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " arrow_forward "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              952232244
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Plaats een pijl op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "line",
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .line
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.draw(
                                                                                    "LINE_DRAWING"
                                                                                  ),
                                                                                    _vm.setActiveEditor(
                                                                                      "line"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " maximize "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              176537643
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Plaats een lijn op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .draw
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.draw(
                                                                                    "FREE_DRAWING"
                                                                                  ),
                                                                                    _vm.setActiveEditor(
                                                                                      "draw"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " gesture "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              3589638110
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Teken op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .text
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.addText(),
                                                                                    _vm.setActiveEditor(
                                                                                      "text"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " text_format "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              2305267663
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Typ een tekst op de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              class: {
                                                                                active:
                                                                                  _vm
                                                                                    .isActiveImageEditor
                                                                                    .blur
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.addFilledBlock(),
                                                                                    _vm.setActiveEditor(
                                                                                      "blur"
                                                                                    )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " texture "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              845889579
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Blur de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "inline-block"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.rotate(
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "rotate_right"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              45762139
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "draai de afbeelding"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "inline-block" },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    !_vm.isCropping
                                                                      ? _c(
                                                                          "v-icon",
                                                                          {
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.enableCrop(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "crop"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isCropping
                                                                      ? _c(
                                                                          "v-icon",
                                                                          {
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.crop(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "check"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        4200483075
                                                      )
                                                    },
                                                    [
                                                      !_vm.isCropping
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Crop de afbeelding"
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm.isCropping
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "Crop opslaan"
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.isCropping
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inline-block"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.cancelCrop(
                                                                                  $event
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "close"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            226200666
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Crop annuleren"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "edit-image-dialog-bottom-bar__right" },
                        [
                          !_vm.isLoading && !_vm.isLoadingEditor
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-flex tw-flex-row tw-flex-no-wrap"
                                  },
                                  [
                                    _vm.canRecognizeDamages
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-flex-shrink tw-pr-16"
                                          },
                                          [
                                            !_vm.hasAttachedPolygons
                                              ? _c(
                                                  "MiButton",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.isLoadingAutoBlur,
                                                      color: _vm.isActiveRecognition
                                                        ? "warning"
                                                        : "secondary",
                                                      small: true,
                                                      outline: true
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.onClickRecognizeDamages(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.recognizeDamagesLabel
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "tw-flex-shrink" },
                                      [
                                        [
                                          _vm.$store.state.isGDPR
                                            ? _c(
                                                "MiButton",
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    disabled:
                                                      _vm.isLoadingAutoBlur,
                                                    color: "secondary",
                                                    small: "true"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onClickAutoBlur(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" GDPR toepassen ")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mr-3 fadedText link",
                                              on: {
                                                click: _vm.closeEditingImage
                                              }
                                            },
                                            [_vm._v("Annuleren")]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "success",
                                                small: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.submit($event)
                                                }
                                              }
                                            },
                                            [_vm._v("Afbeelding opslaan")]
                                          )
                                        ]
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }