var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasReasonOrComment
        ? _c(
            "v-layout",
            { staticClass: "rejectionContainer", attrs: { wrap: "", row: "" } },
            [
              _vm.hasReason && _vm.showReason
                ? _c(
                    "v-flex",
                    {
                      staticClass: "rejectionContainer__reject-reason",
                      attrs: { xs12: "" }
                    },
                    [
                      _c("h4", [_vm._v("Afkeurreden(en):")]),
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.model.reject_reasons, function(
                            reason,
                            index
                          ) {
                            return [
                              _c(
                                "li",
                                { key: index },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getReasonName(reason)) +
                                      " "
                                  ),
                                  _vm.showCategories
                                    ? [
                                        _vm._v(
                                          " (" +
                                            _vm._s(reason.group) +
                                            ") " +
                                            _vm._s(_vm.parseFTR(reason)) +
                                            " "
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e(),
              _vm.hasComment && _vm.showComment
                ? _c(
                    "v-flex",
                    {
                      staticClass: "rejectionContainer__reject-comment",
                      attrs: { xs12: "" }
                    },
                    [
                      _c("h4", [_vm._v("Toelichting:")]),
                      _c("span", [_vm._v(_vm._s(_vm.model.reject_comment))])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }