import { User } from '@/models/User';
import Vue from 'vue';
import Vuex from 'vuex';
import { Route } from 'vue-router';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Breadcrumb } from '@/support/Breadcrumb';
import { ReportType } from '@/models/ReportType';
import { isServiceOrganization, isProduction, isStaging, isAcceptance, isLocal, hasTimeModule } from '@/support/ApplicationMode';
import { UserFilter } from '@/models/UserFilter';
import { Damage } from '@/models/Damage';
import { redirect } from '@/support/Url';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    selectedPlanningDate: '',
    miniNav: false,
    alert: { message: '', show: false } as Alert,
    Auth: null as User | null,
    damages: null as Damage[] | null,
    reportId: '',
    userFilters: [] as UserFilter[],
    lastUsedReportFilter: null as UserFilter | null,
    router: {} as Router,
    selected: null,
    dialog: null,
    breadcrumbs: [] as Breadcrumb[],
    reportTypes: [] as ReportType[],
    isServiceOrganization: isServiceOrganization(),
    displayQuestionKeys: false,
    isBETA: false,
    isGDPR: false,
    isStaging: isStaging(),
    isProduction: isProduction(),
    isAcceptance: isAcceptance(),
    isDisplayingNavigation: true,
    isLocal: isLocal(),
    hasTimeModule: hasTimeModule(),
    answerPayload: null as null,
    updateIsReady: false as boolean, // application update status set by service worker
    filters: {},
  },

  getters: {
    reportTypes(state) {
      return state.reportTypes;
    },
    userFilters: (state) => state.userFilters,
    updateIsReady: (state) => state.updateIsReady,
    filters: (state) => state.filters,
  },

  // mutations are the voltooid verleden tijd of an action.
  mutations: {
    MAINTENANCE_DETECTED: (state, payload) => {
      redirect('/maintenance');
    },
    USER_AUTHENTICATED: (state, payload) => {
      state.Auth = payload;
      state.isBETA = payload.is_beta;
      state.isGDPR = [
        'a.warnaar@nivrecp.nl',
        'R.Panman@nivrecp.nl',
        'w.berentsen@nivrecp.nl',
        'l.smilde@dog-ingenieurs.nl',
        'r.vanderlaan@dog-ingenieurs.nl',
        's.witteveen@dog-ingenieurs.nl',
        'hessel.van.hout@ced.nl',
        'joris.nannenberg@ced.nl',
        'remko.van.der.sluis@ced.nl',
        't.lever@bouwadviesvanmaaren.nl',
        'Jan.Heys@smeetsbma.nl',
        'Dre.Visscher@rvo.nl',
        'Daniel.Boersma@schadedoormijnbouw.nl',
        'nico.veenstra@schadedoormijnbouw.nl',
        // @atabix.nl user accounts
        'paul@atabix.nl',
        'mike.lam@atabix.nl',
        'elwin.devries@atabix.nl',
        'lex.vanmeijer@atabix.nl',
        'bryse.meijer@atabix.nl',
        'joep.vandenoever@atabix.nl',
        'artan.fetahaj@atabix.nl',
        'thomas.vandenoever@atabix.nl',
        'daan.hage@atabix.nl',
        'nico.veenstra@schadedoormijnbouw.nl',
      ].some(((email) => email === payload.email));

      state.hasTimeModule = hasTimeModule() || state.isBETA;
    },
    USER_DEAUTHENTICATED: (state, payload) => {
      if (state.Auth !== null && state.Auth !== undefined) {
        (state.Auth as User).logout();
        redirect('/');
      }

      state.Auth = null;
    },
    DRAWER_TOGGLE: (state: any, forcedState: boolean) => {
      state.drawer = ! state.drawer;
    },
    MINI_TOGGLE: (state: any, forcedState: boolean) => {
      if (forcedState !== undefined) {
        state.miniNav = forcedState;
        return;
      }

      state.miniNav = ! state.miniNav;
    },
    UPDATE_DAMAGES: (state: any, payload: any) => {
      state.damages = payload;
    },
    SELECTED_PLANNING_DATE_UPDATE: (state: any, payload: string) => {
      state.selectedPlanningDate = payload;
    },

    // User Filters
    USERFILTERS_CHANGE: (state: any, payload) => {
      state.userFilters = payload;
    },
    USERFILTERS_UPDATE: (state: any, payload) => {
      state.userFilters = [...state.userFilters, ...[payload]];
    },
    REPORTFILTER_CHANGE: (state: any, payload) => {
      state.reportFilter = payload;
    },
    LASTUSEDREPORTFILTER_CHANGE: (state: any, payload) => {
      state.lastUsedReportFilter = payload;
    },
    SELECTED_CHANGE: (state: any, payload) => {
      state.selected = payload;
    },
    DIALOG_OPENED: (state: any, options: Options) => {
      state.dialog = options;
    },
    DIALOG_CLOSED: (state: any, options: null) => {
      state.dialog = null;
    },
    BREADCRUMBS_CHANGED: (state, payload: Breadcrumb[]) => {
      state.breadcrumbs = payload;
    },
    ANSWERPAYLOAD_SAVED: (state: any, payload: any) => {
      state.answerPayload = payload;
    },
    ISUPDATEREADY_TOGGLED: (state: any, payload: boolean) => {
      state.updateIsReady = payload;
    },
    REPORTID_UPDATE: (state: any, payload: string) => {
      state.reportId = payload;
    },
    ALERT_UPDATE: (state: any, payload: any) => {
      state.alert = payload;
    },

    // ALL SAVED FILTERS
    UPDATE_FILTERS: (state: any, payload: any) => {
      state.filters = { ...state.filters, ...payload };
    },

    SET_DISPLAYING_NAVIGATION: (state: any, payload: any) => {
      state.isDisplayingNavigation = payload;
    },
  },

  actions: {
    maintenanceDetected: (context, payload) => {
      context.commit('MAINTENANCE_DETECTED', payload);
    },
    userAuthenticated: (context, payload) => {
      context.commit('USER_AUTHENTICATED', payload);
    },
    userDeauthenticated: (context, payload) => {
      context.commit('USER_DEAUTHENTICATED', payload);
    },
    toggleDrawer: (context: any, payload: boolean) => {
      context.commit('DRAWER_TOGGLE', payload);
    },
    updateDamages: (context: any, payload: any) => {
      context.commit('UPDATE_DAMAGES', payload);
    },
    toggleMini: (context: any, payload: boolean) => {
      context.commit('MINI_TOGGLE', payload);
    },
    updateSelectedPlanningDate: (context: any, payload: string) => {
      context.commit('SELECTED_PLANNING_DATE_UPDATE', payload);
    },

    // User filters
    changeUserFilters: (context: any, payload: string) => {
      context.commit('USERFILTERS_CHANGE', payload);
    },
    updateUserFilters: (context: any, payload: string) => {
      context.commit('USERFILTERS_UPDATE', payload);
    },
    changeLastUsedReportFilter: (context: any, payload: string) => {
      context.commit('LASTUSEDREPORTFILTER_CHANGE', payload);
    },

    changeReportFilter: (context: any, payload: string) => {
      context.commit('REPORTFILTER_CHANGE', payload);
    },
    // changeReportStatisticsFilter: (context: any, payload: string) => {
    //   context.commit('REPORSTATISTICSTFILTER_CHANGE', payload);
    // },
    // changeReportFilters: (context: any, payload: string) => {
    //   context.commit('REPORTFILTERS_CHANGE', payload);
    // },

    selectedChanged: (context: any, payload: any) => {
      context.commit('SELECTED_CHANGE', payload);
    },
    openDialog: (context: any, options: Options) => {
      context.commit('DIALOG_OPENED', options);
    },
    closeDialog: (context: any, options = null) => {
      context.commit('DIALOG_CLOSED', options);
    },
    changeBreadcrumbs: (context: any, breadcrumbs: Breadcrumb[] = []) => {
      context.commit('BREADCRUMBS_CHANGED', breadcrumbs);
    },
    saveAnswerPayload: (context: any, payload: any) => {
      context.commit('ANSWERPAYLOAD_SAVED', payload);
    },
    toggleUpdateIsReady: (context: any, payload: boolean) => {
      context.commit('ISUPDATEREADY_TOGGLED', payload);
    },
    updateReportId: (context: any, payload: boolean) => {
      context.commit('REPORTID_UPDATE', payload);
    },
    updateAlert: (context: any, payload: any) => {
      context.commit('ALERT_UPDATE', payload);
    },

    // ALL SAVED FILTERS
    filtersUpdate: (context: any, payload: any) => {
      context.commit('UPDATE_FILTERS', payload);
    },

    setDisplayingNavigation: (context: any, payload: any) => {
      context.commit('SET_DISPLAYING_NAVIGATION', payload);
    },
  },
});

interface Router {
  previous: Route;
  current: Route;
}

interface Alert {
  message: string;
  show: boolean;
  sync?: boolean;
}
