import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { YearFilters } from '@/support/years';

@Component<DateFilterDialog>({})
export default class DateFilterDialog extends Vue {
  @Prop()
  protected type!: string;

  protected isLoading = false;

  protected years: string[] = YearFilters;

  protected filters = {
    q: [
      {
        name: 'Q1',
        from: '01',
        to: '03',
      },
      {
        name: 'Q2',
        from: '04',
        to: '06',
      },
      {
        name: 'Q3',
        from: '07',
        to: '09',
      },
      {
        name: 'Q4',
        from: '10',
        to: '12',
      },
    ],
    month: [
      {
        name: 'jan',
        value: '01',
      },
      {
        name: 'feb',
        value: '02',
      },
      {
        name: 'mrt',
        value: '03',
      },
      {
        name: 'apr',
        value: '04',
      },
      {
        name: 'mei',
        value: '05',
      },
      {
        name: 'jun',
        value: '06',
      },
      {
        name: 'jul',
        value: '07',
      },
      {
        name: 'aug',
        value: '08',
      },
      {
        name: 'sept',
        value: '09',
      },
      {
        name: 'okt',
        value: '10',
      },
      {
        name: 'nov',
        value: '11',
      },
      {
        name: 'dec',
        value: '12',
      },
    ],
  }

  protected close() {
    this.$emit('input', false);
  }

  protected parseYearFrom(year: string) {
    return `${year}-01-01`;
  }

  protected parseYearTo(year: string) {
    const lastDayOfMonth = DateTime.local(Number(year), 12).daysInMonth;
    return `${year}-12-${lastDayOfMonth}`;
  }

  protected parseQFrom(year: string, q: any) {
    return `${year}-${q.from}-01`;
  }

  protected parseQTo(year: string, q: any) {
    const lastDayOfMonth = DateTime.local(Number(year), Number(q.to)).daysInMonth;
    return `${year}-${q.to}-${lastDayOfMonth}`;
  }

  protected parseMonthFrom(year: string, month: any) {
    return `${year}-${month.value}-01`;
  }

  protected parseMonthTo(year: string, month: any) {
    const lastDayOfMonth = DateTime.local(Number(year), Number(month.value)).daysInMonth;
    return `${year}-${month.value}-${lastDayOfMonth}`;
  }

  protected setPeriod(from: string, to: string) {
    this.$emit('applyDateFilters', from, to);
    this.close();
  }
}
