var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "approvalBar" }, [
    _c(
      "div",
      { staticClass: "approvalBarContainer" },
      [
        _c(
          "v-layout",
          { attrs: { wrap: "" } },
          [
            _vm.backUrl || _vm.backFunction
              ? _c("v-flex", { staticClass: "text-xs-left" }, [
                  _c("div", { staticClass: "backContainer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "back",
                        on: { click: _vm.handleBackAction }
                      },
                      [
                        _c("v-icon", [_vm._v("arrow_back")]),
                        _c("span", [_vm._v(_vm._s(_vm.backText))])
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _c("v-flex", [_vm._t("content")], 2)
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }