import { LibraryTag } from '@/models/LibraryTag';
import { RepairType } from '@/models/Repair';
import { DefaultAnswer } from '@/models/DefaultAnswer';
import { Model } from '@/models/Model';
import { icons, Folder } from '@/models//Folder';

export class Article extends Model {
  protected $name = 'Article';

  protected $endpoint = '/library/articles';

  protected $fillable: string[] = [
    'content',
    'description',
    'notes',
  ];

  public id?: string;

  public title?: string;

  public content?: string;

  public description?: string;

  public notes?: string;

  public updated_at?: string;

  public created_at?: string;

  public repair_types?: RepairType[];

  public default_answers?: DefaultAnswer[];

  public tags?: LibraryTag[];

  public media?: File[];

  public status?: ArticleStatus;

  public children?: Article[] | Folder[];

  protected article_icon?: string;

  protected article_internal_type?: string;

  public mediaEndpoint() {
    this.$endpoint = `/library/articles/${this.id}/media`;
    return this;
  }

  public deleteMediaRequest(id: string): Promise<any> {
    const url = `/library/articles/${this.id}/media/${id}`;

    return this.request('delete', url);
  }

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public get icon() {
    return icons[this.internal_type || ''];
  }

  public set icon(icon) {
    this.article_icon = icon;
  }

  public get internal_type() {
    return this.article_internal_type;
  }

  public set internal_type(type) {
    this.article_internal_type = type;
  }
}

type ArticleStatus = 'released' | 'draft';
