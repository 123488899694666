import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { Organization } from '@/models/Organization';

@Component<ReportOrganizationFilter>({})
export default class ReportOrganizationFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  protected organizations: Organization[] = [];

  protected selectedOrganizations: string[] = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedOrganizations);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedOrganizations = [];
    } else {
      this.selectedOrganizations = cloneDeep(this.value);
    }
  }

  public async mounted() {
    await this.getOrganizations();
  }

  protected async getOrganizations(): Promise<void> {
    this.organizations = await new Organization()
      .getAllExperts()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedOrganizations', this.organizations.map((organization: Organization) => organization.id || ''));
    } else {
      this.$set(this, 'selectedOrganizations', []);
    }

    this.handleChange();
  }
}
