var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showNavigation
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "navigationContainer__tenantBrandColor" },
            [
              _vm.isShowingSync
                ? [
                    _c("v-progress-linear", {
                      staticClass: "custom-progressloader",
                      attrs: { indeterminate: true }
                    }),
                    _c(
                      "v-alert",
                      {
                        staticClass: "custom-alert",
                        staticStyle: { height: "10px" },
                        attrs: { value: true, type: "error" }
                      },
                      [
                        _vm._v(
                          " Dit dossier is nog bezig met het overzetten van data, een ogenblik geduld alstublieft. "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _c(
                "v-alert",
                {
                  staticClass: "custom-alert",
                  staticStyle: { height: "10px" },
                  attrs: {
                    value: _vm.$store.state.alert.show && !_vm.isShowingSync,
                    type: "error"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$store.state.alert.message) + " ")]
              )
            ],
            2
          ),
          _c(
            "v-container",
            {
              staticClass: "navigationContainer",
              class: {
                condensed: _vm.isCondensed,
                withAlert: _vm.$store.state.alert.show || _vm.isShowingSync
              },
              attrs: { "pa-0": "", fluid: "" }
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "navigationContainer__topBar",
                  attrs: { "align-center": "", row: "", wrap: "" }
                },
                [
                  _c("v-flex", { staticClass: "logoContainer flex-starving" }, [
                    _vm.isAcceptance
                      ? _c("img", {
                          staticClass: "logoContainer__logo",
                          attrs: {
                            src: require("@/assets/images/logos/acceptance-logo.png")
                          }
                        })
                      : _c("img", {
                          staticClass: "logoContainer__logo",
                          attrs: {
                            src: require("@/assets/images/IMG-logo.svg")
                          }
                        })
                  ]),
                  _c(
                    "v-flex",
                    { staticClass: "breadcrumbContainer" },
                    [_c("Breadcrumbs", { attrs: { crumbs: _vm.breadcrumbs } })],
                    1
                  ),
                  _vm.user.type !== "simple" && _vm.user.type !== "simple_plus"
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "userContainer flex-starving",
                          attrs: { "hidden-sm-and-down": "" }
                        },
                        [_c("MiGlobalSearch")],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "userContainer flex-starving",
                      attrs: { "hidden-sm-and-down": "" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          staticClass: "settingsMenu",
                          attrs: {
                            transition: "slide-y-transition",
                            "offset-y": ""
                          },
                          model: {
                            value: _vm.isSettingsMenuOpen,
                            callback: function($$v) {
                              _vm.isSettingsMenuOpen = $$v
                            },
                            expression: "isSettingsMenuOpen"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "userContainer__user",
                              attrs: { slot: "activator" },
                              slot: "activator"
                            },
                            [
                              _c("img", {
                                staticClass: "userContainer__avatar",
                                attrs: { src: _vm.userAvatar }
                              }),
                              _c(
                                "span",
                                { staticClass: "userContainer__username" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.Auth.name
                                          ? _vm.$store.state.Auth.name
                                          : _vm.$store.state.Auth.email
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "hamburger hamburger--collapse",
                                  class: { "is-active": _vm.isSettingsMenuOpen }
                                },
                                [
                                  _c("div", { staticClass: "hamburger-box" }, [
                                    _c("div", {
                                      staticClass: "hamburger-inner"
                                    })
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-list",
                            { staticClass: "submenuList" },
                            [
                              _vm._l(_vm.settingItems, function(item, i) {
                                return [
                                  item.visible
                                    ? [
                                        item.link
                                          ? _c(
                                              "v-list-tile",
                                              {
                                                key: "link-" + i,
                                                staticClass:
                                                  "submenuList__item",
                                                attrs: { to: item.link }
                                              },
                                              [
                                                item.icon
                                                  ? _c(
                                                      "v-list-tile-avatar",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _vm._v(_vm._s(item.title))
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-tile",
                                              {
                                                key: "action-" + i,
                                                staticClass:
                                                  "submenuList__item",
                                                on: { click: item.action }
                                              },
                                              [
                                                item.icon
                                                  ? _c(
                                                      "v-list-tile-avatar",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _vm._v(_vm._s(item.title))
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                      ]
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "userContainer topBar__menuToggle flex-starving align-center",
                      attrs: { xs2: "", "hidden-md-and-up": "" },
                      on: {
                        click: function($event) {
                          _vm.isMenuActive = !_vm.isMenuActive
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "hamburger hamburger--collapse",
                          class: { "is-active": _vm.isMenuActive }
                        },
                        [
                          _c("div", { staticClass: "hamburger-box" }, [
                            _c("div", { staticClass: "hamburger-inner" })
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "navigationContainer__menuBar",
                  class: {
                    active: _vm.isMenuActive,
                    expertView: _vm.$route.name === "expert.editor"
                  },
                  attrs: { "align-center": "", row: "", wrap: "" }
                },
                [
                  _c("v-flex", { attrs: { sm12: "" } }, [
                    _c(
                      "ul",
                      { staticClass: "menuBar__menuList" },
                      [
                        _vm._l(_vm.items, function(item, index) {
                          return [
                            _vm.canViewItem(item)
                              ? _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "menuList__item",
                                    class: { active: item.active }
                                  },
                                  [
                                    !item.external &&
                                    !item.action &&
                                    !item.items
                                      ? _c(
                                          "router-link",
                                          {
                                            staticClass: "item__link",
                                            class: _vm.checkForActive(item)
                                              ? "hasActive"
                                              : "",
                                            attrs: { to: item.link, tag: "a" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: { click: _vm.resetFilters }
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    item.items && _vm.isTablet
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "offset-y": "",
                                              top: "",
                                              "content-class":
                                                "submenuList--container"
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "item__link",
                                                attrs: { slot: "activator" },
                                                slot: "activator"
                                              },
                                              [_vm._v(_vm._s(item.title))]
                                            ),
                                            _c(
                                              "v-list",
                                              { staticClass: "submenuList" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "submenuList__back"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("arrow_back")
                                                    ]),
                                                    _vm._v(" Terug ")
                                                  ],
                                                  1
                                                ),
                                                _vm._l(item.items, function(
                                                  item,
                                                  index
                                                ) {
                                                  return [
                                                    _vm.canViewItem(item)
                                                      ? _c(
                                                          "v-list-tile",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "submenuList__item",
                                                            attrs: {
                                                              to: item.link
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getStatusLabel(
                                                                        item.title
                                                                      ) !==
                                                                        "Onbekend"
                                                                        ? _vm.getStatusLabel(
                                                                            item.title
                                                                          )
                                                                        : item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                })
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.items && !_vm.isTablet
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "offset-y": "",
                                              "content-class":
                                                "submenuList--container"
                                            }
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "item__link",
                                                class: _vm.checkForActive(item)
                                                  ? "hasActive"
                                                  : "",
                                                attrs: { slot: "activator" },
                                                slot: "activator"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getStatusLabel(
                                                        item.title
                                                      ) !== "Onbekend"
                                                        ? _vm.getStatusLabel(
                                                            item.title
                                                          )
                                                        : item.title
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-list",
                                              { staticClass: "submenuList" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "submenuList__back"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("arrow_back")
                                                    ]),
                                                    _vm._v(" Terug ")
                                                  ],
                                                  1
                                                ),
                                                _vm._l(item.items, function(
                                                  item,
                                                  index
                                                ) {
                                                  return [
                                                    _vm.canViewItem(item) &&
                                                    !item.divider
                                                      ? _c(
                                                          "v-list-tile",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "submenuList__item",
                                                            class: _vm.checkForActive(
                                                              item
                                                            )
                                                              ? "hasActive"
                                                              : "",
                                                            attrs: {
                                                              to: item.link
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.resetFilters
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getStatusLabel(
                                                                        item.title
                                                                      ) !==
                                                                        "Onbekend"
                                                                        ? _vm.getStatusLabel(
                                                                            item.title
                                                                          )
                                                                        : item.title
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    item.divider
                                                      ? _c(
                                                          "v-list-tile",
                                                          {
                                                            key:
                                                              "divider-" +
                                                              index,
                                                            staticClass:
                                                              "itemDivider"
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              {
                                                                staticClass:
                                                                  "itemDivider__title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                })
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.external && !item.items
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "item__link",
                                            attrs: { href: item.link },
                                            on: { click: _vm.resetFilters }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getStatusLabel(
                                                    item.title
                                                  ) !== "Onbekend"
                                                    ? _vm.getStatusLabel(
                                                        item.title
                                                      )
                                                    : item.title
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }),
                        _vm.settingItems && _vm.isTablet
                          ? [
                              _vm._l(_vm.settingItems, function(
                                settingItem,
                                index
                              ) {
                                return [
                                  index === 0
                                    ? _c(
                                        "li",
                                        {
                                          key: "setting--" + index,
                                          staticClass:
                                            "menuList__item menuList__item--setting border"
                                        },
                                        [
                                          settingItem.action
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "item__link",
                                                  on: {
                                                    click: settingItem.action
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusLabel(
                                                          settingItem.title
                                                        ) !== "Onbekend"
                                                          ? _vm.getStatusLabel(
                                                              settingItem.title
                                                            )
                                                          : settingItem.title
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "item__link" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusLabel(
                                                          settingItem.title
                                                        ) !== "Onbekend"
                                                          ? _vm.getStatusLabel(
                                                              settingItem.title
                                                            )
                                                          : settingItem.title
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                    : _c(
                                        "li",
                                        {
                                          key: "setting--" + index,
                                          staticClass:
                                            "menuList__item menuList__item--setting border"
                                        },
                                        [
                                          settingItem.action
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "item__link",
                                                  on: {
                                                    click: settingItem.action
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusLabel(
                                                          settingItem.title
                                                        ) !== "Onbekend"
                                                          ? _vm.getStatusLabel(
                                                              settingItem.title
                                                            )
                                                          : settingItem.title
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "item__link" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusLabel(
                                                          settingItem.title
                                                        ) !== "Onbekend"
                                                          ? _vm.getStatusLabel(
                                                              settingItem.title
                                                            )
                                                          : settingItem.title
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                ]
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }