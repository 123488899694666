import { isArray, debounce } from 'lodash';
import { Answer } from '@/models/Answer';
import { Question as QuestionModel, Option } from '@/models/Question';
import { Component, Vue, Watch, Prop, InjectReactive } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import PrefillableTextArea from '@/components/prefillable-text-area/PrefillableTextArea.vue';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';
import { Damage } from '@/models/Damage';
import QuestionActions from '@/components/Questionnaire/QuestionActions.vue';
import CausalityQuestion from '@/components/Questionnaire/CausalityQuestion/CausalityQuestion.vue';
import BuildingFeaturesQuestion from '@/components/Questionnaire/BuildingFeaturesQuestion/BuildingFeaturesQuestion.vue';
import ImageRecognitionQuestion from '@/components/Questionnaire/ImageRecognitionQuestion/ImageRecognitionQuestion.vue';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<Question>({
  name: 'question',
  components: {
    PrefillableTextArea,
    QuestionActions,
    CausalityQuestion,
    BuildingFeaturesQuestion,
    ImageRecognitionQuestion,
  },
})
export default class Question extends Vue {
  // Injects
  @InjectReactive() // Provided at Expert.ts
  protected isImageRecognitionAvaliable!: boolean;

  @Prop()
  protected question!: QuestionModel;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: false })
  protected enableReadMode!: boolean;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop({ default: false })
  protected toggleAllEditMode!: boolean;

  protected readMode = false;

  protected editMode = true;

  @Prop()
  protected answers!: Answer[] | {[key: string]: string};

  @Prop()
  protected report!: Report;

  @Prop()
  protected damage!: Damage;

  @Prop()
  protected hiddenQuestionKeys!: string[];

  protected PermissionSlug = PermissionSlug;

  protected inputValue = '';

  protected inputValueCheckbox: string[] = [];

  protected refreshChildren = false;

  protected inputValueRadio: Option = {};

  protected date: DateFormatter = new DateFormatter();

  protected assumedValue = false;

  protected checkboxInputChosenOptions: Option[] = [];

  protected answerDebounce: Function = debounce(this.saveAnswer, 500);

  protected async mounted() {
    this.setAnswers();
    this.setAssumedAnswers();
    this.initializeHiddenQuestionKeys();

    if (this.$refs.quillEditor) {
      (this.$refs.quillEditor as any).quill.enable(! this.disabled);
    }

    this.initReadMode();
  }

  protected async toggleAssumable(value?: boolean): Promise<void> {
    const questionKey = this.question?.key || '';
    const assumedValue = typeof value === 'boolean' ? value : this.assumedValue;

    if (this.damage && questionKey) {
      const updatedDamage = await this.damage.update({ assumed_answers: {
        [questionKey]: this.$set(this.damage, 'assumed_answers', assumedValue),
      } });

      this.$emit('damageAnswerSaved', updatedDamage, this.question);
    }
  }

  protected initReadMode() {
    if (this.readOnly) {
      this.readMode = true;
      this.editMode = false;
    }

    if (this.question.is_formattable) { return; }

    if (this.enableReadMode || this.answerValue?.length) {
      if (this.answerValue?.length) {
        this.readMode = true;
        this.editMode = false;
      }
    }
  }

  protected toggleEditMode() {
    if (this.readOnly) {
      return;
    }
    this.editMode = ! this.editMode;
    this.readMode = ! this.readMode;
  }

  protected disableEditMode() {
    if (this.question.is_formattable) { return; }
    if (! this.enableReadMode) { return; }

    if (this.answerValue?.length) {
      this.editMode = false;
      this.readMode = true;
    }
  }

  protected determineEditMode() {
    if (this.question.is_formattable) { return; }
    if (! this.enableReadMode) { return; }

    if (this.answerValuePayload && this.answerValuePayload.length > 0) {
      this.readMode = true;
      this.editMode = false;
      return;
    }

    this.readMode = false;
    this.editMode = true;
  }

  protected onClickInfo(question: QuestionModel): void {
    this.$emit('onClickInfo', question);
  }

  protected onClickMetaPgvLimit(question: QuestionModel): void {
    this.$emit('onClickMetaPgvLimit', question);
  }

  protected setAnswers() {
    if (! this.answer) {
      return;
    }

    if (this.question.type === 'date') {
      return this.fillDateInput();
    }

    if (this.question.type === 'radio') {
      return this.fillRadioInput();
    }

    if (this.question.type === 'checkbox') {
      return this.fillCheckboxInput();
    }

    return this.fillInputValue();
  }

  protected setAssumedAnswers() {
    if (! this.damage?.assumed_answers) {
      return;
    }

    this.assumedValue = this.damage?.assumed_answers[this.question?.key || ''] || false;
  }

  protected fillInputValue() {
    this.inputValue = this.answerToString();
  }

  protected fillCheckboxInput() {
    const answerModelValue = (this.answer as Answer).value;

    if (answerModelValue) {
      this.inputValueCheckbox = [...answerModelValue as string, ...[]];
      return;
    }

    if (Array.isArray(this.answer)) {
      this.inputValueCheckbox = this.answer;
    } else {
      this.inputValueCheckbox = [this.answer as string];
    }
  }

  protected fillDateInput() {
    const answer = this.answerToString();

    if (answer && answer === 'Invalid DateTime') {
      return;
    }

    this.date.selectedDate = DateTime.fromSQL(answer).toFormat('yyyy-MM-dd');
  }

  protected answerToString() {
    const answerModelValue = (this.answer as Answer).value;
    if (answerModelValue !== undefined) {
      return answerModelValue as string;
    }

    return this.answer as string;
  }

  protected fillRadioInput() {
    if (! this.question.options) { return; }

    const answerModelValue = (this.answer as Answer).value;
    this.question.options.forEach((option: Option) => {
      if (answerModelValue && (option.value === answerModelValue)) {
        this.inputValueRadio = option;
        return;
      }

      if (option.value === this.answer) {
        this.inputValueRadio = option;
      }
    });
  }

  protected textareaDebounce(value: string) {
    this.inputValue = value;
    this.saveAnswer();
  }

  protected textareaChanged(value: string) {
    this.inputValue = value;
    this.questionAnswered();
  }

  protected async questionAnswered() {
    await this.saveAnswer();
    this.determineEditMode();
  }

  protected async saveAnswer() {
    if (this.damage) {
      return await this.saveDamageAnswer();
    }

    if (this.report) {
      return await this.saveReportAnswer();
    }
  }

  protected async saveDamageAnswer() {
    if (! this.damage) { return; }

    const payload: {[key: string]: any} = {
      key: this.question.key,
      value: this.answerValuePayload,
    };

    this.refreshChildren = true;
    const damage = await new Damage(this.damage)
      .updateAnswer()
      .include(['validations'])
      .create(payload)
      .catch((error: AxiosError) => {
        console.log('error', error);
      });
    this.refreshChildren = false;
    this.$emit('damageAnswerSaved', damage, this.question);
    if (this.question.type === 'radio') {
      this.disableEditMode();
    }
  }

  protected reportAnswerSaved(answer: Answer) {
    this.$emit('reportAnswerSaved', answer);
  }

  protected reloadAnswers() {
    this.$emit('reloadAnswers');
  }

  protected reloadReportAnswers() {
    this.$emit('reloadReportAnswers');
  }

  protected reloadDamageAnswers() {
    this.$emit('reloadDamageAnswers');
  }

  protected damageAnswerSaved(answer: Answer, question: Question) {
    this.$emit('damageAnswerSaved', answer, question);
  }

  protected async saveReportAnswer() {
    const payload = {
      value: this.answerValuePayload,
      report: this.report.uuid,
      question: this.question.uuid,
    };

    this.refreshChildren = true;
    const answer = await new Answer()
      .create(payload)
      .catch((error: AxiosError) => {
        console.log('error', error);
      });
    this.refreshChildren = false;

    this.$emit('reportAnswerSaved', answer);
    if (this.question.type === 'radio') {
      this.disableEditMode();
    }
  }

  protected isVisibleDeleteButton(option: Option) {
    if (! this.inputValueRadio || ! option) {
      return false;
    }

    return option.value === this.inputValueRadio.value;
  }

  protected get answerValuePayload() {
    if (this.question.type === 'radio') {
      return this.inputValueRadio.value;
    }

    if (this.question.type === 'checkbox') {
      return this.inputValueCheckbox;
    }

    if (this.question.type === 'date' && this.date.selectedDate) {
      return DateTime.fromSQL(this.date.selectedDate).toFormat('yyyy-LL-dd HH:mm:ss');
    }

    return this.inputValue;
  }

  protected isSelectedCheckbox(option: Option) {
    return this.inputValueCheckbox.some((id: string) => option.uuid === id);
  }

  //
  // set Answers
  //
  protected getAnswerForQuestion(question: QuestionModel) {
    if (isArray(this.answers)) {
      return this.getAnswerFromAnswerModel(question);
    }

    return this.getAnswerFromObject(question) as string;
  }

  protected getAnswerFromAnswerModel(question: QuestionModel) {
    const answer = (this.answers as Answer[]).find((answer: Answer) => answer.key === question.key);

    return answer || null;
  }

  protected getAnswerFromObject(question: QuestionModel) {
    if (! question || ! question.key || ! this.answers) { return; }

    if (! (this.answers as {[key: string]: string})[question.key]) {
      return '';
    }
    const answer = (this.answers as {[key: string]: string})[question.key];

    return answer || null;
  }

  protected get answer() {
    return this.getAnswerForQuestion(this.question);
  }

  protected get answerValue() {
    if (this.question.type === 'date') {
      return this.date.selectedDate;
    }

    if (this.question.type === 'radio') {
      return this.inputValueRadio.value;
    }

    if (this.question.type === 'checkbox') {
      const answeredOptions: Option[] = [];
      this.inputValueCheckbox.forEach((answer: string) => {
        if (this.question.options) {
          const option = this.question.options.find((option: Option) => (this.damage ? option.key === answer : option.uuid === answer));
          if (option) {
            answeredOptions.push(option);
          }
        }
      });

      return answeredOptions;
    }

    return this.inputValue;
  }

  protected get isCausalityQuestion(): boolean {
    return this.question.key === 'MijnbouwCausaalVerband' && this.report.canAccessCausalityWizard;
  }

  protected get isBuildingFeaturesQuestion(): boolean {
    return this.question.key === 'KenmerkenGevoeligObject' && this.report.canAccessBuildingFeatureWizard;
  }

  protected get isImageRecognitionQuestion(): boolean {
    return this.question.key === 'EBS_Dossiernummer_Beeldherkenning_Banner' && this.report.canUseImageRecognition;
  }

  protected get parsedQuestionLabel(): string {
    if (! this.question?.label) {
      return '';
    }

    if (! this.question.meta?.bold) {
      return this.question.label;
    }

    return this.question.label.replace((this.question.meta?.bold as string) || '', `<b>${this.question.meta?.bold || ''}</b>`);
  }

  @Watch('toggleAllEditMode')
  protected toggleAllEditModeChanged() {
    this.editMode = this.toggleAllEditMode;
    this.readMode = ! this.editMode;
  }

  @Watch('hiddenQuestionKeys')
  protected initializeHiddenQuestionKeys() {
    if (! this.hiddenQuestionKeys) { return; }
    const shouldHideQuestion = this.hiddenQuestionKeys.some((key: string) => this.question.key === key);
    this.$set(this.question, 'hidden', shouldHideQuestion);
  }
}

export type QuestionAnsweredExternalFunction = (value: string) => Promise<void>;

export interface ModefiedQuestion extends QuestionModel {
  hidden?: boolean;
}
