import { AxiosError } from 'axios';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { firstDayOfWeek, lastDayOfWeek, setFormattedDatePickerValue, isValidDate, dateErrorMessage, formatDate } from '@/support/String';
import { DateTime, Settings } from 'luxon';
import { Event, DayPart, EventType } from '@/models/Event';
import { User, userTypes, userLevelLabels, userRoles, UserRole } from '@/models/User';
import { Options } from '@/components/mi-dialog/MiDialog';
import EventNoteDialog from '@/components/Availability/EventNoteDialog/EventNoteDialog.vue';
import ApplyTemplateDialog from '@/components/Availability/ApplyTemplateDialog/ApplyTemplateDialog.vue';
import { debounce } from 'lodash';
import { Setting, SettingKey } from '@/models/Setting';
import { TableMeta } from '@/components/data-table/DataTable';
import WorkHoursDialog from '@/components/Availability/WorkHoursDialog/WorkHoursDialog.vue';
import { Department } from '@/models/Department';
import { AppointmentLimit } from '@/models/AppointmentLimit';
import { downloadExcel } from '@/support/Client';
import AppointmentTypeDialog from '@/components/dialog/appointment-type-dialog/AppointmentTypeDialog.vue';
import { ModifiedAppointmentType } from '@/components/dialog/appointment-type-dialog/AppointmentTypeDialog';
import { Rpc } from '@//models/Rpc';

@Component<ExpertAvailability>({
  components: {
    EventNoteDialog,
    ApplyTemplateDialog,
    WorkHoursDialog,
    AppointmentTypeDialog,
  },
})
export default class ExpertAvailability extends Vue {
  @Prop()
  protected startDate!: DateTime;

  @Prop({ default: false })
  protected isPlanner!: boolean;

  @Prop({ default: false })
  protected isZaakbegeleider!: boolean;

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  @Prop({ default: false })
  protected readOnly!: boolean;

  @Prop()
  protected organization!: string;

  public $pageTitle = 'Deskundige Beschikbaarheid';

  protected appointmentType: AppointmentTypeSettings = {};

  protected isDisplayingAppointmentTypeDialog = false;

  protected currentWeek: DateTime[] = [];

  protected events: Event[] = [];

  protected experts: User[] = [];

  protected lockDate = DateTime.local().minus({ weeks: Math.round(Math.random() * 10) });

  protected isSearching = true;

  protected isLoading = true;

  protected stickyTableHeader = false;

  protected isMenuCondensed = false;

  protected selectedToggle: string | null = null;

  private lastScrollY = 0;

  protected scrollPosition = 0;

  protected planningWeekStartsAt: DateTime = DateTime.local();

  protected clickCount = 0;

  protected clickTimer: NodeJS.Timeout | null = null;

  protected selectedEvent: Event | null = null;

  protected query = '';

  protected onlyEmptyExperts = false;

  protected userTypes = userTypes;

  protected selectedUserTypes = [];

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected selectedDate = '';

  protected userRoles = userRoles;

  protected isLoadingExport = false;

  // Pagination
  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 10;

  public paginationDebounce: Function = this.handlePagination();

  protected isShowingApplyTemplateDialog = false;

  protected isShowingEventNoteDialog = false;

  protected departments: Department[] = [];

  protected selectedDepartments: string[] = [];

  // work hours
  protected isShowingEditWorkHoursDialog = false;

  protected visiblePlanningEndDate = '';

  protected availabilityIcon: {[key: string] : string} = {
    free: 'check_circle',
    busy: 'remove_circle',
    internal: 'apartment',
    appointment: 'check_circle',
    holiday: 'airplanemode_active',
    sick: 'local_hospital',
    invalid: 'help',
  };

  // appointmentLimits
  protected appointmentLimits: AppointmentLimit[] = [];

  protected settings: Setting[] = [];

  public mounted() {
    window.addEventListener('scroll', this.handleWindowScroll);
    Settings.defaultLocale = 'nl';
    this.isLoading = true;
    this.readOnly ? this.initializeReadOnly() : this.initialize();
    this.selectedDate = this.startDate ? this.startDate.toFormat('yyyy-LL-dd') : DateTime.local().toFormat('yyyy-LL-dd');
    this.getSettings();
    this.getDepartments();
  }

  protected showAppointmentTypeDialog(expert: User, date: DateTime) {
    this.isDisplayingAppointmentTypeDialog = true;
    this.appointmentType.user = expert;
    this.appointmentType.date = date;
    this.appointmentType.appointmentLimit = this.findAppointmentLimit(expert?.uuid || '', date);
    this.appointmentType.max = this.fetchMaxLimitSetting(expert);
  }

  protected fetchMaxLimitSetting(user: User): number {
    const setting = this.settings.find((currentSetting: Setting) => (currentSetting.key ? currentSetting.key.includes(user.is_opnemer ? SettingKey.MAX_VISIT_OPNEMER : SettingKey.MAX_VISIT_DESKUNDIGE) : false));

    return setting?.value && (setting.value > 0) ? Number(setting.value) : 0;
  }

  protected getDepartments() {
    new Department()
      .all()
      .then((departments: Department[]) => {
        this.departments = departments;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  public destroyed() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  protected async reloadInitialize() {
    this.isLoading = true;
    this.readOnly ? await this.initializeReadOnly() : await this.initialize();
    this.isLoading = false;
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.readOnly ? this.initializeReadOnly() : this.initialize();
      },
      300,
    );
  }

  protected handleWindowScroll() {
    const direction = this.lastScrollY > window.scrollY ? 'up' : 'down';
    this.lastScrollY = window.scrollY;

    if (window.scrollY > 280) {
      this.stickyTableHeader = true;
    } else {
      this.stickyTableHeader = false;
    }

    if ((window.scrollY > 400 && direction === 'up') || window.scrollY < 280) {
      this.isMenuCondensed = false;
    } else {
      this.isMenuCondensed = true;
    }
  }

  public async initialize() {
    this.isSearching = true;
    this.generateCurrentWeek();

    this.experts = this.isPlanner || this.isServiceLoket ? await this.fetchExperts() : await this.fetchExpert();
    this.events = await this.fetchEvents();
    this.appointmentLimits = await this.fetchAppointmentLimits();

    this.total = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).total : 0;
    this.lastPage = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).last_page : 1;
    this.from = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).from : 0;
    this.to = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).to : 0;

    this.planningWeekStartsAt = firstDayOfWeek(DateTime.local()).minus({ weeks: 1 });

    setTimeout(() => {
      this.isLoading = false;
      this.isSearching = false;

      this.$nextTick(() => {
        this.scrollToExpert();
        this.setClasses();
      });
    }, this.isPlanner ? 0 : 500);
  }

  protected async initializeReadOnly() {
    this.isSearching = true;
    this.generateCurrentWeek();

    this.experts = await this.fetchDmzExperts();
    this.events = await this.fetchDmzEvents();
    this.appointmentLimits = await this.fetchAppointmentLimits(true);

    this.total = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).total : 0;
    this.lastPage = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).last_page : 1;
    this.from = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).from : 0;
    this.to = this.experts[0] !== undefined ? (this.experts[0].meta as TableMeta).to : 0;

    this.planningWeekStartsAt = firstDayOfWeek(DateTime.local()).minus({ weeks: 1 });

    setTimeout(() => {
      this.isLoading = false;
      this.isSearching = false;

      this.$nextTick(() => {
        this.scrollToExpert();
        this.setClasses();
      });
    }, this.isPlanner ? 0 : 500);
  }

  protected async getSettings() {
    const model = this.organization ? new Setting().dmz(this.organization) : new Setting();

    const settings = await model
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (! settings) {
      return;
    }

    this.settings = settings;
    const visiblePlanningEndDate = this.settings.find((setting: Setting) => setting.key === SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG);

    if (typeof visiblePlanningEndDate?.value === 'string' && visiblePlanningEndDate?.value) {
      this.visiblePlanningEndDate = formatDate(visiblePlanningEndDate.value, 'yyyy-LL-dd');
    }
  }

  protected scrollToExpert() {
    window.scroll(0, this.scrollPosition);
  }

  protected selectToggle(type: string) {
    if (this.selectedToggle === type) {
      this.selectedToggle = null;
      return;
    }

    this.selectedToggle = type;
  }

  protected goToPreviousWeek() {
    this.$emit('previousweek');
  }

  protected goToNextWeek() {
    this.$emit('nextweek');
  }

  protected goToDate(today?: boolean) {
    this.$emit('goToDate', today ? DateTime.local() : DateTime.fromSQL(this.selectedDate));
  }

  protected navigateToUser(user: User) {
    let routeData;
    if (this.isZaakbegeleider || this.isImmaterial || ! this.$store.state.isServiceOrganization) {
      routeData = this.$router.resolve(`/users/${user.uuid}?tab=tab-workhours`);
    } else {
      routeData = this.$router.resolve(`/expert-user/${user.uuid}?organization=${this.organization}`);
    }

    if (! routeData || ! routeData.href) { return; }

    window.open(routeData?.href, '_blank');
  }

  protected get totalMissingExperts() {
    let count = 0;

    this.experts.forEach((expert: User) => {
      const events = this.events.filter((event: Event) => expert.uuid === event.user_id);

      if (! events.length) {
        count += 1;
      }
    });

    return count;
  }

  protected get userLevelLabels() {
    return userLevelLabels;
  }

  protected setClasses() {
    this.events.forEach((event: Event) => {
      const toggleTargets = document.querySelectorAll(`.availability__indicator[data-expert='${event.user_id}'][data-day='${event.date}'][data-daypart='${event.daypart}']`);
      if (toggleTargets.length) {
        const eventCell = toggleTargets[0] as HTMLDivElement;
        eventCell.setAttribute('data-type', event.type as string);
        // eslint-disable-next-line no-unused-expressions
        event.date && event.date < this.planningWeekStartsAt.toFormat('yyyy-LL-dd') ? eventCell.classList.add('locked') : null;

        // eslint-disable-next-line no-unused-expressions
        event.has_note ? eventCell.classList.add('note') : null;
        if (event.type) {
          const timestampContainer = eventCell.querySelectorAll('.timestamp');
          if (timestampContainer.length) {
            const morningTimestamp = event.starts_at ? DateTime.fromSQL(event.starts_at).toFormat('HH:mm') : '';
            const afternoonTimestamp = event.ends_at ? DateTime.fromSQL(event.ends_at).toFormat('HH:mm') : '';
            (timestampContainer[0] as HTMLDivElement).innerText = event.daypart === 'morning' ? morningTimestamp : afternoonTimestamp;
          }
        }

        const icon: HTMLDivElement = (toggleTargets[0] as HTMLDivElement).getElementsByClassName('item__icon')[0] as HTMLDivElement;
        if (icon) {
          icon.innerText = this.availabilityIcon[event.type as string];
        }
      }
    });

    const lockedTargets = document.querySelectorAll('.availability__indicator');
    if (lockedTargets.length) {
      lockedTargets.forEach((target) => {
        const date = (target as HTMLDivElement).getAttribute('data-day');

        date && date < this.planningWeekStartsAt.toFormat('yyyy-LL-dd') ? (target as HTMLDivElement).classList.add('locked') : null;
      });
    }
  }

  protected get canSubmitWeek() {
    return this.currentWeek[6].toFormat('yyyy-LL-dd') >= this.planningWeekStartsAt.toFormat('yyyy-LL-dd');
  }

  protected get isServiceOrganization() {
    return this.$store.state.isServiceOrganization;
  }

  protected async fetchExperts(): Promise<User[] | []> {
    const payload: {[key: string]: string | boolean | string[]} = {
      types: this.selectedUserTypes,
      search: this.query,
      is_active: true,
      is_plannable: true,
    };

    if (this.isZaakbegeleider) {
      payload.type = UserRole.CASE_MEDIATOR;
    }

    if (this.isImmaterial) {
      payload.type = UserRole.APPEALS_COMMITTEE;
    }

    if (this.onlyEmptyExperts) {
      payload.availability_unfilled_for_period = [
        firstDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
        lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
      ];
    }

    if (this.selectedDepartments && this.selectedDepartments.length) {
      payload.departments = this.selectedDepartments;
    }

    return await new User()
      .filter(payload)
      .include('schedules')
      .sort('name', 'ASC')
      .limit(this.limit)
      .page(this.page)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchDmzExperts(): Promise<User[] | []> {
    const payload: {[key: string]: string | boolean | string[]} = {
      types: this.selectedUserTypes,
      search: this.query,
      is_active: true,
      is_plannable: true,
    };

    if (this.onlyEmptyExperts) {
      payload.availability_unfilled_for_period = [
        firstDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
        lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
      ];
    }

    if (this.selectedDepartments && this.selectedDepartments.length) {
      payload.departments = this.selectedDepartments;
    }

    return await new User()
      .dmz(this.organization || '')
      .filter(payload)
      .include('schedules')
      .sort('name', 'ASC')
      .limit(this.limit)
      .page(this.page)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchExpert(): Promise<User[]> {
    const user = await new User()
      .filter({
        search: this.query,
      })
      .include('schedules')
      .find(this.$store.state.Auth.uuid)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    return [user] as User[];
  }

  protected handleSearch(query: string) {
    this.query = query;
    this.isSearching = true;
    this.readOnly ? this.initializeReadOnly() : this.initialize();
  }

  protected filterUserTypes() {
    this.isSearching = true;
    this.readOnly ? this.initializeReadOnly() : this.initialize();
  }

  protected get isPastCurrentWeek() {
    if (! this.startDate) {
      return true;
    }
    return this.startDate.toFormat('yyyy-LL-dd') < DateTime.local().toFormat('yyyy-LL-dd');
  }

  protected get isInThePast() {
    if (! this.startDate) {
      return true;
    }

    return lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd') < DateTime.local().toFormat('yyyy-LL-dd');
  }

  protected async fetchEvents(): Promise<Event[]> {
    return await new Event()
      .filter({
        period: [
          firstDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
          lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
        ],
        types: ['busy', 'free', 'internal'],
        organization: this.$store.state.Auth && this.$store.state.Auth.organization && this.$store.state.Auth.organization.id ? this.$store.state.Auth.organization.id : '',
        users: this.experts.map((expert: User) => expert.uuid),
      })
      .limit(this.limit * 14)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchAppointmentLimits(dmz = false): Promise<AppointmentLimit[]> {
    const model = dmz ? new AppointmentLimit().dmz(this.organization) : new AppointmentLimit();
    return await model
      .filter({
        week: this.startDate.toFormat('yyyy-LL-dd'),
        users: this.experts.map((expert: User) => expert.uuid),
      })
      .include(['user', 'type_limits'])
      .limit(this.limit * 14)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchDmzEvents(): Promise<Event[]> {
    return await new Event()
      .dmz(this.organization)
      .filter({
        period: [
          firstDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
          lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd'),
        ],
        types: ['busy', 'free', 'internal'],
        organization: this.organization,
        users: this.experts.map((expert: User) => expert.uuid),
      })
      .limit(this.limit * 14)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected get weekNumber() {
    return this.startDate ? this.startDate.weekNumber : '';
  }

  protected get headerDate() {
    return this.startDate ? `${lastDayOfWeek(this.startDate).toFormat('dd-LL-yyyy')}` : '';
  }

  protected isLocked(date: DateTime): boolean {
    return date.toFormat('yyyy-LL-dd') <= DateTime.local().toFormat('yyyy-LL-dd');
  }

  private generateCurrentWeek() {
    if (! this.startDate) {
      return;
    }

    this.currentWeek = [];
    const weekStart = firstDayOfWeek(this.startDate);
    for (let i = 0; i <= 6; i += 1) {
      const date = weekStart.plus({ days: i });
      this.currentWeek.push(date);
    }
  }

  protected hasEvents(expert: User) {
    return this.events.some((event: Event) => event.user_id === expert.uuid);
  }

  protected findContainerTarget(date: DateTime, expertId: string, daypart: DayPart) {
    return document.querySelectorAll(`.availability--container[data-expert='${expertId}'][data-day='${date.toFormat('yyyy-LL-dd')}'][data-daypart='${daypart}']`);
  }

  protected setAvailability(date: DateTime, expertId: string, daypart: DayPart, type: EventType) {
    const containerTargets = this.findContainerTarget(date, expertId, daypart);
    const isTimeType = type === 'time';

    if (containerTargets.length) {
      if (! isTimeType) {
        containerTargets[0].classList.add('processing');
      }
    }

    if (! this.isEventAvailable(date.toFormat('yyyy-LL-dd'), expertId, daypart) && ! isTimeType) {
      this.createEvent(date, expertId, daypart, type);
      return;
    }

    const event = this.events.find((event: Event) => event.date === date.toFormat('yyyy-LL-dd') && event.daypart == daypart && event.user_id === expertId);

    if (! event) {
      return;
    }

    if (isTimeType) {
      if (event.starts_at && DateTime.fromSQL(event.starts_at).toFormat('yyyy-LL-dd') < DateTime.local().toFormat('yyyy-LL-dd')) {
        return;
      }

      this.selectedEvent = event;
      this.isShowingEditWorkHoursDialog = true;
      return;
    }

    this.updateEvent(event, type, {
      type,
    });
  }

  protected createEvent(date: DateTime, user: string, daypart: DayPart, type: EventType) {
    const payload: {[key: string]: string | boolean | string[]} = {
      date: date.toFormat('yyyy-LL-dd'),
      type,
      daypart,
      user,
    };

    const eventModel = this.readOnly ? new Event().dmz(this.organization) : new Event();

    eventModel
      .create(payload)
      .then((event: Event) => {
        this.events.push(event);

        setTimeout(() => {
          const toggleTargets = document.querySelectorAll(`.availability__indicator[data-expert='${event.user_id}'][data-day='${event.date}'][data-daypart='${event.daypart}']`);
          if (toggleTargets.length) {
            const eventCell = (toggleTargets[0] as HTMLDivElement);
            eventCell.setAttribute('data-type', event.type as string);
            const icon: HTMLDivElement = (toggleTargets[0] as HTMLDivElement).getElementsByClassName('item__icon')[0] as HTMLDivElement;
            if (icon) {
              icon.innerText = this.availabilityIcon[event.type as string];
            }

            const timestampContainer = eventCell.querySelectorAll('.timestamp');
            if (timestampContainer.length) {
              const morningTimestamp = event.starts_at ? DateTime.fromSQL(event.starts_at).toFormat('HH:mm') : '';
              const afternoonTimestamp = event.ends_at ? DateTime.fromSQL(event.ends_at).toFormat('HH:mm') : '';
              (timestampContainer[0] as HTMLDivElement).innerText = event.daypart === 'morning' ? morningTimestamp : afternoonTimestamp;
            }
          }
        }, 1000);
      })
      .catch((error: AxiosError) => {
      // TODO: mike: deze error messages met backend naast elkaar zetten en dan errhandler(error) gebruiken.
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
        ErrorHandler.alert(message, 'Beschikbaarheid kon niet worden aangepast');
      })
      .finally(() => {
        setTimeout(() => {
          const containerTargets = document.querySelectorAll(`.availability--container[data-expert='${user}'][data-day='${date.toFormat('yyyy-LL-dd')}'][data-daypart='${daypart}']`);
          if (containerTargets.length) {
            containerTargets[0].classList.remove('processing');
          }
        }, 1000);
      });
  }

  protected updateEvent(event: Event, type: EventType, payload: {[key: string]: string | string | string[]}) {
    const eventModel = this.readOnly ? event.dmz(this.organization) : event;

    eventModel
      .update(payload)
      .then((newEvent: Event) => {
        this.events = this.events.filter((currentEvent: Event) => currentEvent.id !== event.id);

        setTimeout(() => {
          this.events.push(newEvent);

          const toggleTargets = document.querySelectorAll(`.availability__indicator[data-expert='${event.user_id}'][data-day='${event.date}'][data-daypart='${event.daypart}']`);
          if (toggleTargets.length) {
            (toggleTargets[0] as HTMLDivElement).setAttribute('data-type', type as string);
            const icon: HTMLDivElement = (toggleTargets[0] as HTMLDivElement).getElementsByClassName('item__icon')[0] as HTMLDivElement;
            if (icon) {
              icon.innerText = this.availabilityIcon[type];
            }

            const eventCell = (toggleTargets[0] as HTMLDivElement);
            const timestampContainer = eventCell.querySelectorAll('.timestamp');

            if (timestampContainer.length) {
              const morningTimestamp = newEvent.starts_at ? DateTime.fromSQL(newEvent.starts_at).toFormat('HH:mm') : '';
              const afternoonTimestamp = newEvent.ends_at ? DateTime.fromSQL(newEvent.ends_at).toFormat('HH:mm') : '';
              (timestampContainer[0] as HTMLDivElement).innerText = event.daypart === 'morning' ? morningTimestamp : afternoonTimestamp;
            }
          }

          const containerTargets = document.querySelectorAll(`.availability--container[data-expert='${event.user_id}'][data-day='${event.date}'][data-daypart='${event.daypart}']`);
          if (containerTargets.length) {
            containerTargets[0].classList.remove('processing');
          }
        }, 1000);
      })
      .catch((error: AxiosError) => {
        const containerTargets = document.querySelectorAll(`.availability--container[data-expert='${event.user_id}'][data-day='${event.date ? DateTime.fromSQL(event.date).toFormat('yyyy-LL-dd') : ''}'][data-daypart='${event.daypart}']`);
        if (containerTargets.length) {
          containerTargets[0].classList.remove('processing');
        }

        if (error.code === '422') {
          this.$store.dispatch('openDialog', this.hasEventDialogOptions);
        }

        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
        ErrorHandler.alert(message, 'Beschikbaarheid kon niet worden aangepast');
      });
  }

  protected findAppointmentLimit(userId: string, date: DateTime) {
    if (! userId || ! date) {
      return;
    }

    return this.appointmentLimits.find((appointmentLimit: AppointmentLimit) => {
      if (! appointmentLimit) { return null; }
      return (appointmentLimit.date === date.toFormat('yyyy-LL-dd')) && (appointmentLimit?.user?.uuid === userId);
    });
  }

  protected findValueAppointmentLimit(user: User, date: DateTime) {
    if (! user?.uuid) { return '-'; }
    const appointmentLimit = this.findAppointmentLimit(user.uuid, date);
    const maxLimit = this.fetchMaxLimitSetting(user);
    return appointmentLimit && (appointmentLimit.appointment_limit !== null) ? `${appointmentLimit.appointment_limit}/${maxLimit}` : `${maxLimit}`;
  }

  protected getAppointmentLimit(user: User, date: DateTime) {
    if (! user?.uuid) { return '-'; }
    const appointmentLimit = this.findAppointmentLimit(user.uuid, date);
    return appointmentLimit ? appointmentLimit.appointment_limit : null;
  }

  // Update Appointment Limits
  protected async updateMaxAppointmentTypeCount(appointmentTypes: ModifiedAppointmentType[], expertId: string, date: DateTime) {
    const filteredAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType?.value !== undefined);
    const typeLimits = Object.fromEntries(filteredAppointmentTypes.map((filteredAppointmentType) => [filteredAppointmentType.key, filteredAppointmentType.value || '']));

    const appointmentLimit = this.appointmentLimits.find((appointmentLimit: AppointmentLimit) => {
      if (! appointmentLimit || ! date) { return; }
      return (appointmentLimit?.user?.uuid === expertId) && (date.toFormat('yyyy-LL-dd') === appointmentLimit?.date);
    });

    if (appointmentLimit) {
      const model = this.readOnly ? new AppointmentLimit(appointmentLimit).dmz(this.organization) : new AppointmentLimit(appointmentLimit);

      await model
        .update({
          type_limits: typeLimits,
        });
    } else {
      const model = this.readOnly ? new AppointmentLimit().dmz(this.organization) : new AppointmentLimit();

      await model
        .create({
          user: expertId,
          date: date.toFormat('yyyy-LL-dd'),
          type_limits: typeLimits,
        });
    }

    this.reloadInitialize();
  }

  // Update Appointment Limits
  protected updateAppointmentLimit(value: string, expertId: string, date: DateTime) {
    const appointmentLimit = this.appointmentLimits.find((appointmentLimit: AppointmentLimit) => {
      if (! appointmentLimit || ! date) { return; }
      return (appointmentLimit?.user?.uuid === expertId) && (date.toFormat('yyyy-LL-dd') === appointmentLimit?.date);
    });

    if (appointmentLimit) {
      const model = this.readOnly ? new AppointmentLimit(appointmentLimit).dmz(this.organization) : new AppointmentLimit(appointmentLimit);

      model
        .update({
          appointment_limit: value,
        });
      return;
    }

    const model = this.readOnly ? new AppointmentLimit().dmz(this.organization) : new AppointmentLimit();

    model
      .create({
        user: expertId,
        date: date.toFormat('yyyy-LL-dd'),
        appointment_limit: value,
      });
  }

  protected isEventAvailable(date: string, expertId: string, daypart: DayPart): boolean {
    return this.events.some((event: Event) => event.date === date && event.daypart === daypart && event.user_id === expertId);
  }

  protected handleTableClick(event: MouseEvent) {
    this.clickCount += 1;

    if (this.clickCount === 1) {
      this.clickTimer = setTimeout(() => {
        this.clickCount = 0;
        this.handleSingleClick(event);
      }, 200);
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimer as NodeJS.Timeout);
      this.clickCount = 0;
      this.handleDoubleClick(event);
    }
  }

  protected handleDoubleClick(event: MouseEvent) {
    const target: HTMLDivElement = event.target as HTMLDivElement;
    if (! target.classList.contains('availability__indicator') && ! (target.parentElement as HTMLDivElement).classList.contains('availability__indictator')) {
      return;
    }

    const type = target.getAttribute('data-type') ? target.getAttribute('data-type') : null;

    if (type !== 'internal') {
      return;
    }

    const user_id = target.getAttribute('data-expert') ? target.getAttribute('data-expert') : null;
    const day = target.getAttribute('data-day') ? DateTime.fromFormat(target.getAttribute('data-day') as string, 'yyyy-LL-dd') : null;
    const daypart = target.getAttribute('data-daypart') ? target.getAttribute('data-daypart') as DayPart : null;

    const clickedEvent = this.events.find((event: Event) => event.date === (day as DateTime).toFormat('yyyy-LL-dd') && event.daypart == daypart && event.user_id === user_id);

    this.selectedEvent = clickedEvent || null;
    this.isShowingEventNoteDialog = true;
  }

  protected handleSingleClick(event: MouseEvent) {
    if (this.isServiceLoket) { return; }

    let target: HTMLDivElement = event.target as HTMLDivElement;
    if (! target.classList.contains('availability__indicator') && ! (target.parentElement as HTMLDivElement).classList.contains('availability__indictator')) {
      return;
    }

    target = target.classList.contains('availability__toggle') ? target as HTMLDivElement : target.parentElement as HTMLDivElement;
    const user_id = target.getAttribute('data-expert') ? target.getAttribute('data-expert') : null;
    const day = target.getAttribute('data-day') ? DateTime.fromFormat(target.getAttribute('data-day') as string, 'yyyy-LL-dd') : null;
    const daypart = target.getAttribute('data-daypart') ? target.getAttribute('data-daypart') as DayPart : null;

    if (! day || day.toFormat('yyyy-LL-dd') < this.planningWeekStartsAt.toFormat('yyyy-LL-dd')) {
      this.$store.dispatch('openDialog', this.lockedDialogOptions);
      return;
    }

    if (! this.selectedToggle) {
      this.$store.dispatch('openDialog', this.editModeDialogOptions);
      return;
    }

    if (! user_id || ! day || ! daypart) {
      return;
    }

    this.setAvailability(day, user_id, daypart, this.selectedToggle as EventType);
  }

  protected handInWeek() {
    this.$store.dispatch('openDialog', this.handInWeekDialogOptions);
  }

  protected get editModeDialogOptions(): Options {
    return {
      title: 'U heeft geen status geselecteerd',
      text: 'Om de beschikbaarheid aan te passen selecteer dan eerst een status in de werkbalk.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get lockedDialogOptions(): Options {
    return {
      title: 'Dag in het verleden',
      text: 'U kunt de beschikbaarheid niet aanpassen voor dagen in het verleden.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get handInWeekDialogOptions(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je de beschikbaarheid tot</br> <strong>${this.headerDate}</strong> wilt inleveren?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('general.yes').toString(),
          action: () => {
            const payload = {
              signature: 'planning:submit',
              body: {
                planning_visible_end_date_for_tcmg: this.startDate ? `${lastDayOfWeek(this.startDate).toFormat('yyyy-LL-dd')}` : '',
              },
            };

            new Rpc()
              .rpcPost(payload)
              .then(() => {
                this.$store.dispatch('openDialog', this.successDialogOptions);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  protected get hasEventDialogOptions(): Options {
    return {
      title: 'Beschikbaarheid is niet meer aan te passen',
      text: 'Er al een afspraak ingepland Voor deze expert is op dit tijdslot. Neem contact op met Planning om deze afspraak te verzetten',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get successDialogOptions(): Options {
    return {
      title: 'Week is met succes ingeleverd',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected handleEventUpdated(eventId: string) {
    const updatedEvent = this.events.find((currentEvent: Event) => currentEvent.id === eventId);

    if (! updatedEvent) {
      return;
    }

    updatedEvent.has_note = true;
    this.setClasses();
  }

  protected handleWorkHourUpdated(date: string, event: Event) {
    const updatedEvent = this.events.find((currentEvent: Event) => currentEvent.id === event.id);

    if (! updatedEvent || ! updatedEvent.type) {
      return;
    }

    const isMorning = updatedEvent && updatedEvent.daypart === 'morning';
    let payload = {};
    if (isMorning) {
      payload = {
        starts_at: date,
      };
    } else {
      payload = {
        ends_at: date,
      };
    }
    if (! updatedEvent.starts_at || ! updatedEvent.ends_at || ! updatedEvent.user_id || ! updatedEvent.daypart) {
      return;
    }

    const containerTargets = this.findContainerTarget(isMorning ? DateTime.fromSQL(updatedEvent.starts_at) : DateTime.fromSQL(updatedEvent.ends_at), updatedEvent.user_id, updatedEvent.daypart);

    if (containerTargets.length) {
      containerTargets[0].classList.add('processing');
    }

    this.updateEvent(updatedEvent, updatedEvent.type, payload);
  }

  protected async downloadExcel() {
    this.isLoadingExport = true;

    const payload = new URLSearchParams();
    payload.append('filters[week]', `${this.startDate.weekNumber}`);
    payload.append('filters[year]', `${this.startDate.year}`);
    ['busy', 'free', 'internal'].forEach((key: string, index: number) => {
      payload.append(`filters[types][${index}]`, key);
    });
    payload.append('filters[organization]', this.$store.state.Auth && this.$store.state.Auth.organization && this.$store.state.Auth.organization.id ? this.$store.state.Auth.organization.id : '');

    this.selectedUserTypes.forEach((key: string, index: number) => {
      payload.append(`user_filters[types][${index}]`, key);
    });
    payload.append('user_filters[is_active]', 'true');
    payload.append('user_filters[is_plannable]', 'true');

    if (this.isZaakbegeleider) {
      payload.append('user_filters[type]', UserRole.CASE_MEDIATOR);
    }

    if (this.isImmaterial) {
      payload.append('user_filters[type]', UserRole.APPEALS_COMMITTEE);
    }

    this.selectedDepartments.forEach((key: string, index: number) => {
      payload.append(`user_filters[departments][${index}]`, key);
    });

    const options = {
      url: '/dmz/events/export',
      dmz: this.organization ? this.organization : this.$store.state.Auth && this.$store.state.Auth.organization && this.$store.state.Auth.organization.id ? this.$store.state.Auth.organization.id : '',
    };

    await downloadExcel(options, payload);
    this.isLoadingExport = false;
  }

  protected get userTypesText() {
    if (this.isImmaterial) {
      return 'jz gebruikers';
    }
    return this.$store.state.Auth && this.$store.state.isServiceOrganization ? 'zaakbegeleiders' : 'experts';
  }

  protected get userTypeText() {
    if (this.isImmaterial) {
      return 'jz gebruiker';
    }
    return this.$store.state.Auth && this.$store.state.isServiceOrganization ? 'zaakbegeleider' : 'expert';
  }

  protected get isServiceLoket() {
    return this.$store.state.Auth && this.$store.state.Auth.hasRole('serviceloket');
  }

  protected get isManager(): boolean {
    return this.$store.state.Auth && (this.$store.state.Auth as User).hasRole(userRoles.ManagerRoles);
  }

    // Date
    protected isEditingDate = false;

    protected dateFormatted: string | null = null;

    protected dateErrorMessage = '';

    protected formatDateFromDatePicker() {
      if (this.selectedDate) {
        this.dateFormatted = setFormattedDatePickerValue(this.selectedDate as string, 'yyyy-LL-dd', 'dd-LL-yyyy');
        this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
      }
    }

    protected formatDateFromTextField(value: string) {
      this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
      this.selectedDate = setFormattedDatePickerValue(value);
    }

    @Watch('selectedDate')
    protected dateChanged() {
      this.formatDateFromDatePicker();
    }

    @Watch('isEditingDate')
    protected isEditingDateChanged() {
      if (! this.isEditingDate) {
        this.formatDateFromDatePicker();
      }
    }

  @Watch('$route')
    public routeChanged() {
      this.isLoading = true;
      this.readOnly ? this.initializeReadOnly() : this.initialize();
    }

  @Watch('startDate')
  public startDateChanged(to: DateTime, from: DateTime) {
    this.scrollPosition = window.scrollY;
    this.isLoading = true;
    this.selectedDate = this.startDate ? this.startDate.toFormat('yyyy-LL-dd') : DateTime.local().toFormat('yyyy-LL-dd');
    this.readOnly ? this.initializeReadOnly() : this.initialize();
  }

  @Watch('organization')
  public organizationChanged() {
    this.scrollPosition = window.scrollY;
    this.isLoading = true;
    this.initializeReadOnly();
  }

  @Watch('onlyEmptyExperts')
  protected onlyEmptyExpertsChanged() {
    this.page = 1;
    this.readOnly ? this.initializeReadOnly() : this.initialize();
  }
}

interface AppointmentTypeSettings {
  user?: User,
  date?: DateTime,
  max?: number,
  appointmentLimit?: AppointmentLimit;
}
