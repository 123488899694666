var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _vm.damages && _vm.damages.length
        ? _c(
            "div",
            { staticClass: "damagePanelContainer" },
            [
              _c(
                "div",
                { staticClass: "text-xs-right handlepanel" },
                [
                  _vm.hasPanelsOpen
                    ? _c("v-flex", [
                        _c("div", { staticClass: "panel" }, [
                          _c(
                            "span",
                            { on: { click: _vm.closeAll } },
                            [
                              _vm._v(" sluit alle "),
                              _c("v-icon", [_vm._v("keyboard_arrow_up")])
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  !_vm.hasPanelsOpen
                    ? _c("v-flex", [
                        _c("div", { staticClass: "panel" }, [
                          _c(
                            "span",
                            { on: { click: _vm.openAll } },
                            [
                              _vm._v(" open alle "),
                              _c("v-icon", [_vm._v("keyboard_arrow_down")])
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.damages, function(damage, index) {
                return [
                  !_vm.isLoadingDamage
                    ? _c("DamagePanelRow", {
                        key: index,
                        attrs: {
                          index: index,
                          damageModel: damage,
                          damages: _vm.damages,
                          includes: _vm.includes,
                          rejectReasons: _vm.reasons,
                          panel: _vm.panels[index],
                          visibility: _vm.visibility,
                          isVES: _vm.isVES,
                          disabled: _vm.disabled
                        },
                        on: {
                          handleEditDialog: _vm.handleEditDialog,
                          openAmbtshalveDialog: _vm.openAmbtshalveDialog,
                          reloadDamages: _vm.reloadDamages,
                          emitSelectedDamage: _vm.emitSelectedDamages,
                          panelCloneChanged: function($event) {
                            return _vm.handleChangedPanel($event, index)
                          }
                        }
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _c("div", [
            _c("span", { staticClass: "fadedText" }, [
              _vm._v("Er zijn geen schades")
            ])
          ]),
      _vm.editDamageDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "90vw" },
              model: {
                value: _vm.editDamageDialog,
                callback: function($$v) {
                  _vm.editDamageDialog = $$v
                },
                expression: "editDamageDialog"
              }
            },
            [
              _c("DamageDialog", {
                attrs: {
                  damage: _vm.currentDamage,
                  damageIndex: _vm.currentDamageIndex,
                  isVES: _vm.isVES
                },
                on: { closeDialog: _vm.reloadDamages },
                model: {
                  value: _vm.editDamageDialog,
                  callback: function($$v) {
                    _vm.editDamageDialog = $$v
                  },
                  expression: "editDamageDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingAmbtshalve
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "750" },
              model: {
                value: _vm.isCreatingAmbtshalve,
                callback: function($$v) {
                  _vm.isCreatingAmbtshalve = $$v
                },
                expression: "isCreatingAmbtshalve"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "elementPanel__header",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "header__title",
                                  attrs: { sm11: "" }
                                },
                                [
                                  _c("h2", [
                                    _vm._v(
                                      "Ambtshalve " +
                                        _vm._s(
                                          _vm.isEditingAmbtshalve
                                            ? "toekennen"
                                            : "aanpassen"
                                        )
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "header__close",
                                  attrs: { sm1: "" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.closeAmbtshalveDialog(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("cancel")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.isLoading
                    ? _c(
                        "v-card-text",
                        [
                          _vm.isLoading
                            ? _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "250px"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-space-between": "",
                                "align-baseline": "",
                                "mb-4": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-2": "" } },
                                [
                                  _c("v-text-field", {
                                    ref: "ambtshalveAmount",
                                    attrs: {
                                      label: "Ambtshalve bedrag",
                                      value: _vm.selectedDamage.overrule_payout,
                                      clearable: ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-autocomplete",
                                    {
                                      staticClass: "with-border",
                                      attrs: {
                                        items: _vm.ambtshalveReasons,
                                        "item-value": "id",
                                        "item-text": "name",
                                        label: "Kies een reden",
                                        multiple: "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.selectedAmbtshalveReasons,
                                        callback: function($$v) {
                                          _vm.selectedAmbtshalveReasons = $$v
                                        },
                                        expression: "selectedAmbtshalveReasons"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "no-data" }, [
                                        _c("span", { staticClass: "pa-3" }, [
                                          _vm._v("Geen redenen gevonden")
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm.hasOtherReasonSelected()
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mb-2": "" } },
                                    [
                                      _c("v-textarea", {
                                        ref: "ambtshalveReasonComment",
                                        attrs: {
                                          label: "Toelichting",
                                          value:
                                            _vm.selectedDamage.overrule_custom
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-space-between": "", row: "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "text-light",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.closeAmbtshalveDialog($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$tc("general.cancel")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "MiButton",
                                {
                                  staticClass: "float--right",
                                  attrs: { color: "success" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.assignAmbtshalve($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isEditingAmbtshalve
                                          ? "Toekennen"
                                          : "Aanpassen"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }