import { Model } from '@/models/Model';

export class SSO extends Model {
  protected $name = 'SSO';

  protected $endpoint = '/sso/link';

  protected $fillable: string[] = [
    'case_number',
  ];

  public case_number?: string;

  public app_link?: string;

  public link?: string;

  public token?: string;
}
