import { Model } from '@/models/Model';

export class Rpc extends Model {
  protected $name = 'Rpc';

  protected $endpoint = '/rpc';

  protected $fillable: string[] = ['signature', 'body'];

  public signature?: string;

  public body?: any;

  public rpcPost(payload: object, parseToModel = true) {
    const url = `${this.$endpoint}`;
    return this.request('post', url, payload, parseToModel);
  }

  public execute(signature: string, body: object) {
    const url = `${this.$endpoint}`;

    return this.request('post', url, {
      signature,
      body,
    });
  }
}
