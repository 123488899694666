import { Model } from '@/models/Model';

export class LibraryTag extends Model {
  protected $name = 'LibraryTag';

  protected $endpoint = '/library/tags';

  protected $fillable: string[] = [

  ];

  public id?: string;

  public title?: string;

  public updated_at?: string;

  public created_at?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
