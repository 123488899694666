var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "approval-dialog" },
        [
          _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
              })
            : [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("v-icon", [_vm._v("warning")])],
                            1
                          )
                        ]),
                        _c("v-flex", [_c("h2", [_vm._v(_vm._s(_vm.title))])])
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "approvalDialogContainer",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _vm.status
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "justify-space-around": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs5: "" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "line-through"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("getStatusLabel")(
                                                _vm.report.status
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-center",
                                        attrs: { xs2: "" }
                                      },
                                      [
                                        _c("v-icon", { staticClass: "arrow" }, [
                                          _vm._v("arrow_forward")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c("v-flex", { attrs: { xs5: "" } }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("getStatusLabel")(_vm.status)
                                          )
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.firstTimeRight && _vm.approval === "approved"
                          ? _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  "text-xs-left": "",
                                  "mt-3": ""
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v("Was dit dossier First Time Right?")
                                ]),
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass:
                                      "approvalChoiceContainer mt-1 mb-2",
                                    attrs: { row: "", "hide-details": "" },
                                    model: {
                                      value: _vm.firstTimeRightValue,
                                      callback: function($$v) {
                                        _vm.firstTimeRightValue = $$v
                                      },
                                      expression: "firstTimeRightValue"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: { value: true, color: "#a09b1b" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              slot: "label",
                                              value: true
                                            },
                                            slot: "label"
                                          },
                                          [_vm._v("ja")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: {
                                          value: false,
                                          color: "#a09b1b"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              slot: "label",
                                              value: false
                                            },
                                            slot: "label"
                                          },
                                          [_vm._v("nee")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                !_vm.firstTimeRightValue
                                  ? [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.firstTimeRightReasons,
                                          label: "Reden",
                                          "item-text": "name",
                                          "item-value": "value",
                                          multiple: ""
                                        },
                                        model: {
                                          value:
                                            _vm.selectedFirstTimeRightReasons,
                                          callback: function($$v) {
                                            _vm.selectedFirstTimeRightReasons = $$v
                                          },
                                          expression:
                                            "selectedFirstTimeRightReasons"
                                        }
                                      }),
                                      _c("v-textarea", {
                                        attrs: {
                                          name: "comment",
                                          label: "Toelichting"
                                        },
                                        model: {
                                          value: _vm.firstTimeRightComment,
                                          callback: function($$v) {
                                            _vm.firstTimeRightComment = $$v
                                          },
                                          expression: "firstTimeRightComment"
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.showCheckbox
                          ? _c(
                              "v-flex",
                              { attrs: { sm12: "" } },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    label: "Wijzig de goedgekeurde datum",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.changeApprovedDate,
                                    callback: function($$v) {
                                      _vm.changeApprovedDate = $$v
                                    },
                                    expression: "changeApprovedDate"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showCommentField
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    name: "comment",
                                    label: "Opmerkingen"
                                  },
                                  model: {
                                    value: _vm.comment,
                                    callback: function($$v) {
                                      _vm.comment = $$v
                                    },
                                    expression: "comment"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showExpertSelect
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("label", { staticClass: "selectLabel" }, [
                                  _vm._v("Selecteer een deskundige")
                                ]),
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.experts,
                                    label: "Naam deskundige",
                                    "item-text": "name",
                                    "item-value": "uuid",
                                    "hide-details": ""
                                  },
                                  on: { keyup: _vm.debouncedSearch },
                                  model: {
                                    value: _vm.selectedExpert,
                                    callback: function($$v) {
                                      _vm.selectedExpert = $$v
                                    },
                                    expression: "selectedExpert"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dateField && _vm.dateField.length
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "", "mt-2": "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "dialog",
                                    attrs: {
                                      "close-on-content-click": false,
                                      lazy: "",
                                      "nudge-right": "100",
                                      "full-width": "",
                                      "max-width": "290px",
                                      "min-width": "290px"
                                    },
                                    model: {
                                      value: _vm.isEditingDate,
                                      callback: function($$v) {
                                        _vm.isEditingDate = $$v
                                      },
                                      expression: "isEditingDate"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-date",
                                      attrs: {
                                        slot: "activator",
                                        placeholder: "DD-MM-YYYY",
                                        label: "Gepland",
                                        clearable: "",
                                        "error-messages": _vm.dateErrorMessage
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.formatDateFromTextField(
                                            $event
                                          )
                                        },
                                        "click:clear": function($event) {
                                          return _vm.clearDateFromTextField()
                                        }
                                      },
                                      slot: "activator",
                                      model: {
                                        value: _vm.dateFormatted,
                                        callback: function($$v) {
                                          _vm.dateFormatted = $$v
                                        },
                                        expression: "dateFormatted"
                                      }
                                    }),
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        "first-day-of-week": "1",
                                        locale: "nl-nl",
                                        min: "1910-01-01",
                                        color: "#837f16"
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.isEditingDate = false
                                        }
                                      },
                                      model: {
                                        value: _vm.date,
                                        callback: function($$v) {
                                          _vm.date = $$v
                                        },
                                        expression: "date"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-text-field", {
                                  staticClass: "input input-time",
                                  attrs: {
                                    slot: "activator",
                                    mask: _vm.mask.time,
                                    placeholder: "HH:MM"
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.time,
                                    callback: function($$v) {
                                      _vm.time = $$v
                                    },
                                    expression: "time"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.planning || _vm.chooseOrganization
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("v-checkbox", {
                                  staticClass:
                                    "planningByOrganisation-checkbox",
                                  attrs: {
                                    label: "Planning door Expertisebureau",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.planningByOrganization,
                                    callback: function($$v) {
                                      _vm.planningByOrganization = $$v
                                    },
                                    expression: "planningByOrganization"
                                  }
                                }),
                                _vm.chooseOrganization &&
                                _vm.planningByOrganization
                                  ? [
                                      _c(
                                        "v-flex",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.expertOrganizations,
                                              label: "Organisatie",
                                              "item-text": "name",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: _vm.selectedOrganization,
                                              callback: function($$v) {
                                                _vm.selectedOrganization = $$v
                                              },
                                              expression: "selectedOrganization"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.planning
                                  ? [
                                      _c(
                                        "v-flex",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.organizations,
                                              label: "Organisatie",
                                              "item-text": "name",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: _vm.selectedOrganization,
                                              callback: function($$v) {
                                                _vm.selectedOrganization = $$v
                                              },
                                              expression: "selectedOrganization"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "field",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.experts,
                                              "search-input": _vm.expertSearch,
                                              label: _vm.selectedOrganization
                                                .length
                                                ? "Koppel aan expert"
                                                : "Kies eerst een organisatie",
                                              "item-text": "name",
                                              "item-value": "uuid",
                                              "hide-details": "",
                                              disabled: !_vm
                                                .selectedOrganization.length
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.expertSearch = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.expertSearch = $event
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedExpert,
                                              callback: function($$v) {
                                                _vm.selectedExpert = $$v
                                              },
                                              expression: "selectedExpert"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.information
                          ? _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("div", { staticClass: "information" }, [
                                _vm._v(_vm._s(_vm.information))
                              ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "buttonContainer" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "justify-space-between": "", row: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "text-light", outline: "true" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.close($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("general.cancel")) + " "
                            )
                          ]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "float--right",
                            attrs: {
                              disabled: _vm.isActionDisabled,
                              color:
                                _vm.approval === "approved"
                                  ? "success"
                                  : "warning"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleConfirm($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.approval === "approved"
                                    ? "Goedkeuren"
                                    : "Terugzetten"
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }