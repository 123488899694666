import { formatDate, isValidDate, isValidTime } from '@/support/String';
import { DateTime } from 'luxon';

export class DateFormatter {
  private time: string | null = null;

  private date: string | null = null;

  private formatted: string | null = null;

  private error = '';

  private errorTime = '';

  public get selectedTime() {
    return this.time || '';
  }

  public set selectedTime(time: string) {
    if (! time) {
      return;
    }

    if (! isValidTime(time)) {
      this.errorTime = 'Gelieve een geldige tijd op te geven (bijv. 15:30)';
    } else {
      this.errorTime = '';
      this.time = formatDate(time, 'HH:mm');
    }
  }

  public get selectedDate(): string | null {
    return this.date || '';
  }

  public set selectedDate(date: string | null) {
    this.date = date ? formatDate(date, 'yyyy-LL-dd') : '';
    this.formattedDate = date ? formatDate(date, 'dd-LL-yyyy') : '';
  }

  public get formattedDate() {
    return this.formatted || '';
  }

  public set formattedDate(date: string) {
    if (! date) {
      this.formatted = '';
      return;
    }

    if (! isValidDate(date)) {
      this.error = 'Gelieve een geldige datum op te geven (bijv. 23-12-2019)';
    } else {
      this.error = '';
      this.formatted = date;

      if (! this.date || this.formatted !== formatDate(this.date)) {
        this.selectedDate = DateTime.fromFormat(date, 'dd-LL-yyyy').toFormat('yyyy-LL-dd');
      }
    }
  }

  public get errorMessage(): string {
    return this.error;
  }

  public clear() {
    this.error = '';
    this.selectedDate = '';
  }

  public get errorMessageTime(): string {
    return this.errorTime;
  }

  public clearTime() {
    this.errorTime = '';
    this.selectedTime = '';
  }
}
