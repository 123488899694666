var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mi-file-upload--container",
      class: [
        { "mi-file-upload--container__dragging": _vm.dragging },
        { invalid: _vm.invalidFileType },
        {
          "mi-file-upload--container__uploading":
            _vm.isUploaded || _vm.isUploading
        }
      ],
      style: "min-height: " + _vm.height + "px",
      on: {
        click: _vm.handleClickUploaderAction,
        dragend: _vm.handleDragEnd,
        dragenter: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDragEnter($event)
        },
        dragover: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDragOver($event)
        },
        dragleave: _vm.handleDragLeave,
        drop: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleDrop($event)
        },
        mouseenter: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleMouseEnter($event)
        },
        mouseleave: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleMouseLeave($event)
        }
      }
    },
    [
      _c("span", {
        staticClass: "mi-file-upload--ripple",
        style: _vm.rippleStyle
      }),
      !_vm.isUploading
        ? [
            _c(
              "span",
              { staticClass: "mi-file-upload--iconContainer" },
              [
                _c("v-icon", { staticClass: "mi-file-upload--icon" }, [
                  _vm._v(
                    _vm._s(_vm.invalidFileType ? "remove_circle" : _vm.fileIcon)
                  )
                ]),
                _c("span", { staticClass: "mi-file-upload--message" }, [
                  _vm._v(_vm._s(_vm.uploadMessage))
                ])
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isUploading
        ? [
            _c(
              "v-layout",
              {
                staticClass: "ma-0 mi-file-upload--scrollContainer",
                style: "max-height: " + _vm.height + "px",
                attrs: { wrap: "", "align-center": "" }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "py-0": "", "px-4": "" } },
                  _vm._l(_vm.filesUploaded, function(item, index) {
                    return _c(
                      "v-layout",
                      {
                        key: "test-" + index,
                        attrs: { wrap: "", "align-center": "", "py-1": "" }
                      },
                      [
                        item
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "text-xs-center": "" } },
                                [
                                  _c("span", {
                                    staticClass: "mi-file-upload--icon mdi",
                                    class: [
                                      _vm.fileIcons[item.icon]
                                        ? _vm.fileIcons[item.icon]
                                        : _vm.fileIcons.other
                                    ]
                                  })
                                ]
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs8: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "pa-0": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs8: "", "pa-0": "" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mi-file-upload--file__name"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("concatenateName")(
                                                    _vm.files[index].name
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs4: "",
                                            "pa-0": "",
                                            "text-xs-right": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mi-file-upload--file__size"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("calculateKB")(
                                                    _vm.files[index].size
                                                  )
                                                ) + " KB"
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "pa-0": "" } },
                                        [
                                          item.isLoading && !item.isUploaded
                                            ? _c("v-progress-linear", {
                                                staticClass: "my-2",
                                                attrs: {
                                                  color: "primary",
                                                  height: "4",
                                                  indeterminate: true
                                                }
                                              })
                                            : _vm._e(),
                                          !item.isLoading && item.isUploaded
                                            ? _c("v-progress-linear", {
                                                staticClass: "my-2",
                                                attrs: {
                                                  color: "success",
                                                  height: "4",
                                                  value: "100"
                                                }
                                              })
                                            : _vm._e(),
                                          !item.isLoading && !item.isUploaded
                                            ? _c("v-progress-linear", {
                                                staticClass: "my-2",
                                                attrs: {
                                                  color: "warning",
                                                  height: "4",
                                                  value: "100"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "", "text-xs-center": "" } },
                                [
                                  !item.isLoading && item.isUploaded
                                    ? _c(
                                        "v-icon",
                                        { staticClass: "success--text" },
                                        [_vm._v("done")]
                                      )
                                    : _vm._e(),
                                  !item.isLoading && !item.isUploaded
                                    ? _c(
                                        "v-icon",
                                        { staticClass: "warning--text" },
                                        [_vm._v("close")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("form", { ref: "uploadFileForm" }, [
        _c("input", {
          ref: "hiddenFileInput",
          staticClass: "hiddenFileInput",
          attrs: { type: "file", multiple: _vm.multiple },
          on: { change: _vm.onFileInputChange }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }