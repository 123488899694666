var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-wrap tw-mb-16 tw-text-lg" },
    [
      _c("div", { staticClass: "tw-text-black-300 tw-w-full" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c(
        "div",
        { staticClass: "tw-w-full" },
        [_vm._t("value"), _vm._v(" " + _vm._s(_vm.value) + " ")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }