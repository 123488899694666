/* tslint:disable:no-console */

import { register } from 'register-service-worker';

register(`${process.env.BASE_URL}service-worker.js`, {
  registered(registration) {
    console.log('Service worker has been registered.');
    setInterval(() => {
      console.log('Interval Update Check');
      registration.update();
    }, 1000 * 60 * 0.5); // e.g. 30 seconds checks
  },
  ready() {
    console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updated(registration) {
    console.log('Content has been updated.');
    document.dispatchEvent(
      new CustomEvent('swUpdated', { detail: registration }),
    );
  },
  offline() {
    // ErrorHandler.updated('No internet connection found. App is running in offline mode and may be unresponsive.');
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});

export default interface ServiceWorkerContainer {
  controller: ServiceWorker;
  updateIsReady?: boolean;
  oncontrollerchange: Function;
  onerror?: Function;
  onmessage: Function;
  onmessageerror: Function;
  ready: Promise<any>; // todo
}
