var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.question
        ? _c(
            "v-card",
            { staticClass: "approval-dialog" },
            [
              [
                _c(
                  "v-flex",
                  { staticClass: "text-xs-right", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.close($event)
                          }
                        }
                      },
                      [_vm._v("cancel")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", [
                          _c(
                            "h2",
                            { staticClass: "defaultAnswerDialog--header" },
                            [
                              _vm._v(
                                " Standaard motivatie teksten voor de vraag: "
                              ),
                              _c("span", { staticClass: "bold" }, [
                                _vm._v(_vm._s(_vm.question.label))
                              ])
                            ]
                          ),
                          _c(
                            "h3",
                            { staticClass: "defaultAnswerDialog--subheader" },
                            [
                              _vm._v(
                                "Selecteer een motivatie tekst om toe te voegen als antwoord."
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "approvalDialogContainer",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("DataTable", {
                              ref: "defaultAnswersDatatable",
                              attrs: {
                                options: _vm.tableOptions,
                                visibility: _vm.visibility
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }