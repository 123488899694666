import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Media, Report } from '@/models/Report';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { AxiosResponse, AxiosError } from 'axios';
import { Options } from '@/components/mi-dialog/MiDialog';
import ErrorHandler from '@/support/ErrorHandler';
import { Model } from '@/models/Model';
import FileUpload from '@/components/file-upload/FileUpload';
import { ADialog } from '@/support/ADialog';
import ReorderMediaDialog from '@/components/ReorderMediaDialog/ReorderMediaDialog.vue';
import { Rpc } from '@/models/Rpc';
import { cloneDeep } from 'lodash';

@Component<Attachments>({})
export default class Attachments extends Vue {
  // #region @Props

  @Prop()
  private acceptedFileTypes!: string[];

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  private hideActions!: boolean;

  @Prop()
  protected options!: AttachmentOptions;

  @Prop({ default: null })
  protected report!: Report | null;

  @Prop({ default: 'Bijlagen' })
  protected title!: string;

  @Prop({ default: null })
  protected type!: string | null;

  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties

  protected attachments: Media[] = [];

  protected fileCounter = 0;

  protected isCreatingAttachment = false;

  protected isCreatingTrillingstool = false;

  protected isLoading = true;

  protected postTimeOut: NodeJS.Timeout | null = null;

  protected selectedAttachment: Media | null = null;

  protected uploadedFile = '';

  protected uploadQueue: File[] = [];

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted() {
    if (! this.options || ! this.options.type) { return; }
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getAttachments();
    this.isLoading = false;
  }

  // #endregion

  // #region Class methods

  public addAttachment() {
    this.isCreatingAttachment = true;
    this.selectedAttachment = null;
  }

  protected close() {
    this.isCreatingAttachment = false;
    this.selectedAttachment = null;
  }

  public deleteAttachment(attachment: Media) {
    this.selectedAttachment = attachment;
    this.$store.dispatch('openDialog', this.dialogOptionsDelete);
  }

  public editAttachment(attachment: Media) {
    this.selectedAttachment = attachment;
    this.isCreatingAttachment = true;
  }

  private filterAcceptedFileTypes(data: Media[]): Media[] {
    return data.filter((media: Media): boolean => !! media.mime_type && this.acceptedFileTypes.includes(media.mime_type));
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.uploadQueue.push(file);
  }

  protected isScopedSlot(name: string) {
    return Object.keys(this.$scopedSlots).some((key: string) => key.includes(name));
  }

  protected async onMediaDragEnd(media: Media[]): Promise<void> {
    // Directly update the order of the array for UI experience (direct update of order without flickering)
    const originalOrder = cloneDeep(this.attachments);

    this.attachments = media;

    // Perform the update
    const success = await this.updateMediaOrder(media);

    // If update failed revert the order
    if (! success) {
      this.attachments = originalOrder;
    }
  }

  // #endregion

  // #region Async methods

  protected createAttachment() {
    if (! this.uploadQueue.length || ! this.report) {
      return;
    }

    const payload: {[key: string]: string | boolean | File} = {
      file: this.uploadQueue[0],
      show_in_report: '0',
    };

    if (this.type) {
      payload.type = this.type;
    }

    new Report()
      .mediaEndpoint(this.report.uuid ? this.report.uuid : '')
      .create(payload)
      .then((response: AxiosResponse) => {
        const queueCopy = [...[], ...this.uploadQueue];
        queueCopy.shift();
        this.uploadQueue = queueCopy;

        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultiple(this.fileCounter);

        if (this.uploadQueue.length <= 0) {
          this.fileCounter = 0;
          this.getAttachments();
        } else {
          setTimeout(() => {
            this.fileCounter += 1;
            this.createAttachment();
          }, 500);
        }
      })
      .catch((error: AxiosError) => {
        const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
        fileUploadComponent.uploadedMultipleFailed(this.fileCounter);
      });
  }

  protected async getAttachments() {
    if (! this.report) { return; }

    const response = await this.report.listMedia({
      type: this.options.type,
    })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      if (this.acceptedFileTypes && this.acceptedFileTypes.length) {
        this.attachments = this.filterAcceptedFileTypes(response.data);
        return;
      }
      this.attachments = response.data;
    }
  }

  protected handleDeleteAttachment() {
    if (! this.report || ! this.selectedAttachment) {
      return;
    }

    this.report
      .deleteMediaEndpoint(this.selectedAttachment.uuid ? this.selectedAttachment.uuid : '')
      .then((response: AxiosResponse) => {
        this.getAttachments();
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async submitMediaKeys(keys: string[]) {
    if (! this.report || ! keys) { return; }
    for (let index = 0; index < keys.length; index += 1) {
      const key = keys[index];

      const payload: {[key: string]: string} = {
        file: `stateless-upload://s3/${key}`,
        show_in_report: '0',
      };

      if (this.type) {
        payload.type = this.type;
      }

      const fileUploadComponent = this.$refs.fileUpload as FileUpload;

      // eslint-disable-next-line no-await-in-loop
      await new Report()
        .mediaEndpoint(this.report.uuid ? this.report.uuid : '')
        .create(payload)
        .then(() => {
          fileUploadComponent.handleUploaded(index);
          this.getAttachments();
        })
        .catch((error: AxiosError) => {
          console.log('ERROR');
          fileUploadComponent.handleError(index);
        });
    }
  }

  protected updateAttachment() {
    if (! this.selectedAttachment || ! this.report) {
      return;
    }

    const payload: {[key: string]: string | boolean} = {
      description: this.selectedAttachment.description ? this.selectedAttachment.description : '',
    };

    if (this.isScopedSlot('type')) {
      payload.type = this.selectedAttachment.type ? this.selectedAttachment.type : '';
    }

    if (this.isScopedSlot('show_in_report')) {
      payload.show_in_report = this.selectedAttachment.show_in_report ? this.selectedAttachment.show_in_report : '0';
    }

    this.report
      .updateMediaEndpoint(this.selectedAttachment.uuid ? this.selectedAttachment.uuid : '', payload)
      .then((response: AxiosResponse) => {
        this.getAttachments();
        this.close();
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async updateMediaOrder(media: Media[]): Promise<boolean> {
    const payload = {
      signature: 'media:order',
      body: {
        media: media.map((media) => media.uuid),
      },
    };

    try {
      await new Rpc()
        .rpcPost(payload);

      return true;
    } catch (error) {
      ErrorHandler.network(error);

      return false;
    }
  }

  // #endregion

  // #region Getters & Setters

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            this.handleDeleteAttachment();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedAttachment = null;
          },
        },
      },
    };
  }

  protected get isPdf() {
    return this.selectedAttachment?.mime_type === 'application/pdf';
  }

  // #endregion

  // #region @Watchers

  @Watch('uploadQueue')
  public uploadQueueChanged(to: File[], from: File[]) {
    if (to.length < from.length) {
      return;
    }

    if (this.postTimeOut) {
      clearTimeout(this.postTimeOut);
    }

    this.postTimeOut = setTimeout(() => {
      this.createAttachment();
    }, 20);
  }

  // #endregion
}

// #region @Enums
// #endregion

// #region @Types
// #endregion

// #region @Interfaces

export interface AttachmentHeader {
  text: string;
  value: string;
  class?: string;
  align?: 'start' | 'center' | 'end';
  visible?: boolean;
}

export interface AttachmentOptions {
  model: Model;
  type: string;
  showAddButton: boolean;
  showOrderButton?: boolean;
  showTrillingsButton: boolean;
  headers: AttachmentHeader[];
}

// #endregion
