var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isTypeSuccess
      ? _c(
          "div",
          { staticClass: "tw-text-center" },
          [
            _c(
              "v-icon",
              {
                staticClass:
                  "atabix-message-dialog__icon atabix-message-dialog__icon--success"
              },
              [_vm._v("mdi-check-circle")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isTypeWarning
      ? _c(
          "div",
          { staticClass: "tw-text-center" },
          [
            _c(
              "v-icon",
              {
                staticClass:
                  "atabix-message-dialog__icon atabix-message-dialog__icon--warning"
              },
              [_vm._v("mdi-alert")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isTypeError
      ? _c(
          "div",
          { staticClass: "tw-text-center" },
          [
            _c(
              "v-icon",
              {
                staticClass:
                  "atabix-message-dialog__icon atabix-message-dialog__icon--error"
              },
              [_vm._v("mdi-close-circle")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }