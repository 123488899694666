import _Vue from 'vue';
import { Breadcrumb } from '@/support/Breadcrumb';

export default function Breadcrumb(Vue: typeof _Vue): void {
  let breadcrumbs: Breadcrumb[] = [];
  const _prop = {
    get: () => breadcrumbs,
    set: (newBreadcrumbs: Breadcrumb[]) => {
      breadcrumbs = newBreadcrumbs;
    },
  };

  Object.defineProperty(Vue, '$breadcrumbs', _prop);
  Object.defineProperty(Vue.prototype, '$breadcrumbs', _prop);
}
