import { RouteRecord } from 'vue-router';

export default class Guard {
  public static auth(record: RouteRecord): boolean {
    return record.meta.requiresAuth;
  }

  public static roles(record: RouteRecord): boolean {
    return record.meta.requiresRoles !== undefined;
  }
}
