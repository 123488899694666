var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atabix-dialog-layout" },
    [
      _c(
        "ACard",
        {
          attrs: { collapse: "", flat: "", squared: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._t("header-close", [
                      _c(
                        "div",
                        { staticClass: "atabix-dialog-layout__close" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "atabix-dialog-layout__close__icon",
                              on: { click: _vm.onCloseButtonClick }
                            },
                            [_vm._v(" " + _vm._s(_vm.closeIcon) + " ")]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._t("header", [
                      _c(
                        "div",
                        { staticClass: "atabix-dialog-layout__header" },
                        [
                          _vm.title && _vm.dialogState === "default"
                            ? _c(
                                "ATitle",
                                {
                                  staticClass:
                                    "atabix-dialog-layout__header__title"
                                },
                                [_vm._v(" " + _vm._s(_vm.title) + " ")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass:
                                "atabix-dialog-layout__header__subtitle"
                            },
                            [
                              _vm.title && _vm.subtitle
                                ? [_vm._v(" | ")]
                                : _vm._e(),
                              _vm._v(" " + _vm._s(_vm.subtitle) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _vm._t("main", [
            _c(
              "div",
              { staticClass: "atabix-dialog__main" },
              [
                _vm.loading ? _c("ALoader") : _vm._e(),
                !_vm.loading
                  ? [
                      _vm.dialogState === "default"
                        ? [_vm._t("default")]
                        : [
                            _c(
                              "div",
                              { staticClass: "atabix-dialog__state-content" },
                              [
                                _vm.dialogState === "success"
                                  ? _vm._t("success", [
                                      _c("AMessageDialogIcon", {
                                        attrs: { type: _vm.dialogState }
                                      }),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "atabix-dialog__state-title tw-text-success-500"
                                        },
                                        [_vm._v(_vm._s(_vm.stateTitle))]
                                      ),
                                      _c("p", {
                                        staticClass:
                                          "atabix-dialog__state-message",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.stateMessage)
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm.dialogState === "error"
                                  ? _vm._t("error", [
                                      _c("AMessageDialogIcon", {
                                        attrs: { type: _vm.dialogState }
                                      }),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "atabix-dialog__state-title tw-text-error-500"
                                        },
                                        [_vm._v(_vm._s(_vm.stateTitle))]
                                      ),
                                      _c("p", {
                                        staticClass:
                                          "atabix-dialog__state-message",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.stateMessage)
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm.dialogState === "warning"
                                  ? _vm._t("warning", [
                                      _c("AMessageDialogIcon", {
                                        attrs: { type: _vm.dialogState }
                                      }),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "atabix-dialog__state-title tw-text-warning-500"
                                        },
                                        [_vm._v(_vm._s(_vm.stateTitle))]
                                      ),
                                      _c("p", {
                                        staticClass:
                                          "atabix-dialog__state-message",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.stateMessage)
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm.hasFooter
            ? _vm._t("footer", [
                _c(
                  "div",
                  { staticClass: "atabix-dialog-layout__footer" },
                  [
                    _vm._t("close", [
                      _c(
                        "AButton",
                        {
                          staticClass:
                            "atabix-dialog-layout__footer__actions__previous",
                          attrs: { alternative: "" },
                          on: { click: _vm.onCloseButtonClick }
                        },
                        [_vm._v(" " + _vm._s(_vm.cancelLabel) + " ")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "atabix-dialog-layout__footer__actions" },
                      [
                        _vm._t("confirm-prepend", null, {
                          loading: _vm.loading,
                          valid: _vm.valid
                        }),
                        _vm.hasConfirm
                          ? _vm._t(
                              "confirm",
                              [
                                _vm.dialogState !== "success"
                                  ? _c(
                                      "AButton",
                                      {
                                        staticClass:
                                          "atabix-dialog-layout__footer__actions__next",
                                        attrs: {
                                          disabled: _vm.isButtonConfirmDisabled,
                                          "prepend-inner-icon": _vm.confirmIcon
                                        },
                                        on: { click: _vm.onConfirmButtonClick }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.dialogState === "warning" ||
                                                _vm.dialogState === "error"
                                                ? _vm.returnLabel
                                                : _vm.confirmLabel
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              { loading: _vm.loading, valid: _vm.valid }
                            )
                          : _vm._e(),
                        _vm._t("confirm-append", null, {
                          loading: _vm.loading,
                          valid: _vm.valid
                        })
                      ],
                      2
                    )
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }