import { Model } from '@/models/Model';
import client from '@/support/Client';
import { AxiosResponse } from 'axios';

export class Export extends Model {
  protected $name = 'Export';

  protected $endpoint = '/exports';

  protected $fillable: string[] = ['from', 'till'];

  public from?: string;

  public till?: string;

  public uuid?: string;

  public created_at?: string;

  public updated_at?: string;

  public getReportStats(payload: any) {
    const endpoint = `${this.$endpoint}/report-numbers`;
    return client('get', endpoint, payload);
  }

  public downloadExcel(id: string): void | Promise<AxiosResponse> {
    if (! id) { return; }
    const endpoint = `${this.$endpoint}/xls`;
    return client('get', endpoint, { organization: id });
  }

  public downloadPgvExport(): Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/pgv`;
    return client('get', endpoint, {});
  }

  public downloadFactSheet(): Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/factsheet`;
    return client('get', endpoint, {});
  }

  public downloadPerformanceExcel(): Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/proficiency-report`;
    return client('get', endpoint, {});
  }

  public downloadConversionMatrix(): Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/conversion-matrix`;
    return client('get', endpoint, {});
  }

  public downloadCedExcel(): void | Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/ced-xls`;
    return client('get', endpoint, {});
  }

  public downloadWeekPlanningExcel(payload: any): void | Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/week-planning`;
    return client('get', endpoint, payload);
  }

  public downloadCostSpecificationExcel(payload: any): void | Promise<AxiosResponse> {
    const endpoint = `${this.$endpoint}/cost-specification`;
    return client('get', endpoint, payload);
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }
}
