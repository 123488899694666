import { Component, Ref } from 'vue-property-decorator';
import ASelectInput from '@/components/ASelectInput/ASelectInput';

@Component<AAutocompleteInput>({})
export default class AAutocompleteInput extends ASelectInput {
  // #region @VModel
  // #endregion

  // #region @PropSyncs
  // #endregion

  // #region @Props
  // #endregion

  // #region @Refs

  @Ref()
  protected vAutocompleteInput!: HTMLFormElement;

  // #endregion

  // #region Class properties
  // #endregion

  // #region Class methods

  protected handleChangeEvent(): void {
    if (this.internalMultiple) { return; }

    this.vAutocompleteInput.blur();
  }

  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}

// #region @Enums
// #endregion

// #region @Types
// #endregion

// #region @Interfaces
// #endregion
