import { DateFormatter } from '@/support/DateFormatter';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component<DateFilter>({})
export default class DateFilter extends Vue {
  @Prop()
  protected value!: string;

  @Prop({ default: false })
  protected readOnly!: boolean;

  protected date = new DateFormatter();

  protected menu = false;

  public async mounted() {
    this.date.selectedDate = this.value;
  }

  protected handleChange() {
    this.$emit('input', this.date.selectedDate);
    this.$emit('handleChange');
  }

  protected onClear() {
    this.date.clear();
    this.handleChange();
  }

  @Watch('value')
  protected dateChanged() {
    this.date.selectedDate = this.value;
  }
}
