import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component<MiFileUpload>({})
export default class MiFileUpload extends Vue {
  @Prop()
  protected acceptedFileTypes?: string[];

  @Prop()
  protected filePath?: string;

  @Prop({ default: false })
  protected clearAfterUpload?: boolean;

  @Prop({ default: false })
  protected manualSubmit?: boolean;

  @Prop({ default: true })
  protected multiple?: boolean;

  @Prop({ default: 'add_photo_alternate' })
  protected fileIcon?: string;

  protected accepted: string [] = [];

  protected filePathClone: string | null = null;

  protected dragging = false;

  protected hovering = false;

  protected isUploading = false;

  protected isUploaded = false;

  protected isUploadedMultiple = false;

  protected invalidFileType = false;

  protected showRipple = false;

  protected style: RippleStyle = {};

  protected files: File[] = [];

  protected filesUploaded: any = [];

  protected ignoreFileType = false;

  protected imageExtesions = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'bmp'];

  protected fileIcons: {[key:string]:string} = {
    pdf: 'mdi-file-pdf-box',
    xls: 'mdi-file-excel-box',
    xlsx: 'mdi-file-excel-box',
    docx: 'mdi-file-word-box',
    doc: 'mdi-file-word-box',
    ppt: 'mdi-file-powerpoint-box',
    pptx: 'mdi-file-powerpoint-box',
    other: 'mdi-file-document-box',
  };

  protected mounted() {
    if (! this.acceptedFileTypes) {
      this.accepted = [
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'msg',
        'oft',
      ];
      this.ignoreFileType = true;
    } else {
      this.accepted = this.acceptedFileTypes;
    }
  }

  protected onFileInputChange() {
    const input = this.$refs.hiddenFileInput as HTMLInputElement;

    if (input.files) {
      this.files = Array.from(input.files);
      if (this.files) {
        this.files.forEach((file: File) => {
          this.filesUploaded.push({ isLoading: true });
          if (! this.ignoreFileType) {
            this.invalidFileType = ! this.accepted.includes(file.type.toString());
          }

          if (this.invalidFileType) {
            this.dragging = true;
            input.value = '';
            setTimeout(() => {
              this.reset();
            }, 1000);
            return;
          }

          this.isUploading = true;
          this.$emit('itemDropped', file);
        });
      }
    }
  }

  private get fileNameFromFilePath() {
    if (! this.filePathClone) {
      return '';
    }

    const parsedPath = this.filePathClone.split('/').pop();
    if (! parsedPath) {
      return '';
    }
    return parsedPath.split('?')[0];
  }

  private get fileExtension() {
    return this.fileNameFromFilePath ? this.fileNameFromFilePath.split('.').pop() : null;
  }

  protected get iconClass() {
    if (! this.fileExtension || ! this.fileIcons[this.fileExtension]) {
      return this.fileIcons.other;
    }

    return this.fileIcons[this.fileExtension];
  }

  protected get isImage() {
    if (! this.filePathClone) {
      return false;
    }

    if (! this.fileExtension) {
      return false;
    }

    return this.imageExtesions.includes(this.fileExtension) || this.filePathClone.includes('data:image');
  }

  protected handleClickUploaderAction(event: any) {
    const input = this.$refs.hiddenFileInput as HTMLInputElement;
    if (input) {
      const event = new MouseEvent('click');
      input.dispatchEvent(event);
    }
  }

  protected get rippleStyle() {
    return this.style;
  }

  protected get uploadMessage() {
    if (! this.dragging) {
      if (this.hovering) {
        return 'Klik om een ​​bestand te selecteren';
      }
      return 'Versleep een bestand om te uploaden';
    }
    if (this.invalidFileType) {
      return 'Dit bestand wordt niet ondersteund';
    }
    return 'Drop het bestand om te uploaden';
  }

  protected handleMouseEnter() {
    this.hovering = true;
  }

  protected handleMouseLeave() {
    this.hovering = false;
  }

  protected handleDragStart(event: DragEvent) {
    //
  }

  protected handleDragEnd(event: DragEvent) {
    this.dragging = false;
  }

  private setRippleStyle(element: HTMLElement, x: number, y: number) {
    const boundingRectangle: ClientRect = element.getBoundingClientRect();
    const size = Math.max(boundingRectangle.width, boundingRectangle.height);
    this.style.height = `${size}px`;
    this.style.width = `${size}px`;
    this.style.top = `${y - boundingRectangle.top - size / 2}px`;
    this.style.left = `${x - boundingRectangle.left - size / 2}px`;
  }

  protected handleDragEnter(event: DragEvent) {
    if (! event.dataTransfer) {
      return;
    }

    this.dragging = true;
    const element: HTMLElement = event.target as HTMLElement;
    this.setRippleStyle(element, event.pageX, event.pageY);

    if (event.dataTransfer.items && event.dataTransfer.items.length && this.accepted.length) {
      const file = Array.from(event.dataTransfer.items)[0];
      if (! this.ignoreFileType) {
        this.invalidFileType = ! this.accepted.includes(file.type.toString());
      }
    }
  }

  protected handleDragOver(event: DragEvent) {
    this.dragging = true;
  }

  protected handleDragLeave(event: DragEvent) {
    this.reset();
  }

  protected handleDrop(event: DragEvent) {
    if (this.invalidFileType) {
      this.reset();
      return;
    }

    this.dragging = false;
    if (! event.dataTransfer) {
      return;
    }

    this.files = Array.from(event.dataTransfer.files);
    this.isUploading = true;
    this.files.forEach((file: File) => {
      this.filesUploaded.push({ isLoading: true });
      this.$emit('itemDropped', file);
    });
  }

  protected removeFile() {
    this.$emit('itemRemoved');
  }

  public reset() {
    this.dragging = false;
    this.hovering = false;
    this.isUploading = false;
    this.invalidFileType = false;
    this.showRipple = false;
    this.isUploaded = false;
  }

  public uploaded() {
    if (this.clearAfterUpload) {
      this.isUploading = false;
      this.isUploaded = true;

      setTimeout(() => {
        this.reset();
      }, 1000);
      return;
    }

    this.isUploaded = true;
    this.isUploading = false;
  }

  public filesUploadedCount() {
    let count = 0;
    this.filesUploaded.forEach((files: any) => {
      if (files.isUploaded) {
        count += 1;
      }
    });

    return count;
  }

  public uploadedMultiple(index: number) {
    this.filesUploaded[index].isUploaded = true;
    this.filesUploaded[index].isLoading = false;
    this.isUploadedMultiple = true;
  }

  public uploadedMultipleFailed(index: number) {
    this.filesUploaded[index].isUploaded = false;
    this.filesUploaded[index].isLoading = false;
    this.isUploadedMultiple = true;
  }

  @Watch('filePath')
  protected filePathChanged() {
    if (this.filePath && ! this.clearAfterUpload) {
      this.filePathClone = this.filePath;
    }
  }
}

interface RippleStyle {
  top?: string;
  left?: string;
  height?: string;
  width?: string;
}
