var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-filter custom-filter--filterMenu" },
    [
      _c(
        "v-menu",
        {
          staticClass: "userFilterMenu",
          attrs: {
            transition: "slide-y-transition",
            "offset-y": "",
            left: "",
            "nudge-right": "10",
            "nudge-bottom": "10"
          },
          model: {
            value: _vm.isFiltersMenuOpen,
            callback: function($$v) {
              _vm.isFiltersMenuOpen = $$v
            },
            expression: "isFiltersMenuOpen"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "userContainer__user",
              attrs: { slot: "activator" },
              slot: "activator"
            },
            [
              _c("span", { staticClass: "userContainer__username" }, [
                _vm._v(
                  _vm._s(_vm._f("concatenateString")(_vm.selectedFilterName))
                )
              ]),
              _c("v-icon", [
                _vm._v(_vm._s(_vm.isFiltersMenuOpen ? "close" : "filter_list"))
              ])
            ],
            1
          ),
          _vm.isFiltersMenuOpen
            ? _c(
                "v-list",
                { staticClass: "submenuList submenuList--userfilter" },
                [
                  _vm.userFilters
                    ? [
                        _vm._l(_vm.userFilters, function(userFilter, index) {
                          return [
                            _c(
                              "v-list-tile",
                              {
                                key: "filter-" + index,
                                staticClass: "submenuList__item",
                                class: {
                                  isActive: _vm.isSelectedFilter(
                                    userFilter.name
                                  )
                                }
                              },
                              [
                                _c(
                                  "v-list-tile-content",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleApplyFilter(
                                          userFilter.user_filters,
                                          userFilter.name
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-list-tile-title", [
                                      _vm._v(
                                        " " + _vm._s(userFilter.name) + " "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "pl-1" }, [
                                  userFilter.default
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "statusPill statusColor--primary statusPill--small"
                                        },
                                        [_vm._v("Default")]
                                      )
                                    : _vm._e()
                                ]),
                                _c(
                                  "v-list-tile-avatar",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "remove",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleRemoveFilter(
                                                              userFilter
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("delete")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Filter verwijderen")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        })
                      ]
                    : _vm._e(),
                  _c(
                    "v-list-tile",
                    {
                      staticClass:
                        "submenuList__item submenuList__item--action",
                      on: {
                        click: function($event) {
                          _vm.isCreatingFilter = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          _c("v-icon", { staticClass: "add" }, [_vm._v("save")])
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", { staticClass: "text" }, [
                            _vm._v("Huidige filter opslaan")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isCreatingFilter
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "450" },
              model: {
                value: _vm.isCreatingFilter,
                callback: function($$v) {
                  _vm.isCreatingFilter = $$v
                },
                expression: "isCreatingFilter"
              }
            },
            [
              _c(
                "div",
                { staticClass: "defaultDialog" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "dialog-close",
                                          on: {
                                            click: function($event) {
                                              return _vm.closeCreatingFilter()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("close")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2966348339
                          )
                        },
                        [_c("span", [_vm._v("Sluiten")])]
                      ),
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-end": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c("h2", { staticClass: "dialog-title" }, [
                                    _vm._v("Huidige filter opslaan")
                                  ])
                                ]
                              ),
                              _vm.isLoading
                                ? [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("LoaderCard", {
                                          attrs: {
                                            flat: "",
                                            type: "spinner--center"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              !_vm.isLoading
                                ? [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: { label: "Naam filter" },
                                          model: {
                                            value: _vm.filterName,
                                            callback: function($$v) {
                                              _vm.filterName = $$v
                                            },
                                            expression: "filterName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            label: "Zet filter op default",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.filterDefault,
                                            callback: function($$v) {
                                              _vm.filterDefault = $$v
                                            },
                                            expression: "filterDefault"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mt-5": "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "justify-end": "",
                                              "align-center": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { shrink: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fadedText link mr-3",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.closeCreatingFilter()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Annuleren")]
                                                ),
                                                _c(
                                                  "MiButton",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.isDisabled,
                                                      color: "primary",
                                                      small: "true"
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        return _vm.saveFilter()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Opslaan ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }