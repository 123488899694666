import { Model } from '@/models/Model';

export class OverruleReason extends Model {
  protected $name = 'OverruleReason';

  protected $endpoint = '/overrule-reasons';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;
}
