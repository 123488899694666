var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question && !_vm.question.hidden
    ? _c(
        "div",
        {
          staticClass: "question",
          attrs: { id: _vm.question.uuid, "data-type": _vm.question.type }
        },
        [
          _vm.$store.state.displayQuestionKeys
            ? [
                _c("strong", [
                  _vm._v(
                    "Key: " +
                      _vm._s(_vm.question.key) +
                      " - sort_order: " +
                      _vm._s(_vm.question.sort_order)
                  )
                ])
              ]
            : _vm._e(),
          _vm.question.type === "header"
            ? _c(
                "h2",
                { staticClass: "sectionTitle" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.parsedQuestionLabel)
                          }
                        })
                      ]),
                      !_vm.readOnly
                        ? _c("QuestionActions", {
                            attrs: { question: _vm.question },
                            on: {
                              clickInfo: function($event) {
                                return _vm.onClickInfo(_vm.question)
                              },
                              clickMetaPgvLimit: function($event) {
                                return _vm.onClickMetaPgvLimit(_vm.question)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isCausalityQuestion && !_vm.readOnly
            ? _c(
                "div",
                [
                  _c("CausalityQuestion", {
                    attrs: {
                      disabled: _vm.disabled,
                      question: _vm.question,
                      report: _vm.report,
                      damage: _vm.damage
                    },
                    on: {
                      reloadAnswers: _vm.reloadDamageAnswers,
                      damageAnswerSaved: _vm.damageAnswerSaved
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBuildingFeaturesQuestion && !_vm.readOnly
            ? _c(
                "div",
                [
                  _c("BuildingFeaturesQuestion", {
                    attrs: {
                      disabled: _vm.disabled,
                      question: _vm.question,
                      report: _vm.report
                    },
                    on: { reloadAnswers: _vm.reloadReportAnswers }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isImageRecognitionQuestion &&
          !_vm.readOnly &&
          _vm.$store.state.Auth.can(_vm.PermissionSlug.IMAGE_MATCHING)
            ? _c(
                "div",
                [
                  _c("ImageRecognitionQuestion", {
                    attrs: {
                      disabled: !_vm.isImageRecognitionAvaliable,
                      question: _vm.question,
                      report: _vm.report,
                      damage: _vm.damage
                    },
                    on: {
                      reloadAnswers: _vm.reloadDamageAnswers,
                      damageAnswerSaved: _vm.damageAnswerSaved
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.question.is_formattable
            ? _c(
                "div",
                { class: { "d-none": !_vm.readMode } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "item__list",
                      attrs: { row: "", "align-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "list__item list__item--clickable",
                          class: {
                            "list__item--clickable--active": _vm.editMode
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toggleEditMode($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-center": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _vm.answerValue && _vm.answerValue.length
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "#62DC4F" }
                                        },
                                        [_vm._v("check")]
                                      )
                                    : _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { color: "#F9BD35" }
                                        },
                                        [_vm._v("warning")]
                                      )
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.parsedQuestionLabel)
                                  }
                                })
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _vm.question.type === "checkbox"
                                    ? [
                                        _vm._l(_vm.answerValue, function(
                                          option,
                                          index
                                        ) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              staticClass:
                                                "font-bold withLineBreak d-block"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(option.value) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        _vm.assumedValue
                                          ? _c("span", [
                                              _vm._v("(is een aanname)")
                                            ])
                                          : _vm._e()
                                      ]
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "withLineBreak bold",
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.answerValue) + " "
                                          ),
                                          _vm.assumedValue
                                            ? [_vm._v("(is een aanname)")]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.readOnly
                        ? _c("QuestionActions", {
                            attrs: { question: _vm.question },
                            on: {
                              clickInfo: function($event) {
                                return _vm.onClickInfo(_vm.question)
                              },
                              clickMetaPgvLimit: function($event) {
                                return _vm.onClickMetaPgvLimit(_vm.question)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isImageRecognitionQuestion
            ? _c(
                "div",
                { class: { "d-none": !_vm.editMode } },
                [
                  _vm.question.type === "textbox"
                    ? _c(
                        "v-layout",
                        { attrs: { "align-center": "" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                name: _vm.question.key,
                                disabled: _vm.question.disabled || _vm.disabled,
                                required: _vm.question.required,
                                color: "secondary",
                                "append-icon": "expand_less"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.questionAnswered()
                                },
                                keyup: _vm.answerDebounce,
                                "click:append": _vm.toggleEditMode
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.parsedQuestionLabel
                                            )
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3767200945
                              ),
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "append-outer" },
                                [
                                  _c("QuestionActions", {
                                    attrs: { question: _vm.question },
                                    on: {
                                      clickInfo: function($event) {
                                        return _vm.onClickInfo(_vm.question)
                                      },
                                      clickMetaPgvLimit: function($event) {
                                        return _vm.onClickMetaPgvLimit(
                                          _vm.question
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm.question.is_assumable
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "assumable__checkbox",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      ripple: false,
                                      color: "#a09b1b",
                                      label: "is het een aanname?"
                                    },
                                    on: { change: _vm.toggleAssumable },
                                    model: {
                                      value: _vm.assumedValue,
                                      callback: function($$v) {
                                        _vm.assumedValue = $$v
                                      },
                                      expression: "assumedValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.question.type === "number"
                    ? _c(
                        "v-layout",
                        { attrs: { "align-center": "" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                name: _vm.question.key,
                                disabled: _vm.question.disabled || _vm.disabled,
                                required: _vm.question.required,
                                color: "secondary",
                                type: "number",
                                step:
                                  (_vm.question.meta &&
                                    _vm.question.meta.step) ||
                                  "1",
                                "append-icon": "expand_less"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.questionAnswered()
                                },
                                keyup: _vm.answerDebounce,
                                "click:append": _vm.toggleEditMode
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.parsedQuestionLabel
                                            )
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3767200945
                              ),
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "append-outer" },
                                [
                                  _c("QuestionActions", {
                                    attrs: { question: _vm.question },
                                    on: {
                                      clickInfo: function($event) {
                                        return _vm.onClickInfo(_vm.question)
                                      },
                                      clickMetaPgvLimit: function($event) {
                                        return _vm.onClickMetaPgvLimit(
                                          _vm.question
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm.question.is_assumable
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "assumable__checkbox",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      ripple: false,
                                      color: "#a09b1b",
                                      label: "is het een aanname?"
                                    },
                                    on: { change: _vm.toggleAssumable },
                                    model: {
                                      value: _vm.assumedValue,
                                      callback: function($$v) {
                                        _vm.assumedValue = $$v
                                      },
                                      expression: "assumedValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.question.type === "year"
                    ? _c(
                        "v-layout",
                        { attrs: { "align-center": "" } },
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                name: _vm.question.key,
                                disabled: _vm.question.disabled || _vm.disabled,
                                required: _vm.question.required,
                                type: "number",
                                color: "secondary",
                                "append-icon": "expand_less"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.questionAnswered()
                                },
                                keyup: _vm.answerDebounce,
                                "click:append": _vm.toggleEditMode
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.parsedQuestionLabel
                                            )
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3767200945
                              ),
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "append-outer" },
                                [
                                  _c("QuestionActions", {
                                    attrs: { question: _vm.question },
                                    on: {
                                      clickInfo: function($event) {
                                        return _vm.onClickInfo(_vm.question)
                                      },
                                      clickMetaPgvLimit: function($event) {
                                        return _vm.onClickMetaPgvLimit(
                                          _vm.question
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm.question.is_assumable
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "assumable__checkbox",
                                  attrs: { shrink: "" }
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      ripple: false,
                                      color: "#a09b1b",
                                      label: "is het een aanname?"
                                    },
                                    on: { change: _vm.toggleAssumable },
                                    model: {
                                      value: _vm.assumedValue,
                                      callback: function($$v) {
                                        _vm.assumedValue = $$v
                                      },
                                      expression: "assumedValue"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.question.type === "textarea"
                    ? _c(
                        "div",
                        [
                          _c("prefillable-text-area", {
                            attrs: {
                              question: _vm.question,
                              answer: _vm.inputValue,
                              disabled: _vm.disabled,
                              assumedValue: _vm.assumedValue
                            },
                            on: {
                              textareaChanged: _vm.textareaChanged,
                              textareaDebounce: _vm.textareaDebounce,
                              toggleEditMode: _vm.toggleEditMode,
                              onClickInfo: _vm.onClickInfo,
                              toggleAssumable: _vm.toggleAssumable
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.question.type === "checkbox"
                    ? _c(
                        "div",
                        { staticClass: "formGroup" },
                        [
                          _c(
                            "label",
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { shrink: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleEditMode($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.parsedQuestionLabel
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "groupContainer",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _vm.question.is_assumable
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "assumable__checkbox",
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "mb-2 pa-0",
                                                attrs: {
                                                  "hide-details": "",
                                                  ripple: false,
                                                  color: "#a09b1b",
                                                  label: "is het een aanname?"
                                                },
                                                on: {
                                                  change: _vm.toggleAssumable
                                                },
                                                model: {
                                                  value: _vm.assumedValue,
                                                  callback: function($$v) {
                                                    _vm.assumedValue = $$v
                                                  },
                                                  expression: "assumedValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("QuestionActions", {
                                        attrs: { question: _vm.question },
                                        on: {
                                          clickInfo: function($event) {
                                            return _vm.onClickInfo(_vm.question)
                                          },
                                          clickMetaPgvLimit: function($event) {
                                            return _vm.onClickMetaPgvLimit(
                                              _vm.question
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.question.options, function(option, index) {
                            return _c("v-checkbox", {
                              key: index,
                              attrs: {
                                value: _vm.damage ? option.key : option.uuid,
                                label: option.label,
                                name: _vm.question.key,
                                id: option.key,
                                disabled: _vm.disabled,
                                primary: "",
                                "hide-details": "",
                                color: "secondary"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.saveAnswer()
                                }
                              },
                              model: {
                                value: _vm.inputValueCheckbox,
                                callback: function($$v) {
                                  _vm.inputValueCheckbox = $$v
                                },
                                expression: "inputValueCheckbox"
                              }
                            })
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.question.type === "radio" && !_vm.isCausalityQuestion
                    ? _c(
                        "div",
                        {
                          staticClass: "formGroup",
                          attrs: { "data-key": _vm.question.key }
                        },
                        [
                          _c(
                            "label",
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-space-between": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { shrink: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.toggleEditMode($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.parsedQuestionLabel
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm.question.is_assumable
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "assumable__checkbox",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "my-2 pa-0",
                                            attrs: {
                                              "hide-details": "",
                                              ripple: false,
                                              color: "#a09b1b",
                                              label: "is het een aanname?"
                                            },
                                            on: { change: _vm.toggleAssumable },
                                            model: {
                                              value: _vm.assumedValue,
                                              callback: function($$v) {
                                                _vm.assumedValue = $$v
                                              },
                                              expression: "assumedValue"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("QuestionActions", {
                                    attrs: { question: _vm.question },
                                    on: {
                                      clickInfo: function($event) {
                                        return _vm.onClickInfo(_vm.question)
                                      },
                                      clickMetaPgvLimit: function($event) {
                                        return _vm.onClickMetaPgvLimit(
                                          _vm.question
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                mandatory: false,
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.questionAnswered()
                                }
                              },
                              model: {
                                value: _vm.inputValueRadio,
                                callback: function($$v) {
                                  _vm.inputValueRadio = $$v
                                },
                                expression: "inputValueRadio"
                              }
                            },
                            [
                              _vm._l(_vm.question.options, function(
                                option,
                                index
                              ) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      key: index,
                                      staticClass: "relative radioSelection",
                                      class:
                                        index !== _vm.question.options.length
                                          ? "mb-2"
                                          : ""
                                    },
                                    [
                                      _c("v-radio", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          label: option.label,
                                          value: option,
                                          color: "secondary"
                                        }
                                      }),
                                      _vm.isVisibleDeleteButton(option)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "deleteSelection",
                                              on: {
                                                click: function($event) {
                                                  _vm.inputValueRadio = ""
                                                  _vm.questionAnswered()
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("delete")])],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.question.type === "date"
                    ? [
                        _c(
                          "v-layout",
                          { attrs: { "pa-0": "", "ma-0": "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: _vm.question.ref,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "130",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px",
                                  disabled: _vm.disabled
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    "prepend-icon": "event",
                                    disabled: _vm.disabled,
                                    clearable: "",
                                    "error-messages": _vm.date.errorMessage,
                                    "append-icon": "expand_less"
                                  },
                                  on: { "click:append": _vm.toggleEditMode },
                                  slot: "activator",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.parsedQuestionLabel
                                                )
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    3767200945
                                  ),
                                  model: {
                                    value: _vm.date.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.date, "formattedDate", $$v)
                                    },
                                    expression: "date.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16",
                                    "first-day-of-week": "1"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.questionAnswered()
                                    }
                                  },
                                  model: {
                                    value: _vm.date.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.date, "selectedDate", $$v)
                                    },
                                    expression: "date.selectedDate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "pa-0": "",
                                  "ma-0": "",
                                  "align-center": ""
                                }
                              },
                              [
                                _vm.question.is_assumable
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "assumable__checkbox assumable__checkbox-date",
                                        attrs: { shrink: "" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "",
                                            ripple: false,
                                            color: "#a09b1b",
                                            label: "is het een aanname?"
                                          },
                                          on: { change: _vm.toggleAssumable },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.parsedQuestionLabel
                                                        )
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            3767200945
                                          ),
                                          model: {
                                            value: _vm.assumedValue,
                                            callback: function($$v) {
                                              _vm.assumedValue = $$v
                                            },
                                            expression: "assumedValue"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("QuestionActions", {
                              attrs: { question: _vm.question },
                              on: {
                                clickInfo: function($event) {
                                  return _vm.onClickInfo(_vm.question)
                                },
                                clickMetaPgvLimit: function($event) {
                                  return _vm.onClickMetaPgvLimit(_vm.question)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.question.type === "alert"
            ? _c("p", { staticClass: "alertMessage" }, [
                _vm._v(_vm._s(_vm.parsedQuestionLabel))
              ])
            : _vm._e(),
          !_vm.refreshChildren &&
          _vm.inputValueRadio &&
          _vm.inputValueRadio.questions
            ? _c(
                "div",
                _vm._l(_vm.inputValueRadio.questions, function(
                  nestedQuestion,
                  index
                ) {
                  return _c(
                    "div",
                    { key: index, attrs: { xs12: "" } },
                    [
                      _c("question", {
                        attrs: {
                          question: nestedQuestion,
                          answers: _vm.answers,
                          report: _vm.report,
                          damage: _vm.damage,
                          disabled: _vm.disabled,
                          readOnly: _vm.readOnly,
                          enableReadMode: _vm.enableReadMode,
                          toggleAllEditMode: _vm.toggleAllEditMode,
                          hiddenQuestionKeys: _vm.hiddenQuestionKeys
                        },
                        on: {
                          reportAnswerSaved: _vm.reportAnswerSaved,
                          damageAnswerSaved: _vm.damageAnswerSaved,
                          reloadAnswers: _vm.reloadAnswers,
                          reloadDamageAnswers: _vm.reloadDamageAnswers,
                          reloadReportAnswers: _vm.reloadReportAnswers,
                          onClickInfo: _vm.onClickInfo,
                          onClickMetaPgvLimit: _vm.onClickMetaPgvLimit
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.refreshChildren
            ? _vm._l(_vm.question.options, function(checkboxOption, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _vm.isSelectedCheckbox(checkboxOption)
                      ? _vm._l(checkboxOption.questions, function(
                          nestedQuestion,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, attrs: { xs12: "" } },
                            [
                              _c("question", {
                                attrs: {
                                  question: nestedQuestion,
                                  answers: _vm.answers,
                                  report: _vm.report,
                                  damage: _vm.damage,
                                  disabled: _vm.disabled,
                                  readOnly: _vm.readOnly,
                                  enableReadMode: _vm.enableReadMode,
                                  toggleAllEditMode: _vm.toggleAllEditMode,
                                  hiddenQuestionKeys: _vm.hiddenQuestionKeys
                                },
                                on: {
                                  reportAnswerSaved: _vm.reportAnswerSaved,
                                  damageAnswerSaved: _vm.damageAnswerSaved,
                                  reloadAnswers: _vm.reloadAnswers,
                                  reloadDamageAnswers: _vm.reloadDamageAnswers,
                                  reloadReportAnswers: _vm.reloadReportAnswers,
                                  onClickInfo: _vm.onClickInfo
                                }
                              })
                            ],
                            1
                          )
                        })
                      : _vm._e()
                  ],
                  2
                )
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }