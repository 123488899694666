import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<ApprovalBar>({})
export default class ApprovalBar extends Vue {
  @Prop()
  protected backUrl!: string;

  @Prop()
  protected backFunction!: boolean;

  @Prop({ default: 'Terug' })
  protected backText!: string;

  public mounted() {
    //
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected handleBackAction() {
    if (this.backFunction) {
      this.$emit('back');
    } else {
      this.navigateToUrl(this.backUrl);
    }
  }
}
