import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<ALoader>({})
export default class ALoader extends Vue {
  @Prop({ default: '' }) protected title!: string;

  @Prop({ default: '128' }) protected size!: ALoaderSize;

  @Prop({ default: 'primary-300' })
  protected color!: string;

  protected get isHexColor(): boolean {
    return /^#([0-9A-F]{3}){1,2}$/i.test(this.color);
  }

  protected get colorClass(): string {
    return ! this.isHexColor ? `tw-border-r-${this.color}` : '';
  }

  protected get styles(): Record<string, any> {
    return {
      ...(this.isHexColor
        ? {
          'border-right-color': this.color,
        }
        : {}),
      ...{
        width: `${this.size}px`,
        height: `${this.size}px`,
      },
    };
  }
}

type ALoaderSize = '16' | '24' | '32' | '48' | '64' | '128';
