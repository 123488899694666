var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "defaultDialog" },
      [
        _c(
          "v-card",
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            {
                              staticClass: "dialog-close",
                              on: { click: _vm.close }
                            },
                            on
                          ),
                          [_vm._v("close")]
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Sluiten")])]
            ),
            _c(
              "v-container",
              {
                attrs: {
                  "fill-height": "",
                  fluid: "",
                  "grid-list-lg": "",
                  "pa-0": ""
                }
              },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      "align-space-between": "",
                      "justify-space-between": "",
                      column: "",
                      "fill-height": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "flex-none",
                        attrs: { xs12: "", "mb-3": "" }
                      },
                      [
                        _c(
                          "h2",
                          { staticClass: "dialog-title" },
                          [_vm._t("header")],
                          2
                        )
                      ]
                    ),
                    _vm.isLoading
                      ? [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("LoaderCard", {
                                attrs: { flat: "", type: "spinner--center" }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    !_vm.isLoading
                      ? [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [_vm._t("content")],
                            2
                          ),
                          _vm.$slots["actions"]
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "flex-none",
                                  attrs: { xs12: "", "mt-5": "" }
                                },
                                [_vm._t("actions")],
                                2
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }