import { WeekType } from '@/components/Availability/WorkHours/WorkHours';
import { EventType } from '@/models/Event';
import { Model } from '@/models/Model';

export class Schedule extends Model {
  protected $name = 'Schedule';

  protected $primaryKey = 'id';

  protected $endpoint = '';

  protected $fillable: string[] = [];

  public day?: string;

  public starts_at?: string;

  public ends_at?: string;

  public week_type?: WeekType;

  public max_appointment_count?: string;

  public max_appointment_type_count?: {[key: string]: string};

  public daypart_morning?: EventType;

  public daypart_afternoon?: EventType;

  public user(userId: string) {
    this.$endpoint = `/users/${userId}/relationships/schedules`;
    return this;
  }

  public resolvePrimaryKey(): string {
    return '';
  }
}
