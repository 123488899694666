import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ReportType } from '@/models/ReportType';
import { ReportTypes } from '@/support/ReportTypes';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { UserRole } from '@/models/User';

@Component<ReportTypeFilter>({})
export default class ReportTypeFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: () => [] })
  protected selectables!: ReportTypes[];

  protected readonly appealsCommitteeSelectables: ReportTypes[] = [
    ReportTypes.ADVIESRAPPORT_BEZWAAR,
    ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM,
    ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING,
    ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK,
    ReportTypes.BEZWAAR_2021,
    ReportTypes.BEZWAAR_AMBTELIJK_2,
    ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
    ReportTypes.BEZWAAR_BAC_2,
    ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
  ];

  protected reportTypes: ReportType[] = [];

  protected types = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.types);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.types = [];
    } else {
      this.types = cloneDeep(this.value);
    }
  }

  public async mounted() {
    await this.getReportTypes();
  }

  protected async getReportTypes(): Promise<void> {
    await new ReportType()
      .all()
      .then((reportTypes: ReportType[]) => {
        const selectables = this.$store.state.Auth.hasRole(UserRole.APPEALS_COMMITTEE)
          ? this.appealsCommitteeSelectables
          : this.selectables;

        if (selectables.length) {
          reportTypes.forEach((reportType: ReportType) => {
            if (selectables.some(x => x === reportType.uuid)) {
              this.reportTypes.push(reportType);
            }
          });
          this.itemsChanged();
          return;
        }

        this.reportTypes = reportTypes;
        this.itemsChanged();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'types', this.reportTypes.map((reportType: ReportType) => reportType.uuid || ''));
    } else {
      this.$set(this, 'types', []);
    }

    this.handleChange();
  }
}
