import { Component, Vue, Prop } from 'vue-property-decorator';
import { Str } from '@atabix/core';
import { ADialogButtonOptions, ADialogOptions, AMessageDialogType } from '@/support/ADialog';
import Modal from '@/services/AModal';

@Component<AMessageDialog>({})
export default class AMessageDialog extends Vue {
  @Prop({ default: {} })
  protected options!: ADialogOptions;

  protected settings: ADialogOptions | null = null;

  protected isProcessing = false;

  public cancel(): void {
    if (this.hasCancelButton && this.options && this.options.buttons) {
      const button: ADialogButtonOptions = this.options.buttons.cancel as ADialogButtonOptions;
      if (button.action) {
        button.action();
      }
    }

    this.close();
  }

  public async confirm(): Promise<void> {
    this.isProcessing = true;

    if (this.hasConfirmButton && this.options && this.options.buttons) {
      const button: ADialogButtonOptions = this.options.buttons.confirm as ADialogButtonOptions;

      if (button.action) {
        await button.action();
      }
    }

    this.isProcessing = false;

    if (
      this.options?.buttons?.confirm?.autoClose === undefined
      || this.options?.buttons?.confirm?.autoClose === true
    ) {
      this.close();
    }
  }

  public close(): void {
    Modal.close();
  }

  get dialogText(): string {
    return Str.purify(this.options.text);
  }

  get dialogTitle(): string {
    return this.options.title || Str.ucwords(this.options.type);
  }

  get borderColor(): string {
    switch (this.options.type) {
      case AMessageDialogType.SUCCESS: {
        return 'border-success-500';
      }
      case AMessageDialogType.WARNING: {
        return 'border-warning-500';
      }
      case AMessageDialogType.ERROR: {
        return 'border-error-500';
      }
      default:
        return '';
    }
  }

  // #region Button Visiblity getters
  get hasCancelButton(): boolean {
    if (! this.options || ! this.options.buttons || ! this.options.buttons.cancel) {
      return false;
    }

    return this.options.buttons.cancel.text !== '';
  }

  get hasConfirmButton(): boolean {
    if (! this.options || ! this.options.buttons || ! this.options.buttons.confirm) {
      return false;
    }

    return this.options.buttons.confirm.text !== '';
  }

  get hasCloseButton(): boolean {
    return ! this.hasCancelButton && ! this.hasConfirmButton;
  }
  // #endregion

  // #region Button Text getters
  get confirmButtonText(): string | undefined {
    return this.options?.buttons?.confirm?.text;
  }

  get cancelButtonText(): string | undefined {
    return this.options?.buttons?.cancel?.text;
  }

  get closeButtonText(): string | undefined {
    return this.options?.closeLabel || 'Close';
  }
  // #endregion

  // #region Button Class getters
  get confirmButtonClass(): string {
    return this.options?.buttons?.confirm?.color || 'bg-success-500 hover:bg-success-600';
  }

  get cancelButtonClass(): string {
    return this.options?.buttons?.cancel?.color || 'bg-gray-500 hover:bg-gray-600';
  }

  get closeButtonClass(): string {
    if (this.options.type === AMessageDialogType.SUCCESS) {
      return 'bg-success-500 hover:bg-success-600';
    }
    if (this.options.type === AMessageDialogType.WARNING) {
      return 'bg-warning-500 hover:bg-warning-600';
    }
    if (this.options.type === AMessageDialogType.ERROR) {
      return 'bg-error-500 hover:bg-error-600';
    }

    return 'bg-gray-500 hover:bg-gray-600';
  }
  // #endregion

  // #region Button Icon getters
  get confirmButtonIcon(): string | undefined {
    return this.options?.buttons?.confirm?.icon;
  }

  get cancelButtonIcon(): string | undefined {
    return this.options?.buttons?.cancel?.icon;
  }

  get closeButtonIcon(): string | undefined {
    if (this.options.type === AMessageDialogType.SUCCESS) {
      return 'mdi-check-circle';
    }
    return 'mdi-close-circle';
  }
  // #endregion
}
