export const firstTimeRightBureau: FirstTimeRight[] = [
  {
    name: 'Onvolledig / onjuist',
    value: 'onvolledig',
  },
  {
    name: 'Calculatie',
    value: 'calculaties',
  },
  {
    name: 'Bewijsvermoeden',
    value: 'bewijsvermoeden',
  },
];

export const firstTimeRightTcmg: FirstTimeRight[] = [
  {
    name: 'Onbrekende informatie/documenten',
    value: 'onvolledig',
  },
  {
    name: 'Aangeleverd in verkeerde variant',
    value: 'verkeerde_variant',
  },
];

export const ftrConfirmationStatusMap: FtrConfirmationStatusMap[] = [
  {
    key: 'pending',
    label: 'Onbeoordeeld',
    color: 'yellow',
  },
  {
    key: 'rejected',
    label: 'Niet akkoord',
    color: 'red',
  },
  {
    key: 'confirmed',
    label: 'Bevestigd',
    color: 'green',
  },
];

export const getFirstTimeRightName = (key: string, tcmg: boolean): string => (getFirstTimeRightAttribute(key, 'name', tcmg) as string);

const getFirstTimeRightAttribute = (key: string, attribute: string, tcmg: boolean) => {
  const firstTimeRight = searchFirstTimeRight(key, tcmg);

  return (firstTimeRight as FirstTimeRight)[attribute];
};

const searchFirstTimeRight = (key: string, tcmg: boolean) => {
  const defaultStatus: FirstTimeRight = { value: '', name: '' };

  let firstTimeRight;

  if (tcmg) {
    firstTimeRight = firstTimeRightTcmg.find((firstTimeRightObject: FirstTimeRight) => firstTimeRightObject.value === key);
  } else {
    firstTimeRight = firstTimeRightBureau.find((firstTimeRightObject: FirstTimeRight) => firstTimeRightObject.value === key);
  }

  return firstTimeRight || defaultStatus;
};

export interface FirstTimeRight {
  [key: string]: string;
  name: string;
  value: string;
}

export interface FtrConfirmationStatusMap {
  [key: string]: string | string[];
  key: string;
  label: string;
  color: string;
}
