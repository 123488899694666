/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// eslint-disable-next-line import/no-unresolved
import { ValidationRuleResult, ValidationRuleSchema } from 'vee-validate/dist/types/types';
import { ATranslator } from '@/support/ATranslator';
import { IBAN, BIC } from 'ibankit';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

type veeRuleParams = any[] | Record<string, any>;
type veeRuleReturnType = boolean | string | ValidationRuleResult | Promise<boolean | string | ValidationRuleResult>;

/** Validation Functions */
function dateBeforeValidationRule(value: any, params: {dateToCheck: string; target: string} | Record<string, any>): veeRuleReturnType {
  if (! value || ! params) { return false; }

  const checkDate = dayjs(params.dateToCheck);
  const selectedDate = dayjs(params.target);

  return selectedDate.isValid()
    && checkDate.isValid()
    && checkDate.isBefore(selectedDate);
}

function dateAfterValidationRule(value: any, params: {dateToCheck: string; target: string} | Record<string, any>): veeRuleReturnType {
  if (! value || ! params) { return false; }

  const checkDate = dayjs(params.dateToCheck);
  const selectedDate = dayjs(params.target);

  return selectedDate.isValid()
    && checkDate.isValid()
    && checkDate.isAfter(selectedDate);
}

function dateAfterOrEqualValidationRule(value: any, params: {dateToCheck: string; target: string} | Record<string, any>): veeRuleReturnType {
  if (! value || ! params) { return false; }

  const checkDate = dayjs(params.dateToCheck);
  const selectedDate = dayjs(params.target);

  return selectedDate.isValid()
    && checkDate.isValid()
    && checkDate.isSameOrAfter(selectedDate);
}

function dateBeforeOrEqualValidationRule(value: any, params: {dateToCheck: string; target: string} | Record<string, any>): veeRuleReturnType {
  if (! value || ! params) { return false; }

  const checkDate = dayjs(params.dateToCheck);
  const selectedDate = dayjs(params.target);

  return selectedDate.isValid()
    && checkDate.isValid()
    && checkDate.isSameOrBefore(selectedDate);
}

function dateBetweenValidationRule(value: any, params: {startDate: string; endDate: string; target: string} | Record<string, any>): veeRuleReturnType {
  if (! value || ! params) { return false; }

  const startDate = dayjs(params.startDate);
  const endDate = dayjs(params.endDate);
  const selectedDate = dayjs(params.target);

  return selectedDate.isValid()
    && startDate.isValid()
    && endDate.isValid()
    && selectedDate.isBetween(startDate, endDate);
}

function ibanValidationRule(value: any): veeRuleReturnType {
  if (! value) { return false; }
  return IBAN.isValid(value);
}

function bicValidationRule(value: any): veeRuleReturnType {
  if (! value) { return false; }
  return BIC.isValid(value);
}

/** Schemas */
export const accepted: ValidationRuleSchema = {
  validate: (value: any, _params: veeRuleParams): veeRuleReturnType => {
    if (typeof value === 'boolean') {
      return value;
    }

    return (value === 'true' || value === 'on' || value === 1);
  },
  message: (field: string, _params: Record<string, any>): string => ATranslator.tc('validation_rules_accepted', 1, { item: field }) || `The ${field} must be accepted.`,
};

export const date: ValidationRuleSchema = {
  validate: (value: any, _params: veeRuleParams): veeRuleReturnType => value && dayjs(value).isValid(),
  message: ATranslator.tc('validation_rules_date') || 'Invalid date',
};

export const date_before: ValidationRuleSchema = {
  params: ['dateToCheck', 'target'],
  validate: dateBeforeValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_date_before', 1, { item: params.dateToCheck }) || `The selected date should be before ${params.dateToCheck}`,
};

export const date_after: ValidationRuleSchema = {
  params: ['dateToCheck', 'target'],
  validate: dateAfterValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_date_after', 1, { item: params.dateToCheck }) || `The selected date should be after ${params.dateToCheck}`,
};

export const date_after_or_equal: ValidationRuleSchema = {
  params: ['dateToCheck', 'target'],
  validate: dateAfterOrEqualValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_date_after_or_equal', 1, { item: params.dateToCheck }) || `The selected date should be after or on ${params.dateToCheck}`,
};

export const date_before_or_equal: ValidationRuleSchema = {
  params: ['dateToCheck', 'target'],
  validate: dateBeforeOrEqualValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_date_before_or_equal', 1, { item: params.dateToCheck }) || `The selected date should be before or on ${params.dateToCheck}`,
};

export const date_between: ValidationRuleSchema = {
  params: ['startDate', 'endDate', 'target'],
  validate: dateBetweenValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_date_between', 1, { start: params.startDate, end: params.endDate }) || `The selected date should be between ${params.startDate} and ${params.endDate}`,
};

export const iban: ValidationRuleSchema = {
  validate: ibanValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_iban') || `This is not a valid IBAN`,
};

export const bic: ValidationRuleSchema = {
  validate: bicValidationRule,
  message: (field: string, params: Record<string, any>): string => ATranslator.tc('validation_rules_bic') || `This not a valid BIC`,
};
