import { Component, Vue, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { Url } from '@atabix/core';

@Component<ATableLink>({})
export default class ATableLink extends Vue {
  @Prop()
  protected value!: string;

  @Prop()
  protected to!: RawLocation;

  protected get isFullUrl(): boolean {
    return typeof this.to === 'string' && Url.isValid(this.to);
  }

  // Span and empty click handler to prevent propagation to datatable Row
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected handleClick(): void {}
}
