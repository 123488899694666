var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.crumb.name
    ? _c(
        "li",
        { staticClass: "breadcrumb--text breadcrumb--text__link" },
        [
          _vm.crumb.path
            ? _c("router-link", { attrs: { to: _vm.crumb.path } }, [
                _vm._v(_vm._s(_vm.crumb.name))
              ])
            : _vm._e(),
          !_vm.crumb.path
            ? _c("span", [_vm._v(_vm._s(_vm.crumb.name))])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }