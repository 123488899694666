import { Model } from '@/models/Model';
import { User } from '@/models/User';

export class CostSpecificationQuestion extends Model {
  protected $name = 'CostSpecificationQuestion';

  protected $endpoint = '/cost-specification-questions';

  protected $fillable: string[] = [
    'file',
  ];

  public id!: string;

  public question?: string;

  public answer?: string;

  public status!: Status;

  public created_at!: string;

  public answered_at?: string;

  public accepted_at?: string;

  public adjudicated_at?: string;

  public updated_at!: string;

  public answered_by?: User;

  public accepted_by?: User;

  public adjudicated_by?: User;

  public resolvePrimaryKey(): string {
    return this.id;
  }

  public get statusLabel() {
    return this.status ? statusLabels[this.status] : '';
  }

  public get statusBgClass() {
    return this.status ? statusBackgrounds[this.status] : '';
  }

  public get statusBorderClass() {
    return this.status ? statusBorders[this.status] : '';
  }
}

export const statusLabels: { [key: string]: string } = {
  unanswered: 'Onbeantwoord',
  answered: 'Beantwoord',
  rejected: 'Afgekeurd',
  accepted: 'Goedgekeurd',
};

export const statusBorders: { [key: string]: string } = {
  unanswered: 'tw-border-gray-200',
  answered: 'tw-border-primary-400',
  accepted: 'tw-border-success-500',
  rejected: 'tw-border-error-500',
};

export const statusBackgrounds: { [key: string]: string } = {
  unanswered: 'tw-bg-warning-500',
  answered: 'tw-bg-primary-400',
  accepted: 'tw-bg-success-500',
  rejected: 'tw-bg-error-500',
};

export type Status = 'answered' | 'unanswered' | 'rejected' | 'accepted';

export interface CreateCostSpecificationQuestionPayload {
  cost_specification_id: string,
  question: string,
}
