import { Model } from '@/models/Model';
import { Report } from '@/models/Report';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import { Address } from '@/models/Address';

export class Tools extends Model {
  protected $primaryKey = '';

  protected $name = 'Tools';

  protected $endpoint = '/tools';

  protected $fillable: string[] = [];

  public report?: Report | string;

  public organization?: Organization | string;

  public expert?: User | string;

  public mediator?: User | string;

  public starts_at?: string;

  public ends_at?: string;

  public errors?: string[];

  public warnings?: string[];

  public duration?: PlanningDuration;

  public is_valid?: PlanningValidation;

  public questions?: QuestionValidation;

  public departure_address?: Address;

  public mediator_departure_address?: Address;

  public validatePlanning(payload: object) {
    const url = '/tools/planning/validate';
    return this.request('post', url, payload);
  }

  public get encodeDepartureAddress() {
    return encodeURI(`${this.departure_address?.street} ${this.departure_address?.number} ${this.departure_address?.number_add ? this.departure_address?.number_add : ''},  ${this.departure_address?.postcode} ${this.departure_address?.city}`);
  }

  public get encodeMediatorDepartureAddress() {
    return encodeURI(`${this.mediator_departure_address?.street} ${this.mediator_departure_address?.number} ${this.mediator_departure_address?.number_add ? this.mediator_departure_address?.number_add : ''},  ${this.mediator_departure_address?.postcode} ${this.mediator_departure_address?.city}`);
  }
}

export interface PlanningDuration {
  minutes: PlanningMinutes;
  hours: PlanningMinute;
}

export interface PlanningValidation {
  [key:string]: any;
  report: boolean,
  datetime: boolean,
  expert: boolean,
  mediator: boolean,
  questions: boolean
}

export interface PlanningDuration {
  [key:string]: any;
  minutes: PlanningMinutes;
  hours: PlanningMinute;
}

export interface QuestionValidation {
  [key:string]: any;
  complete: boolean;
  answered: number;
  unanswered: number;
  total: number;
}

interface PlanningMinutes {
  [key:string]: any;
  standaard: PlanningMinute;
  bouwlaag_standaard: PlanningMinute;
  bouwlaag_meer: PlanningMinute;
  count_schades: PlanningMinute;
  count_ebs: PlanningMinute;
  total: PlanningMinute;
}

export interface PlanningMinute {
  name: string;
  before: number;
  visit: number;
  after: number;
}
