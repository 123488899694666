var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questions
    ? _c(
        "div",
        [
          !_vm.readOnly
            ? _c(
                "v-flex",
                {
                  staticClass: "px-4",
                  attrs: { xs12: "", "text-xs-right": "" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "link",
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleEditMode()
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.toggleAllEditMode
                                            ? "unfold_less"
                                            : "unfold_more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3291945940
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.toggleAllEditMode
                              ? "Alle vragen invouwen"
                              : "Alle vragen uitvouwen"
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.questions, function(question, index) {
            return _c(
              "v-flex",
              { key: index, attrs: { xs12: "" } },
              [
                _c("Question", {
                  attrs: {
                    question: question,
                    answers: _vm.answers,
                    report: _vm.report,
                    damage: _vm.damage,
                    disabled: _vm.isQuestionDisabled(question),
                    enableReadMode: _vm.enableReadMode,
                    readOnly: _vm.readOnly,
                    toggleAllEditMode: _vm.toggleAllEditMode,
                    hiddenQuestionKeys: _vm.hiddenQuestionKeys
                  },
                  on: {
                    reportAnswerSaved: _vm.reportAnswerSaved,
                    damageAnswerSaved: _vm.damageAnswerSaved,
                    reloadAnswers: _vm.reloadAnswers,
                    onClickInfo: _vm.onClickInfo,
                    onClickMetaPgvLimit: _vm.onClickMetaPgvLimit,
                    reloadDamageAnswers: _vm.reloadDamageAnswers,
                    reloadReportAnswers: _vm.reloadReportAnswers
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }