var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.report.isClosed && _vm.allowEdit
        ? [
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "text-xs-right": "", "pa-2": "" } },
                  [
                    _vm.options.showAddButton
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: { color: "primary" },
                                            nativeOn: {
                                              click: function($event) {
                                                _vm.isCreatingAttachment = true
                                                _vm.selectedAttachment = null
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v(" add ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1055936504
                            )
                          },
                          [_c("span", [_vm._v("Bijlagen toevoegen")])]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.attachments && _vm.attachments.length
        ? _c("div", [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-flex-row tw-flex-no-wrap tw-font-semibold tw-uppercase tw-text-rem tw-pl-16 tw-pb-16"
                  },
                  [
                    _vm.options.showOrderButton
                      ? _c("div", { staticClass: "tw-w-48" })
                      : _vm._e(),
                    _vm._l(_vm.options.headers, function(header, index) {
                      var _obj
                      return [
                        (header.visible !== undefined
                        ? header.visible
                        : true)
                          ? _c(
                              "div",
                              {
                                key: "header-" + index + "-" + header.value,
                                staticClass: "tw-w-1/6",
                                class:
                                  ((_obj = {}),
                                  (_obj[
                                    "tw-w-1/" +
                                      _vm.options.headers.filter(function(h) {
                                        return (
                                          h.visible === undefined || h.visible
                                        )
                                      }).length
                                  ] = true),
                                  (_obj["" + header.class] = header.class),
                                  _obj)
                              },
                              [_vm._v(" " + _vm._s(header.text) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _c(
                  "DraggableContent",
                  {
                    attrs: {
                      "allow-edit-order": _vm.options.showOrderButton,
                      draggables: _vm.attachments
                    },
                    on: { handleDragEndEvent: _vm.onMediaDragEnd }
                  },
                  [
                    _c(
                      "template",
                      { slot: "content" },
                      _vm._l(_vm.attachments, function(
                        attachment,
                        attachmentIndex
                      ) {
                        return _c(
                          "div",
                          {
                            key:
                              "media-" + attachmentIndex + "-" + attachment.id,
                            staticClass:
                              "tw-flex tw-flex-row tw-flex-no-wrap tw-gap-8 tw-py-4 tw-items-center tw-text-gray-600 tw-group",
                            class: { "tw-bg-gray-100": !(attachmentIndex % 2) }
                          },
                          [
                            _vm.options.showOrderButton
                              ? _c(
                                  "div",
                                  { staticClass: "tw-w-48 tw-cursor-move" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "tw-text-transparent group-hover:tw-text-gray-500"
                                      },
                                      [_vm._v("drag_indicator")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(_vm.options.headers, function(
                              header,
                              index
                            ) {
                              var _obj
                              return [
                                (header.visible !== undefined
                                ? header.visible
                                : true)
                                  ? _c(
                                      "div",
                                      {
                                        key:
                                          "row-" + index + "-" + header.value,
                                        staticClass: "tw-w-1/6",
                                        class:
                                          ((_obj = {}),
                                          (_obj[
                                            "tw-w-1/" +
                                              _vm.options.headers.filter(
                                                function(h) {
                                                  return (
                                                    h.visible === undefined ||
                                                    h.visible
                                                  )
                                                }
                                              ).length
                                          ] = true),
                                          (_obj["" + header.class] =
                                            header.class),
                                          _obj)
                                      },
                                      [
                                        _vm.isScopedSlot(header.value)
                                          ? _vm._t(
                                              "item." + header.value,
                                              null,
                                              null,
                                              attachment
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(attachment[header.value])
                                              )
                                            ])
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("span", { staticClass: "fadedText" }, [
                      _vm._v("Er zijn geen bijlagen")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
      _vm.isCreatingAttachment
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isCreatingAttachment,
                callback: function($$v) {
                  _vm.isCreatingAttachment = $$v
                },
                expression: "isCreatingAttachment"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(
                      _vm._s(_vm.title) +
                        " " +
                        _vm._s(
                          _vm.selectedAttachment ? "aanpassen" : "toevoegen"
                        )
                    )
                  ]),
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "template",
                          { slot: "content" },
                          [
                            _vm.selectedAttachment
                              ? [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          label: "Beschrijving",
                                          "hide-details": true
                                        },
                                        model: {
                                          value:
                                            _vm.selectedAttachment.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedAttachment,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedAttachment.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isScopedSlot("show_in_report") &&
                                  !_vm.$store.state.isServiceOrganization &&
                                  _vm.isPdf
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Wilt u deze bijlage in het rapport zichtbaar maken?"
                                            )
                                          ]),
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: {
                                                row: "",
                                                "hide-details": ""
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedAttachment
                                                    .show_in_report,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedAttachment,
                                                    "show_in_report",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedAttachment.show_in_report"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-radio",
                                                {
                                                  attrs: {
                                                    value: "1",
                                                    color: "secondary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "label" },
                                                      slot: "label"
                                                    },
                                                    [_vm._v("Ja")]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-radio",
                                                {
                                                  attrs: {
                                                    value: "0",
                                                    color: "secondary"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "label" },
                                                      slot: "label"
                                                    },
                                                    [_vm._v("Nee")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            !_vm.selectedAttachment
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          "fill-height": "",
                                          "mb-3": "",
                                          "pa-0": "",
                                          wrap: ""
                                        }
                                      },
                                      [
                                        _c("FileUpload", {
                                          ref: "fileUpload",
                                          attrs: {
                                            multiple: true,
                                            height: 300
                                          },
                                          on: {
                                            submitKeys: _vm.submitMediaKeys
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        !_vm.hideActions
                          ? _c(
                              "template",
                              { slot: "actions" },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "justify-end": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { shrink: "" } },
                                      [
                                        _vm.selectedAttachment
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "fadedText link mr-3",
                                                  on: { click: _vm.close }
                                                },
                                                [_vm._v("Annuleren")]
                                              ),
                                              _c(
                                                "MiButton",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    small: "true"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.updateAttachment(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("actions.save")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }