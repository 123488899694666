import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { OpnameVariantEnum, OpnameVariantLabels } from '@/items/OpnameVariant';

@Component<OpnameVariantFilter>({})
export default class OpnameVariantFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected hasNoTags!: boolean;

  protected items: Item[] = Object.values(OpnameVariantEnum).map((variant: string) => ({ name: OpnameVariantLabels[`${variant}`], value: variant }));

  protected selectedItems = this.value ? this.value : [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedItems);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedItems = [];
    } else {
      this.selectedItems = cloneDeep(this.value);
    }
  }

  public async mounted() {

  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedItems', this.items.map((item: Item) => item.value || ''));
    } else {
      this.$set(this, 'selectedItems', []);
    }

    this.handleChange();
  }
}

interface Item {
  name: string;
  value: string;
}
