import { Damage } from '@/models/Damage';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component<RepairTable>({})
export default class RepairTable extends Vue {
  // #region @Props
  @Prop()
  protected damage!: Damage;
  // #endregion

  protected total = 0;

  protected mounted(): void {
    this.calculateDamageTotals();
  }

  // #region Class methods

  protected calculateDamageTotals() {
    if (! this.damage || ! this.damage.repairs) {
      return;
    }

    let total = 0;
    this.damage.repairs.forEach((repair) => {
      total += repair?.total_incl_vat || 0;
    });
    this.total = total;
    total = 0;
  }

  // #endregion

  @Watch('damage', { deep: true })
  public damageChanged(newDamage: Damage, oldDamage: Damage): void {
    this.calculateDamageTotals();
  }
}
