import { Model } from '@/models/Model';

export class UserPreference extends Model {
  protected $name = 'UserPreference';

  protected $endpoint = '/user-preferences';

  protected $fillable: string[] = [
    'key',
    'slug',
    'user_filters',
  ];

  public id?: string;

  public key?: string;

  public slug?: string;

  public data?: UserPreferenceData[];

  get uuid(): string {
    return this.id ? this.id : '';
  }
}

export enum UserPreferenceEnum {
  PROFILE = 'profile',
  REPORT_HEADERS__DEFAULT = 'report-headers-defeault',
}

export const userPreferenceProfileDefaults: UserPreferenceData = {
  'report-tags-skills': 'Tags & Competenties',
  'report-roles': 'Rollen',
  'report-editors': 'Editors',
  'report-deadline': 'Deadline & Verwachte Opleverdatum',
  'report-dates': 'Datum',
  'report-finance-info': 'Schadebedrag',
  'report-statistics-info': 'Aantal toegekende schades',
  'report-ftr-info': 'First time right',
};

export type UserPreferenceData = Record<string, unknown>;
