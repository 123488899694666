var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", {
    ref: "observer",
    staticClass: "atabix-stateful-form",
    attrs: { autocomplete: _vm.autocomplete ? "on" : "off", tag: "form" },
    on: {
      keypress: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        $event.preventDefault()
        return _vm.submit($event)
      },
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit($event)
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var valid = ref.valid
            return [
              _vm._t("default", null, null, {
                submit: _vm.submit,
                errors: _vm.errors,
                reset: _vm.reset,
                payload: _vm.payload,
                isValid: valid,
                isDirty: _vm.isDirty
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }