var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "QuestionConclusion",
        {
          attrs: {
            result: _vm.classificationResult,
            report: _vm.report,
            question: _vm.question,
            buttonDisabled: _vm.disabled,
            isDeviation: _vm.isDeviation
          },
          on: {
            answerQuestion: _vm.handleAnswerQuestion,
            actionButtonClicked: function($event) {
              return _vm.onOpenQuestionConclusionDialog()
            }
          }
        },
        [
          _vm.isDeviation
            ? [
                _c("template", { slot: "content_deviation-reason" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.overwriteConclusionReasonLabels[
                          _vm.answer.sensitive_building_anomaly_reason
                        ]
                      ) +
                      " "
                  )
                ]),
                _c("template", { slot: "content_deviation-explanation" }, [
                  _vm._v(
                    _vm._s(_vm.answer.sensitive_building_anomaly_explanation)
                  )
                ])
              ]
            : _vm._e(),
          _c("template", { slot: "content_title" }, [
            _vm._v(_vm._s(_vm.question.label))
          ]),
          _c("template", { slot: "content_description" }, [
            _vm._v(_vm._s(_vm.classificationDescription))
          ]),
          _c("template", { slot: "button_text" }, [_vm._v("Vraag beoordeling")])
        ],
        2
      ),
      _vm.isDisplayingQuestionConclusionDialog
        ? _c(
            "v-dialog",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.isDisplayingQuestionConclusionDialog,
                callback: function($$v) {
                  _vm.isDisplayingQuestionConclusionDialog = $$v
                },
                expression: "isDisplayingQuestionConclusionDialog"
              }
            },
            [
              _c(
                "QuestionConclusionDialog",
                {
                  attrs: {
                    title: "Gevoelig gebouw",
                    report: _vm.report,
                    question: _vm.question,
                    conclusion: _vm.conclusion,
                    primarySection: 80,
                    isLoadingQuestions: _vm.isLoadingQuestions,
                    overwriteConclusionDialogTitle:
                      _vm.overwriteConclusionDialogTitle,
                    overwriteConclusionDialogReasons:
                      _vm.overwriteConclusionDialogReasons
                  },
                  on: {
                    answerQuestion: _vm.handleAnswerQuestion,
                    useConclusion: _vm.onUseConclusion,
                    deviateFromConclusion: _vm.onDeviateFromConclusion,
                    reloadFreshData: _vm.reloadFreshData
                  },
                  model: {
                    value: _vm.isDisplayingQuestionConclusionDialog,
                    callback: function($$v) {
                      _vm.isDisplayingQuestionConclusionDialog = $$v
                    },
                    expression: "isDisplayingQuestionConclusionDialog"
                  }
                },
                [
                  _c("template", { slot: "error" }, [
                    _c("h2", [_vm._v("Er is een onbekende fout opgetreden.")])
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }