var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "repairValidations", attrs: { xs12: "" } },
            [
              _c(
                "div",
                {
                  staticClass: "customRepairIndicator",
                  class:
                    _vm.validation === "error"
                      ? "customRepairIndicator--error"
                      : ""
                },
                [_c("v-icon", { staticClass: "custom" }, [_vm._v("warning")])],
                1
              ),
              _c("span", [
                _vm._v(_vm._s("" + _vm.parseWarning(_vm.validationKey)))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }