export enum PermissionSlug {
  USERS_LIST = 'users.list',
  USERS_CREATE = 'users.create',
  USERS_READ = 'users.read',
  USERS_UPDATE = 'users.update',
  USERS_DELETE = 'users.delete',
  ROLES_LIST = 'roles.list',
  ROLES_CREATE = 'roles.create',
  ROLES_READ = 'roles.read',
  ROLES_UPDATE = 'roles.update',
  ROLES_DELETE = 'roles.delete',
  PERMISSIONS_LIST = 'permissions.list',
  EXPORTS_AANNEMERS_VARIANT = 'exports.aannemers_variant',
  EXPORTS_BI_UPDATE = 'exports.bi_update',
  EXPORTS_FACT_SHEET = 'exports.fact_sheet',
  EXPORTS_PGV = 'exports.pgv',
  EXPORTS_REPORTS = 'exports.reports',
  EXPORTS_PROFICIENCY_REPORT = 'exports.proficiency_report',
  EXPORTS_CONVERSIONMATRIX = 'exports.conversiematrix', // #FIXME: CHECK this
  MANAGEMENT_INFO_RESET = 'management_info.reset',
  APPLICANTS_MANAGEMENT = 'applicants.management',
  RECOGNIZE_DAMAGE = 'damage_recognition.show_button',
  IMAGE_MATCHING = 'damage_recognition.image_matching',

  // PROJECT
  PROJECTS_LIST = 'projects.list',
  PROJECTS_CREATE = 'projects.create',
  PROJECTS_READ = 'projects.read',
  PROJECTS_UPDATE = 'projects.update',
  PROJECTS_DELETE = 'projects.delete',
  MONITOR = 'monitor',
}
