import { isArray } from 'lodash';
import { filter } from '@/support/Utilities';
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { Token } from '@/support/Token';
import { trim } from '@/support/String';
import { Options } from '@/components/mi-dialog/MiDialog';
import store from '@/store';
import Storage from '@/support/Storage';
import { Application } from '@/support/Application';
import ErrorHandler from '@/support/ErrorHandler';

let API = '';
const Qs = require('qs');

let updateDialogOpen = false;

export default (method: string, url: string, payload: object | string[], isHttpSort = false, dmz: string | null = null, refactor: boolean | null = null, customheaders: {[key: string]: string | boolean} = {}) => {
  let headers: {[key: string]: string | boolean} = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'X-Requested-With': 'vue',
    ...(isHttpSort ? { 'X-HTTP-Method-Override': 'sort' } : {}),
  };

  headers = { ...headers, ...customheaders };

  if (dmz) {
    headers['X-DMZ'] = dmz;
  }

  if (refactor) {
    headers['X-refactor'] = refactor;
  }

  // the dmz endpoint is without /v1
  API = `${identifyHostname()}${url === '/rpc' || url === '/rpc?' || dmz ? '' : Application.config('APP_API_VERSION')}`;

  if (isLocal()) {
    (headers as any)['X-Application'] = identifyApplication();
  }

  if (Token.get('access')) {
    (headers as any).Authorization = `Bearer ${Token.get('access')}`;
  }

  const client = axios.create({
    baseURL: API,
    headers,
    paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'brackets' }),
  });

  let hasFile = false;
  const keys = Object.keys(payload);

  if (! payload) {
    payload = {};
  }

  keys.forEach((property, index) => {
    if ((payload as any)[property] !== undefined && (payload as any)[property] !== null && (payload as any)[property].name !== undefined && (payload as any)[property].lastModified !== undefined && (payload as any)[property].size !== undefined) {
      hasFile = true;
    }

    if (isArray((payload as any)[property])) {
      (payload as any)[property].forEach((prop: any) => {
        Object.keys(prop).forEach((key: any) => {
          if (prop[key] !== undefined && prop[key] !== null && prop[key].name !== undefined && prop[key].lastModified !== undefined && prop[key].size !== undefined) {
            hasFile = true;
          }
        });
      });
    }
  });

  if (hasFile) {
    payload = objectToFormdata(payload);
  }

  if (method.toLowerCase() === 'get' || method.toLowerCase() === 'delete') {
    payload = {
      params: payload,
    };
  }

  // if ('serviceWorker' in navigator) {
  //   if (method !== 'get') {
  //     const serviceWorkerContainer = navigator.serviceWorker as ServiceWorkerContainer;
  //     if (serviceWorkerContainer.hasOwnProperty('updateIsReady') && serviceWorkerContainer.updateIsReady) {
  //       if (!updateDialogOpen) {
  //         store.dispatch('openDialog', updateIsReadyDialogOptions);
  //         updateDialogOpen = true;
  //       }
  //     } else {
  //       console.log(' no update is ready');
  //     }
  //   }
  // }

  if (method !== 'get' && store.getters.updateIsReady) {
    if (! updateDialogOpen) {
      store.dispatch('openDialog', updateIsReadyDialogOptions);
      updateDialogOpen = true;
    } else {
      console.log(' no update is ready');
    }
  }

  return (client as any)
    [method](url, payload)
    .then((response: AxiosResponse) => {
      Storage.delete('maintenanceMode');
      Storage.set('maintenanceChecked', 1);
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      if (error.code === '503') {
        Storage.set('maintenanceMode', 1);
        Storage.set('maintenanceChecked', 1);
      }

      return Promise.reject(error);
    });
};

export const identifyApplication = (): string => {
  const host = window.location.host;
  const application = host.split('.')[0];

  if (application.includes('localhost:') || host.includes(':8080')) {
    if (Application.config('APP_TENANT')) {
      return Application.config('APP_TENANT') as string;
    }
    return '';
  }

  return application;
};

export const isLocal = (): boolean => {
  if (! Application.config('APP_API_LOCAL') || Application.config('APP_API_LOCAL') === 'false') {
    return false;
  }

  const port = window.location.port;

  return ! port || port != '80';
};

export const identifyHostname = (): string => {
  const hostName = trim('.', Application.config('APP_API') as string);
  const applicationName = identifyApplication();
  const hostProtocol = isHttps() ? 'https' : 'http';
  return `${hostProtocol}://${hostName}`;
};

export const isHttps = (): boolean => (! (Application.config('APP_API_HTTPS') && Application.config('APP_API_HTTPS') === 'false'));

export const identifyAppHostname = (): string => {
  const hostName = trim('.', Application.config('APP_HOSTNAME') as string);
  return `${hostName}`;
};

export const objectToFormdata = (payload: any, form: any = null, namespace: any = null): FormData => {
  const fd: FormData = form || new FormData();
  let formKey: string | null = null;

  Object.keys(payload).forEach((key: string) => {
    if (namespace) {
      formKey = `${namespace}[${key}]`;
    } else {
      formKey = key;
    }

    if (typeof payload[key] === 'object' && ! (payload[key] instanceof File)) {
      objectToFormdata(payload[key], fd, key);
    } else {
      fd.append(formKey, payload[key]);
    }
  });

  return fd;
};

const updateIsReadyDialogOptions: Options = {
  title: 'Er is een nieuwe versie beschikbaar',
  text: 'Sla uw werk op en druk op "ctrl+F5"  (windows) of "cmd + Shift + R" (apple).',
  type: 'warning',
  buttons: {
    confirm: {
      text: 'OK',
      color: 'success',
      action: () => {
        store.dispatch('closeDialog');
        updateDialogOpen = false;
      },
    },
  },
};

export const downloadExcel = async (options: ExcelOptions, payload?: object) => {
  const API = `${identifyHostname()}`;

  const headers: {[key: string]: string | null} = {
    'Content-type': 'application/json',
  };

  headers.Accept = options.accept || 'application/vnd.ms-excel',
  headers['X-DMZ'] = options.dmz || null,

  // removes propertys with value === null
  filter(headers);

  if (Token.get('access')) {
    (headers as any).Authorization = `Bearer ${Token.get('access')}`;
  }

  const client = axios.create({
    baseURL: API,
    headers,
    responseType: 'blob',
  });

  await client
    .get(options.url, payload ? { params: payload } : {})
    .then((response: AxiosResponse) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const disposition = response.request.getResponseHeader('Content-Disposition');
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      let name = '';
      if (matches != null && matches[1]) {
        name = matches[1].replace(/['"]/g, '');
      }
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    })
    .catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });
};

export const downloadExcelRequest = async (options: ExcelOptions, payload?: object) => {
  const API = `${identifyHostname()}`;

  const headers: {[key: string]: string | null} = {
    'Content-type': 'application/json',
  };

  headers['X-DMZ'] = options.dmz || null,

  // removes propertys with value === null
  filter(headers);

  if (Token.get('access')) {
    (headers as any).Authorization = `Bearer ${Token.get('access')}`;
  }

  const client = axios.create({
    baseURL: API,
    headers,
  });

  return client
    .get(options.url, payload ? { params: payload } : {});
};

export interface ExcelOptions {
  dmz?: string;
  accept?: string;
  url: string;
}
