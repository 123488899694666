import { Model } from '@/models/Model';

export class Answer extends Model {
  protected $name = 'Answer';

  protected $endpoint = '/answers';

  protected $fillable: string[] = ['value', 'question', 'report'];

  public uuid?: string;

  public key?: string;

  public type?: string;

  public value?: string;

  public question_id?: string;

  public question?: string;

  public report?: number;

  public instruction?: string;

  public is_editable?: boolean;

  public sensitive_building_has_deviated?: boolean;

  public sensitive_building_anomaly_reason?: string;

  public sensitive_building_anomaly_explanation?: string;

  public resolvePrimaryKey(): string {
    return '';
  }

  public answersBulk() {
    this.$endpoint = '/answers/bulk';
    return this;
  }
}
