import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<FullscreenDialogLayout>({})
export default class FullscreenDialogLayout extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  public close() {
    this.$emit('close');
  }
  // #endregion

  // #region Lifecycle Hooks / Init
  // #endregion

  // #region Class methods
  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}
