import { Model } from '@/models/Model';

export class ExperienceSubmissionItem extends Model {
  protected $name = 'ExperienceSubmissionItem';

  protected $endpoint = '/experience-submission-items';

  protected $fillable: string[] = [
    'title',
    'description',
    'completed',
    'starts_at',
    'ends_at',
    'type',
    'user',
    'is_verified',
  ];

  public id?: string;

  public submission?: string;

  public type?: string;

  public completed?: boolean | number;

  public title?: string;

  public experience?: string;

  public description?: string;

  public starts_at?: string;

  public declaration_of_education?: any;

  public ends_at?: string;

  public user?: string;

  public verified_by?: string;

  public verified_at?: string;

  public is_verified?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
