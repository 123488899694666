import { cloneDeep } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { statusToReportHeaders, statusToReportSort } from '@/support/ReportHeaders';
import { getStatusLabel } from '@/support/ReportStatus';
import { UserPreference } from '@/models/UserPreference';

@Component<ReportDatatable>({})
export default class ReportDatatable extends Vue {
  @Prop({ default: '' })
  protected expertPlanningInclude!: string;

  @Prop({ default: () => {} })
  protected isJzFilter!: object;

  @Prop({ default: true })
  protected autoRefetch!: boolean;

  @Prop({ default: '' })
  protected queryStatus!: string;

  @Prop({ default: '' })
  protected queryHeaders!: string;

  @Prop({ default: '' })
  protected tableSearchInput!: string;

  @Prop({ default: () => {} })
  protected dataTableFilters!: object;

  @Prop({ default: () => [] })
  protected dataTableActions!: any[];

  @Prop()
  protected sort!: string[];

  @Prop({ default: 25 })
  protected limit!: number;

  @Prop()
  private reportModel!: Report;

  @Prop({ default: null })
  protected userPreferences!: null | UserPreference[];

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected get tableSort() {
    if (this.sort) {
      return this.sort[0], this.sort[1];
    }

    return '';
  }

  protected get tableOptions(): Object {
    return {
      model: this.model,
      name: {
        singular: 'Rapport',
        plural: ! this.queryStatus || this.queryStatus === '' ? this.queryStatus : getStatusLabel((this.queryStatus as string)) !== 'Onbekend' ? getStatusLabel((this.queryStatus as string)) : 'Item',
      },
      sort: statusToReportSort(this.queryStatus as string, this.$store.state.Auth, this.queryHeaders),
      headers: statusToReportHeaders(this.queryStatus as string, this.$store.state.isServiceOrganization, this.$store.state.Auth, this.userPreferences, this.queryHeaders),
      filter: [],
      actions: this.dataTableActions,
    };
  }

  public refresh() {
    if (this.$refs.reportDatatable) {
      (this.$refs.reportDatatable as ReportDatatable).refresh();
    }
  }

  public get searchInputValue(): string {
    if (this.$refs.reportDatatable) {
      return (this.$refs.reportDatatable as DataTable).searchInputValue;
    }

    return '';
  }

  protected onMouseEnterTableRow(item: any) {
    this.$emit('onMouseEnterTableRow', item);
  }

  protected get model(): Report | null {
    if (! this.reportModel) {
      return new Report()
        .filter(this.dataTableFilters)
        .filter(this.isJzFilter)
        .sort(this.tableSort);
    }

    const model = cloneDeep(this.reportModel);
    return model.include(['applicant', 'skills', 'organization', 'last_status_change_log', 'applicant_submissions', this.expertPlanningInclude])
      .filter(this.dataTableFilters)
      .filter(this.isJzFilter)
      .sort(this.tableSort);
  }
}
