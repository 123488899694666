var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loader-cardContainer",
      style: { "min-height": _vm.minHeight }
    },
    [
      _vm.type === "dots"
        ? _c(
            "v-card",
            { staticClass: "loader-card--card", attrs: { flat: _vm.flat } },
            [
              _c("v-card-text", [
                _c("div", { staticClass: "dots", class: _vm.extraClass }, [
                  _c("div", { staticClass: "bounce1" }),
                  _c("div", { staticClass: "bounce2" }),
                  _c("div", { staticClass: "bounce3" })
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.type === "dots--small"
        ? _c(
            "div",
            { staticClass: "dots dots--small", class: _vm.extraClass },
            [
              _c("div", { staticClass: "bounce1" }),
              _c("div", { staticClass: "bounce2" }),
              _c("div", { staticClass: "bounce3" })
            ]
          )
        : _vm._e(),
      _vm.type === "spinner"
        ? _c(
            "div",
            { staticClass: "loader-card--card", attrs: { flat: _vm.flat } },
            [_c("div", { staticClass: "spinner" })]
          )
        : _vm._e(),
      _vm.type === "spinner--center"
        ? _c(
            "div",
            {
              staticClass: "loader-card--card text-xs-center",
              attrs: { flat: _vm.flat }
            },
            [_c("div", { staticClass: "spinner" })]
          )
        : _vm._e(),
      _vm.type === "spinner--medium"
        ? _c(
            "div",
            { staticClass: "loader-card--card", attrs: { flat: _vm.flat } },
            [_c("div", { staticClass: "spinner spinner--medium" })]
          )
        : _vm._e(),
      _vm.type === "spinner--small"
        ? _c(
            "div",
            { staticClass: "loader-card--card", attrs: { flat: _vm.flat } },
            [_vm._m(0)]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner spinner--small" }, [
      _c("div", { staticClass: "checkmark draw" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }