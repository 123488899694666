import { DamageRecognition, Mask } from '@/models/DamageRecognition';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { formatDate } from '@/support/String';
import { Media } from '@/models/Media';
import TuiImageEditor from '@/components/image-editor/image-editor.vue';
import ErrorHandler from '@/support/ErrorHandler';
import { Token } from '@/support/Token';
import { identifyHostname } from '@/support/Client';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Polygon, Point } from '@/support/Polygon';
import { Damage } from '@/models/Damage';
import { Contour } from '@/support/Contour';
import { Report } from '@/models/Report';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<EditImageDialog>({
  components: {
    TuiImageEditor,
  },
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
  },
})
export default class EditImageDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop({ default: '' })
  protected imageUrl!: string;

  @Prop()
  protected selectedMedia!: Media;

  @Prop()
  protected currentDamage!: Damage;

  @Prop({ default: false })
  protected canAddDescription!: boolean;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */

  @Ref()
  protected canvas!: HTMLCanvasElement;

  @Ref()
  protected maskCanvas!: HTMLCanvasElement;

  @Ref()
  protected resultCanvas!: HTMLCanvasElement;

  @Ref()
  protected combineCanvas!: HTMLCanvasElement;

  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public oAuthServer: string = identifyHostname() || '';

  protected isLoadingAutoBlur = false;

  protected isLoading = false;

  protected isLoadingEditor = true;

  protected isEditingImage = false;

  protected isCropping = false;

  protected showErrorMessage = false;

  protected isActiveRecognition = false;

  protected isUsingLineTool = false;

  protected isInitialLineClick = true;

  protected isUsingMaskBrush = false;

  protected isErasingMaskBrush = false;

  protected hasAttachedPolygons = false;

  protected undoChanged = 0;

  protected combineImagesLoaded = 0;

  protected editorHeight = '';

  protected currentEditorProp: any = {};

  protected imageEditorOptions: any = {};

  protected isActiveImageEditor: object = {
    circle: false,
    rect: false,
    arrow: false,
    line: false,
    text: false,
    blur: false,
  };

  protected context: CanvasRenderingContext2D | null = null;

  protected maskContext: CanvasRenderingContext2D | null = null;

  protected resultContext: CanvasRenderingContext2D | null = null;

  protected combineContext: CanvasRenderingContext2D | null = null;

  protected maskToolsRadius = 30;

  protected maskContrast = 0.2;

  protected damageRecognition: DamageRecognition | null = null;

  protected polygons: Polygon[] = [];

  protected selectedPolygons: Polygon[] = [];

  protected isApiPolygon = false;

  protected isBrushShape = false;

  protected position: Point = { x: 0, y: 0 };

  protected initialPosition: Point = { x: 0, y: 0 };

  protected secondPosition: Point = { x: 0, y: 0 };

  // protected positions: Point[] = [];

  protected damagePointCollection: Point[][] = [];

  protected mask: Mask | null = null;

  protected fileDescription = '';
  // #endregion

  // #region Lifecycle Hooks / Init
  public async mounted() {
    await this.fetchMediaDamageRecognition();
    await this.editMedia();
  }
  // #endregion

  // #region Class methods
  protected closeEditingImage() {
    this.isEditingImage = false;
    this.isActiveImageEditor = {
      circle: false,
      rect: false,
      arrow: false,
      line: false,
      text: false,
      blur: false,
    };

    this.$emit('closeDialog', '');
  }

  protected async handleSavePolygon() {
    // Initialize the combineCanvas to combine the images onto
    this.combineContext = this.combineCanvas.getContext('2d');
    this.combineCanvas.width = this.canvasSize.width || this.canvas.width;
    this.combineCanvas.height = this.canvasSize.height || this.canvas.height;

    if (this.combineContext) {
      const originalDataURL = await this.imageEditorOptions.container.invoke('toDataURL');

      const originalImage = new Image();
      originalImage.onload = async () => {
        this.combineContext?.drawImage(originalImage, 0, 0);

        if (this.isApiPolygon) {
          this.selectedPolygons?.forEach((selectedPolygon: Polygon) => {
            const polygon = new Polygon(selectedPolygon);
            polygon.draw(this.combineContext, 'red');
          });
        }

        if (this.isBrushShape) {
          if (! this.resultContext || ! this.resultCanvas) {
            return null;
          }

          const test = new Contour(this.resultCanvas, this.resultContext);
          const points = test.points();
          const polygon = new Polygon({ points });
          polygon.draw(this.combineContext, 'red');
        }

        const imageName = this.imageEditorOptions.container.invoke('getImageName');
        const dataURL = this.combineCanvas.toDataURL('image/jpeg');

        const file = this.dataURLtoFile(dataURL, imageName);
        const media = await this.createMedia(file);

        await this.savePolygons(media);

        this.closeEditingImage();
        this.$emit('mediaCreated');
      };
      originalImage.src = originalDataURL;
    }
  }

  protected async savePolygons(media: Media | null) {
    if (! media) {
      return;
    }

    let currentPolygonIds: string[] = [];
    if (this.isApiPolygon) {
      await this.attachPolygonToMedia(media.id);
      currentPolygonIds = this.selectedPolygons.map((polygon) => polygon.id);
    }

    if (this.isBrushShape) {
      const polygon: Polygon | null = await this.createManualPolygon(media.id) || null;
      if (polygon) {
        currentPolygonIds = [polygon.id];
      }
    }

    const damagePolygons: Polygon[] = this.damageRecognition?.polygons || [];
    const damagePolygonIds: string[] = [
      ...damagePolygons?.filter((polygon) => !! polygon.media).map((polygon) => polygon.id) || [],
      ...currentPolygonIds,
    ];

    await this.createMask(damagePolygonIds);
    this.drawMaskFromPointCollection();
    const imageDataURL = this.resultContext?.canvas.toDataURL('image/jpeg');
    this.updateMask(imageDataURL);
  }

  public async submit(): Promise<void> {
    this.isLoadingEditor = true;

    try {
      if (! this.hasAttachedPolygons && (this.selectedPolygons.length || this.isBrushShape)) {
        await this.handleSavePolygon();
        return;
      }

      await this.createMedia();
      this.closeEditingImage();
      this.$emit('mediaCreated');
    } catch (error) {
      ErrorHandler.network(error);
    }

    this.isLoadingEditor = false;
  }

  public async createMedia(file?: File): Promise<Media | null> {
    const imageName = this.imageEditorOptions.container.invoke('getImageName');
    const dataURL = this.imageEditorOptions.container.invoke('toDataURL');
    const newFile = file || this.dataURLtoFile(dataURL, imageName);
    const payload = this.currentDamage
      ? {
        file: newFile,
        original_media: this.selectedMedia.uuid || '',
        description: this.fileDescription,
      }
      : {
        file: newFile,
        type: 'picture',
        description: this.fileDescription,
      };

    try {
      const media = this.currentDamage ? await this.createDamageMedia(payload) : await this.createReportMedia(payload);
      return media;
    } catch (error) {
      ErrorHandler.network(error);
      return null;
    }
  }

  public async createDamageMedia(payload: Record<string, any>): Promise<Media> {
    return await new Damage()
      .mediaEndpoint(this.currentDamage)
      .create(payload) as Media;
  }

  public async createReportMedia(payload: Record<string, any>): Promise<Media> {
    return await new Report()
      .mediaEndpoint(this.$route.params.id)
      .create(payload) as Media;
  }

  protected async initializeCanvases() {
    if (! this.canvas) {
      return;
    }

    this.canvas.width = this.canvasSize.width || this.canvas.width;
    this.canvas.height = this.canvasSize.height || this.canvas.height;
    this.context = this.canvas.getContext('2d');

    this.maskCanvas.width = this.canvasSize.width || this.canvas.width;
    this.maskCanvas.height = this.canvasSize.height || this.canvas.height;
    this.maskContext = this.maskCanvas.getContext('2d');

    if (this.maskContext) {
      this.maskContext.fillStyle = 'black';
      this.maskContext.fillRect(0, 0, this.maskCanvas.width, this.maskCanvas.height);
      this.maskContext.globalCompositeOperation = 'destination-out';
    }

    this.resultContext = this.resultCanvas.getContext('2d');
    this.resultCanvas.width = this.canvasSize.width || this.canvas.width;
    this.resultCanvas.height = this.canvasSize.height || this.canvas.height;
    if (this.resultContext) {
      this.resultContext.fillStyle = 'black';
      this.resultContext.fillRect(0, 0, this.resultCanvas.width, this.resultCanvas.height);
    }
  }

  protected dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length; const
      u8arr = new Uint8Array(n);

    while (n --) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  protected editMedia() {
    this.isEditingImage = true;

    this.$nextTick(async () => {
      this.imageEditorOptions = {
        container: (this.$refs.imageEditor as any),
        color: 'rgba(255, 0, 0)',
      };
      if (! this.selectedMedia) return;
      const imgUrl = `${this.selectedMedia.original}`;
      this.isLoadingEditor = true;

      await this.imageEditorOptions.container.invoke('loadImageFromURL', `${imgUrl}`, `${this.selectedMedia.file_name}`)
        .then(async () => {
          this.imageEditorOptions.container.invoke('clearUndoStack');
          await this.initializeCanvases();
          this.isLoadingEditor = false;
        }).catch(async () => {
          this.showErrorMessage = true;
          await this.initializeCanvases();
          this.isLoadingEditor = false;
        });

      this.resizeEditor();

      this.imageEditorOptions.container.invoke('on', 'undoStackChanged', (length: number) => {
        this.undoChanged = length;
      });
    });
  }

  protected resizeEditor() {
    setTimeout(() => {
      this.editorHeight = '100%';
      this.createRecognitionPolygons();
    }, 200);
  }

  protected setActiveEditor(type: string) {
    this.isActiveImageEditor = {
      circle: false,
      rect: false,
      arrow: false,
      line: false,
      text: false,
      blur: false,
    };

    (this.isActiveImageEditor as any)[`${type}`] = true;
  }

  // editor tool functions
  protected draw(mode: string) {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.imageEditorOptions.container.invoke('startDrawingMode', mode, {
      width: 20,
      color: this.imageEditorOptions.color,
    });
  }

  protected undo() {
    this.imageEditorOptions.container.invoke('undo');
  }

  protected enableCrop() {
    this.imageEditorOptions.container.invoke('startDrawingMode', 'CROPPER');
    this.isCropping = true;
  }

  protected cancelCrop() {
    this.imageEditorOptions.container.invoke('stopDrawingMode', 'CROPPER');
    this.isCropping = false;
  }

  protected crop() {
    this.imageEditorOptions.container.invoke('crop', this.imageEditorOptions.container.invoke('getCropzoneRect'));
    this.isCropping = false;
  }

  protected addShape(shape: string) {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.imageEditorOptions.container.invoke('startDrawingMode', 'SHAPE');
    this.imageEditorOptions.container.invoke('setDrawingShape', shape, {
      stroke: this.imageEditorOptions.color,
      fill: 'transparent',
      strokeWidth: 20,
      x: 10,
      y: 100,
      isRegular: true,
    });
  }

  protected addFilledBlock() {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.imageEditorOptions.container.invoke('startDrawingMode', 'SHAPE');
    this.imageEditorOptions.container.invoke('setDrawingShape', 'rect', {
      fill: this.imageEditorOptions.color,
      x: 10,
      y: 100,
    });
  }

  protected addIcon() {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.setActiveEditor('arrow');
    this.imageEditorOptions.container.invoke('addIcon', 'arrow', {
      fill: this.imageEditorOptions.color,
      top: 200,
    });
  }

  protected addText() {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.imageEditorOptions.container.invoke('startDrawingMode', 'TEXT');
    this.imageEditorOptions.container.invoke('addText', 'Double Click', {
      styles: {
        fill: this.imageEditorOptions.color,
        fontSize: 150,
      },
    });
  }

  protected rotate() {
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    this.imageEditorOptions.container.invoke('rotate', 90);
  }

  protected detectionErrorMessage() {
    return ErrorHandler.alert('Er is niks herkend in de afbeelding om te verbergen.', 'Niks herkend');
  }

  protected recognitionErrorMessage() {
    return ErrorHandler.alert('Er zijn geen schades herkend in de afbeelding.', 'Niks herkend');
  }

  protected async createRecognitionPolygons() {
    this.$nextTick(() => {
      if (this.hasAttachedPolygons) {
        this.damageRecognition?.polygons?.forEach(async (indexedPolygon: Polygon) => {
          const polygon = new Polygon(indexedPolygon);

          if (polygon.media?.uuid === this.selectedMedia.uuid) {
            polygon.draw(this.context);

            if (polygon.type === 'manual_drawing') {
              polygon.drawFromMaskImage(this.maskContext, 'mask');
              polygon.drawFromMaskImage(this.resultContext, 'result');
            }
          }
        });
      } else {
        this.damageRecognition?.polygons?.forEach(async (indexedPolygon: Polygon) => {
          const polygon = new Polygon(indexedPolygon);
          if (polygon.type === 'api') {
            polygon.draw(this.context);
          }
        });
      }
    });
  }

  protected drawMaskFromPointCollection(): void {
    if (this.resultContext) {
      this.resultContext.fillStyle = 'black';
      this.resultContext.fillRect(0, 0, this.resultCanvas.width, this.resultCanvas.height);

      this.damagePointCollection.forEach((points) => {
        const polygon = new Polygon({ points });
        polygon.fill(this.resultContext, 'white');
      });
    }
  }

  protected async createMask(polygons: string[]): Promise<void> {
    if (! this.resultContext || ! this.damageRecognition) {
      return;
    }

    try {
      this.mask = await new DamageRecognition(this.damageRecognition)
        .createMask({ polygons });

      this.damagePointCollection = this.mask?.points as Point[][];
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async updateMask(image?: string): Promise<void> {
    if (! this.mask || ! this.damageRecognition) {
      return;
    }

    try {
      await new DamageRecognition(this.damageRecognition)
        .updateMask(this.mask.id, { mask_image: image });
      this.damagePointCollection = this.mask?.points as Point[][];
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected drawLine(event: MouseEvent) {
    if (event.buttons !== 0) return;
    if (! this.maskContext || ! this.resultContext) {
      return;
    }

    if (this.isInitialLineClick) {
      this.setInitialPosition(event);
      this.maskContext.beginPath();
      this.resultContext.beginPath();
      this.maskContext.moveTo(this.initialPosition.x, this.initialPosition.y);
      this.resultContext.moveTo(this.initialPosition.x, this.initialPosition.y);
      this.maskContext.arc(this.initialPosition.x, this.initialPosition.y, this.maskToolsRadius, 0, 2 * Math.PI);
      this.resultContext.arc(this.initialPosition.x, this.initialPosition.y, this.maskToolsRadius, 0, 2 * Math.PI);
      this.maskContext.fill();
      this.resultContext.fill();
      this.maskContext.closePath();
      this.resultContext.closePath();
      this.isInitialLineClick = false;
      this.isApiPolygon = false;
      this.isBrushShape = true;
      return;
    }

    this.setSecondPosition(event);
    this.maskContext.beginPath();
    this.resultContext.beginPath();
    this.maskContext.lineWidth = this.maskToolsRadius * 2;
    this.resultContext.lineWidth = this.maskToolsRadius * 2;
    this.maskContext.lineCap = 'round';
    this.resultContext.lineCap = 'round';
    this.maskContext.moveTo(this.initialPosition.x, this.initialPosition.y);
    this.resultContext.moveTo(this.initialPosition.x, this.initialPosition.y);
    this.maskContext.lineTo(this.secondPosition.x, this.secondPosition.y);
    this.resultContext.lineTo(this.secondPosition.x, this.secondPosition.y);
    this.maskContext.stroke();
    this.resultContext.stroke();
    this.maskContext.closePath();
    this.resultContext.closePath();
    this.initialPosition = { x: 0, y: 0 };
    this.secondPosition = { x: 0, y: 0 };

    this.isInitialLineClick = true;
    this.isApiPolygon = false;
    this.isBrushShape = true;
    // debugger;
  }

  protected createBrushShape(event: MouseEvent) {
    if (event.buttons !== 1) return;
    if (! this.maskContext || ! this.resultContext) {
      return;
    }

    this.maskContext.beginPath();
    this.resultContext.beginPath();

    this.maskContext.moveTo(this.position.x, this.position.y);
    this.maskContext.lineTo(this.position.x, this.position.y);
    this.maskContext.arc(this.position.x, this.position.y, this.maskToolsRadius, 0, 2 * Math.PI);

    this.resultContext.moveTo(this.position.x, this.position.y);
    this.resultContext.lineTo(this.position.x, this.position.y);
    this.resultContext.arc(this.position.x, this.position.y, this.maskToolsRadius, 0, 2 * Math.PI);

    this.setPosition(event);
    this.maskContext.fill();
    this.maskContext.closePath();
    this.resultContext.fill();
    this.resultContext.closePath();
    this.isApiPolygon = false;
    this.isBrushShape = true;
  }

  protected getMousePositionPoint(event: MouseEvent): Point | null {
    if (! event?.target || ! this.damageRecognition?.polygons) { return null; }
    const target = event.target as HTMLElement;

    if (! this.canvasSize?.width || ! this.canvasSize?.height) {
      return null;
    }

    const diffWidth = (this.canvasSize.width / target.offsetWidth) * 100;
    const diffHeight = (this.canvasSize.height / target.offsetHeight) * 100;

    const eventX = event.offsetX / 100 * diffWidth;
    const eventY = event.offsetY / 100 * diffHeight;
    return {
      x: eventX,
      y: eventY,
    };
  }

  protected onClickCanvas(event: MouseEvent) {
    if (this.hasAttachedPolygons) {
      return;
    }
    const mousePoint = this.getMousePositionPoint(event);
    if (! mousePoint) { return; }

    this.polygons.forEach((polygon) => {
      if (polygon.IsPointInPolygon(mousePoint)) {
        this.toggleSelectedPolygon(polygon);
      }

      polygon.draw(this.context);
    });
  }

  protected setPosition(event: MouseEvent) {
    const mousePoint = this.getMousePositionPoint(event);
    if (! mousePoint) { return; }
    this.position = mousePoint;
    // this.positions.push(mousePoint);
  }

  protected setInitialPosition(event: MouseEvent) {
    const mousePoint = this.getMousePositionPoint(event);
    if (! mousePoint) { return; }
    this.initialPosition = mousePoint;
  }

  protected setSecondPosition(event: MouseEvent) {
    const mousePoint = this.getMousePositionPoint(event);
    if (! mousePoint) { return; }
    this.secondPosition = mousePoint;
  }

  protected onMouseMoveCanvas(event: MouseEvent) {
    if (this.hasAttachedPolygons) {
      return;
    }
    const mousePoint = this.getMousePositionPoint(event);
    if (! mousePoint) { return; }
    document.body.style.cursor = 'auto';

    this.polygons.forEach((polygon) => {
      if (polygon.IsPointInPolygon(mousePoint)) {
        document.body.style.cursor = 'pointer';
      }
    });
  }

  protected onMouseMoveCanvasMask(event: MouseEvent) {
    if (this.isUsingMaskBrush || this.isErasingMaskBrush) {
      this.createBrushShape(event);
    }
  }

  protected onMouseDownCanvasMask(event: MouseEvent) {
    if (! this.isUsingLineTool) {
      this.setPosition(event);
    }
  }

  protected onMouseUpCanvasMask(event: MouseEvent) {
    if (this.isUsingLineTool) {
      this.drawLine(event);
      return;
    }

    this.setPosition(event);
    if (this.isUsingMaskBrush || this.isErasingMaskBrush) {
      this.createBrushShape(event);
    }
  }

  protected useSelectTool() {
    this.isUsingMaskBrush = false;
    this.isUsingLineTool = false;
    this.isErasingMaskBrush = false;
  }

  protected clearSelectedPolygons() {
    this.polygons.forEach((polygon) => {
      polygon.isSelected = false;
    });

    this.selectedPolygons = [];

    this.createRecognitionPolygons();
  }

  protected useEraser() {
    if (! this.maskContext || ! this.resultContext) {
      return;
    }
    this.maskContext.globalCompositeOperation = 'multiply';
    this.resultContext.fillStyle = '#000';
    this.isUsingMaskBrush = false;
    this.isUsingLineTool = false;
    this.isErasingMaskBrush = true;
  }

  protected useBrush() {
    if (! this.maskContext || ! this.resultContext) {
      return;
    }
    this.maskContext.globalCompositeOperation = 'destination-out';
    this.resultContext.fillStyle = '#fff';
    this.isErasingMaskBrush = false;
    this.isUsingLineTool = false;
    this.isUsingMaskBrush = true;
  }

  protected useLineTool() {
    if (! this.maskContext || ! this.resultContext) {
      return;
    }
    this.maskContext.globalCompositeOperation = 'destination-out';
    this.resultContext.fillStyle = '#fff';
    this.resultContext.strokeStyle = '#fff';
    this.isErasingMaskBrush = false;
    this.isUsingMaskBrush = false;
    this.isUsingLineTool = true;
  }

  protected toggleSelectedPolygon(polygon: Polygon) {
    if (polygon.hasMedia) {
      return;
    }

    if (polygon.isSelected) {
      const index = this.selectedPolygons.findIndex((activePolygon) => activePolygon.id === polygon.id);
      this.selectedPolygons.splice(index, 1);
      // this.createMaskFromPolygons(this.selectedPolygons);
    } else {
      this.selectedPolygons.push(polygon);
      // this.createMaskFromPolygons(this.selectedPolygons);
    }

    polygon.isSelected = ! polygon.isSelected;
    this.isApiPolygon = true;
    this.isBrushShape = false;
  }

  protected async onClickAutoBlur() {
    const detectionDetails: DetectionDetail = await this.fetchDetectionDetails();

    if (! detectionDetails.grouped_boxes?.length) {
      this.detectionErrorMessage();
      return;
    }

    const canvasSize = this.imageEditorOptions.container.invoke('getCanvasSize');
    this.imageEditorOptions.container.invoke('stopDrawingMode');
    const imageWidth = canvasSize.width;
    const imageHeight = canvasSize.height;

    detectionDetails.grouped_boxes.forEach((groupedBox) => {
      setTimeout(() => {
        this.createBlurShape(groupedBox.Left * imageWidth, groupedBox.Top * imageHeight, groupedBox.Width * imageWidth, groupedBox.Height * imageHeight);
      }, 1);
    });
  }

  protected onClickRecognizeDamages() {
    this.isActiveRecognition = ! this.isActiveRecognition;

    if (this.isActiveRecognition) {
      this.recognizeDamage();
    } else {
      this.resetRecognizeDamage();
    }
  }

  protected async recognizeDamage() {
    this.isLoadingAutoBlur = true;

    const damageRecognition: DamageRecognition = this.selectedMedia.damage_recognition?.id
      ? this.selectedMedia.damage_recognition // Use the same recognition we already have
      : await this.fetchRecognitionDetails(); // Only the first time we call the image recognition server

    if (damageRecognition) {
      this.damageRecognition = damageRecognition;

      if (this.damageRecognition && this.damageRecognition.polygons) {
        this.damageRecognition.polygons.forEach((polygon) => {
          if (polygon.type === 'api') {
            polygon = new Polygon(polygon);
            this.polygons.push(polygon);
          }
        });

        this.createRecognitionPolygons();
        this.isLoadingAutoBlur = false;
      }
    }
  }

  protected resetRecognizeDamage() {
    this.selectedPolygons = [];
    this.polygons.forEach((polygon) => {
      polygon.isSelected = false;
    });

    this.isActiveRecognition = false;
  }

  protected createBlurShape(left: number, top: number, width: number, height: number) {
    this.imageEditorOptions.container.invoke('addShape', 'rect', {
      fill: this.imageEditorOptions.color,
      top,
      left,
      width,
      height,
      originX: 'left',
      originY: 'top',
    });
    this.imageEditorOptions.container.invoke('deactivateAll');
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchMediaDamageRecognition() {
    const media: Media = await new Media()
      .include(['damage_recognition', 'polygons', 'media'])
      .find(this.selectedMedia.uuid);

    if (media?.damage_recognition?.id) {
      this.selectedMedia.damage_recognition = media.damage_recognition;
    }

    if (media.damage_recognition?.polygons) {
      media.damage_recognition?.polygons?.forEach((polygon) => {
        if (polygon.media?.uuid === this.selectedMedia?.uuid) {
          this.isApiPolygon = polygon.type === 'api';
          this.isBrushShape = ! this.isApiPolygon;
          this.hasAttachedPolygons = true;
          polygon = new Polygon(polygon);
          this.polygons.push(polygon);
        }
      });
    }
  }

  protected async attachPolygonToMedia(newMediaId: string) {
    this.isLoadingAutoBlur = true;

    if (! this.selectedPolygons) {
      return;
    }

    const payload = {
      polygons: this.selectedPolygons.map((polygon) => polygon.id),
    };

    const response = await this.client
      .post(`/v1/ml/damage-recognition/media/${newMediaId}/attach-polygons?with[]=polygons&with[]=media&with[]=damage_recognition`, payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (response?.data) {
      this.polygons = [];
      const media = response.data as Media;
      media.damage_recognition?.polygons?.forEach(async (polygon) => {
        if (polygon.type === 'api' && (polygon.media?.uuid === this.selectedMedia?.uuid)) {
          // await this.updateDamageMask(polygon);
          polygon = new Polygon(polygon);
          this.polygons.push(polygon);
          polygon.draw(this.context);
        }
      });
    }

    this.isLoadingAutoBlur = false;
  }

  protected async createManualPolygon(newMediaId: string) {
    const damageRecognition = this.damageRecognition;

    if (! damageRecognition || ! this.resultCanvas || ! this.resultContext) {
      return;
    }

    const contour = new Contour(this.resultCanvas, this.resultContext);
    const points = JSON.stringify(contour.points());

    const payload = {
      type: 'manual_drawing',
      media: newMediaId,
      points,
    };

    return await new DamageRecognition(damageRecognition)
      .createManualPolygon(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      }) as Polygon;
  }

  protected async fetchDetectionDetails() {
    this.isLoadingAutoBlur = true;

    const response = await this.client
      .get(`/v1/ml/recognize/${this.selectedMedia.uuid}`)
      .catch((error: AxiosError) => {
        this.detectionErrorMessage();
        return null;
      });

    this.isLoadingAutoBlur = false;

    return response ? response.data : this.detectionErrorMessage();
  }

  protected async fetchRecognitionDetails() {
    this.isLoadingAutoBlur = true;

    const response = await this.client
      .get(`/v1/ml/damage-recognition/media/${this.selectedMedia.uuid}?with[]=polygons&with[]=media`)
      .catch((error: AxiosError) => {
        this.recognitionErrorMessage();
        return null;
      });

    this.isLoadingAutoBlur = false;

    return response?.data || this.detectionErrorMessage();
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get canRecognizeDamages(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.RECOGNIZE_DAMAGE);
  }

  protected get recognizeDamagesLabel() {
    if (this.isActiveRecognition) {
      return 'Negeer herkenning';
    }

    return this.hasAttachedPolygons ? 'Bekijk schade herkenning' : 'Schade herkennen';
  }

  protected get hasSelectedPolygons() {
    return !! this.selectedPolygons?.length;
  }

  protected get canvasSize(): CanvasSize {
    return this.imageEditorOptions.container.invoke('getCanvasSize');
  }

  protected get client(): AxiosInstance {
    const headers = {};
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }
    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  // #endregion

  // #region @Watchers
  // #endregion
}

export interface StringArray {
  [key: string]: string;
}

export interface NumberArray {
  [key: string]: number;
}

interface DetectionDetail {
  face_recognition_response: string;
  text_recognition_response: string;
  grouped_boxes: GroupedBox[];
}

interface GroupedBox {
  Width: number;
  Height: number;
  Left: number;
  Top: number;
}

interface CanvasSize {
  width?: number;
  height?: number
}
