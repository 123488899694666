import { Component, Vue, Watch } from 'vue-property-decorator';
import Navigation from '@/components/navigation/Navigation.vue';
import { Token } from '@/support/Token';
import { User } from '@/models/User';
import MiDialog from '@/components/mi-dialog/MiDialog.vue';
import { Options as DialogOptions } from '@/components/mi-dialog/MiDialog';
import { Route } from 'vue-router';
import { Breadcrumb } from '@/support/Breadcrumb';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import { UserFilter } from '@/models/UserFilter';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { redirect } from '@/support/Url';
import { Application } from '@/support/Application';

@Component<App>({
  components: {
    MiDialog,
    Navigation,
  },
  beforeRouteUpdate(to, from, next) {
    next();

    this.routeName = this.$route.name as string;
  },
})
export default class App extends Vue {
  public routeName: string | undefined = '';

  public breadcrumbs: Breadcrumb[] = [];

  protected isLoading = true;

  public created() {
    document.addEventListener('swUpdated', (event) => {
      console.log('Service worker updated');
    }, { once: true });
  }

  public mounted() {
    if (this.$store.state.isAcceptance) {
      this.pingApi();
    }

    this.isLoading = true;

    this.$root.$on('initialize', this.initialize);
    this.routeName = this.$route.name as string;

    if (Token.get('access')) {
      new User()
        .current()
        .then((user: User) => {
          this.getUserFilters();
          this.$store.dispatch('userAuthenticated', user);
          this.initialize();
          this.isLoading = false;
        })
        .catch(() => {
          Token.delete('access');
          redirect('/login');
          this.$store.dispatch('userDeauthenticated');
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }

    this.windowScrollTop();
    document.title = Application.config('APP_TITLE') as string;
  }

  protected pingApi() {
    // FIXME: dit zal niet gaan werken op een gegeven moment, bedenk hier een betere oplossing voor. Mike
    // Dit heeft te maken met de simple user redirect daar krijg ik een een sso & token query
    if (this.$store.state.router.current.name !== 'maintenance' && ! this.$store.state.router.current.query.sso && ! this.$store.state.router.current.query.token) {
      new User()
        .all()
        .then((users: User[]) => {});
    }
  }

  protected windowScrollTop() {
    window.scrollTo(0, 0);
  }

  protected get updateIsReady(): boolean {
    return this.$store.getters.updateIsReady;
  }

  protected get queryStatus() {
    return this.$route.query.status;
  }

  protected getUserFilters() {
    new UserFilter()
      .limit(5000)
      .all()
      .then((userFilters: UserFilter[]) => {
        this.$store.dispatch('changeUserFilters', userFilters);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  get showAppLoader() {
    return Token.get('access') && this.isLoading;
  }

  public initialize() {
    //
  }

  get dialogOptions(): DialogOptions | null {
    return this.$store.state.dialog;
  }

  get isDisplayingDialog(): boolean {
    return this.dialogOptions !== null;
  }

  set isDisplayingDialog(value: boolean) {
    this.$store.dispatch('closeDialog');
  }

  protected get isDisplayingNavigation() {
    return this.$store.state.Auth && this.$store.state.isDisplayingNavigation;
  }

  @Watch('$route')
  protected routeChanged(to: Route, from: Route) {
    this.windowScrollTop();
    // this.breadcrumbs.push({ name: 'name', path: to.path });
  }
}
