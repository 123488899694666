var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-w-full tw-text-center tw-bg-transparent" },
    [
      _c("div", {
        staticClass: "tw-m-auto atabix-loader",
        class: [_vm.colorClass],
        style: _vm.styles
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }