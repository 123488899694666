var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-conclusion", class: _vm.resultBackgroundColor },
    [
      _c(
        "div",
        { staticClass: "question-conclusion__icon" },
        [_vm._t("icon", [_c("v-icon", [_vm._v(_vm._s(_vm.resultIcon))])])],
        2
      ),
      _c("div", { staticClass: "question-conclusion__content" }, [
        _c(
          "div",
          { staticClass: "tw-w-full tw-pr-16 tw-self-center" },
          [
            _vm._t("content", [
              _c("div", [_c("strong", [_vm._t("content_title")], 2)]),
              _c("div", [_c("em", [_vm._t("content_description")], 2)])
            ])
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "tw-w-full tw-pl-16" },
          [
            _vm.isDeviation
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "tw-w-full tw-flex tw-flex-wrap tw-text-lg"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tw-w-full tw-flex" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass:
                                "tw-text-warning-500 tw-mr-4 tw-text-lg"
                            },
                            [_vm._v("warning")]
                          ),
                          _c(
                            "strong",
                            { staticClass: "tw-text-warning-500 tw-mr-4" },
                            [_vm._v("Afgeweken")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "tw-w-full tw-flex tw-flex-wrap" },
                        [
                          _vm._m(0),
                          _c(
                            "div",
                            { staticClass: "tw-w-full" },
                            [_vm._t("content_deviation-reason")],
                            2
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "tw-w-full tw-flex tw-flex-wrap" },
                        [
                          _vm._m(1),
                          _c(
                            "div",
                            { staticClass: "tw-w-full" },
                            [_vm._t("content_deviation-explanation")],
                            2
                          )
                        ]
                      )
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "question-conclusion__actions" },
        [
          _vm._t("actions", [
            _c(
              "span",
              {
                staticClass: "deleteConclusion",
                on: {
                  click: function($event) {
                    return _vm.deleteAnswer()
                  }
                }
              },
              [_c("v-icon", [_vm._v("delete")])],
              1
            ),
            !_vm.$slots.actions
              ? _c(
                  "MiButton",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      color: _vm.classificationButtonColors,
                      icon: "dynamic_form",
                      disabled: _vm.buttonDisabled,
                      compact: "true",
                      fontSize: "10"
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("actionButtonClicked")
                      }
                    }
                  },
                  [_vm._t("button_text", [_vm._v("Open dialog")])],
                  2
                )
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tw-w-full" }, [
      _c("strong", { staticClass: "tw-mr-4" }, [_vm._v("Reden:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tw-w-full" }, [
      _c("strong", { staticClass: "tw-mr-4" }, [_vm._v("Toelichting:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }