import { Component, Vue, Prop } from 'vue-property-decorator';
import ImageEditor from 'tui-image-editor';

@Component<TuiImageEditor>({})
export default class TuiImageEditor extends Vue {
  protected editorDefaultOptions: Object = {
    selectionStyle: {
      cornerSize: 60,
      rotatingPointOffset: 70,
      cornerColor: '#fff',
      cornerStrokeColor: '#000',
    },
  };

  protected includeUIOptions = {
    includeUI: {
      initMenu: 'filter',
    },
  };

  protected editorEvents = [
    'addText',
    'mousedown',
    'objectActivated',
    'objectMoved',
    'objectScaled',
    'redoStackChanged',
    'textEditing',
    'undoStackChanged',
  ];

  @Prop({ default: true })
  protected includeUi!: boolean;

  @Prop({ default: () => {} })
  protected options!: Object;

  protected editorInstance: any = null;

  public mounted() {
    let options = this.options && Object.keys(this.options).length > 0 ? this.options : this.editorDefaultOptions;

    if (this.includeUi) {
      options = Object.assign(this.includeUIOptions, this.options);
    }

    this.editorInstance = new ImageEditor(this.$refs.tuiImageEditor as any, options);
    this.addEventListener();
  }

  public destroyed() {
    if (this.editorInstance !== null) {
      this.editorEvents.forEach((eventName) => this.editorInstance.off(eventName));
      this.editorInstance.destroy();
    }
  }

  addEventListener() {
    if (this.editorInstance) {
      this.editorEvents.forEach((eventName) => {
        this.editorInstance.on(eventName, (...args: any[]) => this.$emit(eventName, ...args));
      });
    }
  }

  getRootElement() {
    return this.$refs.tuiImageEditor;
  }

  invoke(methodName: string, ...args: any[]) {
    let result = null;
    if (this.editorInstance[methodName]) {
      result = this.editorInstance[methodName](...args);
    } else if (methodName.indexOf('.') > - 1) {
      const func = this.getMethod(this.editorInstance, methodName);

      if (typeof func === 'function') {
        result = func(...args);
      }
    }

    return result;
  }

  getMethod(instance: any, methodName: string): Object {
    const { first, rest } = this.parseDotMethodName(methodName);
    const isInstance = (instance.constructor.name !== 'Object');
    const type = typeof instance[first];
    let obj;

    if (isInstance && type === 'function') {
      obj = instance[first].bind(instance);
    } else {
      obj = instance[first];
    }

    if (rest.length > 0) {
      return this.getMethod(obj, rest);
    }

    return obj;
  }

  parseDotMethodName(methodName: string) {
    const firstDotIdx = methodName.indexOf('.');
    let firstMethodName = methodName;
    let restMethodName = '';

    if (firstDotIdx > - 1) {
      firstMethodName = methodName.substring(0, firstDotIdx);
      restMethodName = methodName.substring(firstDotIdx + 1, methodName.length);
    }

    return {
      first: firstMethodName,
      rest: restMethodName,
    };
  }
}
