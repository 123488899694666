import { QuestionBase } from '@/models/question-models/question-base';

export class RadioQuestion extends QuestionBase<string> {
  controlType = 'radio';

  options: {key: string, value: string, label: string, optionalQuestions: any[]}[] = [];

  checked: boolean;

  constructor(options: any = {}) {
    super(options);
    this.options = options.options || [];
    this.checked = false;

    if (options.checked) {
      this.checked = true;
    }
  }
}
