import { Address } from '@/models/Address';
import { isEmpty } from 'lodash';
import { AxiosError } from 'axios';
import client from '@/support/Client';
import { Model } from '@/models/Model';
import { Report } from '@/models/Report';
import { User } from '@/models/User';
import { Organization } from '@/models/Organization';
import { Meeting } from '@/models/Meeting';

export class Event extends Model {
  protected $primaryKey = 'id';

  protected $name = 'Event';

  protected $endpoint = '/events';

  protected $fillable: string[] = [
    'date',
    'daypart',
    'status',
  ];

  public id?: string;

  public date?: string;

  public daypart?: DayPart;

  public status?: EventStatus;

  public type?: EventType;

  public starts_at?: string;

  public ends_at?: string;

  public mediator_event?: MediatorEvent;

  public user_id?: string;

  public note?: string;

  public organization_id?: string;

  public opname_variant?: string;

  public user?: User;

  public mediator?: User;

  public organization?: Organization;

  public calendarEvent?: any;

  public has_note?: boolean;

  public report?: Report;

  public is_mediator_present?: boolean;

  public appointment_type?: AppointmentType;

  public confirm_email_to_application?: boolean;

  public meeting?: Meeting;

  public address?: Address;

  public appointment_group!: AppointmentGroup;

  public summary(): Promise<any> {
    const url = `${this.$endpoint}/summary`;

    return client('get', url, {}).then(
      (response: any) => {
        if (! response.data) {
          Promise.reject('Data not present on response.');
        }

        const data = (response.data as object[]).map((data: any) => data);

        return Promise.resolve(data);
      },
      (error: AxiosError) => Promise.reject(error),
    );
  }

  public get fullcalendarEvent() {
    if (! this.id || ! this.starts_at || ! this.ends_at) {
      return null;
    }

    const event: {[key: string]: any} = {
      id: this.id,
      start: this.starts_at,
      end: this.ends_at,
      // TODO: check if this keeps working wiht schedule_spoof
      // rendering: this.type && (this.type === 'busy' || this.type === 'internal') ? 'background' : '',
    };

    if (this.report && ! isEmpty(this.report)) {
      event.title = this.report.case_number;
    }

    return event;
  }

  public hasType(type: string | string[]) {
    if (this.type === undefined) {
      return false;
    }

    if (! Array.isArray(type)) {
      type = [type];
    }

    let hasType = false;
    if (status.includes(this.type)) {
      hasType = true;
    }

    return hasType;
  }

  public export() {
    const url = `${this.$endpoint}/export`;

    return this.request('get', url);
  }

  public get isIMSEvent() {
    return !! this.report?.isImmaterial;
  }

  private isAppointmentType(type: string): boolean {
    if (! this.appointment_type) {
      return false;
    }

    return this.appointment_type === type;
  }

  public get isZb() {
    return this.appointment_group === AppointmentGroup.ZB;
  }

  public get isExpert() {
    return this.appointment_group === AppointmentGroup.EXPERT;
  }
}

export enum EventIncludes {
  MEETING = 'meeting',
  USER = 'user',
  MEDIATOR = 'mediator',
  PARTICIPANTS = 'participants',
}

export enum DayPart {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
}

export enum EventStatus {
  CONCEPT = 'concept',
  PLANNED = 'planned',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

interface MediatorEvent {
  starts_at?: string;
  ends_at?: string;
}

export enum EventType {
  BUSY = 'busy',
  FREE = 'free',
  INTERNAL = 'internal',
  SICK = 'sick',
  HOLIDAY = 'holiday',
  OTHER = 'other',
  APPOINTMENT = 'appointment',
  MEETING = 'meeting',
  HOUSEVISIT = 'house_visit',
  SPEAKINGROOM = 'speaking_room',
  LOSSOFNONRESIDENTIALPROPERTYVALUE = 'loss_of_non_residential_property_value',
  COMMUNITYCENTER = 'community_center',
  MANURECELLAR = 'manure_cellar',
  TIME = 'time',
}

export const EventTypeLabels: {[key: string] : any} = {
  house_visit: 'Huisbezoek',
  speaking_room: 'Spreekkamer dienst',
  meeting: 'Overleg',
  holiday: 'Verlof',
  sick: 'Ziekte',
  community_center: 'Steunpunt',
  other: 'Overige afspraak',
  loss_of_non_residential_property_value: 'Waardedaling niet woningen',
  manure_cellar: 'Mestkelder',
};

export enum AppointmentType {
  DEFAULT = 'default',
  VERVOLGOPNAME = 'vervolgopname',
  NADER_ONDERZOEK = 'nader_onderzoek',
  AANVULLEND_ONDERZOEK = 'aanvullend_onderzoek',
  HOORZITTING = 'hoorzitting',
  OPNAME_OP_AFSTAND = 'opname_op_afstand',
  PHYSICAL = 'physical',
  CALL = 'call',
}

export const AppointmentTypeLabels: {[key: string] : any} = {
  default: 'Opname',
  vervolgopname: 'Vervolgopname',
  nader_onderzoek: 'Nader onderzoek',
  aanvullend_onderzoek: 'Aanvullend onderzoek',
  hoorzitting: 'Hoorzitting',
  opname_op_afstand: 'Opname op afstand',
  physical: 'Fysieke afspraak',
  call: 'Belafspraak',
};

export const appointmentTypeMap: AppointmentTypeMap[] = [
  { key: 'default', abbreviation: 'SO', label: 'Schade-opname', color: 'blue-magenta', icon: 'person_search', type: 'default' },
  { key: 'vervolgopname', abbreviation: 'VO', label: 'Vervolgopname', color: 'blue-magenta', icon: 'settings', type: 'default' },
  { key: 'nader_onderzoek', abbreviation: 'NO', label: 'Nader onderzoek', color: 'blue-magenta', icon: 'settings', type: 'default' },
  { key: 'aanvullend_onderzoek', abbreviation: 'AO', label: 'Aanvullend onderzoek', color: 'blue-magenta', icon: 'settings', type: 'default' },
  { key: 'heropname', abbreviation: 'HER', label: 'Heropname', color: 'blue-magenta', icon: 'settings', type: 'default' },
  { key: 'hoorzitting', abbreviation: 'HZ', label: 'Hoorzitting', color: 'blue-magenta', icon: 'settings_voice', type: 'default' },
  { key: 'second_opinion', abbreviation: 'SECOP', label: 'Second Opinion', color: 'blue-magenta', icon: 'settings', type: 'default' },
  { key: 'opname_op_afstand', abbreviation: 'OOP', label: 'Opname op afstand', color: 'blue-magenta', icon: 'settings', type: 'default' },

  { key: 'physical', abbreviation: 'FS', label: 'Fysieke afspraak', color: 'blue-magenta', icon: 'settings', type: 'ims' },
  { key: 'webex', abbreviation: 'we', label: 'Webex afspraak', color: 'blue-magenta', icon: 'settings', type: 'ims' },
  { key: 'office', abbreviation: 'we', label: 'Kantoor', color: 'blue-magenta', icon: 'settings', type: 'ims' },
  { key: 'call', abbreviation: 'we', label: 'Bel afspraak', color: 'blue-magenta', icon: 'settings', type: 'ims' },
];

export interface AppointmentTypeMap {
  [key: string]: string | string[] | undefined;
  key: string;
  label: string;
  abbreviation: string;
  color: string;
  icon: string;
  type: string;
}

export const getGroupAppointmentTypes = (group: AppointmentGroup) => {
  if (group === AppointmentGroup.ZB) {
    return appointmentTypeMap.filter((appointmentType) => appointmentType.key === 'physical' || appointmentType.key === 'call' || appointmentType.key === 'opname_op_afstand');
  }

  if (group === AppointmentGroup.EXPERT) {
    return appointmentTypeMap.filter((appointmentType) => appointmentType.type === 'default');
  }

  if (group === AppointmentGroup.JZ) {
    return appointmentTypeMap.filter((appointmentType) => appointmentType.type === 'ims');
  }

  return [];
};

export const getAppointmentType = (key: string) => {
  const defaultAppointmentType: AppointmentTypeMap = { key: 'default', abbreviation: 'SO', label: 'Opname', color: 'blue-magenta', icon: 'settings', type: 'default' };
  const appointmentType = appointmentTypeMap.find((appointmentType: AppointmentTypeMap) => appointmentType.key === key);

  return appointmentType || defaultAppointmentType;
};

export enum AppointmentGroup {
  ZB = 'zb',
  EXPERT = 'expert',
  HOURS = 'hours',
  JZ = 'jz',
  MEETING = 'meeting',
}
