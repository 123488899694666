var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mi-global-search", class: { focused: _vm.isFocused } },
    [
      _c(
        "v-autocomplete",
        {
          ref: "autocomplete",
          attrs: {
            focus: _vm.isFocused,
            items: _vm.selectedItems,
            disabled: _vm.disabled,
            "item-text": "name",
            "item-value": "uuid",
            "hide-details": "",
            "hide-selected": "",
            "no-filter": "",
            clearable: true,
            placeholder: "Zoeken",
            "append-icon": "search",
            "hide-no-data": true,
            "search-input": _vm.search,
            attach: ".mi-global-search"
          },
          on: {
            focus: function($event) {
              _vm.isFocused = true
            },
            blur: _vm.reset,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.goToSearchPage()
            },
            "update:searchInput": function($event) {
              _vm.search = $event
            },
            "update:search-input": function($event) {
              _vm.search = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    { staticClass: "mi-search-item" },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          item && item.type === "reports"
                            ? _c("v-icon", [_vm._v("folder")])
                            : _vm._e(),
                          item && item.type === "users"
                            ? _c("v-icon", [_vm._v("person")])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", {
                            domProps: { textContent: _vm._s(item.name) }
                          }),
                          _c("v-list-tile-sub-title", {
                            domProps: { textContent: _vm._s(item.sub1) }
                          }),
                          _c("v-list-tile-sub-title", {
                            domProps: { textContent: _vm._s(item.sub2) }
                          })
                        ],
                        1
                      ),
                      _c("v-list-tile-action")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "template",
            { slot: "no-data" },
            [
              _c(
                "v-list-tile",
                [
                  _c("v-list-tile-title", [
                    _vm._v(" Search for your favorite "),
                    _c("strong", [_vm._v("Cryptocurrency")])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }