import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { Department } from '@/models/Department';

@Component<ReportDepartmentFilter>({})
export default class ReportDepartmentFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: true })
  protected multiple!: boolean;

  @Prop()
  protected dmz!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected departments: Department[] = [];

  protected selectedDepartments = [];

  protected selectAll = false;

  protected isLoading = true;

  protected handleChange() {
    this.$emit('input', this.selectedDepartments);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedDepartments = [];
    } else {
      this.selectedDepartments = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getDepartments();
    this.isLoading = false;
  }

  protected async getDepartments() {
    this.departments = await new Department()
      .dmz(this.dmz)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.itemsChanged();
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedDepartments', this.departments.map((department: Department) => department.id || ''));
    } else {
      this.$set(this, 'selectedDepartments', []);
    }

    this.handleChange();
  }
}
