var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FullscreenDialogLayout",
        {
          staticClass: "question-conclusion-dialog",
          on: {
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _vm.step === 1
                ? _c(
                    "v-layout",
                    { staticClass: "tw-text-lg", attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-secondary-400 tw-font-bold tw-text-2xl"
                            },
                            [_vm._v("Foto's nieuwe schade")]
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-text-black-500 tw-font-normal" },
                            [
                              _vm._v(
                                "U kunt foto's zoeken uit een eerder behandelde schade"
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("div", { staticClass: "tw-pl-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-secondary-400 tw-font-bold tw-text-2xl"
                            },
                            [_vm._v("Voorgestelde schades")]
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-text-black-500 tw-font-normal" },
                            [
                              _vm._v(
                                " Dit zijn afbeeldingen van schades die al bekend zijn bij IMG. "
                              )
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 2
                ? _c(
                    "v-layout",
                    { staticClass: "tw-text-lg", attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-secondary-400 tw-font-bold tw-text-2xl"
                            },
                            [_vm._v("Vragen")]
                          ),
                          _c(
                            "div",
                            { staticClass: "tw-text-black-500 tw-font-normal" },
                            [
                              _vm._v(
                                "dit zijn foto's van het dossier waarme jij bezig bent."
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("div", { staticClass: "tw-pl-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-secondary-400 tw-font-bold tw-text-2xl"
                            },
                            [_vm._v("PDF viewer")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-black-500 tw-font-normal tw-flex"
                            },
                            [
                              _c("div", { staticClass: "tw-mr-40" }, [
                                _vm._v(" Rapportnaam "),
                                _c("br"),
                                _c("div", { staticClass: "tw-flex" }, [
                                  _c(
                                    "span",
                                    { staticClass: "tw-font-bold tw-mr-8" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedReportImage
                                            .historical_report_name || ""
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", [
                                _vm._v(" Paginanummer "),
                                _c("br"),
                                _c("div", { staticClass: "tw-flex" }, [
                                  _c(
                                    "span",
                                    { staticClass: "tw-font-bold tw-mr-8" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedReportImage.page_number ||
                                            ""
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 3
                ? _c(
                    "v-layout",
                    { staticClass: "tw-text-lg", attrs: { row: "" } },
                    [
                      _c("v-flex", [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-text-secondary-400 tw-font-bold tw-text-2xl"
                            },
                            [_vm._v("Historische schades informatie")]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.step === 1
            ? _c(
                "template",
                { slot: "container" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass:
                            "image-recognition__image-selection-area",
                          attrs: { xs6: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "image-selection-area__container" },
                            [
                              _vm._l(_vm.currentDamageMedia, function(
                                image,
                                index
                              ) {
                                return [
                                  _c("img", {
                                    key: index,
                                    staticClass: "image-area__image",
                                    class: {
                                      "tw-cursor-wait":
                                        _vm.isLoadingImageSuggestions,
                                      "image-area__image--selected":
                                        _vm.selectedImage &&
                                        image.original ===
                                          _vm.selectedImage.original
                                    },
                                    attrs: { src: image.thumb },
                                    on: {
                                      click: function($event) {
                                        return _vm.compareImage(index)
                                      }
                                    }
                                  })
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-flex",
                        {
                          staticClass:
                            "image-recognition__image-selection-area",
                          attrs: { xs6: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "image-selection-area__container" },
                            [
                              _vm.isLoadingImageSuggestions
                                ? _c("LoaderCard", {
                                    staticClass:
                                      "tw-w-full tw-self-center tw-justify-self-center",
                                    attrs: {
                                      size: "48",
                                      type: "spinner--small"
                                    }
                                  })
                                : _vm._l(_vm.foundImages, function(
                                    image,
                                    index
                                  ) {
                                    return [
                                      _c("img", {
                                        key: index,
                                        staticClass: "image-area__image",
                                        class: {
                                          "tw-cursor-wait":
                                            _vm.isLoadingImageSuggestions,
                                          "image-area__image--selected":
                                            _vm.selectedReportImage &&
                                            image.historical_report_image ===
                                              _vm.selectedReportImage
                                                .historical_report_image
                                        },
                                        attrs: {
                                          src: image.historical_report_image
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.chooseReportImage(index)
                                          }
                                        }
                                      })
                                    ]
                                  })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "image-recognition__image-chosen-area",
                          attrs: { xs6: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "image-chosen-area__container" },
                            [
                              _vm.selectedImage
                                ? _c("img", {
                                    staticClass: "tw-cursor-pointer",
                                    class: _vm.isLoadingImageSuggestions
                                      ? "tw-cursor-wait"
                                      : "tw-cursor-pointer",
                                    attrs: { src: _vm.selectedImage.original },
                                    on: {
                                      click: function($event) {
                                        return _vm.cancelCompareImage()
                                      }
                                    }
                                  })
                                : _c(
                                    "div",
                                    { staticClass: "tw-flex tw-flex-col" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#88949F",
                                            "x-large": ""
                                          }
                                        },
                                        [_vm._v("mdi-image")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tw-text-gray-full tw-mt-16"
                                        },
                                        [_vm._v("Geen foto geselecteerd")]
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        ]
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-flex",
                        {
                          staticClass: "image-recognition__image-chosen-area",
                          attrs: { xs6: "" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "image-chosen-area__container" },
                            [
                              _vm.selectedReportImage
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "container__preview-details-wrapper"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "preview-details-wrapper__details-container"
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "tw-cursor-pointer",
                                            class: _vm.isLoadingImageSuggestions
                                              ? "tw-cursor-wait"
                                              : "tw-cursor-pointer",
                                            attrs: {
                                              src:
                                                _vm.selectedReportImage
                                                  .historical_report_image
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelReportImage()
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "tw-ml-16 tw-text-xl"
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(" Rapportnaam "),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "tw-font-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedReportImage
                                                          .historical_report_name ||
                                                          ""
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "tw-mt-8" },
                                                [
                                                  _vm._v(" Paginanummer "),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "tw-font-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .selectedReportImage
                                                            .page_number || ""
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "tw-flex tw-flex-col" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#88949F",
                                            "x-large": ""
                                          }
                                        },
                                        [_vm._v("mdi-image")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "tw-text-gray-full tw-mt-16"
                                        },
                                        [_vm._v("Geen foto geselecteerd")]
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.step === 2
            ? _c("template", { slot: "container" }, [
                _c(
                  "div",
                  { staticClass: "tw-h-screen tw-overflow-y-scroll" },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "image-recognition__report-area",
                        attrs: { row: "" }
                      },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c(
                            "div",
                            { staticClass: "tw-px-8" },
                            [
                              _vm.isLoadingQuestions
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "100%"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoadingQuestions
                                ? [
                                    _vm.damageQuestions && _vm.damage
                                      ? _c("Questionnaire", {
                                          attrs: {
                                            questions:
                                              _vm.filteredDamageQuestions,
                                            answers: _vm.answers,
                                            report: _vm.report,
                                            damage: _vm.damage,
                                            enableReadMode:
                                              _vm.$store.state.Auth
                                                .has_quick_questions
                                          },
                                          on: {
                                            damageAnswerSaved:
                                              _vm.damageAnswerSaved,
                                            reloadAnswers:
                                              _vm.reloadDamageAnswers,
                                            reloadDamageAnswers:
                                              _vm.reloadDamageAnswers,
                                            reloadReportAnswers:
                                              _vm.reloadReportAnswers
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]),
                        _c("v-divider", { attrs: { vertical: "" } }),
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "image-recognition__iframe-container"
                            },
                            [
                              _c("iframe", {
                                ref: "pdfIframe",
                                staticStyle: { border: "0" },
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  frameborder: "0",
                                  src: _vm.reportUrl,
                                  allowfullscreen: ""
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.step === 3
            ? _c(
                "template",
                { slot: "container" },
                [
                  _vm.isLoadingHistoricalExtractions
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "100%"
                        }
                      })
                    : _c("div", { staticClass: "tw-flex tw-justify-center" }, [
                        _c(
                          "table",
                          {
                            staticClass: "historical-report-extraction__table",
                            attrs: { cellspacing: "0" }
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "tw-rounded-tl-lg" }, [
                                  _vm._v("Naam")
                                ]),
                                _c("th", { staticClass: "tw-rounded-tr-lg" }, [
                                  _vm._v("# Geëxtraheerde afbeeldingen")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.tableData, function(report, index) {
                                return _c(
                                  "tr",
                                  { key: "report-row-" + index },
                                  [
                                    _c("td", [_vm._v(_vm._s(report.name))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(report.extracted_images_count)
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                ],
                1
              )
            : _vm._e(),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "image-recognition__footer-continer" },
              [
                _vm.step !== 3
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "tw-text-white tw-mr-32 tw-flex tw-items-center tw-font-bold tw-cursor-pointer",
                        on: { click: _vm.onClickExtractionInformation }
                      },
                      [
                        _c("v-icon", { staticClass: "tw-text-white tw-mr-4" }, [
                          _vm._v("mdi-information-outline")
                        ]),
                        _vm._v(" Historische schades informatie ")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.step === 1
                  ? _c(
                      "MiButton",
                      {
                        staticClass: "mr-4",
                        attrs: {
                          condensed: "",
                          disabled: !_vm.isMatches,
                          small: true,
                          color: "warning",
                          icon: "mdi-close"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClickReportNoMatches($event)
                          }
                        }
                      },
                      [_vm._v(" Geen match gevonden ")]
                    )
                  : _vm._e(),
                _vm.step === 1
                  ? _c(
                      "MiButton",
                      {
                        staticClass: "tw-mr-8",
                        attrs: {
                          condensed: "",
                          disabled: !_vm.selectedReportImage,
                          small: true,
                          color: "primary",
                          icon: "mdi-arrow-right"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClickNavigateToReport($event)
                          }
                        }
                      },
                      [_vm._v(" Volgende stap ")]
                    )
                  : _vm._e(),
                _vm.step !== 1
                  ? _c(
                      "MiButton",
                      {
                        staticClass: "tw-mx-8",
                        attrs: {
                          condensed: "",
                          small: true,
                          color: "primary",
                          outline: true
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClickNavigateToImageSelect($event)
                          }
                        }
                      },
                      [_vm._v(" Terug ")]
                    )
                  : _vm._e(),
                _vm.step === 2
                  ? _c(
                      "MiButton",
                      {
                        staticClass: "tw-mr-8",
                        attrs: { condensed: "", small: true, color: "primary" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.close($event)
                          }
                        }
                      },
                      [_vm._v(" Klaar ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }