import { Model } from '@/models/Model';

export class Stats extends Model {
  protected $name = 'Stats';

  protected $endpoint = '/stats';

  protected $fillable: string[] = [];

  public from?: string;

  public till?: string;

  public week?: number;

  public cumulatief?: StatsData;

  public Totals?: StatsData;
}

interface StatsData {
  approved_by_tc?: number;
  assigned_to_expert?: number;
  closed_requests?: number;
  confirmed_and_sent?: number;
  created_by_backoffice?: number;
  precontrolled_by_tc?: number;
  submitted_by_expert?: number;
}
