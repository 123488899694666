var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.anomaly.type === _vm.AnomalyType.ASSESSMENT_SCHEME
        ? [
            _c("span", { staticClass: "tw-line-through" }, [
              _vm._v(_vm._s(_vm.anomaly.custom_properties.expected))
            ]),
            _c("span", { staticClass: "tw-mx-4" }, [_vm._v("/")]),
            _c("span", { class: _vm.textClass }, [
              _vm._v(_vm._s(_vm.anomaly.custom_properties.anomaly.value))
            ])
          ]
        : _vm._e(),
      _vm.anomaly.type === _vm.AnomalyType.DAMAGE_AMOUNT
        ? [
            _c("span", [
              _vm._v(_vm._s(_vm._f("currency")(_vm.anomaly.message)))
            ])
          ]
        : _vm._e(),
      _vm.anomaly.type === _vm.AnomalyType.DAMAGE_CLASSIFICATION
        ? [_c("span", [_vm._v(_vm._s(_vm.anomaly.message))])]
        : _vm._e(),
      _vm.anomaly.type === _vm.AnomalyType.OTHER
        ? [_c("span", [_vm._v(_vm._s(_vm.anomaly.message))])]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }