import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Article } from '@/models/Article';
import { Damage } from '@/models/Damage';
import { Question } from '@/models/Question';
import { Report } from '@/models/Report';
import { Rpc } from '@/models/Rpc';
import LibraryArticle from '@/views/LibraryArticle/LibraryArticle.vue';
import QuestionaireDialogConclusionType, { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { Answer } from '@/models/Answer';
import QuestionConclusion from '@/components/QuestionConclusion/QuestionConclusion.vue';
import { cloneDeep } from 'lodash';
import PgvInfoBlock from './PgvInfoBlock.vue';
import QuestionBlock from './QuestionBlock.vue';
import { QuestionConclusionOverwritePayload } from './QuestionConclusionOverwriteDialog/QuestionConclusionOverwriteDialog';
import QuestionConclusionOverwriteDialog from './QuestionConclusionOverwriteDialog/QuestionConclusionOverwriteDialog.vue';

@Component<QuestionConclusionDialog>({
  name: 'QuestionConclusionDialog',
  components: {
    PgvInfoBlock,
    QuestionBlock,
    LibraryArticle,
    QuestionConclusionOverwriteDialog,
    QuestionConclusion,
  },
})
export default class QuestionConclusionDialog extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  @Prop()
  protected report!: Report;

  @Prop()
  protected damage!: Damage | null;

  @Prop()
  protected title!: string;

  @Prop()
  protected question!: Question;

  @Prop()
  protected primarySection!: number;

  @Prop()
  protected secondarySection!: number;

  @Prop({ required: true })
  protected conclusion!: Conclusion;

  @Prop({ default: undefined })
  protected damageAnswers!: {[key: string]: string};

  @Prop({ default: false })
  protected isLoadingQuestions!: boolean;

  @Prop({ default: [] })
  protected overwriteConclusionDialogReasons!: { label?: string, value?: string}[];

  @Prop()
  protected overwriteConclusionDialogTitle!: string;
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected primarySectionQuestions: Question[] = [];

  protected secondSectionQuestions: Question[] = [];

  protected reportAnswers: Answer[] = [];

  protected isLoading = false;

  protected article: Article | null = null;

  protected sidePanelType: SidePanelType = '';

  protected currentQuestion: Question | null = null;

  protected foundQuestion: Question | null = null;

  protected isDisplayingSidePanel = false;

  protected isDisplayingQuestionConclusionOverwriteDialog = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;

    this.primarySectionQuestions = await this.getSectionQuestions(this.primarySection);
    if (this.secondarySection) {
      this.secondSectionQuestions = await this.getSectionQuestions(this.secondarySection);
    }
    await Promise.all([
      this.getReportAnswers(),

      this.$emit('reloadFreshData'),
    ]);

    this.isLoading = false;
    this.$nextTick(() => {
      this.displayCorrectSidePanel();
    });
  }
  // // #endregion

  // #region Class methods
  protected onUseConclusion(): void {
    this.isLoading = true;
    this.$emit('useConclusion', this.isSuccesConclusion, this.conclusion);
  }

  protected onClickInfo(question: Question): void {
    this.clickInfo(question);
  }

  protected onClickMetaPgvLimit(question: Question): void {
    this.clickMetaPgvLimit(question);
  }

  protected onDamageAnswerSaved(damage: Damage, question: Question): void {
    this.damageAnswerSaved(damage, question);
  }

  protected onReportAnswerSaved() {
    this.reportAnswerSaved();
  }

  protected async clickInfo(question: Question): Promise<void> {
    if (! question.library_article_id) { return; }
    await this.getArticle(question.library_article_id);
    this.sidePanelType = 'article';
  }

  protected clickMetaPgvLimit(question: Question): void {
    this.currentQuestion = question;
    this.sidePanelType = 'pgv';
  }

  protected async autoAnswerQuestion(question: Question): Promise<void> {
    this.currentQuestion = question;
    await this.getQuestion(question?.auto_answer || '');
    this.sidePanelType = 'question';
  }

  protected async damageAnswerSaved(damage: Damage, question: Question): Promise<void> {
    this.$emit('update:damageAnswers', damage.answers);
    this.$emit('reloadFreshData', question);
  }

  public displayCorrectSidePanel(question?: Question): void | Promise<void> {
    const nextQuestion = this.getNextQuestion(question);

    if (! nextQuestion) {
      this.sidePanelType = '';
      return;
    }
    if (nextQuestion.auto_answer) {
      return this.autoAnswerQuestion(nextQuestion);
    }

    if (nextQuestion && typeof (nextQuestion.meta as { [key: string]: number })?.pgv_limit === 'number') {
      return this.clickMetaPgvLimit(nextQuestion);
    }

    if (nextQuestion && typeof nextQuestion.library_article_id === 'string') {
      return this.clickInfo(nextQuestion);
    }
  }

  protected getNextQuestion(question?: Question): Question | null {
    const questions = this.getDomQuestions();

    if (! questions) {
      return null;
    }

    let nextQuestion: Element | null = null;
    const filteredQuestions = this.filteredQuestions(questions);

    if (question) {
      // Get next question starting from answered question
      filteredQuestions.forEach((domQuestion, index) => {
        if (domQuestion.id === question.uuid) {
          nextQuestion = filteredQuestions[index + 1] || null;
        }
      });
    } else {
      // Get first unanswered question
      filteredQuestions.forEach((domQuestion, index) => {
        if (! this.hasQuestionValue(domQuestion) && ! nextQuestion) {
          nextQuestion = filteredQuestions[index];
        }
      });
    }

    if (! nextQuestion) {
      return null;
    }

    return this.findQuestionModel(nextQuestion);
  }

  protected hasQuestionValue(domQuestion: Element): boolean {
    const question = this.findQuestionModel(domQuestion);
    if (! question?.key) {
      return false;
    }

    if (this.damageAnswers) { // assume CasualityQuestion
      return !! (this.damageAnswers && this.damageAnswers[question.key]);
    }

    // assume BuildingFeaturesQuestion
    const reportAnswer = this.report.getAnswer(question.key);

    return !! (reportAnswer && reportAnswer.value);
  }

  protected filteredQuestions(questions: NodeListOf<Element>): Element[] {
    const filteredQuestions: Element[] = [];
    questions.forEach((domQuestion) => {
      if (domQuestion.getAttribute('data-type') !== 'header') {
        filteredQuestions.push(domQuestion);
      }
    });

    return filteredQuestions;
  }

  protected findQuestionModel(nextQuestion: Element): Question | null {
    const firstQuestionsFlat = this.getQuestionsAvailableFlattened(this.primarySectionQuestions, this.damageAnswers);
    const secondQuestionsFlat = this.getQuestionsAvailableFlattened(this.secondSectionQuestions, this.damageAnswers);
    const mergedQuestionsFlat = [...firstQuestionsFlat, ...secondQuestionsFlat];

    return mergedQuestionsFlat.find((flatQuestion) => flatQuestion.uuid === nextQuestion?.id) || null;
  }

  protected getDomQuestions(): NodeListOf<Element> | null {
    const container = document.getElementById('question-conclusion-dialog');
    if (! container) {
      return null;
    }

    const questions = container.querySelectorAll('.question');
    return questions || null;
  }

  protected async reportAnswerSaved(): Promise<void> {
    await this.getReportAnswers();
    this.$emit('reloadFreshData');
    this.$nextTick(() => {
      this.displayCorrectSidePanel();
    });
  }

  protected async deviateFromConclusion(payload: QuestionConclusionOverwritePayload): Promise<void> {
    this.isLoading = true;
    this.$emit('deviateFromConclusion', ! this.isSuccesConclusion, payload);
  }

  protected onOpenQuestionConclusionOverwriteDialog(): void {
    this.isDisplayingQuestionConclusionOverwriteDialog = true;
  }

  protected close(): void {
    this.$emit('input', false);
  }

  protected async answerPGVQuestion(question: Question, value: string): Promise<void> {
    this.$emit('answerQuestion', question, value);
  }

  protected async answerCurrentQuestion(question: Question, value: string): Promise<void> {
    this.$emit('answerQuestion', question, value);
  }

  protected getQuestionsAvailableFlattened(questions: Question[], answers: {[key: string]: string} | null): Question[] {
    let questionsFlat: Question[] = [];

    questions.forEach((question: Question) => {
      if (! question.key) { return; }

      questionsFlat = [...questionsFlat, question];

      const questionAnswer = answers?.[question.key];

      if (question.options) {
        const option = question.options.find((questionOption) => questionOption.value === questionAnswer);

        if (option?.questions) {
          questionsFlat = [...questionsFlat, ...this.getQuestionsAvailableFlattened(option.questions, answers)];
        }
      }
    });

    return questionsFlat;
  }
  // #endregion

  // #region Async methods
  protected async getQuestion(questionId: string): Promise<void> {
    this.foundQuestion = await new Question()
      .filter({
        report: this.report.uuid,
        report_type: this.report.type?.uuid,
      })
      .include('answer')
      .find(questionId);
  }

  protected async getSectionQuestions(section: number): Promise<Question[]> {
    if (! this.report?.type || ! section) {
      return [];
    }

    return await new Question()
      .include('auto_answer')
      .filter({
        form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
        sections: section,
        report_type: this.report.type.uuid,
      })
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportAnswers(): Promise<void> {
    if (! this.report?.uuid || ! this.report?.type) {
      return;
    }

    this.reportAnswers = await new Answer()
      .filter({
        report_type: this.report.type.uuid,
        report: this.report.uuid,
      })
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getArticle(id: string): Promise<void> {
    this.article = await new Article()
      .include(['media', 'default_answers', 'repair_types'])
      .find(id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  // #endregion

  // #region Getters & Setters

  protected get questionairAnswers() {
    return this.damageAnswers || this.reportAnswers;
  }

  protected get currentDeviationPayload(): QuestionConclusionOverwritePayload|null {
    // when damage set, assume its CasualityDialog
    if (this.damage instanceof Damage) {
      return {
        reason: this.damage.casuality_anomaly_reason,
        comment: this.damage.casuality_anomaly_explanation,
      } as QuestionConclusionOverwritePayload;
    }

    // assume it's from an specific report answer KenmerkenGevoeligObject
    const answer = this.report.getAnswer('KenmerkenGevoeligObject') as Answer;
    if (answer) {
      return {
        reason: answer.sensitive_building_anomaly_reason,
        comment: answer.sensitive_building_anomaly_explanation,
      } as QuestionConclusionOverwritePayload;
    }

    return null;
  }

  protected get QuestionaireDialogConclusionType(): QuestionaireDialogConclusionType {
    return QuestionaireDialogConclusionType;
  }

  protected get hasConclusionConfirm(): boolean {
    switch (this.conclusion?.status) {
      case QuestionaireDialogConclusionTypeEnum.SUCCESS:
      case QuestionaireDialogConclusionTypeEnum.ERROR:
        return true;
      case QuestionaireDialogConclusionTypeEnum.WARNING:
      case QuestionaireDialogConclusionTypeEnum.INCOMPLETE:
      default:
        return false;
    }
  }

  protected get isSuccesConclusion(): boolean {
    return this.conclusion?.status === QuestionaireDialogConclusionTypeEnum.SUCCESS;
  }

  protected get isErrorConclusion(): boolean {
    return this.conclusion?.status === QuestionaireDialogConclusionTypeEnum.ERROR;
  }

  protected get isShowingQuestionaire() {
    return this.conclusion?.show_questionaire || false;
  }

  protected get isShowingSecondQuestionaire() {
    return this.conclusion?.show_second_questionaire || false;
  }

  protected get isMissingAnswers() {
    return this.conclusion?.missing_answers || false;
  }

  protected get footerClasses(): string {
    switch (this.conclusion?.status) {
      case 'success':
        return 'tw-bg-success-700';
      case 'warning':
        return 'tw-bg-orange-600';
      case 'error':
        return 'tw-bg-error-600';
      default:
        return 'tw-bg-gray-600';
    }
  }

  // #endregion

  // #region @Watchers
  // #endregion
}

// #region Interfaces
export interface Conclusion {
  title: string;
  description: string;
  show_questionaire: boolean;
  required_questions: Question[];
  show_second_questionaire: boolean;
  missing_answers: boolean;
  motivation: string;
  motivation_code: string;
  status: QuestionaireDialogConclusionTypeEnum;
  hide_question_keys: string;
}

// #endregion

type SidePanelType = 'pgv' | 'question' | 'article' | '';
