import { Model } from '@/models/Model';

export class OnHoldReason extends Model {
  protected $name = 'OnHoldReason';

  protected $endpoint = '/on-hold-reasons';

  protected $fillable: string[] = [];

  public id?: OnHoldReasonUuidEnum;

  public name?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}

export enum OnHoldReasonUuidEnum {
  BUITEN_EFFECTGEBIED = '1148ea04-e5db-4fa2-aaa3-b973c473e20d',
  STUDENTENPAND = 'd234e6ed-2609-4ea0-8152-fd6f00b9aac6',
  GEGEVENS_OPGEVRAAGD = '19bf4de9-689d-4399-bb54-712d7fb19579',
  WERKWIJZE_NOG_ONBEKEND = 'e91c310b-1fd7-43f6-b289-103f1ae838ba',
  VES_GEINTERESSEERD = '0aa10934-116c-4e5b-b5fb-33a94ef84f50',
  OVERIG = '2d770d47-c192-4d4a-80d3-51f236afab74',
}

export const onHoldReasonLabels: Record<OnHoldReasonUuidEnum, string> = {
  [OnHoldReasonUuidEnum.BUITEN_EFFECTGEBIED]: 'Buiten effectgebied',
  [OnHoldReasonUuidEnum.STUDENTENPAND]: 'Studentenpand',
  [OnHoldReasonUuidEnum.GEGEVENS_OPGEVRAAGD]: 'Gegevens opgevraagd',
  [OnHoldReasonUuidEnum.WERKWIJZE_NOG_ONBEKEND]: 'Werkwijze nog onbekend',
  [OnHoldReasonUuidEnum.VES_GEINTERESSEERD]: 'VES - Geinteresseerd',
  [OnHoldReasonUuidEnum.OVERIG]: 'Overig',
};

export type PlanningOnHoldReasonRpcPayloadSignature = 'planning:on-hold-reason';

export interface PlanningOnHoldReasonRpcPayloadBody {
  report: string;
  on_hold_reason: OnHoldReasonUuidEnum;
}

export interface PlanningOnHoldReasonRpcPayload {
  signature: PlanningOnHoldReasonRpcPayloadSignature,
  body: PlanningOnHoldReasonRpcPayloadBody;
}
