import { Model } from '@/models/Model';

export class LateReason extends Model {
  protected $name = 'LateReason';

  protected $endpoint = '/late-reasons';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;

  public category?: string;

  public uuid?: string;

  public label?: string;

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }
}
