import { Model } from '@/models/Model';

export class PlanningTarget extends Model {
  protected $name = 'PlanningTarget';

  protected $endpoint = '/planning-targets';

  protected $fillable: string[] = [];

  public agro?: number;

  public created_at?: string;

  public ends_at?: string;

  public id?: string;

  public mkb?: number;

  public monument?: number;

  public opnemersvariant?: number;

  public organization_id?: string;

  public regulier?: number;

  public junior?: number;

  public medior?: number;

  public senior?: number;

  public starts_at?: string;

  public type?: string;

  public updated_at?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
