var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.article
    ? _c("div", [
        _c("div", { staticClass: "library-content" }, [
          _c("h1", { staticClass: "library-title" }, [
            _vm._v(_vm._s(_vm.article.title))
          ]),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.sanitizeString(_vm.article.description))
            }
          })
        ]),
        _vm.article.content
          ? _c("div", { staticClass: "library-content" }, [
              _c("h2", { staticClass: "library-title" }, [
                _vm._v("Toelichting:")
              ]),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.sanitizeString(_vm.article.content))
                }
              })
            ])
          : _vm._e(),
        _vm.article.media && _vm.article.media.length
          ? _c(
              "div",
              { staticClass: "library-content" },
              [
                _c("h2", { staticClass: "library-title" }, [
                  _vm._v("Voorbeelden:")
                ]),
                _vm._l(_vm.article.media, function(media, index) {
                  return [
                    _c("MediaViewer", {
                      key: "images-" + index,
                      attrs: { media: media }
                    })
                  ]
                })
              ],
              2
            )
          : _vm._e(),
        _vm.article.default_answers && _vm.article.default_answers.length
          ? _c("div", { staticClass: "library-content" }, [
              _c("h2", { staticClass: "library-title" }, [
                _vm._v("Standaardmotiveringen:")
              ]),
              _c(
                "ul",
                [
                  _vm._l(_vm.article.default_answers, function(
                    defaultAnswer,
                    index
                  ) {
                    return [
                      _c("li", { key: "defaultAnswer-" + index }, [
                        _vm._v(" " + _vm._s(defaultAnswer.title) + " ")
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        _vm.article.repair_types && _vm.article.repair_types.length
          ? _c("div", { staticClass: "library-content" }, [
              _c("h2", { staticClass: "library-title" }, [
                _vm._v("Reparatiewijze")
              ]),
              _c(
                "ul",
                [
                  _vm._l(_vm.article.repair_types, function(repairType, index) {
                    return [
                      _c("li", { key: "repairType-" + index }, [
                        _vm._v(" " + _vm._s(repairType.name) + " ")
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        _vm.article.notes && _vm.article.notes.length
          ? _c("div", { staticClass: "library-content" }, [
              _c("h2", { staticClass: "library-title" }, [_vm._v("Diverse")]),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.sanitizeString(_vm.article.notes))
                }
              })
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }