var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional profile" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                class: { editing: _vm.isEditing },
                attrs: { row: "", wrap: "" }
              },
              [
                !_vm.isEditing &&
                _vm.allowEdit &&
                !_vm.syncing &&
                !_vm.report.is_offline
                  ? _c("div", { staticClass: "iconContainer" }, [
                      _c(
                        "span",
                        { staticClass: "iconContainer__edit" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "edit-icon",
                                              on: {
                                                click: function($event) {
                                                  _vm.isEditing = true
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("edit")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1471541473
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("Dossier skills en tags aanpassen")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.isEditing
                  ? _c(
                      "div",
                      { staticClass: "iconContainer" },
                      [
                        !_vm.isUpdating
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "cancel-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEditTags()
                                  }
                                }
                              },
                              [_vm._v("cancel")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-flex", [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Tags & Competenties")
                  ])
                ])
              ],
              1
            ),
            !_vm.isUpdating
              ? _c(
                  "v-layout",
                  { staticClass: "profileContainer", attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { sm12: "" } },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("Tags")]),
                        _c("ReportTags", {
                          attrs: {
                            autoSave: false,
                            reportId: _vm.report.uuid,
                            reportTags: _vm.tags,
                            isEditing: _vm.isEditing
                          },
                          on: { tagsCreated: _vm.saveReportTags }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { sm12: "" } },
                      [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Competenties")
                        ]),
                        _vm._l(_vm.report.skills, function(skill, index) {
                          return _c(
                            "v-chip",
                            {
                              key: index,
                              style:
                                "background-color: " +
                                skill.color +
                                " !important"
                            },
                            [_c("span", [_vm._v(_vm._s(skill.name))])]
                          )
                        })
                      ],
                      2
                    ),
                    _c("v-flex", { attrs: { sm12: "" } }, [
                      _c("div", { staticClass: "label" }, [_vm._v("Niveau")]),
                      _vm.report.level
                        ? _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userLevelLabels[_vm.report.level])
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isEditing
              ? _c(
                  "v-layout",
                  {
                    staticClass: "profileContainer",
                    attrs: { row: "", wrap: "", "align-center": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _vm.isUpdating
                          ? _c("LoaderCard", {
                              attrs: {
                                flat: "",
                                type: "spinner--center",
                                minHeight: "50px"
                              }
                            })
                          : _vm._e(),
                        !_vm.isUpdating
                          ? _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "primary",
                                  small: "true",
                                  outline: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.updateReport($event)
                                  }
                                }
                              },
                              [_vm._v(" Opslaan ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }