import { Model } from '@/models/Model';

export class Topic extends Model {
  protected $name = 'Topic';

  protected $endpoint = '/topics';

  protected $fillable: string[] = ['name', 'type'];

  public id?: string;

  public name?: string;

  public file?: any;

  public type?: string;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }
}
