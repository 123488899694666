import { Model } from '@/models/Model';

export class PlanningWeek extends Model {
  protected $primaryKey = 'id';

  protected $name = 'PlanningWeek';

  protected $endpoint = '/planning-weeks';

  protected $fillable: string[] = [

  ];

  public id?: string;

  public starts_at?: string;

  public ends_at?: string;

  public organization_id?: string;

  public report_type_id?: string;

  public name?: string;

  public hours?: number;

  public week?: number;

  public year?: number;

  public submitted_at?: any;

  public created_at?: string;

  public updated_at?: string;
}
