var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { "justify-end": "" } },
        [
          _vm.visible.title
            ? _c("v-flex", [
                _c("h2", { staticClass: "datatable__title" }, [
                  _vm._v(_vm._s(_vm.name.plural))
                ])
              ])
            : _vm._e(),
          !_vm.visible.hidePaginationTop &&
          _vm.visible.pagination &&
          _vm.total > 0 &&
          _vm.lastPage !== _vm.page &&
          _vm.visible.title
            ? _c(
                "v-flex",
                {
                  staticClass:
                    "pt-2 flex-starving text-xs-right paginationContainer"
                },
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.lastPage, "total-visible": 7 },
                    on: { input: _vm.paginationDebounce },
                    model: {
                      value: _vm.$page,
                      callback: function($$v) {
                        _vm.$page = $$v
                      },
                      expression: "$page"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "search--row",
          attrs: { "align-end": "", row: "", wrap: "" }
        },
        [
          _vm._l(_vm.options.filters, function(filter, index) {
            return _c(
              "v-flex",
              { key: index, staticClass: "dropdown grade flex-starving" },
              [
                filter.type === "model"
                  ? [
                      _c("SelectFilter", {
                        attrs: {
                          label: filter.label,
                          multiple: filter.multiple,
                          returnObject: false,
                          type: filter.value
                        },
                        model: {
                          value: _vm.selectedFilter[filter.value],
                          callback: function($$v) {
                            _vm.$set(_vm.selectedFilter, filter.value, $$v)
                          },
                          expression: "selectedFilter[filter.value]"
                        }
                      })
                    ]
                  : [
                      _c("v-select", {
                        staticClass: "datatable__filter",
                        attrs: {
                          "append-icon": "keyboard_arrow_down",
                          clearableis: "",
                          "hide-details": "",
                          "item-value": "value",
                          items: _vm.isFunction(filter.value)
                            ? filter.value()
                            : filter.value,
                          label: filter.type,
                          multiple: filter.multiple
                        },
                        model: {
                          value: _vm.selectedFilter[filter.type],
                          callback: function($$v) {
                            _vm.$set(_vm.selectedFilter, filter.type, $$v)
                          },
                          expression: "selectedFilter[filter.type]"
                        }
                      })
                    ],
                void 0
              ],
              2
            )
          }),
          _vm.visible.search
            ? _c(
                "v-flex",
                { staticClass: "tableMeta flex-starving" },
                [
                  _c("v-text-field", {
                    staticClass: "datatable__filter search--input",
                    attrs: {
                      "append-icon": "search",
                      clearable: "",
                      "hide-details": "",
                      placeholder: _vm.$tc("general.search", 2),
                      "single-line": ""
                    },
                    on: { input: _vm.searchDebounce },
                    model: {
                      value: _vm.searchInput,
                      callback: function($$v) {
                        _vm.searchInput = $$v
                      },
                      expression: "searchInput"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._t("filters"),
          _c("v-spacer"),
          !_vm.visible.hidePaginationTop &&
          _vm.visible.pagination &&
          _vm.total > 0 &&
          _vm.lastPage !== _vm.page &&
          !_vm.visible.title
            ? _c(
                "v-flex",
                {
                  staticClass: "flex-starving text-xs-right paginationContainer"
                },
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.lastPage, "total-visible": 7 },
                    on: { input: _vm.paginationDebounce },
                    model: {
                      value: _vm.$page,
                      callback: function($$v) {
                        _vm.$page = $$v
                      },
                      expression: "$page"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.$slots.options
            ? _c(
                "v-flex",
                { staticClass: "flex-starving" },
                [_vm._t("options")],
                2
              )
            : _vm._e(),
          _vm.options.add && _vm.options.showAdd
            ? _c(
                "v-flex",
                { staticClass: "flex-starving" },
                [
                  _vm.isFunction(_vm.options.add)
                    ? _c(
                        "MiButton",
                        {
                          attrs: {
                            background: "true",
                            color: "secondary",
                            icon: "add_circle",
                            outline: "true",
                            round: "true"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.options.add()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("user.add", { item: _vm.name.singular })
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm.showLoaderCard ? _c("LoaderCard") : _vm._e(),
      !_vm.showLoaderCard
        ? _c(
            "v-data-table",
            {
              staticClass: "elevation-1",
              class: { compact: _vm.compact },
              attrs: {
                headers: _vm.TableHeader,
                "hide-actions": "",
                "item-key": _vm.itemKey,
                items: _vm.items,
                loading: _vm.isLoading,
                pagination: _vm.pagination,
                "select-all": _vm.visible.checkboxes,
                "total-items": _vm.total
              },
              on: {
                "update:pagination": function($event) {
                  _vm.pagination = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "headers",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          [
                            _vm.visible.checkboxes
                              ? _c(
                                  "th",
                                  { staticClass: "checkbox-column" },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "hide-details": "",
                                        indeterminate: props.indeterminate,
                                        "input-value": props.all,
                                        primary: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.toggleAll($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(props.headers, function(header, i) {
                              return [
                                _vm.isVisible(
                                  props.headers[i].visible,
                                  props.item
                                )
                                  ? _c(
                                      "th",
                                      {
                                        key: "column-" + i,
                                        class: {
                                          "avatar-column":
                                            header.value === "avatar",
                                          "actions-column":
                                            header.value === "$actions",
                                          "sortable-column": _vm.isSortable(
                                            header
                                          ),
                                          asc:
                                            _vm.isSortable(header) &&
                                            header.sortable.order === "ASC",
                                          desc:
                                            _vm.isSortable(header) &&
                                            header.sortable.order === "DESC"
                                        },
                                        style: {
                                          width: header.width
                                            ? header.width
                                            : ""
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: _vm.getExtraClass(header),
                                            on: {
                                              click: function($event) {
                                                return _vm.changeSorting(
                                                  header.sortable
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(header.text) + " "
                                            ),
                                            header.information
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "informationTooltip",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openInformationDialog(
                                                          header.information
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              header.information
                                                                .tooltip
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isSortable(header)
                                              ? _c(
                                                  "v-icon",
                                                  { staticClass: "sortIcon" },
                                                  [
                                                    _vm._v(
                                                      "keyboard_arrow_down"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        header.value === "$actions" &&
                                        _vm.editable &&
                                        !_vm.editMode
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "text-right",
                                                on: {
                                                  click: function($event) {
                                                    _vm.editMode = !_vm.editMode
                                                  }
                                                }
                                              },
                                              [_c("v-icon", [_vm._v("edit")])],
                                              1
                                            )
                                          : _vm._e(),
                                        header.value === "$actions" &&
                                        _vm.editable &&
                                        _vm.editMode
                                          ? _c(
                                              "div",
                                              { staticClass: "actions" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "delete action",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.cancelEdit(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("cancel")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "save action",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.update()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("check")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    }
                  },
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          {
                            attrs: {
                              active: _vm.visible.checkboxes && props.selected
                            },
                            on: {
                              click: function($event) {
                                return _vm.onClickedRow(props.item)
                              },
                              mouseenter: function($event) {
                                return _vm.onMouseEnterTableRow(props.item)
                              }
                            }
                          },
                          [
                            _vm.visible.checkboxes
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "checkbox-column",
                                    on: {
                                      click: function($event) {
                                        props.selected = !props.selected
                                      }
                                    }
                                  },
                                  [
                                    _vm.visible.checkboxPerRow(props.item)
                                      ? _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "",
                                            "input-value": props.selected,
                                            primary: ""
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(_vm.TableHeader, function(header, index) {
                              return [
                                _vm.isVisible(
                                  _vm.TableHeader[index].visible,
                                  props.item
                                )
                                  ? _c(
                                      "td",
                                      {
                                        key: index,
                                        class: {
                                          "avatar-column":
                                            header.value === "avatar",
                                          "action-column":
                                            header.value === "$actions"
                                        }
                                      },
                                      [
                                        header.editable
                                          ? [
                                              header.value !== "avatar" &&
                                              header.value !== "$actions" &&
                                              header.type === "date"
                                                ? _c(
                                                    "v-dialog",
                                                    {
                                                      ref: "dialog",
                                                      refInFor: true,
                                                      attrs: {
                                                        "full-width": "",
                                                        lazy: "",
                                                        persistent: "",
                                                        width: "290px"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dateModals[
                                                            (props.item.id
                                                              ? props.item.id
                                                              : props.item
                                                                  .uuid) +
                                                              "_" +
                                                              header.value
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dateModals,
                                                            (props.item.id
                                                              ? props.item.id
                                                              : props.item
                                                                  .uuid) +
                                                              "_" +
                                                              header.value,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dateModals[`${props.item.id ? props.item.id : props.item.uuid}_${header.value}`]"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          slot: "activator",
                                                          "prepend-icon":
                                                            "event",
                                                          readonly: ""
                                                        },
                                                        slot: "activator",
                                                        model: {
                                                          value:
                                                            props.item[
                                                              header.value
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              props.item,
                                                              header.value,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "props.item[header.value]"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-date-picker",
                                                        {
                                                          attrs: {
                                                            "first-day-of-week":
                                                              "1",
                                                            max: _vm.maxDate(
                                                              header
                                                            ),
                                                            min: _vm.minDate(
                                                              header
                                                            ),
                                                            scrollable: ""
                                                          },
                                                          model: {
                                                            value:
                                                              props.item[
                                                                header.value
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.item,
                                                                header.value,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.item[header.value]"
                                                          }
                                                        },
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                flat: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.resetDateField(
                                                                    props.item,
                                                                    header.value
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                flat: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.dateModals[
                                                                    (props.item
                                                                      .id
                                                                      ? props
                                                                          .item
                                                                          .id
                                                                      : props
                                                                          .item
                                                                          .uuid) +
                                                                      "_" +
                                                                      header.value
                                                                  ] = false
                                                                  _vm.setItemChanged(
                                                                    props.item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v(" OK ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              header.value !== "avatar" &&
                                              header.value !== "$actions" &&
                                              header.type !== "date"
                                                ? _c("v-text-field", {
                                                    staticClass:
                                                      "input--bordered",
                                                    staticStyle: {
                                                      width: "200px"
                                                    },
                                                    attrs: {
                                                      "hide-details": true,
                                                      value: _vm.parseValue(
                                                        props.item,
                                                        header.value
                                                      )
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.updateField(
                                                          props.item,
                                                          header,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          : [
                                              header.value === "$actions"
                                                ? _vm._l(
                                                    _vm.tableActions,
                                                    function(action, index) {
                                                      return _c(
                                                        "span",
                                                        { key: index },
                                                        [
                                                          _vm.isVisible(
                                                            _vm.tableActions[
                                                              index
                                                            ].visible,
                                                            props.item
                                                          )
                                                            ? [
                                                                _vm.isFunction(
                                                                  _vm
                                                                    .tableActions[
                                                                    index
                                                                  ].tooltip
                                                                )
                                                                  ? [
                                                                      !_vm.tableActions[
                                                                        index
                                                                      ].tooltip(
                                                                        props.item
                                                                      ) &&
                                                                      !_vm.tableActions[
                                                                        index
                                                                      ].tooltip(
                                                                        props.item
                                                                      ).length
                                                                        ? [
                                                                            _vm.isFunction(
                                                                              _vm
                                                                                .tableActions[
                                                                                index
                                                                              ]
                                                                                .action
                                                                            ) &&
                                                                            action.type !==
                                                                              "download" &&
                                                                            _vm.visibleForRole(
                                                                              _vm
                                                                                .tableActions[
                                                                                index
                                                                              ]
                                                                                .roles
                                                                            )
                                                                              ? _c(
                                                                                  "a",
                                                                                  {
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return _vm.handleAction(
                                                                                          _vm
                                                                                            .tableActions[
                                                                                            index
                                                                                          ]
                                                                                            .action,
                                                                                          props.item
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        domProps: {
                                                                                          innerHTML: _vm._s(
                                                                                            _vm.tableActions[
                                                                                              index
                                                                                            ].icon(
                                                                                              props.item
                                                                                            )
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  bottom:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "activator",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var on =
                                                                                          ref.on
                                                                                        return [
                                                                                          _vm.isFunction(
                                                                                            _vm
                                                                                              .tableActions[
                                                                                              index
                                                                                            ]
                                                                                              .action
                                                                                          ) &&
                                                                                          action.type !==
                                                                                            "download" &&
                                                                                          _vm.visibleForRole(
                                                                                            _vm
                                                                                              .tableActions[
                                                                                              index
                                                                                            ]
                                                                                              .roles
                                                                                          )
                                                                                            ? _c(
                                                                                                "a",
                                                                                                _vm._g(
                                                                                                  {
                                                                                                    on: {
                                                                                                      click: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        return _vm.handleAction(
                                                                                                          _vm
                                                                                                            .tableActions[
                                                                                                            index
                                                                                                          ]
                                                                                                            .action,
                                                                                                          props.item
                                                                                                        )
                                                                                                      }
                                                                                                    }
                                                                                                  },
                                                                                                  on
                                                                                                ),
                                                                                                [
                                                                                                  _vm.isFunction(
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .icon
                                                                                                  )
                                                                                                    ? _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          domProps: {
                                                                                                            innerHTML: _vm._s(
                                                                                                              _vm.tableActions[
                                                                                                                index
                                                                                                              ].icon(
                                                                                                                props.item
                                                                                                              )
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    : _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          class: {
                                                                                                            delete:
                                                                                                              _vm
                                                                                                                .tableActions[
                                                                                                                index
                                                                                                              ]
                                                                                                                .type ===
                                                                                                              "delete",
                                                                                                            view:
                                                                                                              _vm
                                                                                                                .tableActions[
                                                                                                                index
                                                                                                              ]
                                                                                                                .type ===
                                                                                                              "view",
                                                                                                            "success-icon":
                                                                                                              _vm
                                                                                                                .tableActions[
                                                                                                                index
                                                                                                              ]
                                                                                                                .type ===
                                                                                                              "success",
                                                                                                            "alert-icon":
                                                                                                              _vm
                                                                                                                .tableActions[
                                                                                                                index
                                                                                                              ]
                                                                                                                .type ===
                                                                                                              "alert"
                                                                                                          }
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm
                                                                                                                  .tableActions[
                                                                                                                  index
                                                                                                                ]
                                                                                                                  .icon
                                                                                                              ) +
                                                                                                              " "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                )
                                                                              },
                                                                              [
                                                                                _vm
                                                                                  .tableActions[
                                                                                  index
                                                                                ]
                                                                                  .tooltip
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        domProps: {
                                                                                          innerHTML: _vm._s(
                                                                                            _vm.tableActions[
                                                                                              index
                                                                                            ].tooltip(
                                                                                              props.item
                                                                                            )
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            )
                                                                          ]
                                                                    ]
                                                                  : !_vm.isFunction(
                                                                      _vm
                                                                        .tableActions[
                                                                        index
                                                                      ].tooltip
                                                                    ) &&
                                                                    _vm
                                                                      .tableActions[
                                                                      index
                                                                    ].tooltip
                                                                  ? [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    !_vm.isFunction(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .action
                                                                                    ) &&
                                                                                    _vm.visibleForRole(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .roles
                                                                                    )
                                                                                      ? _c(
                                                                                          "router-link",
                                                                                          {
                                                                                            attrs: {
                                                                                              to: _vm.tableActions[
                                                                                                index
                                                                                              ].action.replace(
                                                                                                "{id}",
                                                                                                props
                                                                                                  .item
                                                                                                  .id
                                                                                                  ? props
                                                                                                      .item
                                                                                                      .id
                                                                                                  : props
                                                                                                      .item
                                                                                                      .uuid
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  class: {
                                                                                                    delete:
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .type ===
                                                                                                      "delete",
                                                                                                    view:
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .type ===
                                                                                                      "view",
                                                                                                    "success-icon":
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .type ===
                                                                                                      "success",
                                                                                                    "alert-icon":
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .type ===
                                                                                                      "alert"
                                                                                                  }
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .icon
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm.isFunction(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .action
                                                                                    ) &&
                                                                                    action.type !==
                                                                                      "download" &&
                                                                                    _vm.visibleForRole(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .roles
                                                                                    )
                                                                                      ? _c(
                                                                                          "a",
                                                                                          _vm._g(
                                                                                            {
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  return _vm.handleAction(
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .action,
                                                                                                    props.item
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm.isFunction(
                                                                                              _vm
                                                                                                .tableActions[
                                                                                                index
                                                                                              ]
                                                                                                .icon
                                                                                            )
                                                                                              ? _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    domProps: {
                                                                                                      innerHTML: _vm._s(
                                                                                                        _vm.tableActions[
                                                                                                          index
                                                                                                        ].icon(
                                                                                                          props.item
                                                                                                        )
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              : _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    class: {
                                                                                                      delete:
                                                                                                        _vm
                                                                                                          .tableActions[
                                                                                                          index
                                                                                                        ]
                                                                                                          .type ===
                                                                                                        "delete",
                                                                                                      view:
                                                                                                        _vm
                                                                                                          .tableActions[
                                                                                                          index
                                                                                                        ]
                                                                                                          .type ===
                                                                                                        "view",
                                                                                                      "success-icon":
                                                                                                        _vm
                                                                                                          .tableActions[
                                                                                                          index
                                                                                                        ]
                                                                                                          .type ===
                                                                                                        "success",
                                                                                                      "alert-icon":
                                                                                                        _vm
                                                                                                          .tableActions[
                                                                                                          index
                                                                                                        ]
                                                                                                          .type ===
                                                                                                        "alert"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .tableActions[
                                                                                                            index
                                                                                                          ]
                                                                                                            .icon
                                                                                                        ) +
                                                                                                        " "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    action.type ===
                                                                                      "download" &&
                                                                                    _vm.visibleForRole(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .roles
                                                                                    )
                                                                                      ? _c(
                                                                                          "a",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs: {
                                                                                                download:
                                                                                                  "",
                                                                                                href: _vm.handleDownloadLink(
                                                                                                  _vm
                                                                                                    .tableActions[
                                                                                                    index
                                                                                                  ]
                                                                                                    .action,
                                                                                                  props.item
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                class: {
                                                                                                  delete:
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .type ===
                                                                                                    "delete",
                                                                                                  view:
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .type ===
                                                                                                    "view",
                                                                                                  "success-icon":
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .type ===
                                                                                                    "success",
                                                                                                  "alert-icon":
                                                                                                    _vm
                                                                                                      .tableActions[
                                                                                                      index
                                                                                                    ]
                                                                                                      .type ===
                                                                                                    "alert"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .tableActions[
                                                                                                        index
                                                                                                      ]
                                                                                                        .icon
                                                                                                    ) +
                                                                                                    " "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .tableActions[
                                                                            index
                                                                          ]
                                                                            .tooltip
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .tooltip
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : [
                                                                      !_vm.isFunction(
                                                                        _vm
                                                                          .tableActions[
                                                                          index
                                                                        ].action
                                                                      ) &&
                                                                      _vm.visibleForRole(
                                                                        _vm
                                                                          .tableActions[
                                                                          index
                                                                        ].roles
                                                                      )
                                                                        ? _c(
                                                                            "router-link",
                                                                            {
                                                                              attrs: {
                                                                                to: _vm.tableActions[
                                                                                  index
                                                                                ].action.replace(
                                                                                  "{id}",
                                                                                  props
                                                                                    .item
                                                                                    .id
                                                                                    ? props
                                                                                        .item
                                                                                        .id
                                                                                    : props
                                                                                        .item
                                                                                        .uuid
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  class: {
                                                                                    delete:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "delete",
                                                                                    view:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "view",
                                                                                    "success-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "success",
                                                                                    "alert-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "alert"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .tableActions[
                                                                                          index
                                                                                        ]
                                                                                          .icon
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.isFunction(
                                                                        _vm
                                                                          .tableActions[
                                                                          index
                                                                        ].action
                                                                      ) &&
                                                                      action.type !==
                                                                        "download" &&
                                                                      _vm.visibleForRole(
                                                                        _vm
                                                                          .tableActions[
                                                                          index
                                                                        ].roles
                                                                      )
                                                                        ? _c(
                                                                            "a",
                                                                            {
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.handleAction(
                                                                                    _vm
                                                                                      .tableActions[
                                                                                      index
                                                                                    ]
                                                                                      .action,
                                                                                    props.item
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  class: {
                                                                                    delete:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "delete",
                                                                                    view:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "view",
                                                                                    "success-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "success",
                                                                                    "alert-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "alert"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .tableActions[
                                                                                          index
                                                                                        ]
                                                                                          .icon
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      action.type ===
                                                                        "download" &&
                                                                      _vm.visibleForRole(
                                                                        _vm
                                                                          .tableActions[
                                                                          index
                                                                        ].roles
                                                                      )
                                                                        ? _c(
                                                                            "a",
                                                                            {
                                                                              attrs: {
                                                                                download:
                                                                                  "",
                                                                                href: _vm.handleDownloadLink(
                                                                                  _vm
                                                                                    .tableActions[
                                                                                    index
                                                                                  ]
                                                                                    .action,
                                                                                  props.item
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  class: {
                                                                                    delete:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "delete",
                                                                                    view:
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "view",
                                                                                    "success-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "success",
                                                                                    "alert-icon":
                                                                                      _vm
                                                                                        .tableActions[
                                                                                        index
                                                                                      ]
                                                                                        .type ===
                                                                                      "alert"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .tableActions[
                                                                                          index
                                                                                        ]
                                                                                          .icon
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                              ]
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                : [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: _vm.getExtraClass(
                                                          header
                                                        )
                                                      },
                                                      [
                                                        header.transform
                                                          ? [
                                                              header.tooltip
                                                                ? [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  !header.action
                                                                                    ? _c(
                                                                                        "span",
                                                                                        _vm._g(
                                                                                          {
                                                                                            domProps: {
                                                                                              innerHTML: _vm._s(
                                                                                                _vm.sanitizeString(
                                                                                                  header.transform(
                                                                                                    props
                                                                                                      .item[
                                                                                                      header
                                                                                                        .value
                                                                                                    ],
                                                                                                    props.item
                                                                                                  )
                                                                                                )
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        )
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        !_vm.isString(
                                                                          header.tooltip
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      header.tooltip(
                                                                                        props
                                                                                          .item[
                                                                                          header
                                                                                            .value
                                                                                        ],
                                                                                        props.item
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    header.tooltip
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  ]
                                                                : _vm._e(),
                                                              !header.tooltip
                                                                ? [
                                                                    !header.action
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.sanitizeString(
                                                                                  header.transform(
                                                                                    props
                                                                                      .item[
                                                                                      header
                                                                                        .value
                                                                                    ],
                                                                                    props.item
                                                                                  )
                                                                                )
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                : _vm._e(),
                                                              header.action
                                                                ? [
                                                                    !_vm.isFunction(
                                                                      header.action
                                                                    )
                                                                      ? _c(
                                                                          "ATableLink",
                                                                          {
                                                                            attrs: {
                                                                              to: header.action.replace(
                                                                                "{id}",
                                                                                props
                                                                                  .item
                                                                                  .id
                                                                                  ? props
                                                                                      .item
                                                                                      .id
                                                                                  : props
                                                                                      .item
                                                                                      .uuid
                                                                              )
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                domProps: {
                                                                                  innerHTML: _vm._s(
                                                                                    _vm.sanitizeString(
                                                                                      header.transform(
                                                                                        props
                                                                                          .item[
                                                                                          header
                                                                                            .value
                                                                                        ],
                                                                                        props.item
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isFunction(
                                                                      header.action
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              header.class &&
                                                                              _vm.isFunction(
                                                                                header.class
                                                                              )
                                                                                ? header.class(
                                                                                    props.item
                                                                                  )
                                                                                : "action--span",
                                                                            domProps: {
                                                                              innerHTML: _vm._s(
                                                                                _vm.sanitizeString(
                                                                                  header.transform(
                                                                                    props
                                                                                      .item[
                                                                                      header
                                                                                        .value
                                                                                    ],
                                                                                    props.item
                                                                                  )
                                                                                )
                                                                              )
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return header.action(
                                                                                  props.item
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                : _vm._e()
                                                            ]
                                                          : header.action &&
                                                            !_vm.isFunction(
                                                              header.action
                                                            )
                                                          ? _c("ATableLink", {
                                                              attrs: {
                                                                to: header.action.replace(
                                                                  "{id}",
                                                                  props.item.id
                                                                    ? props.item
                                                                        .id
                                                                    : props.item
                                                                        .uuid
                                                                ),
                                                                value:
                                                                  props.item[
                                                                    header.value
                                                                  ]
                                                              }
                                                            })
                                                          : header.action &&
                                                            _vm.isFunction(
                                                              header.action
                                                            )
                                                          ? [
                                                              header.value
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return header.action(
                                                                            props.item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          props
                                                                            .item[
                                                                            header
                                                                              .value
                                                                          ]
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item[
                                                                    header.value
                                                                  ]
                                                                )
                                                              )
                                                            ])
                                                      ],
                                                      2
                                                    )
                                                  ]
                                            ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                6192652
              ),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _vm.options.showTotals &&
              _vm.totals &&
              _vm.totals.length &&
              _vm.items.length
                ? _c("template", { slot: "footer" }, [
                    _c(
                      "tr",
                      { staticClass: "footer__totals" },
                      [
                        _vm.visible.checkboxes ? _c("td") : _vm._e(),
                        _vm._l(_vm.TableHeader, function(header, headerIndex) {
                          return [
                            _vm.isVisible(header.visible, header)
                              ? _c("td", { key: headerIndex }, [
                                  headerIndex === 0
                                    ? _c(
                                        "span",
                                        { staticClass: "totals__title" },
                                        [_vm._v("Totalen")]
                                      )
                                    : header.value !== "$actions"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-bold",
                                          class: _vm.getExtraClass(header)
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getTotalValue(header.value)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _c("template", { slot: "no-data" }, [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("table.noData")))])
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-xs-left paginationContainer tableFooter" },
        [
          _c(
            "v-layout",
            { attrs: { "justify-space-between": "", row: "" } },
            [
              _vm.visible.limit
                ? _c(
                    "v-flex",
                    { staticClass: "mt-2 tableMeta", attrs: { shrink: "" } },
                    [
                      _vm.visible.limit
                        ? _c("v-select", {
                            staticClass: "datatable__filter",
                            attrs: {
                              "append-icon": "keyboard_arrow_down",
                              "hide-details": "",
                              items: [5, 10, 25, 50, 100],
                              label:
                                "" +
                                _vm.$t("table.perPage", {
                                  total: _vm.total,
                                  name:
                                    _vm.total === 1
                                      ? _vm.name.singular
                                      : _vm.name.plural
                                })
                            },
                            model: {
                              value: _vm.limitSelected,
                              callback: function($$v) {
                                _vm.limitSelected = $$v
                              },
                              expression: "limitSelected"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visible.total
                ? _c("v-flex", { staticClass: "databaseTotal start" }, [
                    _c("span", { staticClass: "search--total" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.from) +
                          " - " +
                          _vm._s(_vm.to) +
                          " van " +
                          _vm._s(_vm.total) +
                          " "
                      )
                    ])
                  ])
                : _vm._e(),
              _c("v-flex", { staticClass: "flex-starving" }, [
                !_vm.visible.hidePaginationBottom &&
                _vm.visible.pagination &&
                _vm.total > 0 &&
                _vm.lastPage !== _vm.page
                  ? _c(
                      "div",
                      { staticClass: "pt-2 text-xs-right paginationContainer" },
                      [
                        _c("v-pagination", {
                          attrs: { length: _vm.lastPage, "total-visible": 7 },
                          on: { input: _vm.paginationDebounce },
                          model: {
                            value: _vm.$page,
                            callback: function($$v) {
                              _vm.$page = $$v
                            },
                            expression: "$page"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.currentInformation
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", persistent: true },
              model: {
                value: _vm.informationDialogOpen,
                callback: function($$v) {
                  _vm.informationDialogOpen = $$v
                },
                expression: "informationDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel__header",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__title",
                              attrs: { sm11: "" }
                            },
                            [
                              _c(
                                "h2",
                                [
                                  _c("v-icon", { staticClass: "infoIcon" }, [
                                    _vm._v("info")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentInformation.title) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__close",
                              attrs: { sm1: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.informationDialogOpen = false
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.currentInformation.content, function(information) {
                    return _c("div", { key: information.title }, [
                      information.header
                        ? _c("h2", { staticClass: "informationTitle" }, [
                            _vm._v(_vm._s(information.header))
                          ])
                        : _vm._e(),
                      information.text
                        ? _c("p", [_vm._v(_vm._s(information.text.text))])
                        : _vm._e(),
                      information.text.bulletPoints
                        ? _c(
                            "ul",
                            { staticClass: "bulletPointList" },
                            _vm._l(information.text.bulletPoints, function(
                              bulletPoint
                            ) {
                              return _c("li", { key: bulletPoint }, [
                                _vm._v(_vm._s(bulletPoint))
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }