var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.options
        ? _c(
            "v-card",
            { staticClass: "atabix-message-dialog", class: _vm.borderColor },
            [
              _c(
                "v-card-text",
                { staticClass: "atabix-message-dialog__content-container" },
                [
                  _c("AMessageDialogIcon", {
                    attrs: { type: _vm.options.type }
                  }),
                  _c("h2", { staticClass: "atabix-message-dialog__header" }, [
                    _vm._v(_vm._s(_vm.dialogTitle))
                  ]),
                  _c("div", {
                    staticClass: "atabix-message-dialog__content",
                    domProps: { innerHTML: _vm._s(_vm.dialogText) }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "atabix-message-dialog__actions",
                      class: [
                        _vm.hasCancelButton
                          ? "tw-justify-between"
                          : "tw-justify-center"
                      ]
                    },
                    [
                      _vm.hasCancelButton
                        ? [
                            !_vm.isProcessing
                              ? _c(
                                  "AButton",
                                  {
                                    class: _vm.cancelButtonClass,
                                    attrs: {
                                      "prepend-inner-icon": _vm.cancelButtonIcon
                                    },
                                    on: { click: _vm.cancel }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.cancelButtonText) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.hasConfirmButton
                        ? [
                            !_vm.isProcessing
                              ? _c(
                                  "AButton",
                                  {
                                    class: _vm.confirmButtonClass,
                                    attrs: {
                                      "prepend-inner-icon":
                                        _vm.confirmButtonIcon
                                    },
                                    on: { click: _vm.confirm }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.confirmButtonText) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.isProcessing
                              ? _c("ALoader", { attrs: { size: "48" } })
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm.hasCloseButton
                        ? [
                            !_vm.isProcessing
                              ? _c(
                                  "AButton",
                                  {
                                    class: _vm.closeButtonClass,
                                    attrs: {
                                      "prepend-inner-icon": _vm.closeButtonIcon
                                    },
                                    on: { click: _vm.close }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.closeButtonText) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }