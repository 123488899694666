import { Model } from '@/models/Model';
import { TextboxQuestion } from '@/models/question-models/question-textbox';
import { HeaderQuestion } from '@/models/question-models/question-header';
import { AlertQuestion } from '@/models/question-models/question-alert';
import { DateQuestion } from '@/models/question-models/question-date';
import { YearQuestion } from '@/models/question-models/question-year';
import { RadioQuestion } from '@/models/question-models/question-radio';
import { TextareaQuestion } from '@/models/question-models/question-textarea';
import { CheckboxQuestion } from '@/models/question-models/question-checkbox';

export class Question extends Model {
  protected $name = 'Question';

  protected $endpoint = '/questions';

  protected $fillable: string[] = [];

  public created_at?: string;

  public answer?: any;

  public form_type?: string;

  public is_editable?: string;

  public is_required?: string;

  public is_assumable?: boolean;

  public key!: string;

  public links?: any;

  public options?: Option[];

  public question_option_id?: string;

  public section?: number;

  public sort_order?: string;

  public auto_answer?: string;

  public parent?: {
    question_uuid: string;
    question_option_uuid: string;
  };

  public option?: Question;

  public label?: string;

  public tab?: number;

  public type?: string;

  public updated_at?: string;

  public uuid?: string;

  public value?: string;

  public visible?: boolean;

  public is_formattable?: boolean;

  public is_field_question?: boolean;

  public disabled?: boolean;

  public library_article_id?: string | null;

  public meta?: {[key: string]: string | number};

  protected parseQuestion(attributes: any) {
    switch (attributes.type) {
      case 'textbox':
        return new TextboxQuestion(attributes);
      case 'header':
        return new HeaderQuestion(attributes);
      case 'alert':
        return new AlertQuestion(attributes);
      case 'date':
        return new DateQuestion(attributes);
      case 'year':
        return new YearQuestion(attributes);
      case 'radio':
        return new RadioQuestion(attributes);
      case 'textarea':
        return new TextareaQuestion(attributes);
      case 'checkbox':
        return new CheckboxQuestion(attributes);
      default:
        break;
    }
  }

  public parseQuestions(questions: any) {
    const allQuestions: any[] = [];
    questions.forEach((question: any) => {
      if (! question) { return; }
      allQuestions.push(this.parseQuestion(question));
      question.options.forEach((option: any) => {
        option.questions = this.parseQuestions(option.questions);
      });
    });
    return allQuestions;
  }

  public flatten() {
    const url = this.$endpoint;

    const headers = {
      Accept: 'application/json+flatten',
    };

    return this.request('get', url, {}, true, headers);
  }

  public includeFlattenHeader() {
    this.$headers = {
      Accept: 'application/json+flatten',
    };

    return this;
  }
}

export enum QuestionsSection {
  WERKVOORBEREIDING = 100,
  PLANNING = 110,
  BUREAU_RAPPORT = 120,
  BUREAU_SCHADE = 121,
}

export interface Option {
  created_at?: string;
  key?: string;
  label?: string;
  links?: any;
  questions?: Question[]
  sort_order?: string;
  type?: string;
  updated_at?: string;
  uuid?: string;
  value?: string;
}

export interface CreateQuestionPayload {
  key?: string;
  label?: string;
  parent?: string;
  sort_order?: string;
  type?: string;
  report_type_id?: string;
  section?: string;
  form_type?: string;
}

export interface UpdateQuestionPayload {
  key?: string;
  label?: string;
  parent?: string;
  sort_order?: string;
  type?: string;
  report_type_id?: string;
  is_formattable?: boolean;
  is_assumable?: boolean;
  is_field_question?: boolean;
  section?: number;
  form_type?: string;
  options?: UpdateQuestionOption[],
}

export interface UpdateQuestionOption {
  uuid?: string;
  label?: string;
  key?: string;
  sort_order?: number;
}
