import { Model } from '@/models/Model';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Role } from '@/models/Role';

export class Permission extends Model {
  protected $name = 'Permission';

  protected $endpoint = '/permissions';

  protected $fillable: string[] = [
    'name',
    'slug',
    'description',
  ];

  public id!: string;

  public slug!: PermissionSlug;

  public name!: string;

  public description?: string;

  public roles?: Role[];

  public category?: string;

  public value?: boolean;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public attachPermissionToRole(roleId: string): Promise<any> {
    const url = `/roles/${roleId}/relationship/permission/${this.resolvePrimaryKey()}`;

    return this.request('post', url);
  }

  public detachPermissionFromRole(roleId: string): Promise<any> {
    const url = `/roles/${roleId}/relationship/permission/${this.resolvePrimaryKey()}`;

    return this.request('delete', url);
  }
}

export interface CreatePermissionPayload {
  name?: string;
  slug?: string;
  description?: string;
}

export interface UpdatePermissionPayload {
  name?: string;
  slug?: string;
  description?: string;
}
