var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "approval-dialog" },
        [
          _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
              })
            : [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [_c("v-icon", [_vm._v("warning")])],
                            1
                          )
                        ]),
                        _c("v-flex", [
                          _c("h2", [
                            _vm._v(
                              "Weet u zeker dat u de beschikbaarheid templates wilt toepassen?"
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "approvalDialogContainer",
                        attrs: { row: "", wrap: "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { sm12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label:
                                  "Bestaande beschikbaarheid overschrijven",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.overrideTemplates,
                                callback: function($$v) {
                                  _vm.overrideTemplates = $$v
                                },
                                expression: "overrideTemplates"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.users && !_vm.isExpert
                          ? _c(
                              "v-flex",
                              { attrs: { sm12: "", "py-4": "" } },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.users,
                                    "item-value": "uuid",
                                    "item-text": "name",
                                    label: "Medewerker",
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.selectedUsers,
                                    callback: function($$v) {
                                      _vm.selectedUsers = $$v
                                    },
                                    expression: "selectedUsers"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "buttonContainer" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "justify-space-between": "", row: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "text-light", outline: "true" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.close($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("general.cancel")) + " "
                            )
                          ]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "float--right",
                            attrs: { color: "success" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.applyWeekTemplate($event)
                              }
                            }
                          },
                          [_vm._v(" Template toepassen ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }