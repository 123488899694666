var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "justify-space-between": "",
                "align-center": ""
              }
            },
            [
              _c("v-flex", [_vm._v(_vm._s(_vm.question.label))]),
              _vm.instruction || _vm.stringInputInstruction
                ? _c(
                    "v-flex",
                    { staticClass: "info-icon", attrs: { shrink: "" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openReadMoreDialog()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("info_outline")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            902631435
                          )
                        },
                        [_c("span", [_vm._v("klik voor meer info")])]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { staticClass: "flex-starving my-2", attrs: { shrink: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-space-between": "",
                        "align-center": ""
                      }
                    },
                    [
                      _vm.question.is_assumable
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "assumable__checkbox",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "pa-0",
                                attrs: {
                                  value: _vm.assumedValue,
                                  "hide-details": "",
                                  ripple: false,
                                  color: "#a09b1b",
                                  label: "is het een aanname?"
                                },
                                on: { change: _vm.toggleAssumable }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "mr-1 link",
                                              on: {
                                                click: function($event) {
                                                  _vm.isShowingDefaultAnwsersDialog = true
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("list")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2044954357
                              )
                            },
                            [_c("span", [_vm._v("Standaard teksten")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          !_vm.question.is_formattable
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-1",
                                  on: { click: _vm.toggleEditMode }
                                },
                                [_vm._v("expand_less")]
                              )
                            : _vm._e(),
                          _vm.question.library_article_id &&
                          _vm.$store.state.isBETA
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-1 link",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onClickInfo()
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("info")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3769433805
                                  )
                                },
                                [_c("span", [_vm._v("Open artikel")])]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.question.is_formattable
                    ? [
                        _vm.question
                          ? _c("QuillEditor", {
                              attrs: { disabled: _vm.disabled },
                              on: {
                                blur: _vm.answerQuestionFromQuill,
                                change: _vm.answerDebounceFromQuill
                              },
                              model: {
                                value: _vm.stringInputText,
                                callback: function($$v) {
                                  _vm.stringInputText = $$v
                                },
                                expression: "stringInputText"
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  !_vm.question.is_formattable
                    ? [
                        _c(
                          "v-textarea",
                          {
                            attrs: {
                              name: _vm.question.key,
                              disabled: _vm.question.disabled || _vm.disabled,
                              required: _vm.question.required,
                              color: "secondary"
                            },
                            on: {
                              change: function($event) {
                                return _vm.answerQuestion(_vm.stringInputText)
                              },
                              keyup: function($event) {
                                return _vm.answerDebounce(_vm.stringInputText)
                              }
                            },
                            model: {
                              value: _vm.stringInputText,
                              callback: function($$v) {
                                _vm.stringInputText = $$v
                              },
                              expression: "stringInputText"
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "append-outer" },
                              [
                                _vm.question.library_article_id &&
                                _vm.$store.state.isBETA
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mr-1 link",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onClickInfo()
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("info")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3769433805
                                        )
                                      },
                                      [_c("span", [_vm._v("Open artikel")])]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.isShowingDefaultAnwsersDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "70vw",
                    "content-class": "default-answer-dialog"
                  },
                  model: {
                    value: _vm.isShowingDefaultAnwsersDialog,
                    callback: function($$v) {
                      _vm.isShowingDefaultAnwsersDialog = $$v
                    },
                    expression: "isShowingDefaultAnwsersDialog"
                  }
                },
                [
                  _c("DefaultAnswerDialog", {
                    attrs: { question: _vm.question },
                    on: { answerSelected: _vm.defaultAnswerSelected },
                    model: {
                      value: _vm.isShowingDefaultAnwsersDialog,
                      callback: function($$v) {
                        _vm.isShowingDefaultAnwsersDialog = $$v
                      },
                      expression: "isShowingDefaultAnwsersDialog"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isReadingMoreInfo
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.isReadingMoreInfo,
                    callback: function($$v) {
                      _vm.isReadingMoreInfo = $$v
                    },
                    expression: "isReadingMoreInfo"
                  }
                },
                [
                  _c("MoreInfoDialog", {
                    attrs: {
                      description: _vm.stringInputInstruction,
                      title: "Instructies"
                    },
                    model: {
                      value: _vm.isReadingMoreInfo,
                      callback: function($$v) {
                        _vm.isReadingMoreInfo = $$v
                      },
                      expression: "isReadingMoreInfo"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }