import { Model } from '@/models/Model';
import { Media } from '@/models/Report';

export class SubmissionDamage extends Model {
  protected $name = 'SubmissionDamage';

  protected $endpoint = '/applicant-submission-damages';

  protected $fillable: string[] = [];

  public id?: any;

  public additional_request?: string;

  public media?: Media[];
}
