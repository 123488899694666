import client from '@/support/Client';
import { InstanceLoader } from '@/support/InstanceLoader';
import { studlyCase } from '@/support/String';
import { AxiosError } from 'axios';
import store from '@/store';
import { isObject } from 'lodash';

export class Model {
  // $name must be exactly the same as the class name.
  protected $name = 'Model';

  protected $endpoint = '';

  protected $primaryKey = 'uuid';

  protected $headers: {[key: string]: string} = {};

  protected $fillable: string[] = [];

  protected $dmz: string | null = null;

  protected $refactor: boolean | null = null;

  protected $page: number | null = null;

  protected $limit: number | null = null;

  protected $sort: SortOptions = {};

  public meta?: object = {};

  protected $includes: string[] = [];

  protected filters: object = {};

  protected $getParams: string[] = [];

  constructor(attributes: object = {}) {
    Object.assign(this, attributes);
  }

  public getFilterLabel(): string {
    if ((this as any).title !== undefined) {
      return (this as any).title;
    }

    if ((this as any).name !== undefined) {
      return (this as any).name;
    }

    return 'UNKNOWN';
  }

  public dmz(orginazationUuid: string) {
    this.$dmz = orginazationUuid;
    return this;
  }

  public refactor() {
    this.$refactor = true;
    return this;
  }

  public exists(): boolean {
    return (this as any)[this.$primaryKey] !== null && (this as any)[this.$primaryKey] !== undefined;
  }

  public first(): Promise<any> {
    return this.limit(1)
      .all()
      .then(
        (models: Model[]) => {
          if (models === undefined || ! Array.isArray(models) || ! (models[0] instanceof Model)) {
            return Promise.reject(models);
          }
          Object.assign(this, models[0]);
          return Promise.resolve(models[0]);
        },
        (error: object) => Promise.reject(error),
      );
  }

  public latest(sortKey = 'created_at'): Promise<any> {
    return this.sort(sortKey, 'DESC').first();
  }

  public all(parseToModel = true): Promise<any> {
    const url = this.$endpoint;

    return this.request('get', url, {}, parseToModel, this.$headers);
  }

  public find(id: any): Promise<any> {
    const url = `${this.$endpoint}/${id}`;

    return this.request('get', url);
  }

  public create(attributes?: object, parseToModel = true): Promise<any> {
    const url = this.$endpoint;
    attributes = this.getPayload(attributes);

    return this.request('post', url, attributes, parseToModel);
  }

  public put(attributes?: object): Promise<any> {
    let primaryKey = this.resolvePrimaryKey();
    primaryKey = primaryKey.length ? `/${primaryKey}` : '';

    const url = this.$endpoint + primaryKey;

    return this.request('put', url, this.getPayload(attributes));
  }

  public update(attributes?: object): Promise<any> {
    const url = `${this.$endpoint}${this.resolvePrimaryKey().length ? `/${this.resolvePrimaryKey()}` : ''}`;
    return this.request('patch', url, this.getPayload(attributes));
  }

  public delete(attributes?: object): Promise<any> {
    const url = `${this.$endpoint}/${this.resolvePrimaryKey()}`;

    return this.request('delete', url, this.getPayload(attributes));
  }

  public deleteMany(ids: string[]) {
    const url = this.$endpoint;

    return this.request('delete', url, { id: ids });
  }

  public getInstanceName() {
    return this.$name;
  }

  public resolvePrimaryKey(): string {
    return (this as any)[this.$primaryKey];
  }

  public getPayload(attributes?: object): object {
    if (attributes === undefined) {
      attributes = { ...{}, ...(this as Object) } as Model;

      const keys = Object.keys(attributes);

      keys.forEach((property, index) => {
        if (! this.isFillable(property)) {
          delete (attributes as any)[property];
        }
      });
    }

    return attributes;
  }

  public request(method: string, url: string, payload: any = {}, parseToModel = true, headers: {[key: string]: string | boolean} = {}): Promise<any> {
    if (this.$includes.length) {
      payload.with = this.$includes;
    }

    const keys = Object.keys(this.filters);
    if (keys.length) {
      keys.forEach((key) => {
        const model: Model = (this.filters as any)[key];
        if (isObject(model) && typeof model.resolvePrimaryKey === 'function') {
          (this.filters as any)[key] = model.resolvePrimaryKey();
        }
      });

      payload.filters = this.filters;
    }

    if (this.$page !== null) {
      payload.page = this.$page;
    }

    if (this.$limit !== null) {
      payload.limit = this.$limit;
    }

    if (this.$sort.key) {
      payload.sort = this.$sort.order === 'DESC' ? `!${this.$sort.key}` : this.$sort.key;
    }

    if (this.$dmz && url !== '/rpc') {
      url = `/dmz${url}`;
    }

    if (this.$getParams && this.$getParams.length) {
      url = `${url}${! url.includes('?') ? '?' : '&'}${this.$getParams.join('&')}`;
    }

    return client(method, url, payload, false, this.$dmz, this.$refactor, headers).then(
      (response: object) => (parseToModel ? Promise.resolve(this.responseToModel(response)) : Promise.resolve(response)),
      (error: AxiosError) => {
        if (error.response !== undefined && error.response.status === 503) {
          store.dispatch('maintenanceDetected');
        }

        if (error.response !== undefined && error.response.status === 401) {
          store.dispatch('userDeauthenticated');
        }

        return Promise.reject(error);
      },
    );
  }

  public include(includes: string | string[]) {
    if (typeof includes === 'string') {
      includes = [includes];
    }

    this.$includes = includes;

    return this;
  }

  public removeFilter(key: string) {
    if ((this.filters as any)[key]) {
      delete (this.filters as any)[key];
    }

    return this;
  }

  public filter(filters: string | object, value?: any) {
    if (typeof filters === 'string') {
      const key = filters;
      filters = {};
      (filters as any)[key] = value || null;
    }

    this.filters = { ...this.filters, ...filters };

    return this;
  }

  public page(page: number) {
    this.$page = page;

    return this;
  }

  public limit(limit: number) {
    this.$limit = limit;

    return this;
  }

  public isFillable(key: string): boolean {
    return this.$fillable.includes(key);
  }

  public sort(key: string, order: SortOrder = 'ASC') {
    this.$sort = { key, order };

    return this;
  }

  public addGetParams(params: string[] | string) {
    if (typeof params === 'string') {
      this.$getParams.push(params);
    } else {
      this.$getParams.forEach((param: string) => this.$getParams.push(param));
    }

    return this;
  }

  public clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  public dataToModel(data: any, className: string, rows: boolean | Object[] = false): any {
    if (rows && Array.isArray(rows)) {
      let meta = {
        current_page: data.current_page || 1,
        first_page: data.from || 1,
        last_page: data.last_page || 1,
        total: data.total || 1,
        from: data.from || 0,
        to: data.to || 0,
      };

      if (data.totals) {
        meta = { ...meta, ...{ totals: data.totals } };
      }

      rows.forEach((row: any, i: any) => {
        (rows as any)[i] = this.rowToModel(row, className);
        const currentMeta = (rows as any)[i].meta !== undefined && typeof (rows as any)[i].meta === 'object' ? (rows as any)[i].meta : {};
        (rows as any)[i].meta = { ...meta, ...currentMeta };
      });

      return rows;
    }

    return this.rowToModel(data, className);
  }

  public responseToModel(response: any): any {
    const paginatedResults = response.data.data !== undefined && Array.isArray(response.data.data);
    const data = response.data;
    const rows = paginatedResults ? response.data.data : false;
    const className = this.getInstanceName();
    return this.dataToModel(data, className, rows);
  }

  public rowToModel(row: object, modelClass: string, includes: any = []): Model {
    const attributes = row;

    const model = InstanceLoader.get(modelClass, attributes);

    Object.keys(model).forEach((key) => {
      const setterMethod = `set${studlyCase(key)}Attribute`;

      if (Object.prototype.hasOwnProperty.call(Object.getPrototypeOf(model), setterMethod)) {
        model[key] = model[setterMethod](model[key]);
      }
    });

    return model;
  }
}

export interface SortOptions {
  key?: string;
  order?: SortOrder;
}

export type SortOrder = 'ASC' | 'DESC';
