var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "item__list item__list--reports",
      attrs: { "mt-4": "", wrap: "" }
    },
    [
      _vm.damage && _vm.damage.repairs && _vm.damage.repairs.length > 0
        ? [
            _c(
              "v-flex",
              { staticClass: "label", attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs5: "" } }, [
                      _c("span", [_vm._v("Reparaties")])
                    ]),
                    _c("v-flex", { attrs: { xs2: "" } }, [
                      _c("span", [_vm._v("Eenheid")])
                    ]),
                    _c(
                      "v-flex",
                      { staticClass: "text-xs-right", attrs: { xs2: "" } },
                      [_c("span", [_vm._v("Prijs")])]
                    ),
                    _c(
                      "v-flex",
                      { staticClass: "text-xs-right", attrs: { xs2: "" } },
                      [_c("span", [_vm._v("BTW Percentage")])]
                    ),
                    _c("v-flex", { attrs: { xs1: "" } })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.damage.repairs, function(repair, index) {
              return _c(
                "v-flex",
                { key: index, staticClass: "list__item", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              repair.form !== "custom"
                                ? repair.type.name
                                : repair.custom_name
                            ) +
                            " "
                        )
                      ]),
                      _c("v-flex", { attrs: { xs2: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              repair.form !== "custom"
                                ? repair.quantity
                                : repair.custom_unit
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "text-xs-right", attrs: { xs2: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(repair.total_incl_vat)
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "text-xs-right", attrs: { xs2: "" } },
                        [_vm._v(_vm._s(repair.vat) + "%")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "text-xs-right customRepairIndicator",
                          attrs: { xs1: "" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        repair.form === "custom"
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                { attrs: { color: "primary" } },
                                                on
                                              ),
                                              [_vm._v("info")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("Aangepaste calculatie")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c(
              "v-flex",
              { staticClass: "list__item", attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "strongText", attrs: { xs7: "" } },
                      [_vm._v("Totaal")]
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass: "strongText text-xs-right",
                        attrs: { xs2: "" }
                      },
                      [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))]
                    ),
                    _c("v-flex", { attrs: { xs2: "" } }),
                    _c("v-flex", { attrs: { xs1: "" } })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-flex",
              { staticClass: "label", attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("span", { staticClass: "fadedText" }, [
                        _vm._v("Er zijn geen reparaties.")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }