import AIsSetSlotMixin from '@/mixins/AIsSetSlotMixin';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { isSetStringAttr } from '@/support/AGeneral';

@Component<ACard>({})
export default class ACard extends mixins(Vue, AIsSetSlotMixin) {
  @Prop({ default: undefined })
  protected text!: string;

  @Prop({ default: undefined })
  protected title!: string;

  protected get classes(): string {
    const classes: string[] = [];

    Object.keys(this.$attrs).forEach((attr) => {
      classes.push(`atabix-card--${attr}`);
      delete this.$attrs[attr];
    });

    return classes.join(' ');
  }

  protected get hasActions(): boolean {
    return this.isSetSlot.actions;
  }

  protected get hasContent(): boolean {
    return this.isSetSlot.default || isSetStringAttr(this.text);
  }

  protected get hasCard(): boolean {
    return this.hasActions || this.hasContent || this.hasTitle;
  }

  protected get hasTitle(): boolean {
    return this.isSetSlot.title || isSetStringAttr(this.title);
  }
}
