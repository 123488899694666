var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      class: _vm.isSuccessConclusion ? "warning-border" : "success-border",
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("p", [_vm._v("Licht toe waarom je wilt afwijken.")])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Reden",
                      items: _vm.reasons,
                      "item-text": "label",
                      "item-value": "value"
                    },
                    model: {
                      value: _vm.payload.reason,
                      callback: function($$v) {
                        _vm.$set(_vm.payload, "reason", $$v)
                      },
                      expression: "payload.reason"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    attrs: { name: "comment", label: "Toelichting" },
                    model: {
                      value: _vm.payload.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.payload, "comment", $$v)
                      },
                      expression: "payload.comment"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "v-layout",
            { attrs: { "justify-end": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "fadedText link mr-3",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("Annuleren")]
                  ),
                  _c(
                    "AButton",
                    {
                      class: _vm.isSuccessConclusion
                        ? "tw-bg-error-500"
                        : "tw-bg-success-500",
                      attrs: { disabled: !_vm.isValidForm },
                      nativeOn: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }