import { Organization } from '@/models/Organization';
import { MapArea } from '@/models/MapArea';
import { Model } from '@/models/Model';
import { User } from '@/models/User';

// This is a model for project groups
export class Project extends Model {
  protected $name = 'Project';

  protected $endpoint = '/projects';

  protected $fillable: string[] = [
    'name',
  ];

  public id?: string;

  public name?: string;

  public instruction_planning?: string;

  public type?: string;

  public map_area?: MapArea;

  public map_settings?: MapSettings;

  public organization?: Organization;

  public experts?: User[];

  public planners?: User[];

  public business_rules?: BusinessRules;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public addReports(reports?: object, force?: boolean) {
    const url = `${this.$endpoint}/${this.id}/add-reports`;
    const payload: {[key: string]: any} = {
      reports,
    };

    if (force) {
      payload.force = force;
    }

    return this.request('post', url, payload);
  }

  public removeReports(reports?: object) {
    const url = `${this.$endpoint}/${this.id}/remove-reports`;

    return this.request('post', url, {
      reports,
    });
  }
}

// @Paul types invullen met de value die je naar de api stuurt
export const projectTypes: ProjectType[] = [
  {
    name: 'Doorlopend project',
    value: 'continuously',
  },
  {
    name: 'Aflopend project',
    value: 'job',
  },
  {
    name: 'Analyse project',
    value: 'analysis',
  },
];

export interface ProjectType {
  name: string;
  value: string;
}

export interface MapSettings {
  center: any;
  zoom: number;
}

export interface BusinessRules {
  includes: BusinessRulesFilters;
  excludes: BusinessRulesFilters;
}

export interface BusinessRulesFilters {
  status?: string[]
  organizations?: string[];
  report_handler_users?: string[];
  case_mediator_users?: string[];
  report_types?: string[];
  tags?: string[];
  skills?:string[]
  departments?:string[]
  deadline_expired?: boolean;
  kpi_contract_expired?: boolean;
  prio?: boolean;
  special?: boolean;
  submitted_active?: boolean;
  submitted_from?: string;
  submitted_to?: string;
  downloaded_active?: boolean;
  downloaded_from?: string;
  downloaded_to?: string;
  payout_total_active?: boolean;
  payout_total_from?: number;
  payout_total_to?: number;
}
