import { Question } from '@/models/Question';
import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator';
import { Damage, Classification } from '@/models/Damage';
import { Report } from '@/models/Report';
import QuestionConclusion from '@/components/QuestionConclusion/QuestionConclusion.vue';
import { QuestionaireDialogConclusionTypeEnum } from '@/items/QuestionaireDialogConclusionType';
import { AxiosError } from 'axios';
import ImageRecognitionDialog from '@/components/dialog/ImageRecognitionDialog/ImageRecognitionDialog.vue';
import { File as FileModel } from '@/models/File';
import { overwriteConclusionReasonLabels } from '@/items/OverwriteConclusionDialogReasons';

@Component<ImageRecognitionQuestion>({
  components: {
    QuestionConclusion,
    ImageRecognitionDialog,
  },
})
export default class ImageRecognitionQuestion extends Vue {
  // // File structure for Typescript files in your Vue project
  // // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Injects
  @InjectReactive() // Provided at ImageRecognitionDialog
  protected isShowingImageRecognitionDialog!: boolean;

  @InjectReactive() // Provided at Expert.ts
  protected damageQuestions!: Question[];

  @InjectReactive() // Provided at Expert.ts
  protected currentDamage!: Damage;

  @InjectReactive() // Provided at Expert.ts
  protected currentDamageMedia!: FileModel[];
  // #endregion

  // #region @Props
  @Prop()
  protected question!: Question;

  @Prop()
  protected damage!: Damage;

  @Prop()
  protected report!: Report;

  @Prop({ default: false })
  protected disabled!: boolean;
  // #endregion

  // // #region Class properties
  protected isDisplayingImageRecognitionDialog = false;

  protected overwriteConclusionReasonLabels = overwriteConclusionReasonLabels;

  // TODO: Not sure what this is.
  protected conclusion: null | Conclusion = null;

  protected damageAnswers: {[key: string]: string} | null = null;

  protected isLoading = false;

  protected isLoadingQuestions = false;

  // // #region Class methods
  protected onOpenImageRecognitionDialog(): void {
    this.isDisplayingImageRecognitionDialog = true;
  }

  protected closeImageRecognitionDialog(): void {
    this.isDisplayingImageRecognitionDialog = false;
    this.$emit('reloadAnswers');
  }

  protected async handleAnswerQuestion(question: Question, value: string): Promise<void> {
    try {
      await this.answerQuestion({
        key: question.key,
        value,
      });
    } catch (error) {
      //
    }
  }
  // // #endregion

  // // #region Async methods
  protected async answerQuestion(damagePayload: Record<string, string|number|boolean|null>): Promise<void> {
    this.isLoadingQuestions = true;
    try {
      const damage = await new Damage(this.damage)
        .updateAnswer()
        .include(['validations'])
        .create(damagePayload);
      this.damageAnswers = damage.answers;
    } catch (error) {
      //
    }
    this.isLoadingQuestions = false;
  }

  protected async handleRemoveAnswer(damagePayload: Record<string, string|number|boolean|null>): Promise<void> {
    await this.answerQuestion(damagePayload);
    this.$emit('reloadAnswers');
  }
  // #endregion

  // #region Getters & Setters
  protected get classificationDescription() {
    switch (this.damage.classification) {
      case Classification.CAUSAAL:
        return 'Ja. Causaal';
      case Classification.NIET_CAUSAAL:
        return 'Nee. Niet causaal';
      case Classification.ONBEKEND:
        return '-';
      case Classification.IDENTIEK_NAM:
        return 'Identiek NAM';
      case Classification.IDENTIEK_IMG:
        return 'Identiek IMG';
      case Classification.ROOM_WITHOUT_DAMAGE:
        return 'Ruimte zonder schade';
      default:
        throw new Error(`Invalid classification ${this.damage.classification}`);
    }
  }

  protected get classificationResult(): QuestionaireDialogConclusionTypeEnum {
    switch (this.damage.classification) {
      case Classification.CAUSAAL:
        return QuestionaireDialogConclusionTypeEnum.SUCCESS;
      case Classification.NIET_CAUSAAL:
        return QuestionaireDialogConclusionTypeEnum.ERROR;
      case Classification.ONBEKEND:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.IDENTIEK_IMG:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.IDENTIEK_NAM:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      case Classification.ROOM_WITHOUT_DAMAGE:
        return QuestionaireDialogConclusionTypeEnum.INCOMPLETE;
      default:
        throw new Error(`Invalid classification ${this.damage.classification}`);
    }
  }

  protected get isDeviation(): boolean {
    return !! this.damage?.casuality_has_deviated;
  }

  // // #endregion

  // // #region @Watchers
  // // #endregion
}

// #region Interfaces
export interface Conclusion {
  title: string;
  description: string;
  show_questionaire: boolean;
  required_questions: Question[];
  show_second_questionaire: boolean;
  missing_answers: boolean;
  motivation: string;
  motivation_code: string;
  status: QuestionaireDialogConclusionTypeEnum;
  hide_question_keys: string;
}

// #endregion
