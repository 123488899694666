var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { staticClass: "item__list", attrs: { wrap: "" } },
        [
          _vm.items && _vm.items.length
            ? [
                _c(
                  "v-flex",
                  {
                    staticClass: "label tw-text-gray-600",
                    attrs: { xs12: "" }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      _vm._l(_vm.columns, function(column, columnIndex) {
                        return _c(
                          "v-flex",
                          { key: columnIndex, class: column.class },
                          [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(column.name) }
                            })
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "v-flex",
                    {
                      key: index,
                      staticClass: "list__item",
                      attrs: { xs12: "" }
                    },
                    [
                      _vm.items
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                wrap: "",
                                "align-center": "",
                                "justify-space-between": ""
                              }
                            },
                            _vm._l(_vm.columns, function(column, columnIndex) {
                              return _c(
                                "v-flex",
                                { key: columnIndex, class: column.class },
                                [
                                  _vm.isFunction(column.transform)
                                    ? [
                                        column.key === "week"
                                          ? [
                                              item[column.key][columnIndex - 1]
                                                ? _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        column.transform(
                                                          item[column.key][
                                                            columnIndex - 1
                                                          ]
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          : _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  column.transform(item)
                                                )
                                              }
                                            })
                                      ]
                                    : column.key === "week"
                                    ? [
                                        item[column.key][columnIndex - 1]
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item[column.key][
                                                    columnIndex - 1
                                                  ].total
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    : column.key === "row_total"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.rowTotal(item, column)) +
                                            " "
                                        )
                                      ]
                                    : column.key === "name"
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(item[column.key]) + " "
                                        )
                                      ]
                                    : item.data
                                    ? [
                                        item.links
                                          ? [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: item.links[column.key]
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.data[column.key]
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.data[column.key]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                      ]
                                    : [
                                        item.links && item.links[column.key]
                                          ? [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: item.links[column.key]
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item[column.key])
                                                  )
                                                ]
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item[column.key]) +
                                                  " "
                                              )
                                            ]
                                      ]
                                ],
                                2
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                _c(
                  "v-flex",
                  { staticClass: "list__item subtotal", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          wrap: "",
                          "align-center": "",
                          "justify-space-between": ""
                        }
                      },
                      _vm._l(_vm.columns, function(column, columnIndex) {
                        return _c(
                          "v-flex",
                          { key: columnIndex, class: column.class },
                          [
                            columnIndex === 0
                              ? [_vm._v("Totaal")]
                              : column.key === "row_total"
                              ? [_vm._v(" " + _vm._s(_vm.tableTotal()) + " ")]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.columnTotal(columnIndex)) +
                                      " "
                                  )
                                ]
                          ],
                          2
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          !_vm.items || !_vm.items.length
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "fadedText" }, [
                  _vm._v("Er zijn geen statistieken beschikbaar")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }