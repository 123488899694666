import { Model } from '@/models/Model';
import { Report, ReportIncludes } from '@/models/Report';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import { Address } from '@/models/Address';
import { Reservation } from '@/models/Reservation';

export class PlanningValidation extends Model {
  protected $name = 'PlanningValidation';

  protected $fillable: string[] = [
    'report',
    'organization',
    'expert',
    'starts_at',
    'ends_at',
    'reservation',
    'mediator',
    'mediator_starts_at',
    'mediator_ends_at',
    'user',
    'address_from_report',
    'mediator_appointment_type',
    'appointment_type',
    'without_mediator',
  ];

  public report!: Report | null;

  public organization!: Organization | null;

  public expert!: User | null;

  public starts_at!: string | null;

  public ends_at!: string | null;

  public reservation!: Reservation | null;

  public mediator!: User | null;

  public mediator_starts_at!: string | null;

  public mediator_ends_at!: string | null;

  public duration!: PlaningDuration;

  public settings!: AppointmentSetting;

  public is_valid!: IsValid;

  public questions!: PlanningQuestions;

  public departure_address!: Address | null;

  public mediator_departure_address!: Address | null;

  public errors!: string[];

  public warnings!: string[];

  // Immaterial
  public user?: User | null;

  public address?: Address | string | null;

  public address_from_report?: boolean;

  public without_mediator?: boolean;

  public validate() {
    const url = '/tools/planning/validate';

    return this.request('post', url, this.payload);
  }

  public validateImmaterieel() {
    const url = '/tools/planning/validate-immaterial';

    return this.request('post', url, this.payloadImmaterial);
  }

  public validateVes() {
    const url = '/tools/planning/validate';

    return this.request('post', url, this.payloadVes);
  }

  public reset() {
    this.report = null;
    this.organization = null;
    this.expert = null;
    this.starts_at = null;
    this.ends_at = null;
    this.reservation = null;
    this.mediator = null;
    this.mediator_starts_at = null;
    this.mediator_ends_at = null;
    this.is_valid = {
      report: false,
      ves_status: false,
      datetime: false,
      expert: false,
      zelfopname: false,
      mediator: false,
      questions: false,
    };
    this.settings = {
      mediator_appointment_type: 'call',
      appointment_type: 'default',
      confirm_email_to_applicant: true,
      has_submission: false,
    };
    this.departure_address = null;
    this.mediator_departure_address = null;
    this.validate();
  }

  public get payload() {
    return {
      report: this.report ? this.report.uuid : null,

      organization: this.organization && this.organization.id ? this.organization.id : null,
      expert: this.expert && this.expert.uuid ? this.expert.uuid : null,
      starts_at: this.starts_at ? this.starts_at : null,
      ends_at: this.ends_at ? this.ends_at : null,

      mediator: this.mediator && this.mediator.uuid ? this.mediator.uuid : null,
      mediator_starts_at: this.mediator_starts_at ? this.mediator_starts_at : null,
      mediator_ends_at: this.mediator_ends_at ? this.mediator_ends_at : null,
      settings: this.settings,
      without_mediator: this.without_mediator,
      with: [ReportIncludes.APPLICANT, ReportIncludes.ANSWERS],
    };
  }

  public resetImmaterial() {
    this.report = null;
    this.user = null;
    this.starts_at = null;
    this.ends_at = null;
    this.reservation = null;
    this.mediator = null;
    this.mediator_starts_at = null;
    this.mediator_ends_at = null;
    this.is_valid = {
      report: false,
      datetime: false,
      expert: false,
      zelfopname: false,
      mediator: false,
      questions: false,
    };
    this.settings = {
      mediator_appointment_type: 'physical',
      appointment_type: 'physical',
      confirm_email_to_applicant: true,
    };
    this.address_from_report = false,
    this.without_mediator = false,
    this.address = null;
    this.validateImmaterieel();
  }

  public get payloadImmaterial() {
    return {
      report: this.report ? this.report.uuid : null,

      address_from_report: this.address_from_report,
      user: this.user && this.user.uuid ? this.user.uuid : null,
      starts_at: this.starts_at ? this.starts_at : null,
      ends_at: this.ends_at ? this.ends_at : null,
      address: (this.address as Address)?.uuid ? (this.address as Address).uuid : this.address ? this.address : null,
      mediator: this.mediator && this.mediator.uuid ? this.mediator.uuid : null,
      mediator_starts_at: this.mediator_starts_at ? this.mediator_starts_at : null,
      mediator_ends_at: this.mediator_ends_at ? this.mediator_ends_at : null,
      settings: this.settings,
      without_mediator: this.without_mediator,
      with: [ReportIncludes.APPLICANT],
    };
  }

  public get payloadVes() {
    return { ...this.payload, ...{ is_ves: true, with: [ReportIncludes.ANSWERS, ReportIncludes.APPLICANT] } };
  }

  public get applicantName(): string {
    if (! this.report) {
      return '';
    }

    return this.report?.applicant?.name || '';
  }

  public get applicantEmail(): string {
    if (! this.report) {
      return '';
    }
    return this.report?.applicant?.email || '';
  }

  public get applicantPhone(): string {
    if (! this.report) {
      return '';
    }
    return this.report?.applicant?.phone || '';
  }

  public get totalHours(): number {
    if (! this.duration) {
      return 0;
    }

    return this.duration.minutes.total.visit;
  }

  public get questionProgress() {
    if (! this.questions || ! this.questions.answered || ! this.questions.total) {
      return 0;
    }

    return Math.ceil((this.questions.answered / this.questions.total) * 100);
  }

  public get hasErrors() {
    return this.errors && this.errors.length;
  }

  public get hasWarnings() {
    return this.warnings && this.warnings.length;
  }

  public get encodeDepartureAddress() {
    return encodeURI(`${this.departure_address?.street} ${this.departure_address?.number} ${this.departure_address?.number_add ? this.departure_address?.number_add : ''},  ${this.departure_address?.postcode} ${this.departure_address?.city}`);
  }

  public get encodeMediatorDepartureAddress() {
    return encodeURI(`${this.mediator_departure_address?.street} ${this.mediator_departure_address?.number} ${this.mediator_departure_address?.number_add ? this.mediator_departure_address?.number_add : ''},  ${this.mediator_departure_address?.postcode} ${this.mediator_departure_address?.city}`);
  }
}

interface IsValid {
  [key: string]: boolean;
  report: boolean;
  datetime: boolean;
  expert: boolean;
  zelfopname: boolean;
  mediator: boolean;
  questions: boolean;
}

interface PlaningDuration {
  [key: string]: any;
  minutes: {
    [key: string]: any;
    standaard?: number,
    bouwlaag_standaard?: number,
    bouwlaag_meer?: number,
    count_schades?: number,
    count_ebs?: number,
    total: {
      name: string;
      before: number;
      visit: number;
      after: number;
    }
  },
  hours: {
    [key: string]: any;
    name: string;
    before: number;
    visit: number;
    after: number;
  }
}

interface PlanningQuestions {
  complete: boolean;
  answered: number;
  unanswered: number;
  total: number;
}

export interface AppointmentSetting {
  appointment_type: string;
  mediator_appointment_type: string;
  confirm_email_to_applicant: boolean;
  has_submission?: boolean;
}
