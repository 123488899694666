import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<LabelValue>({})
export default class LabelValue extends Vue {
  @Prop()
  protected value!: string | number;

  @Prop()
  protected label!: string;
}
