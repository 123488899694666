import { Model } from '@/models/Model';
import { PlanningWeek } from '@/models/PlanningWeek';
import { User } from '@/models/User';

export class Organization extends Model {
  protected $name = 'Organization';

  protected $endpoint = '/organizations';

  protected $fillable: string[] = [];

  public type?: string;

  public created_at?: string;

  public updated_at?: string;

  public is_accessible?: boolean;

  public name?: string;

  public id?: string;

  public experts?: any;

  public schedule?: any;

  public planningWeeks?: PlanningWeek[];

  public users?: User[];

  public supervisor?: User;

  public searchUser?: string;

  public report_steekproef_interval?: string | number;

  public totals?: OrganizationTotals;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public async getAllExperts(): Promise<this[]> {
    return this.filter({ types: ['expert', 'msr'] }).all();
  }
}

export const organizationUuid = {
  img: '8db9b34e-7a32-4dd9-9d68-7a82c23aa1bc',
};

export interface OrganizationTotals {
  active_users?: number;
  inactive_users?: number;
  users?: number;
}
