import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<List>({})
export default class List extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop()
  protected labels!: Label[];

  @Prop()
  protected items!: any[];
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    //
  }
  // #endregion

  // #region Class methods

  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  // #endregion

  // #region Getters & Setters

  // #endregion

  // #region @Watchers

  // #endregion
}

export interface Label {
  slotName: string;
  width: LabelWidth;
  title?: string;
  class?: string;
  headerClass?: string;
}

export type LabelWidth = | 'xs1' | 'xs2' | 'xs3' | 'xs4' | 'xs5' | 'xs6' | 'xs7' | 'xs8' | 'xs9' | 'xs10' | 'xs11' | 'xs12';
