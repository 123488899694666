import { Model } from '@/models/Model';
import { TimeProject } from '@/models/TimeProject';
import { HourType } from '@/models/HourType';
import { User } from '@/models/User';

export class Hour extends Model {
  protected $name = 'Hour';

  protected $endpoint = '/hours';

  protected $fillable: string[] = [
    'project',
    'description',
    'type',
    'starts_at',
    'ends_at',
  ];

  public id?: string;

  public project_id?: string;

  public project?: string | TimeProject;

  public description?: string;

  public type_id?: string;

  public type?: string | HourType;

  public starts_at?: string;

  public ends_at?: string;

  public status?: HourStatus;

  public duration?: string;

  public user_id?: string;

  public user?: string | User;

  public report_stats?: ReportStats;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public islockedStatus() {
    if (! this.status) {
      return false;
    }

    const lockedStatuses = ['debited', 'processed', 'turned_in'];

    return lockedStatuses.includes(this.status);
  }
}

export const hourStatuses: HourStatuses[] = [
  {
    name: 'Geschreven',
    key: 'created',
    color: '#67A7C4',
  },
  {
    name: 'Ingeleverd',
    key: 'turned_in',
    color: '#67A7C4',
  },
  {
    name: 'Gefactureerd',
    key: 'processed',
    color: '#62DC4F',
  },
  {
    name: 'Afgeboekt',
    key: 'debited',
    color: '#F74949',
  },
];

export const getStatusName = (key: string): string => (getStatusAttribute(key, 'name') as string);

const getStatusAttribute = (key: string, attribute: string) => {
  const statusObject = searchStatus(key);

  return (statusObject as HourStatuses)[attribute];
};

const searchStatus = (key: string) => {
  const defaultStatus: HourStatuses = { key: '', name: 'Onbekend', color: 'primary' };
  const status = hourStatuses.find((hourStatus: HourStatuses) => hourStatus.key === key);

  return status || defaultStatus;
};

export interface HourStatuses {
  key: string;
  name: string;
  color: string;
  [key: string]: string | string[];
}

type HourStatus = 'created' | 'turned_in' | 'processed' | 'debited';

export interface ReportStats {
  report_status: string;
  report_planned_at: string;
  report_type: string;
  report_workflow: string;
  report_organization_expert_name: string;
  report_opname_variant: string;
  damages_count: number;
  damages_casaul_count: number;
  damages_non_casaul_count: number;
  damages_ebs_count: number;
  payout_total: number;
}
