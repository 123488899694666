import { Component, Vue } from 'vue-property-decorator';

@Component<DefaultDialog>({})
export default class DefaultDialog extends Vue {
  protected isLoading = false;

  public handleLoader(loader: boolean) {
    this.isLoading = loader;
  }

  public close() {
    this.$emit('close');
  }
}
