import { Question as QuestionModel } from '@/models/Question';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<Question>({})
export default class Question extends Vue {
  @Prop()
  protected question!: QuestionModel;

  protected onClickInfo(): void {
    this.$emit('clickInfo');
  }

  protected onClickMetaPgvLimit(): void {
    this.$emit('clickMetaPgvLimit');
  }
}
