import SelectFilter from '@/components/select-filter/SelectFilter.vue';
import { Model as DataModel, SortOptions } from '@/models/Model';
import { sanitizeString } from '@/support/String';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<DataTable>({
  components: {
    SelectFilter,
  },
})
export default class DataTable extends Vue {
  @Prop({ default: null })
  protected options!: TableOptions;

  @Prop({ default: 'id' })
  protected itemKey!: string;

  @Prop({ default: true })
  protected multiple!: boolean;

  @Prop({ default: false })
  protected compact!: boolean;

  @Prop({ default: false })
  protected editable!: boolean;

  @Prop({ default: true })
  protected autoRefetch!: boolean;

  protected initialized = false;

  @Watch('options')
  public optionsChanged(options: TableOptions, oldOptions: TableOptions) {
    if (this.initialized && this.autoRefetch) {
      this.initialize();
    }
  }

  @Prop({ default: null })
  protected limit!: number;

  protected $limit = 25;

  protected limitSelected = 25;

  protected editMode = false;

  protected modal = false;

  @Prop({ default: 1 })
  protected page!: number;

  protected $page = 1;

  protected items: DataModel[] = [];

  protected totals: Totals[] = [];

  protected changedItems: string[] = [];

  protected itemCache: any = {};

  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected isLoading = true;

  protected showLoaderCard = false;

  protected currentInformation: any = null;

  protected informationDialogOpen = false;

  protected TableHeader: object[] = [
    {
      text: 'Name',
      value: 'name',
    },
  ];

  protected pagination: object = {
    sortBy: 'name',
    rowsPerPage: 5,
  };

  protected name: TableName = {
    singular: 'Item',
    plural: 'Items',
  };

  protected $sort: undefined | SortOptions;

  @Prop({ default: () => {} })
  protected visibility!: TableVisibility;

  protected visible: TableVisibility = {
    search: true,
    total: true,
    limit: true,
    pagination: true,
    hidePaginationTop: false,
    hidePaginationBottom: false,
    checkboxes: true,
    checkboxPerRow: (value: any) => true,
    title: true,
  };

  @Prop({ default: false })
  protected clear!: boolean;

  @Prop({ default: () => [] })
  protected default!: string[];

  @Prop({ default: '' })
  protected customSearchInput!: string;

  protected searchInput = '';

  protected tableActions: TableActions[] = [];

  protected tableFilters: TableFilters[] = [];

  public searchDebounce: Function = this.handleSearch();

  public paginationDebounce: Function = this.handlePagination();

  public selected: any = [];

  public selectedFilter: any = null;

  protected dateModals: object = {};

  public mounted() {
    if (this.default) {
      this.selected = this.default;
    } else {
      this.selected = [];
    }

    document.addEventListener('datatable:hook', (event: any) => {
      if (event.detail) {
        event.detail(this);
      }
    });

    this.initialize();
  }

  public initialize() {
    this.items = [];
    this.totals = [];
    this.total = 0;
    this.isLoading = true;
    this.$page = this.page;
    this.$limit = this.limit ? this.limit : 25;
    this.limitSelected = this.$limit;
    (this.pagination as any).rowsPerPage = this.$limit;
    this.visible = { ...{}, ...this.visible, ...this.visibility };

    this.TableHeader = [];
    this.tableActions = [];
    this.tableFilters = [];

    if (this.options.headers !== undefined) {
      this.options.headers = this.options.headers.filter((header: any) => ! header.hide);
      this.TableHeader = this.options.headers;
    }

    if (this.options.actions !== undefined) {
      this.tableActions = this.options.actions;

      const hasActions = this.TableHeader.findIndex((header: any) => header.value === '$actions') !== - 1;
      if (this.tableActions.length > 0 && ! hasActions) {
        this.TableHeader.push({ text: '', value: '$actions' });
      }
    }

    if (this.options.filters !== undefined) {
      this.tableFilters = this.options.filters;
    }

    if (this.options.sort !== undefined && this.options.sort.key !== undefined) {
      this.$sort = this.options.sort as SortOptions;
    }

    if (this.options.name !== undefined) {
      this.name = { ...this.name, ...this.options.name };
    }

    if (this.customSearchInput && this.customSearchInput.length) {
      this.searchInput = this.customSearchInput;
    }
    this.fetchSource();
  }

  public fetchSource(dispatchToStore?: boolean) {
    this.isLoading = true;

    if (this.$sort && this.$sort.key) {
      this.options.model.sort(this.$sort.key, this.$sort.order);
    }

    this.options.model
      .limit(this.$limit)
      .page(this.$page)
      .filter(this.searchFilter)
      .all()
      .then((items: DataModel[]) => {
        this.items = items;
        this.$emit('dataLoaded', this.items);
        this.$emit('customSearchInput', this.searchInput);
        this.total = items[0] !== undefined ? (items[0].meta as TableMeta).total : 0;
        this.lastPage = items[0] !== undefined ? (items[0].meta as TableMeta).last_page : 1;
        this.totals = items[0] !== undefined ? (items[0].meta as TableMeta).totals : [];
        this.from = items[0] !== undefined ? (items[0].meta as TableMeta).from : 0;
        this.to = items[0] !== undefined ? (items[0].meta as TableMeta).to : 0;

        this.createDateModals();

        // if (dispatchToStore) {
        //   let payload: {[key:string]: DataModel[]} = {};
        //   payload[`${this.options.model.getInstanceName()}`] = items;
        //   this.$store.dispatch('updateFilters', payload);
        // }

        this.initialized = true;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
      });
  }

  public createDateModals() {
    const fields: string[] = [];
    this.options.headers.forEach((header: TableHeader, index: number) => {
      if ((header as any).type && (header as any).type === 'date') {
        fields.push(header.value);
      }
    });

    this.items.forEach((item: DataModel) => {
      fields.forEach((field) => {
        const key = `${(item as any).id}_${field}`;
        this.$set(this.dateModals, key, false);
      });
    });
  }

  public select(items: any[]) {
    this.selected = [...items, ...this.selected];
  }

  public unselect(items: any[]) {
    this.selected = this.selected.filter((item : any) => ! items.includes(item));
  }

  public refresh(dispatchToStore?: boolean) {
    this.fetchSource(dispatchToStore);
  }

  public setModel(model: any) {
    this.$set(this.options, 'model', model);
    this.fetchSource();
  }

  public deleteItem(item: DataModel) {
    this.isLoading = true;
    item.delete().then((resonse: any) => {
      this.fetchSource();
    });
  }

  public toggleAll() {
    if (this.selected.length) {
      this.selected = [];
    } else {
      this.selected = this.items.slice();
    }
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.$page = page;
        this.$emit('pageChanged', this.$page);
        this.fetchSource();
      },
      300,
    );
  }

  public handleAction(action: Function, item: DataModel) {
    action(this, item);
  }

  public handleIcon(action: Function, item: DataModel) {
    return action(this, item);
  }

  public resetDateField(item: DataModel, property: string) {
    if (! this.itemCache[(item as any).id]) {
      return;
    }
    (item as any)[property] = this.itemCache[(item as any).id][property];
    (this.dateModals as any)[`${(item as any).id}_${property}`] = false;
  }

  public cancelEdit() {
    Object.keys(this.itemCache).forEach((key: string) => {
      const item: any = this.items.find((level: any) => level.id === key);

      if (! item) {
        return;
      }

      Object.keys(this.itemCache[key]).forEach((property: string) => {
        item[property] = this.itemCache[key][property];
      });
    });

    this.clearChangedItems();
    this.editMode = false;
  }

  public handleTableEdit() {}

  public minDate(header: any) {
    if (! header.min) {
      return '';
    }
    return header.min;
  }

  protected getTotalValue(key: string) {
    const foundTotal = (this.totals as any).find((total: any) => key === total.key);

    return foundTotal ? foundTotal.value : '';
  }

  public maxDate(header: any) {
    if (! header.max) {
      return '';
    }
    return header.max;
  }

  public handleSearch() {
    return debounce((searchInput: string) => {
      this.$page = 1;
      this.fetchSource();
    }, 400);
  }

  public createItemCache() {
    this.clearItemCache();
    this.items.forEach((item: any) => {
      const itemProperties: any = {};
      this.TableHeader.forEach((header: any) => {
        if (header.value !== '$actions' && header.editable) {
          itemProperties[header.value] = item[header.value];
        }
      });
      this.itemCache[item.id] = itemProperties;
    });
  }

  public updateField(item: DataModel, header: TableHeader, value: string) {
    item
      .update({
        [`${header.editableKey}`]: value,
      })
      .then((model: DataModel) => {
        item = model;
        this.$emit('data-update');
        this.refresh();
      })
      .catch((error: AxiosError) => {
        this.showLoaderCard = false;
      });
  }

  protected parseValue(item: DataModel, key: string) {
    const keyParts = key.split('.');
    let value = item;

    keyParts.forEach((keyPart: string) => {
      value = this.searchInObject(keyPart, value);
    });

    return value;
  }

  protected searchInObject(value: string, item: object) {
    return (item as any)[value];
  }

  public update() {
    let callCounter = 0;
    const changed = this.items.filter((item: DataModel) => this.changedItems.includes((item as any).id));

    this.showLoaderCard = true;
    changed.forEach((item: DataModel) => {
      item
        .update()
        .then((model: DataModel) => {
          item = model;
          callCounter += 1;

          if (callCounter >= changed.length) {
            this.showLoaderCard = false;
            this.clearChangedItems();
            this.editMode = false;
            this.$emit('data-update');
            this.refresh();
          }
        })
        .catch((error: AxiosError) => {
          this.showLoaderCard = false;
        });
    });
  }

  public visibleForRole(roles: string[]) {
    if (! roles) {
      return true;
    }

    return this.$store.state.Auth.hasRole(roles);
  }

  public isActionVisible(action: any) {
    if (action.visible === undefined) {
      return true;
    }
    return action.visible;
  }

  protected isVisible(visible: any, item: DataModel) {
    if (visible === undefined) {
      return true;
    }

    if (this.isFunction(visible)) {
      return visible(this, item);
    }

    return visible;
  }

  public clearChangedItems() {
    this.changedItems = [];
  }

  public clearItemCache() {
    Object.keys(this.itemCache).forEach((key: string) => {
      delete this.itemCache[key];
    });
  }

  public isFunction(object: any) {
    return object && {}.toString.call(object) == '[object Function]';
  }

  public isString(value: string) {
    return typeof value === 'string';
  }

  public changeSorting(sortable: SortOptions) {
    if (! sortable) {
      return;
    }

    if (sortable.order === 'ASC') {
      sortable.order = 'DESC';
    } else {
      sortable.order = 'ASC';
    }

    this.$sort = sortable;
    this.fetchSource();
  }

  public isSortable(header: any) {
    if (! header.sortable) {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line consistent-return
  public getExtraClass(object: any) {
    if (object.class) {
      if (this.isFunction(object)) {
        return object.class();
      }
      return object.class;
    }
  }

  public get searchInputValue(): string {
    return this.searchInput;
  }

  public get searchFilter(): Record<string, string> {
    return this.visible.search ? { search: this.searchInput } : {};
  }

  protected onMouseEnterTableRow(item: any) {
    this.$emit('onMouseEnterTableRow', item);
  }

  protected onClickedRow(item: any) {
    this.$emit('onClickedRow', item);
  }

  protected openInformationDialog(content: any) {
    this.currentInformation = content;
    this.informationDialogOpen = true;
  }

  @Watch('selected')
  public selectionChange(value: any, oldValue: any) {
    if (! this.multiple && this.selected.length > 1) {
      this.selected = [this.selected[this.selected.length - 1]];
    }
    this.$emit('input', this.selected);
  }

  @Watch('selectedFilter')
  public selectedFilterChange(value: any, oldValue: string) {
    this.options.model.filter(value.type.toLowerCase(), value.value);
    this.fetchSource();
  }

  @Watch('limitSelected')
  public limitChanged(limit: number, oldLimit: number) {
    if (limit === null) {
      return;
    }

    this.$limit = limit;
    this.$page = 1;
    this.fetchSource();
  }

  @Watch('editMode')
  public editModeToggled(isEditting: boolean) {
    if (isEditting) {
      this.createItemCache();
    }
  }

  @Watch('clear')
  protected clearSelect(clear: boolean) {
    if (clear) {
      this.selected = [];
      this.searchInput = '';
      this.fetchSource();
      this.$emit('cleared');
    }
  }

  public headerClasses(header: any) {
    const defaultClasses = {
      'icon-column': header.value === 'icon',
      'color-column': header.value === 'color',
      'avatar-column': header.value === 'avatar',
      'actions-column': header.value === '$actions',
      'sortable-column': this.isSortable(header),
      asc: (this.isSortable(header) && header.sortable.order === 'ASC'),
      desc: (this.isSortable(header) && header.sortable.order === 'DESC'),
    };

    const customClasses = header.class ? header.class : {};

    return { ...defaultClasses, ...customClasses };
  }

  public columnStyles(header:any) {
    return header.style ? header.style : {};
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }
}

export interface TableMeta {
  total: number;
  last_page: number;
  from: number;
  to: number;
  totals: Totals[];
}

export interface TableOptions {
  model: DataModel;
  name?: TableName;
  headers: TableHeader[];
  actions?: TableActions[];
  filters?: TableFilters[];
  sort?: TableSort;
  add?: Function;
  showAdd?: boolean;
  showTotals?: boolean;
}

export interface Totals {
  approved: number;
  pre_controlled: number;
  submitted: number;
  total: number;
}

export interface TableHeader {
  text: string;
  value: any;
  action?: string | Function;
  sortable?: TableSort;
  class?: string | Function;
  width?: string;
  tooltip?: string | Function;
  transform?: Function;
  visible?: Function;
  editable?: boolean;
  editableKey?: string;
}

export interface TableFilters {
  type: string;
  text: string;
  value: any;
  multiple?: boolean;
}

export interface TableName {
  singular: string;
  plural: string;
}

export interface TableActions {
  type: string;
  label: any;
  icon: string | Function;
  action: string | Function;
  roles?: string[];
  tooltip?: string | Function;
  hide?: boolean;
  visible?: boolean | Function;
}

export interface TableVisibility {
  title?: boolean;
  search?: boolean;
  total?: boolean;
  limit?: boolean;
  pagination?: boolean;
  hidePaginationTop?: boolean;
  hidePaginationBottom?: boolean;
  checkboxes?: boolean;
  checkboxPerRow?: Function;
}

export interface TableSort {
  key: string;
  order: string;
}
