import { QuestionBase } from '@/models/question-models/question-base';

export class AlertQuestion extends QuestionBase<string> {
  controlType = 'alert';

  type: string;

  hasRemove: boolean;

  constructor(options: any = {}) {
    super(options);
    this.type = options.type || '';
    this.hasRemove = false;
  }
}
