import { Model } from '@/models/Model';
import { Type } from '@/models/Report';
import client from '@/support/Client';

export class Repair extends Model {
  protected $name = 'Repair';

  protected $endpoint = '/repairs';

  protected $fillable: string[] = [];

  public created_at?: string;

  public custom_apply_surtax?: boolean;

  public custom_name?: string;

  public custom_unit?: string;

  public custom_unitprice?: string;

  public custom_vat?: string;

  public form?: string;

  public links?: any;

  public quantity?: string | number;

  public total?: number;

  public total_incl_vat?: number;

  public type?: Type;

  public unitprice?: string | number;

  public updated_at?: string;

  public uuid?: string;

  public vat?: number;

  public sort_order?: number

  public repair(repair: Model | string) {
    this.$endpoint = `/repairs/${repair}`;
    return this;
  }

  public removeRepair(repair: Model | string) {
    this.$endpoint = `/repairs/${repair}`;
    return client('delete', this.$endpoint, {});
  }

  public types() {
    this.$endpoint = '/repairs/types';
    return this;
  }

  public resolvePrimaryKey(): string {
    return this.uuid || '';
  }

  public sortRepairs(attributes: string[]) {
    const url = this.$endpoint;

    return client('post', url, attributes, true);
  }
}

export interface RepairType {
  created_at?: string;
  custom_apply_surtax?: boolean;
  custom_name?: string;
  custom_unit?: string;
  custom_unitprice?: string;
  custom_customvat?: string;
  form?: string;
  links?: object;
  quantity?: string;
  total?: number;
  type?: any;
  unitprice?: string;
  updated_at?: string;
  uuid?: string;
  vat?: number;
  elementcluster?: any;
  subsection?: any;
}
