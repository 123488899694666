import { Model } from '@/models/Model';

export class ManagementReport extends Model {
  protected $name = 'ManagementReport';

  protected $endpoint = '/management-reports';

  protected $fillable: string[] = [];

  public id?: string;

  public name?: string;

  public status?: string;

  public content?: ManagementReportContent;

  public period_start?: string;

  public period_end?: string;

  public updated_at?: string;

  public created_at?: string;

  public links?: any;

  public media?: Media;

  public resolvePrimaryKey(): string {
    return this.id || '';
  }

  public get fileUrl(): string {
    if (this.isFinal) {
      return this.media?.final_pdf || '';
    }
    return this.media?.draft_pdf || '';
  }

  public get isFinal(): boolean {
    return this.status === 'final';
  }

  public get statusPill() {
    return `<span class="statusPill statusBackgroundColor--${this.getStatusColor()}">${this.getStatusLabel()}</span>`;
  }

  protected getStatusColor() {
    const colors: {[key: string] : string} = {
      draft: 'orange',
      final: 'green',
    };

    return this.status ? colors[this.status] : '';
  }

  protected getStatusLabel() {
    const labels: {[key: string] : string} = {
      draft: 'Concept',
      final: 'Definitief',
    };

    return this.status ? labels[this.status] : '';
  }
}

export interface ManagementReportPayload {
  content: {
    messages_received_img?: number | null;
    work_stock_img?: number | null;
    forecast_supply_expert?: number | null;
  }
  name?: string;
}

export interface ManagementReportContent {
  messages_received_img?: number | null;
  work_stock_img?: number | null;
  forecast_supply_expert?: number | null;
}

interface Media {
  final_pdf?: string | null;
  draft_pdf?: string | null;
}
