import { Model } from '@/models/Model';

export class Target extends Model {
  protected $name = 'Target';

  protected $endpoint = '/targets';

  protected $fillable: string[] = [];

  public id?: string;

  public type?: 'week' | 'month';

  public starts_at?: string;

  public ends_at?: string;

  public availability?: number;

  public planned?: number;

  public production?: number;

  public created_at?: string;

  public updated_at?: string;

  public resolvePrimaryKey(): string {
    return this.id ? this.id : '';
  }
}
