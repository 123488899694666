import { User } from '@/models/User';
import store from '@/store';
import Guard from '@/support/Guard';
import { Token } from '@/support/Token';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

let user: any;
let iterations = 0;

const everybody = ['admin', 'manager', 'helpdesk_tcmg', 'damage_handler', 'damage_controller', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'pre_controller', 'expert', 'smr', 'simple', 'simple_plus', 'case_mediator'];

const router: Router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'welkom',
      component: () => import(/* webpackChunkName: "welkom" */ '@/views/Dashboard/Dashboard.vue'),
      meta: { requiresAuth: true, requiresRoles: everybody },
    },
    {
      path: '/reports',
      name: 'report',
      component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports/Reports.vue'),
      meta: { requiresAuth: true, name: 'reports', type: 'Report', category: 'reports', requiresRoles: everybody },
    },
    {
      path: '/reports/:id',
      name: 'report.detail',
      component: () => import(/* webpackChunkName: "report" */ '@/views/Reports/Report.vue'),
      meta: { requiresAuth: true, name: 'report', type: 'Report', category: 'reports', requiresRoles: ['admin', 'manager', 'damage_handler', 'damage_controller', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'pre_controller', 'expert', 'smr', 'case_mediator', 'helpdesk_tcmg'] },
      children: [
        {
          name: 'report.detail.communicatie',
          path: 'communicatie',
          component: () => import(/* webpackChunkName: "report-tab-communicatie" */ '@/views/Reports/Communicatie/Communicatie.vue'),
        },
        {
          name: 'report.detail.beheer',
          path: 'beheer',
          component: () => import(/* webpackChunkName: "report-tab-beheer" */ '@/views/Reports/Beheer/Beheer.vue'),
        },
        {
          name: 'report.detail.urenadministratie',
          path: 'urenadministratie',
          component: () => import(/* webpackChunkName: "report-tab-urenadministratie" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/Reports/Urenadministratie/Urenadministratie.vue
        },
        {
          name: 'report.detail.planning',
          path: 'planning',
          component: () => import(/* webpackChunkName: "report-tab-planning" */ '@/views/Reports/Planning/Planning.vue'),
        },
        {
          name: 'report.detail.schades',
          path: 'schades',
          component: () => import(/* webpackChunkName: "report-tab-schades" */ '@/views/Reports/Schades/Schades.vue'),
        },
        {
          name: 'report.detail.msr',
          path: 'mijn-schaderegelen',
          component: () => import(/* webpackChunkName: "report-tab-msr" */ '@/views/Reports/MSR/MSR.vue'),
        },
        {
          name: 'report.detail.historische-schades',
          path: 'historische-schades',
          component: () => import(/* webpackChunkName: "report-tab-historische-schades" */ '@/views/Reports/HistorischeSchades/HistorischeSchades.vue'),
        },
        {
          name: 'report.detail.trillingstool-realtime',
          path: 'trillingstool-realtime',
          component: () => import(/* webpackChunkName: "report-tab-trillingstool-realtime" */ '@/views/Reports/TrillingstoolRealtime/TrillingstoolRealtime.vue'),
        },
        {
          name: 'report.detail.bijlagen',
          path: 'bijlagen',
          component: () => import(/* webpackChunkName: "report-tab-bijlagen" */ '@/views/Reports/Bijlagen/Bijlagen.vue'),
        },
        {
          name: 'report.detail.aanzichtfotos',
          path: 'aanzichtfotos',
          component: () => import(/* webpackChunkName: "report-tab-aanzichtfotos" */ '@/views/Reports/Aanzichtfotos/Aanzichtfotos.vue'),
        },
        {
          name: 'report.detail.plattegronden',
          path: 'plattegronden',
          component: () => import(/* webpackChunkName: "report-tab-plattegronden" */ '@/views/Reports/Plattegronden/Plattegronden.vue'),
        },
        {
          name: 'report.detail.schadebedrag',
          path: 'schadebedrag',
          component: () => import(/* webpackChunkName: "report-tab-schadebedrag" */ '@/views/Reports/SchadeBedrag/SchadeBedrag.vue'),
        },
        {
          name: 'report.detail.management-info',
          path: 'management-info',
          component: () => import(/* webpackChunkName: "report-tab-management-info" */ '@/views/Reports/ManagementInfo/ManagementInfo.vue'),
        },
        {
          name: 'report.detail.vragenoverzicht',
          path: 'vragenoverzicht',
          component: () => import(/* webpackChunkName: "report-tab-vragenoverzicht" */ '@/views/Reports/Vragenoverzicht/Vragenoverzicht.vue'),
        },
        {
          name: 'report.detail.data-quality',
          path: 'data-quality',
          component: () => import(/* webpackChunkName: "report-tab-data-quality" */ '@/views/Reports/DataQuality/DataQuality.vue'),
        },
      ],
    },
    {
      path: '/werkstromen',
      name: 'rapport-flow',
      component: () => import(/* webpackChunkName: "rapport-flow" */ '@/views/Overview/Overview.vue'),
      meta: { requiresAuth: true, name: 'reports', type: 'Report', category: 'reports', requiresRoles: everybody },
    },
    {
      path: '/mijn-agenda',
      name: 'my-planning',
      component: () => import(/* webpackChunkName: "my-planning" */ '@/views/Availability/MyPlanning.vue'),
      meta: { requiresAuth: true, name: 'my-planning', type: 'Availability', category: 'availability', requiresRoles: everybody },
    },
    {
      path: '/werkvoorbereiding',
      component: () => import(/* webpackChunkName: "werkvoorbereiding" */ '@/views/Werkvoorbereiding/Werkvoorbereiding.vue'),
      meta: { requiresAuth: true, name: 'werkvoorbereiding', type: 'Werkvoorbereiding', category: 'werkvoorbereiding', requiresRoles: everybody },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "werkvoorbereiding-dashboard" */ '@/views/Werkvoorbereiding/Dashboard/Dashboard.vue'),
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "werkvoorbereiding-mededelingen" */ '@/views/Werkvoorbereiding/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'bas-importtool',
          meta: { type: 'werkvoorbereiding' },
          component: () => import(/* webpackChunkName: "werkvoorbereiding-bas-importtool" */ '@/views/ExcelUploader/ExcelUploader.vue'),
        },
        {
          path: 'nam-importtool',
          name: 'NamUploader',
          component: () => import(/* webpackChunkName: "nam-uploader" */ '@/views/NAMUploader/NAMUploader.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "wv-reports" */ '@/views/Reports/Reports.vue'),
        },
        // TEMP_HIDE_APPLICANT
        // {
        //   path: 'aanvragers',
        //   component: () => import(/* webpackChunkName: "werkvoorbereiding-aanvragers" */ '@/views/Werkvoorbereiding/Aanvragers/Aanvragers.vue'),
        // },
      ],
    },
    // TEMP_HIDE_APPLICANT
    // {
    //   path: '/werkvoorbereiding/aanvragers/:id',
    //   component: () => import(/* webpackChunkName: "werkvoorbereiding-aanvrager" */ '@/views/Werkvoorbereiding/Aanvragers/Aanvrager.vue'),
    //   children: [
    //     {
    //       path: '/',
    //       component: () => import(/* webpackChunkName: "werkvoorbereiding-aanvrager-rapporten" */ '@/views/Werkvoorbereiding/Aanvragers/AanvragerRapporten/AanvragerRapporten.vue'),
    //     },
    //   ],
    // },
    {
      path: '/planning',
      component: () => import(/* webpackChunkName: "planning" */ '@/views/Planning/Planning.vue'),
      meta: { requiresAuth: true, name: 'planning', type: 'Planning', category: 'tools', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'] },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "planning-dashboard-lld" */ '@/views/Planning/Dashboard/Dashboard.vue'),
        },
        {
          path: 'onderzoeken',
          component: () => import(/* webpackChunkName: "planning-dashboard-onderzoeken" */ '@/views/Planning/Dashboard/DashboardOnderzoeken.vue'),
        },
        {
          path: 'opname',
          component: () => import(/* webpackChunkName: "planning-dashboard-opname" */ '@/views/Planning/Dashboard/DashboardOpname.vue'),
        },
        {
          path: 'zelfopname',
          component: () => import(/* webpackChunkName: "planning-dashboard-zelfopname" */ '@/views/Planning/Dashboard/DashboardZelfopname.vue'),
          children: [
            {
              path: '/',
              component: () => import(/* webpackChunkName: "planning-dashboard-zelfopname-reports" */ '@/views/Reports/Reports.vue'),
            },
          ],
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "planning-mededelingen" */ '@/views/Planning/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'availability-request',
          component: () => import(/* webpackChunkName: "expert-availability-request" */ '@/views/Planning/ExpertAvailabilityRequest/ExpertAvailabilityRequest.vue'),
        },
        {
          path: 'excel-uploader',
          meta: { type: 'planning' },
          name: 'excelUploaderPlanning',
          component: () => import(/* webpackChunkName: "planning-excel-uploader" */ '@/views/ExcelUploader/ExcelUploader.vue'),
        },
        {
          path: 'settings',
          component: () => import(/* webpackChunkName: "planning-settings" */ '@/views/Planning/Settings/Settings.vue'),
        },
        {
          path: 'settings-log',
          component: () => import(/* webpackChunkName: "planning-settings-log" */ '@/views/Planning/PlanningSettingsLog/PlanningSettingsLog.vue'),
        },
        {
          path: 'block-users',
          component: () => import(/* webpackChunkName: "planning-block-users" */ '@/views/Planning/BlockUsers/BlockUsers.vue'),
        },
        {
          path: 'agreed-hours',
          component: () => import(/* webpackChunkName: "agreed-hours" */ '@/views/Planning/AgreedHours/AgreedHour.vue'),
        },
        {
          path: 'availability-log',
          component: () => import(/* webpackChunkName: "availability-log" */ '@/views/Planning/AvailabilityLog/AvailabilityLog.vue'),
        },
        {
          path: 'availability',
          name: 'planning-availability',
          component: () => import(/* webpackChunkName: "planning-availability" */ '@/views/Availability/Availability.vue'),
          props: true,
        },
        {
          path: 'organization-availability',
          name: 'organization-availability',
          component: () => import(/* webpackChunkName: "organization-availability" */ '@/views/Availability/Availability.vue'),
          props: true,
        },
        {
          path: 'availability-planner',
          component: () => import(/* webpackChunkName: "availability-planner" */ '@/views/Planning/AvailabilityPlanner/AvailabilityPlanner.vue'),
        },
        {
          path: 'organization-planning',
          component: () => import(/* webpackChunkName: "organization-planning" */ '@/views/Planning/OrganizationPlanning/OrganizationPlanning.vue'),
        },
        {
          path: 'week-planning',
          component: () => import(/* webpackChunkName: "week-planning" */ '@/views/Planning/WeekPlanning/WeekPlanning.vue'),
        },
        {
          path: 'planning-statistics',
          component: () => import(/* webpackChunkName: "planning-statistics" */ '@/views/Planning/PlanningStatistics/PlanningStatistics.vue'),
        },
        {
          path: 'production-statistics-incomming',
          component: () => import(/* webpackChunkName: "planning-statistics-production-statistics-incomming" */ '@/views/ReportStatistics/ProductionStatistics/ProductionStatistics.vue'),
        },
        {
          path: 'production-statistics',
          component: () => import(/* webpackChunkName: "production-statistics" */ '@/views/Planning/ProductionStatistics/ProductionStatistics.vue'),
        },
        {
          path: 'production-statistics-on-hold',
          component: () => import(/* webpackChunkName: "production-statistics-on-hold" */ '@/views/Planning/ProductionStatisticsOnHold/ProductionStatisticsOnHold.vue'),
        },
        {
          path: 'production-statistics-experts',
          component: () => import(/* webpackChunkName: "production-statistics-experts" */ '@/views/Planning/ProductionStatisticsExperts/ProductionStatisticsExperts.vue'),
        },
        {
          path: 'planning-employee-statistics',
          component: () => import(/* webpackChunkName: "planning-employee-statistics" */ '@/views/Planning/PlanningEmployeeStatistics/PlanningEmployeeStatistics.vue'),
        },
        {
          path: 'planning-type-statistics',
          component: () => import(/* webpackChunkName: "planning-type-statistics" */ '@/views/Planning/PlanningTypeStatistics/PlanningTypeStatistics.vue'),
        },
        {
          path: 'planning-cancelled-statistics',
          component: () => import(/* webpackChunkName: "planning-cancelled-statistics" */ '@/views/Planning/PlanningCancelledStatistics/PlanningCancelledStatistics.vue'),
        },
        {
          path: 'planning-cancelled-statistics-ves',
          component: () => import(/* webpackChunkName: "planning-cancelled-statistics-ves" */ '@/views/Planning/PlanningCancelledStatisticsVes/PlanningCancelledStatisticsVes.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "planning-reports" */ '@/views/Reports/Reports.vue'),
        },
        {
          path: 'plannable-reports',
          component: () => import(/* webpackChunkName: "planning-plannable-reports" */ '@/views/PlannableReports/PlannableReports.vue'),
        },
        {
          path: 'aanpassingen-bureau',
          name: 'expert.report.logs',
          component: () => import(/* webpackChunkName: "tools-report-logs" */ '@/views/Tools/ExpertReportLogs/ExpertReportLogs.vue'),
        },

        // Immaterieel
        {
          path: 'immaterieel',
          component: () => import(/* webpackChunkName: "planning-immaterieel-dashboard" */ '@/views/Planning/Immaterieel/Dashboard/Dashboard.vue'),
        },
        {
          path: 'availability-jz',
          component: () => import(/* webpackChunkName: "planning-immaterieel-availability" */ '@/views/Planning/Immaterieel/Availability/Availability.vue'),
        },
        {
          path: 'planning-type-statistics-ims',
          component: () => import(/* webpackChunkName: "planning-type-statistics-ims" */ '@/views/Planning/PlanningTypeStatisticsIms/PlanningTypeStatisticsIms.vue'),
        },

        // VES nulmeting
        {
          path: 'ves-nulmeting',
          component: () => import(/* webpackChunkName: "planning-immaterieel-dashboard" */ '@/views/Planning/Ves/Dashboard/Dashboard.vue'),
        },
        {
          path: 'planning-type-statistics-ves',
          component: () => import(/* webpackChunkName: "planning-type-statistics-ves" */ '@/views/Planning/PlanningTypeStatisticsVes/PlanningTypeStatisticsVes.vue'),
        },
      ],
    },
    {
      path: '/schadebepaling',
      component: () => import(/* webpackChunkName: "schadebepaling" */ '@/views/Schadebepaling/Schadebepaling.vue'),
      meta: { requiresAuth: true, name: 'bureau', type: 'Bureau', category: 'bureau', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'damage_controller', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'pre_controller', 'expert', 'smr', 'case_mediator'] },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "schadebepaling-dashboard" */ '@/views/Schadebepaling/Dashboard/Dashboard.vue'),
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "schadebepaling-mededelingen" */ '@/views/Schadebepaling/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "schadebepaling-reports" */ '@/views/Reports/Reports.vue'),
        },
      ],
    },
    {
      path: '/schadeafhandeling',
      component: () => import(/* webpackChunkName: "schadeafhandeling" */ '@/views/Schadeafhandeling/Schadeafhandeling.vue'),
      meta: { requiresAuth: true, name: 'bureau', type: 'Bureau', category: 'bureau', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'damage_handler', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'pre_controller', 'expert', 'smr', 'case_mediator'] },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "schadeafhandeling-dashboard" */ '@/views/Schadeafhandeling/Dashboard/Dashboard.vue'),
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "schadeafhandeling-mededelingen" */ '@/views/Schadeafhandeling/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "schadeafhandeling-reports" */ '@/views/Reports/Reports.vue'),
        },
      ],
    },
    {
      path: '/bezwaar',
      component: () => import(/* webpackChunkName: "Bezwaar" */ '@/views/Bezwaar/Bezwaar.vue'),
      meta: { requiresAuth: true, name: 'bureau', type: 'Bureau', category: 'bureau', requiresRoles: everybody },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "Bezwaar-mededelingen" */ '@/views/Bezwaar/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'ambtelijk-dashboard',
          component: () => import(/* webpackChunkName: "Ambtelijk-dashboard" */ '@/views/Bezwaar/Dashboard/AmbtelijkDashboard.vue'),
        },
        {
          path: 'bac-dashboard',
          component: () => import(/* webpackChunkName: "Bac-dashboard" */ '@/views/Bezwaar/Dashboard/BACDashboard.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "bezwaar-reports" */ '@/views/Reports/Reports.vue'),
        },
      ],
    },
    {
      path: '/juridische-zaken',
      component: () => import(/* webpackChunkName: "juridische-zaken" */ '@/views/JuridischeZaken/JuridischeZaken.vue'),
      meta: { requiresAuth: true, name: 'juridische-zaken', type: 'juridische-zaken', category: 'juridische-zaken', requiresRoles: everybody },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "JuridischeZaken-dashboard" */ '@/views/JuridischeZaken/Dashboard/Dashboard.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "juridische-zaken-reports" */ '@/views/Reports/Reports.vue'),
        },
      ],
    },
    {
      path: '/zaakbegeleiding',
      component: () => import(/* webpackChunkName: "zaakbegeleiding" */ '@/views/CaseMediators/CaseMediators.vue'),
      meta: { requiresAuth: true, name: 'caseMediators', type: 'CaseMediators', category: 'caseMediators', requiresRoles: everybody },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "zaakbegeleiding-dashboard" */ '@/views/CaseMediators/Dashboard/Dashboard.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "zaakbegeleiding-reports" */ '@/views/Reports/Reports.vue'),
        },
        {
          path: 'rapportage',
          component: () => import(/* webpackChunkName: "zaakbegeleiding-rapportage" */ '@/views/CaseMediators/AppointmentCount/AppointmentCount.vue'),
        },
      ],
    },
    {
      path: '/Bureau',
      component: () => import(/* webpackChunkName: "bureau" */ '@/views/Expert/Expert.vue'),
      meta: { requiresAuth: true, name: 'bureau', type: 'Bureau', category: 'bureau', requiresRoles: everybody },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "bureau-dashboard" */ '@/views/Expert/Dashboard/Dashboard.vue'),
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "bureau-mededelingen" */ '@/views/Expert/Mededelingen/Mededelingen.vue'),
        },
        {
          path: 'reports',
          component: () => import(/* webpackChunkName: "bureau-reports" */ '@/views/Reports/Reports.vue'),
        },
      ],
    },
    {
      path: '/rapportage',
      component: () => import(/* webpackChunkName: "report-statistics-dashboard" */ '@/views/ReportStatistics/ReportStatistics.vue'),
      meta: { requiresAuth: true, name: 'report statistics', type: 'ReportStatistics', category: 'tools', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'], hasManagementInfo: true, isSupervisor: true },
      children: [
        {
          path: '/',
          name: 'report.stats',
          component: () => import(/* webpackChunkName: "report-dashboard" */ '@/views/ReportStatistics/Dashboard/Dashboard.vue'),
        },
        {
          path: 'pdf-rapportages',
          name: 'pdf-rapportages',
          component: () => import(/* webpackChunkName: "report-statistics-pdf-rapportages" */ '@/views/ReportStatistics/PdfRapportages/PdfRapportages.vue'),
        },
        {
          path: 'pdf-rapportages/:id',
          name: 'pdf-rapportages.detail',
          component: () => import(/* webpackChunkName: "report-statistics-pdf-rapportages-detail" */ '@/views/ReportStatistics/PdfRapportages/PdfRapportage.vue'),
        },
        {
          path: 'opgeleverd-matrix',
          component: () => import(/* webpackChunkName: "report-statistics-opgeleverd-matrix" */ '@/views/ReportStatistics/OpgeleverdMatrix/OpgeleverdMatrix.vue'),
        },
        {
          path: 'box-plot-doorlooptijd',
          component: () => import(/* webpackChunkName: "report-statistics-box-plot-doorlooptijd" */ '@/views/ReportStatistics/BoxPlotDoorlooptijd/BoxPlotDoorlooptijd.vue'),
        },
        {
          path: 'termijn-bewaking',
          component: () => import(/* webpackChunkName: "report-statistics-termijn-bewaking" */ '@/views/ReportStatistics/TermijnBewaking/TermijnBewaking.vue'),
        },
        {
          path: 'kwaliteit-monitor',
          component: () => import(/* webpackChunkName: "report-statistics-kwaliteit-monitor" */ '@/views/ReportStatistics/KwaliteitMonitor/KwaliteitMonitor.vue'),
        },
        {
          path: 'ftr-logboek',
          component: () => import(/* webpackChunkName: "report-statistics-ftr-logboek" */ '@/views/ReportStatistics/FtrLogboek/FtrLogboek.vue'),
        },
        {
          path: 'production-statistics-outgoing',
          component: () => import(/* webpackChunkName: "report-statistics-production-statistics-outgoing" */ '@/views/ReportStatistics/ProductionStatistics/ProductionStatistics.vue'),
        },
        {
          path: 'production-statistics-incomming',
          component: () => import(/* webpackChunkName: "report-statistics-production-statistics-incomming" */ '@/views/ReportStatistics/ProductionStatistics/ProductionStatistics.vue'),
        },
        {
          path: 'rapport-aantallen-ohw',
          component: () => import(/* webpackChunkName: "report-statistics-rapport-aantallen-ohw" */ '@/views/ReportStatistics/RapportAantallenOhw/RapportAantallenOhw.vue'),
        },
        {
          path: 'dossiers-uit-termijn',
          component: () => import(/* webpackChunkName: "planning-statistics-dossiers-uit-termijn" */ '@/views/ReportStatistics/DossiersUitTermijn/DossiersUitTermijn.vue'),
        },
        {
          path: 'dossier-onder-handen',
          component: () => import(/* webpackChunkName: "report-dossier-onder-handen-statistics" */ '@/views/ReportStatistics/DossierOnderHanden/DossierOnderHanden.vue'),
        },
        {
          path: 'voorstel-prt',
          component: () => import(/* webpackChunkName: "report-voorstel-prt-statistics" */ '@/views/ReportStatistics/VoorstelPrt/VoorstelPrt.vue'),
        },
        {
          path: 'doelstellingen',
          component: () => import(/* webpackChunkName: "report-statistics-doelstellingen" */ '@/views/ReportStatistics/Objectives/Objectives.vue'),
        },
        {
          path: 'trendlijn',
          component: () => import(/* webpackChunkName: "report-statistics-trendlijn" */ '@/views/ReportStatistics/Trendlijn/Trendlijn.vue'),
        },
        {
          path: 'ouderdoms-analyse',
          component: () => import(/* webpackChunkName: "report-statistics-ouderdoms-analyse" */ '@/views/ReportStatistics/OuderdomsAnalyse/OuderdomsAnalyse.vue'),
        },
        {
          path: 'fact-sheet',
          component: () => import(/* webpackChunkName: "report-statistics-fact-sheet" */ '@/views/ReportStatistics/FactSheet/FactSheet.vue'),
        },
        {
          path: 'production',
          component: () => import(/* webpackChunkName: "report-statistics-production" */ '@/views/ReportStatistics/Production/Production.vue'),
          children: [
            {
              path: '/',
              component: () => import(/* webpackChunkName: "report-statistics-production-reports" */ '@/views/Reports/Reports.vue'),
            },
          ],
        },
      ],
    },
    // EDITORS
    {
      path: '/schadebepaling/:id',
      name: 'schadebepaling.editor',
      component: () => import(/* webpackChunkName: "schadebepaling-editor" */ '@/views/Editor/Schadebepaling/Schadebepaling.vue'),
      meta: { requiresAuth: true, name: 'Schadebepaling', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'damage_handler', 'damage_controller', 'appeals_committee', 'smr'] },
    },
    {
      path: '/bouwkundige-controle/:id',
      name: 'bouwkundige-controle.editor',
      component: () => import(/* webpackChunkName: "bouwkundige-controle-editor" */ '@/views/Editor/StructuralEngineer/StructuralEngineer.vue'),
      meta: { requiresAuth: true, name: 'bouwkundige-controle', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'damage_handler', 'damage_controller', 'appeals_committee', 'smr'] },
    },
    {
      path: '/mijn-schaderegelen/:id',
      name: 'msr.editor',
      component: () => import(/* webpackChunkName: "msr-editor" */ '@/views/Editor/MSR/MSR.vue'),
      meta: { requiresAuth: true, name: 'MSR', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'expert', 'helpdesk_tcmg', 'case_mediator', 'pre_controller'] },
    },
    {
      path: '/manager/:id',
      name: 'manager.editor',
      component: () => import(/* webpackChunkName: "manager-editor" */ '@/views/Editor/Manager/Manager.vue'),
      meta: { requiresAuth: true, name: 'Manager', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'expert', 'helpdesk_tcmg'] },
    },
    {
      path: '/expert/:id',
      name: 'expert.editor',
      component: () => import(/* webpackChunkName: "expert-editor" */ '@/views/Editor/Expert/Expert.vue'),
      meta: { requiresAuth: true, name: 'Expert', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'pre_controller', 'expert', 'helpdesk_tcmg'] },
    },
    {
      path: '/planning/:id',
      name: 'planning.editor',
      component: () => import(/* webpackChunkName: "planning-editor" */ '@/views/Editor/Planning/Planning.vue'),
      meta: { requiresAuth: true, name: 'Planning', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'smr', 'helpdesk_tcmg'] },
    },
    {
      path: '/planning-expert/:id',
      name: 'planning-expert.editor',
      component: () => import(/* webpackChunkName: "planning-expert-editor" */ '@/views/Editor/PlanningExpert/PlanningExpert.vue'),
      meta: { requiresAuth: true, name: 'PlanningExpert', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'pre_controller', 'expert', 'helpdesk_tcmg'] },
    },
    {
      path: '/nalever/:id',
      name: 'nalever.editor',
      component: () => import(/* webpackChunkName: "nalever-editor" */ '@/views/Editor/Nalever/Nalever.vue'),
      meta: { requiresAuth: true, name: 'Nalever', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'damage_handler', 'damage_controller', 'smr', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee'] },
    },
    {
      path: '/work-preparation/:id',
      name: 'work-preparation.editor',
      component: () => import(/* webpackChunkName: "work-preparation-editor" */ '@/views/Editor/WorkPreparation/WorkPreparation.vue'),
      meta: { requiresAuth: true, name: 'WorkPreparation', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'smr', 'damage_handler', 'damage_controller', 'appeals_committee'] },
    },
    {
      path: '/work-preparation-objection/:id',
      name: 'work-preparation-objection.editor',
      component: () => import(/* webpackChunkName: "work-preparation-objection-editor" */ '@/views/Editor/WorkPreparationObjection/WorkPreparationObjection.vue'),
      meta: { requiresAuth: true, name: 'WorkPreparationObjection', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'appeals_committee'] },
    },
    {
      path: '/pre-check/:id',
      name: 'pre-check.editor',
      component: () => import(/* webpackChunkName: "pre-check-editor" */ '@/views/Editor/PreCheck/PreCheck.vue'),
      meta: { requiresAuth: true, name: 'PreCheck', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'pre_controller'] },
    },
    {
      path: '/schade-regelen/:id',
      name: 'schade-regelen.editor',
      component: () => import(/* webpackChunkName: "schade-regelen-editor" */ '@/views/Editor/SimpleEditor/SimpleEditor.vue'),
      meta: { requiresAuth: true, name: 'SimpleEditor', type: 'Editor', category: 'editor', requiresRoles: ['simple', 'simple_plus', 'admin', 'manager', 'helpdesk_tcmg', 'pre_controller', 'expert'] },
    },
    {
      path: '/uitvraag/:id',
      name: 'uitvraag.editor',
      component: () => import(/* webpackChunkName: "uitvraag-editor" */ '@/views/Editor/Uitvraag/Uitvraag.vue'),
      meta: { requiresAuth: true, name: 'Uitvraag', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'] },
    },
    {
      path: '/pre-check-images/:id',
      name: 'pre-check-images.editor',
      component: () => import(/* webpackChunkName: "pre-check-images-editor" */ '@/views/Editor/PreCheckImages/PreCheckImages.vue'),
      meta: { requiresAuth: true, name: 'PreCheckImages', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'pre_controller', 'expert'] },
    },
    {
      path: '/work-division/:id',
      name: 'work-division.editor',
      component: () => import(/* webpackChunkName: "work-division-editor" */ '@/views/Editor/WorkDivision/WorkDivision.vue'),
      meta: { requiresAuth: true, name: 'WorkDivision', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg', 'pre_controller'] },
    },
    {
      path: '/further-investigation/:id',
      name: 'further-investigation.editor',
      props: true,
      component: () => import(/* webpackChunkName: "further-investigation-editor" */ '@/views/Editor/FurtherInvestigation/FurtherInvestigation.vue'),
      meta: { requiresAuth: true, name: 'FurtherInvestigation', type: 'Editor', category: 'editor', requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'] },
    },
    // TOOLS
    {
      path: '/tools',
      component: () => import(/* webpackChunkName: "tools" */ '@/views/Tools/Dashboard.vue'),
      meta: { requiresAuth: true, name: 'tools', type: 'tools', category: 'tools', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'helpdesk_tcmg'] },
      children: [
        {
          path: 'trillingstool',
          component: () => import(/* webpackChunkName: "trillingstool" */ '@/views/Trillingstool/Trillingstool.vue'),
        },
        {
          path: 'trillingstool-waardedaling',
          component: () => import(/* webpackChunkName: "trillingstool-waardedaling" */ '@/views/Trillingstool/TrillingstoolWaardedaling.vue'),
        },
        {
          path: 'export-excel',
          component: () => import(/* webpackChunkName: "export-excel" */ '@/views/Tools/ExportExcel/ExportExcel.vue'),
        },
        {
          path: 'excel-uploader-smr',
          meta: { type: 'smr' },
          name: 'excelUploaderSmr',
          component: () => import(/* webpackChunkName: "excel-uploader-smr" */ '@/views/ExcelUploader/ExcelUploader.vue'),
        },
        {
          path: 'deadline-manager',
          name: 'DeadlineManager',
          component: () => import(/* webpackChunkName: "deadline-manager" */ '@/views/DeadlineManager/DeadlineManager.vue'),
        },
        {
          path: 'planning-checker',
          name: 'planning-checker',
          component: () => import(/* webpackChunkName: "planning-checker" */ '@/views/PlanningChecker/PlanningChecker.vue'),
        },
        {
          path: 'licentie-overzicht',
          name: 'license.stats',
          component: () => import(/* webpackChunkName: "license-statistics" */ '@/views/LicenseStats/LicenseStats.vue'),
        },
        {
          path: 'ves-import-tool',
          component: () => import(/* webpackChunkName: "ves-import-tool" */ '@/views/VESImportTool/VESImportTool.vue'),
        },
        {
          path: 'opname-export',
          component: () => import(/* webpackChunkName: "opname-export" */ '@/views/OpnameExport/OpnameExport.vue'),
        },
      ],
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: "support" */ '@/views/Support/Support.vue'),
      meta: { requiresAuth: true, name: 'Support', type: 'Tools', category: 'tools' },
    },
    // URENADMINISTRATIE
    {
      path: '/urenadministratie',
      component: () => import(/* webpackChunkName: "hour-administration" */ '@/views/HourAdministration/HourAdministration.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin'] },
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "hour-registration" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/HourRegistration/HourRegistration.vue
          meta: { isServiceOrganization: false },
        },
        {
          path: 'mijn-urenoverzicht',
          component: () => import(/* webpackChunkName: "time-overview" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/TimeOverview/TimeOverview.vue
        },
        {
          path: 'uren-verwerken',
          component: () => import(/* webpackChunkName: "apply-hours" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/ApplyHours/ApplyHours.vue
        },
        {
          path: 'declaratie-specificaties',
          component: () => import(/* webpackChunkName: "declaratie-specificaties" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/DeclaratieSpecificaties/DeclaratieSpecificaties.vue
        },
        {
          path: 'interne-specificaties',
          component: () => import(/* webpackChunkName: "interne-specificaties" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/InterneSpecificaties/InterneSpecificaties.vue
        },
        {
          path: 'interne-specificaties/:id',
          component: () => import(/* webpackChunkName: "interne-specificaties-details" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/InterneSpecificaties/InterneSpecificatie.vue
        },
        {
          path: 'aannemersvariant',
          component: () => import(/* webpackChunkName: "aannemersvariant" */ '@/views/PageMovedToFinance/PageMovedToFinance.vue'), // @/views/HourAdministration/Aannemersvariant/Aannemersvariant.vue
        },
        {
          path: 'tarieven',
          component: () => import(/* webpackChunkName: "tariffs" */ '@/views/HourAdministration/Tariffs/Tariffs.vue'),
        },
        {
          path: 'instellingen',
          component: () => import(/* webpackChunkName: "hour-settings" */ '@/views/HourAdministration/Settings/Settings.vue'),
        },

        // invoice specifications
        {
          path: 'specificatie-checker',
          component: () => import(/* webpackChunkName: "hour-administration-specificatie-checker" */ '@/views/Invoice/Specifications/Check/Check.vue'),
          meta: { isServiceOrganization: false },
        },
        {
          path: 'specificaties-uploaden',
          component: () => import(/* webpackChunkName: "hour-administration-specificaties-uploaden" */ '@/views/Invoice/Specifications/Upload/Upload.vue'),
          meta: { isServiceOrganization: false },
        },
        {
          path: 'specificaties-indienen',
          component: () => import(/* webpackChunkName: "hour-administration-specificaties-indienen" */ '@/views/Invoice/Specifications/Submit/Submit.vue'),
          meta: { isServiceOrganization: false },
        },
        {
          path: 'ingediende-specificaties',
          component: () => import(/* webpackChunkName: "hour-administration-ingediende-specificaties" */ '@/views/Invoice/Specifications/Submitted/Submitted.vue'),
          meta: { isServiceOrganization: true },
        },
        {
          path: 'afgekeurde-specificaties',
          component: () => import(/* webpackChunkName: "hour-administration-afgekeurde-specificaties" */ '@/views/Invoice/Specifications/Rejected/Rejected.vue'),
        },
        {
          path: 'goedgekeurde-specificaties',
          component: () => import(/* webpackChunkName: "hour-administration-goedgekeurde-specificaties" */ '@/views/Invoice/Specifications/Approved/Approved.vue'),
        },
      ],
    },
    {
      path: '/specificaties/:id',
      name: 'specificaties.detail',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "hour-specification-detail" */ '@/views/Invoice/Specification/Specification.vue'),
    },
    // SETTINGS
    {
      path: '/instellingen',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Settings.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin'] },
      children: [
        {
          path: 'steekproef',
          component: () => import(/* webpackChunkName: "settings-steekproef" */ '@/views/Settings/Steekproef/Steekproef.vue'),
        },
        {
          path: 'gebruikersbeheer',
          component: () => import(/* webpackChunkName: "settings-users" */ '@/views/Users/Users.vue'),
        },
        {
          path: 'simple-gebruikersbeheer',
          component: () => import(/* webpackChunkName: "settings-simple-users" */ '@/views/SimpleUsers/SimpleUsers.vue'),
        },
        {
          path: 'deskundige-gebruikersbeheer',
          component: () => import(/* webpackChunkName: "settings-expert-users" */ '@/views/Users/Expert/Experts.vue'),
        },
        {
          path: 'mededelingen',
          component: () => import(/* webpackChunkName: "announcements" */ '@/views/Announcements/Announcements.vue'),
        },
        {
          path: 'configuratie',
          component: () => import(/* webpackChunkName: "settings-change" */ '@/views/Settings/Configuration/Configuration.vue'),
        },
        {
          path: 'deskundige-register',
          component: () => import(/* webpackChunkName: "expert-register" */ '@/views/Settings/ExpertRegister/ExpertRegister.vue'),
        },
        {
          path: 'deskundige-register-dashboard',
          component: () => import(/* webpackChunkName: "expert-register-dashboard" */ '@/views/Settings/ExpertRegisterDashboard/ExpertRegisterDashboard.vue'),
        },
        {
          path: 'deskundige-register-submissions',
          component: () => import(/* webpackChunkName: "expert-register" */ '@/views/ExpertRegisterSubmissions/ExpertRegisterSubmissions.vue'),
        },
        {
          path: 'rollen',
          component: () => import(/* webpackChunkName: "settings-roles" */ '@/views/Settings/Roles/Roles.vue'),
        },
        {
          path: 'permissies',
          component: () => import(/* webpackChunkName: "settings-roles" */ '@/views/Settings/Permissions/Permissions.vue'),
        },
        {
          path: 'questions',
          component: () => import(/* webpackChunkName: "settings-questions" */ '@/views/Settings/Questions/Questions.vue'),
        },
        {
          path: 'default-answers',
          component: () => import(/* webpackChunkName: "settings-default-answers" */ '@/views/Settings/DefaultAnswers/DefaultAnswers.vue'),
        },
      ],
    },
    {
      path: '/default-answers/:id',
      name: 'default-answers.show',
      component: () => import(/* webpackChunkName: "settings-default-answers-detail" */ '@/views/Settings/DefaultAnswers/DefaultAnswer.vue'),
    },
    {
      path: '/rollen/:id',
      name: 'roles.show',
      component: () => import(/* webpackChunkName: "settings-role-detail" */ '@/views/Settings/Roles/Role.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin', 'manager'] },
    },
    {
      path: '/permissies/:id',
      name: 'permissions.show',
      component: () => import(/* webpackChunkName: "settings-permission-detail" */ '@/views/Settings/Permissions/Permission.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin', 'manager'] },
    },
    {
      path: '/expert-user/:id',
      name: 'expert-users.show',
      component: () => import(/* webpackChunkName: "expert-detail" */ '@/views/Users/Expert/Expert.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'] },
    },
    {
      path: '/users/:id',
      name: 'users.show',
      component: () => import(/* webpackChunkName: "user-detail" */ '@/views/Users/User.vue'),
      meta: { requiresAuth: true, requiresRoles: ['admin', 'manager', 'helpdesk_tcmg'] },
    },
    {
      path: '/mijn-profiel',
      name: 'my-profile',
      component: () => import(/* webpackChunkName: "my-profile" */ '@/views/Users/User.vue'),
      meta: { requiresAuth: true, requiresRoles: everybody },
    },
    {
      path: '/instructies',
      name: 'instructions',
      component: () => import(/* webpackChunkName: "instruction" */ '@/views/Instructions/Instruction.vue'),
      meta: { requiresAuth: true, name: 'instruction', type: 'Help', category: 'helper' },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'),
      meta: { requiresGuest: true },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ '@/views/Auth/Login.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "forgotpassword" */ '@/views/ForgotPassword/ForgotPassword.vue'),
      meta: { requiresGuest: true },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "resetpassword" */ '@/views/ResetPassword/ResetPassword.vue'),
      meta: { requiresGuest: true },
    },
    {
      path: '/set-mfa',
      name: 'set-mfa',
      component: () => import(/* webpackChunkName: "setmfa" */ '@/views/SetMfa/SetMfa.vue'),
      meta: { requiresGuest: true },
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/Errors/PageNotFound.vue'),
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import(/* webpackChunkName: "maintenance-page" */ '@/views/Maintenance/Maintenance.vue'),
    },
    {
      path: '/alert-confirmation',
      name: 'alertconfirmation',
      component: () => import(/* webpackChunkName: "alert-confirmation" */ '@/views/AlertConfirmation/AlertConfirmation.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/schadebibliotheek',
      name: 'library',
      component: () => import(/* webpackChunkName: "library" */ '@/views/Library/Library.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/schadebibliotheek/:id',
      name: 'library.detail',
      component: () => import(/* webpackChunkName: "library-detail" */ '@/views/Library/Library.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/monitor',
      name: 'monitor',
      component: () => import(/* webpackChunkName: "library" */ '@/views/Monitor/Monitor.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/release-dashboard',
      name: 'releasedashboard',
      component: () => import(/* webpackChunkName: "release-dashboard" */ '@/views/ReleaseDashboard/ReleaseDashboard.vue'),
      meta: { requiresAuth: true },
    },

    // PLANNINGTOOL
    {
      path: '/plannable-reports',
      name: 'plannable-reports',
      component: () => import(/* webpackChunkName: "plannable-reports" */ '@/views/PlannableReports/PlannableReports.vue'),
      meta: { requiresAuth: true, name: 'plannable-reports', type: 'Report', category: 'reports', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'helpdesk_tcmg'] },
    },
    {
      path: '/plannable-reports/:status',
      name: 'plannable-reports.status',
      component: () => import(/* webpackChunkName: "plannable-reports-status" */ '@/views/PlannableReports/PlannableReports.vue'),
      meta: { requiresAuth: true, name: 'plannable-reports', type: 'Report', category: 'reports', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'helpdesk_tcmg'] },
    },
    {
      path: '/availability',
      name: 'availability',
      component: () => import(/* webpackChunkName: "availability" */ '@/views/Availability/Availability.vue'),
      meta: { requiresAuth: true, name: 'availability', type: 'Availability', category: 'availability', requiresRoles: everybody },
    },
    {
      path: '/planning-tool/',
      name: 'planning-tool',
      component: () => import(/* webpackChunkName: "planning-tool" */ '@/views/PlanningTool/PlanningTool.vue'),
      meta: { requiresAuth: true, name: 'PlanningTool', type: 'planning', category: 'planning-tool', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'helpdesk_tcmg'] },
    },
    {
      path: '/planning-tool-immaterieel/',
      name: 'planning-tool-immaterieel',
      component: () => import(/* webpackChunkName: "planning-tool-immaterieel" */ '@/views/Planning/Immaterieel/PlanningToolImmaterieel/PlanningToolImmaterieel.vue'),
      meta: { requiresAuth: true, name: 'PlanningToolImmaterieel', type: 'planning', category: 'planning-tool', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'helpdesk_tcmg'] },
    },
    {
      path: '/planning-tool-ves-nulmeting/',
      name: 'planning-tool-ves-nulmeting',
      component: () => import(/* webpackChunkName: "planning-tool-ves-nulmeting" */ '@/views/Planning/Ves/PlanningToolVES/PlanningToolVES.vue'),
      meta: { requiresAuth: true, name: 'PlanningToolVES', type: 'planning', category: 'planning-tool', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee', 'helpdesk_tcmg'] },
    },

    {
      path: '/effectgebied',
      name: 'effect-area',
      component: () => import(/* webpackChunkName: "effect-area" */ '@/views/Effectgebied/Effectgebied.vue'),
      meta: { requiresAuth: true, name: 'EffectArea', type: 'effect-area', category: 'effect-area', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'helpdesk_tcmg'] },
    },

    // Project tool
    {
      path: '/projecten/',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects/Projects.vue'),
      meta: { requiresAuth: true, name: 'Projects', type: 'projects', category: 'projects', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee'] },
    },
    {
      path: '/projecten/:id',
      name: 'project',
      component: () => import(/* webpackChunkName: "project" */ '@/views/Projects/Project.vue'),
      meta: { requiresAuth: true, name: 'Project', type: 'projects', category: 'projects', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee'] },
    },
    {
      path: '/project-manager/:id',
      name: 'project-manager',
      component: () => import(/* webpackChunkName: "project-manager" */ '@/views/Projects/ProjectManager.vue'),
      meta: { requiresAuth: true, name: 'ProjectManager', type: 'projects', category: 'projects', requiresRoles: ['admin', 'manager', 'tcmg', 'werkvoorbereiding', 'planning', 'serviceloket', 'appeals_committee'] },
    },

    // Chat room tool
    {
      path: '/meeting/:id',
      name: 'chat-room',
      component: () => import(/* webpackChunkName: "chat-room" */ '@/views/ChatRoom/ChatRoom.vue'),
      meta: { requiresAuth: true, name: 'ChatRoom', type: 'chat-room', category: 'chat-room' },
    },

    // Search tool
    {
      path: '/search',
      name: 'search',
      component: () => import(/* webpackChunkName: "search" */ '@/views/Tools/SearchModule/SearchModule.vue'),
      meta: { requiresAuth: true, name: 'SearchModule', type: 'search', category: 'search', requiresRoles: ['admin'] },
    },

    // Exports
    {
      path: '/mijn-exports',
      name: 'exports',
      component: () => import(/* webpackChunkName: "exports" */ '@/views/Exports/Exports.vue'),
      meta: { requiresAuth: true, name: 'Exports', type: 'exports', category: 'exports' },
    },
    // KITCHENSINK
    {
      path: '/kitchen-sink',
      name: 'kitchen-sink',
      component: () => import(/* webpackChunkName: "kitchen-sink" */ '@/views/AKitchenSink.vue'),
      meta: { requiresAuth: true, name: 'Kitchen-sink', type: 'kitchen-sink', category: 'kitchen-sink' },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  store.state.router = { current: to, previous: from };

  if (store.state.router.current.query && store.state.router.current.query.token && ! store.state.router.current.query.planned_at) {
    redirectSimpleUser();
  } else if (store.state.router.current.query && store.state.router.current.query.sso) {
    redirectSSOLink();
  } else {
    if (Token.get('access')) {
      if (store.state.router.current.name === 'login') {
        router.push('/');
      }
    }

    if (to.matched.some(Guard.auth)) {
      if (! Token.get('access')) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath,
          },
        });
      } else if (to.matched.some((record) => record.meta.requiresGuest)) {
        next({
          path: '/',
        });
      } else if (! user) {
        const interval = setInterval(() => {
          iterations += 1;

          if (store.state.Auth) {
            user = store.state.Auth;
            clearInterval(interval);
            reRoute(to, next);
          }

          if (iterations > 20) {
            clearInterval(interval);
            next({
              path: '/login',
              query: {
                redirect: to.fullPath,
              },
            });
          }
        }, 500);
      } else {
        user = store.state.Auth;
        reRoute(to, next);
      }
    } else {
      next();
    }
  }
});

const reRoute = (to: any, next: Function) => {
  const user = store.state.Auth as User | null;
  if (user) {
    if ((to.path === '/support' || to.path === '/kitchen-sink') && ! user.isAtabix) {
      next({
        path: '/',
      });
    }

    if (to.matched.some((record: any): boolean => record.meta.hasManagementInfo && record.meta.isSupervisor)) {
      if (user.has_management_info || user.supervisor_info?.is_supervisor) {
        next();
      } else {
        next({
          path: '/',
        });
      }
    } else if (to.matched.some((record: any): boolean => record.meta.hasManagementInfo)) {
      if (user.has_management_info) {
        next();
      } else {
        next({
          path: '/',
        });
      }
    } else if (to.matched.some((record: any): boolean => record.meta.isSupervisor)) {
      if (user.supervisor_info?.is_supervisor) {
        next();
      } else {
        next({
          path: '/',
        });
      }
    }

    if (user.force_password_change) {
      next({
        path: '/reset-password',
      });
    } else if (to.meta.requiresRoles && ! to.meta.requiresRoles.includes(user.type)) {
      if (! user.mfa_token) {
        next({
          path: '/set-mfa',
        });
      } else if (user.hasRole(['expert', 'case_mediator'])) {
        next({
          path: '/reports',
        });
      } else if (user.hasRole(['simple', 'simple_plus'])) {
        if (! store.state.isServiceOrganization) {
          next({
            path: '/mijn-profiel',
          });
        } else {
          next({
            path: '/',
          });
        }
      } else {
        next({
          path: '/',
        });
      }
    } else {
      if (! user.mfa_token) {
        next({
          path: '/set-mfa',
        });
      } else if (typeof to.meta.isServiceOrganization !== 'undefined' && to.meta.isServiceOrganization !== store.state.isServiceOrganization) {
        next({
          path: '/',
        });
      }

      next();
    }
  } else {

  }
};

const redirectSimpleUser = () => {
  const token = store.state.router.current.query.token;
  Token.delete(['access', 'refresh']);
  Token.set({ access: token as string });
  new User()
    .current()
    .then((user: User) => {
      store.dispatch('userAuthenticated', user);
      router.push(store.state.router.current.path);
    })
    .catch((error: AxiosError) => {
      console.log('error', error);
      Token.delete(['access', 'refresh']);
    });
};

const redirectSSOLink = () => {
  const link = store.state.router.current.query.sso;
  Token.delete(['access', 'refresh']);

  axios
    .post(link as string)
    .then((response: any) => {
      const user = new User(response.data);
      Token.delete(['access', 'refresh']);
      Token.set({ access: (user.token as any).bearer });

      store.dispatch('userAuthenticated', user);
      router.push(store.state.router.current.path);
    })
    .catch((error: AxiosError) => {
      Token.delete(['access', 'refresh']);
    });
};

export default router;
