import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * @ignore
 * We don't allow the use of prepend-icon or append-icon
 *
 * instead use the below values:
 *  - prepend-icon ~> prepend-outer-icon
 *  - append-icon ~> append-inner-icon
 *
 * @see https://github.com/atabix/vue-ui/issues/26
 */
@Component
export default class AIconNamePropertyMixin extends Vue {
  /**
   * Prepends an icon to the component,
   */
  @Prop({ required: false })
  protected prependOuterIcon!: string;

  /**
   * Appends an icon to the component
   */
  @Prop({ required: false })
  protected appendInnerIcon!: string;

  protected guardInvalidIconPropUse(): void {
    Object.keys(this.$attrs).forEach((key) => {
      if (key === 'prepend-icon') {
        throw new Error(`We don't allow the use of '${key}' for naming consistencies. Instead use proper property name 'prepend-outer-icon'`);
      }
      if (key === 'append-icon') {
        throw new Error(`We don't allow the use of '${key}' for naming consistencies. Instead use proper property name 'append-inner-icon'`);
      }
    });
  }
}
