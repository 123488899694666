import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep, debounce } from 'lodash';
import { User } from '@/models/User';

@Component<ReportMediatorFilter>({})
export default class ReportMediatorFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected users: User[] = [];

  protected selectedUsers: User[] = [];

  protected searchInput = '';

  public searchDebounce: Function = this.handleSearch();

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedUsers);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedUsers = [];
    } else {
      this.selectedUsers = cloneDeep(this.value);
    }
  }

  public async mounted() {
    await this.getUsers();
  }

  protected async getUsers(): Promise<void> {
    this.users = await new User()
      .filter('search', this.searchInput)
      .sort('name', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  handleSearch() {
    return debounce(() => {
      this.getUsers();
    }, 400);
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedUsers', this.users.map((user: User) => user.uuid || ''));
    } else {
      this.$set(this, 'selectedUsers', []);
    }

    this.handleChange();
  }
}
