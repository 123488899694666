export const filter = (value: any, callback?: (value: any, index: number | string) => boolean) => {
  if (! callback) {
    callback = (value, index) => value !== null;
  }

  if (typeof value === 'object') {
    Object.keys(value).forEach((key) => { if (callback && ! callback(value[key], key)) { delete value[key]; } });
    return value;
  }

  if (Array.isArray(value)) {
    return value.filter(callback);
  }
};

export const spliceItems = (items: any[], selected: any, key = '') => {
  const index = items.findIndex((item) => item[key] === selected[key]);
  if (index >= 0) {
    items.splice(index, 1);
  }

  return items;
};
