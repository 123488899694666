import { Model } from '@/models/Model';
import client from '@/support/Client';

export class File extends Model {
  protected $name = 'File';

  protected $endpoint = '/files';

  public description?: string;

  public file_name?: string;

  public mime_type?: string;

  public original?: string;

  public show_in_report?: string;

  public sort_order?: number;

  public thumb?: string;

  public type?: string;

  public uuid?: string;

  public sortFiles(attributes: string[]) {
    const url = this.$endpoint;

    return client('post', url, attributes, true);
  }
}
