var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.readOnly
    ? _c(
        "v-menu",
        {
          ref: "dialog",
          attrs: {
            attach: "",
            bottom: "",
            "close-on-content-click": false,
            lazy: "",
            "offset-y": "",
            "full-width": "",
            "max-width": "290px",
            "min-width": "290px"
          },
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-text-field",
            _vm._b(
              {
                staticClass: "input input-date",
                attrs: {
                  slot: "activator",
                  "error-messages": _vm.date.errorMessage,
                  placeholder:
                    "" +
                    (_vm.$attrs["placeholder"]
                      ? _vm.$attrs["placeholder"]
                      : "DD-MM-YYYY")
                },
                on: { "click:clear": _vm.onClear, change: _vm.handleChange },
                slot: "activator",
                model: {
                  value: _vm.date.formattedDate,
                  callback: function($$v) {
                    _vm.$set(_vm.date, "formattedDate", $$v)
                  },
                  expression: "date.formattedDate"
                }
              },
              "v-text-field",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "v-date-picker",
            _vm._g(
              {
                ref: "picker",
                staticClass: "tw-p-0",
                attrs: {
                  "first-day-of-week": "1",
                  locale: "nl-nl",
                  min: "1910-01-01",
                  color: "#837f16"
                },
                on: {
                  input: function($event) {
                    _vm.menu = false
                  },
                  change: _vm.handleChange
                },
                model: {
                  value: _vm.date.selectedDate,
                  callback: function($$v) {
                    _vm.$set(_vm.date, "selectedDate", $$v)
                  },
                  expression: "date.selectedDate"
                }
              },
              _vm.$listeners
            )
          )
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-text-field",
            _vm._b(
              {
                staticClass: "input input-date",
                attrs: {
                  readonly: "",
                  disabled: "",
                  "error-messages": _vm.date.errorMessage,
                  placeholder:
                    "" +
                    (_vm.$attrs["placeholder"]
                      ? _vm.$attrs["placeholder"]
                      : "DD-MM-YYYY")
                },
                model: {
                  value: _vm.date.formattedDate,
                  callback: function($$v) {
                    _vm.$set(_vm.date, "formattedDate", $$v)
                  },
                  expression: "date.formattedDate"
                }
              },
              "v-text-field",
              _vm.$attrs,
              false
            )
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }